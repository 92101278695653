import React from "react";
import { gql } from "graphql-tag";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { MedicationDispenseConnection } from "@ehealth/ehealth-ua.schema";

import Badge from "../../../components/Badge";
import Link from "../../../components/Link";
import Table from "../../../components/Table";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type MedicationDispensesTableProps = {
  data: MedicationDispenseConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  tableName?: string;
};

const MedicationDispensesTable = ({
  data,
  locationParams,
  setLocationParams,
  tableName
}: MedicationDispensesTableProps) => {
  const { i18n } = useLingui();
  if (!(data && data.length)) return null;

  return (
    <Table
      data={data}
      header={{
        databaseId: <Trans>ID</Trans>,
        medicationRequestId: <Trans>Medication request ID</Trans>,
        legalEntityId: <Trans>Legal entity ID</Trans>,
        divisionId: <Trans>Division ID</Trans>,
        status: <Trans>Status</Trans>,
        dispensedAt: <Trans>Dispensed at</Trans>,
        action: <Trans>Action</Trans>
      }}
      renderRow={({
        id,
        status,
        insertedAt,
        legalEntity,
        division,
        dispensedAt,
        ...medicationDispense
      }) => ({
        ...medicationDispense,
        legalEntityId: legalEntity.databaseId,
        divisionId: division.databaseId,
        status: (
          <Badge
            name={status}
            dictionary="eHealth/medication_dispense_statuses"
            minWidth={100}
          />
        ),
        dispensedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, dispensedAt),
        action: (
          <Link to={`../${id}`} fontWeight="bold">
            <Trans>Details</Trans>
          </Link>
        )
      })}
      sortableFields={["insertedAt", "status"]}
      sortingParams={parseSortingParams(locationParams.orderBy)}
      onSortingChange={(sortingParams) =>
        setLocationParams({
          ...locationParams,
          orderBy: stringifySortingParams(sortingParams)
        })
      }
      tableName={tableName}
    />
  );
};

MedicationDispensesTable.fragments = {
  entry: gql`
    fragment MedicationDispenses on MedicationDispense {
      id
      databaseId
      status
      medicationRequestId
      legalEntity {
        databaseId
      }
      division {
        databaseId
      }
      dispensedAt
    }
  `
};

export default MedicationDispensesTable;
