import * as React from "react";
import { Trans } from "@lingui/macro";

import * as Field from "../Field";
import STATUSES from "../../helpers/statuses";

type StatusFieldProps = {
  name: string;
  status: string;
  label?: React.ReactElement;
};

const StatusField = ({ name, status, label }: StatusFieldProps) => (
  <Trans
    id="All statuses"
    render={({ translation }) => (
      <Field.Select
        name={name}
        label={label || <Trans id="Status" />}
        items={Object.keys(STATUSES[status])}
        itemToString={(item: string) => STATUSES[status][item] || translation}
        variant="select"
        emptyOption
        filterOptions={{ keys: [(item: string) => STATUSES[status][item]] }}
      />
    )}
  />
);

export default StatusField;
