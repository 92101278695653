import React from "react";
import { Box, Flex } from "@rebass/emotion";
import { Trans, t } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { SearchIcon } from "@edenlabllc/ehealth-icons";

import * as Field from "../../components/Field";

import { CANDIDATE_MERGE_REQUEST_STATUSES } from "./Search";

const PrimarySearchFields = () => (
  <Flex flexDirection="column">
    <Box width={1 / 3} mr={2}>
      <Trans
        id="Enter master person ID"
        render={({ translation }) => (
          <Field.Text
            name="filter.masterPersonId"
            label={<Trans id="Master person ID" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
          />
        )}
      />
    </Box>
    <Box width={1 / 3} mr={2}>
      <Trans
        id="Enter person ID"
        render={({ translation }) => (
          <Field.Text
            name="filter.personId"
            label={<Trans id="Person ID" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
          />
        )}
      />
    </Box>
    <Box width={1 / 3} mr={2}>
      <Trans
        id="Enter status"
        render={({ translation }) => (
          <Field.Select
            name="filter.status"
            label={<Trans id="Status" />}
            placeholder={translation}
            items={Object.keys(CANDIDATE_MERGE_REQUEST_STATUSES.values)}
            itemToString={(item) => i18n._(t`${item}`) || item}
            variant="select"
            postfix={<SearchIcon color="silverCity" />}
            filterOptions={{
              keys: [
                (item: string) => CANDIDATE_MERGE_REQUEST_STATUSES.values[item]
              ]
            }}
          />
        )}
      />
    </Box>
  </Flex>
);

export default PrimarySearchFields;
