import * as React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex } from "@rebass/emotion";

import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  DiagnosesGroup,
  DiagnosesGroupConnection
} from "@ehealth/ehealth-ua.schema";

import Link from "../../../components/Link";
import Table from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type DiagnosesGroupsTableProps = {
  diagnosesGroups: DiagnosesGroupConnection["nodes"];
  tableName?: string;
};

const DiagnosesGroupsTable = ({
  diagnosesGroups,
  tableName = "diagnosesGroups/search"
}: DiagnosesGroupsTableProps) => (
  <Table
    data={diagnosesGroups}
    header={{
      databaseId: <Trans>ID</Trans>,
      name: <Trans>Group name</Trans>,
      code: <Trans>Group code</Trans>,
      description: <Trans>Description</Trans>,
      isActive: <Trans>Status</Trans>,
      insertedAt: <Trans>Inserted at</Trans>,
      updatedAt: <Trans>Updated at</Trans>,
      action: <Trans>Action</Trans>
    }}
    renderRow={({
      databaseId,
      name,
      code,
      description,
      isActive,
      insertedAt,
      updatedAt,
      id
    }: DiagnosesGroup) => ({
      databaseId,
      name,
      code,
      description: description || "-",
      isActive: (
        <Flex justifyContent="center">
          {isActive ? <PositiveIcon /> : <NegativeIcon />}
        </Flex>
      ),
      insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
      updatedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, updatedAt),
      action: (
        <Link to={`../${id}`} fontWeight="bold">
          <Trans>View</Trans>
        </Link>
      )
    })}
    tableName={tableName}
    hiddenFields="updatedAt"
  />
);

DiagnosesGroupsTable.fragments = {
  entry: gql`
    fragment DiagnosesGroups on DiagnosesGroup {
      id
      code
      databaseId
      description
      name
      isActive
      insertedAt
      updatedAt
    }
  `
};

export default DiagnosesGroupsTable;
