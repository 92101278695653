import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Box, Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import {
  RuleEngineRule,
  // @ts-expect-error types mismatch
  RuleItem
} from "@ehealth/ehealth-ua.schema";

import Button from "../../../components/Button";
import Collapsable from "../../../components/Collapsable";
import DefinitionListView from "../../../components/DefinitionListView";
import EhealthQueryBuilder from "../../../components/QueryBuilder";
import { getDisabledTreeFromJson } from "../../../components/QueryBuilder/helpers";
import Line from "../../../components/Line";
import LoadingOverlay from "../../../components/LoadingOverlay";
import useConfig from "../../../components/QueryBuilder/useConfig";

import { itemsComparators } from "../Search/Mutations/Create";

type RuleProps = RouteComponentProps & {
  ruleEngine: RuleEngineRule;
};

const Rule = ({ ruleEngine }: RuleProps) => {
  const config = useConfig();
  if (!config.fields) {
    return <LoadingOverlay loading={true} />;
  }

  const { databaseId, items = [], itemsComparator, name } = ruleEngine;

  const handleExport = () => {
    let dataStr = JSON.stringify(
      items!.map((item: RuleItem) => ({
        description: item.description,
        value: {
          json: item.value.json,
          string: item.value.string
        }
      }))
    );
    let dataUri =
      "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

    const exportFileDefaultName = `${databaseId}_${name}.json`;

    const linkElement = document.createElement("a");
    linkElement.setAttribute("href", dataUri);
    linkElement.setAttribute("download", exportFileDefaultName);
    linkElement.click();
  };

  return (
    <Box p={5}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Box>
          <DefinitionListView
            labels={{
              itemsComparator: <Trans id="Items comparator" />
            }}
            data={{
              itemsComparator: itemsComparators[itemsComparator]
            }}
            fontSize={14}
            labelWidth="320px"
          />
        </Box>
        <Box>
          <Button variant="blue" onClick={handleExport}>
            <Trans>Export to file</Trans>
          </Button>
        </Box>
      </Flex>
      <Line />
      <Box>
        {items!.map((rule: RuleItem, index: number) => (
          <Collapsable
            title={
              <>
                <Trans>Rule</Trans>
                {` ${rule.number}`}
                {rule.description && `: ${rule.description}`}
              </>
            }
            holderProps={{ mt: 6, mb: 6, style: { cursor: "pointer" } }}
            titleProps={{
              fontSize: 2,
              fontWeight: "bold",
              mr: 2
            }}
            key={index}
          >
            <Box mt={2}>
              <EhealthQueryBuilder
                tree={getDisabledTreeFromJson(rule.value.json, config)}
                config={config}
              />
            </Box>
          </Collapsable>
        ))}
      </Box>
    </Box>
  );
};

export default Rule;
