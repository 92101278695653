import React, { useCallback } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Box, Flex, Text } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { Form, LocationParams, Modal } from "@edenlabllc/ehealth-components";
import system from "@edenlabllc/ehealth-system-components";
import { RemoveItemIcon, SearchIcon } from "@edenlabllc/ehealth-icons";
import {
  Service,
  ServiceConnection,
  ServiceGroupConnection
} from "@ehealth/ehealth-ua.schema";

import Button from "../../../../components/Button";
import * as Field from "../../../../components/Field";
import Link from "../../../../components/Link";
import * as SearchField from "../../../../components/SearchField";
import {
  SearchParams,
  SetSearchParams,
  TLocationParams
} from "../../../../components/SearchForm";
import Table from "../../../../components/Table";

import { ITEMS_PER_PAGE } from "../../../../constants/pagination";

import { StateType } from "./AddGroupElement";

type ServicesModalFormProps = RouteComponentProps<{
  serviceId: string;
}>;

const ServicesModalForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state },
  serviceId
}: ServicesModalFormProps) => {
  const onClose = useCallback(() => navigate!("../", { state }), [
    navigate,
    state
  ]);

  const initDictSystem =
    state.services[serviceId] && state.services[serviceId].dictionaryName
      ? state.services[serviceId].dictionaryName
      : "Service";

  return (
    <LocationParams state={state}>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        setLocationParams({
          system: locationParams.system || initDictSystem,
          ...locationParams
        });
        return (
          <Modal backdrop width="90%">
            <Close onClick={onClose} />
            <Form
              onSubmit={(data: Partial<Service> & { system: string }) => {
                const { code, system, name } = data;
                setLocationParams({ code, name, system });
              }}
              initialValues={{
                ...state,
                ...locationParams
              }}
            >
              <Box width={1 / 2}>
                <SearchField.ForbiddenGroupSystem
                  name="system"
                  systemType="service"
                />
              </Box>
              <Flex>
                <Box width={1 / 2}>
                  <Trans
                    id="Enter code"
                    render={({ translation }) => (
                      <Field.Text
                        name="code"
                        label={<Trans id="Code" />}
                        placeholder={translation}
                        postfix={<SearchIcon color="silverCity" />}
                      />
                    )}
                  />
                </Box>
                <Box width={1 / 2} ml={2}>
                  <Trans
                    id="Enter name"
                    render={({ translation }) => (
                      <Field.Text
                        name="name"
                        label={<Trans id="Name" />}
                        placeholder={translation}
                        postfix={<SearchIcon color="silverCity" />}
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex mx={-1} mt={4} justifyContent="flex-start">
                <Box px={1}>
                  <Button variant="red" type="reset" onClick={onClose}>
                    <Trans>Close</Trans>
                  </Button>
                </Box>
                <Box px={1}>
                  <Button variant="blue">
                    <Trans>Search</Trans>
                  </Button>
                </Box>
              </Flex>
            </Form>
            {!locationParams.system || locationParams.system === "Service" ? (
              <Services
                locationParams={locationParams}
                setLocationParams={setLocationParams}
                state={state}
                serviceId={serviceId!}
                initDictSystem={initDictSystem}
              />
            ) : (
              <ServiceGroups
                locationParams={locationParams}
                setLocationParams={setLocationParams}
                state={state}
                serviceId={serviceId!}
                initDictSystem={initDictSystem}
              />
            )}
          </Modal>
        );
      }}
    </LocationParams>
  );
};

export default ServicesModalForm;

const Close = system(
  {
    is: RemoveItemIcon
  },
  {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: 12,
    height: 12,
    opacity: 0.2
  }
);

type ServicesProps = {
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  state: StateType;
  serviceId: string;
  initDictSystem?: string;
};

const Services = ({
  locationParams,
  setLocationParams,
  state,
  serviceId,
  initDictSystem
}: ServicesProps) => (
  <Query
    query={ServicesQuery}
    variables={{
      first: locationParams.servicesTableFirst
        ? parseInt(locationParams.servicesTableFirst)
        : ITEMS_PER_PAGE[0],
      filter: {
        code: locationParams.code,
        name: locationParams.name
      }
    }}
  >
    {({ data }: QueryResult<{ services: ServiceConnection }>) => {
      const { services } = data || {};
      if (!services) return null;
      return (
        <ServicesTable
          dictionaryName={locationParams.system || initDictSystem}
          data={services.nodes}
          state={state}
          elementIndex={serviceId}
          locationParams={locationParams}
          setLocationParams={setLocationParams}
          hasNextPage={services.pageInfo.hasNextPage}
        />
      );
    }}
  </Query>
);

const ServiceGroups = ({
  locationParams,
  setLocationParams,
  state,
  serviceId,
  initDictSystem
}: ServicesProps) => (
  <Query
    query={ServiceGroupsQuery}
    variables={{
      first: locationParams.servicesTableFirst
        ? parseInt(locationParams.servicesTableFirst)
        : ITEMS_PER_PAGE[0],
      filter: {
        code: locationParams.code,
        name: locationParams.name
      }
    }}
  >
    {({ data }: QueryResult<{ serviceGroups: ServiceGroupConnection }>) => {
      const { serviceGroups } = data || {};
      if (!serviceGroups) return null;
      return (
        <ServicesTable
          dictionaryName={locationParams.system || initDictSystem}
          data={serviceGroups.nodes}
          state={state}
          elementIndex={serviceId}
          locationParams={locationParams}
          setLocationParams={setLocationParams}
          hasNextPage={serviceGroups.pageInfo.hasNextPage}
        />
      );
    }}
  </Query>
);

export const ServicesQuery = gql`
  query ServicesQuery($first: Int, $filter: ServiceFilter) {
    services(first: $first, filter: $filter) {
      nodes {
        id
        databaseId
        name
        code
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const ServiceGroupsQuery = gql`
  query ServiceGroupsQuery($first: Int, $filter: ServiceGroupFilter) {
    serviceGroups(first: $first, filter: $filter) {
      nodes {
        id
        databaseId
        name
        code
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

type ServicesTable = {
  data: ServiceConnection["nodes"] | ServiceGroupConnection["nodes"];
  state: StateType;
  elementIndex: string;
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  dictionaryName: string | undefined;
  hasNextPage: boolean;
};

const ServicesTable = ({
  data,
  state,
  elementIndex,
  locationParams,
  setLocationParams,
  dictionaryName,
  hasNextPage
}: ServicesTable) => {
  const first = locationParams.servicesTableFirst
    ? parseInt(locationParams.servicesTableFirst)
    : ITEMS_PER_PAGE[0];

  return (
    <>
      <Table
        data={data}
        hidePagination
        header={{
          code: <Trans>Code</Trans>,
          name: <Trans>Name</Trans>,
          dictionaryName: <Trans>Dictionary name</Trans>,
          action: <Trans>Action</Trans>
        }}
        renderRow={({ code, name, databaseId }) => {
          const data = {
            ...state,
            services: state.services.map((item, index) => {
              if (index === parseInt(elementIndex))
                return {
                  codeValue: code,
                  dictionaryName,
                  name,
                  id: databaseId
                };
              else return item;
            })
          };

          const isSelected = state.services.find(
            (item) => item && item.codeValue === code && item.name === name
          );

          return {
            code,
            name,
            dictionaryName:
              dictionaryName === "Service" ? (
                <Trans>Service</Trans>
              ) : (
                <Trans>Service group</Trans>
              ),
            action: isSelected ? (
              <Text fontWeight="700" color="rgba(46, 162, 248, 0.5)">
                <Trans>Chosen</Trans>
              </Text>
            ) : (
              <Link to={`../`} state={data} fontWeight="bold">
                <Trans>Select</Trans>
              </Link>
            )
          };
        }}
      />
      {hasNextPage && (
        <Box mt={4}>
          <Button
            variant="blue"
            onClick={() => {
              setLocationParams({
                ...locationParams,
                servicesTableFirst: String(first + ITEMS_PER_PAGE[0])
              });
            }}
          >
            <Trans>Load more</Trans>
          </Button>
        </Box>
      )}
    </>
  );
};
