import React from "react";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";

import { Dictionary } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

const Dictionaries: any = {
  "eHealth/ICD10_AM/condition_codes": "Довідник діагнозів ICD10AM",
  "eHealth/ICPC2/condition_codes": "Довідник діагнозів ICPC2"
};

const PrimarySearchFields = () => (
  <Flex mx={-1} flexWrap="wrap">
    <Box px={1} width={1 / 4}>
      <Trans
        id="Enter group name"
        render={({ translation }) => (
          <Field.Text
            name="filter.name"
            label={<Trans id="Find group" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <Trans
        id="Diagnoses group ID"
        render={({ translation }) => (
          <Field.Text
            name="filter.databaseId"
            label={<Trans id="Group ID" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <DictionaryValue
        name="DIAGNOSES_GROUP_CODES"
        render={(dict: Dictionary["values"]) => (
          <Trans
            id="Choose diagnose group code"
            render={({ translation }) => (
              <Field.Select
                variant="select"
                name="filter.code"
                label={<Trans id="Group code" />}
                placeholder={translation}
                itemToString={(item: string) =>
                  (dict[item] && `${item} - ${dict[item]}`) || translation
                }
                filterOptions={{ keys: [(item: string) => item] }}
                items={dict ? Object.keys(dict) : []}
                emptyOption
              />
            )}
          />
        )}
      />
    </Box>
    <Box width={1 / 4} px={1}>
      <SearchField.Status
        name="filter.isActive"
        status="ACTIVE_STATUS_F"
        label={<Trans id="Status group" />}
      />
    </Box>
  </Flex>
);

const SecondarySearchFields = () => (
  <Flex mx={-1} flexWrap="wrap">
    <Box px={1} width={1 / 4}>
      <Trans
        id="Enter diagnose code"
        render={({ translation }) => (
          <Field.Text
            name="filter.diagnosesGroupCode.code"
            label={<Trans id="Diagnose code search" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <Trans
        id="All dictionaries"
        render={({ translation }) => (
          <Field.Select
            name="filter.diagnosesGroupCode.system"
            label={<Trans id="Dictionary" />}
            placeholder={translation}
            items={Object.keys(Dictionaries)}
            itemToString={(item: string) => Dictionaries[item] || translation}
            variant="select"
            emptyOption
          />
        )}
      />
    </Box>
  </Flex>
);

export { PrimarySearchFields, SecondarySearchFields };
