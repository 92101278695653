import React from "react";
import { Router, Link, RouteComponentProps } from "@reach/router";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { Flex, Box } from "@rebass/emotion";
import { loader } from "graphql.macro";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import isEmpty from "lodash/isEmpty";

import {
  LocationParams,
  Form,
  Validation,
  Validations
} from "@edenlabllc/ehealth-components";
import system from "@edenlabllc/ehealth-system-components";
import { getFullName } from "@edenlabllc/ehealth-utils";
import { SearchIcon } from "@edenlabllc/ehealth-icons";
import {
  Dictionary,
  Employee,
  EmployeeConnection,
  CapitationContractRequest,
  LegalEntityStatus,
  UpdateContractRequestInput
} from "@ehealth/ehealth-ua.schema";

import Badge from "../../../../../components/Badge";
import Button from "../../../../../components/Button";
import DefinitionListView from "../../../../../components/DefinitionListView";
import DictionaryValue from "../../../../../components/DictionaryValue";
import * as Field from "../../../../../components/Field/index";
import InfoBox from "../../../../../components/InfoBox";
import LinkComponent from "../../../../../components/Link";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import Steps from "../../../../../components/Steps";
import {
  SearchParams,
  SetSearchParams,
  TLocationParams
} from "../../../../../components/SearchForm";

import { CapitationContractRequestQuery } from "../";

const EmployeesQuery = loader(
  "../../../../../graphql/GetAssignEmployeeQuery.graphql"
);
const UpdateContractRequestMutation = loader(
  "../../../../../graphql/UpdateContractRequestMutation.graphql"
);

type UpdateProps = RouteComponentProps<{
  id: string;
}>;

const Update = ({ id }: UpdateProps) => (
  <>
    <Box pt={5} px={5}>
      <Steps.List>
        <Steps.Item to="./">
          <Trans>Fill in fields</Trans>
        </Steps.Item>
        <Steps.Item to="../approve">
          <Trans>Confirm with EDS</Trans>
        </Steps.Item>
      </Steps.List>
    </Box>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Query query={CapitationContractRequestQuery} variables={{ id }}>
          {({
            loading,
            data
          }: QueryResult<{
            capitationContractRequest: CapitationContractRequest;
          }>) => {
            if (isEmpty(data) || isEmpty(data.capitationContractRequest))
              return null;
            const {
              status,
              databaseId,
              contractorLegalEntity: {
                id: legalEntityId,
                databaseId: legalEntityDatabaseId,
                name,
                edrpou,
                status: contractorLegalEntityStatus
              },
              issueCity,
              nhsContractPrice,
              nhsPaymentMethod,
              nhsSignerBase,
              nhsSigner,
              miscellaneous
            } = data.capitationContractRequest;

            return (
              <LoadingOverlay loading={loading}>
                <OpacityBox m={5}>
                  <DefinitionListView
                    labels={{
                      databaseId: <Trans>Contract request ID</Trans>,
                      status: <Trans>Status</Trans>,
                      edrpou: <Trans>EDRPOU</Trans>,
                      name: <Trans>Name</Trans>,
                      legalEntityDatabaseId: <Trans>Legal entity ID</Trans>
                    }}
                    data={{
                      databaseId,
                      status: (
                        <Badge
                          name={status}
                          type="CONTRACT_REQUEST"
                          minWidth={100}
                        />
                      ),
                      edrpou,
                      name,
                      legalEntityDatabaseId: (
                        <LinkComponent to={`/legal-entities/${legalEntityId}`}>
                          {legalEntityDatabaseId}
                        </LinkComponent>
                      )
                    }}
                    color="#7F8FA4"
                    labelWidth="120px"
                  />
                </OpacityBox>
                <Router>
                  <UpdateContractRequest
                    path="/"
                    initialValues={{
                      nhsSignerBase,
                      issueCity,
                      nhsContractPrice,
                      nhsPaymentMethod,
                      nhsSigner,
                      miscellaneous
                    }}
                    locationParams={locationParams}
                    onSubmit={setLocationParams}
                    data={data.capitationContractRequest}
                    contractorLegalEntityStatus={contractorLegalEntityStatus}
                  />
                </Router>
              </LoadingOverlay>
            );
          }}
        </Query>
      )}
    </LocationParams>
  </>
);

type UpdateContractRequestProps = RouteComponentProps<{
  initialValues: Partial<CapitationContractRequest>;
  id: string;
  contractorLegalEntityStatus: LegalEntityStatus;
  locationParams: SearchParams;
  onSubmit: SetSearchParams;
  data: CapitationContractRequest;
}>;

const UpdateContractRequest = ({
  initialValues = {},
  navigate,
  id,
  contractorLegalEntityStatus
}: UpdateContractRequestProps) => {
  const { i18n } = useLingui();
  const { nhsSignerBase, issueCity, nhsContractPrice } = initialValues;
  const isUpdateDisabled = contractorLegalEntityStatus !== "ACTIVE";

  return (
    <Box m={5}>
      <Query
        query={EmployeesQuery}
        variables={{
          first: 50,
          filter: {
            employeeType: ["NHS ADMIN SIGNER"],
            status: "APPROVED"
          },
          orderBy: "INSERTED_AT_DESC"
        }}
      >
        {({ data }: QueryResult<{ employees: EmployeeConnection }>) => {
          const employees = data && data.employees ? data.employees.nodes : [];

          return (
            <Mutation
              mutation={UpdateContractRequestMutation}
              refetchQueries={() => [
                {
                  query: CapitationContractRequestQuery,
                  variables: { id }
                }
              ]}
            >
              {(updateContractRequest: MutationFunction) => (
                <Form
                  onSubmit={async (
                    data: UpdateContractRequestInput & {
                      nhsSignerId: Employee;
                      nhsContractPrice: string;
                    }
                  ) => {
                    const {
                      nhsSignerId,
                      nhsContractPrice,
                      miscellaneous,
                      nhsPaymentMethod,
                      issueCity,
                      nhsSignerBase
                    } = data;
                    await updateContractRequest({
                      variables: {
                        input: {
                          id,
                          nhsPaymentMethod,
                          issueCity,
                          nhsSignerBase,
                          nhsContractPrice:
                            Math.round(Number(nhsContractPrice) * 100) / 100,
                          nhsSignerId: nhsSignerId ? nhsSignerId.id : undefined,
                          miscellaneous: miscellaneous || ""
                        }
                      }
                    });
                    await navigate!("../approve");
                  }}
                  initialValues={{
                    ...initialValues,
                    nhsSignerBase:
                      nhsSignerBase ||
                      "Положення про Національну службу здоров'я України, затвердженого постановою Кабінету Міністрів України від 27 грудня 2017 року № 1101",
                    issueCity: issueCity || "Київ",
                    nhsContractPrice: nhsContractPrice || 0
                  }}
                >
                  <Flex>
                    <Box mr={5} width={2 / 5}>
                      <Trans
                        id="Enter signer"
                        render={({ translation }) => (
                          <Field.Select
                            name="nhsSignerId"
                            label={
                              <Trans id="Signatory from the Customers side" />
                            }
                            placeholder={translation}
                            items={employees!.map((employee) => {
                              const party = employee && employee.party;
                              const { firstName, secondName, lastName } =
                                party || {};
                              return {
                                party: { firstName, secondName, lastName },
                                id: employee && employee.id
                              };
                            })}
                            itemToString={(item: Employee) =>
                              item && getFullName(item.party)
                            }
                            filterOptions={{
                              keys: ["party.lastName", "party.firstName"]
                            }}
                            iconComponent={SearchIcon}
                          />
                        )}
                      />

                      <Validation.Required
                        field="nhsSignerId"
                        message="Required field"
                      />
                    </Box>
                    <Box width={2 / 5}>
                      <Field.Text
                        name="nhsSignerBase"
                        label={<Trans id="Basis" />}
                        placeholder={i18n._(t`Choose base`)}
                        maxLength={255}
                        showLengthHint
                      />
                      <Validation.Required
                        field="nhsSignerBase"
                        message="Required field"
                      />
                    </Box>
                  </Flex>
                  <Flex>
                    <Box mr={5} width={2 / 5}>
                      <Field.Number
                        name="nhsContractPrice"
                        label={<Trans id="Contract Amount" />}
                        placeholder="0 - 1 000 000"
                        postfix={<Trans>uah</Trans>}
                      />
                      <Validations field="nhsContractPrice">
                        <Validation.Required message="Required field" />
                      </Validations>
                    </Box>
                    <Box width={2 / 5}>
                      <DictionaryValue
                        name="CONTRACT_PAYMENT_METHOD"
                        render={(dict: Dictionary["values"]) => (
                          <Trans
                            id="Choose payment method"
                            render={({ translation }) => (
                              <Field.Select
                                variant="select"
                                name="nhsPaymentMethod"
                                label={<Trans id="Payment method" />}
                                placeholder={translation}
                                itemToString={(item: string) => dict[item]}
                                items={Object.keys(dict)}
                                size="small"
                                sendForm="key"
                                filterOptions={{
                                  keys: [(item: string) => dict[item]]
                                }}
                              />
                            )}
                          />
                        )}
                      />
                      <Validation.Required
                        field="nhsPaymentMethod"
                        message="Required field"
                      />
                    </Box>
                  </Flex>
                  <Box width={2 / 5}>
                    <Field.Text
                      name="issueCity"
                      label={
                        <Trans id="The city of the conclusion of the contract" />
                      }
                      placeholder={i18n._(t`Enter city`)}
                      maxLength={100}
                      showLengthHint
                    />
                    <Validation.Required
                      field="issueCity"
                      message="Required field"
                    />
                  </Box>
                  <Box width={2 / 5}>
                    <Trans
                      id="List the conditions"
                      render={({ translation }) => (
                        <Field.Textarea
                          name="miscellaneous"
                          label={
                            <Trans id="Miscellaneous (depending on the type of medical service)" />
                          }
                          placeholder={translation}
                          rows={5}
                        />
                      )}
                    />
                  </Box>
                  <Flex mt={5}>
                    <Box mr={3}>
                      <Link to="../">
                        <Button variant="blue" width={140}>
                          <Trans>Return</Trans>
                        </Button>
                      </Link>
                    </Box>
                    <Box>
                      <Button
                        variant="green"
                        width={140}
                        disabled={isUpdateDisabled}
                      >
                        <Trans>Refresh</Trans>
                      </Button>
                      {isUpdateDisabled && (
                        <InfoBox>
                          <Trans>
                            It is impossible to update the contract request,
                            because the legal entity is inactive.
                          </Trans>
                        </InfoBox>
                      )}
                    </Box>
                  </Flex>
                </Form>
              )}
            </Mutation>
          );
        }}
      </Query>
    </Box>
  );
};

const OpacityBox = system({ extend: Box, opacity: 0.5 }, "opacity", "space");

export default Update;
