import React from "react";
import { gql } from "graphql-tag";
import { useLingui } from "@lingui/react";
import { Box, Flex } from "@rebass/emotion";

import DefinitionListView from "../../../components/DefinitionListView";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import Period from "./Period";
import { LABEL_WIDTH } from "./Episode";

type IntervalOrOpenDateProps = {
  intervalOrOpenDate: any; // ValueDateTime | TPeriod;
  header: React.ReactNode;
  dateTimePrefix?: React.ReactNode;
};

const IntervalOrOpenDate = ({
  intervalOrOpenDate,
  header,
  dateTimePrefix
}: IntervalOrOpenDateProps) => {
  const { i18n } = useLingui();

  const { start, end, dateTime } = intervalOrOpenDate;

  return (
    <Box mt={4}>
      <DefinitionListView
        fontSize={14}
        labels={{
          intervalOrOpenDate: header
        }}
        data={{
          intervalOrOpenDate: dateTime ? (
            <Flex>
              {dateTimePrefix}
              {dateFormatter(i18n.locale, DATE_TIME_FORMAT, dateTime)}
            </Flex>
          ) : (
            <Period datePeriod={{ start, end }} wrapperProps={{ mt: 0 }} />
          )
        }}
        labelWidth={LABEL_WIDTH}
      />
    </Box>
  );
};

export default IntervalOrOpenDate;

IntervalOrOpenDate.fragments = {
  entry: gql`
    fragment IntervalOrOpenDate on Effective {
      ... on Period {
        end
        start
      }
      ... on ValueDateTime {
        dateTime
      }
    }
  `
};
