import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Validation } from "@edenlabllc/ehealth-components";

import * as Field from "../../../../components/Field";
import * as SearchField from "../../../../components/SearchField";

import STATUSES from "../../../../helpers/statuses";
import { SEARCH_CONTRACT_PATTERN } from "../../../../constants/contracts";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 3}>
      <Trans
        id="EDRPOU or Contract number"
        render={({ translation }) => (
          <Field.Text
            name="filter.searchRequest"
            label={<Trans id="Search contract" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
          />
        )}
      />
      <Validation.Matches
        field="filter.searchRequest"
        options={SEARCH_CONTRACT_PATTERN}
        message="Invalid number"
      />
    </Box>
    <Box px={1} width={1 / 3}>
      <SearchField.LegalEntity
        name="filter.contractorLegalEntity"
        filteredParams={["edrData.name"]}
        additionalFilters={{ type: ["MSP", "MSP_PHARMACY"] }}
      />
    </Box>
    <Box px={1} width={1 / 3}>
      <SearchField.Status
        name="filter.status"
        status="CONTRACT"
        label={<Trans id="Contract status" />}
      />
    </Box>
  </Flex>
);

const SecondarySearchFields = () => (
  <>
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <Field.RangePicker
          rangeNames={["filter.date.startFrom", "filter.date.startTo"]}
          label={<Trans id="Contract start date" />}
        />
      </Box>
      <Box px={1} width={1 / 3}>
        <Field.RangePicker
          rangeNames={["filter.date.endFrom", "filter.date.endTo"]}
          label={<Trans id="Contract end date" />}
        />
      </Box>
    </Flex>
    <Flex mx={-1}>
      <Box width={1 / 3} px={1}>
        <Trans
          id="All contracts"
          render={({ translation }) => (
            <Field.Select
              name="filter.isSuspended"
              label={<Trans id="Suspended" />}
              placeholder={translation}
              items={Object.keys(STATUSES.SUSPENDED)}
              itemToString={(item: boolean) =>
                // @ts-expect-error statuses boolean key
                STATUSES.SUSPENDED[item] || translation
              }
              variant="select"
              emptyOption
              filterOptions={{
                // @ts-expect-error statuses boolean key
                keys: [(item: boolean) => STATUSES.SUSPENDED[item]]
              }}
            />
          )}
        />
      </Box>
      <Box width={1 / 3} px={1}>
        <Trans
          id="All contracts"
          render={({ translation }) => (
            <Field.Select
              name="filter.legalEntityRelation"
              label={<Trans id="Legal entity relation" />}
              placeholder={translation}
              items={Object.keys(STATUSES.LEGAL_ENTITY_RELATION)}
              itemToString={(item: string) =>
                STATUSES.LEGAL_ENTITY_RELATION[item] || translation
              }
              variant="select"
              emptyOption
              filterOptions={{
                keys: [(item: string) => STATUSES.LEGAL_ENTITY_RELATION[item]]
              }}
            />
          )}
        />
      </Box>
    </Flex>
  </>
);

export { PrimarySearchFields, SecondarySearchFields };
