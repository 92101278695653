import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { EmployeeRequestConnection } from "@ehealth/ehealth-ua.schema";

import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import EmployeeRequestsTable from "./EmployeeRequestsTable";
import { PrimarySearchFields } from "./SearchFields";

const Search = (_props: RouteComponentProps) => (
  <Box p={6}>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <>
          <Heading as="h1" fontWeight="normal" mb={60}>
            <Trans>Employee Requests</Trans>
          </Heading>
          <SearchForm
            initialValues={locationParams}
            onSubmit={setLocationParams}
            renderPrimary={PrimarySearchFields}
          />
          <Query
            query={SearchEmployeeRequestsQuery}
            fetchPolicy="network-only"
            variables={filteredLocationParams(locationParams)}
          >
            {({
              loading,
              data
            }: QueryResult<{
              employeeRequests: EmployeeRequestConnection;
            }>) => {
              if (isEmpty(data) || isEmpty(data.employeeRequests)) return null;
              const {
                employeeRequests: { nodes: employeeRequests = [], pageInfo }
              } = data;

              return (
                <LoadingOverlay loading={loading}>
                  <EmployeeRequestsTable
                    locationParams={locationParams}
                    setLocationParams={setLocationParams}
                    employeeRequests={employeeRequests}
                  />
                  <Pagination {...pageInfo} />
                </LoadingOverlay>
              );
            }}
          </Query>
        </>
      )}
    </LocationParams>
  </Box>
);

const SearchEmployeeRequestsQuery = gql`
  query SearchEmployeeRequestsQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: EmployeeRequestFilter
    $orderBy: EmployeeRequestOrderBy
  ) {
    employeeRequests(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        ...EmployeeRequests
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${EmployeeRequestsTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Search;
