import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Text } from "@rebass/emotion";

import system from "@edenlabllc/ehealth-system-components";

type EmptyDataProps = {
  height?: number | string;
  mx?: number;
  my?: number;
};

const EmptyData = ({ height, ...props }: EmptyDataProps) => (
  <WrapperBoxHeight height={height}>
    <Text color="shiningKnight" fontSize={1} mx={6} my={2} {...props}>
      <Trans>No info</Trans>
    </Text>
  </WrapperBoxHeight>
);

const WrapperBoxHeight = system(
  {
    is: Box,
    height: 500
  },
  "height"
);

export default EmptyData;
