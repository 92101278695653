import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { BooleanValue } from "react-values";
import { Flex, Text } from "@rebass/emotion";

import system from "@edenlabllc/ehealth-system-components";
import { filterTableColumn as filterTableDefaultColumn } from "@edenlabllc/ehealth-utils";
import {
  CapitationContractRequest,
  ExternalContractor,
  ExternalContractorDivision
} from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../../components/DictionaryValue";
import EmptyData from "../../../../components/EmptyData";
import Table, {
  TableBodyComponent,
  TableCell,
  TableRow
} from "../../../../components/Table";
import { TableBodyType } from "../../../../components/Table/TableBody";

type ExternalContractorsProps = RouteComponentProps & {
  externalContractors: CapitationContractRequest["externalContractors"];
};

const ExternalContractors = ({
  externalContractors
}: ExternalContractorsProps) =>
  externalContractors && externalContractors.length > 0 ? (
    <>
      <Text px={6} pt={2} fontSize={1}>
        <Trans>To see the services, click on "Show division"</Trans>
      </Text>
      <ExternalContractorsTable data={externalContractors} />
    </>
  ) : (
    <EmptyData />
  );

type ExternalContractorsTableProps = RouteComponentProps & {
  data: CapitationContractRequest["externalContractors"];
};

const ExternalContractorsTable = ({ data }: ExternalContractorsTableProps) => (
  <Table
    data={data}
    header={{
      name: <Trans>Legal entity</Trans>,
      divisions: <Trans>Division and Services</Trans>,
      number: <Trans>Contract Number</Trans>,
      issuedAt: <Trans>Contract start date</Trans>,
      expiresAt: <Trans>Contract end date</Trans>
    }}
    tableName="/capitation-contract-requests/external-contractors"
    tableBody={({
      columns,
      data,
      rowKeyExtractor,
      columnKeyExtractor,
      filterTableColumn = filterTableDefaultColumn,
      filterRow
    }: TableBodyType) => {
      const renderRow = (
        {
          legalEntity: { name },
          contract: { number, issuedAt, expiresAt },
          divisions
        }: ExternalContractor,
        onClick: () => void
      ) => ({
        name,
        number,
        issuedAt,
        expiresAt,
        divisions: (
          <Wrapper onClick={onClick}>
            <Text color="rockmanBlue" fontWeight="bold" mr={1} fontSize={0}>
              <Trans>Show division</Trans>({divisions.length})
            </Text>
          </Wrapper>
        )
      });

      return (
        <TableBodyComponent>
          {data.map((item: $TSFixMe, index: number) => (
            <BooleanValue>
              {({ value: opened, toggle }: $TSFixMe) => {
                const row: $TSFixMe = renderRow(item, toggle);
                return (
                  <>
                    <TableRow key={rowKeyExtractor(item, index)}>
                      {columns
                        .filter((bodyName: string) =>
                          filterTableColumn(filterRow, bodyName)
                        )
                        .map((name: string, index: number) => (
                          <TableCell key={columnKeyExtractor(name, index)}>
                            {row[name]}
                          </TableCell>
                        ))}
                    </TableRow>
                    {opened && (
                      <TableRow
                        key={`row_${rowKeyExtractor(item, index)}`}
                        // @ts-expect-error TS(2322): Type '{ children: Element; key: string; fullSize: ... Remove this comment to see the full error message
                        fullSize
                      >
                        <TableCell
                          key={`cell_${rowKeyExtractor(item, index)}`}
                          colSpan={
                            columns.filter((bodyName: string) =>
                              filterTableColumn(filterRow, bodyName)
                            ).length
                          }
                          fullSize
                        >
                          <Table
                            data={item.divisions}
                            header={{
                              name: "",
                              medicalService: ""
                            }}
                            renderRow={({
                              division: { name },
                              medicalService
                            }: ExternalContractorDivision) => ({
                              name,
                              medicalService: (
                                <DictionaryValue
                                  name="MEDICAL_SERVICE"
                                  item={medicalService}
                                />
                              )
                            })}
                            tableName="/capitation-contract-requests/ExternalContractorsTable"
                            headless
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                );
              }}
            </BooleanValue>
          ))}
        </TableBodyComponent>
      );
    }}
  />
);

const Wrapper = system(
  {
    extend: Flex
  },
  { cursor: "pointer" },
  "color"
);

ExternalContractors.fragments = {
  entry: gql`
    fragment ExternalContractors on CapitationContractRequest {
      externalContractors {
        legalEntity {
          id
          name
        }
        contract {
          number
          issuedAt
          expiresAt
        }
        divisions {
          division {
            id
            name
          }
          medicalService
        }
      }
    }
  `
};

export default ExternalContractors;
