import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";

import Search from "./Search";
import Details from "./Details";
import AddAuthMethod from "./Details/Mutations/AddAuthMethod";
import DeactivatePerson from "./Details/Mutations/DeactivatePerson";
import EditPerson from "./Search/Mutations/EditPerson";
import PracticalMonitoring from "../PracticalMonitoring";
import { ProcessDracs } from "./Details/ProcessDracs";
import PersonVerificationDataHistory from "./Details/PersonVerificationDataHistory";

const Persons = ({ uri }: RouteComponentProps) => (
  <Router>
    <Redirect from="/" to={`${uri}/search`} />
    <EditPerson path=":id/edit-person/*" />
    <Search path="search/*" />
    <DeactivatePerson path=":id/deactivate-person/*" />
    <PracticalMonitoring path=":id/practical-monitoring/*" />
    <AddAuthMethod path=":id/auth/add-auth-method/*" />
    <Details path=":id/*" />
    <ProcessDracs path=":id/verification/process-dracs/*" />
    <PersonVerificationDataHistory path=":id/verification/history" />
  </Router>
);

export default Persons;
