import React from "react";
import { RouteComponentProps } from "@reach/router";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { Flex } from "@rebass/emotion";

import Table from "../../../components/Table";
import DictionaryValue from "../../../components/DictionaryValue";

import dateFormatter from "../../../helpers/dateFormatter";

type IntegrationStatusTableProps = RouteComponentProps<{
  items: $TSFixMe;
}>;

const IntegrationStatusTable = ({ items }: IntegrationStatusTableProps) => {
  return (
    <Flex
      flexDirection={"column"}
      style={{ position: "relative", minHeight: "100px" }}
      p={5}
    >
      <Table
        data={items}
        header={{
          component: <Trans>Component</Trans>,
          type: <Trans>Task type</Trans>,
          integrationStatus: <Trans>Integration status</Trans>,
          statusCode: <Trans>Integration result</Trans>,
          statusMessage: <Trans>Integration message</Trans>,
          taskStatus: <Trans>Job status</Trans>,
          updatedAt: <Trans>Integration update date</Trans>,
          personId: <Trans>Integration child identifier</Trans>,
          SL_NUM: <Trans>ELN number</Trans>,
          SL_REG_DATETIME: <Trans>ELN registration date</Trans>,
          SL_START: <Trans>ELN open date</Trans>,
          SL_STOP: <Trans>ELN close date</Trans>,
          IC_NUM: (
            <Flex style={{ wordBreak: "break-word" }}>
              <Trans>Disability case number</Trans>
            </Flex>
          )
        }}
        renderRow={({
          integrationStatus,
          statusCode,
          statusMessage,
          taskStatus,
          type,
          updatedAt,
          component,
          details: {
            SL_NUM,
            SL_REG_DATETIME,
            SL_START,
            SL_STOP,
            IC_NUM,
            personId,
            ...other
          }
        }) => ({
          component: (
            <DictionaryValue
              name="COMPOSITION_PROCESSING_SYSTEMS"
              item={component}
            />
          ),
          type: (
            <DictionaryValue name="COMPOSITION_PROCESSING_TASKS" item={type} />
          ),
          integrationStatus: (
            <DictionaryValue
              name="COMPOSITION_PROCESSING_STATUS"
              item={integrationStatus}
            />
          ),
          statusCode,
          statusMessage,
          taskStatus: (
            <DictionaryValue
              name="COMPOSITION_PROCESSING_TASK_STATUS"
              item={taskStatus}
            />
          ),
          updatedAt: dateFormatter(
            i18n.locale,
            {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric"
            },
            updatedAt
          ),
          personId,
          SL_NUM,
          SL_REG_DATETIME: dateFormatter(
            i18n.locale,
            {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric"
            },
            SL_REG_DATETIME
          ),
          SL_START: dateFormatter(
            i18n.locale,
            {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric"
            },
            SL_START
          ),
          SL_STOP: dateFormatter(
            i18n.locale,
            {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric"
            },
            SL_STOP
          ),
          IC_NUM,
          ...other
        })}
        sortableFields={[]}
        // sortingParams={[]}
        whiteSpaceNoWrap={[]}
        hidePagination={true}
        tableName="medicalComposition/integration"
        hiddenFields=""
      />
    </Flex>
  );
};

export default IntegrationStatusTable;
