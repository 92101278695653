import React from "react";
import { Flex, Box } from "@rebass/emotion";

import { Maybe, PersonAuthenticationMethod } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "./DictionaryValue";

type AuthMethodsListProps = {
  data: Maybe<PersonAuthenticationMethod>[];
};

const AuthMethodsList = ({ data }: AuthMethodsListProps) => (
  <Flex as="ul" flexDirection="column">
    {data.map((method: Maybe<PersonAuthenticationMethod>, idx: number) => (
      <Box
        key={idx}
        as="li"
        mb={1}
        css={{ "&:last-child": { marginBottom: 0 } }}
      >
        {method && method.type !== "NA" ? (
          <>
            <div>
              <DictionaryValue
                name="AUTHENTICATION_METHOD"
                item={method.type}
              />
            </div>
            {method && method.phoneNumber && <div>{method.phoneNumber}</div>}
          </>
        ) : (
          "—"
        )}
      </Box>
    ))}
  </Flex>
);

export default AuthMethodsList;
