import React from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction, MutationResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Heading, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  Form,
  LocationParams,
  SUBMIT_ERROR
} from "@edenlabllc/ehealth-components";

import Popup from "../../../components/Popup";
import { TLocationParams } from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";
import { defaultError } from "../../../constants/submitErrors";

import { PersonQuery } from "../../Persons/Details";

type VerifyProps = {
  isVisible: boolean;
  onCancel: () => void;
  personId?: string;
};

const Verify = ({ isVisible, onCancel, personId }: VerifyProps) => {
  const { i18n } = useLingui();
  return (
    <LocationParams>
      {({ locationParams }: TLocationParams) => (
        <Popup
          visible={isVisible}
          okText={<Trans>Verify</Trans>}
          onCancel={onCancel}
          formId="verifyPerson"
          okButtonProps={{ variant: "green", width: 220 }}
          cancelButtonProps={{ variant: "blue", width: 220 }}
        >
          <Mutation
            mutation={UpdatePersonManualRulesVerificationStatusMutation}
            refetchQueries={() => [
              {
                query: PersonQuery,
                variables: {
                  id: personId,
                  ...pagination(locationParams)
                }
              }
            ]}
          >
            {(
              updatePersonManualRulesVerificationStatus: MutationFunction,
              { error }: MutationResult
            ) => (
              <Form
                onSubmit={async () => {
                  const {
                    data
                  } = await updatePersonManualRulesVerificationStatus({
                    variables: {
                      input: {
                        personId,
                        manualRulesVerificationStatus: "VERIFIED"
                      }
                    }
                  });

                  if (
                    error ||
                    isEmpty(data.updatePersonManualRulesVerificationStatus)
                  ) {
                    return { [SUBMIT_ERROR]: defaultError };
                  }

                  onCancel();
                }}
                id="verifyPerson"
              >
                <Heading as="h1" fontWeight="normal" mb={6}>
                  <Trans>Verification of Patient's personal data</Trans>
                </Heading>
                <Text mb={6}>
                  <Trans>
                    Warning! <br />
                    After setting of verification status to "Verified", this
                    action cannot be reversed
                  </Trans>
                </Text>
                <Flex justifyContent="center">
                  <Form.Error
                    default={i18n._(
                      t`Something went wrong. Please try again later`
                    )}
                  />
                </Flex>
              </Form>
            )}
          </Mutation>
        </Popup>
      )}
    </LocationParams>
  );
};

export default Verify;

export const UpdatePersonManualRulesVerificationStatusMutation = gql`
  mutation UpdatePersonManualRulesVerificationStatus(
    $input: UpdatePersonManualRulesVerificationStatusInput!
  ) {
    updatePersonManualRulesVerificationStatus(input: $input) {
      person {
        id
        databaseId
        verificationStatus
        verificationComment
      }
    }
  }
`;
