import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Box } from "@rebass/emotion";
import { get, isEmpty } from "lodash";

import { Division } from "@ehealth/ehealth-ua.schema";

import LoadingOverlay from "../../../components/LoadingOverlay";

import paramToBase64 from "../../../helpers/paramToBase64";

import Reference from "./Reference";

type DivisionReferenceProps = {
  reference: any;
  divisionFieldPath: string;
  header: React.ReactNode;
};

const DivisionReference = ({
  reference,
  divisionFieldPath,
  header
}: DivisionReferenceProps) => {
  const division = get(reference, divisionFieldPath);
  if (isEmpty(reference) || isEmpty(division)) return null;

  return (
    <Query
      query={DivisionQuery}
      fetchPolicy="network-only"
      variables={{
        id: paramToBase64("Division", division.identifier.value)
      }}
    >
      {({
        data,
        loading: divisionLoading
      }: QueryResult<{ division: Division }>) => {
        const { division: foundDivision } = data || {};
        if (isEmpty(foundDivision)) return null;

        return (
          <LoadingOverlay loading={divisionLoading}>
            <Box mt={2}>
              <Reference
                fontSize={14}
                header={header}
                linkPath={`/legal-entities/${
                  foundDivision.legalEntity.id
                }/divisions/${paramToBase64(
                  "Division",
                  division.identifier.value
                )}`}
                linkDisplayValue={
                  foundDivision.name || division.identifier.value
                }
                linkWrapperProps={{
                  mt: 2,
                  ml: 0
                }}
              />
            </Box>
          </LoadingOverlay>
        );
      }}
    </Query>
  );
};

export default DivisionReference;

export const DivisionQuery = gql`
  query DivisionQuery($id: ID!) {
    division(id: $id) {
      id
      databaseId
      name
      legalEntity {
        id
        databaseId
      }
    }
  }
`;
