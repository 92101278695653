import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Query } from "@apollo/client/react/components";
import { gql } from "graphql-tag";
import { DocumentNode, QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Switch } from "@edenlabllc/ehealth-components";
import {
  Activity as TActivity,
  Detail,
  Period as TPeriod,
  Reference as TReference,
  Timing as TTiming,
  ValueString as TValueString
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../../components/Ability";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import CodeableConcept from "../../../../components/CodeableConcept";
import Collapsable from "../../../../components/Collapsable";
import DefinitionListView from "../../../../components/DefinitionListView";
import Line from "../../../../components/Line";
import LoadingOverlay from "../../../../components/LoadingOverlay";

import dateFormatter from "../../../../helpers/dateFormatter";
import fromBase64toUUID from "../../../../helpers/fromBase64toUUID";
import paramToBase64 from "../../../../helpers/paramToBase64";
import STATUSES from "../../../../helpers/statuses";
import { DATE_TIME_FORMAT } from "../../../../constants/dateFormats";

import CodeableConceptList from "../CodeableConceptList";
import DivisionReference from "../DivisionReference";
import Period from "../Period";
import Reference from "../Reference";
import ReasonReferences from "../ReasonReferences";
import ValueString from "../ValueString";
import { ACTIVITY_STATUSES, ACTIVITY_KINDS } from "./ActivitiesTab";
import { LABEL_WIDTH } from "../Episode";
import { SimpleQuantity } from "../SimpleQuantity";
import { Timing } from "../Timing";
import ServiceRequestCode from "../ServiceRequestCode";
import OutcomeReferences from "../OutcomeReferences";

const DEFAULT_REFERENCE_PROPS = {
  linkWrapperProps: {
    mt: 0,
    ml: 0
  }
};

export type ActivityProps = RouteComponentProps<{
  queryVariables: {
    personId?: string;
    skipAdditionalFields?: boolean;
  };
  itemId: string;
  id: string;
  patientId: string;
  activityId: string;
  query?: DocumentNode;
  isClinicalMonitoring?: boolean;
}>;

export const Activity = ({
  itemId,
  id,
  activityId,
  query = ActivityByIDQuery,
  patientId,
  isClinicalMonitoring
}: ActivityProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="care_plan"
  >
    <Box p={6}>
      <Box mb={10}>
        <Breadcrumbs.List>
          {isClinicalMonitoring ? (
            <>
              <Breadcrumbs.Item to="/clinical-monitoring">
                <Trans>Clinical monitoring</Trans>
              </Breadcrumbs.Item>
              <Breadcrumbs.Item
                to={`/clinical-monitoring/care-plan/${patientId}/${itemId}/`}
              >
                <Trans>Care plan</Trans>
              </Breadcrumbs.Item>
            </>
          ) : (
            <>
              <Breadcrumbs.Item to="/persons">
                <Trans>Patient Search</Trans>
              </Breadcrumbs.Item>
              <Breadcrumbs.Item to={`/persons/${id}`}>
                <Trans>Details of the patient</Trans>
              </Breadcrumbs.Item>
              <Breadcrumbs.Item to={`/persons/${id}/practical-monitoring`}>
                <Trans>Practical monitoring</Trans>
              </Breadcrumbs.Item>
              <Breadcrumbs.Item
                to={`/persons/${id}/practical-monitoring/care-plan/${itemId}/`}
              >
                <Trans>Care plan</Trans>
              </Breadcrumbs.Item>
            </>
          )}
          <Breadcrumbs.Item>
            <Trans>Activity</Trans>
          </Breadcrumbs.Item>
        </Breadcrumbs.List>
      </Box>
      <Query
        query={query}
        fetchPolicy="network-only"
        variables={{
          ...(isClinicalMonitoring ? { patientId } : { personId: id }),
          id: activityId,
          carePlanId: itemId
        }}
      >
        {({
          data = {},
          loading,
          error
        }: QueryResult<{ [key: string]: TActivity }>) => {
          const activity = isClinicalMonitoring
            ? data.impersonalActivity
            : data.activity;

          return (
            <LoadingOverlay loading={loading}>
              <Box mt={4}>
                {!isEmpty(data) && !isEmpty(activity) && !error && (
                  <>
                    <HeaderDefinitions
                      activity={activity}
                      carePlanId={itemId!}
                    />
                    <Box mt={2}>
                      <Reference
                        fontSize={14}
                        header={<Trans>Author</Trans>}
                        linkPath={`/employees/${paramToBase64(
                          "Employee",
                          activity.author.identifier.value
                        )}`}
                        linkDisplayValue={
                          activity.author.displayValue
                            ? activity.author.displayValue
                            : activity.author.identifier.value
                        }
                        {...DEFAULT_REFERENCE_PROPS}
                      />
                    </Box>
                    <Box mt={4}>
                      <TopBodyDefinitions activity={activity} />
                      {!isEmpty(activity.detail.reasonReference) && (
                        <Box mb={4}>
                          <Collapsable
                            title={
                              <Text color="darkCharcoal">
                                <Trans id="Activity reason reference">
                                  Reason reference
                                </Trans>
                              </Text>
                            }
                          >
                            <ReasonReferences
                              reasonReferences={activity.detail.reasonReference}
                              handleSetReferenceLink={(
                                memoizedReferenceType: string,
                                memoizedReferenceSlug: string,
                                reference: TReference
                              ) =>
                                isClinicalMonitoring
                                  ? `../../../../../${memoizedReferenceSlug}/${patientId}/${paramToBase64(
                                      memoizedReferenceType,
                                      reference.identifier.value
                                    )}`
                                  : `../../../../${memoizedReferenceSlug}/${paramToBase64(
                                      memoizedReferenceType,
                                      reference.identifier.value
                                    )}`
                              }
                            />
                          </Collapsable>
                        </Box>
                      )}
                      <ServiceRequestCode
                        serviceRequestCode={activity.detail.productReference}
                      />
                      <Box mt={4}>
                        <MiddleBodyDefinitions activity={activity} />
                      </Box>
                      <DivisionReference
                        reference={activity}
                        divisionFieldPath="detail.location"
                        header={<Trans>Location</Trans>}
                      />
                      <Line />
                      <Scheduled scheduled={activity.detail.scheduled} />
                      {!isEmpty(activity.detail.performer) && (
                        <Box>
                          <Reference
                            fontSize={14}
                            header={<Trans>Performer</Trans>}
                            linkPath={`/employees/${paramToBase64(
                              "Employee",
                              activity.detail.performer.identifier.value
                            )}`}
                            linkDisplayValue={
                              activity.detail.performer.displayValue
                                ? activity.detail.performer.displayValue
                                : activity.detail.performer.identifier.value
                            }
                          />
                        </Box>
                      )}

                      {!isEmpty(activity.outcomeReference) && (
                        <Box mb={4}>
                          <Collapsable
                            title={
                              <Text color="darkCharcoal">
                                <Trans>Outcome reference</Trans>
                              </Text>
                            }
                          >
                            <OutcomeReferences
                              outcomeReferences={activity.outcomeReference}
                              handleSetReferenceLink={(
                                memoizedReferenceType: string,
                                memoizedReferenceSlug: string,
                                reference: TReference
                              ) =>
                                isClinicalMonitoring
                                  ? `../../../../../${memoizedReferenceSlug}/${patientId}/${paramToBase64(
                                      memoizedReferenceType,
                                      reference.identifier.value
                                    )}`
                                  : `../../../../${memoizedReferenceSlug}/${paramToBase64(
                                      memoizedReferenceType,
                                      reference.identifier.value
                                    )}`
                              }
                            />
                          </Collapsable>
                        </Box>
                      )}

                      <Box mt={4}>
                        <BottomBodyDefinitions activity={activity} />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </LoadingOverlay>
          );
        }}
      </Query>
    </Box>
  </Ability>
);

type HeaderDefinitionsProps = {
  activity: TActivity;
  carePlanId: string;
};

const HeaderDefinitions = ({
  activity,
  carePlanId
}: HeaderDefinitionsProps) => {
  const { i18n } = useLingui();
  if (isEmpty(activity)) return null;

  return (
    <>
      <DefinitionListView
        labels={{
          databaseId: <Trans>ID</Trans>,
          carePlanId: <Trans>Care plan ID</Trans>,
          status: <Trans>Status</Trans>,
          ...(!isEmpty(activity.detail.statusReason) && {
            statusReason: <Trans>Reason status</Trans>
          })
        }}
        data={{
          ...activity,
          carePlanId: fromBase64toUUID(carePlanId),
          status: i18n._(ACTIVITY_STATUSES[activity.detail.status]),
          statusReason: (
            <CodeableConcept codeableConcept={activity.detail.statusReason} />
          )
        }}
        color="#7F8FA4"
        labelWidth={LABEL_WIDTH}
      />
      <Line />
    </>
  );
};

const TopBodyDefinitions = ({ activity }: { activity: TActivity }) => {
  const { i18n } = useLingui();
  if (isEmpty(activity)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labelWidth={LABEL_WIDTH}
      labels={{
        kind: <Trans>Kind</Trans>,
        doNotPerform: <Trans>Do not perform</Trans>,
        ...(!isEmpty(activity.detail.goal) && { goal: <Trans>Goal</Trans> }),
        ...(!isEmpty(activity.detail.reasonCode) && {
          reasonCode: <Trans>Reason code</Trans>
        })
      }}
      data={{
        ...activity,
        kind: i18n._(ACTIVITY_KINDS[activity.detail.kind]),
        // @ts-expect-error statuses boolean key
        doNotPerform: STATUSES.YES_NO[activity.detail.doNotPerform],
        ...(!isEmpty(activity.detail.goal) && {
          goal: (
            <CodeableConceptList codeableConceptList={activity.detail.goal} />
          )
        }),
        ...(!isEmpty(activity.detail.reasonCode) && {
          reasonCode: (
            <CodeableConceptList
              codeableConceptList={activity.detail.reasonCode}
            />
          )
        })
      }}
    />
  );
};

const MiddleBodyDefinitions = ({ activity }: { activity: TActivity }) => {
  if (isEmpty(activity)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labelWidth={LABEL_WIDTH}
      labels={{
        ...(!isEmpty(activity.detail.program) && {
          program: <Trans>Program</Trans>
        }),
        ...(!isEmpty(activity.detail.quantity) && {
          quantity: <Trans>Quantity</Trans>
        }),
        ...(!isEmpty(activity.detail.remainingQuantity) && {
          remainingQuantity: <Trans>Remaining quantity</Trans>
        }),
        ...(!isEmpty(activity.detail.dailyAmount) && {
          dailyAmount: <Trans>Daily amount</Trans>
        })
      }}
      data={{
        ...(!isEmpty(activity.detail.program) && {
          program: (
            <Box mt={-10}>
              <Reference
                fontSize={14}
                headless
                linkPath={`/medical-programs/${activity.detail.program.id}`}
                linkDisplayValue={activity.detail.program.name}
              />
            </Box>
          ),
          ...(!isEmpty(activity.detail.quantity) && {
            quantity: (
              <SimpleQuantity simpleQuantity={activity.detail.quantity} />
            )
          }),
          ...(!isEmpty(activity.detail.remainingQuantity) && {
            remainingQuantity: (
              <SimpleQuantity
                simpleQuantity={activity.detail.remainingQuantity}
              />
            ),
            ...(!isEmpty(activity.detail.dailyAmount) && {
              dailyAmount: (
                <SimpleQuantity simpleQuantity={activity.detail.dailyAmount} />
              )
            })
          })
        })
      }}
    />
  );
};

const BottomBodyDefinitions = ({ activity }: { activity: TActivity }) => {
  const { i18n } = useLingui();
  if (isEmpty(activity)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labelWidth={LABEL_WIDTH}
      labels={{
        ...(activity.detail.description && {
          description: <Trans>Description</Trans>
        }),
        ...(!isEmpty(activity.outcomeCodeableConcept) && {
          outcomeCodeableConcept: <Trans>Outcome codeable concept</Trans>
        }),
        insertedAt: <Trans>Inserted at</Trans>,
        updatedAt: <Trans>Updated at</Trans>
      }}
      data={{
        ...activity,
        description: activity.detail.description,
        ...(!isEmpty(activity.outcomeCodeableConcept) && {
          outcomeCodeableConcept: (
            <CodeableConceptList
              codeableConceptList={activity.outcomeCodeableConcept}
            />
          )
        }),
        insertedAt: dateFormatter(
          i18n.locale,
          DATE_TIME_FORMAT,
          activity.insertedAt
        ),
        updatedAt: dateFormatter(
          i18n.locale,
          DATE_TIME_FORMAT,
          activity.updatedAt
        )
      }}
    />
  );
};

const Scheduled = ({ scheduled }: { scheduled: Detail["scheduled"] }) => {
  if (isEmpty(scheduled)) return null;
  const { __typename } = scheduled;

  return (
    <Box>
      <Text color="darkCharcoal" fontSize={14} fontWeight="bold">
        <Trans>Scheduled</Trans>
      </Text>
      <Box mt={4}>
        <Switch
          value={__typename}
          Timing={
            <Box mb={2}>
              <Timing timing={scheduled as TTiming} />
            </Box>
          }
          ValueString={
            <DefinitionListView
              fontSize={14}
              labelWidth={LABEL_WIDTH}
              labels={{
                string: <Trans>String</Trans>
              }}
              data={{
                string: (
                  <ValueString
                    valueString={(scheduled as TValueString).string}
                  />
                )
              }}
            />
          }
          Period={
            <DefinitionListView
              fontSize={14}
              labelWidth={LABEL_WIDTH}
              labels={{
                period: <Trans>Period</Trans>
              }}
              data={{
                period: (
                  <Period
                    datePeriod={scheduled as TPeriod}
                    wrapperProps={{ mt: 0 }}
                  />
                )
              }}
            />
          }
        />
      </Box>
      <Line />
    </Box>
  );
};

Scheduled.fragments = {
  entry: gql`
    fragment Scheduled on Scheduled {
      ... on Period {
        ...Period
      }
      ... on ValueString {
        ...ValueString
      }
      ... on Timing {
        ...Timing
      }
    }
    ${Period.fragments!.entry}
    ${ValueString.fragments.entry}
    ${Timing.fragments.entry}
  `
};

Activity.fragments = {
  entry: gql`
    fragment Activity on Activity {
      id
      databaseId
      author {
        ...Reference
      }
      insertedAt
      updatedAt
      detail {
        status
        statusReason {
          ...CodeableConcept
        }
        kind
        doNotPerform
        goal {
          ...CodeableConcept
        }
        reasonCode {
          ...CodeableConcept
        }
        reasonReference {
          ...Reference
        }
        productReference {
          ...ActivityProduct
        }
        program {
          id
          name
        }
        quantity {
          ...SimpleQuantity
        }
        remainingQuantity {
          ...SimpleQuantity
        }
        dailyAmount {
          ...SimpleQuantity
        }
        scheduled {
          ...Scheduled
        }
        location {
          ...Reference
        }
        performer {
          ...Reference
        }
        description
      }
    }
    ${Reference.fragments.entry}
    ${CodeableConcept.fragments!.entry}
    ${SimpleQuantity.fragments.entry}
    ${Scheduled.fragments.entry}
    ${ServiceRequestCode.fragments.entryWithINNMDosage}
  `
};

export const ActivityByIDQuery: DocumentNode = gql`
  query ActivityByIDQuery($id: ID!, $personId: ID!, $carePlanId: ID!) {
    activity(id: $id, personId: $personId, carePlanId: $carePlanId) {
      ...Activity
    }
  }
  ${Activity.fragments.entry}
`;
