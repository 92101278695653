import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { useLazyQuery, MutationFunction } from "@apollo/client";
import { DocumentNode } from "@apollo/client";
import Composer from "react-composer";
import { Trans } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";
import { Box, Flex } from "@rebass/emotion";

import { Form, Validation, Validations } from "@edenlabllc/ehealth-components";
import {
  convertObjectKeys,
  fieldNameDenormalizer
} from "@edenlabllc/ehealth-utils";
import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
// @ts-expect-error types mismatch
import { Dictionary, MedicalComposition } from "@ehealth/ehealth-ua.schema";

import Button from "../../../components/Button";
import DictionaryValue from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import Popup from "../../../components/Popup";
import { SearchParams } from "../../../components/SearchForm";
import UnpocessableEntityModalError from "../../../components/UnpocessableEntityModalError";

import { getErrorMessage } from "../../../helpers/errorHelpers";
import { pollStatus } from "./utils";

import env from "../../../env";

const pollCancellationStatus = async (id: string, taskId: string) => {
  return fetch(
    `${env.REACT_APP_REST_API_URL}/admin/composition/${id}/cancel?taskId=${taskId}`,
    {
      method: "POST"
    }
  ).then((response) => response.json());
};

type ChangeStatusPopupProps = {
  data: MedicalComposition;
  locationParams: SearchParams;
  medicalCompositionQuery: DocumentNode;
};

const ChangeStatusPopup = ({
  data,
  locationParams,
  medicalCompositionQuery
}: ChangeStatusPopupProps) => {
  const [changeStatusPopupOpened, setChangedStatusPopupOpened] = useState(
    false
  );
  const [shouldResultPopupOpen, setShouldResultPopupOpen] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [error, setError] = useState<null | React.ReactElement>(null);
  const toggleChangeStatusPopup = () => {
    setChangedStatusPopupOpened(!changeStatusPopupOpened);
  };
  const cancellationReasonCode = `COMPOSITION_CANCELLATION_REASONS_${data.type.coding[0].code}`;

  const [queryMedicalComposition] = useLazyQuery(medicalCompositionQuery, {
    fetchPolicy: "network-only"
  });
  return (
    <Signer.Parent
      url={env.REACT_APP_SIGNER_URL}
      features={{
        width: 640,
        height: 589
      }}
    >
      {/* @ts-expect-error signData */}
      {({ signData }) => (
        <Mutation
          mutation={ChangeCompositionStatus}
          onQueryUpdated={() => false}
          refetchQueries={() => [
            {
              query: medicalCompositionQuery,
              variables: locationParams
            }
          ]}
        >
          {(changeCompositionStatus: MutationFunction) => {
            return (
              <>
                <Button
                  onClick={toggleChangeStatusPopup}
                  variant="blue"
                  disabled={data.status !== "FINAL"}
                  style={{ flex: 1 }}
                >
                  <Trans>Change medical composition status</Trans>
                </Button>

                <Popup
                  visible={shouldResultPopupOpen}
                  hideOkButton={true}
                  onCancel={() => {
                    setShouldResultPopupOpen(false);
                  }}
                  title={<Trans>Status is changing</Trans>}
                  cancelButtonProps={{ variant: "green" }}
                  cancelText={<Trans>Ok</Trans>}
                  justifyButtons="center"
                >
                  <Flex justifyContent="center" mb={4}>
                    {!inProgress ? <Box></Box> : <Trans>Status loading</Trans>}
                  </Flex>
                </Popup>
                <Popup
                  visible={changeStatusPopupOpened}
                  onCancel={toggleChangeStatusPopup}
                  title={<Trans>Change medical composition status</Trans>}
                  okButtonProps={{ variant: "green" }}
                  okText={<Trans>Approve by EDS</Trans>}
                  justifyButtons="center"
                  formId="changeCompositionStatus"
                >
                  <Form
                    id="changeCompositionStatus"
                    onSubmit={async (form: $TSFixMe) => {
                      setError(null);
                      const dataToSign = convertObjectKeys(
                        {
                          identifier: {
                            type: {
                              coding: [
                                {
                                  system: "eHealth/resources",
                                  code: "composition"
                                }
                              ]
                            },
                            value: data.identifier.value
                          },
                          reason: {
                            coding: [
                              {
                                system: cancellationReasonCode,
                                code: form.type
                              }
                            ],
                            text: form.reason
                          }
                        },
                        fieldNameDenormalizer
                      );

                      const { signedContent } = await signData(dataToSign);
                      try {
                        setError(null);
                        const response = await changeCompositionStatus({
                          variables: {
                            id: data.identifier.value,
                            input: {
                              data: signedContent
                            }
                          }
                        });
                        setShouldResultPopupOpen(true);
                        await pollStatus(() =>
                          pollCancellationStatus(
                            data.identifier.value,
                            response.data.changeCompositionStatus.data.id
                          )
                        );
                        setInProgress(false);
                        await queryMedicalComposition({
                          variables: {
                            id: data.identifier.value
                          }
                        });
                        setShouldResultPopupOpen(false);
                      } catch (e) {
                        console.error(e);
                        setError(
                          <Trans>
                            Something went wrong. Please try again later
                          </Trans>
                        );
                      }
                      toggleChangeStatusPopup();
                    }}
                  >
                    <Form.Spy>
                      {({ values }: $TSFixMe) => {
                        return (
                          <>
                            <Box width={3 / 5}>
                              <Composer
                                components={[
                                  <DictionaryValue
                                    name={cancellationReasonCode}
                                  />,
                                  ({
                                    render
                                  }: {
                                    render: (
                                      props: TransRenderProps
                                    ) => React.ReactElement;
                                  }) => (
                                    <Trans id="Select option" render={render} />
                                  )
                                ]}
                              >
                                {([dict, { translation }]: [
                                  Dictionary["values"],
                                  { translation: React.ReactNode }
                                ]) => (
                                  <Field.Select
                                    name="type"
                                    label={
                                      <Trans id="Medical composition change reason" />
                                    }
                                    placeholder={translation}
                                    items={Object.keys(dict)}
                                    itemToString={(item) =>
                                      dict[item] || translation
                                    }
                                    variant="select"
                                    emptyOption
                                    filterOptions={{
                                      keys: [(item: string) => dict[item]]
                                    }}
                                  />
                                )}
                              </Composer>
                              <Validation.Required
                                field="type"
                                message="Required field"
                              />
                            </Box>
                            <Trans
                              id="Enter description"
                              render={({ translation }) => (
                                <Field.Textarea
                                  name="reason"
                                  placeholder={translation}
                                  rows={5}
                                  maxLength={500}
                                  showLengthHint
                                />
                              )}
                            />
                            <Validations field="reason">
                              <Validation.Required message="Required field" />
                            </Validations>
                          </>
                        );
                      }}
                    </Form.Spy>
                  </Form>
                </Popup>
                {error && (
                  <UnpocessableEntityModalError
                    errorMessage={getErrorMessage(error)}
                    isModalOpen
                  />
                )}
              </>
            );
          }}
        </Mutation>
      )}
    </Signer.Parent>
  );
};

const ChangeCompositionStatus = gql`
  fragment Payload on REST {
    data: String
  }
  mutation ChangeCompositionStatus($id: String!, $input: Payload) {
    changeCompositionStatus(id: $id, input: $input)
      @rest(
        type: "ChangeCompositionRequest"
        method: "Post"
        path: "/admin/composition/:id/cancel"
      ) {
      data
    }
  }
`;

export default ChangeStatusPopup;
