import React from "react";
import { RouteComponentProps, NavigateFn } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { DocumentNode, QueryResult } from "@apollo/client";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams, Validation } from "@edenlabllc/ehealth-components";
import {
  Activity,
  ActivityConnection,
  Service,
  ServiceGroup
} from "@ehealth/ehealth-ua.schema";

import Button from "../../../../components/Button";
import EmptyData from "../../../../components/EmptyData";
import * as Field from "../../../../components/Field";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import Pagination from "../../../../components/Pagination";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../../components/SearchForm";
import Table from "../../../../components/Table";

import dateFormatter from "../../../../helpers/dateFormatter";
import pagination from "../../../../helpers/pagination";
import paramToBase64 from "../../../../helpers/paramToBase64";
import { DATE_TIME_FORMAT } from "../../../../constants/dateFormats";
import { UUID_PATTERN } from "../../../../constants/validationPatterns";

export const ACTIVITY_STATUSES: { [key: string]: string } = {
  CANCELLED: t`Cancelled status`,
  COMPLETED: t`Completed status`,
  IN_PROGRESS: t`In progress status`,
  SCHEDULED: t`Scheduled status`
};

export const ACTIVITY_KINDS: { [key: string]: string } = {
  MEDICATION_REQUEST: t`Medication request`,
  SERVICE_REQUEST: t`Service request`
};

type ActivitiesTabProps = RouteComponentProps<{
  id: string;
  itemId: string;
  query: DocumentNode;
  patientId: string;
  isClinicalMonitoring?: boolean;
}>;

export const ActivitiesTab = ({
  id,
  itemId,
  navigate,
  query = ActivitiesQuery,
  patientId,
  isClinicalMonitoring = false
}: ActivitiesTabProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => (
      <Box my={4} ml={2}>
        <Query
          skip={!locationParams.first && !locationParams.last}
          query={query}
          fetchPolicy="network-only"
          variables={prepareParamsToQuery(
            id!,
            itemId!,
            locationParams,
            isClinicalMonitoring,
            patientId!
          )}
        >
          {({
            data = {},
            loading,
            error
          }: QueryResult<{ [key: string]: ActivityConnection }>) => {
            const activities = isClinicalMonitoring
              ? data.impersonalActivities
              : data.activities;

            return (
              <LoadingOverlay loading={loading}>
                <Box>
                  <Box p={1}>
                    <SearchForm
                      initialValues={locationParams}
                      onSubmit={setLocationParams}
                      renderPrimary={() =>
                        PrimarySearchFields(isClinicalMonitoring)
                      }
                      onResetSkipPagination
                    />
                  </Box>
                  {!error && (isEmpty(data) || isEmpty(activities.nodes)) && (
                    <EmptyData />
                  )}
                  {!error && !isEmpty(data) && !isEmpty(activities.nodes) && (
                    <>
                      <Box mt={2}>
                        <Text>
                          <Trans>Total found</Trans>: {activities.totalEntries}
                        </Text>
                      </Box>
                      <ActivitiesTable
                        activities={activities.nodes}
                        navigate={navigate!}
                        isClinicalMonitoring={isClinicalMonitoring}
                      />
                      <Pagination {...activities.pageInfo} />
                    </>
                  )}
                </Box>
              </LoadingOverlay>
            );
          }}
        </Query>
      </Box>
    )}
  </LocationParams>
);

const PrimarySearchFields = (isClinicalMonitoring: boolean) => {
  const { i18n } = useLingui();

  return (
    <Flex>
      <Box width={1 / 3} mt={3} px={1}>
        <Field.Select
          name="filter.status"
          label={<Trans id="Activity status" />}
          placeholder={i18n._(t`Select status`)}
          items={Object.keys(ACTIVITY_STATUSES)}
          itemToString={(item: string) =>
            i18n._(t`${ACTIVITY_STATUSES[item]}`) || i18n._(t`All statuses`)
          }
          variant="select"
          emptyOption
          filterOptions={{
            keys: [(item: string) => ACTIVITY_STATUSES[item]]
          }}
        />
      </Box>
      <Box width={1 / 3} mt={3} px={1}>
        <Field.Select
          name="filter.kind"
          label={<Trans id="Activity kind" />}
          placeholder={i18n._(t`Select kind`)}
          items={Object.keys(ACTIVITY_KINDS)}
          itemToString={(item: string) =>
            i18n._(t`${ACTIVITY_KINDS[item]}`) || i18n._(t`All kinds`)
          }
          variant="select"
          emptyOption
          filterOptions={{ keys: [(item: string) => ACTIVITY_KINDS[item]] }}
        />
      </Box>
      {isClinicalMonitoring && (
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter activity ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.activityId"
                label={<Trans id="Activity ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.activityId"
            options={UUID_PATTERN}
            message="Invalid activity ID"
          />
        </Box>
      )}
    </Flex>
  );
};

const prepareParamsToQuery = (
  id: string,
  itemId: string,
  locationParams: SearchParams,
  isClinicalMonitoring: boolean,
  patientId: string
) => {
  const { activityId, ...restFilterParams } = locationParams.filter || {};

  return {
    orderBy: "INSERTED_AT_DESC",
    ...pagination(locationParams),
    filter: {
      carePlanId: itemId,
      ...(isClinicalMonitoring ? { patientId } : { personId: id }),
      ...restFilterParams,
      ...(activityId && {
        id: paramToBase64("Activity", activityId)
      })
    }
  };
};

const ActivitiesQuery = gql`
  query ActivitiesQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ActivityFilter!
    $orderBy: ActivityOrderBy
  ) {
    activities(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      nodes {
        id
        databaseId
        insertedAt
        detail {
          status
        }
      }
      totalEntries
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

type ActivitiesTableProps = {
  activities: ActivityConnection["nodes"];
  navigate: NavigateFn;
  isClinicalMonitoring: boolean;
};

const ActivitiesTable = ({
  activities,
  navigate,
  isClinicalMonitoring
}: ActivitiesTableProps) => {
  const { i18n } = useLingui();
  if (isEmpty(activities)) return null;

  return (
    <Table
      data={activities}
      header={{
        databaseId: <Trans>ID</Trans>,
        ...(isClinicalMonitoring && {
          productReference: <Trans>Service code</Trans>
        }),
        status: <Trans>Status</Trans>,
        insertedAt: <Trans>Inserted at</Trans>,
        action: <Trans>Action</Trans>
      }}
      renderRow={({ databaseId, insertedAt, detail, id }: Activity) => ({
        databaseId,
        ...(isClinicalMonitoring && {
          productReference: !isEmpty(
            (detail.productReference as Service | ServiceGroup).code
          )
            ? `${detail.productReference.name} (${
                (detail.productReference as Service | ServiceGroup).code
              })`
            : detail.productReference.name
        }),
        insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
        status: i18n._(t`${ACTIVITY_STATUSES[detail.status]}`),
        action: (
          <Button variant="link" onClick={() => navigate(`../activity/${id}`)}>
            <Trans>Details</Trans>
          </Button>
        )
      })}
    />
  );
};
