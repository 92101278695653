import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Heading } from "@rebass/emotion";

import { Maybe, ConfidantPerson } from "@ehealth/ehealth-ua.schema";

import EmptyData from "../../../components/EmptyData";
import Line from "../../../components/Line";

import { getDataByType } from "./index";
import UserInfo from "./UserInfo";

type PersonConfidantPersonsProps = RouteComponentProps<{
  data: Maybe<Maybe<ConfidantPerson>[]>;
}>;

const PersonConfidantPersons = ({ data }: PersonConfidantPersonsProps) => {
  if (!data) return <EmptyData />;

  const [primaryPerson] = getDataByType("PRIMARY", data, "relationType");
  const [secondaryPerson] = getDataByType("SECONDARY", data, "relationType");

  return (
    <>
      {primaryPerson && (
        <>
          <Heading fontSize="1" fontWeight="normal" p={5} pb={0}>
            <Trans>Primary Confidant Persons</Trans>
          </Heading>
          <UserInfo data={primaryPerson} />
        </>
      )}

      {secondaryPerson && (
        <>
          <Line />
          <Heading fontSize="1" fontWeight="normal" p={5} py={2}>
            <Trans>Secondary Confidant Persons</Trans>
          </Heading>
          <UserInfo data={secondaryPerson} />
        </>
      )}
    </>
  );
};

export default PersonConfidantPersons;

PersonConfidantPersons.fragments = {
  entry: gql`
    fragment PersonConfidantPersons on Person {
      confidantPersons {
        relationType
        firstName
        lastName
        secondName
        gender
        birthDate
        birthCountry
        birthSettlement
        unzr
        taxId
        email
        secret
        preferredWayCommunication
        phones {
          type
          number
        }
        documents {
          type
          number
          issuedAt
          issuedBy
          expirationDate
        }
        relationshipDocuments {
          type
          number
          issuedAt
          issuedBy
          expirationDate
        }
      }
    }
  `
};
