import React from "react";
import { gql } from "graphql-tag";
import isEmpty from "lodash/isEmpty";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";

import { Switch } from "@edenlabllc/ehealth-components";

import CodeableConcept from "../../../components/CodeableConcept";
import DefinitionListView from "../../../components/DefinitionListView";

import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import Period from "./Period";
import Quantity from "./Quantity";
import Range from "./Range";
import Ratio from "./Ratio";
import SampledData from "./SampledData";
import ValueBoolean from "./ValueBoolean";
import ValueDateTime from "./ValueDateTime";
import ValueString from "./ValueString";
import ValueTime from "./ValueTime";
import { LABEL_WIDTH } from "./Episode";

type ValueProps = {
  value: any;
};

const Value = ({ value }: ValueProps) => {
  if (isEmpty(value)) return null;
  const { __typename } = value;

  return (
    <Box mt={4}>
      <DefinitionListView
        fontSize={14}
        labelWidth={LABEL_WIDTH}
        labels={{ value: <Trans>Value</Trans> }}
        data={{
          value: (
            <Switch
              value={__typename}
              CodeableConcept={<CodeableConcept codeableConcept={value} />}
              Period={<Period datePeriod={value} wrapperProps={{ mt: 0 }} />}
              Quantity={<Quantity quantity={value} />}
              Range={<Range range={value} />}
              Ratio={<Ratio ratio={value} />}
              SampledData={<SampledData sampledData={value} />}
              ValueBoolean={<ValueBoolean valueBoolean={value.boolean} />}
              ValueDateTime={
                <ValueDateTime
                  valueDateTime={value.dateTime}
                  dateTimeFormat={DATE_TIME_FORMAT}
                />
              }
              ValueString={<ValueString valueString={value.string} />}
              ValueTime={<ValueTime valueTime={value.time} />}
            />
          )
        }}
      />
    </Box>
  );
};

export default Value;

Value.fragments = {
  entry: gql`
    fragment Value on ObservationValue {
      ... on CodeableConcept {
        ...CodeableConcept
      }
      ... on Period {
        ...Period
      }
      ... on Quantity {
        ...Quantity
      }
      ... on Range {
        ...Range
      }
      ... on Ratio {
        ...Ratio
      }
      ... on SampledData {
        ...SampledData
      }
      ... on ValueBoolean {
        ...ValueBoolean
      }
      ... on ValueDateTime {
        ...ValueDateTime
      }
      ... on ValueString {
        ...ValueString
      }
      ... on ValueTime {
        ...ValueTime
      }
    }
    ${CodeableConcept.fragments!.entry}
    ${Period.fragments!.entry}
    ${Quantity.fragments.entry}
    ${SampledData.fragments.entry}
    ${ValueBoolean.fragments.entry}
    ${ValueDateTime.fragments.entry}
    ${ValueString.fragments.entry}
    ${ValueTime.fragments.entry}
    ${Ratio.fragments.entry}
    ${Range.fragments.entry}
  `
};
