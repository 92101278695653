import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box, Text } from "@rebass/emotion";

import { Form, Validation } from "@edenlabllc/ehealth-components";
import { PositiveIcon, CancelIcon } from "@edenlabllc/ehealth-icons";
import { InnmDosage, Maybe, Scalars } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import DictionaryValue from "../../../../components/DictionaryValue";
import Dosage from "../../../../components/Dosage";
import * as Field from "../../../../components/Field";

type UpdateDosageProps = {
  id: Scalars["ID"];
  fieldName: string;
  dosage?: InnmDosage["dailyDosage"];
  denumeratorUnit?: Maybe<string>;
};

const UpdateDosage = ({
  id,
  dosage,
  fieldName,
  denumeratorUnit
}: UpdateDosageProps) => {
  const [isFormVisible, setFormVisibility] = useState(false);
  const toggle = () => setFormVisibility(!isFormVisible);

  return isFormVisible ? (
    <Mutation mutation={UpdateINNMDosageMutation}>
      {(updateInnmDosage: MutationFunction) => (
        <Form
          initialValues={{
            [fieldName]: dosage
          }}
          onSubmit={async ({ [fieldName]: dosage }: $TSFixMe) => {
            const value = dosage || dosage === 0 ? parseFloat(dosage) : null;

            await updateInnmDosage({
              variables: {
                input: {
                  id,
                  [fieldName]: value
                }
              }
            });
            toggle();
          }}
        >
          <Flex>
            <Box width={200}>
              <Field.Number
                name={fieldName}
                placeholder="0 - 1 000"
                postfix={
                  denumeratorUnit ? (
                    <DictionaryValue
                      name="MEDICATION_UNIT"
                      item={denumeratorUnit}
                    />
                  ) : (
                    <Trans>mg</Trans>
                  )
                }
              />
              <Validation.PositiveFloat
                field={fieldName}
                message="Must be greater than zero"
              />
            </Box>
            <Box mx={2} color="redPigment">
              <Button
                variant="none"
                border="none"
                px="0"
                type="reset"
                onClick={toggle}
              >
                <CancelIcon />
              </Button>
            </Box>
            <Box>
              <Button variant="none" border="none" px="0">
                <PositiveIcon />
              </Button>
            </Box>
          </Flex>
        </Form>
      )}
    </Mutation>
  ) : (
    <Flex>
      <Dosage dosage={dosage} denumeratorUnit={denumeratorUnit} />
      <Ability action="write" resource="innm_dosage">
        <Button variant="none" border="none" px="0" py="0" onClick={toggle}>
          <Text color="rockmanBlue" fontWeight="bold" fontSize="0" ml={2}>
            <Trans>Change</Trans>
          </Text>
        </Button>
      </Ability>
    </Flex>
  );
};

const UpdateINNMDosageMutation = gql`
  mutation UpdateINNMDosageMutation($input: UpdateINNMDosageInput!) {
    updateInnmDosage(input: $input) {
      innmDosage {
        id
        dailyDosage
      }
    }
  }
`;

export default UpdateDosage;
