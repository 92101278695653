import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Switch } from "@edenlabllc/ehealth-components";
import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import { Maybe, MedicalProgram } from "@ehealth/ehealth-ua.schema";

import DefinitionListView from "../../../components/DefinitionListView";
import EmptyData from "../../../components/EmptyData";
import Line from "../../../components/Line";

import UpdateMedicalProgramSettings from "./Mutations/UpdateMedicalProgramSettings";
import { VirtualizedCodesList } from "./VirtualizedCodesList";

type SettingsProps = RouteComponentProps<{
  medicalProgramSettings: MedicalProgram["medicalProgramSettings"];
  id: string;
  type: MedicalProgram["type"];
  isActive: MedicalProgram["isActive"];
  medicalProgramSettingsText: MedicalProgram["medicalProgramSettingsText"];
}>;

const Settings = ({
  medicalProgramSettings,
  navigate,
  id,
  type,
  isActive,
  medicalProgramSettingsText
}: SettingsProps) => {
  const isMedication = type === "MEDICATION";
  const isDevice = type === "DEVICE";
  const {
    skipMnnInTreatmentPeriod,
    carePlanRequired,
    skipEmployeeValidation,
    medicationRequestMaxPeriodDay,
    skipMedicationRequestEmployeeDeclarationVerify,
    skipMedicationRequestLegalEntityDeclarationVerify,
    multiMedicationDispenseAllowed,
    skipMedicationDispenseSign,
    medicationRequestNotificationDisabled,
    medicationDispensePeriodDay,
    skipContractProvisionVerify,
    medicalProgramChangeOnDispenseAllowed,
    employeeTypesToCreateMedicationRequest,
    providingConditionsAllowed,
    specialityTypesAllowed,
    conditionsIcd10AmAllowed,
    conditionsIcpc2Allowed,
    licenseTypesAllowed,
    patientCategoriesAllowed,
    skipTreatmentPeriod,
    employeeTypesToCreateRequest,
    requestMaxPeriodDay,
    skipRequestEmployeeDeclarationVerify,
    skipRequestLegalEntityDeclarationVerify,
    dispensePeriodDay,
    requestAllowed,
    dispenseAllowed
  } = medicalProgramSettings || {};

  return (
    <Box p={5}>
      {isEmpty(medicalProgramSettings) ? (
        <Box mb={5}>
          <EmptyData height="auto" />
        </Box>
      ) : (
        <>
          <DefinitionListView
            labelWidth={300}
            labels={{
              medicalProgramSettingsText: (
                <Trans>Reason for change settings</Trans>
              )
            }}
            data={{
              medicalProgramSettingsText: medicalProgramSettingsText
            }}
          />
          {medicalProgramSettingsText && <Line />}

          <DefinitionListView
            labelWidth={300}
            labels={{
              skipMnnInTreatmentPeriod: (
                <Trans>
                  Create a medication request without period restrictions
                </Trans>
              ),
              carePlanRequired: <Trans>Care plan required</Trans>,
              skipEmployeeValidation: <Trans>Skip employee validations</Trans>,
              medicationRequestMaxPeriodDay: (
                <Trans>Medication request max period (days)</Trans>
              ),
              skipMedicationRequestEmployeeDeclarationVerify: (
                <Trans>
                  Don't check declaration on employee level on create medication
                  request
                </Trans>
              ),
              skipMedicationRequestLegalEntityDeclarationVerify: (
                <Trans>
                  Don't check declaration on legal entity level on create
                  medication request
                </Trans>
              ),
              multiMedicationDispenseAllowed: (
                <Trans>Partially dispense of medication request allowed</Trans>
              ),
              skipMedicationDispenseSign: (
                <Trans>Digital sign on medication dispense is disabled</Trans>
              ),
              medicationRequestNotificationDisabled: (
                <Trans>
                  Patient notification on medication request is disabled
                </Trans>
              ),
              medicationDispensePeriodDay: (
                <Trans>Medication dispense period (days)</Trans>
              ),
              skipContractProvisionVerify: (
                <Trans>
                  Don't check contract and medical program provision on
                  medication dispense
                </Trans>
              ),
              medicalProgramChangeOnDispenseAllowed: (
                <Trans>Medical program change on dispense allowed</Trans>
              ),
              skipTreatmentPeriod: (
                <Trans>Create device request without period restrictions</Trans>
              ),
              requestMaxPeriodDay: (
                <Trans>Device request max treatment period (in days)</Trans>
              ),
              skipRequestEmployeeDeclarationVerify: (
                <Trans>
                  Don't check declaration on employee level on create device
                  request
                </Trans>
              ),
              skipRequestLegalEntityDeclarationVerify: (
                <Trans>
                  Don't check declaration on legal entity level on create device
                  request
                </Trans>
              ),
              dispensePeriodDay: (
                <Trans>Device dispense period (in days)</Trans>
              ),
              requestAllowed: <Trans>Device requests allowed</Trans>,
              dispenseAllowed: <Trans>Device dispenses allowed</Trans>
            }}
            data={{
              skipMnnInTreatmentPeriod: isMedication ? (
                <ValueIcon isPositiveValue={skipMnnInTreatmentPeriod} />
              ) : null,
              carePlanRequired: !isDevice ? (
                <ValueIcon isPositiveValue={carePlanRequired} />
              ) : null,
              skipEmployeeValidation:
                isMedication || isDevice ? (
                  <ValueIcon isPositiveValue={skipEmployeeValidation} />
                ) : null,
              medicationRequestMaxPeriodDay: isMedication
                ? medicationRequestMaxPeriodDay
                : null,
              skipMedicationRequestEmployeeDeclarationVerify: isMedication ? (
                <ValueIcon
                  isPositiveValue={
                    skipMedicationRequestEmployeeDeclarationVerify
                  }
                />
              ) : null,
              skipMedicationRequestLegalEntityDeclarationVerify: isMedication ? (
                <ValueIcon
                  isPositiveValue={
                    skipMedicationRequestLegalEntityDeclarationVerify
                  }
                />
              ) : null,
              multiMedicationDispenseAllowed: isMedication ? (
                <ValueIcon isPositiveValue={multiMedicationDispenseAllowed} />
              ) : null,
              skipMedicationDispenseSign: isMedication ? (
                <ValueIcon isPositiveValue={skipMedicationDispenseSign} />
              ) : null,
              medicationRequestNotificationDisabled: isMedication ? (
                <ValueIcon
                  isPositiveValue={medicationRequestNotificationDisabled}
                />
              ) : null,
              medicationDispensePeriodDay: isMedication
                ? medicationDispensePeriodDay
                : null,
              skipContractProvisionVerify: isMedication ? (
                <ValueIcon isPositiveValue={skipContractProvisionVerify} />
              ) : null,
              medicalProgramChangeOnDispenseAllowed: isMedication ? (
                <ValueIcon
                  isPositiveValue={medicalProgramChangeOnDispenseAllowed}
                />
              ) : null,
              skipTreatmentPeriod: isDevice ? (
                <ValueIcon isPositiveValue={skipTreatmentPeriod} />
              ) : null,
              requestMaxPeriodDay: isDevice ? requestMaxPeriodDay || "-" : null,
              skipRequestEmployeeDeclarationVerify: isDevice ? (
                <ValueIcon
                  isPositiveValue={skipRequestEmployeeDeclarationVerify}
                />
              ) : null,
              skipRequestLegalEntityDeclarationVerify: isDevice ? (
                <ValueIcon
                  isPositiveValue={skipRequestLegalEntityDeclarationVerify}
                />
              ) : null,
              dispensePeriodDay: isDevice ? dispensePeriodDay || "-" : null,
              requestAllowed: isDevice ? (
                <ValueIcon isPositiveValue={requestAllowed} />
              ) : null,
              dispenseAllowed: isDevice ? (
                <ValueIcon isPositiveValue={dispenseAllowed} />
              ) : null
            }}
          />
          {isMedication && (
            <>
              <VirtualizedCodesList
                data={employeeTypesToCreateMedicationRequest || []}
                title={
                  <Trans>
                    Employee types who are allowed to create medication requests
                  </Trans>
                }
                dictionaryName="EMPLOYEE_TYPE"
              />
              <VirtualizedCodesList
                data={providingConditionsAllowed || []}
                title={<Trans>Allowed providing conditions</Trans>}
                dictionaryName="PROVIDING_CONDITION"
              />
            </>
          )}
          {isDevice && (
            <>
              <VirtualizedCodesList
                data={employeeTypesToCreateRequest || []}
                title={
                  <Trans>
                    Employee types allowed to create device requests
                  </Trans>
                }
                dictionaryName="EMPLOYEE_TYPE"
              />
            </>
          )}
          <VirtualizedCodesList
            data={specialityTypesAllowed || []}
            title={
              <Switch
                value={type}
                MEDICATION={
                  <Trans>
                    Speciality types allowed to create medication requests or
                    care plan activities
                  </Trans>
                }
                DEVICE={
                  <Trans>
                    Speciality types allowed to create device requests
                  </Trans>
                }
                default={
                  <Trans>
                    Speciality types allowed to create care plan activities
                  </Trans>
                }
              />
            }
            dictionaryName="SPECIALITY_TYPE"
          />
          <VirtualizedCodesList
            data={conditionsIcd10AmAllowed || []}
            title={
              <Switch
                value={type}
                MEDICATION={
                  <Trans>
                    ICD10AM diagnoses allowed to create medication requests or
                    care plan activities
                  </Trans>
                }
                DEVICE={
                  <Trans>
                    ICD10AM diagnoses allowed to create device requests
                  </Trans>
                }
                default={
                  <Trans>
                    ICD10AM diagnoses allowed to create care plan activities
                  </Trans>
                }
              />
            }
            dictionaryName="eHealth/ICD10_AM/condition_codes"
          />
          <VirtualizedCodesList
            data={conditionsIcpc2Allowed || []}
            title={
              <Switch
                value={type}
                MEDICATION={
                  <Trans>
                    ICPC2 diagnoses allowed to create medication requests or
                    care plan activities
                  </Trans>
                }
                DEVICE={
                  <Trans>
                    ICPC2 diagnoses allowed to create device requests
                  </Trans>
                }
                default={
                  <Trans>
                    ICPC2 diagnoses allowed to create care plan activities
                  </Trans>
                }
              />
            }
            dictionaryName="eHealth/ICPC2/condition_codes"
          />
          {isMedication && (
            <>
              <VirtualizedCodesList
                data={licenseTypesAllowed || []}
                title={
                  <Trans>Legal entity license types allowed on dispense</Trans>
                }
                dictionaryName="LICENSE_TYPE"
              />
              <VirtualizedCodesList
                data={patientCategoriesAllowed || []}
                title={
                  <Trans>
                    Patient categories for whom care plan activity creation are
                    allowed
                  </Trans>
                }
                dictionaryName="eHealth/clinical_impression_patient_categories"
              />
            </>
          )}
        </>
      )}
      {isActive && (
        <UpdateMedicalProgramSettings
          medicalProgramSettings={medicalProgramSettings}
          type={type!}
          navigate={navigate}
          id={id!}
        />
      )}
    </Box>
  );
};

export default Settings;

type ValueIconProps = {
  isPositiveValue?: Maybe<boolean>;
};

const ValueIcon = ({ isPositiveValue }: ValueIconProps) =>
  isPositiveValue ? <PositiveIcon /> : <NegativeIcon />;

Settings.fragments = {
  entry: gql`
    fragment MedicalProgramSettings on MedicalProgram {
      medicalProgramSettings {
        skipMnnInTreatmentPeriod
        carePlanRequired
        skipEmployeeValidation
        specialityTypesAllowed
        employeeTypesToCreateMedicationRequest
        conditionsIcd10AmAllowed
        conditionsIcpc2Allowed
        medicationRequestMaxPeriodDay
        providingConditionsAllowed
        skipMedicationRequestEmployeeDeclarationVerify
        skipMedicationRequestLegalEntityDeclarationVerify
        multiMedicationDispenseAllowed
        skipMedicationDispenseSign
        medicationRequestNotificationDisabled
        medicationDispensePeriodDay
        skipContractProvisionVerify
        medicalProgramChangeOnDispenseAllowed
        licenseTypesAllowed
        patientCategoriesAllowed
        skipTreatmentPeriod
        employeeTypesToCreateRequest
        requestMaxPeriodDay
        skipRequestEmployeeDeclarationVerify
        skipRequestLegalEntityDeclarationVerify
        dispensePeriodDay
        requestAllowed
        dispenseAllowed
      }
    }
  `
};
