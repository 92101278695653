import { gql } from "graphql-tag";
import Pagination from "../../../components/Pagination";
import CodeableConcept from "../../../components/CodeableConcept";
import CarePlan from "../../PracticalMonitoring/Details/CarePlan";

export const ImpersonalCarePlansQuery = gql`
  query ImpersonalCarePlansQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImpersonalCarePlanFilter!
    $orderBy: CarePlanOrderBy
  ) {
    impersonalCarePlans(
      after: $after
      before: $before
      filter: $filter
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      nodes {
        id
        databaseId
        status
        insertedAt
        addresses {
          ...CodeableConcept
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalEntries
    }
  }
  ${Pagination.fragments.entry}
  ${CodeableConcept.fragments!.entry}
`;

export const ImpersonalCarePlanByIDQuery = gql`
  query ImpersonalCarePlanByIDQuery(
    $id: ID!
    $patientId: String!
    $skipAdditionalFields: Boolean!
  ) {
    impersonalCarePlan(id: $id, patientId: $patientId) {
      ...CarePlan
    }
  }
  ${CarePlan.fragments!.entry}
`;
