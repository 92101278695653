import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { loader } from "graphql.macro";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";
import { format } from "date-fns";
import subDays from "date-fns/sub_days";
import { debounce, isEmpty, omit } from "lodash";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { getFullName } from "@edenlabllc/ehealth-utils";
import system from "@edenlabllc/ehealth-system-components";
import { Form, Validation, Validations } from "@edenlabllc/ehealth-components";
import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import {
  Dictionary,
  CapitationContract,
  Employee,
  EmployeeConnection
} from "@ehealth/ehealth-ua.schema";

import Button from "../../../../../components/Button";
import DefinitionListView from "../../../../../components/DefinitionListView";
import DictionaryValue from "../../../../../components/DictionaryValue";
import * as Field from "../../../../../components/Field/index";
import Line from "../../../../../components/Line";
import Link from "../../../../../components/Link";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import Steps from "../../../../../components/Steps";

import { contractRequestMaxDateCount } from "../../../../../helpers/contractRequestMaxDateCount";

import env from "../../../../../env";

const GetDataToCreateCapitationContractRequestQuery = loader(
  "../../../../../graphql/GetDataToCreateCapitationContractRequestQuery.graphql"
);
const GetAssignEmployeeQuery = loader(
  "../../../../../graphql/GetAssignEmployeeQuery.graphql"
);
const CreateContractRequestMutation = loader(
  "../../../../../graphql/CreateContractRequestMutation.graphql"
);

type CreateProps = RouteComponentProps<{
  id: string;
}>;

const Create = ({
  id,
  // @ts-expect-error location state
  location: { state }
}: CreateProps) => (
  <Box px={6}>
    <Box pt={5}>
      <Steps.List>
        <Steps.Item to="./" state={state}>
          <Trans>Fill in fields</Trans>
        </Steps.Item>
        <Steps.Item to="./confirm" disabled>
          <Trans>Confirm with EDS</Trans>
        </Steps.Item>
      </Steps.List>
    </Box>
    <Query
      query={GetDataToCreateCapitationContractRequestQuery}
      variables={{ id }}
    >
      {({
        loading,
        data
      }: QueryResult<{ capitationContract: CapitationContract }>) => {
        if (isEmpty(data) || isEmpty(data.capitationContract)) return null;
        const {
          contractorLegalEntity: { databaseId: legalEntityId, name },
          // @ts-expect-error types mismatch
          toCreateRequestContent,
          endDate,
          startDate
        } = data.capitationContract;
        const { contract_number } = toCreateRequestContent || {};

        return (
          <LoadingOverlay loading={loading}>
            <OpacityBox my={5}>
              <DefinitionListView
                labels={{
                  contractType: <Trans>Type</Trans>,
                  contract_number: <Trans>Contract Number</Trans>,
                  name: <Trans>Name</Trans>,
                  legalEntityId: <Trans>Legal entity ID</Trans>
                }}
                data={{
                  contractType: <Trans>Capitation type</Trans>,
                  contract_number: (
                    <Link to={`/contracts/capitation/${id}`}>
                      {contract_number}
                    </Link>
                  ),
                  name,
                  legalEntityId
                }}
                color="#7F8FA4"
                labelWidth="120px"
              />
              <Line />
            </OpacityBox>
            <Router>
              <CreateContractRequest
                path="/"
                id={id}
                initialValues={toCreateRequestContent}
                endDate={endDate}
                startDate={startDate}
              />
              <Confirmation path="/confirm" />
            </Router>
          </LoadingOverlay>
        );
      }}
    </Query>
  </Box>
);

type CreateContractRequestProps = RouteComponentProps<{
  id: string;
  endDate: string;
  startDate: string;
  // @ts-expect-error types mismatch
  initialValues: CapitationContract["toCreateRequestContent"];
}>;

const CreateContractRequest = ({
  id,
  endDate,
  startDate,
  initialValues,
  navigate,
  // @ts-expect-error location state
  location: { state = {} }
}: CreateContractRequestProps) => {
  const { i18n } = useLingui();
  const { createContractRequest } = state || {};
  const {
    nhs_signer_id,
    nhs_signer_base,
    issue_city,
    nhs_contract_price,
    nhsSigner,
    misc,
    ...initialData
  } = createContractRequest || initialValues;

  const fromToday = subDays(new Date(), 1).toISOString();

  return (
    <Query
      query={GetAssignEmployeeQuery}
      fetchPolicy="cache-first"
      variables={{
        skip: !nhs_signer_id,
        first: 1,
        filter: {
          employeeType: ["NHS ADMIN SIGNER"],
          status: "APPROVED",
          databaseId: nhs_signer_id
        }
      }}
    >
      {({
        data,
        refetch: refetchSigner
      }: QueryResult<{ employees: EmployeeConnection }>) => {
        const employees = data && data.employees ? data.employees.nodes : [];

        return (
          <Form
            onSubmit={async (
              createContractRequestValues: Partial<CapitationContract> & {
                end_date: string;
              }
            ) => {
              const emptyEndDate = isEmpty(
                createContractRequestValues.end_date
              );

              const createContractRequest = emptyEndDate
                ? omit(createContractRequestValues, ["end_date"])
                : createContractRequestValues;

              await navigate!("./confirm", {
                state: {
                  createContractRequest
                }
              });
            }}
            initialValues={{
              ...initialData,
              misc,
              nhsSigner: nhsSigner ? nhsSigner : nhs_signer_id && employees![0],
              nhs_signer_base:
                nhs_signer_base ||
                "Положення про Національну службу здоров'я України, затвердженого постановою Кабінету Міністрів України від 27 грудня 2017 року № 1101",
              issue_city: issue_city || "Київ",
              nhs_contract_price: nhs_contract_price || 0,
              end_date: ""
            }}
          >
            <Flex>
              <Box mr={5} width={2 / 5}>
                <Field.Select
                  name="nhsSigner"
                  label={<Trans id="Signatory from the Customers side" />}
                  placeholder={i18n._(t`Enter signer`)}
                  items={employees!.map((employee) => {
                    const party = employee && employee.party;
                    const { firstName, secondName, lastName } = party || {};
                    return {
                      party: { firstName, secondName, lastName },
                      databaseId: employee && employee.databaseId
                    };
                  })}
                  onInputValueChange={debounce(
                    (fullName, { selectedItem, inputValue }) => {
                      const filter =
                        typeof selectedItem === "object"
                          ? { party: { fullName } }
                          : { databaseId: nhs_signer_id };

                      return (
                        (selectedItem && getFullName(selectedItem.party)) !==
                          inputValue &&
                        refetchSigner({
                          skip: false,
                          first: 20,
                          filter: {
                            employeeType: ["NHS ADMIN SIGNER"],
                            status: "APPROVED",
                            ...filter
                          }
                        })
                      );
                    },
                    1000
                  )}
                  itemToString={(item: Employee) =>
                    item && getFullName(item.party)
                  }
                  filterOptions={{
                    keys: ["party.lastName", "party.firstName"]
                  }}
                  iconComponent={SearchIcon}
                />
                <Validation.Required
                  field="nhsSigner"
                  message="Required field"
                />
              </Box>
              <Box width={2 / 5}>
                <Field.Text
                  name="nhs_signer_base"
                  label={<Trans id="Basis" />}
                  placeholder={i18n._(t`Choose base`)}
                  maxLength={255}
                  showLengthHint
                />
                <Validation.Required
                  field="nhs_signer_base"
                  message="Required field"
                />
              </Box>
            </Flex>
            <Flex>
              <Box mr={5} width={2 / 5}>
                <Field.Number
                  name="nhs_contract_price"
                  label={<Trans id="Contract Amount" />}
                  placeholder="0 - 1 000 000"
                  postfix={<Trans>uah</Trans>}
                />
                <Validations field="nhs_contract_price">
                  <Validation.Required message="Required field" />
                </Validations>
              </Box>
              <Box width={2 / 5}>
                <DictionaryValue
                  name="CONTRACT_PAYMENT_METHOD"
                  render={(dict: Dictionary["values"]) => (
                    <Trans
                      id="Choose payment method"
                      render={({ translation }) => (
                        <Field.Select
                          variant="select"
                          name="nhs_payment_method"
                          label={<Trans id="Payment method" />}
                          placeholder={translation}
                          itemToString={(item: string) => dict[item]}
                          items={Object.keys(dict)}
                          size="small"
                          sendForm="key"
                          filterOptions={{
                            keys: [(item: string) => dict[item]]
                          }}
                        />
                      )}
                    />
                  )}
                />
                <Validation.Required
                  field="nhs_payment_method"
                  message="Required field"
                />
              </Box>
            </Flex>
            <Flex>
              <Box mr={5} width={2 / 5}>
                <Field.Text
                  name="issue_city"
                  label={
                    <Trans id="The city of the conclusion of the contract" />
                  }
                  placeholder={i18n._(t`Enter city`)}
                  maxLength={100}
                  showLengthHint
                />
                <Validation.Required
                  field="issue_city"
                  message="Required field"
                />
              </Box>
              <Box width={2 / 5}>
                <Query
                  query={GetAssignEmployeeQuery}
                  fetchPolicy="cache-first"
                  variables={{
                    skip: true
                  }}
                >
                  {({
                    data,
                    refetch: refetchEmployees
                  }: QueryResult<{ employees: EmployeeConnection }>) => {
                    const employees =
                      data && data.employees ? data.employees.nodes : [];

                    return (
                      <Field.Select
                        name="assigneeId"
                        label={<Trans id="Performer" />}
                        placeholder={i18n._(t`Enter signer`)}
                        items={employees!.map((employee) => {
                          const party = employee && employee.party;
                          const { firstName, secondName, lastName } =
                            party || {};
                          return {
                            party: { firstName, secondName, lastName },
                            databaseId: employee && employee.databaseId
                          };
                        })}
                        itemToString={(item: Employee) =>
                          item && getFullName(item.party)
                        }
                        filterOptions={{
                          keys: ["party.lastName", "party.firstName"]
                        }}
                        filter={(fullName) => fullName}
                        onInputValueChange={debounce(
                          (fullName, { selectedItem, inputValue }) =>
                            (selectedItem &&
                              getFullName(selectedItem.party)) !== inputValue &&
                            refetchEmployees({
                              skip: false,
                              first: 20,
                              filter: {
                                employeeType: ["NHS ADMIN SIGNER"],
                                status: "APPROVED",
                                party: {
                                  fullName
                                }
                              }
                            }),
                          1000
                        )}
                        iconComponent={SearchIcon}
                      />
                    );
                  }}
                </Query>
                <Validation.Required
                  field="assigneeId"
                  message="Required field"
                />
              </Box>
            </Flex>
            <Flex>
              <Box width={2 / 5} mr={5}>
                <Field.Textarea
                  name="misc"
                  label={
                    <Trans id="Miscellaneous (depending on the type of medical service)" />
                  }
                  placeholder={i18n._(t`List the conditions`)}
                  rows={5}
                />
              </Box>
              <Box width={2 / 5}>
                <Field.DatePicker
                  name="end_date"
                  label={<Trans id="End date of the contract" />}
                  minDate={format(fromToday, "YYYY-MM-DD")}
                  maxDate={format(
                    contractRequestMaxDateCount(startDate!, endDate!),
                    "YYYY-MM-DD"
                  )}
                />
                <Validations field="end_date">
                  <Validation.MaxDate
                    options={{
                      max: contractRequestMaxDateCount(startDate!, endDate!),
                      min: fromToday
                    }}
                    message="Invalid date"
                  />
                  <Validation.MinDate
                    options={fromToday}
                    message="Invalid date"
                  />
                </Validations>
              </Box>
            </Flex>
            <Flex mt={5}>
              <Box mr={3}>
                <Button
                  onClick={() => navigate!(`/contracts/capitation/${id}`)}
                  variant="blue"
                  width={140}
                >
                  <Trans>Return</Trans>
                </Button>
              </Box>
              <Button variant="green" width={140}>
                <Trans>Refresh</Trans>
              </Button>
            </Flex>
          </Form>
        );
      }}
    </Query>
  );
};

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  if (!state) return null;
  const {
    createContractRequest,
    createContractRequest: {
      nhsSigner,
      assigneeId,
      nhs_contract_price,
      nhs_payment_method,
      ...contractRequestData
    }
  } = state;

  return (
    <>
      <DefinitionListView
        labels={{
          assigneeId: <Trans>Performer</Trans>,
          nhsSigner: <Trans>Signatory from the Customers side</Trans>,
          nhs_signer_base: <Trans>Basis</Trans>,
          nhs_contract_price: <Trans>Contract Amount</Trans>,
          nhs_payment_method: <Trans>Payment method</Trans>,
          issue_city: <Trans>The city of the conclusion of the contract</Trans>,
          misc: <Trans>Miscellaneous</Trans>,
          end_date: <Trans>End date of the contract</Trans>
        }}
        data={{
          assigneeId: assigneeId && getFullName(assigneeId.party),
          nhsSigner: nhsSigner && getFullName(nhsSigner.party),
          nhs_contract_price: (
            <>
              {nhs_contract_price} <Trans>uah</Trans>
            </>
          ),
          nhs_payment_method: (
            <DictionaryValue
              name="CONTRACT_PAYMENT_METHOD"
              item={nhs_payment_method}
            />
          ),
          ...contractRequestData
        }}
        labelWidth="300px"
        marginBetween={2}
        flexDirection="column"
      />

      <Flex mt={5}>
        <Box mr={3}>
          <Button
            variant="blue"
            width={140}
            onClick={() => {
              navigate!("../", {
                state: {
                  createContractRequest
                }
              });
            }}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Signer.Parent
            url={env.REACT_APP_SIGNER_URL}
            features={{ width: 640, height: 589 }}
          >
            {/* @ts-expect-error signData */}
            {({ signData }) => (
              <Mutation mutation={CreateContractRequestMutation}>
                {(createContractRequest: MutationFunction) => (
                  <Button
                    variant="green"
                    width={140}
                    onClick={async () => {
                      const { signedContent } = await signData({
                        ...contractRequestData,
                        nhs_contract_price:
                          Math.round(Number(nhs_contract_price) * 100) / 100,
                        nhs_payment_method,
                        nhs_signer_id: nhsSigner.databaseId
                      });
                      await createContractRequest({
                        variables: {
                          input: {
                            assigneeId: assigneeId.databaseId,
                            signedContent: {
                              content: signedContent,
                              encoding: "BASE64"
                            },
                            type: "CAPITATION"
                          }
                        }
                      });
                      await navigate!("/contract-requests");
                    }}
                  >
                    <Trans>Sign</Trans>
                  </Button>
                )}
              </Mutation>
            )}
          </Signer.Parent>
        </Box>
      </Flex>
    </>
  );
};

const OpacityBox = system({ extend: Box, opacity: 0.5 }, "opacity", "space");

export default Create;
