import React from "react";
import { Flex, Box } from "@rebass/emotion";

import { Field } from "@edenlabllc/ehealth-components";
import { CheckMarkIcon } from "@edenlabllc/ehealth-icons";
import system from "@edenlabllc/ehealth-system-components";
import { variant, boolean } from "@edenlabllc/ehealth-system-tools";

type CheckboxFieldProps = {
  name: string;
  label?: React.ReactElement;
  disabled?: boolean;
};

const CheckboxField = ({ label, disabled, ...props }: CheckboxFieldProps) => (
  <Field {...props} type="checkbox">
    {({
      input: { checked },
      input,
      meta: { active, errored }
    }: {
      input: any;
      meta: { active: boolean; errored: boolean };
    }) => (
      <Wrapper>
        <Input {...input} disabled={disabled} />
        <CheckBox
          checked={checked}
          active={active}
          errored={errored}
          disabled={disabled}
        >
          <CheckMark checked={checked} />
        </CheckBox>
        <Box>{label}</Box>
      </Wrapper>
    )}
  </Field>
);

type CheckboxFieldViewProps = {
  id?: string;
  label?: React.ReactNode;
  disabled?: boolean;
  checked?: boolean;
  onChange: (e: React.MouseEvent) => void;
};

export const CheckboxFieldView = ({
  label,
  disabled,
  checked,
  ...props
}: CheckboxFieldViewProps) => (
  <Wrapper>
    <Input {...props} disabled={disabled} type="checkbox" />
    <CheckBox checked={checked} disabled={disabled}>
      <CheckMark checked={checked} />
    </CheckBox>
    <Box>{label}</Box>
  </Wrapper>
);

const Wrapper = system(
  {
    is: "label",
    fontSize: 1
  },
  {
    display: "flex",
    alignItems: "center",
    position: "relative"
  },
  "fontSize",
  `
    user-select: none
  `
);

const Input = system(
  {
    is: "input"
  },
  {
    position: "absolute",
    opacity: 0
  }
);

const CheckBox = system(
  {
    is: Flex,
    mx: 2,
    variant: "default"
  },
  {
    height: 16,
    width: 16,
    borderRadius: 4,
    borderStyle: "solid",
    borderWidth: 1
  },
  variant({
    key: "inputs.field.checkbox"
  }),
  boolean({
    prop: "checked",
    key: "inputs.field.checkbox.checked"
  }),
  boolean({
    prop: "disabled",
    key: "inputs.field.checkbox.disabled"
  }),
  "space"
);

const CheckMark = system(
  {
    is: CheckMarkIcon
  },
  {
    width: 8,
    height: 6,
    margin: "auto",
    opacity: 0
  },
  boolean({
    prop: "checked",
    key: "inputs.field.checkbox.checked"
  })
);

export default CheckboxField;
