import React from "react";
import { Box } from "@rebass/emotion";
import { TextWidgetProps } from "react-awesome-query-builder";

import * as Field from "../../../../../components/Field";

export default (props: TextWidgetProps) => {
  const {
    value,
    setValue,
    readonly,
    placeholder,
    maxLength,
    customProps
  } = props;

  const onChange = (e: $TSFixMe) => {
    let val = e.target.value;
    if (val === "") val = undefined;
    setValue(val);
  };
  const textValue = value || "";

  return (
    <Box width={"120px"}>
      <Field.InputView
        placeholder={placeholder}
        input={{
          onChange,
          value: textValue,
          type: "text",
          disabled: readonly
        }}
        maxLength={maxLength}
        hideErrors
        {...customProps}
      />
    </Box>
  );
};
