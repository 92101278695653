import {
  Utils,
  ImmutableTree,
  JsonTree,
  Config
} from "react-awesome-query-builder";

const {
  loadFromJsonLogic,
  jsonLogicFormat,
  queryString,
  loadTree,
  uuid
} = Utils;

export const getDisabledTreeFromJson = (
  jsonLogic: JsonTree,
  config: Config
): ImmutableTree | undefined => {
  return loadFromJsonLogic(
    {
      locked: jsonLogic
    },
    config
  );
};

export const getJsonFromTree = (tree: ImmutableTree, config: Config) => {
  return jsonLogicFormat(tree, config).logic;
};

export const getQueryStringFromTree = (tree: ImmutableTree, config: Config) => {
  return queryString(tree, config, true);
};

export const getJsonStringFromTree = (tree: ImmutableTree, config: Config) => {
  return {
    json: getJsonFromTree(tree, config),
    string: getQueryStringFromTree(tree, config)
  };
};

export const getTreeFromJson = (
  jsonLogic: JsonTree,
  config: Config
): ImmutableTree | undefined => {
  return loadFromJsonLogic(jsonLogic, config);
};

export const getInitTree = (): ImmutableTree | undefined => {
  return loadTree({ id: uuid(), type: "group" });
};

export const getDimLocalization = (dim: string, val: number) => {
  switch (dim) {
    case "day":
      return val === 1 ? "день" : val > 1 && val < 5 ? "дні" : "днів";
    case "week":
      return val === 1 ? "тиждень" : val > 1 && val < 5 ? "тижні" : "тижнів";
    case "month":
      return val === 1 ? "місяць" : val > 1 && val < 5 ? "місяці" : "місяців";
    case "year":
      return val === 1 ? "рік" : val > 1 && val < 5 ? "роки" : "років";
  }
};
