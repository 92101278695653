import { gql } from "graphql-tag";

import IntervalOrOpenDate from "../IntervalOrOpenDate";
import { PaperReferral } from "../PaperReferral";
import Reference from "../Reference";
import ValueString from "../ValueString";
import ServiceRequestCode from "../ServiceRequestCode";
import DiagnosticReport from "./";
import CodeableConcept from "../../../../components/CodeableConcept";

DiagnosticReport.fragments = {
  entry: gql`
    fragment DiagnosticReport on DiagnosticReport {
      basedOn {
        ...Reference
      }
      cancellationReason {
        ...CodeableConcept
      }
      category {
        ...CodeableConcept
      }
      code {
        ...ServiceRequestCode
      }
      conclusion @skip(if: $skipAdditionalFields)
      conclusionCode @skip(if: $skipAdditionalFields) {
        ...CodeableConcept
      }
      databaseId
      effective {
        ...IntervalOrOpenDate
      }
      encounter {
        ...Reference
      }
      explanatoryLetter
      id
      insertedAt
      issued
      managingOrganization {
        ...Reference
      }
      originEpisode {
        ...Reference
      }
      paperReferral {
        ...PaperReferral
      }
      performer {
        ...Reference
        ...ValueString
      }
      primarySource
      recordedBy {
        ...Reference
      }
      reportOrigin {
        ...CodeableConcept
      }
      resultsInterpreter {
        ...Reference
        ...ValueString
      }
      status
      updatedAt
    }
    ${CodeableConcept.fragments!.entry}
    ${IntervalOrOpenDate.fragments.entry}
    ${PaperReferral.fragments.entry}
    ${Reference.fragments.entry}
    ${ValueString.fragments.entry}
    ${ServiceRequestCode.fragments.entry}
  `
};

export const DiagnosticReportByIDQuery = gql`
  query DiagnosticReportByIDQuery(
    $id: ID!
    $personId: ID!
    $skipAdditionalFields: Boolean!
  ) {
    diagnosticReport(id: $id, personId: $personId) {
      ...DiagnosticReport
    }
  }
  ${DiagnosticReport.fragments.entry}
`;
