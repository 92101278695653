import React from "react";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";

import Breadcrumbs from "../../../components/Breadcrumbs";

type DetailsPageBreadcrumbsProps = {
  currentStepName: React.ReactNode;
  id?: string;
  isClinicalMonitoring?: boolean;
};

const DetailsPageBreadcrumbs = ({
  id,
  currentStepName,
  isClinicalMonitoring
}: DetailsPageBreadcrumbsProps) => (
  <Box mb={10}>
    <Breadcrumbs.List>
      {isClinicalMonitoring ? (
        <Breadcrumbs.Item to={`/clinical-monitoring`}>
          <Trans>Clinical monitoring</Trans>
        </Breadcrumbs.Item>
      ) : (
        <>
          <Breadcrumbs.Item to="/persons">
            <Trans>Patient Search</Trans>
          </Breadcrumbs.Item>
          <Breadcrumbs.Item to={`/persons/${id}`}>
            <Trans>Details of the patient</Trans>
          </Breadcrumbs.Item>
          <Breadcrumbs.Item to={`/persons/${id}/practical-monitoring`}>
            <Trans>Practical monitoring</Trans>
          </Breadcrumbs.Item>
        </>
      )}
      <Breadcrumbs.Item>{currentStepName}</Breadcrumbs.Item>
    </Breadcrumbs.List>
  </Box>
);

export default DetailsPageBreadcrumbs;
