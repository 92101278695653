import React from "react";
import Composer from "react-composer";
import { Trans } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";
import { Box, Flex } from "@rebass/emotion";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Dictionary } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 4}>
      <SearchField.LegalEntity
        name="filter.legalEntityId"
        filteredParams={["id", "edrData.name"]}
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <Trans
        id="Enter email"
        render={({ translation }) => (
          <Field.Text
            name="filter.email"
            label={<Trans id="Employee email" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <Composer
        components={[
          <DictionaryValue name="EMPLOYEE_REQUEST_STATUS" />,
          ({
            render
          }: {
            render: (props: TransRenderProps) => React.ReactElement;
          }) => <Trans id="Select option" render={render} />
        ]}
      >
        {([dict, { translation }]: [
          Dictionary["values"],
          { translation: React.ReactNode }
        ]) => (
          <Field.Select
            name="filter.status"
            label={<Trans id="Request status" />}
            placeholder={translation}
            items={Object.keys(dict)}
            itemToString={(item: string) => dict[item] || translation}
            variant="select"
            emptyOption
            filterOptions={{ keys: [(item: string) => dict[item]] }}
          />
        )}
      </Composer>
    </Box>
    <Box px={1} width={1 / 4}>
      <Field.RangePicker
        rangeNames={["filter.date.insertedAtFrom", "filter.date.insertedAtTo"]}
        label={<Trans id="Employee has been inserted at" />}
      />
    </Box>
  </Flex>
);

export { PrimarySearchFields };
