import transform from "lodash/transform";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
var cleanDeep = function cleanDeep(object) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _ref$emptyArrays = _ref.emptyArrays,
    emptyArrays = _ref$emptyArrays === void 0 ? true : _ref$emptyArrays,
    _ref$emptyObjects = _ref.emptyObjects,
    emptyObjects = _ref$emptyObjects === void 0 ? true : _ref$emptyObjects,
    _ref$emptyStrings = _ref.emptyStrings,
    emptyStrings = _ref$emptyStrings === void 0 ? true : _ref$emptyStrings,
    _ref$nullValues = _ref.nullValues,
    nullValues = _ref$nullValues === void 0 ? true : _ref$nullValues,
    _ref$undefinedValues = _ref.undefinedValues,
    undefinedValues = _ref$undefinedValues === void 0 ? true : _ref$undefinedValues;
  return transform(object, function (result, value, key) {
    // Recurse into arrays and objects.
    if (Array.isArray(value) || isPlainObject(value)) {
      value = cleanDeep(value, {
        emptyArrays: emptyArrays,
        emptyObjects: emptyObjects,
        emptyStrings: emptyStrings,
        nullValues: nullValues,
        undefinedValues: undefinedValues
      });
    }

    // Exclude empty objects.
    if (emptyObjects && isPlainObject(value) && isEmpty(value)) {
      return;
    }

    // Exclude empty arrays.
    if (emptyArrays && Array.isArray(value) && !value.length) {
      return;
    }

    // Exclude empty strings.
    if (emptyStrings && value === "") {
      return;
    }

    // Exclude null values.
    if (nullValues && value === null) {
      return;
    }

    // Exclude undefined values.
    if (undefinedValues && value === undefined) {
      return;
    }

    // Append when recursing arrays.
    if (Array.isArray(result)) {
      return result.push(value);
    }
    result[key] = value;
  });
};
export default cleanDeep;