import { gql } from "graphql-tag";
import Pagination from "../../../components/Pagination";
import ServiceRequest from "../../PracticalMonitoring/Details/ServiceRequest";
import ServiceRequestCode from "../../PracticalMonitoring/Details/ServiceRequestCode";

export const ImpersonalServiceRequestsQuery = gql`
  query ServiceRequestsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImpersonalServiceRequestFilter!
    $orderBy: ServiceRequestOrderBy
  ) {
    impersonalServiceRequests(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status
        insertedAt
        code {
          ...ServiceRequestCode
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
  ${ServiceRequestCode.fragments.entry}
`;

export const ImpersonalServiceRequestByIDQuery = gql`
  query ImpersonalServiceRequestQuery(
    $id: ID!
    $patientId: String!
    $skipAdditionalFields: Boolean!
  ) {
    impersonalServiceRequest(id: $id, patientId: $patientId) {
      ...ServiceRequest
    }
  }
  ${ServiceRequest.fragments.entry}
`;
