import React, { Component } from "react";
import { Flex, Box, Text } from "@rebass/emotion";
import { ifProp } from "styled-tools";

import { LoaderIcon } from "@edenlabllc/ehealth-icons";
import system from "@edenlabllc/ehealth-system-components";

type LoadingOverlayProps = {
  loading: boolean;
  table?: boolean;
  fallback?: React.ReactElement;
  children?: any;
  style?: React.CSSProperties;
};

type LoadingOverlayState = {
  delayLoading: undefined | number | boolean;
  loadingFromState: boolean;
  delayBeforeLoading: number;
  delayAfterLoading: number;
};

class LoadingOverlay extends Component<
  LoadingOverlayProps,
  LoadingOverlayState
> {
  // @ts-ignore
  timer: NodeJS.Timeout;
  state = {
    delayLoading: undefined,
    loadingFromState: false,
    delayBeforeLoading: 1000,
    delayAfterLoading: 500
  };

  componentDidMount() {
    const { loading } = this.props;
    if (loading) {
      this.setState({ loadingFromState: true });
    }
  }

  shouldComponentUpdate({ loading }: LoadingOverlayProps) {
    const { loadingFromState, delayAfterLoading } = this.state;
    if (
      loading !== loadingFromState ||
      this.props.loading !== loadingFromState
    ) {
      if (!loading) {
        this.timer = setTimeout(
          () => this.setState({ loadingFromState: false }),
          delayAfterLoading
        );
      } else {
        clearTimeout(this.timer);
        this.setState({ loadingFromState: loading });
      }
    }

    return true;
  }

  render() {
    const {
      table,
      loading,
      fallback: Loader = Spinner,
      children,
      ...props
    } = this.props;

    const { delayLoading, loadingFromState, delayBeforeLoading } = this.state;

    return (
      <Wrapper>
        {loadingFromState && (
          <DelayedSpinner
            delay={delayBeforeLoading}
            delayFn={(status: boolean) => {
              this.setState({ delayLoading: status || undefined });
            }}
          >
            {/* @ts-ignore */}
            <Loader {...props} />
          </DelayedSpinner>
        )}

        <Blur filter={delayLoading}>{children}</Blur>
      </Wrapper>
    );
  }
}

type DelayedSpinnerProps = {
  delay: number;
  delayFn: (status: boolean) => void;
  children?: React.ReactElement;
};

type DelayedSpinnerState = {
  showSpinner: boolean;
};

class DelayedSpinner extends Component<
  DelayedSpinnerProps,
  DelayedSpinnerState
> {
  // @ts-ignore
  timer: NodeJS.Timeout;
  state = {
    showSpinner: false
  };

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ showSpinner: true });
      this.props.delayFn(true);
    }, this.props.delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.props.delayFn(false);
  }

  render() {
    return this.state.showSpinner && this.props.children;
  }
}

export const Spinner = (props: LoadingOverlayProps) => (
  <WrapperLoader
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    {...props}
  >
    <Text mb="2" fontWeight="600" fontSize="14">
      Завантаження результатів
    </Text>

    <LoaderIcon width="50" height="10" />
  </WrapperLoader>
);

const WrapperLoader = system(
  {
    extend: Flex
  },
  {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1
  },
  "justifyContent",
  "alignItems",
  "flexDirection"
);

const Blur = system(
  {
    extend: Box,
    blacklist: ["filter"]
  },
  (props: { filter: number }) => ({
    minHeight: 50,
    filter: ifProp("filter", "blur(2px)", "none")(props)
  })
);

const Wrapper = system(
  {
    extend: Box
  },
  {
    position: "relative",
    display: "table",
    width: "100%",
    tableLayout: "fixed"
  }
);

export default LoadingOverlay;
