import React, { Component } from "react";
import { ApolloProvider } from "@apollo/client";
import { getDataFromTree } from "@apollo/client/react/ssr";

import ErrorBoundary from "./ErrorBoundary";
import { createClient } from "./client";
import { Spinner } from "./components/LoadingOverlay";

class DataProvider extends Component {
  state = {
    loading: true
  };

  // @ts-expect-error TS(2339): Property 'onError' does not exist on type 'Readonl... Remove this comment to see the full error message
  client = createClient({ onError: this.props.onError });

  async componentDidMount() {
    try {
      await getDataFromTree(<ApolloProvider {...this.providerProps} />);
    } catch {
      // We are ignoring the exceptions happened during cache hydration
    }

    this.setState({ loading: false });
  }

  render() {
    return this.state.loading ? (
      <Spinner
        loading={true}
        style={{ stroke: "white", fill: "white", color: "white" }}
      />
    ) : (
      <ApolloProvider {...this.providerProps} />
    );
  }

  get providerProps() {
    const { client } = this;
    // @ts-expect-error TS(2339): Property 'children' does not exist on type 'Readon... Remove this comment to see the full error message
    const { children } = this.props;

    return { client, children };
  }
}

export default (props: $TSFixMe) => (
  <ErrorBoundary.Consumer>
    {(setError) => <DataProvider {...props} onError={setError} />}
  </ErrorBoundary.Consumer>
);
