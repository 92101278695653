import React from "react";
import { SelectWidgetProps } from "react-awesome-query-builder";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { mapListValues } from "react-awesome-query-builder/modules/utils/stuff";

import * as Field from "../../../../../components/Field";
import { ListValue } from "../../../getFields";

export default ({
  listValues,
  value,
  setValue,
  readonly,
  placeholder
}: SelectWidgetProps) => {
  const renderOptions = () =>
    mapListValues(listValues, (item: ListValue) => {
      return { key: item.value, value: item.title };
    });

  const onChange = (selectedItem: { key: string; value: string }) =>
    setValue(selectedItem.key);

  return (
    <Field.SelectFieldView
      placeholder={!readonly ? placeholder : ""}
      // @ts-ignore
      value={listValues[value] || value || ""}
      onChange={onChange}
      disabled={readonly}
      items={renderOptions()}
      itemToString={(item) => (item ? item.value : "")}
      variant={"select"}
      hideErrors
    />
  );
};
