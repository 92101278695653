import React from "react";

import system from "@edenlabllc/ehealth-system-components";
import { RadioIcon } from "@edenlabllc/ehealth-icons";

type RadioFieldProps = {
  value: string;
  selected: boolean;
  name?: string;
  id?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  children?: React.ReactNode;
};

export const RadioField = ({
  selected = false,
  disabled,
  value,
  name,
  children,
  onChange = (e) => e
}: RadioFieldProps) => (
  <Label disabled={disabled}>
    <input
      type="radio"
      {...{
        onChange: () => !disabled && onChange(value),
        checked: selected,
        value,
        name,
        disabled
      }}
    />
    <RadioMarkIcon selected={selected} disabled={disabled} />
    {children}
  </Label>
);

export default RadioField;

export const Label = system(
  {
    is: "label"
  },
  ({ disabled }: { disabled: boolean }) => ({
    color: disabled && "#9299a3",
    cursor: "pointer",
    fontSize: 14,
    display: "flex !important"
  }),
  `
    user-select: none
  `
);

const RadioMarkIcon = system(
  {
    is: RadioIcon
  },
  ({ selected }: { selected: boolean }) => ({
    width: 16,
    height: 16,
    marginRight: 10,
    border: !selected ? "1px solid #ced0da" : "none",
    borderRadius: !selected ? 8 : 0
  })
);
