import _defineProperty from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _slicedToArray from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var pickProps = function pickProps(object, whitelist) {
  var filterFn = Array.isArray(whitelist) ? function (key) {
    return whitelist.includes(key);
  } : whitelist;
  return Object.entries(object).reduce(function (_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 2),
      selected = _ref3[0],
      rejected = _ref3[1];
    var _ref4 = _slicedToArray(_ref2, 2),
      key = _ref4[0],
      value = _ref4[1];
    return filterFn(key) ? [_objectSpread({}, selected, _defineProperty({}, key, value)), rejected] : [selected, _objectSpread({}, rejected, _defineProperty({}, key, value))];
  }, [{}, {}]);
};
export default pickProps;