import React from "react";
import { Box, Flex } from "@rebass/emotion";

import * as Field from "../../../../../components/Field";

export type BooleanProps = {
  value: string | boolean;
  setValue: (checked: string | boolean) => void;
  labelYes: string;
  labelNo: string;
  readonly: boolean;
  customProps: {
    radioYes?: object;
    radioNo?: object;
  };
};

export default ({
  value,
  setValue,
  labelYes,
  labelNo,
  readonly,
  customProps = {}
}: BooleanProps) => {
  const customRadioYesProps = customProps.radioYes || {};
  const customRadioNoProps = customProps.radioNo || {};
  const onRadioChange = (checked: string) => setValue(checked);

  return (
    <Flex ml={2}>
      <Box mr={3}>
        <Field.RadioField
          selected={!!value}
          disabled={readonly}
          value={"true"}
          onChange={onRadioChange}
          {...customRadioYesProps}
        >
          {labelYes}
        </Field.RadioField>
      </Box>
      <Box>
        <Field.RadioField
          selected={!value}
          disabled={readonly}
          value={"false"}
          onChange={onRadioChange}
          {...customRadioNoProps}
        >
          {labelNo}
        </Field.RadioField>
      </Box>
    </Flex>
  );
};
