import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { BooleanValue } from "react-values";
import { loader } from "graphql.macro";
import { Flex, Box, Heading, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import system from "@edenlabllc/ehealth-system-components";
import {
  PositiveIcon,
  NegativeIcon,
  DefaultImageIcon
} from "@edenlabllc/ehealth-icons";
import { getFullName, getPhones } from "@edenlabllc/ehealth-utils";
import {
  Form,
  Modal,
  Switch,
  Validation
} from "@edenlabllc/ehealth-components";
import {
  Declaration,
  DeclarationAttachedDocument,
  Dictionary,
  Maybe,
  TerminateDeclarationInput
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../components/Ability";
import AddressView from "../../components/AddressView";
import Badge from "../../components/Badge";
import Breadcrumbs from "../../components/Breadcrumbs";
import Button, { ButtonVariantType } from "../../components/Button";
import DefinitionListView from "../../components/DefinitionListView";
import DictionaryValue from "../../components/DictionaryValue";
import EmptyData from "../../components/EmptyData";
import * as Field from "../../components/Field";
import Line from "../../components/Line";
import Link from "../../components/Link";
import LoadingOverlay from "../../components/LoadingOverlay";
import Tabs from "../../components/Tabs";

import dateFormatter from "../../helpers/dateFormatter";

const DeclarationQuery = loader("../../graphql/DeclarationQuery.graphql");
const TerminateDeclarationMutation = loader(
  "../../graphql/TerminateDeclarationMutation.graphql"
);
const RejectDeclarationMutation = loader(
  "../../graphql/RejectDeclarationMutation.graphql"
);
const ApproveDeclarationMutation = loader(
  "../../graphql/ApproveDeclarationMutation.graphql"
);

const AVAILABLE_DECLARATION_REASON_TERMINATION = [
  "manual_person",
  "MANUAL_EMPLOYEE_APPEAL"
];

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

const Details = ({ id }: DetailsProps) => (
  <Query query={DeclarationQuery} variables={{ id }}>
    {({ loading, data }: QueryResult<{ declaration: Declaration }>) => {
      if (isEmpty(data) || isEmpty(data.declaration)) return null;

      const {
        id,
        databaseId,
        declarationNumber,
        startDate,
        endDate,
        status,
        scope,
        reason,
        reasonDescription,
        // @ts-expect-error types mismatch
        declarationRequestId,
        legalEntity,
        division,
        employee,
        person,
        declarationAttachedDocuments,
        signedAt
      } = data.declaration;

      const general = {
        declarationNumber,
        startDate,
        endDate,
        status,
        scope,
        reason,
        reasonDescription,
        signedAt
      };

      return (
        <LoadingOverlay loading={loading}>
          <Box p={6}>
            <Box mb={10}>
              <Breadcrumbs.List>
                <Breadcrumbs.Item to="/declarations">
                  <Trans>Search for Declarations</Trans>
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                  <Trans>Declaration Details</Trans>
                </Breadcrumbs.Item>
              </Breadcrumbs.List>
            </Box>
            <Flex justifyContent="space-between" alignItems="flex-end">
              <Box>
                <DefinitionListView
                  labels={{
                    databaseId: <Trans>Declaration ID</Trans>,
                    declarationRequestId: <Trans>Declaration request ID</Trans>,
                    status: <Trans>Status</Trans>
                  }}
                  data={{
                    databaseId,
                    declarationRequestId,
                    status: (
                      <Badge name={status} type="DECLARATION" minWidth={100} />
                    )
                  }}
                  color="#7F8FA4"
                  labelWidth="100px"
                />
              </Box>
              <Box>
                <Switch
                  value={status}
                  PENDING_VERIFICATION={
                    <Flex>
                      <Ability action="reject" resource="declaration">
                        <Box mr={20}>
                          <Popup
                            variant="red"
                            buttonText={<Trans>Reject</Trans>}
                            title={<Trans>Declining declaration</Trans>}
                          >
                            {(toggle: () => void) => (
                              <Mutation
                                mutation={RejectDeclarationMutation}
                                refetchQueries={() => [
                                  {
                                    query: DeclarationQuery,
                                    variables: {
                                      id
                                    }
                                  }
                                ]}
                              >
                                {(rejectDeclaration: MutationFunction) => (
                                  <Flex justifyContent="center">
                                    <Box mr={20}>
                                      <Button variant="blue" onClick={toggle}>
                                        <Trans>Return</Trans>
                                      </Button>
                                    </Box>
                                    <Button
                                      onClick={async () => {
                                        await rejectDeclaration({
                                          variables: {
                                            input: { id }
                                          }
                                        });
                                        toggle();
                                      }}
                                      variant="red"
                                    >
                                      <Trans>Decline declaration</Trans>
                                    </Button>
                                  </Flex>
                                )}
                              </Mutation>
                            )}
                          </Popup>
                        </Box>
                      </Ability>
                      <Ability action="approve" resource="declaration">
                        <Popup
                          variant="green"
                          buttonText={<Trans>Approve</Trans>}
                          title={<Trans>Approval of the declaration</Trans>}
                        >
                          {(toggle: () => void) => (
                            <Mutation
                              mutation={ApproveDeclarationMutation}
                              refetchQueries={() => [
                                {
                                  query: DeclarationQuery,
                                  variables: {
                                    id
                                  }
                                }
                              ]}
                            >
                              {(approveDeclaration: MutationFunction) => (
                                <Flex justifyContent="center">
                                  <Box mr={20}>
                                    <Button variant="blue" onClick={toggle}>
                                      <Trans>Return</Trans>
                                    </Button>
                                  </Box>
                                  <Button
                                    onClick={async () => {
                                      await approveDeclaration({
                                        variables: {
                                          input: { id }
                                        }
                                      });
                                      toggle();
                                    }}
                                    variant="green"
                                  >
                                    <Trans>Approve the declaration</Trans>
                                  </Button>
                                </Flex>
                              )}
                            </Mutation>
                          )}
                        </Popup>
                      </Ability>
                    </Flex>
                  }
                />
              </Box>
            </Flex>
          </Box>
          <Tabs.Nav>
            <Tabs.NavItem to="./">
              <Trans>General information</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./legal-entity">
              <Trans>Legal entity</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./divisions">
              <Trans>Division</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./employee">
              <Trans>Doctor</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./patient">
              <Trans>Patient</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./documents">
              <Trans>Documents</Trans>
            </Tabs.NavItem>
          </Tabs.Nav>
          <Tabs.Content>
            <Box p={5}>
              <Router>
                <GeneralInfo path="/" general={general} />
                <LegalEntity path="/legal-entity" legalEntity={legalEntity} />
                <Division path="/divisions" division={division} />
                <Employee path="/employee" employee={employee} />
                <Patient path="/patient" patient={person} />
                <Documents
                  path="/documents"
                  documents={declarationAttachedDocuments}
                />
              </Router>
            </Box>
          </Tabs.Content>
        </LoadingOverlay>
      );
    }}
  </Query>
);

type PopupProps = {
  variant: ButtonVariantType;
  buttonText: React.ReactNode;
  title: React.ReactNode;
  children: (toggle: () => void) => React.ReactNode;
  render?: (toggle: () => void) => React.ReactNode;
};

const Popup = ({
  variant,
  buttonText,
  title,
  children,
  render = children
}: PopupProps) => (
  <BooleanValue>
    {({ value: opened, toggle }: $TSFixMe) => (
      <>
        <Button variant={variant} disabled={opened} onClick={toggle}>
          {buttonText}
        </Button>
        {opened && (
          <Modal width={760} backdrop>
            <Heading as="h1" fontWeight="normal" mb={6}>
              {title}
            </Heading>
            {render(toggle)}
          </Modal>
        )}
      </>
    )}
  </BooleanValue>
);

type GeneralInfoProps = RouteComponentProps<{
  id: string;
  general: {
    declarationNumber?: Declaration["declarationNumber"];
    startDate?: Declaration["startDate"];
    endDate?: Declaration["endDate"];
    status?: Declaration["status"];
    scope?: Declaration["scope"];
    reason?: Declaration["reason"];
    signedAt?: Declaration["signedAt"];
  };
}>;

const GeneralInfo = ({
  id,
  general: {
    declarationNumber,
    startDate,
    endDate,
    status,
    reason,
    // @ts-expect-error types mismatch
    reasonDescription,
    scope,
    signedAt
  } = {}
}: GeneralInfoProps) => (
  <>
    <DefinitionListView
      labels={{
        declarationNumber: <Trans>Declaration number</Trans>,
        startDate: <Trans>Initial date of the declaration</Trans>,
        endDate: <Trans>End date of the declaration</Trans>,
        signedAt: <Trans>Signed at declaration</Trans>
      }}
      data={{
        declarationNumber,
        startDate: i18n.date(startDate),
        endDate: i18n.date(endDate),
        signedAt: dateFormatter(
          i18n.locale,
          {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
          },
          signedAt
        )
      }}
    />
    {(reason || reasonDescription) && (
      <>
        <Line />
        <DefinitionListView
          labels={{
            reason: <Trans>Change status reason</Trans>,
            reasonDescription: <Trans>Change status reason description</Trans>
          }}
          data={{
            ...(reason && {
              reason: (
                <DictionaryValue name="DECLARATION_REASONS" item={reason} />
              )
            }),
            ...(reasonDescription && { reasonDescription })
          }}
        />
        <Line />
      </>
    )}
    <DefinitionListView
      labels={{
        scope: <Trans>Type</Trans>
      }}
      data={{
        scope
      }}
    />
    {status === "ACTIVE" && (
      <Ability action="terminate" resource="declaration">
        <Box mt={6}>
          <Popup
            variant="red"
            buttonText={<Trans>Terminate declaration</Trans>}
            title={<Trans>Declaration termination</Trans>}
          >
            {(toggle: () => void) => (
              <Mutation
                mutation={TerminateDeclarationMutation}
                refetchQueries={() => [
                  {
                    query: DeclarationQuery,
                    variables: {
                      id
                    }
                  }
                ]}
              >
                {(terminateDeclaration: MutationFunction) => (
                  <Form
                    onSubmit={async ({
                      reasonDescription,
                      reason
                    }: Partial<TerminateDeclarationInput>) => {
                      await terminateDeclaration({
                        variables: {
                          input: { id, reasonDescription, reason }
                        }
                      });
                      toggle();
                    }}
                  >
                    <Text mb={3}>
                      <Trans>
                        Attention! After declaration termination this action can
                        not be canceled
                      </Trans>
                    </Text>
                    <Box mb={3}>
                      <Trans
                        id="Select reason"
                        render={({ translation }) => (
                          <DictionaryValue name="DECLARATION_REASONS">
                            {(dict: Dictionary["values"]) => (
                              <Field.Select
                                name="reason"
                                label={<Trans id="Reason of termination" />}
                                placeholder={translation}
                                items={
                                  dict
                                    ? Object.keys(dict).filter((r) =>
                                        AVAILABLE_DECLARATION_REASON_TERMINATION.includes(
                                          r
                                        )
                                      )
                                    : []
                                }
                                itemToString={(item: string) =>
                                  dict ? dict[item] : item
                                }
                                variant="select"
                                filterOptions={{
                                  keys: [(item: string) => dict[item]]
                                }}
                              />
                            )}
                          </DictionaryValue>
                        )}
                      />
                      <Validation.Required
                        field="reason"
                        message="Required field"
                      />
                    </Box>
                    <Box mb={3}>
                      <Trans
                        id="Enter terminate declaration reason"
                        render={({ translation }) => (
                          <Field.Textarea
                            name="reasonDescription"
                            label={
                              <Trans id="Declaration termination reason description" />
                            }
                            placeholder={translation}
                            rows={5}
                            maxLength={3000}
                            showLengthHint
                          />
                        )}
                      />
                    </Box>
                    <Flex justifyContent="center">
                      <Box mr={20}>
                        <Button variant="blue" onClick={toggle}>
                          <Trans>Return</Trans>
                        </Button>
                      </Box>
                      <Ability action="terminate" resource="declaration">
                        <Button type="submit" variant="red">
                          <Trans>Terminate declaration</Trans>
                        </Button>
                      </Ability>
                    </Flex>
                  </Form>
                )}
              </Mutation>
            )}
          </Popup>
        </Box>
      </Ability>
    )}
  </>
);

type LegalEntityProps = RouteComponentProps & {
  legalEntity: Declaration["legalEntity"];
};

const LegalEntity = ({
  legalEntity: { id, databaseId, edrpou, publicName, addresses }
}: LegalEntityProps) => {
  // @ts-ignore
  const [registrationAddress] =
    addresses && addresses.filter((a) => a && a.type === "REGISTRATION");
  return (
    <>
      <DefinitionListView
        labels={{
          edrpou: <Trans>EDRPOU</Trans>,
          publicName: <Trans>Name</Trans>,
          addresses: <Trans>Address</Trans>
        }}
        data={{
          edrpou,
          publicName,
          addresses: registrationAddress && (
            <AddressView data={registrationAddress} />
          )
        }}
      />
      <DefinitionListView
        labels={{
          databaseId: <Trans>Legal entity ID</Trans>,
          link: ""
        }}
        data={{
          databaseId,
          link: (
            <Link to={`/legal-entities/${id}`} fontWeight={700}>
              <Trans>Show detailed information</Trans>
            </Link>
          )
        }}
        color="#7F8FA4"
      />
    </>
  );
};

type DivisionProps = RouteComponentProps & {
  division: Declaration["division"];
};

const Division = ({
  division: {
    id,
    databaseId,
    type,
    addresses,
    phones,
    mountainGroup,
    ...division
  }
}: DivisionProps) => {
  const [residenceAddress] = addresses.filter(
    (a) => a && a.type === "RESIDENCE"
  );
  return (
    <>
      <DefinitionListView
        labels={{
          name: <Trans>Name</Trans>,
          type: <Trans>Type</Trans>,
          addresses: <Trans>Address</Trans>,
          mountainGroup: <Trans>Mountain region</Trans>,
          phones: <Trans>Phone number</Trans>,
          email: <Trans>Email</Trans>
        }}
        data={{
          type: <DictionaryValue name="DIVISION_TYPE" item={type} />,
          addresses: residenceAddress && (
            <AddressView data={residenceAddress} />
          ),
          phones: getPhones(phones),
          mountainGroup: mountainGroup ? <PositiveIcon /> : null,
          ...division
        }}
      />
      <DefinitionListView
        labels={{
          databaseId: "ID відділення"
        }}
        data={{
          databaseId
        }}
        color="#7F8FA4"
      />
    </>
  );
};

type EmployeeProps = RouteComponentProps & {
  employee: Declaration["employee"];
};

const Employee = ({
  employee: { id, databaseId, position, party, additionalInfo }
}: EmployeeProps) => (
  <>
    <DefinitionListView
      labels={{
        fullName: <Trans>Full name</Trans>,
        speciality: <Trans>Specialty</Trans>,
        position: <Trans>Position</Trans>
      }}
      data={{
        fullName: getFullName(party),
        speciality: additionalInfo && additionalInfo.specialities && (
          <DictionaryValue
            name="SPECIALITY_TYPE"
            item={
              additionalInfo.specialities.find(
                (item) => item && item.specialityOfficio
              )!.speciality
            }
          />
        ),
        position: <DictionaryValue name="POSITION" item={position} />
      }}
    />
    <DefinitionListView
      labels={{
        databaseId: <Trans>Employee ID</Trans>,
        link: ""
      }}
      data={{
        databaseId,
        link: (
          <Link is="a" href={`/employees/${id}`} fontWeight={700}>
            <Trans>Show detailed information</Trans>
          </Link>
        )
      }}
      color="#7F8FA4"
    />
  </>
);

type PatientProps = RouteComponentProps & {
  patient: Declaration["person"];
};

const Patient = ({
  patient: {
    id,
    databaseId,
    birthDate,
    taxId,
    phones,
    birthCountry,
    birthSettlement,
    unzr,
    noTaxId,
    ...fullName
  }
}: PatientProps) => (
  <>
    <DefinitionListView
      labels={{
        fullName: <Trans>Full name</Trans>,
        birthDate: <Trans>Date of birth</Trans>,
        birthCountry: <Trans>Country of birth</Trans>,
        birthSettlement: <Trans>Place of birth</Trans>,
        unzr: <Trans>Record ID in EDDR</Trans>,
        taxId: <Trans>INN</Trans>,
        noTaxId: <Trans>No tax ID</Trans>,
        phones: <Trans>Phone number</Trans>
      }}
      data={{
        fullName: getFullName(fullName),
        birthDate: i18n.date(birthDate),
        birthCountry,
        birthSettlement,
        unzr,
        taxId,
        noTaxId: noTaxId ? (
          <NegativeIcon fill="#ED1C24" stroke="#ED1C24" />
        ) : null,
        phones: getPhones(phones)
      }}
    />
    <DefinitionListView
      labels={{
        databaseId: <Trans>Patient ID</Trans>,
        link: ""
      }}
      data={{
        databaseId,
        link: (
          <Link to={`/persons/${id}`} fontWeight={700}>
            <Trans>Show detailed information</Trans>
          </Link>
        )
      }}
      color="#7F8FA4"
    />
  </>
);

type DocumentsProps = RouteComponentProps & {
  documents: Declaration["declarationAttachedDocuments"];
};

const Documents = ({ documents }: DocumentsProps) => {
  if (isEmpty(documents)) return <EmptyData />;

  return (
    <>
      {documents!.map((document: Maybe<DeclarationAttachedDocument>) => (
        <Box m="2">
          <SaveLink href={document && document.url} target="_blank">
            <Box m={1} color="shiningKnight">
              <DefaultImageIcon />
            </Box>
            <Text color="rockmanBlue" lineHeight="1">
              <DictionaryValue
                name="CONTRACT_DOCUMENT"
                item={document && document.type}
              />
            </Text>
          </SaveLink>
        </Box>
      ))}
    </>
  );
};

const SaveLink = system(
  {
    is: "a"
  },
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    lineHeight: 0,
    textDecoration: "none"
  }
);

export default Details;
