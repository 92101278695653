import * as React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, isEmpty } from "lodash";

import { LegalEntity, LegalEntityConnection } from "@ehealth/ehealth-ua.schema";

import * as Field from "../Field";
import onlyValidKeys from "../../helpers/onlyValidKeys";

type SearchLegalEntityFieldProps = {
  name: string;
  filteredParams: string[];
  additionalFilters?: object;
};

const SearchLegalEntityField = ({
  name,
  filteredParams,
  additionalFilters
}: SearchLegalEntityFieldProps) => (
  <Trans
    id="Enter legal entity name"
    render={({ translation }) => (
      <Query
        query={LegalEntitiesQuery}
        fetchPolicy="cache-first"
        variables={{
          skip: true
        }}
      >
        {({
          data,
          refetch: refetchLegalEntities
        }: QueryResult<{ legalEntities: LegalEntityConnection }>) => {
          const { legalEntities: { nodes: legalEntities = [] } = {} } =
            data || {};
          const itemToString = (item: LegalEntity) =>
            item && item.edrData ? item.edrData.name : "";
          const filteredItems = legalEntities!.map((item) =>
            onlyValidKeys(item, filteredParams)
          );

          return (
            <Field.Select
              name={name}
              label={<Trans id="Legal entity name" />}
              placeholder={translation}
              items={filteredItems}
              itemToString={itemToString}
              filter={(item: LegalEntity[]) => item}
              onInputValueChange={debounce(
                (name, { selectedItem, inputValue }) =>
                  !isEmpty(name) &&
                  itemToString(selectedItem) !== inputValue &&
                  refetchLegalEntities({
                    skip: false,
                    first: 20,
                    filter: {
                      ...additionalFilters,
                      edrData: { name }
                    }
                  }),
                300
              )}
            />
          );
        }}
      </Query>
    )}
  />
);

const LegalEntitiesQuery = gql`
  query LegalEntitiesQuery(
    $first: Int
    $filter: LegalEntityFilter
    $skip: Boolean! = false
  ) {
    legalEntities(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        name
        edrData {
          name
        }
      }
    }
  }
`;

export default SearchLegalEntityField;
