import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { MedicationRequestConnection } from "@ehealth/ehealth-ua.schema";

import Badge from "../../../components/Badge";
import Link from "../../../components/Link";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type MedicationRequestsTableProps = {
  data: MedicationRequestConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  tableName?: string;
};

const MedicationRequestsTable = ({
  data,
  locationParams,
  setLocationParams,
  tableName
}: MedicationRequestsTableProps) => {
  const { i18n } = useLingui();
  if (!(data && data.length)) return null;

  return (
    <Table
      data={data}
      header={{
        createdAt: <Trans>Added</Trans>,
        databaseId: <Trans>ID</Trans>,
        medicationRequestNumber: <Trans>Medication request number</Trans>,
        divisionId: <Trans>Division ID</Trans>,
        legalEntityId: <Trans>Legal entity ID</Trans>,
        medicationId: <Trans>INNM Dosage ID</Trans>,
        patientId: <Trans>Patient ID</Trans>,
        status: <Trans>Status</Trans>,
        action: <Trans>Action</Trans>
      }}
      renderRow={({
        id,
        requestNumber,
        databaseId,
        status,
        createdAt,
        medication,
        division,
        legalEntity,
        patientId
      }) => ({
        createdAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, createdAt),
        databaseId,
        medicationRequestNumber: requestNumber,
        divisionId: division.databaseId,
        legalEntityId: legalEntity.databaseId,
        medicationId: medication.databaseId,
        patientId,
        status: (
          <Badge
            name={status}
            dictionary="eHealth/medication_request_statuses"
            minWidth={100}
          />
        ),
        action: (
          <Link to={`../${id}`} fontWeight="bold">
            <Trans>Details</Trans>
          </Link>
        )
      })}
      sortableFields={["createdAt", "status"]}
      sortingParams={parseSortingParams(locationParams.orderBy)}
      onSortingChange={(sortingParams) =>
        setLocationParams({
          ...locationParams,
          orderBy: stringifySortingParams(sortingParams)
        })
      }
      tableName={tableName}
    />
  );
};

MedicationRequestsTable.fragments = {
  entry: gql`
    fragment MedicationRequests on MedicationRequest {
      id
      databaseId
      status
      createdAt
      requestNumber
      division {
        databaseId
      }
      legalEntity {
        databaseId
      }
      medication {
        databaseId
      }
      patientId
    }
  `
};

export default MedicationRequestsTable;
