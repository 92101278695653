import React from "react";
import {
  AddIcon,
  ThinAddIcon,
  RemoveItemIcon
} from "@edenlabllc/ehealth-icons";

import Button from "../../../../../components/Button";

type ButtonProps = {
  type:
    | "delGroup"
    | "delRuleGroup"
    | "delRule"
    | "addRuleGroup"
    | "addRule"
    | "addGroup"
    | "addRuleGroupExt";
  label: React.ReactNode;
  onClick: () => void;
  readonly?: boolean;
};

export default ({ type, label, onClick, readonly }: ButtonProps) => {
  const typeToOnlyIcon: any = {
    delGroup: <RemoveItemIcon />,
    delRuleGroup: <RemoveItemIcon />,
    delRule: <RemoveItemIcon />,
    addRuleGroup: <AddIcon />
  };
  const typeToIcon: any = {
    addRule: ThinAddIcon,
    addGroup: ThinAddIcon,
    addRuleGroupExt: ThinAddIcon
  };
  const typeToColor: any = {
    addRule: "green",
    addGroup: "blue",
    delGroup: "red",
    delRuleGroup: "red",
    delRule: "red"
  };
  if (typeToOnlyIcon[type])
    return (
      <Button disabled={readonly} onClick={onClick} variant={typeToColor[type]}>
        {typeToOnlyIcon[type]}
      </Button>
    );
  else
    return (
      <Button
        icon={typeToIcon[type]}
        disabled={readonly}
        onClick={onClick}
        variant={typeToColor[type]}
      >
        {label}
      </Button>
    );
};
