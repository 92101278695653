import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import isEmpty from "lodash/isEmpty";
import { Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  Maybe,
  Medication,
  MedicationIngredient
} from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";
import Link from "../../../components/Link";
import Table from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import paramToBase64 from "../../../helpers/paramToBase64";

type IngredientsProps = RouteComponentProps<{
  data: Medication["ingredients"];
}>;

const Ingredients = ({ data = [] }: IngredientsProps) => {
  if (isEmpty(data)) return null;
  const ingredients = data.sort((ingredient: Maybe<MedicationIngredient>) =>
    ingredient && ingredient.isPrimary ? -1 : 1
  );

  return (
    <Table
      data={ingredients}
      header={{
        databaseId: <Trans>ID</Trans>,
        name: <Trans>INNM</Trans>,
        dosage: <Trans>Mixture</Trans>,
        insertedAt: <Trans>Inserted at</Trans>,
        isPrimary: <Trans>Primary</Trans>
      }}
      renderRow={({ isPrimary, dosage, innmDosage }: MedicationIngredient) => {
        const { insertedAt, databaseId, ...innmDetails } = innmDosage || {};
        const {
          numeratorUnit,
          numeratorValue,
          denumeratorUnit,
          denumeratorValue
        } = dosage || {};

        return {
          ...innmDetails,
          databaseId: (
            <Link
              to={`/innm-dosages/${paramToBase64("INNMDosage", databaseId)}`}
            >
              {databaseId}
            </Link>
          ),
          insertedAt: dateFormatter(
            i18n.locale,
            {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric"
            },
            insertedAt
          ),
          isPrimary: isPrimary && (
            <Flex justifyContent="center">
              <PositiveIcon />
            </Flex>
          ),
          dosage: (
            <>
              {denumeratorValue}{" "}
              <DictionaryValue name="MEDICATION_UNIT" item={denumeratorUnit} />{" "}
              <Trans>includes</Trans> {numeratorValue}{" "}
              <DictionaryValue name="MEDICATION_UNIT" item={numeratorUnit} />
            </>
          )
        };
      }}
      whiteSpaceNoWrap={["databaseId"]}
      hiddenFields="insertedAt"
      tableName="MedicationIngredients/search"
    />
  );
};

Ingredients.fragments = {
  entry: gql`
    fragment MedicationIngredients on Medication {
      ingredients {
        dosage {
          denumeratorUnit
          denumeratorValue
          numeratorUnit
          numeratorValue
        }
        innmDosage {
          id
          databaseId
          insertedAt
          isActive
          name
          updatedAt
        }
        isPrimary
      }
    }
  `
};

export default Ingredients;
