import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Text } from "@rebass/emotion";

import { Form } from "@edenlabllc/ehealth-components";
import { Scalars, ProgramService } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import * as Field from "../../../../components/Field";
import Popup from "../../../../components/Popup";

import { UpdateProgramServiceMutation } from "./UpdateRequestAllowance";

type UpdateProgramServiceDescriptionProps = {
  id: Scalars["ID"];
  description: ProgramService["description"];
};

const UpdateProgramServiceDescription = ({
  id,
  description
}: UpdateProgramServiceDescriptionProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Mutation mutation={UpdateProgramServiceMutation}>
      {(updateProgramService: MutationFunction) => (
        <>
          <UpdateButton toggle={toggle} description={description} />
          <Popup
            okButtonProps={{ variant: "green" }}
            visible={isPopupVisible}
            onCancel={toggle}
            title={<Trans>Description</Trans>}
            okText={<Title description={description} />}
            justifyButtons="left"
            formId="updateProgramServiceDescription"
          >
            <Form
              id="updateProgramServiceDescription"
              initialValues={{ description }}
              onSubmit={async ({ description = "" }) => {
                await updateProgramService({
                  variables: {
                    input: {
                      id,
                      description
                    }
                  }
                });
                toggle();
              }}
            >
              <Trans
                id="Enter description"
                render={({ translation }) => (
                  <Field.Textarea
                    name="description"
                    placeholder={translation}
                    rows={5}
                    maxLength={3000}
                    showLengthHint
                  />
                )}
              />
            </Form>
          </Popup>
        </>
      )}
    </Mutation>
  );
};

type UpdateButtonProps = {
  toggle: () => void;
  description?: ProgramService["description"];
};

const UpdateButton = ({ toggle, description }: UpdateButtonProps) =>
  description ? (
    <Ability action="write" resource="program_service">
      <Button variant="none" border="none" px="0" py="0" onClick={toggle}>
        <Text
          fontSize={0}
          color="rockmanBlue"
          fontWeight="bold"
          ml={description ? 2 : 0}
        >
          <Title description={description} />
        </Text>
      </Button>
    </Ability>
  ) : (
    <Button variant="none" border="none" px="0" py="0" onClick={toggle}>
      <Text
        fontSize={0}
        color="rockmanBlue"
        fontWeight="bold"
        ml={description ? 2 : 0}
      >
        <Title description={description} />
      </Text>
    </Button>
  );

const Title = ({
  description
}: {
  description: ProgramService["description"];
}) => (description ? <Trans>Change</Trans> : <Trans>Add</Trans>);

export default UpdateProgramServiceDescription;
