import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { SampledData as TSampledData } from "@ehealth/ehealth-ua.schema";

import DefinitionListView from "../../../components/DefinitionListView";

type SampledDataProps = {
  sampledData: TSampledData;
};

const SampledData = ({ sampledData }: SampledDataProps) => {
  if (isEmpty(sampledData)) return null;

  const {
    dimensions,
    factor,
    lowerLimit,
    origin,
    period,
    upperLimit
  } = sampledData;

  return (
    <Box>
      <DefinitionListView
        fontSize={14}
        labels={{
          data: <Trans>Data</Trans>,
          ...(dimensions && { dimensions: <Trans>Dimensions</Trans> }),
          ...(factor && { factor: <Trans>Factor</Trans> }),
          ...(lowerLimit && { lowerLimit: <Trans>Lower limit</Trans> }),
          ...(origin && { origin: <Trans>Origin</Trans> }),
          ...(period && { period: <Trans>Period</Trans> }),
          ...(upperLimit && { upperLimit: <Trans>Upper limit</Trans> })
        }}
        data={{ ...sampledData }}
      />
    </Box>
  );
};

export default SampledData;

SampledData.fragments = {
  entry: gql`
    fragment SampledData on SampledData {
      data
      dimensions
      factor
      lowerLimit
      origin
      period
      upperLimit
    }
  `
};
