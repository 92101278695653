import React, { useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { Mutation, Query } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { Flex, Box, Text } from "@rebass/emotion";
import printIframe from "print-iframe";
import { loader } from "graphql.macro";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import isEmpty from "lodash/isEmpty";

import system from "@edenlabllc/ehealth-system-components";
import {
  Form,
  Validation,
  LocationParams
} from "@edenlabllc/ehealth-components";
import {
  PrinterIcon,
  PositiveIcon,
  DefaultImageIcon,
  SearchIcon,
  NegativeIcon
} from "@edenlabllc/ehealth-icons";
import {
  getFullName,
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  ContractDocument,
  Dictionary,
  Division,
  Maybe,
  MedicalProgram,
  ReimbursementContract,
  TerminateContractInput
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import AddressView from "../../../components/AddressView";
import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Button from "../../../components/Button";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import Line from "../../../components/Line";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import Popup from "../../../components/Popup";
import Tabs from "../../../components/Tabs";
import Table, { SortingParams } from "../../../components/Table";
import { TLocationParams } from "../../../components/SearchForm";

import STATUSES from "../../../helpers/statuses";
import pagination from "../../../helpers/pagination";
import { ITEMS_PER_PAGE } from "../../../constants/pagination";

import {
  ToggleSuspendContractModal,
  ToggleContractSuspendMutation
} from "../Capitation/Details";

const ReimbursementContractQuery = loader(
  "../../../graphql/ReimbursementContractQuery.graphql"
);
const TerminateContractMutation = loader(
  "../../../graphql/TerminateContractMutation.graphql"
);

const ReimbursementContractsDetails = (_props: RouteComponentProps) => (
  <Router>
    <Details path=":id/*" />
  </Router>
);

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

type DetailsStateType = {
  suspendPopup: boolean;
  terminatePopup: boolean;
};

const Details = ({ id, navigate }: DetailsProps) => {
  const [isVisible, setVisibilityState] = useState<DetailsStateType>({
    suspendPopup: false,
    terminatePopup: false
  });
  const toggle = (popup: "suspendPopup" | "terminatePopup") =>
    setVisibilityState({
      ...isVisible,
      [popup]: !isVisible[popup]
    });

  return (
    <Query
      query={ReimbursementContractQuery}
      variables={{ id, first: ITEMS_PER_PAGE[0] }}
    >
      {({
        data
      }: QueryResult<{ reimbursementContract: ReimbursementContract }>) => {
        if (isEmpty(data) || isEmpty(data.reimbursementContract)) return null;

        const {
          isSuspended,
          databaseId,
          contractNumber,
          contractRequest,
          status,
          printoutContent,
          startDate,
          endDate,
          statusReason,
          reason,
          contractorLegalEntity,
          contractorOwner,
          contractorBase,
          contractorPaymentDetails,
          attachedDocuments
        } = data.reimbursementContract;

        return (
          <>
            <Box p={6}>
              <Box py={10}>
                <Breadcrumbs.List>
                  <Breadcrumbs.Item to="/contracts/reimbursement">
                    <Trans>List of contracts</Trans>
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item>
                    <Trans>Details of the contract</Trans>
                  </Breadcrumbs.Item>
                </Breadcrumbs.List>
              </Box>
              <Flex justifyContent="space-between">
                <Box>
                  <DefinitionListView
                    labels={{
                      databaseId: <Trans>Contract ID</Trans>,
                      contractRequestId: <Trans>Contract request ID</Trans>,
                      contractNumber: <Trans>Contract Number</Trans>,
                      status: <Trans>Status</Trans>,
                      isSuspended: <Trans>Contract state</Trans>
                    }}
                    data={{
                      databaseId,
                      contractRequestId: (
                        <Link
                          to={`/contract-requests/reimbursement/${
                            contractRequest && contractRequest.id
                          }`}
                        >
                          {contractRequest && contractRequest.databaseId}
                        </Link>
                      ),
                      contractNumber,
                      status: (
                        <Badge name={status} type="CONTRACT" minWidth={100} />
                      ),
                      isSuspended: (
                        <Flex alignItems="center">
                          <Badge
                            name={isSuspended}
                            type="SUSPENDED"
                            minWidth={100}
                          />
                          {status === "VERIFIED" && (
                            <ToggleSuspendContractModal
                              toggleSuspendContractMutation={
                                ToggleContractSuspendMutation
                              }
                              isSuspended={isSuspended}
                              isVisible={isVisible}
                              toggle={toggle}
                              formId="suspendReimbursement"
                              contractId={id}
                            />
                          )}
                        </Flex>
                      )
                    }}
                    color="#7F8FA4"
                    labelWidth="120px"
                  />
                </Box>
                <Flex
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <PrintButton content={printoutContent} />
                  <Flex justifyContent="flex-end" flexWrap="wrap">
                    <Ability action="create" resource="contract_request">
                      {status === "VERIFIED" && !isSuspended && (
                        <Button
                          mt={2}
                          variant="blue"
                          onClick={() =>
                            navigate!(
                              `/contract-requests/reimbursement/create/${id}`,
                              {
                                state: {
                                  updateContract: { id }
                                }
                              }
                            )
                          }
                        >
                          <Trans>Update contract</Trans>
                        </Button>
                      )}
                    </Ability>
                    {status === "VERIFIED" && (
                      <Mutation mutation={TerminateContractMutation}>
                        {(terminateContract: MutationFunction) => (
                          <>
                            <Button
                              ml={2}
                              mt={2}
                              variant="red"
                              disabled={isVisible["terminatePopup"]}
                              onClick={() => toggle("terminatePopup")}
                            >
                              <Trans>Terminate contract</Trans>
                            </Button>
                            <Popup
                              visible={isVisible["terminatePopup"]}
                              onCancel={() => toggle("terminatePopup")}
                              title={<Trans>Terminate contract</Trans>}
                              formId="terminateContract"
                            >
                              <Form
                                onSubmit={async ({
                                  reason,
                                  statusReason
                                }: Partial<TerminateContractInput>) => {
                                  await terminateContract({
                                    variables: {
                                      input: { id, reason, statusReason }
                                    }
                                  });
                                  toggle("terminatePopup");
                                }}
                                id="terminateContract"
                              >
                                <Text mb={5}>
                                  <Trans>
                                    Attention! After the termination of the
                                    agreement, this action can not be canceled
                                  </Trans>
                                </Text>
                                <Box width={1 / 2}>
                                  <DictionaryValue
                                    name="CONTRACT_STATUS_REASON"
                                    render={(dict: Dictionary["values"]) => (
                                      <Trans
                                        id="Choose status reason"
                                        render={({ translation }) => (
                                          <Field.Select
                                            name="statusReason"
                                            label={<Trans id="Status reason" />}
                                            placeholder={translation}
                                            items={["DEFAULT"]}
                                            itemToString={(item: string) =>
                                              dict[item] || translation
                                            }
                                            variant="select"
                                            emptyOption
                                            filterOptions={{
                                              keys: [
                                                (item: string) => dict[item]
                                              ]
                                            }}
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                  <Validation.Required
                                    field="statusReason"
                                    message="Required field"
                                  />
                                </Box>
                                <Trans
                                  id="Enter reason comment"
                                  render={({ translation }) => (
                                    <Field.Textarea
                                      label={
                                        <Trans id="Status reason comment" />
                                      }
                                      name="reason"
                                      placeholder={translation}
                                      rows={5}
                                      maxLength={3000}
                                      showLengthHint
                                    />
                                  )}
                                />
                                <Validation.Required
                                  field="reason"
                                  message="Required field"
                                />
                              </Form>
                            </Popup>
                          </>
                        )}
                      </Mutation>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Box>

            <Tabs.Nav>
              <Tabs.NavItem to="./">
                <Trans>General information</Trans>
              </Tabs.NavItem>
              <Tabs.NavItem to="./legal-entity">
                <Trans>Pharmacy</Trans>
              </Tabs.NavItem>
              <Tabs.NavItem to="./divisions">
                <Trans>Subdivisions</Trans>
              </Tabs.NavItem>
              <Tabs.NavItem to="./documents">
                <Trans>Documents</Trans>
              </Tabs.NavItem>
              <Tabs.NavItem to="./medical-programs">
                <Trans>Medical programs</Trans>
              </Tabs.NavItem>
            </Tabs.Nav>
            <Tabs.Content>
              <Router>
                <GeneralInfo
                  path="/"
                  startDate={startDate}
                  endDate={endDate}
                  statusReason={statusReason}
                  reason={reason}
                  isSuspended={isSuspended}
                />
                <LegalEntity
                  path="/legal-entity"
                  contractorOwner={contractorOwner}
                  contractorBase={contractorBase}
                  contractorLegalEntity={contractorLegalEntity}
                  contractorPaymentDetails={contractorPaymentDetails}
                />
                <Divisions path="/divisions" />
                <MedicalPrograms path="/medical-programs" />
                <Documents
                  path="/documents"
                  attachedDocuments={attachedDocuments}
                />
              </Router>
            </Tabs.Content>
          </>
        );
      }}
    </Query>
  );
};

type GeneralInfoProps = RouteComponentProps & {
  startDate: ReimbursementContract["startDate"];
  endDate: ReimbursementContract["endDate"];
  statusReason: ReimbursementContract["statusReason"];
  reason: ReimbursementContract["reason"];
  isSuspended: ReimbursementContract["isSuspended"];
};

const GeneralInfo = ({
  startDate,
  endDate,
  statusReason,
  reason,
  isSuspended
}: GeneralInfoProps) => (
  <Box p={5}>
    <DefinitionListView
      labels={{
        startDate: <Trans>Initial date of the contract</Trans>,
        endDate: <Trans>End date of the contract</Trans>
      }}
      data={{
        startDate: i18n.date(startDate),
        endDate: i18n.date(endDate)
      }}
    />
    {(statusReason || reason) && (
      <>
        <Line />
        <DefinitionListView
          labels={{
            statusReason: <Trans>Status Comment</Trans>,
            reason: isSuspended ? (
              <Trans>Reason of deactivation contract</Trans>
            ) : (
              <Trans>Reason of activation contract</Trans>
            )
          }}
          data={{
            statusReason: (
              <DictionaryValue
                name="CONTRACT_STATUS_REASON"
                item={statusReason}
              />
            ),
            reason
          }}
        />
      </>
    )}
  </Box>
);

type MedicalProgramsProps = RouteComponentProps<{
  id: string;
}>;

const MedicalPrograms = ({ id }: MedicalProgramsProps) => (
  <Box>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const { first, last, after, before, name } = locationParams;

        return (
          <>
            <Form initialValues={locationParams} onSubmit={setLocationParams}>
              <Box px={5} pt={5} width={460}>
                <Trans
                  id="Enter program name"
                  render={({ translation }) => (
                    <Field.Text
                      name="name"
                      label={<Trans id="Find program" />}
                      placeholder={translation}
                      postfix={<SearchIcon color="silverCity" />}
                    />
                  )}
                />
              </Box>
            </Form>
            <Query
              query={ReimbursementContractQuery}
              variables={{
                id,
                ...pagination({ first, last, after, before }),
                medicalProgramFilter: { name }
              }}
            >
              {({
                loading,
                data
              }: QueryResult<{
                reimbursementContract: ReimbursementContract;
              }>) => {
                if (isEmpty(data) || isEmpty(data.reimbursementContract))
                  return null;
                const {
                  reimbursementContract: {
                    medicalPrograms: { nodes: medicalPrograms = [], pageInfo }
                  }
                } = data;

                return (
                  <LoadingOverlay loading={loading}>
                    {!isEmpty(medicalPrograms) ? (
                      <>
                        <Table
                          data={medicalPrograms}
                          header={{
                            databaseId: <Trans>ID</Trans>,
                            name: <Trans>Medical program name</Trans>,
                            isActive: <Trans>Status</Trans>,
                            type: <Trans>Type</Trans>,
                            medicationRequestAllowed: (
                              <Trans>Medication request allowed</Trans>
                            ),
                            medicationDispenseAllowed: (
                              <Trans>Medication dispense allowed</Trans>
                            ),
                            action: <Trans>Action</Trans>
                          }}
                          renderRow={({
                            id,
                            isActive,
                            name,
                            type,
                            medicationRequestAllowed,
                            medicationDispenseAllowed,
                            ...medicalProgram
                          }: MedicalProgram) => ({
                            ...medicalProgram,
                            name,
                            isActive: (
                              <Badge
                                type="ACTIVE_STATUS_F"
                                name={isActive}
                                variant={!isActive}
                                display="block"
                              />
                            ),
                            type: (
                              <DictionaryValue
                                name="MEDICAL_PROGRAM_TYPE"
                                item={type}
                              />
                            ),
                            medicationRequestAllowed: (
                              <Flex justifyContent="center">
                                {medicationRequestAllowed ? (
                                  <PositiveIcon />
                                ) : (
                                  <NegativeIcon />
                                )}
                              </Flex>
                            ),
                            medicationDispenseAllowed: (
                              <Flex justifyContent="center">
                                {medicationDispenseAllowed ? (
                                  <PositiveIcon />
                                ) : (
                                  <NegativeIcon />
                                )}
                              </Flex>
                            ),
                            action: (
                              <Link
                                to={`../../../../medical-programs/${id}`}
                                fontWeight="bold"
                              >
                                <Trans>Details</Trans>
                              </Link>
                            )
                          })}
                          sortableFields={["name", "insertedAt"]}
                          sortingParams={parseSortingParams(
                            locationParams.orderBy
                          )}
                          onSortingChange={(sortingParams: SortingParams) =>
                            setLocationParams({
                              ...locationParams,
                              orderBy: stringifySortingParams(sortingParams)
                            })
                          }
                          whiteSpaceNoWrap={["databaseId"]}
                          tableName="medicalPrograms/search"
                          hiddenFields="medicationDispenseAllowed,medicationRequestAllowed"
                        />
                        <Pagination {...pageInfo} />
                      </>
                    ) : (
                      <EmptyData />
                    )}
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        );
      }}
    </LocationParams>
  </Box>
);

type LegalEntityProps = RouteComponentProps & {
  contractorBase: ReimbursementContract["contractorBase"];
  contractorOwner: ReimbursementContract["contractorOwner"];
  contractorPaymentDetails: ReimbursementContract["contractorPaymentDetails"];
  contractorLegalEntity: ReimbursementContract["contractorLegalEntity"];
};

const LegalEntity = ({
  contractorBase,
  contractorOwner,
  contractorPaymentDetails: { bankName, mfo, payerAccount },
  contractorLegalEntity: {
    databaseId: legalEntityDatabaseId,
    id: legalEntityId,
    edrpou,
    edrData
  }
}: LegalEntityProps) => {
  const { name, registrationAddress } = edrData || {};
  const { zip, country, address } = registrationAddress || {};

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          edrpou: <Trans>EDRPOU</Trans>,
          name: <Trans>Name</Trans>,
          addresses: <Trans>Address</Trans>
        }}
        data={{
          name,
          edrpou,
          addresses: (
            <>
              {zip}, {country}, {address}
            </>
          )
        }}
      />
      <DefinitionListView
        color="blueberrySoda"
        labels={{
          legalEntityId: "ID аптеки"
        }}
        data={{
          legalEntityId: (
            <Link to={`/legal-entities/${legalEntityId}`}>
              {legalEntityDatabaseId}
            </Link>
          )
        }}
      />
      <Line />
      <DefinitionListView
        labels={{
          fullName: <Trans>Subscriber Name</Trans>,
          contractorBase: <Trans>Based on</Trans>
        }}
        data={{
          fullName: contractorOwner && getFullName(contractorOwner.party),
          contractorBase: contractorBase
        }}
      />
      <DefinitionListView
        color="blueberrySoda"
        labels={{
          ownerId: <Trans>Signer ID</Trans>
        }}
        data={{
          ownerId: contractorOwner && contractorOwner.databaseId
        }}
      />
      <Line />
      <DefinitionListView
        labels={{
          bankName: <Trans>Bank</Trans>,
          mfo: <Trans>Bank Code (MFO)</Trans>,
          payerAccount: <Trans>Account number</Trans>
        }}
        data={{
          bankName,
          mfo,
          payerAccount
        }}
      />
    </Box>
  );
};

type DivisionsProps = RouteComponentProps<{
  id: string;
}>;

const Divisions = ({ id }: DivisionsProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => {
      const { first, last, after, before, name } = locationParams;

      return (
        <>
          <Form onSubmit={setLocationParams} initialValues={locationParams}>
            <Box px={5} pt={5} width={460}>
              <Trans
                id="Enter division name"
                render={({ translation }) => (
                  <Field.Text
                    name="name"
                    label={<Trans id="Find division" />}
                    placeholder={translation}
                    postfix={<SearchIcon color="silverCity" />}
                  />
                )}
              />
            </Box>
          </Form>
          <Query
            query={ReimbursementContractQuery}
            variables={{
              id,
              ...pagination({ first, last, after, before }),
              divisionFilter: { name }
            }}
          >
            {({
              loading,
              data
            }: QueryResult<{
              reimbursementContract: ReimbursementContract;
            }>) => {
              if (isEmpty(data) || isEmpty(data.reimbursementContract))
                return null;
              const {
                reimbursementContract: {
                  contractorDivisions: {
                    nodes: contractorDivisions = [],
                    pageInfo
                  }
                }
              } = data;

              return (
                <LoadingOverlay loading={loading}>
                  {contractorDivisions!.length > 0 ? (
                    <>
                      <Table
                        data={contractorDivisions}
                        header={{
                          name: <Trans>Division name</Trans>,
                          addresses: <Trans>Address</Trans>,
                          phones: (
                            <>
                              <Trans>Phone</Trans>
                              <br />
                              <Trans>Email</Trans>
                            </>
                          ),
                          dlsVerified: <Trans>DLS Verification</Trans>
                        }}
                        renderRow={({
                          name,
                          addresses,
                          phones,
                          email,
                          dlsVerified
                        }: Division) => ({
                          name,
                          phones: (
                            <>
                              <Box>
                                {phones
                                  .filter((a) => a && a.type === "MOBILE")
                                  .map((item) => item && item.number)[0] ||
                                  (phones && phones[0] && phones[0].number)}
                              </Box>
                              <Box>{email}</Box>
                            </>
                          ),
                          addresses: addresses
                            .filter((a) => a && a.type === "RESIDENCE")
                            .map((item, key) => (
                              <AddressView data={item} key={key} />
                            )),
                          dlsVerified: (
                            <Flex justifyContent="center">
                              {/* @ts-expect-error statuses boolean key */}
                              {STATUSES.YES_NO[dlsVerified]}
                            </Flex>
                          )
                        })}
                        tableName="reimbursement-contract/divisions"
                      />
                      <Pagination {...pageInfo} />
                    </>
                  ) : (
                    <EmptyData />
                  )}
                </LoadingOverlay>
              );
            }}
          </Query>
        </>
      );
    }}
  </LocationParams>
);

type DocumentsProps = RouteComponentProps & {
  attachedDocuments: ReimbursementContract["attachedDocuments"];
};

const Documents = ({ attachedDocuments }: DocumentsProps) => {
  if (isEmpty(attachedDocuments)) return <EmptyData />;

  return (
    <>
      {attachedDocuments.map((document: Maybe<ContractDocument>) => (
        <Box m="5">
          <SaveLink href={document && document.url} target="_blank">
            <Box mr={2} color="shiningKnight">
              <DefaultImageIcon />
            </Box>
            <Text color="rockmanBlue" lineHeight="1">
              <DictionaryValue
                name="CONTRACT_DOCUMENT"
                item={document && document.type}
              />
            </Text>
          </SaveLink>
        </Box>
      ))}
    </>
  );
};

type PrintButtonProps = {
  content?: Maybe<string>;
};

const PrintButton = ({ content = "" }: PrintButtonProps) => (
  <Wrapper color="shiningKnight" onClick={() => printIframe(content)}>
    <Text color="rockmanBlue" fontWeight="bold" mr={1} fontSize="0">
      <Trans>Show printout form</Trans>
    </Text>
    <PrinterIcon />
  </Wrapper>
);

const Wrapper = system(
  {
    extend: Flex
  },
  { cursor: "pointer" }
);

const SaveLink = system(
  {
    is: "a"
  },
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    lineHeight: 0,
    textDecoration: "none"
  }
);

export default ReimbursementContractsDetails;
