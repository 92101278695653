import React from "react";
import { Redirect, Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { MedicationDispense } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DefinitionListView from "../../../components/DefinitionListView";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import { MedicationDispenseDetails } from "./MedicationDispenseDetails";
import GeneralInfo from "./GeneralInfo";

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

const Details = ({ id, uri }: DetailsProps) => {
  const { i18n } = useLingui();

  return (
    <Ability action="details" resource="medication_dispense_admin">
      <Query
        query={AdminMedicationDispenseByIDQuery}
        fetchPolicy="network-only"
        variables={{ id }}
      >
        {({
          loading,
          data
        }: QueryResult<{ adminMedicationDispense: MedicationDispense }>) => {
          const medicationDispense = data && data.adminMedicationDispense;

          return (
            <LoadingOverlay loading={loading}>
              <Box p={6}>
                <Box py={10}>
                  <Breadcrumbs.List>
                    <Breadcrumbs.Item to="/medication-dispenses">
                      <Trans>Medication dispenses</Trans>
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item>
                      <Trans id="Details of the medication dispense">
                        Medication dispense details
                      </Trans>
                    </Breadcrumbs.Item>
                  </Breadcrumbs.List>
                </Box>

                {!isEmpty(medicationDispense) && (
                  <DefinitionListView
                    labels={{
                      databaseId: <Trans>ID</Trans>,
                      status: <Trans>Status</Trans>,
                      insertedAt: <Trans>Inserted at</Trans>,
                      updatedAt: <Trans>Updated at</Trans>
                    }}
                    data={{
                      ...medicationDispense,
                      status: (
                        <Badge
                          name={medicationDispense.status}
                          dictionary="eHealth/medication_dispense_statuses"
                          minWidth={100}
                        />
                      ),
                      insertedAt: dateFormatter(
                        i18n.locale,
                        DATE_TIME_FORMAT,
                        medicationDispense.insertedAt
                      ),
                      updatedAt: dateFormatter(
                        i18n.locale,
                        DATE_TIME_FORMAT,
                        medicationDispense.updatedAt
                      )
                    }}
                    color="#7F8FA4"
                    labelWidth="120px"
                  />
                )}
              </Box>

              <Tabs.Nav>
                <Tabs.NavItem to={"./general-info"}>
                  <Trans>General info</Trans>
                </Tabs.NavItem>
              </Tabs.Nav>

              <Tabs.Content>
                <Router>
                  <Redirect from="/" to={`${uri}/general-info`} />
                  <GeneralInfo
                    path="general-info"
                    medicationDispense={medicationDispense}
                  />
                </Router>
              </Tabs.Content>
            </LoadingOverlay>
          );
        }}
      </Query>
    </Ability>
  );
};

export default Details;

const AdminMedicationDispenseByIDQuery = gql`
  query AdminMedicationDispenseByIDQuery($id: ID!) {
    adminMedicationDispense(id: $id) {
      id
      databaseId
      details {
        ...MedicationDispenseDetail
      }
      dispensedAt
      dispensedBy
      division {
        id
        name
      }
      insertedAt
      isActive
      legalEntity {
        id
        name
      }
      medicalProgram {
        id
        name
      }
      medicationRequestId
      party {
        id
        databaseId
        firstName
        lastName
        secondName
      }
      paymentAmount
      paymentId
      patientId
      status
      updatedAt
    }
  }
  ${MedicationDispenseDetails.fragments.entry}
`;
