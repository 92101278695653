import React from "react";
import { gql } from "graphql-tag";

import { Maybe } from "@ehealth/ehealth-ua.schema";

type ValueStringProps = {
  valueString?: Maybe<string>;
};

const ValueString = ({ valueString }: ValueStringProps) => {
  if (!valueString) return null;
  return <>{valueString}</>;
};

export default ValueString;

ValueString.fragments = {
  entry: gql`
    fragment ValueString on ValueString {
      string
    }
  `
};
