import React from "react";
import { Box } from "@rebass/emotion";
import { Trans, t } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import {
  Maybe,
  Reference as TReference,
  Reference
} from "@ehealth/ehealth-ua.schema";

import Table from "../../../components/Table";

import ReferenceSwitcher from "./ReferenceSwitcher";

type SupportingInfoProps = {
  handleSetReferenceLink: (
    memoizedReferenceType: string,
    memoizedReferenceSlug: string,
    reference: TReference
  ) => string;
  supportingInfo?: Maybe<Array<Maybe<Reference>>>;
};

const SupportingInfo = ({
  supportingInfo,
  handleSetReferenceLink
}: SupportingInfoProps) => {
  if (isEmpty(supportingInfo)) return null;

  const REFERENCE_TYPES = {
    values: {
      Observation: t`Observation`,
      DiagnosticReport: t`Diagnostic report`,
      Condition: t`Condition`,
      EpisodeOfCare: t`Episode`,
      AllergyIntolerance: t`Allergy intolerance`,
      Immunization: t`Immunization`,
      Procedure: t`Procedure`
    }
  };

  return (
    <Box mt={-4}>
      <Table
        hidePagination
        hideControls
        data={supportingInfo}
        header={{
          reference: <Trans>Reference</Trans>
        }}
        renderRow={({ ...reference }) => ({
          reference: (
            <ReferenceSwitcher
              reference={reference}
              referenceTypes={REFERENCE_TYPES}
              handleSetReferenceLink={handleSetReferenceLink}
            />
          )
        })}
      />
    </Box>
  );
};

export default SupportingInfo;
