import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex } from "@rebass/emotion";

import { NegativeIcon } from "@edenlabllc/ehealth-icons";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  ReimbursementContract,
  ReimbursementContractConnection
} from "@ehealth/ehealth-ua.schema";

import Badge from "../../../../components/Badge";
import FullName from "../../../../components/FullName";
import Link from "../../../../components/Link";
import {
  SearchParams,
  SetSearchParams
} from "../../../../components/SearchForm";
import Table, { SortingParams } from "../../../../components/Table";

import dateFormatter from "../../../../helpers/dateFormatter";

type ContractTableProps = {
  reimbursementContracts: ReimbursementContractConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const ContractTable = ({
  reimbursementContracts,
  locationParams,
  setLocationParams
}: ContractTableProps) => (
  <Table
    data={reimbursementContracts}
    header={{
      databaseId: <Trans>ID</Trans>,
      edrpou: <Trans>EDRPOU</Trans>,
      name: <Trans>Name of medical institution</Trans>,
      nhsSignerName: <Trans>Performer</Trans>,
      contractNumber: <Trans>Contract Number</Trans>,
      startDate: <Trans>The contract is valid with</Trans>,
      endDate: <Trans>The contract is valid for</Trans>,
      isSuspended: <Trans>Contract state</Trans>,
      insertedAt: <Trans>Added</Trans>,
      status: <Trans>Status</Trans>,
      details: <Trans>Details</Trans>
    }}
    renderRow={({
      id,
      status,
      isSuspended,
      startDate,
      endDate,
      contractorLegalEntity: { edrpou, edrData },
      insertedAt,
      nhsSigner,
      ...reimbursementContracts
    }: ReimbursementContract) => ({
      ...reimbursementContracts,
      edrpou,
      name: edrData && edrData.name,
      nhsSignerName: nhsSigner && <FullName party={nhsSigner.party} />,
      isSuspended: (
        <Flex justifyContent="center">
          <NegativeIcon
            fill={!isSuspended ? "#1BB934" : "#ED1C24"}
            stroke={!isSuspended ? "#1BB934" : "#ED1C24"}
          />
        </Flex>
      ),
      startDate: i18n.date(startDate),
      endDate: i18n.date(endDate),
      insertedAt:
        insertedAt &&
        typeof insertedAt === "string" &&
        dateFormatter(
          i18n.locale,
          {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
          },
          insertedAt
        ),
      status: <Badge type="CONTRACT" name={status} display="block" />,
      details: (
        <Link to={`./${id}`} fontWeight="bold">
          <Trans>Show details</Trans>
        </Link>
      )
    })}
    sortableFields={[
      "status",
      "startDate",
      "endDate",
      "isSuspended",
      "insertedAt"
    ]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    tableName="reimbursement-contract/search"
    whiteSpaceNoWrap={["databaseId"]}
    hiddenFields="insertedAt"
  />
);

ContractTable.fragments = {
  entry: gql`
    fragment ReimbursementContracts on ReimbursementContract {
      id
      databaseId
      contractNumber
      startDate
      endDate
      status
      statusReason
      isSuspended
      nhsSigner {
        id
        party {
          id
          ...FullName
        }
      }
      contractorLegalEntity {
        id
        name
        edrpou
        edrData {
          name
        }
      }
    }
    ${FullName.fragments.entry}
  `
};

export default ContractTable;
