import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import Composer from "react-composer";
import { loader } from "graphql.macro";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans, t } from "@lingui/macro";
import { TransRenderProps, useLingui } from "@lingui/react";
import { i18n } from "@lingui/core";
import { Heading, Flex, Box, Text } from "@rebass/emotion";
import { subYears, format } from "date-fns";

import {
  Field as ControlledField,
  Form,
  Validation,
  Validations
} from "@edenlabllc/ehealth-components";
import { PositiveIcon, RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import {
  parsePhone,
  formatPhone,
  getFullName
} from "@edenlabllc/ehealth-utils";
import { Dictionary, Party, Employee, Phone } from "@ehealth/ehealth-ua.schema";

import Button from "../../components/Button";
import DefinitionListView from "../../components/DefinitionListView";
import DictionaryValue from "../../components/DictionaryValue";
import DocumentView from "../../components/DocumentView";
import * as Field from "../../components/Field";
import Line from "../../components/Line";
import Steps from "../../components/Steps";

import {
  TAX_ID_PATTERN,
  NO_TAX_ID_DOCUMENT_PATTERN,
  UUID_PATTERN,
  CYRILLIC_NAME
} from "../../constants/validationPatterns";

import env from "../../env";

const CreateEmployeeRequestMutation = loader(
  "../../graphql/CreateEmployeeRequestMutation.graphql"
);

const Create = ({
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <>
    <Box pt={5} px={5}>
      <Steps.List>
        <Steps.Item to="./" state={state}>
          <Trans>Fill in the form</Trans>
        </Steps.Item>
        <Steps.Item to="./confirm" state={state} disabled>
          <Trans>Confirm</Trans>
        </Steps.Item>
      </Steps.List>
    </Box>
    <Router>
      <CreationForm path="/" />
      <Confirmation path="/confirm" />
    </Router>
  </>
);

const CreationForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { createEmployee } = state || {};

  return (
    <Box p={5}>
      <Heading as="h1" fontWeight="normal" mb={5}>
        <Trans>Add employee</Trans>
      </Heading>
      <Form
        onSubmit={(data: Partial<Employee>) => {
          navigate!("./confirm", {
            state: {
              createEmployee: data
            }
          });
        }}
        initialValues={
          createEmployee || {
            party: {
              documents: [
                {
                  number: ""
                }
              ],
              phones: [
                {
                  number: ""
                }
              ]
            }
          }
        }
      >
        <Flex mx={-1}>
          <Box px={1} width={1 / 3}>
            <Trans
              id="Enter first name"
              render={({ translation }) => (
                <Field.Text
                  name="party.first_name"
                  label={<Trans id="First name" />}
                  placeholder={translation}
                />
              )}
            />
            <Validations field="party.first_name">
              <Validation.Required message="Required field" />
              <Validation.Matches
                options={CYRILLIC_NAME}
                message="Invalid first name"
              />
            </Validations>
          </Box>
          <Box px={1} width={1 / 3}>
            <Trans
              id="Enter second name"
              render={({ translation }) => (
                <Field.Text
                  name="party.second_name"
                  label={<Trans id="Second name" />}
                  placeholder={translation}
                />
              )}
            />
          </Box>
          <Box px={1} width={1 / 3}>
            <Trans
              id="Enter last name"
              render={({ translation }) => (
                <Field.Text
                  name="party.last_name"
                  label={<Trans id="Last name" />}
                  placeholder={translation}
                />
              )}
            />
            <Validations field="party.last_name">
              <Validation.Required message="Required field" />
              <Validation.Matches
                options={CYRILLIC_NAME}
                message="Invalid last name"
              />
            </Validations>
          </Box>
        </Flex>
        <Flex mx={-1}>
          <Box px={1} width={1 / 3}>
            <Composer
              components={[
                <DictionaryValue name="GENDER" />,
                ({
                  render
                }: {
                  render: (props: TransRenderProps) => React.ReactElement;
                }) => <Trans id="Select option" render={render} />
              ]}
            >
              {([dict, { translation }]: [
                Dictionary["values"],
                { translation: React.ReactNode }
              ]) => (
                <Field.Select
                  name="party.gender"
                  label={<Trans id="Gender" />}
                  placeholder={translation}
                  items={Object.keys(dict)}
                  itemToString={(item: string) => dict[item] || translation}
                  variant="select"
                  emptyOption
                  filterOptions={{ keys: [(item: string) => dict[item]] }}
                />
              )}
            </Composer>
            <Validation.Required
              field="party.gender"
              message="Required field"
            />
          </Box>
          <Box px={1} width={1 / 3}>
            <Field.DatePicker
              name="party.birth_date"
              label={<Trans id="Date of birth" />}
              minDate={format(subYears(new Date(), 150), "YYYY-MM-DD")}
            />
            <Validations field="party.birth_date">
              <Validation.Required message="Required field" />
              <Validation.BirthDate message="Invalid birth date" />
            </Validations>
          </Box>
        </Flex>
        <Line />
        <Text fontSize={2} mb={6}>
          <Trans>Job information</Trans>
        </Text>
        <Flex mx={-1}>
          <Box px={1} width={1 / 4}>
            <Composer
              components={[
                <DictionaryValue name="POSITION" />,
                ({
                  render
                }: {
                  render: (props: TransRenderProps) => React.ReactElement;
                }) => <Trans id="Select option" render={render} />
              ]}
            >
              {([dict, { translation }]: [
                Dictionary["values"],
                { translation: React.ReactNode }
              ]) => (
                <Field.Select
                  name="position"
                  label={<Trans id="Position" />}
                  placeholder={translation}
                  items={Object.keys(dict)}
                  itemToString={(item: string) => dict[item] || translation}
                  variant="select"
                  emptyOption
                  filterOptions={{ keys: [(item: string) => dict[item]] }}
                />
              )}
            </Composer>
            <Validation.Required field="position" message="Required field" />
          </Box>
          <Box px={1} width={1 / 4}>
            <Composer
              components={[
                <DictionaryValue name="EMPLOYEE_TYPE" />,
                ({
                  render
                }: {
                  render: (props: TransRenderProps) => React.ReactElement;
                }) => <Trans id="Select option" render={render} />
              ]}
            >
              {([dict, { translation }]: [
                Dictionary["values"],
                { translation: React.ReactNode }
              ]) => (
                <Field.Select
                  name="employee_type"
                  label={<Trans id="Employee type" />}
                  placeholder={translation}
                  items={Object.keys(dict).filter((item) =>
                    (env.REACT_APP_LEGAL_ENTITY_EMPLOYEE_TYPE_REGEX
                      ? new RegExp(
                          env.REACT_APP_LEGAL_ENTITY_EMPLOYEE_TYPE_REGEX
                        )
                      : /^(NHS|MITHRIL)/
                    ).test(item)
                  )}
                  itemToString={(item: string) => dict[item] || translation}
                  variant="select"
                  emptyOption
                  filterOptions={{ keys: [(item: string) => dict[item]] }}
                />
              )}
            </Composer>
            <Validation.Required
              field="employee_type"
              message="Required field"
            />
          </Box>
          <Box px={1} width={1 / 4}>
            <Field.DatePicker
              name="start_date"
              label={<Trans id="Start date" />}
              minDate="1900-01-01"
            />
            <Validation.Required field="start_date" message="Required field" />
          </Box>
          <Box px={1} width={1 / 4}>
            <Trans
              id="Enter division ID"
              render={({ translation }) => (
                <Field.Text
                  name="division_id"
                  label={<Trans id="Division ID" />}
                  placeholder={translation}
                />
              )}
            />
            <Validation.Matches
              field="division_id"
              options={UUID_PATTERN}
              message="Invalid ID"
            />
          </Box>
        </Flex>
        <Line />
        <Text fontSize={2} mb={6}>
          <Trans>Contact information</Trans>
        </Text>
        <Box pr={1} width={1 / 3}>
          <Trans
            id="Enter email"
            render={({ translation }) => (
              <Field.Text
                name="party.email"
                label={<Trans id="Email" />}
                placeholder={translation}
              />
            )}
          />
          <Validations field="party.email">
            <Validation.Required message="Required field" />
            <Validation.Email message="Invalid email" />
          </Validations>
        </Box>
        <Field.Array
          name="party.phones"
          addText={<Trans>Add phone</Trans>}
          fields={PhonesForm}
          removeButton={({ onClick }) => <RemoveButton onClick={onClick} />}
        />
        <Line />
        <Text fontSize={2} mb={6}>
          <Trans>Documents</Trans>
        </Text>
        <Flex>
          <Box pr={2} width={2 / 9}>
            <Trans
              id="Enter the number"
              render={({ translation }) => (
                <ControlledField
                  name="party.no_tax_id"
                  subscription={{ value: true }}
                >
                  {({ input: { value } }: $TSFixMe) =>
                    value ? (
                      <>
                        <Field.Text
                          name="party.tax_id.passport"
                          label={<Trans id="Passport" />}
                          placeholder={translation}
                          maxLength={9}
                        />
                        <Validations field="party.tax_id.passport">
                          <Validation.Required message="Required field" />
                          <Validation.Matches
                            options={NO_TAX_ID_DOCUMENT_PATTERN}
                            message="Invalid number"
                          />
                        </Validations>
                      </>
                    ) : (
                      <>
                        <Field.Text
                          name="party.tax_id.taxNumber"
                          label={<Trans id="INN" />}
                          placeholder={translation}
                          maxLength={10}
                        />
                        <Validations field="party.tax_id.taxNumber">
                          <Validation.Required message="Required field" />
                          <Validation.Matches
                            options={TAX_ID_PATTERN}
                            message="Invalid number"
                          />
                        </Validations>
                      </>
                    )
                  }
                </ControlledField>
              )}
            />
          </Box>
          <Box alignSelf="center">
            <Field.Checkbox
              label={<Trans id="Person waived of tax ID" />}
              name="party.no_tax_id"
            />
            <ControlledField.Listener
              field="party.no_tax_id"
              set="party.tax_id"
              to=""
            />
          </Box>
        </Flex>
        <Field.Array
          name="party.documents"
          addText={<Trans>Add document</Trans>}
          fields={DocumentsForm}
          removeButton={({ onClick }) => <RemoveButton onClick={onClick} />}
        />
        <Line />
        <Flex mb={200}>
          <Box mr={3}>
            <Button
              type="reset"
              variant="blue"
              width={140}
              onClick={() => navigate!("../search")}
            >
              <Trans>Back</Trans>
            </Button>
          </Box>
          <Box>
            <Button variant="green" width={140}>
              <Trans>Add</Trans>
            </Button>
          </Box>
        </Flex>
      </Form>
    </Box>
  );
};

type PhonesFormProps = {
  name: string;
};

const PhonesForm = ({ name }: PhonesFormProps) => {
  const { i18n } = useLingui();

  return (
    <>
      <Box pr={2} width={2 / 9}>
        <Field.Text
          name={`${name}.number`}
          label={<Trans id="Phone number" />}
          format={formatPhone}
          parse={parsePhone}
        />
        <Validations field={`${name}.number`}>
          <Validation.Required message="Required field" />
          <Validation.Matches
            options={"^\\+380\\d{9}$"}
            message="Invalid phone number"
          />
          <Validation.Custom
            options={({
              value,
              allValues: {
                party: { phones }
              }
            }: {
              value: string;
              allValues: {
                party: Party;
              };
            }) => {
              const duplicates = phones.filter(
                (phone) => phone && phone.number === value
              );
              return duplicates.length === 1;
            }}
            message="This number is used more than once"
          />
        </Validations>
      </Box>
      <Box pr={2} width={2 / 9}>
        <Composer components={[<DictionaryValue name="PHONE_TYPE" />]}>
          {([dict]: [Dictionary["values"]]) => {
            const translation = i18n._(t`Select option`);
            return (
              <Field.Select
                name={`${name}.type`}
                label={<Trans id="Phone type" />}
                placeholder={translation}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || translation}
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            );
          }}
        </Composer>
        <Validation.Required field={`${name}.type`} message="Required field" />
      </Box>
    </>
  );
};

type DocumentsFormProps = {
  name: string;
};

const DocumentsForm = ({ name }: DocumentsFormProps) => {
  const { i18n } = useLingui();

  return (
    <>
      <Box pr={2} width={2 / 9}>
        <Composer components={[<DictionaryValue name="DOCUMENT_TYPE" />]}>
          {([dict]: [Dictionary["values"]]) => {
            const translation = i18n._(t`Select option`);
            return (
              <Field.Select
                name={`${name}.type`}
                label={<Trans id="Select document type" />}
                placeholder={translation}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || translation}
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            );
          }}
        </Composer>
        <Validations field={`${name}.type`}>
          <Validation.Required message="Required field" />
          <Validation.Custom
            options={({
              value,
              allValues: {
                party: { documents }
              }
            }: {
              value: string;
              allValues: {
                party: Party;
              };
            }) => {
              const duplicates = documents.filter(
                (doc) => doc && doc.type === value
              );
              return duplicates.length === 1;
            }}
            message="This document type is used more than once"
          />
        </Validations>
      </Box>
      <Box pr={2} width={2 / 9}>
        <Field.Text
          name={`${name}.number`}
          label={<Trans id="Document number" />}
          placeholder={i18n._(t`Enter the number`)}
        />
        <Validation.Required
          field={`${name}.number`}
          message="Required field"
        />
      </Box>
      <Box pr={2} width={2 / 9}>
        <Field.Text
          name={`${name}.issued_by`}
          label={<Trans id="Authority that issued" />}
          placeholder={i18n._(t`Enter authority name`)}
          autoComplete="off"
        />
        <Validation.Required
          field={`${name}.issued_by`}
          message="Required field"
        />
      </Box>
      <Box pr={2} width={2 / 9}>
        <Field.DatePicker
          name={`${name}.issued_at`}
          label={<Trans id="Date of issue" />}
          minDate="1900-01-01"
        />
        <Validation.Required
          field={`${name}.issued_at`}
          message="Required field"
        />
      </Box>
    </>
  );
};

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  if (!state) return null;
  const {
    createEmployee,
    createEmployee: {
      position,
      start_date,
      employee_type,
      division_id,
      party,
      party: {
        birth_date,
        gender,
        tax_id,
        no_tax_id,
        email,
        documents,
        phones,
        first_name,
        second_name,
        last_name
      }
    }
  } = state;

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          fullName: <Trans>Name of employee</Trans>,
          gender: <Trans>Gender</Trans>,
          birthDate: <Trans>Date of birth</Trans>
        }}
        data={{
          fullName: getFullName({
            firstName: first_name,
            secondName: second_name,
            lastName: last_name
          }),
          gender: <DictionaryValue name="GENDER" item={gender} />,
          birthDate: i18n.date(birth_date)
        }}
        labelWidth="200px"
      />
      <Line />
      <DefinitionListView
        labels={{
          position: <Trans>Position</Trans>,
          employeeType: <Trans>Employee type</Trans>,
          startDate: <Trans>Start date</Trans>
        }}
        data={{
          position: <DictionaryValue name="POSITION" item={position} />,
          employeeType: (
            <DictionaryValue name="EMPLOYEE_TYPE" item={employee_type} />
          ),
          startDate: i18n.date(start_date),
          division_id
        }}
        labelWidth="200px"
      />
      <Line />
      <DefinitionListView
        labels={{
          email: <Trans>Email</Trans>,
          phones: <Trans>Phones</Trans>
        }}
        data={{
          email,
          phones: phones.map(({ number }: Phone) => number).join(", ")
        }}
        labelWidth="200px"
      />
      <Line />
      <DefinitionListView
        labels={{
          taxId: no_tax_id ? <Trans>Passport</Trans> : <Trans>INN</Trans>,
          noTaxId: <Trans>No tax ID</Trans>,
          documents: <Trans>Documents</Trans>
        }}
        data={{
          taxId: tax_id && (tax_id.taxNumber || tax_id.passport),
          noTaxId: no_tax_id ? <PositiveIcon /> : null,
          documents: documents.map(
            (
              {
                number,
                type,
                issued_at,
                issued_by
              }: {
                number: string;
                type: string;
                issued_at: string;
                issued_by: string;
              },
              index: number
            ) => (
              <Box key={index} pb={4}>
                <Heading fontSize="0" fontWeight="bold" pb={3}>
                  <DictionaryValue name="DOCUMENT_TYPE" item={type} />
                </Heading>
                <DocumentView
                  data={{
                    number,
                    issuedAt: issued_at,
                    issuedBy: issued_by
                  }}
                />
              </Box>
            )
          )
        }}
        labelWidth="200px"
      />
      <Flex mt={5}>
        <Box mr={3}>
          <Button
            variant="blue"
            width={140}
            onClick={() => {
              navigate!("../", {
                state: {
                  createEmployee
                }
              });
            }}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Signer.Parent
            url={env.REACT_APP_SIGNER_URL}
            features={{ width: 640, height: 589 }}
          >
            {/* @ts-expect-error signData */}
            {({ signData }) => (
              <Mutation mutation={CreateEmployeeRequestMutation}>
                {(createEmployeeRequest: MutationFunction) => (
                  <Button
                    variant="green"
                    width={140}
                    onClick={async () => {
                      const { signedContent } = await signData({
                        employee_request: {
                          ...createEmployee,
                          party: {
                            ...party,
                            tax_id:
                              tax_id && (tax_id.taxNumber || tax_id.passport)
                          },
                          status: "NEW"
                        }
                      });
                      await createEmployeeRequest({
                        variables: {
                          input: {
                            signedContent: {
                              content: signedContent,
                              encoding: "BASE64"
                            }
                          }
                        }
                      });
                      await navigate!("/employee-requests");
                    }}
                  >
                    <Trans>Sign</Trans>
                  </Button>
                )}
              </Mutation>
            )}
          </Signer.Parent>
        </Box>
      </Flex>
    </Box>
  );
};

type RemoveButtonProps = {
  onClick: () => void;
};

const RemoveButton = ({ onClick }: RemoveButtonProps) => (
  <Box width={1 / 9} alignSelf="center">
    <RemoveItemIcon onClick={onClick} />
  </Box>
);

export default Create;
