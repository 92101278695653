import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Validation } from "@edenlabllc/ehealth-components";

import * as Field from "../../../../components/Field";
import * as SearchField from "../../../../components/SearchField";
import AssigneeSearch from "../../../../components/AssigneeSearch";
import { SEARCH_CONTRACT_PATTERN } from "../../../../constants/contracts";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 4}>
      <Trans
        id="EDRPOU or Contract number"
        render={({ translation }) => (
          <Field.Text
            name="filter.searchRequest"
            label={<Trans id="Search contract request" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
          />
        )}
      />
      <Validation.Matches
        field="filter.searchRequest"
        options={SEARCH_CONTRACT_PATTERN}
        message="Invalid number"
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <AssigneeSearch />
    </Box>
    <Box px={1} width={1 / 4}>
      <SearchField.LegalEntity
        name="filter.contractorLegalEntity"
        filteredParams={["edrData.name"]}
        additionalFilters={{ type: ["MSP", "MSP_PHARMACY"] }}
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <SearchField.Status
        name="filter.status"
        status="CONTRACT_REQUEST"
        label={<Trans id="Contract Request status" />}
      />
    </Box>
  </Flex>
);

const SecondarySearchFields = () => (
  <>
    <Flex>
      <Box mr={1} width={1 / 4}>
        <Field.RangePicker
          rangeNames={["filter.date.startFrom", "filter.date.startTo"]}
          label={<Trans id="Contract start date" />}
        />
      </Box>
      <Box width={1 / 4}>
        <Field.RangePicker
          rangeNames={["filter.date.endFrom", "filter.date.endTo"]}
          label={<Trans id="Contract end date" />}
        />
      </Box>
      <Box width={1 / 4}>
        <Field.RangePicker
          rangeNames={[
            "filter.date.insertedAtFrom",
            "filter.date.insertedAtTo"
          ]}
          label={<Trans id="Contract inserted date" />}
        />
      </Box>
    </Flex>
  </>
);

export { PrimarySearchFields, SecondarySearchFields };
