import * as React from "react";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import format from "date-fns/format";
import Composer from "react-composer";
import { Flex, Box } from "@rebass/emotion";

import { Field, DatePicker } from "@edenlabllc/ehealth-components";
import { CalendarIcon } from "@edenlabllc/ehealth-icons";
import { formatDate, parseDate } from "@edenlabllc/ehealth-utils";

import * as FieldView from "./FieldView";
import * as InputView from "./InputView";
import ErrorTranslation from "./ErrorTranslation";

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd.mm.yyyy");

type TimeoutID = NodeJS.Timeout;

type RangeDateFieldState = {
  opened: "none" | "start" | "end";
};

type RangeDateFieldProps = {
  rangeNames: string[];
  name?: string;
  label?: React.ReactElement;
  hint?: string;
  warning?: string;
};

class RangeDateField extends React.Component<
  RangeDateFieldProps,
  RangeDateFieldState
> {
  timeoutIds: TimeoutID[] = [];
  // @ts-ignore
  state = {
    opened: "none"
  };

  componentWillUnmount() {
    this.internalClearTimeouts();
  }

  calendar = React.createRef();

  render() {
    const {
      rangeNames: [start, end],
      label,
      hint,
      ...props
    } = this.props;
    return (
      <FieldView.Wrapper>
        {label && (
          <FieldView.Header>
            <FieldView.Label>{label}</FieldView.Label>
            {hint && <FieldView.Message>{hint}</FieldView.Message>}
          </FieldView.Header>
        )}
        <Composer
          components={[
            <Field {...props} name={start} />,
            <Field {...props} name={end} />
          ]}
        >
          {([propsFrom, propsTo]: $TSFixMe) => (
            <>
              <Flex>
                <InputView.Border
                  variant={propsFrom.meta.state || propsTo.meta.state}
                  ref={this.calendar}
                >
                  <InputView.Content
                    pl={2}
                    py={0}
                    flex="none"
                    display="flex"
                    alignItems="center"
                  >
                    <CalendarIcon />
                  </InputView.Content>
                  <Calendar
                    name={start}
                    label="from"
                    onFocus={() =>
                      this.setState({
                        opened: "start"
                      })
                    }
                    onBlur={this.handleBlur}
                    handleDateSelect={this.handleDateSelect}
                    getSelectedDate={(value: string) =>
                      this.getSelectedDate(value, propsTo.input.value)
                    }
                    handleKeyPress={this.handleKeyPress}
                    maxDate={this.getMinMaxDate(propsTo.input.value)}
                    opened={this.state.opened === "start"}
                    {...propsFrom}
                  />
                </InputView.Border>
                <Box mx={1}>
                  <InputView.Content>-</InputView.Content>
                </Box>
                <InputView.Border
                  variant={propsFrom.meta.state || propsTo.meta.state}
                  ref={this.calendar}
                >
                  <InputView.Content
                    pl={2}
                    py={0}
                    flex="none"
                    display="flex"
                    alignItems="center"
                  >
                    <CalendarIcon />
                  </InputView.Content>
                  <Calendar
                    name={end}
                    label="to"
                    onFocus={() =>
                      this.setState({
                        opened: "end"
                      })
                    }
                    onBlur={this.handleBlur}
                    handleDateSelect={this.handleDateSelect}
                    getSelectedDate={(value: string) =>
                      this.getSelectedDate(value, propsFrom.input.value)
                    }
                    handleKeyPress={this.handleKeyPress}
                    minDate={this.getMinMaxDate(propsFrom.input.value)}
                    opened={this.state.opened === "end"}
                    {...propsTo}
                  />
                </InputView.Border>
              </Flex>
              <FieldView.Footer>
                <FieldView.Message
                  state={propsFrom.meta.state || propsTo.meta.state}
                >
                  {(propsFrom.meta.errored && (
                    <ErrorTranslation error={propsFrom.meta.error} />
                  )) ||
                    (propsTo.meta.errored && (
                      <ErrorTranslation error={propsTo.meta.error} />
                    ))}
                </FieldView.Message>
              </FieldView.Footer>
            </>
          )}
        </Composer>
      </FieldView.Wrapper>
    );
  }

  getSelectedDate(value: string, rangedDate: string) {
    const parsedDate = Date.parse(value);
    const parsedRangedDate = Date.parse(rangedDate);
    return new Date(
      isNaN(parsedDate)
        ? isNaN(parsedRangedDate)
          ? Date.now()
          : parsedRangedDate
        : parsedDate
    );
  }

  getMinMaxDate(value: string) {
    if (!value) return null;
    return new Date(Date.parse(value));
  }

  handleDateSelect = (onFieldChange: (date: string) => void) => ({
    selectable,
    date
  }: {
    selectable: boolean;
    date: Date;
  }) => {
    if (!selectable) return;

    this.setState({ opened: "none" });
    onFieldChange(format(date, "YYYY-MM-DD"));
  };

  handleBlur = () => {
    this.internalSetTimeout(() => {
      if (
        document.hasFocus() &&
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        !this.calendar.current.contains(document.activeElement)
      ) {
        this.setState({ opened: "none" });
      }
    });
  };
  handleKeyPress = (e: React.KeyboardEvent) =>
    e.key === "Enter" && this.setState({ opened: "none" });

  internalSetTimeout = (fn: () => void, time?: number) => {
    const id: TimeoutID = setTimeout(() => {
      this.timeoutIds = this.timeoutIds.filter((i: TimeoutID) => i !== id);
      fn();
    }, time);

    this.timeoutIds.push(id);
  };

  internalClearTimeouts() {
    this.timeoutIds.forEach((id: TimeoutID) => {
      clearTimeout(id);
    });

    this.timeoutIds = [];
  }
}

export default RangeDateField;

type CalendarProps = {
  name: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  opened: boolean;
  onFocus: () => void;
  onBlur: () => void;
  getSelectedDate: (date: string) => Date | Date[];
  handleKeyPress: (e: React.KeyboardEvent) => void;
  handleDateSelect: (
    onFieldChange: (date: string) => void
  ) => (selectable: boolean, date: Date) => void;
  placement?: string;
};

const Calendar = ({
  minDate,
  maxDate,
  opened,
  onFocus,
  onBlur,
  handleKeyPress,
  handleDateSelect,
  getSelectedDate,
  placement = "bottom",
  ...props
}: CalendarProps) => (
  <FieldView.Wrapper maxWidth={120} px={2}>
    <label onFocus={onFocus} onBlur={onBlur}>
      <Field format={formatDate} parse={parseDate} {...props}>
        {({ input }: $TSFixMe) => (
          <InputView.Content
            {...input}
            is={MaskedInput}
            onKeyPress={handleKeyPress}
            placeholder="ДД.ММ.РРРР"
            mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
            guide={false}
            pipe={autoCorrectedDatePipe}
            width="100%"
            autoComplete="off"
            value={input.value || ""}
          />
        )}
      </Field>
    </label>
    {opened && (
      <Field {...props}>
        {({ input: { value, onChange } }: $TSFixMe) => (
          <DatePicker
            placement={placement}
            selected={getSelectedDate(value)}
            onDateSelected={handleDateSelect(onChange)}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      </Field>
    )}
  </FieldView.Wrapper>
);
