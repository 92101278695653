import React, { useState } from "react";
import { RouteComponentProps, NavigateFn } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { Heading, Box, Flex } from "@rebass/emotion";

import { LocationParams, Switch } from "@edenlabllc/ehealth-components";
import {
  Person,
  PersonDrfoVerification,
  PersonDracsDeathVerification,
  PersonManualRulesVerification
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Badge from "../../../components/Badge";
import Button from "../../../components/Button";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import Line from "../../../components/Line";
import Link from "../../../components/Link";
import { TLocationParams } from "../../../components/SearchForm";

import { useAbility } from "../../../helpers/useAbility";
import pagination from "../../../helpers/pagination";
import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import {
  dracsVerificationStatus,
  drfoVerificationStatus,
  handleCalculateDracsVerificationStatus,
  handleCalculateVerificationStatus,
  PersonQuery
} from ".";
import RejectVerify from "../../PersonsVerifications/Mutations/RejectVerify";
import DracsDeathActPopup from "./DracsDeathActPopup";
import Verify, {
  UpdatePersonManualRulesVerificationStatusMutation
} from "../../PersonsVerifications/Mutations/Verify";
import { UpdatePersonDracsDeathVerificationStatusMutation } from "../../PersonsVerifications/Mutations/DRACS/Verify";

type VerificationDetailsProps = RouteComponentProps<{
  data: Person["verificationDetails"];
  id?: string;
  navigate: NavigateFn;
}>;

const VerificationDetails = ({
  data,
  id,
  navigate
}: VerificationDetailsProps) => {
  if (!data) return <EmptyData />;
  const drfo = data.drfo;
  const manual = data.manualRules;
  const dracsDeath = data.dracsDeath;

  return (
    <Box p={6}>
      <Drfo data={drfo} />
      <Manual data={manual} id={id} />
      <Dracs data={dracsDeath} id={id} />
      <Ability action="read" resource="person_history">
        <Button
          variant="link"
          px="0"
          py="0"
          type="reset"
          onClick={() => navigate!("./history")}
        >
          <Trans>Show person verification history</Trans>
        </Button>
      </Ability>
    </Box>
  );
};

const Drfo = ({ data }: { data: PersonDrfoVerification }) => {
  if (!data) return null;

  return (
    <>
      <Heading fontSize="1" fontWeight="normal" pb={5}>
        <Trans>DRFO</Trans>
      </Heading>
      <DefinitionListView
        labelWidth={200}
        labels={{
          status: <Trans>Status</Trans>,
          reason: <Trans>Reason</Trans>,
          result: <Trans>Verification result</Trans>,
          synchronized: <Trans>Synchronized</Trans>,
          unverifiedAt: <Trans>Unverified</Trans>
        }}
        data={{
          status: (
            <Badge
              type="STATUS_VERIFICATION"
              name={drfoVerificationStatus(data.verificationStatus)}
              minWidth={160}
            />
          ),
          reason: (
            <DictionaryValue
              name="PERSON_VERIFICATION_STATUS_REASONS"
              item={data.verificationReason}
            />
          ),
          result: data.result ? (
            <DictionaryValue name="DRFO_RESULT" item={data.result} />
          ) : (
            "-"
          ),
          synchronized: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            data.syncedAt
          ),
          ...(data.unverifiedAt && {
            unverifiedAt: dateFormatter(
              i18n.locale,
              DATE_TIME_FORMAT,
              data.unverifiedAt
            )
          })
        }}
      />
      <Line />
    </>
  );
};

const Manual = ({
  data,
  id
}: {
  data: PersonManualRulesVerification;
  id?: string;
}) => {
  if (!data) return null;

  const [verifyPersonAbility] = useAbility("verify", "person");
  const [isStartVerifyModalVisible, setVerifyModalVisible] = useState(false);
  const [isRejectVerifyModalVisible, setRejectVerifyModalVisible] = useState(
    false
  );

  const calculatedVerificationStatus = handleCalculateVerificationStatus(
    data.verificationStatus,
    data.verificationReason
  );

  return (
    <LocationParams>
      {({ locationParams }: TLocationParams) => (
        <>
          <Flex flexDirection="row">
            <Box flex={1}>
              <Heading fontSize="1" fontWeight="normal" pb={5}>
                <Trans>Manual rules</Trans>
              </Heading>
              <DefinitionListView
                labelWidth={200}
                labels={{
                  status: <Trans>Status</Trans>,
                  reason: <Trans>Reason</Trans>,
                  comment: <Trans>Comment</Trans>,
                  unverifiedAt: <Trans>Unverified</Trans>
                }}
                data={{
                  status: (
                    <Badge
                      type="STATUS_VERIFICATION"
                      name={calculatedVerificationStatus}
                      minWidth={160}
                    />
                  ),
                  reason: (
                    <DictionaryValue
                      name="PERSON_VERIFICATION_STATUS_REASONS"
                      item={data.verificationReason}
                    />
                  ),
                  comment: data.verificationComment || "-",
                  ...(data.unverifiedAt && {
                    unverifiedAt: dateFormatter(
                      i18n.locale,
                      DATE_TIME_FORMAT,
                      data.unverifiedAt
                    )
                  })
                }}
              />
            </Box>
            <Box>
              <Flex alignItems="flex-end">
                {verifyPersonAbility && (
                  <Switch
                    value={calculatedVerificationStatus}
                    VERIFICATION_MANUAL_NEEDED={
                      <Mutation
                        mutation={
                          UpdatePersonManualRulesVerificationStatusMutation
                        }
                        refetchQueries={() => [
                          {
                            query: PersonQuery,
                            variables: {
                              id,
                              ...pagination(locationParams)
                            }
                          }
                        ]}
                      >
                        {(
                          updatePersonManualRulesVerificationStatus: MutationFunction
                        ) => (
                          <Box mb={3}>
                            <Button
                              width={220}
                              variant="blue"
                              onClick={async () => {
                                await updatePersonManualRulesVerificationStatus(
                                  {
                                    variables: {
                                      input: {
                                        personId: id,
                                        manualRulesVerificationStatus:
                                          "IN_REVIEW",
                                        verificationComment: "MANUAL"
                                      }
                                    }
                                  }
                                );
                              }}
                            >
                              <Trans>Start verification</Trans>
                            </Button>
                          </Box>
                        )}
                      </Mutation>
                    }
                    IN_REVIEW={
                      <Flex>
                        <Box mb={3}>
                          <Button
                            width={220}
                            variant="orange"
                            onClick={() => setRejectVerifyModalVisible(true)}
                          >
                            <Trans>Not verify</Trans>
                          </Button>
                        </Box>
                        <Box mb={3} ml={2}>
                          <Button
                            width={220}
                            variant="green"
                            onClick={() => setVerifyModalVisible(true)}
                          >
                            <Trans>Verify</Trans>
                          </Button>
                        </Box>
                      </Flex>
                    }
                  />
                )}
                {isStartVerifyModalVisible && (
                  <Verify
                    isVisible={isStartVerifyModalVisible}
                    onCancel={() => setVerifyModalVisible(false)}
                    personId={id}
                  />
                )}
                {isRejectVerifyModalVisible && (
                  <RejectVerify
                    isVisible={isRejectVerifyModalVisible}
                    onCancel={() => setRejectVerifyModalVisible(false)}
                    personId={id}
                  />
                )}
              </Flex>
            </Box>
          </Flex>
          <Line />
        </>
      )}
    </LocationParams>
  );
};

const Dracs = ({
  data,
  id
}: {
  data: PersonDracsDeathVerification;
  id?: string;
}) => {
  if (!data) return null;

  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);
  const [verifyPersonAbility] = useAbility("verify", "person");

  const calculatedVerificationStatus = handleCalculateDracsVerificationStatus(
    data.verificationStatus,
    data.verificationReason
  );

  return (
    <LocationParams>
      {({ locationParams }: TLocationParams) => (
        <>
          <Flex flexDirection="row">
            <Box flex={1}>
              <Heading fontSize="1" fontWeight="normal" pb={5}>
                <Trans>DRACS</Trans>
              </Heading>
              <DefinitionListView
                labelWidth={200}
                labels={{
                  status: <Trans>Status</Trans>,
                  reason: <Trans>Reason</Trans>,
                  comment: <Trans>Comment</Trans>,
                  unverifiedAt: <Trans>Unverified</Trans>,
                  deathAct: <Trans>Death act</Trans>
                }}
                data={{
                  status: (
                    <Badge
                      type="STATUS_VERIFICATION"
                      name={dracsVerificationStatus(
                        data.verificationStatus,
                        data.verificationReason
                      )}
                      minWidth={160}
                    />
                  ),
                  reason: (
                    <DictionaryValue
                      name="PERSON_VERIFICATION_STATUS_REASONS"
                      item={data.verificationReason}
                    />
                  ),
                  comment: data.verificationComment || "-",
                  ...(data.unverifiedAt && {
                    unverifiedAt: dateFormatter(
                      i18n.locale,
                      DATE_TIME_FORMAT,
                      data.unverifiedAt
                    )
                  }),
                  deathAct: data.dracsDeathActId ? (
                    <Button variant="link" onClick={toggle} px={0} py={0}>
                      {data.dracsDeathActId}
                    </Button>
                  ) : (
                    "-"
                  )
                }}
              />
            </Box>
            <Box>
              <Flex alignItems="flex-end">
                {verifyPersonAbility && (
                  <Switch
                    value={calculatedVerificationStatus}
                    VERIFICATION_MANUAL_NEEDED={
                      <Mutation
                        mutation={
                          UpdatePersonDracsDeathVerificationStatusMutation
                        }
                        refetchQueries={() => [
                          {
                            query: PersonQuery,
                            variables: {
                              id,
                              ...pagination(locationParams)
                            }
                          }
                        ]}
                      >
                        {(
                          updatePersonDracsDeathVerificationStatus: MutationFunction
                        ) => (
                          <Box mb={3}>
                            <Button
                              width={220}
                              variant="blue"
                              onClick={async () => {
                                await updatePersonDracsDeathVerificationStatus({
                                  variables: {
                                    input: {
                                      personId: id,
                                      dracsDeathVerificationStatus: "IN_REVIEW",
                                      dracsDeathVerificationReason: "MANUAL"
                                    }
                                  }
                                });
                              }}
                            >
                              <Trans>Start verification</Trans>
                            </Button>
                          </Box>
                        )}
                      </Mutation>
                    }
                    IN_REVIEW={
                      <Flex>
                        <Link to="./process-dracs">
                          <Button width={220} variant="blue">
                            <Trans>To process</Trans>
                          </Button>
                        </Link>
                      </Flex>
                    }
                  />
                )}
              </Flex>
            </Box>
          </Flex>
          <Line />
          <DracsDeathActPopup
            // @ts-expect-error types mismatch
            deathActId={data.dracsDeathActId}
            isPopupVisible={isPopupVisible}
            toggle={toggle}
          />
        </>
      )}
    </LocationParams>
  );
};

export default VerificationDetails;

VerificationDetails.fragments = {
  entry: gql`
    fragment VerificationDetails on Person {
      verificationDetails {
        dracsDeath {
          verificationComment
          verificationReason
          verificationStatus
          dracsDeathActId
          unverifiedAt
        }
        drfo {
          result
          syncedAt
          unverifiedAt
          verificationReason
          verificationStatus
        }
        manualRules {
          unverifiedAt
          verificationComment
          verificationReason
          verificationStatus
        }
      }
    }
  `
};
