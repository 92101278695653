import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  DiagnosesHistory as TDiagnosesHistory,
  Episode as TEpisode
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import CodeableConcept from "../../../components/CodeableConcept";
import Coding from "../../../components/Coding";
import Collapsable from "../../../components/Collapsable";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Line from "../../../components/Line";
import Table from "../../../components/Table";

import paramToBase64 from "../../../helpers/paramToBase64";
import dateFormatter from "../../../helpers/dateFormatter";
import STATUSES from "../../../helpers/statuses";
import { MonitoringDetailsPageProps } from "../../../helpers/types";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import CreateConclusion from "../../ClinicalMonitoring/CreateConclusion";
import getConclusionData from "../../ClinicalMonitoring/getConclusionData";
import Conclusions from "../../ClinicalMonitoring/Conclusions";
import Diagnosis from "./Diagnosis";
import DetailsPageBreadcrumbs from "./DetailsPageBreadcrumbs";
import Period from "./Period";
import Reference from "./Reference";
import StatusHistory from "./StatusHistory";

export const LABEL_WIDTH = "230px";

const Episode = ({
  query,
  queryVariables,
  itemId,
  id,
  patientId,
  isClinicalMonitoring = false
}: MonitoringDetailsPageProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="episode"
  >
    <Box p={6}>
      <DetailsPageBreadcrumbs
        id={id}
        currentStepName={<Trans>Episode details</Trans>}
        isClinicalMonitoring={isClinicalMonitoring}
      />
      <Query
        query={query}
        fetchPolicy="network-only"
        variables={{
          ...queryVariables,
          id: itemId,
          ...(isClinicalMonitoring && { patientId })
        }}
      >
        {({
          loading,
          error,
          data = {}
        }: QueryResult<{ [key: string]: TEpisode }>) => {
          const episode = isClinicalMonitoring
            ? data.impersonalEpisode
            : data.episode;

          return (
            <LoadingOverlay loading={loading}>
              <Box mt={4}>
                {!isEmpty(episode) && !error && (
                  <>
                    <HeaderDefinitions
                      episode={episode}
                      isClinicalMonitoring={isClinicalMonitoring}
                      patientId={patientId}
                    />
                    {!isEmpty(episode.currentDiagnoses) && (
                      <Box>
                        <Text
                          fontSize={1}
                          fontWeight="bold"
                          color="darkCharcoal"
                        >
                          <Trans>Current diagnoses</Trans>
                        </Text>
                        <Diagnosis
                          diagnosis={episode.currentDiagnoses}
                          isClinicalMonitoring={isClinicalMonitoring}
                          patientId={patientId}
                        />
                      </Box>
                    )}
                    <Box mt={4}>
                      <Flex alignItems="flex-end">
                        <Text
                          fontSize={1}
                          fontWeight="bold"
                          width={LABEL_WIDTH}
                          color="darkCharcoal"
                        >
                          <Trans>Period</Trans>
                        </Text>
                        <Period datePeriod={episode.period} />
                      </Flex>
                    </Box>
                    <Reference
                      fontSize={14}
                      header={<Trans>Managing organization</Trans>}
                      linkPath={`/legal-entities/${paramToBase64(
                        "LegalEntity",
                        episode.managingOrganization.identifier.value
                      )}`}
                      linkDisplayValue={
                        episode.managingOrganization.displayValue
                          ? episode.managingOrganization.displayValue
                          : episode.managingOrganization.identifier.value
                      }
                    />
                    <Reference
                      header={<Trans>Care manager</Trans>}
                      linkPath={`/employees/${paramToBase64(
                        "Employee",
                        episode.careManager.identifier.value
                      )}`}
                      linkDisplayValue={
                        episode.careManager.displayValue
                          ? episode.careManager.displayValue
                          : episode.careManager.identifier.value
                      }
                      fontSize={14}
                    />
                    <Box mt={4}>
                      <TopBodyDefinitions episode={episode} />
                    </Box>
                    {!isEmpty(episode.statusHistory) && (
                      <Collapsable title={<Trans>Status history</Trans>}>
                        <StatusHistory
                          statusHistory={episode.statusHistory}
                          statusDictionaryName="eHealth/episode_statuses"
                        />
                      </Collapsable>
                    )}
                    {!isEmpty(episode.diagnosesHistory) && (
                      <Collapsable title={<Trans>Diagnoses history</Trans>}>
                        <DiagnosesHistory
                          diagnosesHistory={episode.diagnosesHistory}
                          isClinicalMonitoring={isClinicalMonitoring}
                          patientId={patientId!}
                        />
                      </Collapsable>
                    )}
                    <Box mt={4}>
                      <BottomBodyDefinitions episode={episode} />
                    </Box>
                  </>
                )}
              </Box>
            </LoadingOverlay>
          );
        }}
      </Query>
      <Conclusions
        isClinicalMonitoring={isClinicalMonitoring}
        patientId={patientId!}
        itemId={itemId!}
      />
    </Box>
  </Ability>
);

export default Episode;

type HeaderDefinitionsProps = {
  episode: TEpisode;
  patientId?: string;
  isClinicalMonitoring?: boolean;
};

const HeaderDefinitions = ({
  episode,
  isClinicalMonitoring,
  patientId
}: HeaderDefinitionsProps) => {
  if (isEmpty(episode)) return null;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <div>
          <DefinitionListView
            labels={{
              databaseId: <Trans>ID</Trans>,
              status: <Trans>Status</Trans>,
              ...(episode.statusReason && {
                statusReason: <Trans>Reason status</Trans>
              }),
              type: <Trans>Type</Trans>,
              name: <Trans>Name</Trans>
            }}
            data={{
              ...episode,
              status: (
                <DictionaryValue
                  name="eHealth/episode_statuses"
                  item={episode.status.toLowerCase()}
                />
              ),
              statusReason: (
                <CodeableConcept codeableConcept={episode.statusReason} />
              ),
              type: <Coding coding={episode.type} />
            }}
            color="#7F8FA4"
            labelWidth={LABEL_WIDTH}
          />
        </div>
        {isClinicalMonitoring && (
          <CreateConclusion
            initialData={getConclusionData(
              patientId!,
              "episode_of_care",
              episode.databaseId
            )}
          />
        )}
      </Flex>
      <Line />
    </>
  );
};

const TopBodyDefinitions = ({ episode }: { episode: TEpisode }) => {
  if (isEmpty(episode)) return null;
  return (
    <DefinitionListView
      fontSize={14}
      labels={{
        ...(episode.explanatoryLetter && {
          explanatoryLetter: <Trans>Explanatory letter</Trans>
        })
      }}
      data={episode}
      labelWidth={LABEL_WIDTH}
    />
  );
};

const BottomBodyDefinitions = ({ episode }: { episode: TEpisode }) => {
  const { i18n } = useLingui();
  if (isEmpty(episode)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labels={{
        ...(!isEmpty(episode.closingSummary) && {
          closingSummary: <Trans>Closing summary</Trans>
        }),
        insertedAt: <Trans>Inserted at</Trans>,
        updatedAt: <Trans>Updated at</Trans>
      }}
      data={{
        ...(!isEmpty(episode.closingSummary) && {
          closingSummary: episode.closingSummary
        }),
        insertedAt: dateFormatter(
          i18n.locale,
          DATE_TIME_FORMAT,
          episode.insertedAt
        ),
        updatedAt: dateFormatter(
          i18n.locale,
          DATE_TIME_FORMAT,
          episode.updatedAt
        )
      }}
      labelWidth={LABEL_WIDTH}
    />
  );
};

export const getQueryVariables = (id?: string) => {
  return {
    personId: id,
    skipAdditionalFields: true
  };
};

type DiagnosesHistoryProps = {
  diagnosesHistory: TEpisode["diagnosesHistory"];
  patientId: string;
  isClinicalMonitoring?: boolean;
};

export const DiagnosesHistory = ({
  diagnosesHistory,
  isClinicalMonitoring,
  patientId
}: DiagnosesHistoryProps) => {
  const { i18n } = useLingui();
  if (isEmpty(diagnosesHistory)) return null;

  return (
    <Box mt={-4}>
      <Table
        hidePagination
        hideControls
        data={diagnosesHistory}
        header={{
          date: <Trans>Date</Trans>,
          ...(isClinicalMonitoring && {
            code: <Trans>Code</Trans>
          }),
          isActive: <Trans>Is active</Trans>,
          evidence: <Trans>Evidence</Trans>
        }}
        renderRow={({
          date,
          isActive,
          evidence,
          diagnoses,
          ...restData
        }: TDiagnosesHistory) => ({
          ...restData,
          date: dateFormatter(i18n.locale, DATE_TIME_FORMAT, date),
          code: (
            <CodeableConcept
              codeableConcept={diagnoses && diagnoses[0] && diagnoses[0].code}
              withCode
            />
          ),
          // @ts-expect-error statuses boolean key
          isActive: <Box>{STATUSES.YES_NO[isActive]}</Box>,
          evidence: (
            <Reference
              fontSize={14}
              headless
              linkPath={
                isClinicalMonitoring
                  ? `../../../encounter/${patientId}/${paramToBase64(
                      "Encounter",
                      evidence.identifier.value
                    )}`
                  : `../../encounter/${paramToBase64(
                      "Encounter",
                      evidence.identifier.value
                    )}`
              }
              linkDisplayValue={
                evidence.displayValue
                  ? evidence.displayValue
                  : evidence.identifier.value
              }
            />
          )
        })}
      />
    </Box>
  );
};

DiagnosesHistory.fragments = {
  entry: gql`
    fragment DiagnosesHistory on DiagnosesHistory {
      date
      isActive
      evidence {
        ...Reference
      }
      diagnoses {
        ...Diagnosis
      }
    }
    ${Diagnosis.fragments.entry}
  `
};

Episode.fragments = {
  entry: gql`
    fragment Episode on Episode {
      id
      databaseId
      status
      statusReason {
        ...CodeableConcept
      }
      type {
        ...Coding
      }
      name
      currentDiagnoses {
        ...Diagnosis
      }
      period {
        ...Period
      }
      managingOrganization {
        ...Reference
      }
      careManager {
        ...Reference
      }
      explanatoryLetter
      closingSummary @skip(if: $skipAdditionalFields)
      statusHistory {
        ...StatusHistory
      }
      diagnosesHistory {
        ...DiagnosesHistory
      }
      insertedAt
      updatedAt
    }
    ${CodeableConcept.fragments!.entry}
    ${Coding.fragments.entry}
    ${Diagnosis.fragments.entry}
    ${Period.fragments!.entry}
    ${Reference.fragments.entry}
    ${StatusHistory.fragments.entry}
    ${DiagnosesHistory.fragments.entry}
  `
};

export const EpisodeByIDQuery = gql`
  query EpisodeByIDQuery(
    $id: ID!
    $personId: ID!
    $skipAdditionalFields: Boolean!
  ) {
    episode(id: $id, personId: $personId) {
      ...Episode
    }
  }
  ${Episode.fragments.entry}
`;
