import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import createDecorator from "final-form-calculate";
import { Trans } from "@lingui/macro";
import { Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { convertStringToBoolean } from "@edenlabllc/ehealth-utils";
import { LegalEntityConnection } from "@ehealth/ehealth-ua.schema";

import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";

import { PrimarySearchFields } from "./SearchFields";
import LegalEntitiesTable from "./LegalEntitiesTable";

export const ID_PATTERN =
  "^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}";

const Search = (_props: RouteComponentProps) => (
  <Box p={6}>
    <Heading as="h1" fontWeight="normal" mb={6}>
      <Trans>Search legal entities</Trans>
    </Heading>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const {
          filter: { code, addresses, nhsVerified, type, nhsReviewed } = {}
        } = locationParams;
        const regId = new RegExp(ID_PATTERN);
        const testID = code && regId.test(code);
        const sendCodeParams = testID ? { databaseId: code } : { edrpou: code };

        const filteredParams = {
          ...sendCodeParams,
          nhsVerified: isEmpty(nhsVerified)
            ? undefined
            : nhsVerified === "VERIFIED",
          nhsReviewed: convertStringToBoolean(nhsReviewed),
          residenceAddress: isEmpty(addresses)
            ? undefined
            : {
                settlementId: addresses.id,
                type: addresses.settlementType
              },
          type: type ? [type] : undefined
        };

        return (
          <>
            <SearchForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
              renderPrimary={PrimarySearchFields}
              decorators={[resetValue]}
            />
            <Query
              query={SearchLegalEntitiesQuery}
              fetchPolicy="network-only"
              variables={{
                ...pagination(locationParams),
                filter: !isEmpty(locationParams.filter)
                  ? filteredParams
                  : undefined
              }}
            >
              {({
                loading,
                data
              }: QueryResult<{ legalEntities: LegalEntityConnection }>) => {
                if (isEmpty(data) || isEmpty(data.legalEntities)) return null;
                const {
                  legalEntities: { nodes: legalEntities = [], pageInfo }
                } = data;

                return (
                  <LoadingOverlay loading={loading}>
                    <LegalEntitiesTable
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                      legalEntities={legalEntities}
                    />
                    <Pagination {...pageInfo} />
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        );
      }}
    </LocationParams>
  </Box>
);

export default Search;

const SearchLegalEntitiesQuery = gql`
  query SearchLegalEntitiesQuery(
    $filter: LegalEntityFilter
    $orderBy: LegalEntityOrderBy
    $before: String
    $after: String
    $first: Int
    $last: Int
    $skip: Boolean = false
  ) {
    legalEntities(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) @skip(if: $skip) {
      nodes {
        ...LegalEntities
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${LegalEntitiesTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

const resetValue = createDecorator(
  {
    field: "filter.code",
    updates: {
      "filter.addresses": (value: $TSFixMe, { filter = {} }: $TSFixMe) => {
        return value ? undefined : filter.addresses;
      },
      "filter.nhsVerified": (value: $TSFixMe, { filter = {} }: $TSFixMe) => {
        return value ? undefined : filter.nhsVerified;
      }
    }
  },
  {
    field: "filter.addresses",
    updates: {
      "filter.code": (value: $TSFixMe, { filter = {} }: $TSFixMe) => {
        return value ? undefined : filter.code;
      }
    }
  },
  {
    field: "filter.nhsVerified",
    updates: {
      "filter.code": (value: $TSFixMe, { filter = {} }: $TSFixMe) => {
        return value ? undefined : filter.code;
      }
    }
  }
);
