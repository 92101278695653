import React from "react";
import { ifNotProp } from "styled-tools";
import { Flex, Box, Text } from "@rebass/emotion";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import { px } from "styled-system";

import { mixed } from "@edenlabllc/ehealth-system-tools";
import system from "@edenlabllc/ehealth-system-components";
import { DefinitionList } from "@edenlabllc/ehealth-components";

type DefinitionListViewProps = {
  labels: any;
  data: any;
  color?: string | boolean;
  flexDirection?: string;
  alignItems?: string;
  marginBetween?: string | number;
  marginBottom?: number;
  labelWidth?: string | number;
  fontSize?: number;
};

const DefinitionListView = ({
  labels,
  data,
  color,
  flexDirection,
  alignItems,
  marginBetween,
  marginBottom,
  labelWidth = 150,
  fontSize
}: DefinitionListViewProps) => (
  <DefinitionList
    labels={labels}
    data={data}
    renderItem={({ label, value }: { label: string; value: string }) => (
      <Item
        color={color}
        flexDirection={flexDirection}
        alignItems={alignItems}
        mb={marginBottom}
        fontSize={fontSize}
      >
        <Box width={labelWidth}>
          <Text fontWeight={700}>{label}</Text>
        </Box>
        <Box width={`calc(100% - ${px(labelWidth)})`} my={marginBetween}>
          {value}
        </Box>
      </Item>
    )}
  />
);

export default DefinitionListView;

const Item = system(
  {
    extend: Flex,
    color: "#333",
    mb: 4
  },
  (props: Partial<DefinitionListViewProps>) =>
    mixed({ fontSize: ifNotProp("fontSize", "0")(props) }),
  {
    flexWrap: "nowrap",
    flexDirection: "row",
    overflowWrap: "break-word"
  },
  `
    &:last-of-type {
      margin-bottom: 0;
    }
  `,
  "color",
  "fontSize",
  "space",
  "alignItems",
  "flexDirection"
);
