import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import Composer from "react-composer";
import { Trans } from "@lingui/macro";
import { debounce, uniqBy, isEmpty } from "lodash";

import { Service } from "@ehealth/ehealth-ua.schema";

import * as Field from "../Field";

type ComposedSearchServiceFieldProps = {
  name: string;
};

const ComposedSearchServiceField = ({
  name
}: ComposedSearchServiceFieldProps) => (
  <Trans
    id="Enter name or code"
    render={({ translation }) => {
      const getServicesQuery = ({ render }: $TSFixMe) => (
        <Query
          query={GetServicesQuery}
          children={render}
          fetchPolicy="cache-first"
          variables={{
            skip: true
          }}
        />
      );

      return (
        <Composer components={[getServicesQuery, getServicesQuery]}>
          {([
            {
              data: { services: { nodes: servicesByName = [] } = {} } = {},
              refetch: refetchServicesByName
            },
            {
              data: { services: { nodes: servicesByCode = [] } = {} } = {},
              refetch: refetchServicesByCode
            }
          ]: $TSFixMe) => {
            const services = uniqBy(
              [...servicesByName, ...servicesByCode],
              "id"
            );

            const itemToString = (item: {
              id: string;
              name: string;
              code: string;
            }) => item && `${item.name} (${item.code})`;

            return (
              <Field.Select
                name={name}
                label={<Trans id="Service" />}
                placeholder={translation}
                items={services.map(({ id, name, code }: Service) => ({
                  id,
                  name,
                  code
                }))}
                itemToString={itemToString}
                filter={(services: Service[]) => services}
                onInputValueChange={debounce(
                  async (userInput: string, { selectedItem, inputValue }) => {
                    if (
                      !isEmpty(userInput) &&
                      itemToString(selectedItem) !== inputValue
                    ) {
                      await refetchServicesByName({
                        skip: false,
                        first: 20,
                        filter: {
                          name: userInput,
                          isActive: true
                        }
                      });
                      await refetchServicesByCode({
                        skip: false,
                        first: 20,
                        filter: {
                          code: userInput,
                          isActive: true
                        }
                      });
                    }
                  },
                  300
                )}
              />
            );
          }}
        </Composer>
      );
    }}
  />
);

const GetServicesQuery = gql`
  query GetServicesQuery(
    $first: Int
    $filter: ServiceFilter
    $skip: Boolean! = false
  ) {
    services(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        name
        code
      }
    }
  }
`;

export default ComposedSearchServiceField;
