import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  Form,
  LocationParams,
  Validation
} from "@edenlabllc/ehealth-components";
import { Dictionary, EncounterConnection } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";
import paramToBase64 from "../../../helpers/paramToBase64";
import { MonitoringTabProps } from "../../../helpers/types";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

import { PatientSearchField } from "../../ClinicalMonitoring/PatientSearchField";
import { SearchResultTable } from "./EpisodesTab";

const Encounter = ({
  id,
  query = EncountersQuery,
  isClinicalMonitoring = false
}: MonitoringTabProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="encounter"
  >
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Query
          skip={!locationParams.first && !locationParams.last}
          query={query}
          fetchPolicy="network-only"
          variables={prepareParamsToQuery(
            id!,
            locationParams,
            isClinicalMonitoring
          )}
        >
          {({
            data = {},
            loading
          }: QueryResult<{ [key: string]: EncounterConnection }>) => {
            const encounters = isClinicalMonitoring
              ? data.impersonalEncounters
              : data.encounters;

            return (
              <LoadingOverlay loading={loading}>
                <Box p={1}>
                  <SearchForm
                    initialValues={locationParams}
                    onSubmit={setLocationParams}
                    renderPrimary={() =>
                      primarySearchFields(isClinicalMonitoring)
                    }
                    onResetSkipPagination
                  />
                </Box>
                {!data || isEmpty(encounters) || isEmpty(encounters.nodes) ? (
                  <EmptyData mx={2} />
                ) : (
                  <Box mb={6} p={2}>
                    <Box>
                      <Text>
                        <Trans>Total found</Trans>: {encounters.totalEntries}
                      </Text>
                    </Box>
                    <SearchResultTable
                      data={encounters.nodes}
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                      statusSystem="eHealth/encounter_statuses"
                      tableName="encounters"
                      pathSlug={
                        isClinicalMonitoring
                          ? `encounter/${
                              locationParams.filter &&
                              locationParams.filter.patientId
                            }`
                          : "encounter"
                      }
                      isClinicalMonitoring={isClinicalMonitoring}
                    />
                    <Pagination {...encounters.pageInfo} />
                  </Box>
                )}
              </LoadingOverlay>
            );
          }}
        </Query>
      )}
    </LocationParams>
  </Ability>
);

export default Encounter;

const EncountersQuery = gql`
  query EncounterQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: EncounterFilter!
    $orderBy: EncounterOrderBy
  ) {
    encounters(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

const prepareParamsToQuery = (
  id: string,
  locationParams: SearchParams,
  isClinicalMonitoring: boolean
) => {
  const {
    encounterDate,
    status,
    episodeId,
    incomingReferralId,
    encounterId,
    ...restFilterParams
  } = locationParams.filter || {};

  return {
    orderBy: "INSERTED_AT_DESC",
    ...pagination(locationParams),
    filter: {
      ...(!isClinicalMonitoring && { personId: id }),
      ...restFilterParams,
      ...(status && {
        status: status.toUpperCase()
      }),
      ...(episodeId && {
        episodeId: paramToBase64("Episode", episodeId)
      }),
      ...(incomingReferralId && {
        incomingReferralId: paramToBase64("ServiceRequest", incomingReferralId)
      }),
      ...(encounterDate && {
        date: `${encounterDate.from}/${encounterDate.to}`
      }),
      ...(encounterId && {
        id: paramToBase64("Encounter", encounterId)
      })
    }
  };
};

const primarySearchFields = (isClinicalMonitoring: boolean) => {
  const { i18n } = useLingui();
  return (
    <Flex flexDirection="column">
      <PatientSearchField isClinicalMonitoring={isClinicalMonitoring} />
      <Flex>
        <Box width={1 / 3} mt={3} px={1}>
          <DictionaryValue name="eHealth/encounter_statuses">
            {(dict: Dictionary["values"]) => (
              <Field.Select
                name="filter.status"
                label={<Trans id="Encounter status" />}
                placeholder={i18n._(t`Select status`)}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || item}
                variant="select"
              />
            )}
          </DictionaryValue>
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter episode ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.episodeId"
                label={<Trans id="Episode ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.episodeId"
            options={UUID_PATTERN}
            message="Invalid episode ID"
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter incoming referral ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.incomingReferralId"
                label={<Trans id="Incoming referral ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.incomingReferralId"
            options={UUID_PATTERN}
            message="Invalid incoming referral ID"
          />
        </Box>
      </Flex>
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter encounter date"
            render={({ translation }) => (
              <Field.RangePicker
                rangeNames={[
                  "filter.encounterDate.from",
                  "filter.encounterDate.to"
                ]}
                label={<Trans id="Encounter date" />}
              />
            )}
          />
          <Form.Spy>
            {({ values = {} }: $TSFixMe) => {
              if (
                (values.filter &&
                  values.filter.encounterDate &&
                  values.filter.encounterDate.from) ||
                (values.filter &&
                  values.filter.encounterDate &&
                  values.filter.encounterDate.to)
              ) {
                return (
                  <>
                    <Trans
                      id="Required field"
                      render={({ translation }) => (
                        <Validation.Required
                          field="filter.encounterDate.from"
                          message={translation}
                        />
                      )}
                    />
                    <Trans
                      id="Required field"
                      render={({ translation }) => (
                        <Validation.Required
                          field="filter.encounterDate.to"
                          message={translation}
                        />
                      )}
                    />
                  </>
                );
              }
              return null;
            }}
          </Form.Spy>
        </Box>
        {isClinicalMonitoring && (
          <Box px={1} width={1 / 3} mt={3}>
            <Trans
              id="Enter encounter ID"
              render={({ translation }) => (
                <Field.Text
                  name="filter.encounterId"
                  label={<Trans id="Encounter ID" />}
                  placeholder={translation}
                />
              )}
            />
            <Validation.Matches
              field="filter.encounterId"
              options={UUID_PATTERN}
              message="Invalid encounter ID"
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
