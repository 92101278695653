import React from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box, Heading, Text } from "@rebass/emotion";

import { Modal } from "@edenlabllc/ehealth-components";
import {
  Person,
  Scalars,
  PersonAuthenticationMethodFilter
} from "@ehealth/ehealth-ua.schema";

import Button from "../../../components/Button";

import { PersonQuery } from "./index";

type ResetAuthProps = {
  status: Person["status"];
  databaseId: Scalars["UUID"];
  authSearchValues: PersonAuthenticationMethodFilter;
};

const ResetAuth = ({
  status,
  databaseId,
  authSearchValues
}: ResetAuthProps) => {
  const [isVisible, setVisibilityState] = React.useState(false);
  const toggle = () => setVisibilityState((prevState) => !prevState);

  return (
    <Box>
      <Mutation
        mutation={ResetAuthMethodMutation}
        refetchQueries={() => [
          {
            query: PersonQuery,
            variables: authSearchValues
          }
        ]}
      >
        {(resetPersonAuth: MutationFunction) => (
          <Flex justifyContent="flex-end">
            <Flex flexDirection="column" alignItems="flex-end">
              <Button
                variant="green"
                disabled={isVisible || status === "INACTIVE"}
                onClick={toggle}
              >
                <Trans>Reset Authentication Methods</Trans>
              </Button>
            </Flex>
            {isVisible && (
              <Modal width={760} backdrop>
                <Heading as="h1" fontWeight="normal" mb={6}>
                  <Trans>Change authentication method</Trans>
                </Heading>
                <Text lineHeight={2} textAlign="center" mb={6}>
                  <Trans>
                    Warning!
                    <br />
                    After confirmation, your own authentication method will be
                    changed to the uncertain. All third party authentication
                    methods will become invalid.
                  </Trans>
                </Text>
                <Flex justifyContent="center">
                  <Box mx={2}>
                    <Button variant="blue" onClick={toggle}>
                      <Trans>Return</Trans>
                    </Button>
                  </Box>
                  <Box mx={2}>
                    <Button
                      variant="green"
                      onClick={async () => {
                        try {
                          await resetPersonAuth({
                            variables: {
                              input: { personId: databaseId }
                            }
                          });
                          toggle();
                        } catch (errors) {
                          toggle();
                          return errors;
                        }
                      }}
                    >
                      <Trans>Reset Authentication Methods</Trans>
                    </Button>
                  </Box>
                </Flex>
              </Modal>
            )}
          </Flex>
        )}
      </Mutation>
    </Box>
  );
};

export default ResetAuth;

const ResetAuthMethodMutation = gql`
  mutation ResetAuthMethodMutation($input: ResetPersonAuthInput!) {
    person: resetPersonAuth(input: $input) {
      person {
        id
      }
    }
  }
`;
