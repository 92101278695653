import React from "react";
import { Box, Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Validation } from "@edenlabllc/ehealth-components";

import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

import { EDRPOU_OR_PASSPORT_OR_LEGAL_ENTITY_ID_PATTERN } from "../../../constants/validationPatterns";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 1.5}>
      <Trans
        id="Legal entity edrpou"
        render={({ translation }) => (
          <Field.Text
            name="filter.deactivatedLegalEntity.edrpou"
            label={<Trans id="Find legal entity" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
          />
        )}
      />
      <Validation.Matches
        field="filter.deactivatedLegalEntity.edrpou"
        options={EDRPOU_OR_PASSPORT_OR_LEGAL_ENTITY_ID_PATTERN}
        message="Invalid number"
      />
    </Box>
    <Box px={1} width={1 / 3}>
      <SearchField.Status
        name="filter.status"
        status="JOBS"
        label={<Trans id="Job status" />}
      />
    </Box>
  </Flex>
);

export { PrimarySearchFields };
