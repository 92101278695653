import { gql } from "graphql-tag";
import Pagination from "../../../components/Pagination";
import CodeableConcept from "../../../components/CodeableConcept";
import Condition from "../../PracticalMonitoring/Details/Condition";

export const ImpersonalConditionsQuery = gql`
  query ImpersonalConditionsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImpersonalConditionFilter!
    $orderBy: ConditionOrderBy
  ) {
    impersonalConditions(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status: verificationStatus
        insertedAt
        code {
          ...CodeableConcept
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
  ${CodeableConcept.fragments!.entry}
`;

export const ImpersonalConditionByIDQuery = gql`
  query ImpersonalConditionQuery(
    $id: ID!
    $patientId: String!
    $skipAdditionalFields: Boolean!
  ) {
    impersonalCondition(id: $id, patientId: $patientId) {
      ...Condition
    }
  }
  ${Condition.fragments.entry}
`;
