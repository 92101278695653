import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import {
  Maybe,
  LegalEntityReorganizationJob,
  LegalEntityReorganizationTask
} from "@ehealth/ehealth-ua.schema";

import EmptyData from "../../../components/EmptyData";
import Pagination from "../../../components/Pagination";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import TasksTable from "../../../components/TasksTable";

const taskNames: any = {
  "Deactivate related entities": <Trans>Deactivate related entities</Trans>,
  "Create related legal entity": <Trans>Create related legal entity</Trans>,
  "Reorganize legal entity": <Trans>Reorganize legal entity</Trans>,
  "Separate legal entity": <Trans>Separate legal entity</Trans>,
  "Transform legal entity": <Trans>Transform legal entity</Trans>,
  "Merge legal entity": <Trans>Merge legal entity</Trans>
};

type TasksProps = RouteComponentProps & {
  tasks: LegalEntityReorganizationJob["tasks"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const Tasks = ({ tasks, locationParams, setLocationParams }: TasksProps) => {
  const { nodes, pageInfo } = tasks || {};
  if (isEmpty(nodes)) return <EmptyData />;

  return (
    <>
      <TasksTable
        tasks={nodes!}
        locationParams={locationParams}
        setLocationParams={setLocationParams}
        taskName={({ name }: { name?: Maybe<string> }) =>
          name && taskNames[name]
        }
        taskMeta={({
          meta
        }: {
          meta: LegalEntityReorganizationTask["meta"];
        }) => {
          const { edrpou } = meta || {};

          return (
            <Box>
              {edrpou ? (
                <>
                  <Trans>EDRPOU</Trans>: {edrpou}
                </>
              ) : (
                "-"
              )}
            </Box>
          );
        }}
        tableName="legal-entity-reorganization-jobs-table/tasks"
      />
      <Pagination {...pageInfo} />
    </>
  );
};

Tasks.fragments = {
  entry: gql`
    fragment Task on LegalEntityReorganizationTask {
      id
      databaseId
      name
      status
      insertedAt
      endedAt
      updatedAt
      error {
        message
      }
      meta {
        edrpou
      }
    }
  `
};

export default Tasks;
