import React from "react";
import { Flex, Box, Text } from "@rebass/emotion";

import { LocationParams } from "@edenlabllc/ehealth-components";

import Button from "../Button";
import { TLocationParams } from "../SearchForm";

type ShowItemsProps = {
  list: number[];
};

const ShowItems = ({ list = [] }: ShowItemsProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => {
      const { first, last } = locationParams;
      return (
        <Flex alignItems="center" justifyContent="center" mr="1">
          <Text fontSize="0" fontWeight="700" color="blueberrySoda">
            показати
          </Text>
          <Box ml="1">
            {list.map((item, key) => (
              <Button
                key={key}
                variant="none"
                border="none"
                px="1"
                onClick={() => {
                  setLocationParams({
                    ...locationParams,
                    first: !last ? String(item) : null,
                    last: last ? String(item) : null
                  });
                }}
              >
                <Text
                  fontSize="0"
                  fontWeight="700"
                  color={
                    (first && item === parseInt(first)) ||
                    (last && item === parseInt(last)) ||
                    (!key && !last && !first)
                      ? "silverCity"
                      : "blueberrySoda"
                  }
                >
                  {item}
                </Text>
              </Button>
            ))}
          </Box>
        </Flex>
      );
    }}
  </LocationParams>
);

export default ShowItems;
