import React from "react";
import { Trans } from "@lingui/macro";

import { Dictionary, Person, Phone } from "@ehealth/ehealth-ua.schema";

import DefinitionListView from "../../../../components/DefinitionListView";

import TableDiff from "./TableDiff";
import { combineNestedData } from "./DocumentsTable";

type PhonesTableProps = {
  header: Dictionary["values"];
  person: Person["phones"];
  masterPerson: Person["phones"];
};

const PhonesTable = ({
  header,
  person,
  masterPerson,
  ...props
}: PhonesTableProps) => (
  <TableDiff
    header={header}
    data={combineNestedData(person, masterPerson, header)}
    renderRow={({ ...phone }) => {
      const [row] = Object.entries(phone).map(([key, value]) => ({
        [key]: (
          <DefinitionListView
            labels={{
              number: <Trans>Number</Trans>
            }}
            data={{
              number: typeof value === "object" && (value as Phone).number
            }}
            labelWidth="120px"
            marginBottom={0}
          />
        )
      }));
      return row;
    }}
    {...props}
    hideEmptyFields
  />
);
export default PhonesTable;
