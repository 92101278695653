import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { formatWorkingHours } from "@edenlabllc/ehealth-utils";
import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  ContractRequest,
  Division,
  Phone,
  Maybe
} from "@ehealth/ehealth-ua.schema";

import AddressView from "../../../../components/AddressView";
import EmptyData from "../../../../components/EmptyData";
import Table from "../../../../components/Table";

import WEEK_DAYS from "../../../../helpers/weekDays";

type DivisionsProps = RouteComponentProps<{
  contractorDivisions: ContractRequest["contractorDivisions"];
}>;

const Divisions = ({ contractorDivisions }: DivisionsProps) =>
  contractorDivisions && contractorDivisions.length > 0 ? (
    <Table
      data={contractorDivisions}
      header={{
        name: <Trans>Division name</Trans>,
        addresses: <Trans>Address</Trans>,
        mountainGroup: <Trans>Mountain region</Trans>,
        workingHours: <Trans>Work schedule</Trans>,
        phones: (
          <>
            <Trans>Phone</Trans>
            <br />
            <Trans>Email</Trans>
          </>
        )
      }}
      renderRow={({
        name,
        addresses,
        mountainGroup,
        workingHours,
        phones,
        email
      }: Division) => ({
        name,
        mountainGroup: (
          <Flex justifyContent="center">
            {mountainGroup ? <PositiveIcon /> : <NegativeIcon />}
          </Flex>
        ),
        phones: (
          <>
            <Box>
              {phones
                .filter((a: Maybe<Phone>) => a && a.type === "MOBILE")
                .map((item: Maybe<Phone>) => item && item.number)[0] ||
                (phones && phones[0] && phones[0].number)}
            </Box>
            <Box>{email}</Box>
          </>
        ),
        workingHours:
          workingHours &&
          formatWorkingHours(WEEK_DAYS, workingHours).map(
            ({ day, hours }: { day: string; hours: string[][] }) => (
              <Box pb={2}>
                {day}: {hours.map((hour) => hour.join("-")).join(", ")}
              </Box>
            )
          ),
        addresses: addresses
          .filter((a) => a && a.type === "RESIDENCE")
          .map((item, key) => <AddressView data={item} key={key} />)
      })}
      tableName="/capitation-contract-requests/divisions"
      hiddenFields="workingHours"
      hidePagination
    />
  ) : (
    <EmptyData />
  );

Divisions.fragments = {
  entry: gql`
    fragment Divisions on CapitationContractRequest {
      contractorDivisions {
        id
        name
        addresses {
          ...Addresses
        }
        phones {
          type
          number
        }
        email
        mountainGroup
        workingHours
      }
    }
    ${AddressView.fragments.entry}
  `
};

export default Divisions;
