import React from "react";
import { gql } from "graphql-tag";
import { Trans, Plural } from "@lingui/macro";
import { i18n } from "@lingui/core";
import differenceInSeconds from "date-fns/difference_in_seconds";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  MedicationRegistryJob,
  MedicationRegistryJobConnection
} from "@ehealth/ehealth-ua.schema";

import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import Link from "../../../components/Link";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type MedicationRegistryJobsTableProps = {
  medicationRegistryJobs: MedicationRegistryJobConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const MedicationRegistryJobsTable = ({
  medicationRegistryJobs,
  locationParams,
  setLocationParams
}: MedicationRegistryJobsTableProps) => (
  <Table
    data={medicationRegistryJobs}
    header={{
      databaseId: <Trans>ID</Trans>,
      name: <Trans>Job name</Trans>,
      registerType: <Trans>Register type</Trans>,
      startedAt: <Trans>Job start time</Trans>,
      executionTime: <Trans>Execution time</Trans>,
      status: <Trans>Job status</Trans>,
      details: <Trans>Details</Trans>
    }}
    renderRow={({
      id,
      databaseId,
      name,
      registerType,
      status,
      startedAt,
      endedAt
    }: MedicationRegistryJob) => ({
      databaseId,
      name,
      registerType: registerType && (
        <DictionaryValue name="REGISTER_TYPE" item={registerType} />
      ),
      startedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, startedAt),
      executionTime:
        status === "PENDING" || !endedAt || !startedAt ? (
          "-"
        ) : (
          <Plural
            id="{0, plural, zero {# секунд} one {# секунда} few {# секунди} many {# секунд} other {# секунд}}"
            value={differenceInSeconds(endedAt, startedAt)}
            zero="# seconds"
            one="# second"
            few="# seconds"
            many="# seconds"
            other="# seconds"
          />
        ),
      status: <Badge type="JOBS" name={status} display="block" />,
      details: (
        <Link to={`../${id}`} fontWeight="bold">
          <Trans>Show details</Trans>
        </Link>
      )
    })}
    sortableFields={["startedAt"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    hiddenFields="name"
    tableName="medication-registry-jobs/search"
  />
);

MedicationRegistryJobsTable.fragments = {
  entry: gql`
    fragment MedicationRegistryJobs on MedicationRegistryJob {
      id
      databaseId
      name
      registerType
      status
      startedAt
      endedAt
    }
  `
};

export default MedicationRegistryJobsTable;
