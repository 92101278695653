import { flatten, unflatten } from "flat";

const onlyValidKeys = (object: any, validKeys: string[]) => {
  const newObject = {};
  const flattenObject: object = flatten(object);

  Object.keys(flattenObject).forEach((key) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (validKeys.includes(key)) newObject[key] = flattenObject[key];
  });
  return unflatten(newObject);
};
export default onlyValidKeys;
