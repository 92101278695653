import { Config } from "react-awesome-query-builder";

import getFields from "./getFields";
import eHealthConfig from "./config";

const useConfig = () => {
  const fields = getFields();
  // @ts-ignore
  const config: Config = {
    ...eHealthConfig,
    fields
  };
  return config;
};

export default useConfig;
