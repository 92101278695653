import _slicedToArray from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import isNil from "lodash/isNil";
import flatten from "flat";
var stringifySearchParams = function stringifySearchParams(params) {
  return new URLSearchParams(Object.entries(flatten(params)).filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    return !isNil(value);
  })).toString();
};
export default stringifySearchParams;