import React from "react";
import { Flex, Box } from "@rebass/emotion";

import { Field } from "@edenlabllc/ehealth-components";
import system from "@edenlabllc/ehealth-system-components";
import { DropDownButton as PlusIcon } from "@edenlabllc/ehealth-icons";

import Button from "../../components/Button";

type DefaultRemoveButtonProps = {
  onClick: () => void;
  buttonText?: React.ReactElement;
};

type ArrayFieldProps = {
  name: string;
  addText: React.ReactNode;
  fields: ({
    name,
    fieldsProps
  }: {
    name: string;
    fieldsProps: any;
  }) => React.ReactElement;
  removeButton?: ({
    buttonText,
    onClick
  }: DefaultRemoveButtonProps) => React.ReactElement;
  removeText?: React.ReactElement;
  headerComponent?: ({ index }: { index: number }) => React.ReactElement;
  firstItemPinned?: boolean;
  vertical?: boolean;
  fieldsProps?: any;
  withAddButton?: boolean;
  isVirtualizedList?: boolean;
  rowLoaderText?: string;
  searchPlaceholderText?: string;
  foundLabel?: string;
  selectFieldPlaceholderText?: string;
  wrapperProps?: any;
};

const ArrayField = ({
  name,
  addText,
  removeText,
  removeButton: RemoveButton = DefaultRemoveButton,
  fields: Fields,
  headerComponent: Header,
  firstItemPinned,
  vertical,
  fieldsProps,
  withAddButton = true,
  isVirtualizedList,
  rowLoaderText,
  searchPlaceholderText,
  foundLabel,
  selectFieldPlaceholderText,
  wrapperProps = {}
}: ArrayFieldProps) => (
  <Field.Array
    name={name}
    addText={addText}
    addButton={({
      onClick,
      addText
    }: {
      onClick: () => void;
      addText: React.ReactElement;
    }) =>
      withAddButton && (
        <AddButton onClick={onClick}>
          <Flex>
            <Box mr={2}>
              <PlusIcon width={16} height={16} />
            </Box>
            {addText}
          </Flex>
        </AddButton>
      )
    }
    disableRemove
    isVirtualizedList={isVirtualizedList}
    rowLoaderText={rowLoaderText}
    searchPlaceholderText={searchPlaceholderText}
    foundLabel={foundLabel}
    selectFieldPlaceholderText={selectFieldPlaceholderText}
  >
    {({
      name,
      index,
      fields: {
        remove,
        value: { length }
      }
    }: $TSFixMe) => (
      <>
        {Header && <Header index={index} />}
        <Wrapper is={vertical && Box} {...wrapperProps}>
          <Fields name={name} fieldsProps={fieldsProps} />
          {length > 0 &&
            (firstItemPinned && !index ? null : (
              <RemoveButton
                buttonText={removeText}
                onClick={() => remove(index)}
              />
            ))}
        </Wrapper>
      </>
    )}
  </Field.Array>
);

export default ArrayField;

const DefaultRemoveButton = ({
  buttonText,
  onClick
}: DefaultRemoveButtonProps) => (
  <Button mb={5} type="reset" variant="red" onClick={onClick}>
    {buttonText}
  </Button>
);

const Wrapper = system(
  {
    is: Flex
  },
  {},
  "alignItems"
);

const AddButton = system(
  {
    fontSize: 0,
    color: "rockmanBlue"
  },
  {
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    outline: "none",
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: "bold"
  },
  "fontSize",
  "color"
);
