import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { DocumentNode, MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";

import Button from "../../../components/Button";
import Popup from "../../../components/Popup";
import { SearchParams } from "../../../components/SearchForm";

type DeactivateServicePopupProps = {
  id: string;
  name: string;
  locationParams: SearchParams;
  serviceDetailsQuery: DocumentNode;
};

const DeactivateServicePopup = ({
  id,
  name,
  locationParams,
  serviceDetailsQuery
}: DeactivateServicePopupProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Box>
      <Mutation
        mutation={DeactivateServiceMutation}
        refetchQueries={() => [
          {
            query: serviceDetailsQuery,
            variables: { id, ...locationParams }
          }
        ]}
      >
        {(deactivateService: MutationFunction) => (
          <>
            <Button onClick={toggle} variant="red">
              <Trans>Deactivate</Trans>
            </Button>
            <Popup
              visible={isPopupVisible}
              onCancel={toggle}
              title={
                <>
                  <Trans>Deactivate</Trans> "{name}
                  "?
                </>
              }
              okText={<Trans>Deactivate</Trans>}
              onOk={async () => {
                await deactivateService({
                  variables: {
                    input: {
                      id
                    }
                  }
                });
                toggle();
              }}
            />
          </>
        )}
      </Mutation>
    </Box>
  );
};

const DeactivateServiceMutation = gql`
  mutation DeactivateServiceMutation($input: DeactivateServiceInput!) {
    deactivateService(input: $input) {
      service {
        id
      }
    }
  }
`;

export default DeactivateServicePopup;
