const WEEK_DAYS = [
  { key: "mon", value: "ПН" },
  { key: "tue", value: "ВТ" },
  { key: "wed", value: "СР" },
  { key: "thu", value: "ЧТ" },
  { key: "fri", value: "ПТ" },
  { key: "sat", value: "СБ" },
  { key: "sun", value: "НД" }
];

export default WEEK_DAYS;
