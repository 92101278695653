import React from "react";
import {
  AnyObject,
  BasicConfig,
  BasicFuncs,
  FieldProps,
  Operator,
  ValueSource,
  ConjsProps,
  ButtonProps,
  ValueSourcesProps,
  NumberWidgetProps,
  TextWidgetProps,
  DateTimeWidgetProps,
  SelectWidgetProps
} from "react-awesome-query-builder";
import { List as ImmutableList } from "immutable";

import Widgets from "./components/widgets";
import { BooleanProps } from "./components/widgets/value/Boolean";
import { MultiselectProps } from "./components/widgets/value/MultiSelect";

import { getDimLocalization } from "./helpers";

const {
  TextWidget,
  NumberWidget,
  SelectWidget,
  MultiSelectWidget,
  DateWidget,
  BooleanWidget,

  Button,
  FieldSelect,
  Conjs,
  ValueSources,
  confirm
} = Widgets;

const settings = {
  ...BasicConfig.settings,
  renderField: (props: FieldProps) => <FieldSelect {...props} />,
  renderOperator: (props: FieldProps) => <FieldSelect {...props} />,
  renderFunc: (props: FieldProps) => <FieldSelect {...props} />,
  renderConjs: (props: ConjsProps) => <Conjs {...props} />,
  renderButton: (props: ButtonProps) => <Button {...props} />,
  renderValueSources: (props: ValueSourcesProps) => <ValueSources {...props} />,
  renderConfirm: confirm,

  fieldSeparator: ".",
  fieldSeparatorDisplay: ".",
  renderSize: "small",
  maxLabelsLength: 100,
  canReorder: true,
  canRegroup: true,
  showLock: false,
  canDeleteLocked: false,
  showNot: true,
  canLeaveEmptyGroup: true,
  shouldCreateEmptyGroup: false,
  forceShowConj: false,
  canShortMongoQuery: true,
  removeEmptyGroupsOnLoad: true,
  removeIncompleteRulesOnLoad: false,
  groupActionsPosition: "topRight", // oneOf [topLeft, topCenter, topRight, bottomLeft, bottomCenter, bottomRight]
  setOpOnChangeField: ["keep", "default"], // 'default' (default if present), 'keep' (keep prev from last field), 'first', 'none'
  groupOperators: ["some", "all", "none"],

  convertableWidgets: {
    number: ["slider", "rangeslider"],
    slider: ["number", "rangeslider"],
    rangeslider: ["number", "slider"],
    text: ["textarea"],
    textarea: ["text"]
  },

  // localization
  locale: {
    moment: "uk"
  },
  valueLabel: "Значення",
  valuePlaceholder: "Введіть значення",
  fieldLabel: "Поле",
  operatorLabel: "Оператор",
  funcLabel: "Функція",
  fieldPlaceholder: "Виберіть значення",
  funcPlaceholder: "Виберіть функцію",
  operatorPlaceholder: "Виберіть оператор",
  lockLabel: "Lock",
  lockedLabel: "Locked",
  deleteLabel: null,
  addGroupLabel: "Додати групу",
  addCaseLabel: "Додати умову",
  addDefaultCaseLabel: "Додати умову за замовчуванням",
  defaultCaseLabel: "За замовчуванням:",
  addRuleLabel: "Додати правило",
  addSubRuleLabel: "Додати підправило",
  delGroupLabel: "",
  notLabel: "Не",
  valueSourcesPopupTitle: "Виберіть джерело значення",
  removeRuleConfirmOptions: {
    title: "Ви впевнені, що хочете видалити це правило?",
    okText: "Так",
    okType: "danger",
    cancelText: "Відміна"
  },
  removeGroupConfirmOptions: {
    title: "Ви впевнені, що хочете видалити цю групу?",
    okText: "Так",
    okType: "danger",
    cancelText: "Відміна"
  },

  valueSourcesInfo: {
    value: {
      label: "Значення"
    },
    field: {
      label: "Поле",
      widget: "field"
    },
    func: {
      label: "Функція",
      widget: "func"
    }
  },

  formatAggr: (
    whereStr: string,
    aggrField: string,
    operator: string,
    value: string | ImmutableList<string>,
    valueSrc: ValueSource,
    valueType: string,
    opDef: Operator
  ) => {
    const { labelForFormat, cardinality } = opDef;
    if (cardinality === 0) {
      const cond = whereStr ? ` має ${whereStr}` : "";
      return `${labelForFormat} з ${aggrField}${cond}`;
    } else if (cardinality === undefined || cardinality === 1) {
      const cond = whereStr ? ` де ${whereStr}` : "";
      return `Кількість з ${aggrField}${cond} ${labelForFormat} ${value}`;
    } else if (cardinality === 2) {
      const cond = whereStr ? ` де ${whereStr}` : "";
      const valFrom = (value as ImmutableList<string>).first();
      const valTo = (value as ImmutableList<string>).get(1);
      return `Кількість з ${aggrField}${cond} ${labelForFormat} ${valFrom} та ${valTo}`;
    }
  }
};

const widgets = {
  ...BasicConfig.widgets,
  text: {
    ...BasicConfig.widgets.text,
    factory: (props: TextWidgetProps) => <TextWidget {...props} />,
    valueLabel: "Текст",
    valuePlaceholder: "Введіть текст"
  },
  number: {
    ...BasicConfig.widgets.number,
    factory: (props: NumberWidgetProps) => <NumberWidget {...props} />,
    valueLabel: "Число",
    valuePlaceholder: "Введіть число",
    valueLabels: [
      { label: "Number from", placeholder: "Enter number from" },
      { label: "Number to", placeholder: "Enter number to" }
    ]
  },
  select: {
    ...BasicConfig.widgets.select,
    factory: (props: SelectWidgetProps) => <SelectWidget {...props} />,
    valueLabel: "Значення",
    valuePlaceholder: "Виберіть значення"
  },
  multiselect: {
    ...BasicConfig.widgets.multiselect,
    factory: (props: MultiselectProps) => <MultiSelectWidget {...props} />,
    valueLabel: "Значення",
    valuePlaceholder: "Виберіть значення"
  },
  date: {
    ...BasicConfig.widgets.date,
    factory: (props: DateTimeWidgetProps) => <DateWidget {...props} />
  },
  datetime: {
    ...BasicConfig.widgets.datetime,
    factory: (props: DateTimeWidgetProps) => <DateWidget {...props} />
  },
  boolean: {
    ...BasicConfig.widgets.boolean,
    factory: (props: BooleanProps) => <BooleanWidget {...props} />,
    labelYes: "Так",
    labelNo: "Ні"
  }
};

const types = {
  ...BasicConfig.types,
  text: {
    ...BasicConfig.types.text,
    widgets: {
      ...BasicConfig.types.text.widgets,
      text: {
        ...BasicConfig.types.text.widgets.text,
        operators: [
          "equal",
          "not_equal",
          "like",
          "not_like",
          "is_empty",
          "is_not_empty",
          "is_null",
          "is_not_null"
        ]
      },
      textarea: {
        ...BasicConfig.types.text.widgets.textarea,
        operators: [
          "equal",
          "not_equal",
          "like",
          "not_like",
          "is_empty",
          "is_not_empty",
          "is_null",
          "is_not_null"
        ]
      },
      field: {
        operators: ["equal", "not_equal"]
      }
    }
  },
  "!group": {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ...BasicConfig.types["!group"],
    widgets: {
      number: {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        ...BasicConfig.types["!group"].widgets.number,
        opProps: {
          equal: {
            label: "Кількість =="
          },
          not_equal: {
            label: "Кількість !="
          },
          less: {
            label: "Кількість <"
          },
          less_or_equal: {
            label: "Кількість <="
          },
          greater: {
            label: "Кількість >"
          },
          greater_or_equal: {
            label: "Кількість >="
          },
          between: {
            label: "Кількість поміж"
          },
          not_between: {
            label: "Кількість не поміж"
          }
        }
      }
    }
  }
};

const conjunctions = {
  ...BasicConfig.conjunctions,
  AND: {
    ...BasicConfig.conjunctions.AND,
    label: "Та",
    formatConj: (
      children: ImmutableList<string>,
      conj: string,
      not: boolean,
      isForDisplay?: boolean
    ) => {
      return children.size > 1
        ? (not ? "не " : "") +
            "(" +
            children.join(" " + (isForDisplay ? "та" : "&&") + " ") +
            ")"
        : (not ? "не (" : "") + children.first() + (not ? ")" : "");
    }
  },
  OR: {
    ...BasicConfig.conjunctions.OR,
    label: "Або",
    formatConj: (
      children: ImmutableList<string>,
      conj: string,
      not: boolean,
      isForDisplay?: boolean
    ) => {
      return children.size > 1
        ? (not ? "не " : "") +
            "(" +
            children.join(" " + (isForDisplay ? "або" : "||") + " ") +
            ")"
        : (not ? "не (" : "") + children.first() + (not ? ")" : "");
    }
  }
};

const operators = {
  ...BasicConfig.operators,
  equal: {
    ...BasicConfig.operators.equal,
    formatOp: (
      field: string,
      op: string,
      value: string | ImmutableList<string>,
      valueSrcs?: ValueSource,
      valueTypes?: string,
      opDef?: Operator,
      operatorOptions?: AnyObject,
      isForDisplay?: boolean
    ) => {
      const opStr = isForDisplay ? "=" : opDef && opDef.label;
      if (valueTypes === "boolean" && isForDisplay)
        return value === "No" ? `не ${field}` : `${field}`;
      else return `${field} ${opStr} ${value}`;
    }
  },
  not_equal: {
    ...BasicConfig.operators.not_equal,
    formatOp: (
      field: string,
      op: string,
      value: string | ImmutableList<string>,
      valueSrcs?: ValueSource,
      valueTypes?: string,
      opDef?: Operator,
      operatorOptions?: AnyObject,
      isForDisplay?: boolean
    ) => {
      if (valueTypes === "boolean" && isForDisplay)
        return value === "No" ? `${field}` : `не ${field}`;
      else return `${field} ${opDef && opDef.label} ${value}`;
    }
  },
  like: {
    ...BasicConfig.operators.like,
    label: "Містить",
    labelForFormat: "Містить"
  },
  not_like: {
    ...BasicConfig.operators.not_like,
    label: "Не містить",
    labelForFormat: "Не містить"
  },
  starts_with: {
    ...BasicConfig.operators.starts_with,
    label: "Починається з",
    labelForFormat: "Починається з"
  },
  ends_with: {
    ...BasicConfig.operators.ends_with,
    label: "Закінчується на",
    labelForFormat: "Закінчується на"
  },
  between: {
    ...BasicConfig.operators.between,
    label: "Поміж",
    labelForFormat: "Поміж",
    valueLabels: ["Значення з", "Значення до"],
    textSeparators: [null, "та"],
    formatOp: (
      field: string,
      op: string,
      values: ImmutableList<string>,
      valueSrcs?: ValueSource,
      valueTypes?: string,
      opDef?: Operator,
      operatorOptions?: AnyObject,
      isForDisplay?: boolean
    ) => {
      const valFrom = values.first();
      const valTo = values.get(1);
      if (isForDisplay) return `${field} поміж ${valFrom} та ${valTo}`;
      else return `${field} >= ${valFrom} && ${field} <= ${valTo}`;
    }
  },
  not_between: {
    ...BasicConfig.operators.not_between,
    label: "Не поміж",
    labelForFormat: "Не поміж",
    valueLabels: ["Значення з", "Значення до"],
    formatOp: (
      field: string,
      op: string,
      values: ImmutableList<string>,
      valueSrcs?: ValueSource,
      valueTypes?: string,
      opDef?: Operator,
      operatorOptions?: AnyObject,
      isForDisplay?: boolean
    ) => {
      const valFrom = values.first();
      const valTo = values.get(1);
      if (isForDisplay) return `${field} не поміж ${valFrom} та ${valTo}`;
      else return `(${field} < ${valFrom} || ${field} > ${valTo})`;
    }
  },
  is_empty: {
    ...BasicConfig.operators.is_empty,
    label: "Є порожнім",
    labelForFormat: "Є порожнім",
    formatOp: (
      field: string,
      op: string,
      value: string | ImmutableList<string>,
      valueSrcs?: ValueSource,
      valueTypes?: string,
      opDef?: Operator,
      operatorOptions?: AnyObject,
      isForDisplay?: boolean
    ) => {
      return isForDisplay ? `${field} є порожнім` : `!${field}`;
    }
  },
  is_not_empty: {
    ...BasicConfig.operators.is_not_empty,
    label: "Не є порожнім",
    labelForFormat: "Не є порожнім",
    formatOp: (
      field: string,
      op: string,
      value: string | ImmutableList<string>,
      valueSrcs?: ValueSource,
      valueTypes?: string,
      opDef?: Operator,
      operatorOptions?: AnyObject,
      isForDisplay?: boolean
    ) => {
      return isForDisplay ? `${field} не є порожнім` : `!!${field}`;
    }
  },
  is_null: {
    ...BasicConfig.operators.is_null,
    label: "Є null",
    labelForFormat: "Є null",
    formatOp: (
      field: string,
      op: string,
      value: string | ImmutableList<string>,
      valueSrcs?: ValueSource,
      valueTypes?: string,
      opDef?: Operator,
      operatorOptions?: AnyObject,
      isForDisplay?: boolean
    ) => {
      return isForDisplay ? `${field} є null` : `!${field}`;
    }
  },
  is_not_null: {
    ...BasicConfig.operators.is_not_null,
    label: "Не є null",
    labelForFormat: "Не є null",
    formatOp: (
      field: string,
      op: string,
      value: string | ImmutableList<string>,
      valueSrcs?: ValueSource,
      valueTypes?: string,
      opDef?: Operator,
      operatorOptions?: AnyObject,
      isForDisplay?: boolean
    ) => {
      return isForDisplay ? `${field} не є null` : `!!${field}`;
    }
  },
  select_any_in: {
    ...BasicConfig.operators.select_any_in,
    label: "Будь-який в",
    labelForFormat: "Будь-який в",
    formatOp: (
      field: string,
      op: string,
      values: string | ImmutableList<string>,
      valueSrc: ValueSource
    ) => {
      if (valueSrc === "value")
        return `${field} в (${(values as ImmutableList<string>).join(", ")})`;
      else return `${field} в (${values})`;
    }
  },
  select_not_any_in: {
    ...BasicConfig.operators.select_not_any_in,
    label: "Не в",
    labelForFormat: "Не в",
    formatOp: (
      field: string,
      op: string,
      values: string | ImmutableList<string>,
      valueSrc: ValueSource
    ) => {
      if (valueSrc === "value")
        return `${field} не в (${(values as ImmutableList<string>).join(
          ", "
        )})`;
      else return `${field} не в (${values})`;
    }
  },
  multiselect_equals: {
    ...BasicConfig.operators.multiselect_equals,
    label: "Рівний"
  },
  multiselect_not_equals: {
    ...BasicConfig.operators.multiselect_not_equals,
    label: "Не рівний"
  },
  proximity: {
    ...BasicConfig.operators.proximity,
    label: "Пошук схожих"
  },
  some: {
    // @ts-expect-error TS(2339): Property 'some' does not exist on type '{ equal: B... Remove this comment to see the full error message
    ...BasicConfig.operators.some,
    label: "Деякий",
    labelForFormat: "Деякий"
  },
  all: {
    // @ts-expect-error TS(2339): Property 'all' does not exist on type '{ equal: Bi... Remove this comment to see the full error message
    ...BasicConfig.operators.all,
    label: "Всі",
    labelForFormat: "Всі"
  },
  none: {
    // @ts-expect-error TS(2339): Property 'none' does not exist on type '{ equal: B... Remove this comment to see the full error message
    ...BasicConfig.operators.none,
    label: "Жодного",
    labelForFormat: "Жодного"
  }
};

const funcs = {
  ...BasicFuncs,
  NOW: {
    ...BasicFuncs.NOW,
    label: "Зараз"
  },
  RELATIVE_DATETIME: {
    ...BasicFuncs.RELATIVE_DATETIME,
    label: "Відносний",
    formatFunc: ({ date, op, val, dim }: $TSFixMe) =>
      !val
        ? date
        : `${date} ${op === "minus" ? "-" : "+"} ${val} ${getDimLocalization(
            dim,
            val
          )}`,
    args: {
      // @ts-expect-error TS(2339): Property 'args' does not exist on type 'Func | Fun... Remove this comment to see the full error message
      ...BasicFuncs.RELATIVE_DATETIME.args,
      dim: {
        // @ts-expect-error TS(2339): Property 'args' does not exist on type 'Func | Fun... Remove this comment to see the full error message
        ...BasicFuncs.RELATIVE_DATETIME.args.dim,
        fieldSettings: {
          listValues: {
            day: "день",
            week: "тиждень",
            month: "місяць",
            year: "рік"
          }
        }
      }
    }
  },
  LOWER: {
    ...BasicFuncs.LOWER,
    label: "Нижній регістр",
    args: {
      str: {
        // @ts-expect-error TS(2339): Property 'args' does not exist on type 'Func | Fun... Remove this comment to see the full error message
        ...BasicFuncs.LOWER.args.str,
        label: "Строка"
      }
    }
  },
  UPPER: {
    ...BasicFuncs.LOWER,
    label: "Верхній регістр",
    args: {
      str: {
        // @ts-expect-error TS(2339): Property 'args' does not exist on type 'Func | Fun... Remove this comment to see the full error message
        ...BasicFuncs.UPPER.args.str,
        label: "Строка"
      }
    }
  }
};

export default {
  ...BasicConfig,
  types,
  widgets,
  settings,
  conjunctions,
  operators,
  funcs
};
