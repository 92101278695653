import React, { useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { t, Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { differenceInCalendarDays } from "date-fns";
import createDecorator from "final-form-calculate";
import { Heading, Flex, Box } from "@rebass/emotion";
import get from "lodash/get";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import {
  Form,
  Validations,
  Validation,
  Field as FieldListener
} from "@edenlabllc/ehealth-components";
import {
  CreateProgramMedicationInput,
  Dictionary
} from "@ehealth/ehealth-ua.schema";

import Button from "../../components/Button";
import DefinitionListView from "../../components/DefinitionListView";
import DictionaryValue from "../../components/DictionaryValue";
import * as Field from "../../components/Field";
import Line from "../../components/Line";
import Price from "../../components/Price";
import Steps from "../../components/Steps";
import * as SearchField from "../../components/SearchField";
import UnpocessableEntityModalError from "../../components/UnpocessableEntityModalError";

import { getErrorCode, getErrorMessage } from "../../helpers/errorHelpers";
import { PERCENT_PATTERN } from "../../constants/validationPatterns";

import MedicationModalForm from "./MedicationModalForm";
import Dosage from "../../components/Dosage";

const Create = ({
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <>
    <Box pt={5} px={5}>
      <Steps.List>
        <Steps.Item to="./" state={state}>
          <Trans>Fill in the form</Trans>
        </Steps.Item>
        <Steps.Item to="./confirm" state={state} disabled>
          <Trans>Confirm</Trans>
        </Steps.Item>
      </Steps.List>
    </Box>
    <Router>
      <CreationForm path="/" />
      <Confirmation path="/confirm" />
      <MedicationModalForm path="/medication" />
    </Router>
  </>
);

const CreationForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { data = {}, medication } = state || {};
  const { type, reimbursementAmount, percentageDiscount } =
    data.reimbursement || {};
  return (
    <Box p={5} pb={300}>
      <Heading as="h1" fontWeight="normal" mb={5}>
        <Trans>Create program medication</Trans>
      </Heading>
      <Form
        onSubmit={(data: CreateProgramMedicationInput) => {
          navigate!("./confirm", {
            state: {
              data
            }
          });
        }}
        initialValues={Object.assign(
          data,
          medication && { medication },
          data.reimbursement && {
            reimbursement: {
              type: type || "FIXED",
              reimbursementAmount,
              percentageDiscount
            }
          }
        )}
        decorators={[resetReimbursementAmount]}
      >
        <Flex>
          <Box pr={2} width={2 / 5} onClick={() => navigate!("medication")}>
            <SearchField.Medication
              name="medication"
              additionalFilters={{ isActive: true }}
              filteredParams={["id", "name"]}
              fieldParams={{
                disabled: true,
                iconComponent: () => <SearchIcon color="silverCity" />,
                style: { backgroundColor: "#fff" }
              }}
            />
            <Validation.Required field="medication" message="Required field" />
          </Box>
          <Box width={2 / 5}>
            <SearchField.MedicalProgram
              name="medicalProgram"
              filteredParams={["name", "id"]}
              additionalFilters={{ isActive: true, type: "MEDICATION" }}
            />
            <Validation.Required
              field="medicalProgram"
              message="Required field"
            />
          </Box>
        </Flex>
        <Flex alignItems="flex-end">
          <Box pr={2} width={2 / 5}>
            <Form.Spy>
              {({ values }: $TSFixMe) => {
                const isPercentage =
                  get(values, "reimbursement.type") === "PERCENTAGE";

                return (
                  <>
                    <Field.Number
                      name="reimbursement.reimbursementAmount"
                      label={
                        <Trans>
                          Reimbursement amount for the package of the medicinal
                          product
                        </Trans>
                      }
                      placeholder="0 - 1 000 000"
                      postfix={<Trans id="uah" />}
                      disabled={isPercentage}
                    />
                    <Validations field="reimbursement.reimbursementAmount">
                      {!isPercentage ? (
                        <Validation.Required message="Required field" />
                      ) : (
                        <></>
                      )}
                      <Validation.PositiveFloat
                        message={i18n._(t`Must be greater than zero`)}
                      />
                    </Validations>
                  </>
                );
              }}
            </Form.Spy>
          </Box>
          <Box width={2 / 5}>
            <DictionaryValue name="REIMBURSEMENT_TYPE">
              {(reimbursementTypes: Dictionary["values"]) => (
                <>
                  <Field.Select
                    name="reimbursement.type"
                    label={<Trans id="Reimbursement type" />}
                    items={Object.keys(reimbursementTypes)}
                    itemToString={(item: string) => reimbursementTypes[item]}
                    variant="select"
                  />
                  <Validation.Required
                    field="reimbursement.type"
                    message="Required field"
                  />
                </>
              )}
            </DictionaryValue>
          </Box>
        </Flex>
        <Flex>
          <Box pr={2} width={2 / 5}>
            <Field.Number
              name="reimbursement.percentageDiscount"
              label={<Trans id="% of discount" />}
              placeholder="0 - 100"
            />
            <Validations field="reimbursement.percentageDiscount">
              <Validation.Required message="Required field" />
              <Validation.Matches
                field="reimbursement.percentageDiscount"
                options={PERCENT_PATTERN}
                message={i18n._(t`Invalid percentage discount`)}
              />
            </Validations>
          </Box>
        </Flex>
        <Box mt={-1} width={4 / 5}>
          <Line />
        </Box>
        <Flex>
          <Box pr={2} width={2 / 5}>
            <Field.Number
              name="wholesalePrice"
              label={<Trans id="Wholesale price" />}
              placeholder="0 - 1 000 000"
              postfix={<Trans id="uah" />}
            />
            <Validation.PositiveFloat
              field="wholesalePrice"
              message={i18n._(t`Must be greater than zero`)}
            />
          </Box>
          <Box width={2 / 5}>
            <Field.Number
              name="consumerPrice"
              label={<Trans id="Consumer price" />}
              placeholder="0 - 1 000 000"
              postfix={<Trans id="uah" />}
            />
            <Validation.PositiveFloat
              field="consumerPrice"
              message={i18n._(t`Must be greater than zero`)}
            />
          </Box>
        </Flex>
        <Flex alignItems="flex-end">
          <Box pr={2} width={2 / 5}>
            <Field.Number
              name="estimatedPaymentAmount"
              label={<Trans id="Estimated payment amount" />}
              placeholder="0 - 1 000 000"
              postfix={<Trans id="uah" />}
            />
            <Trans
              id="Must be zero or positive number"
              render={({ translation }) => (
                <Validation.ZeroOrPositive
                  field="estimatedPaymentAmount"
                  message={translation}
                />
              )}
            />
          </Box>
          <Box width={2 / 5}>
            <Field.Number
              name="reimbursementDailyDosage"
              label={<Trans id="Reimbursement daily dosage" />}
              placeholder="0 - 1 000 000"
              postfix={<Trans id="uah" />}
            />
            <Trans
              id="Must be zero or positive number"
              render={({ translation }) => (
                <Validation.ZeroOrPositive
                  field="reimbursementDailyDosage"
                  message={translation}
                />
              )}
            />
          </Box>
        </Flex>
        <Flex>
          <Box pr={2} width={2 / 5}>
            <Field.Number
              name="maxDailyDosage"
              label={<Trans id="Max daily dosage" />}
              placeholder="0 - 1 000"
            />
            <Trans
              id="Must be greater than zero"
              render={({ translation }) => (
                <Validation.PositiveFloat
                  field="maxDailyDosage"
                  message={translation}
                />
              )}
            />
          </Box>
        </Flex>
        <Box mt={-1} width={4 / 5}>
          <Line />
        </Box>
        <Flex mx={-1}>
          <Box px={1} width={2 / 5}>
            <Trans
              id="Enter registry number"
              render={({ translation }) => (
                <Field.Text
                  name="registryNumber"
                  label={<Trans id="Registry number" />}
                  placeholder={translation}
                  postfix={<SearchIcon color="silverCity" />}
                  autoComplete="off"
                />
              )}
            />
          </Box>
          <Box px={1} width={1 / 5}>
            <Field.DatePicker
              name="date.startDate"
              label={<Trans id="Start date" />}
              minDate="1900-01-01"
            />
            <FieldListener name="date" subscription={{ value: true }}>
              {({
                input: {
                  value: { startDate, endDate } = {
                    startDate: undefined,
                    endDate: undefined
                  }
                }
              }: $TSFixMe) =>
                startDate && endDate ? (
                  <Validation.Custom
                    field="date.startDate"
                    options={() => {
                      const differenceInDays = differenceInCalendarDays(
                        new Date(endDate),
                        new Date(startDate)
                      );
                      return differenceInDays >= 0;
                    }}
                    message="Start date must be earlier than end date"
                  />
                ) : null
              }
            </FieldListener>
          </Box>
          <Box px={1} width={1 / 5}>
            <Field.DatePicker
              name="date.endDate"
              label={<Trans id="End date" />}
              minDate="1900-01-01"
            />
          </Box>
        </Flex>
        <Flex>
          <Box mr={3}>
            <Button
              type="reset"
              variant="blue"
              width={140}
              onClick={() => navigate!("../search")}
            >
              <Trans>Back</Trans>
            </Button>
          </Box>
          <Box>
            <Button variant="green" width={140}>
              <Trans>Add</Trans>
            </Button>
          </Box>
        </Flex>
      </Form>
    </Box>
  );
};

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const [error, setError] = useState(null);
  if (!state) return null;
  const {
    data,
    data: {
      medication,
      medicalProgram,
      wholesalePrice,
      consumerPrice,
      estimatedPaymentAmount,
      reimbursementDailyDosage,
      registryNumber,
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      date: { startDate, endDate } = {},
      reimbursement: { type, reimbursementAmount, percentageDiscount },
      maxDailyDosage
    }
  } = state;
  const floatMaxDailyDosage = maxDailyDosage && parseFloat(maxDailyDosage);

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          medicationName: <Trans>Medication name</Trans>,
          medicalProgramName: <Trans>Medical program name</Trans>
        }}
        data={{
          medicationName: medication.name,
          medicalProgramName: medicalProgram.name
        }}
        labelWidth="225px"
      />
      <Line />
      <DefinitionListView
        labels={{
          type: <Trans>Reimbursement type</Trans>,
          reimbursementAmount: (
            <Trans>
              Reimbursement amount for the package of the medicinal product
            </Trans>
          ),
          wholesalePrice: <Trans>Wholesale price</Trans>,
          consumerPrice: <Trans>Consumer price</Trans>,
          estimatedPaymentAmount: <Trans>Estimated payment amount</Trans>,
          reimbursementDailyDosage: <Trans>Reimbursement daily dosage</Trans>,
          ...(percentageDiscount && {
            percentageDiscount: <Trans>% of discount</Trans>
          }),
          ...(floatMaxDailyDosage && {
            maxDailyDosage: <Trans>Max daily dosage</Trans>
          })
        }}
        data={{
          type: <DictionaryValue name="REIMBURSEMENT_TYPE" item={type} />,
          reimbursementAmount: <Price amount={reimbursementAmount} />,
          wholesalePrice: <Price amount={wholesalePrice} />,
          consumerPrice: <Price amount={consumerPrice} />,
          estimatedPaymentAmount: <Price amount={estimatedPaymentAmount} />,
          reimbursementDailyDosage: <Price amount={reimbursementDailyDosage} />,
          ...(percentageDiscount && {
            percentageDiscount: `${percentageDiscount} %`
          }),
          maxDailyDosage: <Dosage dosage={floatMaxDailyDosage} />
        }}
        labelWidth="225px"
      />
      <Line />
      <DefinitionListView
        labels={{
          startDate: <Trans>Start date</Trans>,
          endDate: <Trans>End date</Trans>,
          registryNumber: <Trans>Registry number</Trans>
        }}
        data={{
          startDate: startDate ? i18n.date(startDate) : "-",
          endDate: endDate ? i18n.date(endDate) : "-",
          registryNumber: registryNumber ? registryNumber : "-"
        }}
        labelWidth="225px"
      />

      <Flex mt={5}>
        <Box mr={3}>
          <Button
            variant="blue"
            width={140}
            onClick={() => {
              navigate!("../", {
                state: {
                  data
                }
              });
            }}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Mutation mutation={CreateProgramMedicationMutation}>
            {(createProgramMedication: MutationFunction) => (
              <Button
                variant="green"
                width={140}
                onClick={async () => {
                  setError(null);

                  try {
                    const { data } = await createProgramMedication({
                      variables: {
                        input: {
                          medicationId: medication.id,
                          medicalProgramId: medicalProgram.id,
                          wholesalePrice: getPriceVariable(wholesalePrice),
                          consumerPrice: getPriceVariable(consumerPrice),
                          estimatedPaymentAmount: getPriceVariable(
                            estimatedPaymentAmount
                          ),
                          reimbursementDailyDosage: getPriceVariable(
                            reimbursementDailyDosage
                          ),
                          reimbursement: {
                            type,
                            ...(reimbursementAmount && {
                              reimbursementAmount: parseFloat(
                                reimbursementAmount
                              )
                            }),
                            ...(percentageDiscount && {
                              percentageDiscount: parseFloat(percentageDiscount)
                            })
                          },
                          startDate: startDate || null,
                          endDate: endDate || null,
                          registryNumber,
                          ...(floatMaxDailyDosage && {
                            maxDailyDosage: floatMaxDailyDosage
                          })
                        }
                      }
                    });
                    if (data.createProgramMedication) {
                      navigate!("../../search");
                    }
                  } catch (error) {
                    if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                      setError(getErrorMessage(error));
                    }
                  }
                }}
              >
                <Trans>Add</Trans>
              </Button>
            )}
          </Mutation>
          {error && (
            <UnpocessableEntityModalError errorMessage={error} isModalOpen />
          )}
        </Box>
      </Flex>
    </Box>
  );
};

const getPriceVariable = (price: string) =>
  price ? parseFloat(price) : undefined;

const CreateProgramMedicationMutation = gql`
  mutation CreateProgramMedicationMutation(
    $input: CreateProgramMedicationInput!
  ) {
    createProgramMedication(input: $input) {
      programMedication {
        id
      }
    }
  }
`;

export default Create;

const resetReimbursementAmount = createDecorator({
  field: "reimbursement.type",
  updates: {
    "reimbursement.reimbursementAmount": (
      value: $TSFixMe,
      allValues: $TSFixMe
    ) => {
      const { reimbursement } = allValues || {};
      return value === "PERCENTAGE"
        ? undefined
        : reimbursement.reimbursementAmount;
    }
  }
});
