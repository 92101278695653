import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, isEmpty } from "lodash";

import {
  MedicationFilter,
  Medication,
  MedicationConnection
} from "@ehealth/ehealth-ua.schema";

import * as Field from "../Field";
import onlyValidKeys from "../../helpers/onlyValidKeys";

type FieldParamsProps = {
  style?: { [key: string]: string };
  disabled?: boolean;
  iconComponent?: () => React.ReactElement;
};

type SearchMedicationField = {
  name: string;
  additionalFilters?: MedicationFilter;
  filteredParams: string[];
  fieldParams?: FieldParamsProps;
};

const SearchMedicationField = ({
  name,
  additionalFilters,
  filteredParams,
  fieldParams
}: SearchMedicationField) => (
  <Trans
    id="Choose medication name"
    render={({ translation }) => (
      <Query
        query={MedicationsQuery}
        fetchPolicy="cache-first"
        variables={{
          skip: true
        }}
      >
        {({
          data,
          refetch: refetchMedications
        }: QueryResult<{ medications: MedicationConnection }>) => {
          const { medications: { nodes: medications = [] } = {} } = data || {};
          const itemToString = (item: Medication) => item && item.name;
          const filteredItems = medications!.map((item) =>
            onlyValidKeys(item, filteredParams)
          );
          return (
            <Field.Select
              {...fieldParams}
              name={name}
              label={<Trans id="Medication name" />}
              placeholder={translation}
              items={filteredItems}
              itemToString={itemToString}
              filter={(items: Medication[]) => items}
              onInputValueChange={debounce(
                (name, { selectedItem, inputValue }) =>
                  !isEmpty(name) &&
                  itemToString(selectedItem) !== inputValue &&
                  refetchMedications({
                    skip: false,
                    first: 20,
                    filter: {
                      ...additionalFilters,
                      name
                    }
                  }),
                300
              )}
            />
          );
        }}
      </Query>
    )}
  />
);

const MedicationsQuery = gql`
  query MedicationsQuery(
    $first: Int
    $filter: MedicationFilter
    $skip: Boolean! = false
  ) {
    medications(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        databaseId
        name
      }
    }
  }
`;

export default SearchMedicationField;
