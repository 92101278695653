import * as React from "react";
import styled from "@emotion/styled";

import { Switch } from "@edenlabllc/ehealth-components";
import { CaretDownIcon, CaretUpIcon } from "@edenlabllc/ehealth-icons";
import {
  filterTableColumn as filterTableDefaultColumn,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";

import { SortingParams } from "./";

type HeaderData = { [key: string]: any };

type HeaderDataWithStatus = {
  name: string;
  status: boolean;
  title: string;
};

type TableHeaderType = {
  header: HeaderData;
  columnKeyExtractor: (name: string, index: number) => string;
  filterTableColumn: (
    filterRow: Array<HeaderDataWithStatus | any>,
    headerName: string
  ) => boolean;
  headerComponent: React.ComponentType<{
    children: React.ReactElement;
  }>;
  rowComponent: React.ComponentType<{
    children: React.ReactElement;
  }>;
  headerCellComponent: React.ComponentType<{
    children: React.ReactElement;
    cellName: string;
    onClick?: () => void;
  }>;
  sortableFields?: string[];
  sortingParams?: SortingParams;
  onSortingChange?: (value?: SortingParams) => void;
  filterRow: Array<HeaderDataWithStatus | any>;
  switchSorting: (
    name: string,
    sortingParams: SortingParams,
    onSortingChange: (value?: SortingParams) => void
  ) => void;
};

const TableHeader = ({
  header,
  columnKeyExtractor,
  filterTableColumn = filterTableDefaultColumn,
  headerComponent: HeaderComponent,
  rowComponent: RowComponent,
  headerCellComponent: HeaderCellComponent,
  sortableFields = [],
  sortingParams = {},
  onSortingChange = () => {},
  filterRow,
  switchSorting = switchSortingParams
}: TableHeaderType) => (
  <HeaderComponent>
    <RowComponent>
      <>
        {Object.entries(header)
          .filter(([headerName]) => filterTableColumn(filterRow, headerName))
          .map(([name, content], index) => {
            const isSortable = sortableFields.includes(name);
            return (
              <HeaderCellComponent
                cellName={name}
                key={columnKeyExtractor(name, index)}
                onClick={
                  isSortable
                    ? () => switchSorting(name, sortingParams, onSortingChange)
                    : undefined
                }
              >
                <ContentBlock
                  content={content}
                  prefix={isSortable}
                  icon={
                    <Switch
                      value={sortingParams.name === name && sortingParams.order}
                      ASC={<CaretUpIcon />}
                      DESC={<CaretDownIcon />}
                      default={
                        <>
                          <CaretUpIcon />
                          <CaretDownIcon />
                        </>
                      }
                    />
                  }
                />
              </HeaderCellComponent>
            );
          })}
      </>
    </RowComponent>
  </HeaderComponent>
);

const switchSortingParams = (
  name: string,
  order: SortingParams,
  onSortingChange: (value?: SortingParams) => void
) => {
  const sortParams = stringifySortingParams(order);
  switch (sortParams) {
    case stringifySortingParams({ name, order: "ASC" }):
      onSortingChange({
        name,
        order: "DESC"
      });
      break;
    case stringifySortingParams({ name, order: "DESC" }):
      onSortingChange();
      break;
    default:
      onSortingChange({
        name,
        order: "ASC"
      });
  }
};

type ContentBlockProps = {
  content: string;
  icon: React.ReactNode;
  prefix: boolean;
};

const ContentBlock = ({ content, icon, prefix }: ContentBlockProps) => (
  <Content>
    {content}
    {prefix && <Icon key="icon">{icon}</Icon>}
  </Content>
);

const Content = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  text-align: left;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 16px;
`;

export default TableHeader;
