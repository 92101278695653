import system from "@edenlabllc/ehealth-system-components";

const Line = system(
  {
    is: "figure",
    bg: "januaryDawn",
    my: 5,
    mx: 0
  },
  {
    height: "1px",
    width: "100%"
  },
  "space",
  "color"
);

export default Line;
