import React from "react";
import { Router, RouteComponentProps } from "@reach/router";

import Search from "./Search";
import ConclusionsSearch from "./ConclusionsSearch";

// details
import Episode from "../PracticalMonitoring/Details/Episode";
import Observation from "../PracticalMonitoring/Details/Observation";
import AllergyIntolerance from "../PracticalMonitoring/Details/AllergyIntolerance";
import Encounter from "../PracticalMonitoring/Details/Encounter";
import Condition from "../PracticalMonitoring/Details/Condition";
import Immunization from "../PracticalMonitoring/Details/Immunization";
import Procedure from "../PracticalMonitoring/Details/Procedure";
import DiagnosticReport from "../PracticalMonitoring/Details/DiagnosticReport";
import CarePlan from "../PracticalMonitoring/Details/CarePlan";
import ServiceRequest from "../PracticalMonitoring/Details/ServiceRequest";
import MedicationDispense from "../PracticalMonitoring/Details/MedicationDispense";
import { Activity } from "../PracticalMonitoring/Details/Activity/Activity";
import MedicationRequest from "../PracticalMonitoring/Details/MedicationRequest";

// graphql
import { ImpersonalEpisodeByIDQuery } from "./graphql/Episodes";
import { ImpersonalObservationByIDQuery } from "./graphql/Observations";
import { ImpersonalAllergyIntoleranceByIDQuery } from "./graphql/AllergyIntolerance";
import { ImpersonalEncounterByIDQuery } from "./graphql/Encounters";
import { ImpersonalConditionByIDQuery } from "./graphql/Conditions";
import { ImpersonalImmunizationByIDQuery } from "./graphql/Immunizations";
import { ImpersonalProcedureByIDQuery } from "./graphql/Procedures";
import { ImpersonalDiagnosticReportByIDQuery } from "./graphql/DiagnosticReports";
import { ImpersonalCarePlanByIDQuery } from "./graphql/CarePlans";
import { ImpersonalServiceRequestByIDQuery } from "./graphql/ServiceRequests";
import { ImpersonalMedicationDispenseByIDQuery } from "./graphql/MedicationDispenses";
import { ImpersonalActivityByIDQuery } from "./graphql/Activities";
import { ImpersonalMedicationRequestByIDQuery } from "./graphql/MedicationRequests";

const ClinicalMonitoring = (_props: RouteComponentProps) => (
  <Router>
    <Episode
      path="/episode/:patientId/:itemId/*"
      query={ImpersonalEpisodeByIDQuery}
      queryVariables={{ skipAdditionalFields: false }}
      isClinicalMonitoring
    />
    <Observation
      path="/observation/:patientId/:itemId/*"
      query={ImpersonalObservationByIDQuery}
      queryVariables={{ skipAdditionalFields: false }}
      isClinicalMonitoring
    />
    <AllergyIntolerance
      path="/allergy-intolerance/:patientId/:itemId/*"
      query={ImpersonalAllergyIntoleranceByIDQuery}
      queryVariables={{ skipAdditionalFields: false }}
      isClinicalMonitoring
    />
    <Encounter
      path="/encounter/:patientId/:itemId/*"
      query={ImpersonalEncounterByIDQuery}
      queryVariables={{ skipAdditionalFields: false }}
      isClinicalMonitoring
    />
    <Condition
      path="/condition/:patientId/:itemId/*"
      query={ImpersonalConditionByIDQuery}
      queryVariables={{ skipAdditionalFields: false }}
      isClinicalMonitoring
    />
    <Immunization
      path="/immunization/:patientId/:itemId/*"
      query={ImpersonalImmunizationByIDQuery}
      queryVariables={{ skipAdditionalFields: false }}
      isClinicalMonitoring
    />
    <Procedure
      path="/procedure/:patientId/:itemId/*"
      query={ImpersonalProcedureByIDQuery}
      queryVariables={{ skipAdditionalFields: false }}
      isClinicalMonitoring
    />
    <DiagnosticReport
      path="/diagnostic-report/:patientId/:itemId/*"
      query={ImpersonalDiagnosticReportByIDQuery}
      queryVariables={{ skipAdditionalFields: false }}
      isClinicalMonitoring
    />
    <Activity
      path="/care-plan/:patientId/:itemId/activity/:activityId/*"
      query={ImpersonalActivityByIDQuery}
      isClinicalMonitoring
    />
    <CarePlan
      path="/care-plan/:patientId/:itemId/*"
      query={ImpersonalCarePlanByIDQuery}
      queryVariables={{ skipAdditionalFields: false }}
      isClinicalMonitoring
    />
    <ServiceRequest
      path="/service-request/:patientId/:itemId/*"
      query={ImpersonalServiceRequestByIDQuery}
      queryVariables={{ skipAdditionalFields: false }}
      isClinicalMonitoring
    />
    <MedicationDispense
      path="/medication-dispense/:patientId/:itemId/*"
      query={ImpersonalMedicationDispenseByIDQuery}
      isClinicalMonitoring
    />
    <MedicationRequest
      path="/medication-request/:patientId/:itemId/*"
      query={ImpersonalMedicationRequestByIDQuery}
      isClinicalMonitoring
    />
    <ConclusionsSearch path="/conclusions" />

    <Search path="/*" />
  </Router>
);

export default ClinicalMonitoring;
