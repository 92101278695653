import format from "date-fns/format";

export const getDayBefore = (date: string) => {
  if (!date) return null;
  if (!isValidDate(date)) return null;
  const day = new Date(date);
  return new Date(day.setDate(day.getDate() - 1)).toISOString();
};

export const isValidDate = (date: string) =>
  // @ts-expect-error
  new Date(date) !== "Invalid Date" && !isNaN(new Date(date));

export const getFormatDateBeforeNow = (before: number) => {
  const date = new Date();
  return format(date.setDate(date.getDate() - before), "YYYY-MM-DD");
};

export const dateDaysDiff = (
  start: string,
  end: string = new Date().toISOString()
) => {
  if (!isValidDate(start)) return null;
  const dateStart = new Date(start);
  const dateEnd = new Date(end);
  // @ts-ignore
  const diffTime = Math.abs(dateEnd - dateStart);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};
