import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, isEmpty } from "lodash";

import {
  ServiceGroup,
  ServiceGroupConnection
} from "@ehealth/ehealth-ua.schema";

import * as Field from "../Field";

type SearchServiceGroupFieldProps = {
  name: string;
};

const SearchServiceGroupField = ({ name }: SearchServiceGroupFieldProps) => (
  <Trans
    id="Choose service group"
    render={({ translation }) => (
      <Query
        query={GetServiceGroupsQuery}
        fetchPolicy="cache-first"
        variables={{
          skip: true
        }}
      >
        {({
          data,
          refetch: refetchServiceGroups
        }: QueryResult<{ serviceGroups: ServiceGroupConnection }>) => {
          const { serviceGroups: { nodes: serviceGroups = [] } = {} } =
            data || {};

          return (
            <Field.Select
              name={name}
              label={<Trans id="Service group name" />}
              placeholder={translation}
              items={serviceGroups!.map((serviceGroup) => {
                const { id, databaseId, name } = serviceGroup || {};
                return {
                  id,
                  databaseId,
                  name
                };
              })}
              itemToString={(item: {
                id: string;
                databaseId: string;
                name: string;
              }) => item && item.name}
              filter={(serviceGroups: ServiceGroup[]) => serviceGroups}
              onInputValueChange={debounce(
                (name, { selectedItem, inputValue }) =>
                  !isEmpty(name) &&
                  (selectedItem && selectedItem.name) !== inputValue &&
                  refetchServiceGroups({
                    skip: false,
                    first: 20,
                    filter: {
                      name,
                      isActive: true
                    }
                  }),
                300
              )}
            />
          );
        }}
      </Query>
    )}
  />
);

const GetServiceGroupsQuery = gql`
  query GetServiceGroupsQuery(
    $first: Int
    $filter: ServiceGroupFilter
    $skip: Boolean! = false
  ) {
    serviceGroups(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        databaseId
        name
      }
    }
  }
`;

export default SearchServiceGroupField;
