import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Query, Mutation } from "@apollo/client/react/components";
import { loader } from "graphql.macro";
import { MutationFunction, QueryResult } from "@apollo/client";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { Flex, Box, Text, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  MergeRequest,
  MergeRequestConnection
} from "@ehealth/ehealth-ua.schema";

import Badge from "../../components/Badge";
import Button from "../../components/Button";
import Link from "../../components/Link";
import LoadingOverlay from "../../components/LoadingOverlay";
import Pagination from "../../components/Pagination";
import Table, { SortingParams } from "../../components/Table";
import { TLocationParams } from "../../components/SearchForm";

import pagination from "../../helpers/pagination";
import dateFormatter from "../../helpers/dateFormatter";

import { AssignMergeCandidateMutation } from "../CandidatesPatientMergeReuests/Search";

const PatientMergeRequestsQuery = loader(
  "../../graphql/PatientMergeRequestsQuery.graphql"
);

const Search = (_props: RouteComponentProps) => (
  <Box p={6}>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const {
          first,
          last,
          after,
          before,
          orderBy = "STATUS_ASC"
        } = locationParams;

        return (
          <Query
            query={PatientMergeRequestsQuery}
            fetchPolicy="network-only"
            variables={pagination({ first, last, after, before, orderBy })}
          >
            {({
              loading,
              data
            }: QueryResult<{ mergeRequests: MergeRequestConnection }>) => {
              if (isEmpty(data) || isEmpty(data.mergeRequests)) return null;
              const {
                mergeRequests: {
                  canAssignNew,
                  nodes: mergeRequests = [],
                  pageInfo
                }
              } = data;

              return (
                <LoadingOverlay loading={loading}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mb={5}
                  >
                    <Box>
                      <Heading as="h1" fontWeight="normal" mb={4}>
                        <Trans>Applications patients merge</Trans>
                      </Heading>
                      <Text fontSize={1}>
                        <Trans>
                          Choose a pending request or get the new one to get
                          started
                        </Trans>
                      </Text>
                    </Box>
                    <Mutation
                      mutation={AssignMergeCandidateMutation}
                      refetchQueries={() => [
                        {
                          query: PatientMergeRequestsQuery,
                          fetchPolicy: "network-only",
                          variables: {
                            ...pagination({
                              first,
                              last,
                              after,
                              before,
                              orderBy
                            })
                          }
                        }
                      ]}
                    >
                      {(assignMergeCandidate: MutationFunction) => (
                        <Button
                          onClick={async () =>
                            await assignMergeCandidate({
                              variables: {
                                input: {
                                  manualMergeCandidateId: null
                                }
                              }
                            })
                          }
                          variant="blue"
                          disabled={!canAssignNew}
                        >
                          <Trans>Get the new Request</Trans>
                        </Button>
                      )}
                    </Mutation>
                  </Flex>
                  {mergeRequests!.length > 0 && (
                    <>
                      <Table
                        data={
                          orderBy
                            ? mergeRequests
                            : sortMergeRequests(mergeRequests)
                        }
                        header={{
                          databaseId: <Trans>Merge Request ID</Trans>,
                          status: <Trans>Status</Trans>,
                          insertedAt: <Trans>Added</Trans>,
                          details: <Trans>Details</Trans>
                        }}
                        renderRow={({
                          id,
                          databaseId,
                          status,
                          insertedAt
                        }: MergeRequest) => ({
                          databaseId,
                          insertedAt: dateFormatter(
                            i18n.locale,
                            {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric"
                            },
                            insertedAt
                          ),
                          status: (
                            <Badge
                              type="PATIENT_MERGE_REQUEST"
                              name={status}
                              display="block"
                            />
                          ),
                          details: (
                            <Link to={`../${id}`} fontWeight="bold">
                              <Trans>Show details</Trans>
                            </Link>
                          )
                        })}
                        sortableFields={["status", "insertedAt"]}
                        sortingParams={parseSortingParams(
                          locationParams.orderBy
                        )}
                        onSortingChange={(sortingParams: SortingParams) =>
                          setLocationParams({
                            ...locationParams,
                            orderBy: stringifySortingParams(sortingParams)
                          })
                        }
                        tableName="PatientMergeRequests/search"
                        whiteSpaceNoWrap={["databaseId"]}
                      />
                      <Pagination {...pageInfo} />
                    </>
                  )}
                </LoadingOverlay>
              );
            }}
          </Query>
        );
      }}
    </LocationParams>
  </Box>
);

export default Search;

const sortMergeRequests = (mergeRequests: MergeRequestConnection["nodes"]) =>
  mergeRequests!.sort((request) =>
    request && request.status === "NEW" ? -1 : 1
  );
