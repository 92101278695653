import React from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import printIframe from "print-iframe";
import { loader } from "graphql.macro";
import { Trans } from "@lingui/macro";
import { Box, Flex, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Switch } from "@edenlabllc/ehealth-components";
import system from "@edenlabllc/ehealth-system-components";
import { PrinterIcon } from "@edenlabllc/ehealth-icons";
import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import { CapitationContractRequest, Maybe } from "@ehealth/ehealth-ua.schema";

import Button from "../../../../../components/Button";
import InfoBox from "../../../../../components/InfoBox";
import LoadingOverlay from "../../../../../components/LoadingOverlay";

import { CapitationContractRequestQuery } from "../";

import env from "../../../../../env";

const SignContractRequestMutation = loader(
  "../../../../../graphql/SignContractRequestMutation.graphql"
);

type PrintOutContentProps = RouteComponentProps<{
  id: string;
}>;

const PrintOutContent = ({ id, navigate }: PrintOutContentProps) => {
  return (
    <Query
      query={CapitationContractRequestQuery}
      variables={{
        id
      }}
    >
      {({
        loading,
        error,
        data
      }: QueryResult<{
        capitationContractRequest: CapitationContractRequest;
      }>) => {
        if (error) return <>Error! ${error.message}</>;
        if (isEmpty(data) || isEmpty(data.capitationContractRequest))
          return null;
        const {
          capitationContractRequest: {
            printoutContent: content,
            status,
            toSignContent,
            contractorLegalEntity
          }
        } = data;
        const isSignDisabled =
          contractorLegalEntity && contractorLegalEntity.status !== "ACTIVE";

        return (
          <LoadingOverlay loading={loading}>
            <Portal content={content} />
            <FixedWrapper>
              <Flex
                width="720"
                justifyContent="space-between"
                alignItems="center"
                mx="auto"
              >
                <Flex>
                  <Link to="../">
                    <Button mr="2" variant="blue">
                      <Trans>Back</Trans>
                    </Button>
                  </Link>
                  <Switch
                    value={status}
                    PENDING_NHS_SIGN={
                      <Signer.Parent
                        url={env.REACT_APP_STAMP_URL}
                        features={{
                          width: 640,
                          height: 670
                        }}
                      >
                        {/* @ts-expect-error signData */}
                        {({ signData }) => (
                          <Mutation
                            mutation={SignContractRequestMutation}
                            refetchQueries={() => [
                              {
                                query: CapitationContractRequestQuery,
                                variables: { id }
                              }
                            ]}
                          >
                            {(signContractRequest: MutationFunction) => (
                              <>
                                <Button
                                  variant="green"
                                  disabled={isSignDisabled}
                                  onClick={async () => {
                                    const { signedContent } = await signData(
                                      toSignContent
                                    );
                                    await signContractRequest({
                                      variables: {
                                        input: {
                                          id,
                                          signedContent: {
                                            content: signedContent,
                                            encoding: "BASE64"
                                          }
                                        }
                                      }
                                    });
                                    await navigate!("../");
                                  }}
                                >
                                  <Trans>Signing by EDS and seal</Trans>
                                </Button>
                                {isSignDisabled && (
                                  <InfoBox variant="horizontal">
                                    <Trans>
                                      It is impossible to sign the contract
                                      request, because the legal entity is
                                      inactive.
                                    </Trans>
                                  </InfoBox>
                                )}
                              </>
                            )}
                          </Mutation>
                        )}
                      </Signer.Parent>
                    }
                  />
                </Flex>

                <Button
                  variant="none"
                  outline="none"
                  border="0"
                  onClick={async () => {
                    await printIframe(content);
                  }}
                >
                  <Flex color="shiningKnight">
                    <Text
                      fontWeight="bold"
                      mr={1}
                      fontSize="1"
                      color="rockmanBlue"
                    >
                      <Trans>Print</Trans>
                    </Text>
                    <PrinterIcon color="shiningKnight" />
                  </Flex>
                </Button>
              </Flex>
            </FixedWrapper>
          </LoadingOverlay>
        );
      }}
    </Query>
  );
};

const Wrapper = system(
  {},
  {
    position: "relative",
    overflow: "hidden",
    height: "calc(100vh - 90px)"
  }
);

const FixedWrapper = system(
  {
    extend: Box,
    p: 2,
    bg: "white",
    borderColor: "silverCity"
  },
  {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid",
    boxShadow: "0 0 18px rgba(174, 174, 174, 0.75)"
  },
  "space",
  "color",
  "borderColor"
);

const Frame = system(
  { is: "iframe" },
  {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    border: 0
  }
);

type PortalProps = {
  content?: Maybe<string>;
};

const Portal = ({ content = "" }: PortalProps) => (
  <Wrapper>
    <Frame srcDoc={content} />
  </Wrapper>
);

export default PrintOutContent;
