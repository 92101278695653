import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { MedicationDispense as TMedicationDispense } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../../components/Ability";
import DefinitionListView from "../../../../components/DefinitionListView";
import Line from "../../../../components/Line";
import Link from "../../../../components/Link";
import LoadingOverlay from "../../../../components/LoadingOverlay";

import dateFormatter from "../../../../helpers/dateFormatter";
import paramToBase64 from "../../../../helpers/paramToBase64";
import { MonitoringDetailsPageProps } from "../../../../helpers/types";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT
} from "../../../../constants/dateFormats";

import CreateConclusion from "../../../ClinicalMonitoring/CreateConclusion";
import getConclusionData from "../../../ClinicalMonitoring/getConclusionData";
import Conclusions from "../../../ClinicalMonitoring/Conclusions";
import { MEDICATION_DISPENSES_STATUSES } from "../../Search/MedicationDispensesTab";
import DetailsPageBreadcrumbs from "../DetailsPageBreadcrumbs";
import { MedicationDispenseDetails } from "./MedicationDispenseDetails";
import { LABEL_WIDTH } from "../Episode";
import { Party } from "./Party";

export const MedicationDispense = ({
  query,
  queryVariables = {},
  itemId,
  id,
  patientId,
  isClinicalMonitoring = false
}: MonitoringDetailsPageProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="medication_dispense"
  >
    <Box p={6}>
      <DetailsPageBreadcrumbs
        id={id}
        currentStepName={<Trans>Medication dispense</Trans>}
        isClinicalMonitoring={isClinicalMonitoring}
      />
      <Query
        query={query}
        fetchPolicy="network-only"
        variables={{
          ...queryVariables,
          id: itemId,
          ...(isClinicalMonitoring && { patientId })
        }}
      >
        {({
          loading,
          data = {},
          error
        }: QueryResult<{ [key: string]: TMedicationDispense }>) => {
          const medicationDispense = isClinicalMonitoring
            ? data.impersonalMedicationDispense
            : data.medicationDispense;

          return (
            <LoadingOverlay loading={loading}>
              <Box mt={4}>
                {!isEmpty(medicationDispense) && !error && (
                  <>
                    <HeaderDefinitions
                      medicationDispense={medicationDispense}
                      isClinicalMonitoring={isClinicalMonitoring}
                      patientId={patientId!}
                    />
                    <Box mt={4}>
                      <TopBodyDefinitions
                        medicationDispense={medicationDispense}
                        isClinicalMonitoring={isClinicalMonitoring}
                        patientId={patientId!}
                      />
                      <Party party={medicationDispense.party} />
                      <MiddleBodyDefinitions
                        medicationDispense={medicationDispense}
                      />
                      {!isEmpty(medicationDispense.details) && (
                        <Box mt={4}>
                          <Text
                            fontSize={1}
                            fontWeight="bold"
                            color="darkCharcoal"
                          >
                            <Trans>Medication dispense details</Trans>
                          </Text>
                          <MedicationDispenseDetails
                            details={medicationDispense.details}
                          />
                        </Box>
                      )}
                      <BottomBodyDefinitions
                        medicationDispense={medicationDispense}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </LoadingOverlay>
          );
        }}
      </Query>
      <Conclusions
        isClinicalMonitoring={isClinicalMonitoring}
        patientId={patientId!}
        itemId={itemId!}
      />
    </Box>
  </Ability>
);

export const getMedicationDispenseQueryVariables = (id?: string) => ({
  personId: id
});

type HeaderDefinitionsProps = {
  medicationDispense: TMedicationDispense;
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const HeaderDefinitions = ({
  medicationDispense,
  isClinicalMonitoring,
  patientId
}: HeaderDefinitionsProps) => {
  const { i18n } = useLingui();
  if (isEmpty(medicationDispense)) return null;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <div>
          <DefinitionListView
            labels={{
              databaseId: <Trans>ID</Trans>,
              status: <Trans>Status</Trans>
            }}
            data={{
              ...medicationDispense,
              status: i18n._(
                MEDICATION_DISPENSES_STATUSES.values[
                  medicationDispense.status.toLowerCase()
                ]
              )
            }}
            color="#7F8FA4"
            labelWidth={LABEL_WIDTH}
          />
        </div>
        {isClinicalMonitoring && (
          <CreateConclusion
            initialData={getConclusionData(
              patientId,
              "medication_dispense",
              medicationDispense.databaseId
            )}
          />
        )}
      </Flex>
      <Line />
    </>
  );
};

type TopBodyDefinitionsProps = {
  medicationDispense: TMedicationDispense;
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const TopBodyDefinitions = ({
  medicationDispense,
  isClinicalMonitoring,
  patientId
}: TopBodyDefinitionsProps) => {
  if (isEmpty(medicationDispense)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labelWidth={LABEL_WIDTH}
      labels={{
        medicationRequest: <Trans>Medical request</Trans>,
        legalEntity: <Trans id="Pharmacy">Legal entity</Trans>,
        division: <Trans id="Subdivision">Division</Trans>
      }}
      data={{
        ...medicationDispense,
        medicationRequest: (
          <Box>
            <Link
              to={
                isClinicalMonitoring
                  ? `../../../medication-request/${patientId}/${paramToBase64(
                      "MedicationRequest",
                      medicationDispense.medicationRequestId
                    )}`
                  : `../../medication-request/${paramToBase64(
                      "MedicationRequest",
                      medicationDispense.medicationRequestId
                    )}`
              }
              fontSize={14}
            >
              {medicationDispense.medicationRequestId}
            </Link>
          </Box>
        ),
        legalEntity: (
          <Box>
            <Link
              to={`/legal-entities/${medicationDispense.legalEntity.id}`}
              fontSize={14}
            >
              {medicationDispense.legalEntity.name}
            </Link>
          </Box>
        ),
        division: (
          <Box>
            <Link
              to={`/legal-entities/${medicationDispense.legalEntity.id}/divisions/${medicationDispense.division.id}`}
              fontSize={14}
            >
              {medicationDispense.division.name}
            </Link>
          </Box>
        )
      }}
    />
  );
};

const MiddleBodyDefinitions = ({
  medicationDispense
}: {
  medicationDispense: TMedicationDispense;
}) => {
  const { i18n } = useLingui();
  if (isEmpty(medicationDispense)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labelWidth={LABEL_WIDTH}
      labels={{
        dispensedAt: <Trans>Dispensed at</Trans>,
        dispensedBy: <Trans>Dispensed by</Trans>,
        ...(medicationDispense.medicalProgram && {
          medicalProgram: <Trans>Medical program</Trans>
        })
      }}
      data={{
        dispensedAt: dateFormatter(
          i18n.locale,
          DATE_FORMAT,
          medicationDispense.dispensedAt
        ),
        dispensedBy: medicationDispense.dispensedBy,
        ...(medicationDispense.medicalProgram && {
          medicalProgram: (
            <Box>
              <Link
                to={`/medical-programs/${medicationDispense.medicalProgram.id}/`}
                fontSize={14}
              >
                {medicationDispense.medicalProgram.name}
              </Link>
            </Box>
          )
        })
      }}
    />
  );
};

const BottomBodyDefinitions = ({
  medicationDispense
}: {
  medicationDispense: TMedicationDispense;
}) => {
  const { i18n } = useLingui();
  if (isEmpty(medicationDispense)) return null;

  return (
    <Box mt={4}>
      <DefinitionListView
        fontSize={14}
        labelWidth={LABEL_WIDTH}
        labels={{
          ...(typeof medicationDispense.paymentAmount === "number" && {
            paymentAmount: <Trans>Payment amount</Trans>
          }),
          ...(medicationDispense.paymentId && {
            paymentId: <Trans>Payment ID</Trans>
          }),
          insertedAt: <Trans>Inserted at</Trans>,
          updatedAt: <Trans>Updated at</Trans>
        }}
        data={{
          ...medicationDispense,
          ...(typeof medicationDispense.paymentAmount === "number" && {
            paymentAmount: String(medicationDispense.paymentAmount)
          }),
          ...(medicationDispense.paymentId && {
            paymentId: medicationDispense.paymentId
          }),
          insertedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            medicationDispense.insertedAt
          ),
          updatedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            medicationDispense.updatedAt
          )
        }}
      />
    </Box>
  );
};

MedicationDispense.fragments = {
  entry: gql`
    fragment MedicationDispense on MedicationDispense {
      id
      databaseId
      details {
        ...MedicationDispenseDetail
      }
      dispensedAt
      dispensedBy
      division {
        id
        name
      }
      insertedAt
      isActive
      legalEntity {
        id
        name
      }
      medicalProgram {
        id
        name
      }
      medicationRequestId
      party {
        ...Party
      }
      paymentAmount
      paymentId
      status
      updatedAt
    }
    ${MedicationDispenseDetails.fragments.entry}
    ${Party.fragments.entry}
  `
};

export const MedicationDispenseByIDQuery = gql`
  query MedicationDispenseByIDQuery($id: ID!, $personId: ID!) {
    medicationDispense(id: $id, personId: $personId) {
      ...MedicationDispense
    }
  }
  ${MedicationDispense.fragments.entry}
`;
