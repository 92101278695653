import React, { useState } from "react";
import styled from "@emotion/styled";
import { Trans } from "@lingui/macro";
import { ifProp } from "styled-tools";
import { Box, Text } from "@rebass/emotion";

import system from "@edenlabllc/ehealth-system-components";
import { mixed } from "@edenlabllc/ehealth-system-tools";

import Button from "./Button";
import { MAX_EXPANDABLEE_TEXT_SIZES } from "../constants/maxExpandableTextSizes";

type ExpandableTextProps = {
  text: string;
  maxTextSize?: number;
  showMoreButtonText?: React.ReactElement;
  hideFullTextButtonText?: React.ReactElement;
  fixedAlign?: boolean;
  fullScreenHeight?: boolean;
};

const ExpandableText = ({
  text,
  maxTextSize = MAX_EXPANDABLEE_TEXT_SIZES[149],
  showMoreButtonText = <Trans>Show</Trans>,
  hideFullTextButtonText = <Trans>Hide</Trans>,
  fixedAlign = false,
  fullScreenHeight = false
}: ExpandableTextProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (text.length < maxTextSize) return <Text>{text}</Text>;

  return (
    <Wrapper>
      <Box>
        <Text>
          {text.substring(0, maxTextSize)} <b>...</b>
        </Text>
        <Box mt={1}>
          <Button
            variant="link-thin"
            px={0}
            py={0}
            onClick={() => setIsExpanded(true)}
          >
            <Text fontSize={12} fontWeight="700">
              {showMoreButtonText}
            </Text>
          </Button>
        </Box>
        {isExpanded && (
          <FullText fixedAlign={fixedAlign} fullScreenHeight={fullScreenHeight}>
            {text}
            <Box mt={1}>
              <Button
                variant="link-thin"
                px={0}
                py={0}
                onClick={() => setIsExpanded(false)}
              >
                <Text fontSize={12} fontWeight="700">
                  {hideFullTextButtonText}
                </Text>
              </Button>
            </Box>
          </FullText>
        )}
      </Box>
    </Wrapper>
  );
};

export default ExpandableText;

const Wrapper = styled.div`
  position: relative;
  color: #7f8fa4;
  line-height: 19px;
  overflow-wrap: break-word;
`;

const FullText = system(
  {
    is: "div"
  },
  (props: Partial<ExpandableTextProps>) =>
    mixed({
      position: ifProp("fixedAlign", "fixed", "absolute")(props),
      top: ifProp("fullScreenHeight", "0", "30%")(props),
      left: ifProp("fixedAlign", "15%", "-20px")(props),
      color: "#7f8fa4",
      border: "1px solid #ced0da",
      borderRadius: "2px",
      padding: "20px",
      width: ifProp("fixedAlign", "70vw", "50vw")(props),
      backgroundColor: "white",
      zIndex: 999999999,
      overflow: "auto",
      height: ifProp("fullScreenHeight", "100%", "auto")(props)
    })
);
