import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { Link } from "@edenlabllc/ehealth-components";
import { ProgramMedication } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Dosage from "../../../components/Dosage";
import Line from "../../../components/Line";
import Price from "../../../components/Price";

import paramToBase64 from "../../../helpers/paramToBase64";

import UpdateReimbursementData from "./Mutations/UpdateReimbursementData";

type GeneralInfoProps = RouteComponentProps & {
  isActive: ProgramMedication["isActive"];
  details: Partial<ProgramMedication>;
};

const GeneralInfo = ({
  isActive,
  details: {
    id,
    wholesalePrice,
    consumerPrice,
    estimatedPaymentAmount,
    reimbursementDailyDosage,
    medication,
    medicalProgram,
    reimbursement,
    startDate,
    endDate,
    registryNumber,
    maxDailyDosage
  }
}: GeneralInfoProps) => {
  const {
    id: medicationId,
    databaseId: medicationDatabaseId,
    name: medicationName,
    container
  } = medication || {};
  const { databaseId: medicalProgramId, name: medicalProgramName } =
    medicalProgram || {};
  const { type, reimbursementAmount, percentageDiscount } = reimbursement || {};

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          medicationName: <Trans>Medication name</Trans>
        }}
        data={{
          medicationName
        }}
        labelWidth="280px"
      />
      <DefinitionListView
        labels={{
          medicationId: <Trans>Medication ID</Trans>
        }}
        data={{
          medicationId: (
            <Link to={`/medications/${medicationId}`}>
              {medicationDatabaseId}
            </Link>
          )
        }}
        color="blueberrySoda"
        labelWidth="280px"
      />
      <Line />
      <DefinitionListView
        labels={{
          medicalProgramName: <Trans>Medical program name</Trans>
        }}
        data={{
          medicalProgramName
        }}
        labelWidth="280px"
      />
      <DefinitionListView
        labels={{
          medicalProgramId: <Trans>Medical program ID</Trans>
        }}
        data={{
          medicalProgramId: (
            <Link
              to={`/medical-programs/${paramToBase64(
                "MedicalProgram",
                medicalProgramId
              )}`}
            >
              {medicalProgramId}
            </Link>
          )
        }}
        color="blueberrySoda"
        labelWidth="280px"
      />
      <Line />
      <DefinitionListView
        labels={{
          type: <Trans>Reimbursement type</Trans>,
          reimbursementAmount: (
            <Trans>
              Reimbursement amount for the package of the medicinal product
            </Trans>
          ),
          ...(percentageDiscount && {
            percentageDiscount: <Trans>% of discount</Trans>
          }),
          wholesalePrice: <Trans>Wholesale price</Trans>,
          consumerPrice: <Trans>Consumer price</Trans>,
          estimatedPaymentAmount: <Trans>Estimated payment amount</Trans>,
          reimbursementDailyDosage: <Trans>Reimbursement daily dosage</Trans>,
          startDate: <Trans>Program medication start date</Trans>,
          endDate: <Trans>Program medication end date</Trans>,
          registryNumber: <Trans>Registry number</Trans>,
          ...(maxDailyDosage && {
            maxDailyDosage: <Trans>Max daily dosage</Trans>
          })
        }}
        data={{
          type: <DictionaryValue name="REIMBURSEMENT_TYPE" item={type} />,
          reimbursementAmount: <Price amount={reimbursementAmount} />,
          ...(percentageDiscount && {
            percentageDiscount: `${percentageDiscount} %`
          }),
          wholesalePrice: wholesalePrice && <Price amount={wholesalePrice} />,
          consumerPrice: consumerPrice && <Price amount={consumerPrice} />,
          estimatedPaymentAmount: typeof estimatedPaymentAmount ===
            "number" && <Price amount={estimatedPaymentAmount} />,
          reimbursementDailyDosage: typeof reimbursementDailyDosage ===
            "number" && <Price amount={reimbursementDailyDosage} />,
          startDate: startDate ? i18n.date(startDate) : "-",
          endDate: endDate ? i18n.date(endDate) : "-",
          registryNumber: registryNumber ? registryNumber : "-",
          maxDailyDosage: (
            <Dosage
              dosage={maxDailyDosage}
              denumeratorUnit={container && container.numeratorUnit}
            />
          )
        }}
        labelWidth="280px"
      />
      {isActive && (
        <Box pt={10}>
          <Ability action="write" resource="program_medication">
            <UpdateReimbursementData
              id={id!}
              reimbursement={reimbursement!}
              startDate={startDate}
              endDate={endDate}
              registryNumber={registryNumber}
              consumerPrice={consumerPrice}
              reimbursementDailyDosage={reimbursementDailyDosage}
              wholesalePrice={wholesalePrice}
              estimatedPaymentAmount={estimatedPaymentAmount}
            />
          </Ability>
        </Box>
      )}
    </Box>
  );
};

GeneralInfo.fragments = {
  entry: gql`
    fragment ProgramMedicationGeneralInfo on ProgramMedication {
      wholesalePrice
      consumerPrice
      estimatedPaymentAmount
      reimbursementDailyDosage
      startDate
      endDate
      registryNumber
      maxDailyDosage
      reimbursement {
        type
        reimbursementAmount
        percentageDiscount
      }
      medicalProgram {
        id
        databaseId
        name
        isActive
      }
      medication {
        id
        databaseId
        name
        isActive
        type
        container {
          numeratorUnit
        }
      }
    }
  `
};

export default GeneralInfo;
