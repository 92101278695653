import system from "@edenlabllc/ehealth-system-components";

const DropzoneView = system(
  {
    borderColor: "#eee"
  },
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
    padding: "20px",
    marginBottom: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out"
  },
  `
    &:focus {
      border-color: #2196f3;
    }
  `
);

export default DropzoneView;
