import { gql } from "graphql-tag";
import Pagination from "../../../components/Pagination";
import CodeableConcept from "../../../components/CodeableConcept";
import Observation from "../../PracticalMonitoring/Details/Observation";

export const ImpersonalObservationsQuery = gql`
  query ImpersonalObservationsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImpersonalObservationFilter!
    $orderBy: ObservationOrderBy
  ) {
    impersonalObservations(
      after: $after
      before: $before
      filter: $filter
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      nodes {
        id
        databaseId
        status
        insertedAt
        code {
          ...CodeableConcept
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalEntries
    }
  }
  ${Pagination.fragments.entry}
  ${CodeableConcept.fragments!.entry}
`;

export const ImpersonalObservationByIDQuery = gql`
  query ImpersonalObservationByIDQuery(
    $id: ID!
    $patientId: String!
    $skipAdditionalFields: Boolean!
  ) {
    impersonalObservation(id: $id, patientId: $patientId) {
      ...Observation
    }
  }
  ${Observation.fragments.entry}
`;
