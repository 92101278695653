import React from "react";
import { Router, RouteComponentProps, NavigateFn } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import Composer from "react-composer";
import createDecorator from "final-form-calculate";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Heading, Flex, Box, Text } from "@rebass/emotion";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Form, Validation, Validations } from "@edenlabllc/ehealth-components";
import {
  CreateMedicationInput,
  CreateMedicationIngredientInput,
  Dictionary,
  InnmDosage
} from "@ehealth/ehealth-ua.schema";

import Button from "../../../../components/Button";
import DefinitionListView from "../../../../components/DefinitionListView";
import DictionaryValue from "../../../../components/DictionaryValue";
import * as Field from "../../../../components/Field";
import Line from "../../../../components/Line";
import Steps from "../../../../components/Steps";

import { DRLZ_SKU_ID_PATTERN } from "../../../../constants/validationPatterns";

import InnmModalForm from "./InnmModalForm";

const Create = ({
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <>
    <Box pt={5} px={5}>
      <Steps.List>
        <Steps.Item to="./" state={state}>
          <Trans>General info</Trans>
        </Steps.Item>
        <Steps.Item to="./ingredients" state={state}>
          <Trans>Ingredients</Trans>
        </Steps.Item>
        <Steps.Item to="./registration" state={state}>
          <Trans>Registration</Trans>
        </Steps.Item>
        <Steps.Item to="./confirm" state={state} disabled>
          <Trans>Confirm</Trans>
        </Steps.Item>
      </Steps.List>
    </Box>
    <Router>
      <GeneralForm path="/" />
      <IngredientsForm path="/ingredients/*" />
      <RegistrationForm path="/registration" />
      <Confirmation path="/confirm" />
    </Router>
  </>
);

const GeneralForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { i18n } = useLingui();
  const { createMedicationGeneral } = state || {};

  return (
    <Box p={5}>
      <Heading as="h1" fontWeight="normal" mb={5}>
        <Trans>Create medication</Trans>
      </Heading>
      <Form
        onSubmit={({ maxRequestDosage, ...data }: CreateMedicationInput) => {
          navigate!("./ingredients", {
            state: {
              ...state,
              createMedicationGeneral: {
                ...data,
                ...(maxRequestDosage && {
                  // @ts-ignore
                  maxRequestDosage: parseFloat(maxRequestDosage)
                })
              }
            }
          });
        }}
        initialValues={
          createMedicationGeneral || {
            atcCodes: [""]
          }
        }
      >
        <Flex mx={-1}>
          <Box width={2 / 5} px={1}>
            <Trans
              id="Enter medication name"
              render={({ translation }) => (
                <Field.Text
                  name="name"
                  label={<Trans id="Medication name" />}
                  placeholder={translation}
                />
              )}
            />
            <Validation.Required field="name" message="Required field" />
          </Box>
          <Box width={2 / 5} px={1}>
            <Trans
              id="Enter product ID in DRLZ register"
              render={({ translation }) => (
                <Field.Number
                  name="drlzSkuId"
                  label={<Trans id="Product ID in DRLZ register" />}
                  placeholder={translation}
                />
              )}
            />
            <Validations field="drlzSkuId">
              <Validation.Matches
                options={DRLZ_SKU_ID_PATTERN}
                message="Invalid format"
              />
            </Validations>
          </Box>
        </Flex>
        <Field.Array
          name="atcCodes"
          addText={<Trans>Add ATC Code</Trans>}
          removeText={<Trans>Delete</Trans>}
          fields={AtcCodes}
          firstItemPinned
          vertical
        />
        <Line />
        <Flex mx={-1}>
          <Box px={1} width={1 / 4}>
            <Composer components={[<DictionaryValue name="MEDICATION_FORM" />]}>
              {([dict]: [Dictionary["values"]]) => {
                const translation = i18n._(t`Select option`);
                return (
                  <Field.Select
                    name="form"
                    label={<Trans id="Form" />}
                    placeholder={translation}
                    items={Object.keys(dict)}
                    itemToString={(item: string) => dict[item] || translation}
                    variant="select"
                    emptyOption
                    filterOptions={{ keys: [(item: string) => dict[item]] }}
                  />
                );
              }}
            </Composer>
            <Validation.Required field="form" message="Required field" />
          </Box>
          <Box px={1} width={1 / 4}>
            <Composer components={[<DictionaryValue name="FORM_PHARM_LIST" />]}>
              {([dict]: [Dictionary["values"]]) => {
                const translation = i18n._(t`Select option`);
                return (
                  <Field.Select
                    name="formPharm"
                    label={<Trans id="Pharmaceutical form" />}
                    placeholder={translation}
                    items={Object.keys(dict)}
                    itemToString={(item: string) => dict[item] || translation}
                    variant="select"
                    emptyOption
                    filterOptions={{ keys: [(item: string) => dict[item]] }}
                  />
                );
              }}
            </Composer>
          </Box>
        </Flex>
        <Flex mx={-1}>
          <Box px={1} width={1 / 4}>
            <Field.Number
              name="maxRequestDosage"
              label={
                <Trans id="Maximum quantity for dispense of per one Medication request" />
              }
              placeholder="0 - 999"
            />
            <Validation.PositiveFloat
              field="maxRequestDosage"
              message={i18n._(t`Must be greater than zero`)}
            />
          </Box>
        </Flex>
        <Flex pt={5} mb={100}>
          <Box mr={3}>
            <Button
              type="reset"
              variant="blue"
              width={140}
              onClick={() => navigate!("../search")}
            >
              <Trans>Back</Trans>
            </Button>
          </Box>
          <Box>
            <Button variant="green" width={140}>
              <Trans>Next</Trans>
            </Button>
          </Box>
        </Flex>
      </Form>
    </Box>
  );
};

const IngredientsForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state = {} }
}: RouteComponentProps) => {
  const { i18n } = useLingui();
  const { createMedicationIngredients } = state || {};

  return (
    <Box p={5} pb={100}>
      <Router>
        <InnmModalForm path=":innmId" />
      </Router>
      <Heading as="h1" fontWeight="normal" mb={5}>
        <Trans>Ingredients</Trans>
      </Heading>
      <Form
        onSubmit={(values: CreateMedicationInput["ingredients"]) => {
          navigate!("../registration", {
            state: prepareFormValues(state, values)
          });
        }}
        initialValues={
          createMedicationIngredients || {
            ingredients: [
              {
                isPrimary: true
              }
            ]
          }
        }
        decorators={[unitDecorator]}
      >
        <Field.Array
          name="ingredients"
          addText={<Trans>Add additional ingredient</Trans>}
          removeText={<Trans>Delete</Trans>}
          fields={(props) => (
            <Ingredients {...props} state={state} navigate={navigate!} />
          )}
          headerComponent={IngredientsHeader}
          firstItemPinned
          vertical
          withAddButton={false} // Current business requirement is to set only one INNM_DOSAGE for BRAND. So it was needed to hide 2022-06:
        />
        <Line />
        <Heading as="h1" fontWeight="normal" mb={5}>
          <Trans>Package</Trans>
        </Heading>
        <Text fontSize={2} mb={6}>
          <Trans>Container</Trans>
        </Text>
        <Flex mx={-1}>
          <Box px={1} width={2 / 7}>
            <Composer components={[<DictionaryValue name="MEDICATION_UNIT" />]}>
              {([dict]: [Dictionary["values"]]) => {
                const translation = i18n._(t`Select option`);
                return (
                  <Field.Select
                    name="container.denumeratorUnit"
                    label={<Trans id="Type" />}
                    placeholder={translation}
                    items={Object.keys(dict)}
                    itemToString={(item: string) => dict[item] || translation}
                    variant="select"
                    emptyOption
                    filterOptions={{ keys: [(item: string) => dict[item]] }}
                  />
                );
              }}
            </Composer>
            <Validation.Required
              field="container.denumeratorUnit"
              message="Required field"
            />
          </Box>
          <Box px={1} width={2 / 7}>
            <Field.Number
              name="container.numeratorValue"
              label={<Trans id="Amount" />}
              placeholder="0 - 1 000"
            />
            <Validations field="container.numeratorValue">
              <Validation.Required message="Required field" />
              <Validation.PositiveFloat
                message={i18n._(t`Must be greater than zero`)}
              />
            </Validations>
          </Box>
          <Box px={1} width={2 / 7}>
            <Composer components={[<DictionaryValue name="MEDICATION_UNIT" />]}>
              {([dict]: [Dictionary["values"]]) => {
                const translation = i18n._(t`Select option`);
                return (
                  <Field.Select
                    name="container.numeratorUnit"
                    label={<Trans id="Units" />}
                    placeholder={translation}
                    items={Object.keys(dict)}
                    itemToString={(item: string) => dict[item] || translation}
                    variant="select"
                    emptyOption
                    filterOptions={{ keys: [(item: string) => dict[item]] }}
                  />
                );
              }}
            </Composer>
            <Validation.Required
              field="container.numeratorUnit"
              message="Required field"
            />
          </Box>
        </Flex>
        <Flex mx={-1}>
          <Box px={1} width={2 / 7}>
            <Field.Number
              name="packageQty"
              label={<Trans id="Package quantity" />}
              placeholder="0 - 1 000"
            />
            <Validations field="packageQty">
              <Validation.Required message="Required field" />
              <Validation.PositiveFloat
                message={i18n._(t`Must be greater than zero`)}
              />
            </Validations>
          </Box>
          <Box px={1} width={2 / 7}>
            <Field.Number
              name="packageMinQty"
              label={<Trans id="Package minimum quantity" />}
              placeholder="0 - 1 000"
            />
            <Validations field="packageMinQty">
              <Validation.Required message="Required field" />
              <Validation.PositiveFloat
                message={i18n._(t`Must be greater than zero`)}
              />
            </Validations>
          </Box>
        </Flex>
        <Flex pt={5}>
          <Box mr={3}>
            <Button
              type="reset"
              variant="blue"
              width={140}
              onClick={() => {
                navigate!("../", {
                  state
                });
              }}
            >
              <Trans>Back</Trans>
            </Button>
          </Box>
          <Box>
            <Button variant="green" width={140}>
              <Trans>Next</Trans>
            </Button>
          </Box>
        </Flex>
      </Form>
    </Box>
  );
};

const RegistrationForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { createMedicationRegistration } = state || {};
  return (
    <Box p={5} pb={250}>
      <Heading as="h1" fontWeight="normal" mb={5}>
        <Trans>Manufacturer country</Trans>
      </Heading>
      <Form
        onSubmit={(data: CreateMedicationInput["manufacturer"]) => {
          navigate!("../confirm", {
            state: {
              ...state,
              createMedicationRegistration: data
            }
          });
        }}
        initialValues={createMedicationRegistration}
      >
        <Box width={1 / 3}>
          <Composer components={[<DictionaryValue name="COUNTRY" />]}>
            {([dict]: [Dictionary["values"]]) => {
              const translation = i18n._(t`Select option`);
              return (
                <Field.Select
                  name="manufacturer.country"
                  label={<Trans id="Country" />}
                  placeholder={translation}
                  items={Object.keys(dict)}
                  filterOptions={{ keys: [(item: string) => dict[item]] }}
                  itemToString={(item: string) => dict[item]}
                />
              );
            }}
          </Composer>
          <Validation.Required
            field="manufacturer.country"
            message="Required field"
          />
        </Box>
        <Box width={1 / 3}>
          <Trans
            id="Enter manufacturer"
            render={({ translation }) => (
              <Field.Text
                name="manufacturer.name"
                label={<Trans id="Manufacturer" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Required
            field="manufacturer.name"
            message="Required field"
          />
        </Box>
        <Line />
        <Heading as="h1" fontWeight="normal" mb={5}>
          <Trans>Certificate</Trans>
        </Heading>
        <Box width={1 / 3}>
          <Trans
            id="Enter certificate number"
            render={({ translation }) => (
              <Field.Text
                name="certificate"
                label={<Trans id="Certificate number" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Required field="certificate" message="Required field" />
        </Box>
        <Box width={1 / 3}>
          <Field.DatePicker
            name="certificateExpiredAt"
            label={<Trans id="Certificate expired at" />}
            minDate="1900-01-01"
          />
          <Validation.Required
            field="certificateExpiredAt"
            message="Required field"
          />
        </Box>
        <Flex pt={5}>
          <Box mr={3}>
            <Button
              type="reset"
              variant="blue"
              width={140}
              onClick={() => {
                navigate!("../ingredients", {
                  state
                });
              }}
            >
              <Trans>Back</Trans>
            </Button>
          </Box>
          <Box>
            <Button variant="green" width={140}>
              <Trans>Next</Trans>
            </Button>
          </Box>
        </Flex>
      </Form>
    </Box>
  );
};

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const {
    createMedicationGeneral,
    createMedicationIngredients,
    createMedicationRegistration
  } = state || {};

  if (
    !createMedicationGeneral ||
    !createMedicationIngredients ||
    !createMedicationRegistration
  )
    return null;

  const {
    atcCodes,
    form,
    name,
    formPharm,
    maxRequestDosage,
    drlzSkuId
  } = createMedicationGeneral;
  const {
    ingredients,
    container: {
      numeratorValue,
      numeratorUnit,
      denumeratorValue,
      denumeratorUnit
    },
    packageMinQty,
    packageQty
  } = createMedicationIngredients;
  const {
    manufacturer,
    certificate,
    certificateExpiredAt
  } = createMedicationRegistration;

  return (
    <Box p={5}>
      <Heading as="h1" fontWeight="normal" mb={5}>
        <Trans>Confirmation</Trans>
      </Heading>
      <DefinitionListView
        labels={{
          name: <Trans>Medication name</Trans>,
          form: <Trans>Form</Trans>,
          ...(formPharm && {
            formPharm: <Trans>Pharmaceutical form </Trans>
          }),
          ...(maxRequestDosage && {
            maxRequestDosage: (
              <Trans>
                Maximum quantity for dispense of per one Medication request
              </Trans>
            )
          }),
          drlzSkuId: <Trans>Product ID in DRLZ register</Trans>,
          atcCodes: <Trans>ATC Codes</Trans>
        }}
        data={{
          name,
          form: <DictionaryValue name="MEDICATION_FORM" item={form} />,
          atcCodes: atcCodes.join(", "),
          formPharm: (
            <DictionaryValue name="FORM_PHARM_LIST" item={formPharm} />
          ),
          maxRequestDosage,
          drlzSkuId
        }}
        labelWidth="200px"
      />
      <Line />
      {ingredients.map(
        (
          {
            innmDosage: { name },
            dosage: {
              numeratorValue,
              numeratorUnit,
              denumeratorValue,
              denumeratorUnit
            }
          }: CreateMedicationIngredientInput & { innmDosage: { name: string } },
          index: number
        ) => (
          <Box key={index}>
            <IngredientsHeader index={index} />
            <DefinitionListView
              labels={{
                name: <Trans>INNM dosage name</Trans>,
                dosages: <Trans>Dosage</Trans>
              }}
              data={{
                name,
                dosages: (
                  <>
                    {denumeratorValue}{" "}
                    <DictionaryValue
                      name="MEDICATION_UNIT"
                      item={denumeratorUnit}
                    />{" "}
                    <Trans>includes</Trans> {numeratorValue}{" "}
                    <DictionaryValue
                      name="MEDICATION_UNIT"
                      item={numeratorUnit}
                    />
                  </>
                )
              }}
              labelWidth="200px"
            />
            <Line />
          </Box>
        )
      )}
      <DefinitionListView
        labels={{
          container: <Trans>Package</Trans>,
          packageQty: <Trans>Package quantity</Trans>,
          packageMinQty: <Trans>Package minimum quantity</Trans>
        }}
        data={{
          container: (
            <>
              {denumeratorValue}{" "}
              <DictionaryValue name="MEDICATION_UNIT" item={denumeratorUnit} />{" "}
              <Trans>includes</Trans> {numeratorValue}{" "}
              <DictionaryValue name="MEDICATION_UNIT" item={numeratorUnit} />
            </>
          ),
          packageQty,
          packageMinQty
        }}
        labelWidth="200px"
      />
      <Line />
      <DefinitionListView
        labels={{
          country: <Trans>Country</Trans>,
          manufacturer: <Trans>Manufacturer</Trans>,
          certificate: <Trans>Certificate</Trans>,
          certificateExpiredAt: <Trans>Certificate expired at</Trans>
        }}
        data={{
          country: (
            <DictionaryValue name="COUNTRY" item={manufacturer.country} />
          ),
          manufacturer: manufacturer.name,
          certificate,
          certificateExpiredAt: i18n.date(certificateExpiredAt)
        }}
        labelWidth="200px"
      />
      <Flex mt={5}>
        <Box mr={3}>
          <Button
            variant="blue"
            width={140}
            onClick={() => {
              navigate!("../registration", {
                state
              });
            }}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Mutation mutation={CreateMedicationMutation}>
            {(createMedication: MutationFunction) => (
              <Button
                variant="green"
                width={140}
                onClick={async () => {
                  const completedIngredients = ingredients.map(
                    ({
                      innmDosage: { databaseId: innmDosageId },
                      ...data
                    }: $TSFixMe) => ({
                      ...data,
                      innmDosageId
                    })
                  );
                  await createMedication({
                    variables: {
                      input: {
                        ...createMedicationGeneral,
                        ...createMedicationRegistration,
                        ...createMedicationIngredients,
                        ingredients: completedIngredients
                      }
                    }
                  });
                  await navigate!("../../search");
                }}
              >
                <Trans>Add</Trans>
              </Button>
            )}
          </Mutation>
        </Box>
      </Flex>
    </Box>
  );
};

const AtcCodes = ({ name }: { name: string }) => (
  <Box width={1 / 4}>
    <Field.Text
      name={name}
      label={<Trans id="ATC Code" />}
      placeholder="A01AD01"
    />
    <Validations field={name}>
      <Validation.Required message="Required field" />
      <Validation.Matches
        options="^[abcdghjlmnprsvABCDGHJLMNPRSV]{1}[0-9]{2}[a-zA-Z]{2}[0-9]{2}$"
        message="Invalid code"
      />
    </Validations>
  </Box>
);

type IngredientsProps = {
  name: any;
  navigate: NavigateFn;
  state: any;
};

const Ingredients = ({ name = "", navigate, state }: IngredientsProps) => {
  const { i18n } = useLingui();
  return (
    <Flex mx={-1}>
      <Box px={1} width={2 / 7}>
        <Form.Spy>
          {({ values }: $TSFixMe) => (
            <Box
              onClick={() =>
                navigate(name.match(/\d+/)[0], {
                  state: prepareFormValues(state, values)
                })
              }
            >
              <Field.Select
                name={`${name}.innmDosage`}
                label={<Trans id="INNM dosage name" />}
                placeholder={i18n._(t`Enter INNM dosage name`)}
                itemToString={(item: InnmDosage) => item && item.name}
                iconComponent={() => <SearchIcon color="silverCity" />}
                disabled
                style={{ backgroundColor: "#fff" }}
              />
            </Box>
          )}
        </Form.Spy>
        <Validation.Required
          field={`${name}.innmDosage`}
          message="Required field"
        />
      </Box>
      <Box px={1} ml={2} width={1.5 / 7}>
        <Field.Number
          name={`${name}.dosage.numeratorValue`}
          label={<Trans id="Amount" />}
          placeholder="0 - 1 000"
        />
        <Validations field={`${name}.dosage.numeratorValue`}>
          <Validation.Required message="Required field" />
          <Validation.PositiveFloat
            message={i18n._(t`Must be greater than zero`)}
          />
        </Validations>
      </Box>
      <Box px={1} width={1.5 / 7}>
        <Composer components={[<DictionaryValue name="MEDICATION_UNIT" />]}>
          {([dict]: [Dictionary["values"]]) => {
            const translation = i18n._(t`Select option`);
            return (
              <Field.Select
                name={`${name}.dosage.numeratorUnit`}
                label={<Trans id="Units" />}
                placeholder={translation}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || translation}
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            );
          }}
        </Composer>
        <Validation.Required
          field={`${name}.dosage.numeratorUnit`}
          message="Required field"
        />
      </Box>
      <Box px={1} ml={2} width={1.5 / 7}>
        <Field.Number
          name={`${name}.dosage.denumeratorValue`}
          label={<Trans id="By quantity" />}
          placeholder="0 - 1 000"
        />
        <Validations field={`${name}.dosage.denumeratorValue`}>
          <Validation.Required message="Required field" />
          <Validation.PositiveFloat
            message={i18n._(t`Must be greater than zero`)}
          />
        </Validations>
      </Box>
      <Box px={1} width={1.5 / 7}>
        <Composer components={[<DictionaryValue name="MEDICATION_UNIT" />]}>
          {([dict]: [Dictionary["values"]]) => {
            const translation = i18n._(t`Select option`);
            return (
              <Field.Select
                name={`${name}.dosage.denumeratorUnit`}
                label={<Trans id="Units" />}
                placeholder={translation}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || translation}
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            );
          }}
        </Composer>
        <Validation.Required
          field={`${name}.dosage.denumeratorUnit`}
          message="Required field"
        />
      </Box>
    </Flex>
  );
};

type IngredientsHeaderProps = {
  index: number;
};

const IngredientsHeader = ({ index }: IngredientsHeaderProps) => (
  <Text fontSize={2} mb={6}>
    {index ? (
      <Trans>Additional ingredient</Trans>
    ) : (
      <Trans>Main ingredient</Trans>
    )}
  </Text>
);

const CreateMedicationMutation = gql`
  mutation CreateMedicationMutation($input: CreateMedicationInput!) {
    createMedication(input: $input) {
      medication {
        id
      }
    }
  }
`;

export default Create;

const unitDecorator = createDecorator(
  {
    field: "ingredients[0].dosage.denumeratorUnit",
    updates: {
      "container.numeratorUnit": (value: $TSFixMe) => {
        return value ? value : undefined;
      }
    }
  },
  {
    field: "packageQty",
    updates: {
      packageMinQty: (value: $TSFixMe) => {
        return value ? value : undefined;
      }
    }
  }
);

const prepareFormValues = (prev: $TSFixMe, values: $TSFixMe = {}) => {
  const {
    ingredients: ingredientsFields = [],
    container: containerField = {},
    packageQty,
    packageMinQty
  } = values;
  const { numeratorValue } = containerField;

  const ingredients = ingredientsFields.map((ingredient: $TSFixMe = {}) => {
    const { isPrimary, dosage: itemDosage = {} } = ingredient;

    const dosage = Object.assign(itemDosage, {
      numeratorValue: parseFloat(itemDosage.numeratorValue),
      denumeratorValue: parseFloat(itemDosage.denumeratorValue)
    });

    return Object.assign(ingredient, {
      dosage,
      isPrimary: Boolean(isPrimary)
    });
  });

  const container = Object.assign(containerField, {
    denumeratorValue: 1,
    numeratorValue: parseFloat(numeratorValue)
  });

  return Object.assign(prev, {
    createMedicationIngredients: {
      ...values,
      container,
      ingredients,
      packageMinQty: parseFloat(packageMinQty),
      packageQty: parseFloat(packageQty)
    }
  });
};
