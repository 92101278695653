import React, { useMemo } from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { Trans } from "@lingui/macro";
import { debounce, isEmpty, get } from "lodash";

import { Validation, Form } from "@edenlabllc/ehealth-components";
import { QueryStreetsArgs, StreetConnection } from "@ehealth/ehealth-ua.schema";

import * as Field from "../Field";
import { ADDRESS_ITEM_PATTERN } from "../../constants/validationPatterns";
import { ApolloQueryResult, QueryResult } from "@apollo/client";

type StreetFieldProps = {
  name: string;
  streetTypeName: string;
  rootName: string;
};

const StreetField = ({ name, streetTypeName, rootName }: StreetFieldProps) => {
  return (
    <Trans
      id="Enter street name"
      render={({ translation }) => (
        <Form.Spy>
          {({ values }: $TSFixMe) => {
            const currStreetNameVal = get(values, name);
            const currStreetTypeNameVal = get(values, streetTypeName);
            const currAddressVal = get(values, rootName);

            const settlementId =
              (currAddressVal &&
                currAddressVal.settlementId &&
                currAddressVal.settlementId) ||
              (currAddressVal &&
                currAddressVal.settlement &&
                currAddressVal.settlement.databaseId &&
                currAddressVal.settlement.databaseId);

            if (!settlementId) {
              return (
                <>
                  <Field.Text
                    name={name}
                    label={<Trans id="Street" />}
                    placeholder={translation}
                  />
                  <Trans
                    id="Invalid format"
                    render={({ translation }) => (
                      <Validation.Matches
                        field={name}
                        options={ADDRESS_ITEM_PATTERN}
                        message={translation}
                      />
                    )}
                  />
                </>
              );
            }

            return (
              <Query
                query={StreetsQuery}
                fetchPolicy="cache-first"
                variables={{
                  first: 20,
                  filter: {
                    settlementId: settlementId,
                    name: currStreetNameVal,
                    type: currStreetTypeNameVal
                  }
                }}
              >
                {({
                  data,
                  error,
                  refetch
                }: QueryResult<{ streets: StreetConnection }>) => {
                  if (error || isEmpty(data) || isEmpty(data.streets.nodes)) {
                    return (
                      <>
                        <Field.Text
                          name={name}
                          label={<Trans id="Street" />}
                          placeholder={translation}
                        />
                        <Trans
                          id="Invalid format"
                          render={({ translation }) => (
                            <Validation.Matches
                              field={name}
                              options={ADDRESS_ITEM_PATTERN}
                              message={translation}
                            />
                          )}
                        />
                      </>
                    );
                  }
                  return (
                    <>
                      <StreetsField
                        refetch={refetch}
                        streets={data && data.streets && data.streets.nodes}
                        name={name}
                        translation={translation}
                        settlementId={settlementId}
                        streetType={currStreetTypeNameVal}
                      />
                      <Trans
                        id="Invalid format"
                        render={({ translation }) => (
                          <Validation.Matches
                            field={name}
                            options={ADDRESS_ITEM_PATTERN}
                            message={translation}
                          />
                        )}
                      />
                    </>
                  );
                }}
              </Query>
            );
          }}
        </Form.Spy>
      )}
    />
  );
};

export default StreetField;

export const StreetsQuery = gql`
  query StreetsQuery($first: Int, $filter: StreetFilter) {
    streets(first: $first, filter: $filter) {
      nodes {
        id
        type
        name
      }
    }
  }
`;

type StreetsFieldProps = {
  streets: StreetConnection["nodes"];
  refetch: (
    variables: QueryStreetsArgs
  ) => Promise<ApolloQueryResult<{ streets: StreetConnection }>>;
  name: string;
  translation: React.ReactNode;
  settlementId: string;
  streetType: string;
};

const StreetsField = ({
  streets,
  refetch,
  name,
  translation,
  settlementId,
  streetType
}: StreetsFieldProps) => {
  const streetsList = useMemo(
    () => (streets ? streets.map((street) => street && street.name) : []),
    [streets]
  );

  return (
    <Field.Select
      name={name}
      label={<Trans id="Street" />}
      placeholder={translation}
      items={streetsList}
      itemToString={(item: string) => (item ? item : "")}
      onInputValueChange={debounce(
        (settlementName, { selectedItem, inputValue }) => {
          return (
            !isEmpty(settlementName) &&
            selectedItem !== inputValue &&
            refetch({
              first: 20,
              filter: {
                name: inputValue,
                settlementId: settlementId,
                type: streetType
              }
            })
          );
        },
        300
      )}
    />
  );
};
