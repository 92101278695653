import React from "react";
import { Trans } from "@lingui/macro";

export const TABS_LIST = [
  {
    tabLabel: <Trans>Episodes</Trans>,
    path: "episodes",
    slug: "episode",
    ability: false
  },
  {
    tabLabel: <Trans>Encounters</Trans>,
    path: "encounters",
    slug: "encounter",
    ability: false
  },
  {
    tabLabel: <Trans>Conditions</Trans>,
    path: "conditions",
    slug: "condition",
    ability: false
  },
  {
    tabLabel: <Trans>Observations</Trans>,
    path: "observations",
    slug: "observation",
    ability: false
  },
  {
    tabLabel: <Trans>Procedures</Trans>,
    path: "procedures",
    slug: "procedure",
    ability: false
  },
  {
    tabLabel: <Trans>Service requests</Trans>,
    path: "service-requests",
    slug: "serviceRequest",
    ability: false
  },
  {
    tabLabel: <Trans>Medication requests</Trans>,
    path: "medication-requests",
    slug: "medicationRequest",
    ability: false
  },
  {
    tabLabel: <Trans>Medication dispenses</Trans>,
    path: "medication-dispenses",
    slug: "medicationDispense",
    ability: false
  },
  {
    tabLabel: <Trans>Immunizations</Trans>,
    path: "immunizations",
    slug: "immunization",
    ability: false
  },
  {
    tabLabel: <Trans>Care plans</Trans>,
    path: "care-plans",
    slug: "carePlan",
    ability: false
  },
  {
    tabLabel: <Trans>Allergy intolerances</Trans>,
    path: "allergy-intolerances",
    slug: "allergyIntolerance",
    ability: false
  },
  {
    tabLabel: <Trans>Diagnostic reports</Trans>,
    path: "diagnostic-reports",
    slug: "diagnosticReport",
    ability: false
  }
];
