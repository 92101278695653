import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";

import Search from "./Search/";
import Details from "./Details/";
import DivisionDetails from "./Details/Divisions/Details";
import Add from "./Add";

const LegalEntities = ({ uri }: RouteComponentProps) => (
  <Router>
    <Redirect from="/" to={`${uri}/search`} />
    <Search path="search/*" />
    <Details path=":id/*" />
    <DivisionDetails path=":id/divisions/:divisionId/*" />
    <Add path=":id/add/*" />
  </Router>
);

export default LegalEntities;
