import * as React from "react";
import { gql } from "graphql-tag";
import { Flex, Box, Text } from "@rebass/emotion";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { TriangleLeftIcon, TriangleRightIcon } from "@edenlabllc/ehealth-icons";
import system from "@edenlabllc/ehealth-system-components";
import { PageInfo } from "@ehealth/ehealth-ua.schema";

import { ITEMS_PER_PAGE } from "../constants/pagination";
import Button from "./Button";
import { TLocationParams } from "./SearchForm";

type PaginationProps = PageInfo & {
  itemsCountDefault?: number;
  offset?: boolean;
};

const Pagination = ({
  offset = false,
  endCursor,
  hasNextPage,
  hasPreviousPage,
  startCursor,
  itemsCountDefault = ITEMS_PER_PAGE[0]
}: PaginationProps): React.ReactElement => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => {
      const { first, last } = locationParams;
      return (
        <Flex alignItems="center" justifyContent="center" m="2">
          {hasPreviousPage && (
            <Box mr="1">
              <Button
                variant="none"
                border="1px solid #e6eaee"
                onClick={() => {
                  setLocationParams(
                    offset
                      ? {
                          ...locationParams,
                          after: String(
                            Number(startCursor) -
                              (Number(first) || itemsCountDefault)
                          ),
                          first: first || String(itemsCountDefault),
                          before: undefined,
                          last: undefined
                        }
                      : {
                          ...locationParams,
                          before: startCursor,
                          last: last || String(itemsCountDefault),
                          after: undefined,
                          first: undefined
                        }
                  );
                }}
              >
                <Wrapper>
                  <TriangleLeftIcon />
                  <Text ml="1" color="darkAndStormy">
                    Назад
                  </Text>
                </Wrapper>
              </Button>
            </Box>
          )}
          {hasNextPage && (
            <Button
              variant="none"
              border="1px solid #e6eaee"
              onClick={() => {
                setLocationParams(
                  offset
                    ? {
                        ...locationParams,
                        after: endCursor,
                        first: first || String(itemsCountDefault),
                        before: undefined,
                        last: undefined
                      }
                    : {
                        ...locationParams,
                        after: endCursor,
                        first: first || String(itemsCountDefault),
                        before: undefined,
                        last: undefined
                      }
                );
              }}
            >
              <Wrapper>
                <Text mr="1" color="darkAndStormy">
                  Вперед
                </Text>
                <TriangleRightIcon />
              </Wrapper>
            </Button>
          )}
        </Flex>
      );
    }}
  </LocationParams>
);

Pagination.fragments = {
  entry: gql`
    fragment PageInfo on PageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  `
};

const Wrapper = system(
  {
    extend: Flex,
    color: "silverCity"
  },
  {
    alignItems: "center",
    justifyContent: "center",
    width: "70px",
    lineHeight: 1
  },
  "color"
);

export default Pagination;
