import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import {
  getFullName,
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import {
  PersonDataHistoryRecordConnection,
  PersonDataHistoryRecord
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ChangesetView from "../../../components/ChangesetView";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import Table from "../../../components/Table";
import { TLocationParams } from "../../../components/SearchForm";

import dateFormatter from "../../../helpers/dateFormatter";
import pagination from "../../../helpers/pagination";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type PersonDataHistoryProps = RouteComponentProps<{
  id: string;
}>;

const PersonVerificationDataHistory = ({
  id: personId,
  navigate
}: PersonDataHistoryProps) => {
  const { i18n } = useLingui();

  return (
    <Ability action="read" resource="person_history">
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => {
          const variables = {
            orderBy: locationParams.orderBy
              ? locationParams.orderBy
              : "INSERTED_AT_DESC",
            filter: {
              personId,
              resourceType: "ALL",
              personDataHistoryType: "VERIFICATION"
            },
            ...pagination(locationParams)
          };

          return (
            <>
              <Box p={6}>
                <Box mb={10}>
                  <Breadcrumbs.List>
                    <Breadcrumbs.Item to="/persons">
                      <Trans>Patient Search</Trans>
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item>
                      <Trans>Person verification changes history</Trans>
                    </Breadcrumbs.Item>
                  </Breadcrumbs.List>
                </Box>
                <Flex justifyContent="flex-end">
                  <Box>
                    <Button
                      variant="link"
                      px="0"
                      py="0"
                      icon={RemoveItemIcon}
                      type="reset"
                      onClick={() => navigate!("../")}
                    >
                      <Trans>Exit persons verification changes history</Trans>
                    </Button>
                  </Box>
                </Flex>
                <Query
                  query={PersonDataHistoryQuery}
                  fetchPolicy="network-only"
                  variables={variables}
                >
                  {({
                    data,
                    loading: loadingPersonDataHistory
                  }: QueryResult<{
                    personDataHistoryRecords: PersonDataHistoryRecordConnection;
                  }>) => {
                    if (isEmpty(data) || isEmpty(data.personDataHistoryRecords))
                      return null;

                    const { pageInfo } = data.personDataHistoryRecords;

                    return (
                      <LoadingOverlay loading={loadingPersonDataHistory}>
                        <Box>
                          <Table
                            data={data.personDataHistoryRecords.nodes}
                            header={{
                              databaseId: <Trans>ID</Trans>,
                              changeset: <Trans>Changes history</Trans>,
                              insertedAt: <Trans>Inserted at</Trans>,
                              actorName: <Trans>Performer full name</Trans>
                            }}
                            renderRow={({
                              databaseId,
                              insertedAt,
                              partyData,
                              // @ts-expect-error types mismatch
                              changeset,
                              operationType
                            }: PersonDataHistoryRecord) => ({
                              databaseId,
                              changeset: (
                                <ChangesetView
                                  content={changeset.content}
                                  operationType={operationType}
                                />
                              ),
                              insertedAt: dateFormatter(
                                i18n.locale,
                                DATE_TIME_FORMAT,
                                insertedAt
                              ),
                              actorName: partyData
                                ? getFullName(partyData)
                                : "-"
                            })}
                            sortableFields={["insertedAt"]}
                            sortingParams={parseSortingParams(
                              locationParams.orderBy
                            )}
                            onSortingChange={(sortingParams) =>
                              setLocationParams({
                                ...locationParams,
                                orderBy: stringifySortingParams(sortingParams)
                              })
                            }
                            tableName="personVerificationDataHistory/search"
                            columnsDefaultWidth={{
                              changeset: 600
                            }}
                          />
                          <Pagination {...pageInfo} />
                        </Box>
                      </LoadingOverlay>
                    );
                  }}
                </Query>
              </Box>
            </>
          );
        }}
      </LocationParams>
    </Ability>
  );
};

export default PersonVerificationDataHistory;

const PersonDataHistoryQuery = gql`
  query PersonDataHistory(
    $filter: PersonDataHistoryRecordFilter!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: PersonDataHistoryRecordOrderBy
  ) {
    personDataHistoryRecords(
      filter: $filter
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
    ) {
      nodes {
        changeset {
          content
        }
        id
        databaseId
        insertedAt
        partyData {
          firstName
          lastName
          secondName
        }
        operationType
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;
