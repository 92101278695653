import React, { Fragment, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Flex, Heading, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import { LegalEntity } from "@ehealth/ehealth-ua.schema";

import Button from "../../../components/Button";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";
import UnpocessableEntityModalError from "../../../components/UnpocessableEntityModalError";

import dateFormatter from "../../../helpers/dateFormatter";
import { getErrorCode, getErrorMessage } from "../../../helpers/errorHelpers";
import { DATE_FORMAT } from "../../../constants/dateFormats";
import { PRIVATE_ENTREPRENEUR_REGEX } from "../constants";

import env from "../../../env";

const Confirm = ({
  // @ts-expect-error location state
  location: { state },
  navigate
}: RouteComponentProps) => {
  const { i18n } = useLingui();
  const [error, setError] = useState(null);

  const isPrivateEntrepreneurTransformation =
    state.reorganizationType === "TRANSFORMATION" &&
    PRIVATE_ENTREPRENEUR_REGEX.test(state.reorganizedLegalEntities[0].edrpou);

  return (
    <>
      <Heading as="h1" fontWeight="normal" mb={6}>
        <Trans>Reorganized legal entity</Trans>
      </Heading>
      <DefinitionListView
        labels={{
          databaseId: <Trans>Legal entity ID</Trans>,
          name: <Trans>Name</Trans>,
          edrpou: <Trans>EDRPOU</Trans>
        }}
        data={{
          databaseId:
            state.reorganizedLegalEntities &&
            state.reorganizedLegalEntities[0].databaseId,
          name:
            state.reorganizedLegalEntities &&
            state.reorganizedLegalEntities[0].name,
          edrpou:
            state.reorganizedLegalEntities &&
            state.reorganizedLegalEntities[0].edrpou
        }}
        labelWidth="250px"
      />
      <Line />
      {!isEmpty(state.successorLegalEntites) && (
        <Heading as="h1" fontWeight="normal" mb={6}>
          <Trans>Successor legal entity</Trans>
        </Heading>
      )}
      {!isEmpty(state.successorLegalEntites) &&
        state.successorLegalEntites.map(
          ({ databaseId, name, edrpou }: LegalEntity) => (
            <Fragment key={databaseId}>
              <DefinitionListView
                labels={{
                  databaseId: <Trans>Legal entity ID</Trans>,
                  name: <Trans>Name</Trans>,
                  edrpou: <Trans>EDRPOU</Trans>
                }}
                data={{
                  databaseId: databaseId,
                  name: name,
                  edrpou: edrpou
                }}
                labelWidth="250px"
              />
              <Line />
            </Fragment>
          )
        )}
      <Heading as="h1" fontWeight="normal" mb={6}>
        <Trans>Reorganization data</Trans>
      </Heading>
      <DefinitionListView
        labelWidth="250px"
        labels={{
          reorganizationType: <Trans>Reorganization type</Trans>,
          reorganizationReason: <Trans>Reorganization reason</Trans>,
          reorganizationReasonDate: <Trans>Reorganization reason date</Trans>
        }}
        data={{
          reorganizationType: (
            <DictionaryValue
              name="LEGAL_ENTITY_REORGANIZATION_TYPES"
              item={state.reorganizationType}
            />
          ),
          reorganizationReason: state.reorganizationReason,
          reorganizationReasonDate: dateFormatter(
            i18n.locale,
            DATE_FORMAT,
            state.reorganizationReasonDate
          )
        }}
      />
      <Line />
      <Flex>
        <Button
          mr={2}
          onClick={() => {
            if (state.reorganizationType === "TRANSFORMATION") {
              return navigate!("../reorganization-data", { state });
            }
            navigate!("../successor-legal-entity", {
              state
            });
          }}
          variant="blue"
          type="reset"
          width={140}
        >
          <Trans>Return</Trans>
        </Button>
        <Signer.Parent
          url={env.REACT_APP_SIGNER_URL}
          features={{
            width: 640,
            height: 589
          }}
        >
          {/* @ts-expect-error signData */}
          {({ signData }) => {
            return (
              <Mutation mutation={ReorganizeLegalEntityMutation}>
                {(reorganizeLegalEntity: MutationFunction) => {
                  const mergedToLegalEntity: {
                    id: string;
                    name: string;
                    edrpou: string;
                  }[] = [];
                  Object.keys(state.successorLegalEntites || []).forEach(
                    (index) => {
                      mergedToLegalEntity.push({
                        id: state.successorLegalEntites[index].databaseId,
                        name: state.successorLegalEntites[index].name,
                        edrpou: state.successorLegalEntites[index].edrpou
                      });
                    }
                  );

                  return (
                    <>
                      <Button
                        variant="green"
                        onClick={async () => {
                          setError(null);
                          const { signedContent } = await signData({
                            reason: state.reorganizationReason,
                            reason_date: state.reorganizationReasonDate,
                            type: state.reorganizationType,
                            merged_from_legal_entity: {
                              id: state.reorganizedLegalEntities[0].databaseId,
                              name: state.reorganizedLegalEntities[0].name,
                              edrpou: state.reorganizedLegalEntities[0].edrpou
                            },
                            ...(state.reorganizationType !==
                              "TRANSFORMATION" && {
                              merged_to_legal_entity: mergedToLegalEntity
                            })
                          });

                          try {
                            const { data } = await reorganizeLegalEntity({
                              variables: {
                                input: {
                                  signedContent: {
                                    content: signedContent,
                                    encoding: "BASE64"
                                  }
                                }
                              }
                            });
                            if (data.reorganizeLegalEntity) {
                              await navigate!(
                                "/legal-entity-reorganization-jobs"
                              );
                            }
                          } catch (error) {
                            if (
                              getErrorCode(error) === "UNPROCESSABLE_ENTITY"
                            ) {
                              setError(getErrorMessage(error));
                            }
                          }
                        }}
                        disabled={isPrivateEntrepreneurTransformation}
                      >
                        <Trans>Sign</Trans>
                      </Button>
                      {error && (
                        <UnpocessableEntityModalError
                          errorMessage={error}
                          isModalOpen
                        />
                      )}
                    </>
                  );
                }}
              </Mutation>
            );
          }}
        </Signer.Parent>
      </Flex>
      {isPrivateEntrepreneurTransformation && (
        <Box mt={2}>
          <Text color="red">
            <Trans>
              Reorganization with type TRANSFORMATION is not allowed for
              selected legal entity
            </Trans>
          </Text>
        </Box>
      )}
    </>
  );
};

export default Confirm;

const ReorganizeLegalEntityMutation = gql`
  mutation ReorganizeLegalEntity($input: ReorganizeLegalEntityInput!) {
    reorganizeLegalEntity(input: $input) {
      legalEntityReorganizationJob {
        id
      }
    }
  }
`;
