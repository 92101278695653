import React from "react";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import { ForbiddenGroupService, Maybe } from "@ehealth/ehealth-ua.schema";

import Link from "../../../components/Link";
import Table from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type ForbiddenGroupServicesTable = {
  forbiddenGroupServices?: Maybe<Array<Maybe<ForbiddenGroupService>>>;
  tableName?: string;
};

const ForbiddenGroupServicesTable = ({
  forbiddenGroupServices,
  tableName = "forbiddenGroupServices/search"
}: ForbiddenGroupServicesTable) => {
  return (
    <Table
      data={forbiddenGroupServices || []}
      header={{
        databaseId: <Trans>ID</Trans>,
        code: <Trans>Code</Trans>,
        type: <Trans>Type</Trans>,
        name: <Trans>Name</Trans>,
        isActive: <Trans>Status</Trans>,
        insertedAt: <Trans>Inserted at</Trans>,
        updatedAt: <Trans>Updated at</Trans>,
        action: <Trans>Action</Trans>
      }}
      renderRow={({
        databaseId,
        serviceGroup,
        service,
        isActive,
        insertedAt,
        updatedAt
      }) => {
        const isServiceGroup = isEmpty(service);
        return {
          databaseId,
          type: isEmpty(serviceGroup) ? (
            <Trans>Service</Trans>
          ) : (
            <Trans>Service group</Trans>
          ),
          name:
            isServiceGroup && serviceGroup
              ? serviceGroup.name
              : service && service.name,
          isActive: (
            <Flex justifyContent="center">
              {isActive ? <PositiveIcon /> : <NegativeIcon />}
            </Flex>
          ),
          code:
            isServiceGroup && serviceGroup
              ? serviceGroup.code
              : service && service.code,
          insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
          updatedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, updatedAt),
          action:
            isServiceGroup && serviceGroup ? (
              <Link
                to={`../../../service-groups/${serviceGroup.id}`}
                fontWeight="bold"
              >
                <Trans>View</Trans>
              </Link>
            ) : (
              <Link
                to={`../../../services/${service && service.id}`}
                fontWeight="bold"
              >
                <Trans>View</Trans>
              </Link>
            )
        };
      }}
      tableName={tableName}
      hiddenFields="action,updatedAt"
    />
  );
};

export default ForbiddenGroupServicesTable;
