import React, { useState } from "react";
import styled from "@emotion/styled";
import isEmpty from "lodash/isEmpty";
import { css, keyframes } from "@emotion/core";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core";

type TooltipProps = {
  component: () => React.ReactElement;
  content: React.ReactNode;
  disableHover?: boolean;
  showTooltip?: boolean;
  placement?: Placement;
};

const Tooltip = ({
  component: Component,
  content,
  disableHover = false,
  showTooltip = false,
  placement = "auto"
}: TooltipProps) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes, state } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [{ name: "arrow", options: { element: arrowElement } }],
      placement: placement
    }
  );

  const isStateStylesExist = !isEmpty(state && state.styles);

  return (
    <>
      {/* @ts-expect-error ref */}
      <Target ref={setReferenceElement} disableHover={disableHover}>
        <Component />
      </Target>
      <TooltipWrapper
        // @ts-expect-error ref
        ref={setPopperElement}
        placement={
          attributes.popper
            ? attributes.popper["data-popper-placement"]
            : placement
        }
        showTooltip={showTooltip}
        {...attributes.popper}
        style={styles.popper}
      >
        {content}
        <Arrow
          style={{
            top:
              isStateStylesExist && state!.styles.arrow.top
                ? `${state!.styles.arrow.top}px`
                : "auto",
            left:
              isStateStylesExist && state!.styles.arrow.left
                ? `${state!.styles.arrow.left}px`
                : "auto"
          }}
          placement={!isEmpty(state) ? state.placement : placement}
          // @ts-expect-error ref
          ref={setArrowElement}
          transform={isStateStylesExist ? state!.styles.arrow.transform : ""}
        />
      </TooltipWrapper>
    </>
  );
};

const bounce = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;

  }
  50% {
    opacity: 1;
    visibility: visible;
  }
  0% {
    opacity: 0;
    visibility: hidden;
  }
`;

const TooltipWrapper = styled.div`
  max-width: 450px;
  margin: 7px;
  padding: 10px;
  background-image: linear-gradient(0deg, #f2f4f7 0%, #ffffff 100%);
  border: 1px solid #ced0da;
  border-radius: 2px;
  font-size: 14px;
  color: #354052;
  text-align: center;
  line-height: 19px;
  opacity: 0;
  visibility: hidden;
  transition: all 100ms;
  ${(props: { showTooltip: boolean }) =>
    props.showTooltip && { animation: `${bounce} 1.5s ease forwards` }};
`;

const Target = styled.div`
  display: inline-block;
  line-height: 0;
  ${(props: { disableHover: boolean }) =>
    !props.disableHover
      ? `&:hover + div {
    opacity: 1;
    visibility: visible;
  }`
      : null};
`;

const arrowPosition = ({
  placement,
  transform
}: {
  placement: Placement;
  transform?: string;
}) => {
  switch (placement) {
    case "top":
      return css`
        bottom: 0;
        transform: ${transform} rotate(-135deg);
        clip-path: polygon(100% 0, 0 100%, 0 0);
      `;
    case "right":
      return css`
        right: 100%;
        transform: ${transform} rotate(-135deg);
        clip-path: polygon(100% 100%, 0 0, 100% 0);
      `;
    case "bottom":
      return css`
        bottom: 100%;
        transform: ${transform} rotate(-135deg);
        clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
      `;
    case "left":
      return css`
        right: 0;
        transform: ${transform} rotate(135deg);
        clip-path: polygon(0 0, 0 100%, 100% 0);
      `;
    default:
      return;
  }
};

const Arrow = styled.div`
  position: absolute;
  z-index: 50;
  margin: -6px;
  ${arrowPosition};
  width: 12px;
  height: 12px;
  background-image: linear-gradient(135deg, #f2f4f7 0%, #ffffff 100%);
  border: 1px solid #ced0da;
  will-change: transform;
`;

export default Tooltip;
