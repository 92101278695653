import { gql } from "graphql-tag";
import Pagination from "../../../components/Pagination";
import Diagnosis from "../../PracticalMonitoring/Details/Diagnosis";
import Encounter from "../../PracticalMonitoring/Details/Encounter";

export const ImpersonalEncountersQuery = gql`
  query ImpersonalEncountersQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImpersonalEncounterFilter!
    $orderBy: EncounterOrderBy
  ) {
    impersonalEncounters(
      after: $after
      before: $before
      filter: $filter
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      nodes {
        id
        databaseId
        status
        insertedAt
        diagnoses {
          ...Diagnosis
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalEntries
    }
  }
  ${Pagination.fragments.entry}
  ${Diagnosis.fragments.entry}
`;

export const ImpersonalEncounterByIDQuery = gql`
  query ImpersonalEncounterByIDQuery(
    $id: ID!
    $patientId: String!
    $skipAdditionalFields: Boolean!
  ) {
    impersonalEncounter(id: $id, patientId: $patientId) {
      ...Encounter
    }
  }
  ${Encounter.fragments.entry}
`;
