import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { AllergyIntolerance as TAllergyIntolerance } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import CodeableConcept from "../../../components/CodeableConcept";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";
import LoadingOverlay from "../../../components/LoadingOverlay";

import dateFormatter from "../../../helpers/dateFormatter";
import paramToBase64 from "../../../helpers/paramToBase64";
import { MonitoringDetailsPageProps } from "../../../helpers/types";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import CreateConclusion from "../../ClinicalMonitoring/CreateConclusion";
import getConclusionData from "../../ClinicalMonitoring/getConclusionData";
import Conclusions from "../../ClinicalMonitoring/Conclusions";
import DetailsPageBreadcrumbs from "./DetailsPageBreadcrumbs";
import Reference from "./Reference";
import { LABEL_WIDTH } from "./Episode";

const AllergyIntolerance = ({
  query,
  queryVariables,
  itemId,
  id,
  patientId,
  isClinicalMonitoring = false
}: MonitoringDetailsPageProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="service_request"
  >
    <Box p={6}>
      <DetailsPageBreadcrumbs
        id={id}
        currentStepName={<Trans>Allergy intolerance details</Trans>}
        isClinicalMonitoring={isClinicalMonitoring}
      />
      <Query
        query={query}
        fetchPolicy="network-only"
        variables={{
          ...queryVariables,
          id: itemId,
          ...(isClinicalMonitoring && { patientId })
        }}
      >
        {({
          loading,
          error,
          data = {}
        }: QueryResult<{ [key: string]: TAllergyIntolerance }>) => {
          const allergyIntolerance = isClinicalMonitoring
            ? data.impersonalAllergyIntolerance
            : data.allergyIntolerance;

          return (
            <LoadingOverlay loading={loading}>
              <Box mt={4}>
                {!isEmpty(allergyIntolerance) && !error && (
                  <>
                    <HeaderDefinitions
                      allergyIntolerance={allergyIntolerance}
                      isClinicalMonitoring={isClinicalMonitoring}
                      patientId={patientId || ""}
                    />
                    <TopBodyDefinitions
                      allergyIntolerance={allergyIntolerance}
                    />
                    {!isEmpty(allergyIntolerance.context) && (
                      <Box mt={2}>
                        <Reference
                          fontSize={14}
                          linkWrapperProps={{ mt: 0, ml: 0 }}
                          header={<Trans>Context</Trans>}
                          linkPath={
                            isClinicalMonitoring
                              ? `../../../encounter/${patientId}/${paramToBase64(
                                  "Encounter",
                                  allergyIntolerance.context.identifier.value
                                )}`
                              : `../../encounter/${paramToBase64(
                                  "Encounter",
                                  allergyIntolerance.context.identifier.value
                                )}`
                          }
                          linkDisplayValue={
                            allergyIntolerance.context.displayValue
                              ? allergyIntolerance.context.displayValue
                              : allergyIntolerance.context.identifier.value
                          }
                        />
                      </Box>
                    )}
                    <BottomBodyDefinitions
                      allergyIntolerance={allergyIntolerance}
                    />
                  </>
                )}
              </Box>
            </LoadingOverlay>
          );
        }}
      </Query>
      <Conclusions
        isClinicalMonitoring={isClinicalMonitoring}
        patientId={patientId!}
        itemId={itemId!}
      />
    </Box>
  </Ability>
);

export default AllergyIntolerance;

type HeaderDefinitionsProps = {
  allergyIntolerance: TAllergyIntolerance;
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const HeaderDefinitions = ({
  allergyIntolerance,
  isClinicalMonitoring,
  patientId
}: HeaderDefinitionsProps) => {
  if (isEmpty(allergyIntolerance)) return null;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <div>
          <DefinitionListView
            labels={{
              databaseId: <Trans>ID</Trans>,
              ...(allergyIntolerance.verificationStatus && {
                verificationStatus: (
                  <Trans id="AllergyIntolerance verification status">
                    Verification status
                  </Trans>
                )
              }),
              ...(allergyIntolerance.clinicalStatus && {
                clinicalStatus: <Trans>Clinical status</Trans>
              })
            }}
            data={{
              ...allergyIntolerance,
              ...(allergyIntolerance.verificationStatus && {
                verificationStatus: (
                  <DictionaryValue
                    name="eHealth/allergy_intolerance_verification_statuses"
                    item={allergyIntolerance.verificationStatus.toLowerCase()}
                  />
                )
              }),
              ...(allergyIntolerance.clinicalStatus && {
                clinicalStatus: (
                  <DictionaryValue
                    name="eHealth/allergy_intolerance_clinical_statuses"
                    item={allergyIntolerance.clinicalStatus.toLowerCase()}
                  />
                )
              })
            }}
            color="#7F8FA4"
            labelWidth={LABEL_WIDTH}
          />
        </div>
        {isClinicalMonitoring && (
          <CreateConclusion
            initialData={getConclusionData(
              patientId,
              "allergy_intolerance",
              allergyIntolerance.databaseId
            )}
          />
        )}
      </Flex>
      <Line />
    </>
  );
};

const TopBodyDefinitions = ({
  allergyIntolerance
}: {
  allergyIntolerance: TAllergyIntolerance;
}) => {
  const { i18n } = useLingui();
  if (isEmpty(allergyIntolerance)) return null;
  return (
    <DefinitionListView
      fontSize={14}
      labelWidth={LABEL_WIDTH}
      labels={{
        type: <Trans>Type</Trans>,
        category: <Trans>Category</Trans>,
        criticality: <Trans>Criticality</Trans>,
        ...(allergyIntolerance.code && { code: <Trans>Code</Trans> }),
        onsetDateTime: <Trans>Onset datetime</Trans>,
        ...(allergyIntolerance.reportOrigin && {
          reportOrigin: <Trans>Report origin</Trans>
        }),
        ...(!isEmpty(allergyIntolerance.asserter) && {
          asserter: <Trans>Asserter</Trans>
        })
      }}
      data={{
        ...allergyIntolerance,
        type: (
          <DictionaryValue
            name="eHealth/allergy_intolerance_types"
            item={allergyIntolerance.type.toLowerCase()}
          />
        ),
        category: (
          <DictionaryValue
            name="eHealth/allergy_intolerance_categories"
            item={allergyIntolerance.category.toLowerCase()}
          />
        ),
        ...(allergyIntolerance.criticality && {
          criticality: (
            <DictionaryValue
              name="eHealth/allergy_intolerance_criticalities"
              item={allergyIntolerance.criticality.toLowerCase()}
            />
          )
        }),
        ...(allergyIntolerance.code && {
          code: <CodeableConcept codeableConcept={allergyIntolerance.code} />
        }),
        onsetDateTime: dateFormatter(
          i18n.locale,
          DATE_TIME_FORMAT,
          allergyIntolerance.onsetDateTime
        ),
        reportOrigin: (
          <CodeableConcept codeableConcept={allergyIntolerance.reportOrigin} />
        ),
        ...(!isEmpty(allergyIntolerance.asserter) && {
          asserter: (
            <Reference
              fontSize={14}
              linkWrapperProps={{ mt: 0, ml: 0 }}
              linkPath={`/employees/${paramToBase64(
                "Employee",
                allergyIntolerance.asserter.identifier.value
              )}`}
              linkDisplayValue={
                allergyIntolerance.asserter.displayValue
                  ? allergyIntolerance.asserter.displayValue
                  : allergyIntolerance.asserter.identifier.value
              }
            />
          )
        })
      }}
    />
  );
};

const BottomBodyDefinitions = ({
  allergyIntolerance
}: {
  allergyIntolerance: TAllergyIntolerance;
}) => {
  const { i18n } = useLingui();
  if (isEmpty(allergyIntolerance)) return null;

  return (
    <Box mt={4}>
      <DefinitionListView
        fontSize={14}
        labels={{
          lastOccurrence: <Trans>Last occurrence</Trans>,
          insertedAt: <Trans>Inserted at</Trans>,
          updatedAt: <Trans>Updated at</Trans>
        }}
        data={{
          insertedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            allergyIntolerance.insertedAt
          ),
          updatedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            allergyIntolerance.updatedAt
          ),
          lastOccurrence: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            allergyIntolerance.lastOccurrence
          )
        }}
        labelWidth={LABEL_WIDTH}
      />
    </Box>
  );
};

AllergyIntolerance.fragments = {
  entry: gql`
    fragment AllergyIntolerance on AllergyIntolerance {
      id
      databaseId
      clinicalStatus
      verificationStatus
      type
      category
      criticality
      code @skip(if: $skipAdditionalFields) {
        ...CodeableConcept
      }
      context {
        ...Reference
      }
      onsetDateTime
      reportOrigin {
        ...CodeableConcept
      }
      asserter {
        ...Reference
      }
      lastOccurrence
      insertedAt
      updatedAt
    }
    ${CodeableConcept.fragments!.entry}
    ${Reference.fragments.entry}
  `
};

export const AllergyIntoleranceByIDQuery = gql`
  query AllergyIntoleranceByIDQuery(
    $id: ID!
    $personId: ID!
    $skipAdditionalFields: Boolean!
  ) {
    allergyIntolerance(id: $id, personId: $personId) {
      ...AllergyIntolerance
    }
  }
  ${AllergyIntolerance.fragments.entry}
`;
