import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams, Switch } from "@edenlabllc/ehealth-components";
import { filterObjectKeys } from "@edenlabllc/ehealth-utils";
import {
  fieldNameNormalizer,
  convertObjectKeys,
  getFullName,
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  ConfidantPerson,
  Maybe,
  PersonAuthenticationMethod,
  PersonDataHistoryRecord,
  PersonDataHistoryRecordConnection
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import Popup from "../../../components/Popup";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import pagination from "../../../helpers/pagination";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import { getDataByType } from "./index";
import EmergencyContact from "./EmergencyContact";
import UserInfo from "./UserInfo";
import { PrimarySearchFields } from "./PersonDataHistorySearchFields";

type PersonDataHistoryProps = RouteComponentProps<{
  id: string;
  authInfo: Maybe<Array<Maybe<PersonAuthenticationMethod>>>;
}>;

const PersonDataHistory = ({
  id: personId,
  authInfo
}: PersonDataHistoryProps) => {
  const [isDetailsVisible, setDetailsVisibility] = useState(false);
  const [currentDetailsRecordId, setCurrentDetailsRecordId] = useState<
    string | null
  >(null);
  const { i18n } = useLingui();

  return (
    <Ability action="read" resource="person_history">
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => {
          const variables = {
            orderBy: locationParams.orderBy
              ? locationParams.orderBy
              : "INSERTED_AT_DESC",
            filter: {
              personId,
              resourceType:
                (locationParams.filter && locationParams.filter.resourceType) ||
                "ALL",
              personDataHistoryType: "FULL"
            },
            ...pagination(locationParams)
          };

          return (
            <>
              <Box p={6}>
                <Text mb={1}>
                  <Trans>Changes in personal data and statuses</Trans>
                </Text>
                <Query
                  query={PersonDataHistoryQuery}
                  fetchPolicy="network-only"
                  variables={variables}
                >
                  {({
                    data,
                    loading: loadingPersonDataHistory
                  }: QueryResult<{
                    personDataHistoryRecords: PersonDataHistoryRecordConnection;
                  }>) => {
                    if (isEmpty(data) || isEmpty(data.personDataHistoryRecords))
                      return null;

                    const { pageInfo } = data.personDataHistoryRecords;

                    return (
                      <LoadingOverlay loading={loadingPersonDataHistory}>
                        <Box>
                          <SearchForm
                            initialValues={variables}
                            onSubmit={setLocationParams}
                            renderPrimary={PrimarySearchFields}
                          />
                          <Table
                            hiddenFields="actorId"
                            data={data.personDataHistoryRecords.nodes}
                            header={{
                              databaseId: <Trans>ID</Trans>,
                              insertedAt: <Trans>Inserted at</Trans>,
                              resourceType: <Trans>Resource type</Trans>,
                              operationType: <Trans>Record type</Trans>,
                              actorName: <Trans>Performer full name</Trans>,
                              actorId: <Trans>Performer ID</Trans>,
                              action: <Trans>Action</Trans>
                            }}
                            renderRow={({
                              id,
                              databaseId,
                              insertedAt,
                              actorId,
                              partyData,
                              resourceType,
                              operationType
                            }: PersonDataHistoryRecord) => ({
                              databaseId,
                              insertedAt: dateFormatter(
                                i18n.locale,
                                DATE_TIME_FORMAT,
                                insertedAt
                              ),
                              resourceType: (
                                <DictionaryValue
                                  name="HISTORY_RESOURCE_TYPES"
                                  item={resourceType}
                                />
                              ),
                              operationType: (
                                <DictionaryValue
                                  name="HISTORY_OPERATION_TYPES"
                                  item={operationType}
                                />
                              ),
                              actorName: getFullName(partyData),
                              actorId,
                              action: (
                                <>
                                  <Button
                                    variant="link"
                                    width={140}
                                    onClick={() => {
                                      setCurrentDetailsRecordId(id);
                                      setDetailsVisibility(true);
                                    }}
                                  >
                                    <Trans>Details</Trans>
                                  </Button>
                                </>
                              )
                            })}
                            sortableFields={["insertedAt"]}
                            sortingParams={parseSortingParams(
                              locationParams.orderBy
                            )}
                            onSortingChange={(sortingParams: SortingParams) =>
                              setLocationParams({
                                ...locationParams,
                                orderBy: stringifySortingParams(sortingParams)
                              })
                            }
                            tableName="personDataHistory/search"
                          />
                          <Pagination {...pageInfo} />
                          {currentDetailsRecordId && isDetailsVisible && (
                            <Query
                              query={PersonDataHistoryRecordQuery}
                              fetchPolicy="network-only"
                              variables={{ id: currentDetailsRecordId }}
                            >
                              {({
                                data,
                                loading,
                                error
                              }: QueryResult<{
                                personDataHistoryRecord: PersonDataHistoryRecord;
                              }>) => {
                                if (
                                  error ||
                                  isEmpty(data) ||
                                  isEmpty(data.personDataHistoryRecord)
                                ) {
                                  return null;
                                }

                                const content = convertObjectKeys(
                                  JSON.parse(
                                    // @ts-expect-error types mismatch
                                    data.personDataHistoryRecord.changeset
                                      .content
                                  ),
                                  fieldNameNormalizer
                                );

                                const filteredContent = filterObjectKeys(
                                  content,
                                  ["nhsRequestNumber", "nhsRequestComment"]
                                );

                                const {
                                  resourceType
                                } = data.personDataHistoryRecord;

                                return (
                                  <LoadingOverlay loading={loading}>
                                    <Popup
                                      visible={isDetailsVisible}
                                      title={
                                        <Trans>Details of the change</Trans>
                                      }
                                      onCancel={() =>
                                        setDetailsVisibility(false)
                                      }
                                      hideOkButton={true}
                                      overflow="auto"
                                      textAlign="left"
                                    >
                                      <Flex justifyContent="center">
                                        {isEmpty(content) ? (
                                          <Text mb={5} fontSize={1}>
                                            <Trans>
                                              The record does not contain
                                              changes to personal data
                                            </Trans>
                                          </Text>
                                        ) : (
                                          <Box pb={5} fontSize={1}>
                                            <DefinitionListView
                                              labelWidth={200}
                                              labels={{
                                                nhsRequestNumber: (
                                                  <Trans>
                                                    Application number
                                                  </Trans>
                                                ),
                                                nhsRequestComment: (
                                                  <Trans>
                                                    Reason of editing
                                                  </Trans>
                                                )
                                              }}
                                              data={{
                                                nhsRequestNumber:
                                                  content.nhsRequestNumber,
                                                nhsRequestComment:
                                                  content.nhsRequestComment
                                              }}
                                            />
                                            {!isEmpty(filteredContent) && (
                                              <HistoryDetailsSwitcher
                                                resourceType={resourceType}
                                                content={filteredContent}
                                                authInfo={authInfo}
                                              />
                                            )}
                                          </Box>
                                        )}
                                      </Flex>
                                    </Popup>
                                  </LoadingOverlay>
                                );
                              }}
                            </Query>
                          )}
                        </Box>
                      </LoadingOverlay>
                    );
                  }}
                </Query>
              </Box>
            </>
          );
        }}
      </LocationParams>
    </Ability>
  );
};

export default PersonDataHistory;

const PersonDataHistoryQuery = gql`
  query PersonDataHistory(
    $filter: PersonDataHistoryRecordFilter!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: PersonDataHistoryRecordOrderBy
  ) {
    personDataHistoryRecords(
      filter: $filter
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
    ) {
      nodes {
        actorId
        id
        databaseId
        insertedAt
        operationType
        resourceId
        resourceType
        partyData {
          firstName
          lastName
          secondName
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

const PersonDataHistoryRecordQuery = gql`
  query PersonDataHistoryRecordQuery($id: ID!) {
    personDataHistoryRecord(id: $id) {
      actorId
      resourceType
      id
      databaseId
      changeset {
        content
        encoding
      }
    }
  }
`;

type HistoryDetailsSwitcherProps = {
  resourceType: string;
  content: any;
  authInfo: Maybe<Array<Maybe<PersonAuthenticationMethod>>> | undefined;
};

const HistoryDetailsSwitcher = ({
  resourceType,
  content,
  authInfo
}: HistoryDetailsSwitcherProps) => (
  <Switch
    value={resourceType}
    PERSON={<PersonInfo content={content} />}
    PERSON_PHONES={<PersonInfo content={{ phones: dataToArray(content) }} />}
    PERSON_ADDRESSES={
      <PersonInfo content={{ addresses: dataToArray(content) }} />
    }
    PERSON_DOCUMENTS={
      <PersonInfo content={{ documents: dataToArray(content) }} />
    }
    PERSON_AUTHENTICATION_METHODS={
      <AuthMethodsInfo data={dataToArray(content)} authInfo={authInfo} />
    }
    default={<PersonInfo content={content} />}
  />
);

type PersonInfoProps = {
  content: any;
};

const PersonInfo = ({ content }: PersonInfoProps) => {
  if (isEmpty(content)) {
    return null;
  }

  const {
    emergencyContact,
    confidantPerson,
    status,
    verificationStatus,
    verificationReason,
    verificationComment,
    firstName,
    lastName,
    secondName,
    ...restContent
  } = content;

  return (
    <Box>
      <DefinitionListView
        labelWidth={200}
        labels={{
          status: <Trans>Status</Trans>,
          verificationStatus: <Trans>General verification status</Trans>,
          verificationReason: <Trans>Verification reason</Trans>,
          verificationComment: <Trans>Verification comment</Trans>,
          firstName: <Trans>First name</Trans>,
          lastName: <Trans>Last name</Trans>,
          secondName: <Trans>Second name</Trans>
        }}
        data={{
          firstName,
          lastName,
          secondName: checkForNullableValue(secondName),
          verificationComment,
          status: status && (
            <DictionaryValue
              name="PERSON_STATUSES"
              item={status.toUpperCase()}
            />
          ),
          verificationStatus: verificationStatus && (
            <DictionaryValue
              name="PERSON_VERIFICATION_STATUSES"
              item={verificationStatus}
            />
          ),
          verificationReason: verificationReason && (
            <DictionaryValue
              name="PERSON_VERIFICATION_STATUS_REASONS"
              item={verificationReason}
            />
          )
        }}
      />
      <UserInfo data={restContent} wrapperPadding={0} />
      {!isEmpty(emergencyContact) && (
        <>
          <Line />
          <Box mb={4}>
            <Text fontSize={1}>
              <Trans>Emergency contact</Trans>
            </Text>
          </Box>
          <EmergencyContact data={emergencyContact} holderPadding={0} />
        </>
      )}
      {!isEmpty(confidantPerson) && (
        <PersonConfidantPersons data={confidantPerson} />
      )}
    </Box>
  );
};

type PersonConfidantPersonsProps = {
  data: ConfidantPerson[];
};

const PersonConfidantPersons = ({ data }: PersonConfidantPersonsProps) => {
  const [primaryPerson] = getDataByType("PRIMARY", data, "relationType");
  const [secondaryPerson] = getDataByType("SECONDARY", data, "relationType");

  return (
    <>
      {primaryPerson && (
        <>
          <Line />
          <Text fontSize={1} mb={4}>
            <Trans>Primary Confidant Persons</Trans>
          </Text>
          <UserInfo data={primaryPerson} wrapperPadding={0} />
        </>
      )}

      {secondaryPerson && (
        <>
          <Line />
          <Text fontSize={1} mb={4}>
            <Trans>Secondary Confidant Persons</Trans>
          </Text>
          <UserInfo data={secondaryPerson} wrapperPadding={0} />
        </>
      )}
    </>
  );
};

type AuthMethodsInfoProps = {
  data: any;
  authInfo: Maybe<Array<Maybe<PersonAuthenticationMethod>>> | undefined;
};

const AuthMethodsInfo = ({ data }: AuthMethodsInfoProps) => {
  const { i18n } = useLingui();
  const { type, alias, phoneNumber, thirdPerson, endedAt, startedAt } = data[0];

  return (
    <DefinitionListView
      labelWidth={200}
      labels={{
        type: <Trans>Type</Trans>,
        alias: <Trans>Name</Trans>,
        phoneNumber: <Trans>Phone number</Trans>,
        endedAt: <Trans>Ended</Trans>,
        startedAt: <Trans>Started</Trans>,
        thirdPersonId: <Trans>Third person ID</Trans>
      }}
      data={{
        type: type && (
          <DictionaryValue name="AUTHENTICATION_METHOD" item={type} />
        ),
        alias,
        phoneNumber,
        thirdPersonId: thirdPerson && thirdPerson.databaseId,
        endedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, endedAt),
        startedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, startedAt)
      }}
    />
  );
};

const dataToArray = (data: any) => (Array.isArray(data) ? data : [data]);

const checkForNullableValue = (value: string) =>
  value === null ? <Trans>Missing value</Trans> : value;
