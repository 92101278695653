import React from "react";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";

import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

import STATUSES from "../../../helpers/statuses";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 4}>
      <Trans
        id="Enter ID"
        render={({ translation }) => (
          <Field.Text
            name="filter.databaseId"
            label={<Trans id="Participant ID" />}
            placeholder={translation}
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <SearchField.MedicalProgram
        name="filter.medicalProgram"
        filteredParams={["name", "databaseId"]}
        additionalFilters={{ isActive: true, type: "SERVICE" }}
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <SearchField.Service name="filter.service" />
    </Box>
    <Box px={1} width={1 / 4}>
      <SearchField.ServiceGroup name="filter.serviceGroup" />
    </Box>
  </Flex>
);

const SecondarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 4}>
      <SearchField.Status name="filter.isActive" status="ACTIVE_STATUS_F" />
    </Box>
    <Box px={1} width={1 / 4}>
      <Trans
        id="Enter ID"
        render={({ translation }) => (
          <Field.Text
            name="filter.medicalProgram.databaseId"
            label={<Trans id="Medical program ID" />}
            placeholder={translation}
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <Trans
        id="All statuses"
        render={({ translation }) => (
          <Field.Select
            name="filter.requestAllowed"
            label={<Trans id="Is request allowed" />}
            items={Object.keys(STATUSES.YES_NO)}
            itemToString={(item: boolean) =>
              // @ts-expect-error statuses boolean key
              STATUSES.YES_NO[item] || translation
            }
            variant="select"
            emptyOption
            filterOptions={{
              // @ts-expect-error statuses boolean key
              keys: [(item: boolean) => STATUSES.YES_NO[item]]
            }}
          />
        )}
      />
    </Box>
  </Flex>
);

export { PrimarySearchFields, SecondarySearchFields };
