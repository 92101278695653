import React from "react";
import { ApolloQueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { PositiveIcon, NegativeIcon } from "@edenlabllc/ehealth-icons";
import {
  getFullName,
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { Approval, ApprovalConnection } from "@ehealth/ehealth-ua.schema";

import EmptyData from "../../../components/EmptyData";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Table, { SortingParams } from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import Actions from "./Actions";

type ApprovalsTableProps = {
  personId: string;
  loading: boolean;
  data: { approvals: ApprovalConnection } | undefined;
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  refetch: () => Promise<ApolloQueryResult<{ approvals: ApprovalConnection }>>;
};

const ApprovalsTable = ({
  personId,
  loading,
  data,
  setLocationParams,
  locationParams,
  refetch
}: ApprovalsTableProps) => {
  const { i18n } = useLingui();

  return (
    <LoadingOverlay loading={loading}>
      {isEmpty(data) ||
      isEmpty(data.approvals) ||
      isEmpty(data.approvals.nodes) ? (
        <Box mt={5}>
          <EmptyData mx={5} />
        </Box>
      ) : (
        <>
          <Table
            data={data.approvals.nodes}
            header={{
              databaseId: <Trans>ID</Trans>,
              grantedTo: <Trans>Employee</Trans>,
              grantedResource: <Trans>Forbidden group</Trans>,
              isVerified: <Trans>Verified</Trans>,
              expiresAt: <Trans>Approval expires at</Trans>,
              insertedAt: <Trans>Inserted at</Trans>,
              action: <Trans>Action</Trans>
            }}
            renderRow={({
              authenticationMethodCurrent,
              id,
              databaseId,
              grantedTo,
              grantedResources,
              isVerified,
              expiresAt,
              insertedAt
            }: Approval) => ({
              databaseId,
              grantedTo: getFullName(grantedTo.party),
              grantedResource: grantedResources
                .map((grantedResource) => grantedResource.name)
                .join(", "),
              isVerified: (
                <Flex justifyContent="center">
                  {isVerified ? <PositiveIcon /> : <NegativeIcon />}
                </Flex>
              ),
              expiresAt: dateFormatter(
                i18n.locale,
                DATE_TIME_FORMAT,
                expiresAt
              ),
              insertedAt: dateFormatter(
                i18n.locale,
                DATE_TIME_FORMAT,
                insertedAt
              ),
              action: (
                <Actions
                  isVerified={isVerified}
                  id={id}
                  personId={personId}
                  refetch={refetch}
                  authenticationMethodCurrent={
                    authenticationMethodCurrent || {}
                  }
                  expiresAt={expiresAt}
                />
              )
            })}
            sortableFields={["insertedAt"]}
            sortingParams={parseSortingParams(locationParams.orderBy)}
            onSortingChange={(sortingParams: SortingParams) =>
              setLocationParams({
                ...locationParams,
                orderBy: stringifySortingParams(sortingParams)
              })
            }
            tableName="patientApprovals"
          />
          <Pagination {...data.approvals.pageInfo} />
        </>
      )}
    </LoadingOverlay>
  );
};

export default ApprovalsTable;
