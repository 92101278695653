import React, { useCallback } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import createDecorator from "final-form-calculate";
import { Trans } from "@lingui/macro";
import { Box, Heading, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { RuleEngineRuleConnection } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import { PrimarySearchFields } from "./SearchFields";
import RuleEngineRulesTable from "./RuleEngineRulesTable";

const Search = ({ navigate }: RouteComponentProps) => {
  const onCreateRuleEngineRule = useCallback(() => navigate!("../create"), [
    navigate
  ]);

  return (
    <Ability action="details" resource="rule_engine_rule">
      <Box p={6}>
        <Flex justifyContent="space-between" mb={2}>
          <Box>
            <Heading as="h1" fontWeight="normal" mb={6}>
              <Trans>Rule engine rules</Trans>
            </Heading>
          </Box>
          <Ability action="write" resource="rule_engine_rule">
            <Box>
              <Button variant="green" onClick={onCreateRuleEngineRule}>
                <Trans>Create rules engine rule</Trans>
              </Button>
            </Box>
          </Ability>
        </Flex>
        <LocationParams>
          {({ locationParams, setLocationParams }: TLocationParams) => (
            <>
              <SearchForm
                initialValues={locationParams}
                onSubmit={setLocationParams}
                renderPrimary={PrimarySearchFields}
                decorators={[resetSystemValue]}
              />
              <Query
                fetchPolicy="cache-and-network"
                query={RuleEngineRulesQuery}
                variables={{
                  ...filteredLocationParams(locationParams),
                  orderBy: locationParams.orderBy
                    ? locationParams.orderBy
                    : "INSERTED_AT_DESC"
                }}
              >
                {({
                  loading,
                  data
                }: QueryResult<{
                  ruleEngineRules: RuleEngineRuleConnection;
                }>) => {
                  if (isEmpty(data) || isEmpty(data.ruleEngineRules))
                    return null;
                  const {
                    ruleEngineRules: { nodes: ruleEngineRules = [], pageInfo }
                  } = data;

                  return (
                    <LoadingOverlay loading={loading}>
                      <RuleEngineRulesTable
                        ruleEngineRules={ruleEngineRules}
                        setLocationParams={setLocationParams}
                        locationParams={locationParams}
                      />
                      <Pagination {...pageInfo} />
                    </LoadingOverlay>
                  );
                }}
              </Query>
            </>
          )}
        </LocationParams>
      </Box>
    </Ability>
  );
};

export default Search;

export const RuleEngineRulesQuery = gql`
  query RuleEngineRulesQuery(
    $filter: RuleEngineRuleFilter
    $orderBy: RuleEngineRuleOrderBy
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    ruleEngineRules(
      filter: $filter
      orderBy: $orderBy
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      nodes {
        ...RuleEngineRules
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${RuleEngineRulesTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

const resetSystemValue = createDecorator({
  field: "filter.system",
  updates: {
    "filter.code": () => {
      return undefined;
    }
  }
});
