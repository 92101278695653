import * as React from "react";
import { NavigateFn } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Flex, Text } from "@rebass/emotion";

import { Form, Validation } from "@edenlabllc/ehealth-components";
import {
  Scalars,
  Party,
  Employee,
  LegalEntity,
  DeactivateEmployeeInput,
  Dictionary
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Badge from "../../../components/Badge";
import Button from "../../../components/Button";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import FullName from "../../../components/FullName";
import Popup from "../../../components/Popup";

type HeaderEmployeeProps = {
  id: Scalars["ID"];
  databaseId: Scalars["UUID"];
  party: Party;
  status: Employee["status"];
  employeeType: Employee["employeeType"];
  legalEntityType: LegalEntity["type"];
  navigate: NavigateFn;
};

const HeaderEmployee = ({
  id,
  databaseId,
  party,
  status,
  employeeType,
  legalEntityType,
  navigate
}: HeaderEmployeeProps) => {
  const [isVisible, setVisibilityState] = React.useState(false);
  const toggle = () => setVisibilityState(!isVisible);

  return (
    <Flex justifyContent="space-between" alignItems="flex-end">
      <Box>
        <DefinitionListView
          labels={{
            databaseId: <Trans>ID</Trans>,
            name: <Trans>Name of employee</Trans>,
            status: <Trans>Employee status</Trans>
          }}
          data={{
            databaseId,
            name: <FullName party={party} />,
            status: (
              <Badge name={status} type="EMPLOYEE_STATUS" minWidth={160} />
            )
          }}
          color="#7F8FA4"
          labelWidth="160px"
        />
      </Box>
      <Flex justifyContent="flex-end" flexWrap="wrap">
        {status === "APPROVED" &&
          employeeType !== "OWNER" &&
          legalEntityType === "NHS" && (
            <Button
              mt={2}
              variant="blue"
              onClick={() => navigate(`../update/${id}`)}
            >
              <Trans>Update</Trans>
            </Button>
          )}
        {status !== "DISMISSED" && employeeType !== "OWNER" && (
          <Ability action="deactivate" resource="employee">
            <Mutation mutation={DeactivateEmployee}>
              {(deactivateEmployee: MutationFunction) => (
                <>
                  <Button mt={2} ml={2} onClick={toggle} variant="red">
                    <Trans>Dismiss</Trans>
                  </Button>
                  <Popup
                    visible={isVisible}
                    onCancel={toggle}
                    title={<Trans>Dismiss employee</Trans>}
                    okText={<Trans>Dismiss</Trans>}
                    okButtonProps={{ type: "submit", variant: "green" }}
                    formId="dismissEmployee"
                  >
                    <Form
                      id="dismissEmployee"
                      onSubmit={async ({
                        statusReason
                      }: Partial<DeactivateEmployeeInput>) => {
                        await deactivateEmployee({
                          variables: {
                            input: {
                              id,
                              statusReason
                            }
                          }
                        });
                        toggle();
                      }}
                    >
                      <Text mb={5}>
                        <Trans>
                          Attention! After the dismiss, this action can not be
                          canceled
                        </Trans>
                      </Text>
                      <Box width={1 / 2} mb={3}>
                        <DictionaryValue
                          name="employee_deactivation_reason"
                          render={(dict: Dictionary["values"]) => (
                            <Trans
                              id="Choose dismiss reason"
                              render={({ translation }) => (
                                <Field.Select
                                  name="statusReason"
                                  label={<Trans id="Dismiss reason" />}
                                  placeholder={translation}
                                  items={dict ? Object.keys(dict) : []}
                                  itemToString={(item: string) =>
                                    dict ? dict[item] : translation
                                  }
                                  variant="select"
                                  filterOptions={{
                                    keys: [(item: string) => dict[item]]
                                  }}
                                />
                              )}
                            />
                          )}
                        />
                        <Validation.Required
                          field="statusReason"
                          message="Required field"
                        />
                      </Box>
                    </Form>
                  </Popup>
                </>
              )}
            </Mutation>
          </Ability>
        )}
      </Flex>
    </Flex>
  );
};

HeaderEmployee.fragments = {
  entry: gql`
    fragment HeaderEmployee on Employee {
      databaseId
      status
      employeeType
      party {
        id
        ...FullName
      }
      legalEntity {
        id
        databaseId
        name
        type
        edrData {
          name
        }
      }
    }
    ${FullName.fragments.entry}
  `
};

const DeactivateEmployee = gql`
  mutation DeactivateEmployee($input: DeactivateEmployeeInput!) {
    deactivateEmployee(input: $input) {
      employee {
        id
        status
      }
    }
  }
`;

export default HeaderEmployee;
