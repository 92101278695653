export const getErrorCode = (error: $TSFixMe) =>
  error.graphQLErrors &&
  error.graphQLErrors[0].extensions &&
  error.graphQLErrors[0].extensions.code &&
  error.graphQLErrors[0].extensions.code;

export const getErrorMessage = (error: $TSFixMe) =>
  (error.graphQLErrors &&
    error.graphQLErrors[0].extensions.exception &&
    error.graphQLErrors[0].extensions.exception.inputErrors[0].message) ||
  (error.graphQLErrors && error.graphQLErrors[0].message) ||
  error.message;
