import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Flex, Heading } from "@rebass/emotion";
import { isEmpty } from "lodash";

import { LocationParams } from "@edenlabllc/ehealth-components";
// @ts-expect-error types mismatch
import { MedicalcompositionConnection } from "@ehealth/ehealth-ua.schema";

import SearchForm, { TLocationParams } from "../../../components/SearchForm";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import UnpocessableEntityModalError from "../../../components/UnpocessableEntityModalError";

import convertToQueryParams from "../../../helpers/convertToQueryParams";
import filteredLocationParams from "../../../helpers/filteredLocationParams";
import { getErrorMessage } from "../../../helpers/errorHelpers";

import MedicalCompositionTable from "./MedicalCompositionTable";
import { PrimarySearchFields, SecondarySearchFields } from "./SearchFields";

const Search = (_props: RouteComponentProps) => {
  const { i18n } = useLingui();
  const [errorState, setErrorState] = useState<null | React.ReactElement>(null);

  return (
    <Box p={6}>
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => {
          const filteredParams = filteredLocationParams(locationParams);
          const filter = filteredParams.filter;
          const queryParams = convertToQueryParams(filter, {
            compositionStatus: "status"
          });
          const canQuery = !!(filter.title || filter.subject || filter.focus);

          return (
            <>
              <Flex
                justifyContent="space-between"
                alignItems="flex-start"
                mb={3}
              >
                <Box>
                  <Heading as="h1" fontWeight="normal" mb={4}>
                    <Trans>Medical composition</Trans>
                  </Heading>
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="flex-start"
                mb={3}
              >
                <Box>
                  <Heading as="h2" fontWeight="normal" mb={5}>
                    <Trans>Medical composition search</Trans>
                  </Heading>
                </Box>
              </Flex>
              <SearchForm
                initialValues={filteredParams}
                onSubmit={setLocationParams}
                renderPrimary={PrimarySearchFields}
                renderSecondary={SecondarySearchFields}
              />
              <Query
                query={MedicalCompositionQuery}
                fetchPolicy="network-only"
                skip={!canQuery}
                variables={{
                  after: 0,
                  first: 10,
                  ...filteredParams,
                  query: queryParams
                }}
              >
                {({
                  loading,
                  error,
                  data
                }: QueryResult<{
                  medicalComposition: MedicalcompositionConnection;
                }>) => {
                  const {
                    medicalComposition: {
                      items: medicalComposition = [],
                      hasNext = false
                    } = {}
                  } = data || {};
                  const offset = +(filteredParams.after || 0);
                  const limit = filteredParams.first;
                  if ((!errorState && error) || (!error && errorState)) {
                    setErrorState(
                      error ? (
                        <Trans>
                          Something went wrong. Please try again later
                        </Trans>
                      ) : null
                    );
                  }

                  const pageInfo = {
                    offset: true,
                    startCursor: String(offset),
                    endCursor: String(offset + limit),
                    hasPreviousPage: offset > 0,
                    hasNextPage: hasNext
                  };
                  if (isEmpty(medicalComposition)) return null;

                  return (
                    <LoadingOverlay loading={loading}>
                      <MedicalCompositionTable
                        locationParams={filteredParams}
                        setLocationParams={setLocationParams}
                        medicalComposition={medicalComposition}
                      />
                      <Pagination {...pageInfo} />
                      {error && (
                        <UnpocessableEntityModalError
                          errorMessage={getErrorMessage(error)}
                          isModalOpen
                        />
                      )}
                    </LoadingOverlay>
                  );
                }}
              </Query>
              {errorState && (
                <UnpocessableEntityModalError
                  errorMessage={i18n._(
                    t`Something went wrong. Please try again later`
                  )}
                  isModalOpen
                />
              )}
            </>
          );
        }}
      </LocationParams>
    </Box>
  );
};

export default Search;

const MedicalCompositionQuery = gql`
  query MedicalCompositionQuery($first: Int!, $after: Int!, $query: String!) {
    medicalComposition(offset: $after, limit: $first, query: $query)
      @rest(
        type: "SearchPayload"
        path: "/admin/composition/search?offset=:offset&limit=:limit&:query"
      ) {
      items
      hasNext
    }
  }
`;
