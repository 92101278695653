import {
  HealthcareServiceAvailableTime,
  Maybe
} from "@ehealth/ehealth-ua.schema";

export const convertAvailableTime = (
  data: Maybe<HealthcareServiceAvailableTime>[]
) =>
  data.reduce((acc: $TSFixMe, item: Maybe<HealthcareServiceAvailableTime>) => {
    const { daysOfWeek, allDay, availableStartTime, availableEndTime } =
      item || {};

    return (
      daysOfWeek &&
      daysOfWeek.reduce((acc: $TSFixMe, day: string) => {
        const { [day]: prevDayItem = {} } = acc;

        if (allDay) {
          return { ...acc, [day]: { ...prevDayItem, allDay } };
        } else {
          const { availableTime: prevAvailableTime = [] } = prevDayItem;
          const availableTime = prevAvailableTime.concat({
            availableStartTime,
            availableEndTime
          });

          return { ...acc, [day]: { ...prevDayItem, availableTime } };
        }
      }, acc)
    );
  }, {});
