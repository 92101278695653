export const MEDICAL_COMPOSITION_NUMBER = [
  /\w/,
  /\w/,
  /\w/,
  /\w/,
  "-",
  /\w/,
  /\w/,
  /\w/,
  /\w/,
  "-",
  /\w/,
  /\w/,
  /\w/,
  /\w/,
  "-",
  /\w/,
  /\w/,
  /\w/,
  /\w/
];
