import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";

import Ability from "../../components/Ability";
import Notification from "../../components/Notification";
import OnRouteChange from "../../components/OnRouteChange";
import Steps from "../../components/Steps";

import SearchLEStep from "./Steps/SearchLEStep";
import ReorganizationData from "./Steps/ReorganizationData";
import SuccessorLegalEntity from "./Steps/SuccessorLegalEntity";
import Confirm from "./Steps/Confirm";

const LegalEntitiesReorganizations = ({
  // @ts-expect-error location state
  location: { state, pathname }
}: RouteComponentProps) => (
  <Ability actions={["merge"]} resource="legal_entity">
    {pathname.includes("confirm") && (
      <Box m={-2}>
        <Notification variant="red">
          <Trans>
            Warning! Legal entities reorganization is an irreversible procedure,
            be sure to check the correctness of the data entered before
            confirmation
          </Trans>
        </Notification>
      </Box>
    )}
    {state.reorganizationType === "DIVIDING" &&
      pathname.includes("successor-legal-entity") && (
        <Box m={-2}>
          <Notification variant="orange">
            <Trans>
              For dividing reorganization type two or more successor legal
              entities needs to be selected
            </Trans>
          </Notification>
        </Box>
      )}
    <Box p={6}>
      <Steps.List>
        <Steps.Item to="./" state={state}>
          <Trans>Find Legal entity</Trans>
        </Steps.Item>
        <Steps.Item to="reorganization-data" disabled>
          <Trans>Specify the Reorganization Data</Trans>
        </Steps.Item>
        <Steps.Item to="./successor-legal-entity" disabled>
          <Trans>Find Successor Legal Entity</Trans>
        </Steps.Item>
        <Steps.Item to="./confirm" disabled>
          <Trans>approve by EDS</Trans>
        </Steps.Item>
      </Steps.List>
      <Router>
        <SearchLEStep path="/" />
        <ReorganizationData path="reorganization-data" />
        <SuccessorLegalEntity path="successor-legal-entity" />
        <Confirm path="confirm" />
      </Router>
      <OnRouteChange />
    </Box>
  </Ability>
);

export default LegalEntitiesReorganizations;
