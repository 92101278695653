import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { LegalEntityReorganizationJob } from "@ehealth/ehealth-ua.schema";

import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import Tabs from "../../../components/Tabs";
import { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import Tasks from "./Tasks";

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

const Details = ({ id }: DetailsProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => (
      <Query
        query={LegalEntityMergeJobQuery}
        variables={{ id, ...filteredLocationParams(locationParams) }}
      >
        {({
          loading,
          data
        }: QueryResult<{
          legalEntityReorganizationJob: LegalEntityReorganizationJob;
        }>) => {
          if (isEmpty(data) || isEmpty(data.legalEntityReorganizationJob))
            return null;
          const {
            databaseId,
            reorganizedLegalEntity,
            status,
            reorganizationType,
            tasks
          } = data.legalEntityReorganizationJob;

          return (
            <LoadingOverlay loading={loading}>
              <Box p={6}>
                <Box py={10}>
                  <Breadcrumbs.List>
                    <Breadcrumbs.Item to="/legal-entity-reorganization-jobs">
                      <Trans>Legal entity reorganization jobs</Trans>
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item>
                      <Trans>Job details</Trans>
                    </Breadcrumbs.Item>
                  </Breadcrumbs.List>
                </Box>
                <DefinitionListView
                  labels={{
                    databaseId: <Trans>Job ID</Trans>,
                    reorganizedLegalEntityName: (
                      <Trans>Reorganized Legal Entity name</Trans>
                    ),
                    reorganizedLegalEntityEdrpou: (
                      <Trans>Reorganized Legal Entity edrpou</Trans>
                    ),
                    reorganizationType: <Trans>Reorganization Type</Trans>,
                    status: <Trans>Status</Trans>
                  }}
                  data={{
                    databaseId,
                    reorganizedLegalEntityName: reorganizedLegalEntity.name,
                    reorganizedLegalEntityEdrpou: reorganizedLegalEntity.edrpou,
                    reorganizationType: (
                      <DictionaryValue
                        name="LEGAL_ENTITY_REORGANIZATION_TYPES"
                        item={reorganizationType}
                      />
                    ),
                    status: <Badge type="JOBS" name={status} minWidth={100} />
                  }}
                  color="#7F8FA4"
                  labelWidth="250px"
                />
              </Box>
              <Tabs.Nav>
                <Tabs.NavItem to="./">
                  <Trans>Job details</Trans>
                </Tabs.NavItem>
              </Tabs.Nav>
              <Tabs.Content>
                <Router>
                  <Tasks
                    path="/"
                    tasks={tasks}
                    locationParams={locationParams}
                    setLocationParams={setLocationParams}
                  />
                </Router>
              </Tabs.Content>
            </LoadingOverlay>
          );
        }}
      </Query>
    )}
  </LocationParams>
);

const LegalEntityMergeJobQuery = gql`
  query LegalEntityReorganizationJobQuery(
    $id: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: TaskFilter
    $orderBy: TaskOrderBy
  ) {
    legalEntityReorganizationJob(id: $id) {
      databaseId
      reorganizationType
      status
      reorganizedLegalEntity {
        edrpou
        name
      }
      tasks(
        first: $first
        last: $last
        before: $before
        after: $after
        filter: $filter
        orderBy: $orderBy
      ) {
        nodes {
          ...Task
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${Tasks.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Details;
