import React from "react";
import { Flex, Box } from "@rebass/emotion";
import * as Field from "../../../components/Field";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { Dictionary } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";

const PrimarySearchFields = () => {
  const { i18n } = useLingui();
  return (
    <Flex>
      <Box width={1 / 3} mt={4}>
        <DictionaryValue name="HISTORY_RESOURCE_TYPES">
          {(dict: Dictionary["values"]) => (
            <Field.Select
              name="filter.resourceType"
              label={<Trans id="Resource Type" />}
              placeholder={i18n._(t`Select resource type`)}
              items={Object.keys(dict)}
              itemToString={(item: string) => dict[item] || item}
              variant="select"
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </DictionaryValue>
      </Box>
    </Flex>
  );
};

export { PrimarySearchFields };
