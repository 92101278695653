var formatWorkingHours = function formatWorkingHours(weekDays, workingHours) {
  var sortedWorkingDays = weekDays.map(function (_ref) {
    var key = _ref.key,
      value = _ref.value;
    return {
      day: value,
      hours: workingHours[key]
    };
  }).filter(function (_ref2) {
    var day = _ref2.day,
      hours = _ref2.hours;
    return hours;
  });
  return sortedWorkingDays;
};
export default formatWorkingHours;