import React, { useState } from "react";
import { Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import system from "@edenlabllc/ehealth-system-components";
import { Modal } from "@edenlabllc/ehealth-components";
import { EhealthLogoIcon, CloseIcon } from "@edenlabllc/ehealth-icons";

import Button from "./Button";

type UnpocessableEntityModalErrorProps = {
  errorMessage: string;
  isModalOpen: boolean;
  handleClearError?: () => void;
};

const UnpocessableEntityModalError = ({
  errorMessage,
  isModalOpen,
  handleClearError
}: UnpocessableEntityModalErrorProps) => {
  const [isVisible, setVisibility] = useState(isModalOpen);
  const handleClose = () => {
    setVisibility(false);
    if (handleClearError) {
      handleClearError();
    }
  };
  if (!isVisible) return null;

  return (
    <Modal width={760} px={76} py={32} placement="center" backdrop>
      <CloseButton onClick={handleClose}>
        <CloseIcon width={15} height={15} />
      </CloseButton>
      <Wrapper>
        <EhealthLogoIcon height="80" />
        <ErrorTitle weight="bold">
          <Trans>Error occured. Try again later</Trans>
        </ErrorTitle>
        <ErrorDetails>{errorMessage}</ErrorDetails>
        <Flex justifyContent="center" mt={3}>
          <Button variant="blue" mx={2} onClick={handleClose}>
            <Trans>Close</Trans>
          </Button>
        </Flex>
      </Wrapper>
    </Modal>
  );
};

const CloseButton = system(
  {},
  {
    position: "absolute",
    top: 30,
    right: 30,
    width: 15,
    height: 15
  }
);

const Wrapper = system(
  {
    extend: Flex
  },
  {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
);

const ErrorTitle = system(
  {
    is: "h2",
    mt: 50,
    mb: 40,
    fontWeight: 200
  },
  "space",
  "fontWeight"
);

const ErrorDetails = system(
  {
    extend: Flex,
    p: 2,
    m: 2
  },
  {
    background: "#f9f9f9",
    fontFamily: "monospace",
    justifyContent: "center",
    border: "1px solid #ddd"
  },
  "space"
);

export default UnpocessableEntityModalError;
