import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { getFullName } from "@edenlabllc/ehealth-utils";
import { GlobalBudgetContract } from "@ehealth/ehealth-ua.schema";

import DefinitionListView from "../../../components/DefinitionListView";
import Line from "../../../components/Line";
import Link from "../../../components/Link";

type LegalEntityGlobalBudgetProps = RouteComponentProps & {
  contractorBase: GlobalBudgetContract["contractorBase"];
  contractorOwner: GlobalBudgetContract["contractorOwner"];
  contractorPaymentDetails: GlobalBudgetContract["contractorPaymentDetails"];
  contractorLegalEntity: GlobalBudgetContract["contractorLegalEntity"];
};

const LegalEntityGlobalBudget = ({
  contractorBase,
  contractorOwner,
  contractorPaymentDetails: { bankName, mfo, payerAccount },
  contractorLegalEntity: {
    databaseId: legalEntityDatabaseId,
    id: legalEntityId,
    edrpou,
    edrData
  }
}: LegalEntityGlobalBudgetProps) => {
  const { name, registrationAddress } = edrData || {};
  const { zip, country, address } = registrationAddress || {};
  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          edrpou: <Trans>EDRPOU</Trans>,
          name: <Trans>Name</Trans>,
          addresses: <Trans>Address</Trans>
        }}
        data={{
          name,
          edrpou,
          addresses: (
            <>
              {zip}, {country}, {address}
            </>
          )
        }}
      />
      <DefinitionListView
        color="blueberrySoda"
        labels={{
          legalEntityId: <Trans>Legal entity ID</Trans>
        }}
        data={{
          legalEntityId: (
            <Link to={`/legal-entities/${legalEntityId}`}>
              {legalEntityDatabaseId}
            </Link>
          )
        }}
      />
      <Line />
      <DefinitionListView
        labels={{
          fullName: <Trans>Subscriber Name</Trans>,
          contractorBase: <Trans>Based on</Trans>
        }}
        data={{
          fullName: contractorOwner && getFullName(contractorOwner.party),
          contractorBase: contractorBase
        }}
      />
      <DefinitionListView
        color="blueberrySoda"
        labels={{
          ownerId: <Trans>Signer ID</Trans>
        }}
        data={{
          ownerId: contractorOwner && contractorOwner.databaseId
        }}
      />
      <Line />
      <DefinitionListView
        labels={{
          bankName: <Trans>Bank</Trans>,
          mfo: <Trans>Bank Code (MFO)</Trans>,
          payerAccount: <Trans>Account number</Trans>
        }}
        data={{
          bankName,
          mfo,
          payerAccount
        }}
      />
    </Box>
  );
};

export default LegalEntityGlobalBudget;

LegalEntityGlobalBudget.fragments = {
  entry: gql`
    fragment LegalEntityGlobalBudget on GlobalBudgetContract {
      id
      contractorBase
      contractorPaymentDetails {
        bankName
        mfo
        payerAccount
      }
      contractorOwner {
        id
        databaseId
        party {
          id
          firstName
          lastName
          secondName
        }
      }
    }
  `
};
