import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  Observation as TObservation,
  ReferenceRange
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import CodeableConcept from "../../../components/CodeableConcept";
import Collapsable from "../../../components/Collapsable";
import dateFormatter from "../../../helpers/dateFormatter";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import ExpandableText from "../../../components/ExpandableText";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Line from "../../../components/Line";
import Table from "../../../components/Table";

import paramToBase64 from "../../../helpers/paramToBase64";
import STATUSES from "../../../helpers/statuses";
import { MonitoringDetailsPageProps } from "../../../helpers/types";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import CreateConclusion from "../../ClinicalMonitoring/CreateConclusion";
import getConclusionData from "../../ClinicalMonitoring/getConclusionData";
import Conclusions from "../../ClinicalMonitoring/Conclusions";
import DetailsPageBreadcrumbs from "./DetailsPageBreadcrumbs";
import CodeableConceptList from "./CodeableConceptList";
import Range from "./Range";
import Reference from "./Reference";
import ReferenceList from "./ReferenceList";
import IntervalOrOpenDate from "./IntervalOrOpenDate";
import Value from "./Value";
import Quantity from "./Quantity";
import { LABEL_WIDTH } from "./Episode";

const Observation = ({
  query,
  queryVariables,
  itemId,
  id,
  patientId,
  isClinicalMonitoring = false
}: MonitoringDetailsPageProps) => {
  const { i18n } = useLingui();

  return (
    <Ability
      action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
      resource="observation"
    >
      <Box p={6}>
        <DetailsPageBreadcrumbs
          id={id}
          currentStepName={<Trans>Observation details</Trans>}
          isClinicalMonitoring={isClinicalMonitoring}
        />
        <Query
          query={query}
          fetchPolicy="network-only"
          variables={{
            ...queryVariables,
            id: itemId,
            ...(isClinicalMonitoring && { patientId })
          }}
        >
          {({
            loading,
            error,
            data = {}
          }: QueryResult<{ [key: string]: TObservation }>) => {
            const observation = isClinicalMonitoring
              ? data.impersonalObservation
              : data.observation;

            return (
              <LoadingOverlay loading={loading}>
                <Box mt={4}>
                  {!isEmpty(data) && !isEmpty(observation) && !error && (
                    <>
                      <Flex
                        justifyContent="space-between"
                        alignItems="flex-end"
                      >
                        <div>
                          <DefinitionListView
                            labels={{
                              databaseId: <Trans>ID</Trans>,
                              status: <Trans>Status</Trans>
                            }}
                            data={{
                              ...observation,
                              status: (
                                <DictionaryValue
                                  name="eHealth/observation_statuses"
                                  item={observation.status.toLowerCase()}
                                />
                              )
                            }}
                            color="#7F8FA4"
                            labelWidth={LABEL_WIDTH}
                          />
                        </div>
                        {isClinicalMonitoring && (
                          <CreateConclusion
                            initialData={getConclusionData(
                              patientId!,
                              "observation",
                              observation.databaseId
                            )}
                          />
                        )}
                      </Flex>
                      <Line />
                      <Box mt={4}>
                        <DefinitionListView
                          fontSize={14}
                          labels={{
                            ...(observation.categories && {
                              categories: <Trans>Categories</Trans>
                            }),
                            ...(!isEmpty(observation.basedOn) && {
                              basedOn: (
                                <Trans id="Observation based on">
                                  Based on
                                </Trans>
                              )
                            }),
                            issued: <Trans>Issued</Trans>,
                            primarySource: <Trans>Primary source</Trans>,
                            ...(!isEmpty(observation.reportOrigin) && {
                              reportOrigin: <Trans>Report origin</Trans>
                            }),
                            ...(!isEmpty(observation.code) && {
                              code: <Trans>Code</Trans>
                            }),
                            ...(!isEmpty(observation.interpretation) && {
                              interpretation: <Trans>Interpretation</Trans>
                            }),
                            ...(observation.comment && {
                              comment: <Trans>Comment</Trans>
                            }),
                            ...(observation.bodySite && {
                              bodySite: <Trans>Body site</Trans>
                            }),
                            ...(observation.method && {
                              method: <Trans>Method</Trans>
                            })
                          }}
                          data={{
                            ...observation,
                            categories: (
                              <CodeableConceptList
                                codeableConceptList={observation.categories}
                              />
                            ),
                            ...(!isEmpty(observation.basedOn) && {
                              basedOn: (
                                <ReferenceList
                                  referencesList={observation.basedOn}
                                  pathSlug={
                                    isClinicalMonitoring
                                      ? `../../../service-request/${patientId}`
                                      : "../../service-request"
                                  }
                                  entityName="ServiceRequest"
                                />
                              )
                            }),
                            issued: dateFormatter(
                              i18n.locale,
                              DATE_TIME_FORMAT,
                              observation.issued
                            ),
                            primarySource: (
                              <Box>
                                {/* @ts-expect-error statuses boolean key */}
                                {STATUSES.YES_NO[observation.primarySource]}
                              </Box>
                            ),
                            ...(!isEmpty(observation.reportOrigin) && {
                              reportOrigin: (
                                <CodeableConcept
                                  codeableConcept={observation.reportOrigin}
                                />
                              )
                            }),
                            ...(!isEmpty(observation.code) && {
                              code: (
                                <CodeableConcept
                                  codeableConcept={observation.code}
                                  withCode
                                />
                              )
                            }),
                            ...(!isEmpty(observation.interpretation) && {
                              interpretation: (
                                <CodeableConcept
                                  codeableConcept={observation.interpretation}
                                />
                              )
                            }),
                            ...(!isEmpty(observation.bodySite) && {
                              bodySite: (
                                <CodeableConcept
                                  codeableConcept={observation.bodySite}
                                />
                              )
                            }),
                            ...(!isEmpty(observation.method) && {
                              method: (
                                <CodeableConcept
                                  codeableConcept={observation.method}
                                />
                              )
                            })
                          }}
                          labelWidth={LABEL_WIDTH}
                        />
                      </Box>
                      {!isEmpty(observation.context) && (
                        <Box mt={2}>
                          <Reference
                            fontSize={14}
                            header={<Trans>Context</Trans>}
                            linkPath={
                              isClinicalMonitoring
                                ? `../../../encounter/${patientId}/${paramToBase64(
                                    "Encounter",
                                    observation.context.identifier.value
                                  )}`
                                : `../../encounter/${paramToBase64(
                                    "Encounter",
                                    observation.context.identifier.value
                                  )}`
                            }
                            linkDisplayValue={
                              observation.context.displayValue
                                ? observation.context.displayValue
                                : observation.context.identifier.value
                            }
                          />
                        </Box>
                      )}
                      {!isEmpty(observation.effective) && (
                        <Box mt={2}>
                          <IntervalOrOpenDate
                            intervalOrOpenDate={observation.effective}
                            header={<Trans>Effective at </Trans>}
                          />
                        </Box>
                      )}
                      {!isEmpty(observation.performer) && (
                        <Box mt={2}>
                          <Reference
                            fontSize={14}
                            header={<Trans>Performer</Trans>}
                            linkPath={`/employees/${paramToBase64(
                              "Employee",
                              observation.performer.identifier.value
                            )}`}
                            linkDisplayValue={
                              observation.performer.displayValue
                                ? observation.performer.displayValue
                                : observation.performer.identifier.value
                            }
                          />
                        </Box>
                      )}
                      {!isEmpty(observation.value) && (
                        <Value value={observation.value} />
                      )}
                      {!isEmpty(observation.referenceRanges) && (
                        <Box mt={2}>
                          <Text
                            fontSize={1}
                            fontWeight="bold"
                            color="darkCharcoal"
                          >
                            <Trans>Reference ranges</Trans>
                          </Text>
                          <ReferenceRanges
                            referenceRangesList={observation.referenceRanges}
                          />
                        </Box>
                      )}
                      <Line />
                      {!isEmpty(observation.components) && (
                        <>
                          <Collapsable
                            title={
                              <Text
                                fontSize={1}
                                fontWeight="bold"
                                color="darkCharcoal"
                              >
                                <Trans>Components</Trans>
                              </Text>
                            }
                          >
                            <Components
                              components={observation.components}
                              headLess
                            />
                          </Collapsable>
                          <Line />
                        </>
                      )}
                      <Box mt={2}>
                        <DefinitionListView
                          labels={{
                            insertedAt: <Trans>Inserted at</Trans>,
                            updatedAt: <Trans>Updated at</Trans>
                          }}
                          data={{
                            insertedAt: dateFormatter(
                              i18n.locale,
                              DATE_TIME_FORMAT,
                              observation.insertedAt
                            ),
                            updatedAt: dateFormatter(
                              i18n.locale,
                              DATE_TIME_FORMAT,
                              observation.updatedAt
                            )
                          }}
                          labelWidth={LABEL_WIDTH}
                          fontSize={14}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </LoadingOverlay>
            );
          }}
        </Query>
        <Conclusions
          isClinicalMonitoring={isClinicalMonitoring}
          patientId={patientId!}
          itemId={itemId!}
        />
      </Box>
    </Ability>
  );
};

export default Observation;

const FULL_SCREEN_HEIGHT_LIMIT = 2000;

type ReferenceRangesProps = {
  referenceRangesList: TObservation["referenceRanges"];
};

const ReferenceRanges = ({ referenceRangesList }: ReferenceRangesProps) => {
  if (isEmpty(referenceRangesList)) return null;

  return (
    <Box mt={-4}>
      <Table
        hidePagination
        hideControls
        data={referenceRangesList}
        header={{
          age: <Trans>Age</Trans>,
          high: <Trans>High</Trans>,
          low: <Trans>Low</Trans>,

          appliesTo: <Trans>Applies to</Trans>,
          text: <Trans>Text</Trans>,
          type: <Trans>Type</Trans>
        }}
        renderRow={({
          age,
          high,
          low,
          appliesTo,
          type,
          text,
          ...restData
        }: ReferenceRange) => ({
          ...restData,
          age: <Range range={age} />,
          high: <Quantity quantity={high} />,
          low: <Quantity quantity={low} />,
          appliesTo: <CodeableConceptList codeableConceptList={appliesTo} />,
          type: <CodeableConcept codeableConcept={type} />,
          text: (
            <ExpandableText
              text={text || ""}
              fixedAlign
              fullScreenHeight={
                text ? text.length > FULL_SCREEN_HEIGHT_LIMIT : false
              }
            />
          )
        })}
      />
    </Box>
  );
};

ReferenceRanges.fragments = {
  entry: gql`
    fragment ReferenceRanges on ReferenceRange {
      age {
        ...Range
      }
      high {
        ...Quantity
      }
      low {
        ...Quantity
      }
      appliesTo {
        ...CodeableConcept
      }
      text
      type {
        ...CodeableConcept
      }
    }
    ${Range.fragments.entry}
    ${CodeableConcept.fragments!.entry}
  `
};

type ComponentsProps = {
  components: TObservation["components"];
  headLess: boolean;
};

export const Components = ({
  components
}: // header
ComponentsProps) => {
  if (isEmpty(components)) return null;

  return (
    <>
      {components!.map((component, index) => {
        const { code, interpretation, value, referenceRanges } =
          component || {};

        return (
          <Box key={index} ml={5} mt={index === 0 ? 2 : 50}>
            <Box mt={2}>
              <DefinitionListView
                labels={{
                  ...(code && { code: <Trans>Code</Trans> }),
                  ...(interpretation && {
                    interpretation: <Trans>Interpretation</Trans>
                  })
                }}
                data={{
                  code: <CodeableConcept codeableConcept={code} />,
                  interpretation: (
                    <CodeableConcept codeableConcept={interpretation} />
                  )
                }}
                labelWidth={LABEL_WIDTH}
                fontSize={14}
              />
            </Box>
            <Value value={value} />
            {!isEmpty(referenceRanges) && (
              <Box mt={2}>
                <Text fontSize={1} fontWeight="bold" color="darkCharcoal">
                  <Trans>Reference ranges</Trans>
                </Text>
                <ReferenceRanges referenceRangesList={referenceRanges} />
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
};

Components.fragments = {
  entry: gql`
    fragment Components on Component {
      code {
        ...CodeableConcept
      }
      interpretation {
        ...CodeableConcept
      }
      referenceRanges {
        ...ReferenceRanges
      }
      value {
        ...Value
      }
    }
    ${CodeableConcept.fragments!.entry}
    ${ReferenceRanges.fragments.entry}
    ${Value.fragments.entry}
  `
};

Observation.fragments = {
  entry: gql`
    fragment Observation on Observation {
      id
      databaseId
      status
      basedOn {
        ...Reference
      }
      categories {
        ...CodeableConcept
      }
      context {
        ...Reference
      }
      effective {
        ...IntervalOrOpenDate
      }
      issued
      primarySource
      performer {
        ...Reference
      }
      reportOrigin {
        ...CodeableConcept
      }
      code {
        ...CodeableConcept
      }
      bodySite {
        ...CodeableConcept
      }
      method {
        ...CodeableConcept
      }
      components {
        ...Components
      }
      insertedAt
      updatedAt
      interpretation @skip(if: $skipAdditionalFields) {
        ...CodeableConcept
      }
      value @skip(if: $skipAdditionalFields) {
        ...Value
      }
      comment @skip(if: $skipAdditionalFields)
      referenceRanges @skip(if: $skipAdditionalFields) {
        ...ReferenceRanges
      }
    }
    ${CodeableConcept.fragments!.entry}
    ${Reference.fragments.entry}
    ${IntervalOrOpenDate.fragments.entry}
    ${Value.fragments.entry}
    ${ReferenceRanges.fragments.entry}
    ${Components.fragments.entry}
  `
};

export const ObservationByIDQuery = gql`
  query ObservationByIDQuery(
    $id: ID!
    $personId: ID!
    $skipAdditionalFields: Boolean!
  ) {
    observation(id: $id, personId: $personId) {
      ...Observation
    }
  }
  ${Observation.fragments.entry}
`;
