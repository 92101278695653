import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { DocumentNode, MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";

import Button from "../../../components/Button";
import Popup from "../../../components/Popup";
import { SearchParams } from "../../../components/SearchForm";

import { ActionType } from "../../../helpers/types";

type UpdateServiceGroupPopupProps = {
  id: string;
  name: string;
  requestAllowed: boolean;
  locationParams: SearchParams;
  serviceGroupDetailsQuery: DocumentNode;
};

const UpdateServiceGroupPopup = ({
  id,
  name,
  requestAllowed,
  locationParams,
  serviceGroupDetailsQuery
}: UpdateServiceGroupPopupProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  const action: ActionType = requestAllowed
    ? {
        name: <Trans>Disallow Request</Trans>,
        description: <Trans>Disallow Request for group</Trans>,
        variant: "orange"
      }
    : {
        name: <Trans>Allow Request</Trans>,
        description: <Trans>Allow Request for group</Trans>,
        variant: "green"
      };

  return (
    <Mutation
      mutation={UpdateServiceGroupMutation}
      refetchQueries={() => [
        {
          query: serviceGroupDetailsQuery,
          variables: { id, ...locationParams }
        }
      ]}
    >
      {(updateServiceGroup: MutationFunction) => (
        <>
          <Button onClick={toggle} variant={action.variant}>
            {action.name}
          </Button>
          <Popup
            okButtonProps={{ type: "submit", variant: action.variant }}
            visible={isPopupVisible}
            onCancel={toggle}
            title={
              <>
                {action.description} "{name}
                "?
              </>
            }
            okText={action.name}
            onOk={async () => {
              await updateServiceGroup({
                variables: {
                  input: {
                    id,
                    requestAllowed: !requestAllowed
                  }
                }
              });
              toggle();
            }}
          />
        </>
      )}
    </Mutation>
  );
};

const UpdateServiceGroupMutation = gql`
  mutation UpdateServiceGroupMutation($input: UpdateServiceGroupInput!) {
    updateServiceGroup(input: $input) {
      serviceGroup {
        id
      }
    }
  }
`;

export default UpdateServiceGroupPopup;
