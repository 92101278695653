import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";
import { Trans } from "@lingui/macro";
import { Box, Heading } from "@rebass/emotion";

import { Tabs } from "@edenlabllc/ehealth-components";

import Ability from "../../../components/Ability";
import OnRouteChange from "../../../components/OnRouteChange";

import { ManualRulesTab } from "./ManualRulesTab";
import { DRACSTab } from "./DRACSTab";

const PersonsVerification = ({ uri }: RouteComponentProps) => (
  <Ability action="verify" resource="person">
    <Box p={6}>
      <Heading as="h1" fontWeight="normal" mb={6}>
        <Trans>Verification of patients</Trans>
      </Heading>

      <Tabs.Nav justifyContent="center">
        <Tabs.Link to="./manual-rules">
          <Trans>Manual rules</Trans>
        </Tabs.Link>
        <Tabs.Link to="./dracs">
          <Trans>DRACS</Trans>
        </Tabs.Link>
      </Tabs.Nav>
      <Router>
        <Redirect from="/" to={`/${uri}/manual-rules`} />
        <ManualRulesTab path="manual-rules" />
        <DRACSTab path="dracs" />
      </Router>
      <OnRouteChange />
    </Box>
  </Ability>
);

export default PersonsVerification;
