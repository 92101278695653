import React, { useEffect } from "react";

const STYLES = {
  WebkitUserSelect: "none",
  WebkitTouchCallout: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none"
};

const KEY_CODE_C = 67;
const KEY_CODE_I = 73;
const KEY_CODE_J = 74;
const KEY_CODE_F12 = 123;

type ContentCopyProtectionProps = {
  children: React.ReactNode;
};

export const ContentCopyProtection = ({
  children
}: ContentCopyProtectionProps) => {
  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());

    const copyFunc = (event: $TSFixMe) => {
      event.clipboardData.setData(
        "text/plain",
        "Copying is not allowed on this webpage"
      );
      event.preventDefault();
    };
    document.addEventListener("copy", copyFunc, false);

    const keydownFunc = (event: $TSFixMe) => {
      const key = event.which || event.key || event.keyCode || event.charCode;

      // Keyboard shortcuts for opening DevTools in Chrome:
      if (key === KEY_CODE_F12) {
        return event.preventDefault();
      } else if (
        // [Windows / Linux] Control+Shift+I
        (event.ctrlKey && event.shiftKey && key === KEY_CODE_I) ||
        // [Windows / Linux] Control+Shift+J
        (event.ctrlKey && event.shiftKey && key === KEY_CODE_J) ||
        // [Windows / Linux] Control+Shift+C
        (event.ctrlKey && event.shiftKey && key === KEY_CODE_C)
      ) {
        return event.preventDefault();
      } else if (
        // [Mac] Command+Shift+C
        (event.metaKey && event.shiftKey && key === KEY_CODE_C) ||
        // [Mac] Command+Option+C
        (event.metaKey && event.altKey && key === KEY_CODE_C) ||
        // [Mac] Command+Option+I
        (event.metaKey && event.altKey && key === KEY_CODE_I) ||
        // [Mac] Command+Option+J
        (event.metaKey && event.altKey && key === KEY_CODE_J)
      ) {
        return event.preventDefault();
      }
    };
    document.addEventListener("keydown", keydownFunc, false);

    return () => {
      document.removeEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
      document.removeEventListener("copy", copyFunc);
      document.removeEventListener("keydown", keydownFunc);
    };
  }, []);

  // @ts-expect-error TS(2322): Type '{ WebkitUserSelect: string; WebkitTouchCallo... Remove this comment to see the full error message
  return <div style={STYLES}>{children}</div>;
};
