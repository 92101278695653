import React from "react";
import { gql } from "graphql-tag";
import { Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  ProgramService,
  ProgramServiceConnection
} from "@ehealth/ehealth-ua.schema";

import Badge from "../../../components/Badge";
import Link from "../../../components/Link";
import Price from "../../../components/Price";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type ProgramServicesTableProps = {
  programServices: ProgramServiceConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  tableName?: string;
};

const ProgramServicesTable = ({
  programServices,
  locationParams,
  setLocationParams,
  tableName = "program-services-table/search"
}: ProgramServicesTableProps) => (
  <Table
    data={programServices}
    header={{
      databaseId: <Trans>Participant ID</Trans>,
      medicalProgramId: <Trans>Medical program ID</Trans>,
      medicalProgram: <Trans>Medical program</Trans>,
      service: <Trans>Service</Trans>,
      serviceGroup: <Trans>Service group</Trans>,
      consumerPrice: <Trans>Reimbursement amount</Trans>,
      isActive: <Trans>Status</Trans>,
      requestAllowed: <Trans>Is request allowed</Trans>,
      insertedAt: <Trans>Inserted at</Trans>,
      updatedAt: <Trans>Updated at</Trans>,
      details: <Trans>Details</Trans>
    }}
    renderRow={({
      id,
      medicalProgram,
      service,
      serviceGroup,
      consumerPrice,
      isActive,
      requestAllowed,
      insertedAt,
      updatedAt,
      ...serviceGroupData
    }: ProgramService) => ({
      ...serviceGroupData,
      medicalProgramId: medicalProgram && medicalProgram.databaseId,
      medicalProgram: medicalProgram && medicalProgram.name,
      service: service && service.name,
      serviceGroup: serviceGroup && serviceGroup.name,
      consumerPrice: <Price amount={consumerPrice} />,
      insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
      updatedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, updatedAt),
      isActive: (
        <Badge
          type="ACTIVE_STATUS_F"
          name={isActive}
          variant={!isActive}
          display="block"
        />
      ),
      requestAllowed: (
        <Flex justifyContent="center">
          {requestAllowed ? <PositiveIcon /> : <NegativeIcon />}
        </Flex>
      ),
      details: (
        <Link to={`/program-services/${id}`} fontWeight="bold">
          <Trans>Show details</Trans>
        </Link>
      )
    })}
    sortableFields={["consumerPrice", "insertedAt"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    hiddenFields="updatedAt"
    tableName={tableName}
  />
);

ProgramServicesTable.fragments = {
  entry: gql`
    fragment ProgramServices on ProgramService {
      id
      databaseId
      medicalProgram {
        id
        databaseId
        name
      }
      service {
        id
        name
      }
      serviceGroup {
        id
        name
      }
      consumerPrice
      isActive
      requestAllowed
      insertedAt
      updatedAt
    }
  `
};

export default ProgramServicesTable;
