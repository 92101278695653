import React, { useMemo } from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import Cookie from "js-cookie";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Flex, Heading, Text } from "@rebass/emotion";
import { isEmpty, get } from "lodash";

import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import { JustificationConnection, Person } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Button from "../../../components/Button";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import ExpandableText from "../../../components/ExpandableText";
import Line from "../../../components/Line";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import EpisodesTab from "./EpisodesTab";
import EncountersTab from "./EncountersTab";
import ConditionsTab from "./ConditionsTab";
import ObservationsTab from "./ObservationsTab";
import ProceduresTab from "./ProceduresTab";
import ServiceRequestsTab from "./ServiceRequestsTab";
import MedicationRequestsTab from "./MedicationRequestsTab";
import MedicationDispensesTab from "./MedicationDispensesTab";
import ImmunizationsTab from "./ImmunizationsTab";
import CarePlansTab from "./CarePlansTab";
import AllergyIntolerancesTab from "./AllergyIntolerancesTab";
import DiagnosticReportsTab from "./DiagnosticReportsTab";
import { useTabsScopesAbilities } from "./useTabsScopesAbilities";
import { dateFrom, dateTo } from "../JustificationHistory";
import { TABS_LIST } from "./constants";

type SearchProps = RouteComponentProps<{
  id: string;
}>;

const Search = ({ id, navigate, uri }: SearchProps) => {
  const { i18n } = useLingui();

  const tabsSopesAbilities: any = useTabsScopesAbilities();

  const TABS_LIST_MEMOIZED = useMemo(() => {
    return TABS_LIST.map((tab) => ({
      ...tab,
      ability: tabsSopesAbilities[`${tab.slug}Ability`]
    })).filter((tab) => tab.ability);
  }, [tabsSopesAbilities]);

  const { userId } = Cookie.getJSON("meta");

  return (
    <Query
      query={JustificationsQuery}
      fetchPolicy="network-only"
      variables={{
        first: 1,
        orderBy: "INSERTED_AT_DESC",
        filter: {
          personId: id,
          userId: userId,
          expiresAt: `${dateFrom}/${dateTo}`,
          status: "ACTIVE"
        }
      }}
    >
      {({
        data,
        error,
        loading
      }: QueryResult<{ justifications: JustificationConnection }>) => {
        if (!data || isEmpty(data.justifications) || error) return null;

        const isAbsentJustification =
          isEmpty(data.justifications) || isEmpty(data.justifications.nodes);
        const firstJustification =
          data.justifications &&
          data.justifications.nodes &&
          data.justifications.nodes[0];

        return (
          <LoadingOverlay loading={loading}>
            <Box p={6}>
              <Box mb={10}>
                <Breadcrumbs.List>
                  <Breadcrumbs.Item to="/persons">
                    <Trans>Patient Search</Trans>
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item to="../">
                    <Trans>Details of the patient</Trans>
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item>
                    <Trans>Practical monitoring</Trans>
                  </Breadcrumbs.Item>
                </Breadcrumbs.List>
              </Box>
              <Flex justifyContent="space-between">
                <Box width={1 / 2}>
                  <Query
                    query={PersonQuery}
                    variables={{
                      id: id
                    }}
                  >
                    {({
                      data,
                      loading,
                      error
                    }: QueryResult<{ person: Person }>) => {
                      if (!data || isEmpty(data.person) || error) return null;

                      return (
                        <LoadingOverlay loading={loading}>
                          <DefinitionListView
                            labels={{
                              patientId: <Trans>Patient ID</Trans>,
                              status: <Trans>Person status</Trans>
                            }}
                            data={{
                              patientId: data.person.databaseId,
                              status: (
                                <Badge
                                  name={data.person.status}
                                  type="ACTIVE_INACTIVE_M"
                                  minWidth={115}
                                />
                              )
                            }}
                            color="#7F8FA4"
                            labelWidth="150px"
                          />
                        </LoadingOverlay>
                      );
                    }}
                  </Query>
                  {!isAbsentJustification && (
                    <>
                      <Line />
                      <DefinitionListView
                        labels={{
                          expiresAt: <Trans>Expires at</Trans>,
                          reason: <Trans>Reason</Trans>,
                          reasonType: <Trans>Reason type</Trans>
                        }}
                        data={{
                          expiresAt: dateFormatter(
                            i18n.locale,
                            DATE_TIME_FORMAT,
                            firstJustification && firstJustification.expiresAt
                          ),
                          reason: (
                            <ExpandableText
                              text={
                                firstJustification
                                  ? firstJustification.reason
                                  : "-"
                              }
                            />
                          ),
                          reasonType: (
                            <DictionaryValue
                              name={get(
                                firstJustification,
                                "reasonType.coding[0].system",
                                ""
                              )}
                              item={get(
                                firstJustification,
                                "reasonType.coding[0].code"
                              )}
                            />
                          )
                        }}
                        color="#7F8FA4"
                        labelWidth="100px"
                      />
                    </>
                  )}
                </Box>
                <Flex flexDirection="column" alignItems="flex-end">
                  <Box>
                    <Button
                      variant="link"
                      px="0"
                      py="0"
                      icon={RemoveItemIcon}
                      type="reset"
                      onClick={() => navigate!("../")}
                    >
                      <Trans>Exit the practical monitor</Trans>
                    </Button>
                  </Box>
                  <Box mt={!isAbsentJustification ? "75px" : 4}>
                    <Button
                      variant="link"
                      px="0"
                      py="0"
                      type="reset"
                      onClick={() => navigate!("justification-history")}
                    >
                      <Trans>Justification history</Trans>
                    </Button>
                  </Box>
                  <Ability action="read" resource="approval">
                    <Box mt={4}>
                      <Button
                        variant="link"
                        px="0"
                        py="0"
                        type="reset"
                        onClick={() => navigate!("patient-approvals")}
                      >
                        <Trans>Patient approvals</Trans>
                      </Button>
                    </Box>
                  </Ability>
                </Flex>
              </Flex>
              {!isAbsentJustification ? (
                <Box mt={6}>
                  {TABS_LIST_MEMOIZED.length > 0 ? (
                    <>
                      <Tabs.Nav scrollAble>
                        {TABS_LIST_MEMOIZED.map(
                          ({ tabLabel: TabLabel, path }) => (
                            <Tabs.NavItem key={path} to={`./${path}`}>
                              {TabLabel}
                            </Tabs.NavItem>
                          )
                        )}
                      </Tabs.Nav>
                      <Tabs.Content>
                        <Router>
                          <Redirect from="/" to={`/${uri}/episodes`} />
                          <EpisodesTab path="episodes" />
                          <EncountersTab path="encounters" />
                          <ConditionsTab path="conditions" />
                          <ObservationsTab path="observations" />
                          <ProceduresTab path="procedures" />
                          <ServiceRequestsTab path="service-requests" />
                          <MedicationRequestsTab path="medication-requests" />
                          <MedicationDispensesTab path="medication-dispenses" />
                          <ImmunizationsTab path="immunizations" />
                          <CarePlansTab path="care-plans" />
                          <AllergyIntolerancesTab path="allergy-intolerances" />
                          <DiagnosticReportsTab path="diagnostic-reports" />
                        </Router>
                      </Tabs.Content>
                    </>
                  ) : (
                    <EmptyData />
                  )}
                </Box>
              ) : (
                <Text fontSize={1} color="#7F8FA4">
                  <Heading as="h1" my={6} textAlign="center">
                    <Trans>Provide justification</Trans>
                  </Heading>
                </Text>
              )}
            </Box>
          </LoadingOverlay>
        );
      }}
    </Query>
  );
};

export default Search;

const JustificationsQuery = gql`
  query JustificationsQuery(
    $first: Int!
    $filter: JustificationFilter!
    $orderBy: JustificationOrderBy
  ) {
    justifications(first: $first, filter: $filter, orderBy: $orderBy) {
      nodes {
        id
        databaseId
        reason
        reasonType {
          text
          coding {
            code
            system
          }
        }
        status
        expiresAt
      }
    }
  }
`;

const PersonQuery = gql`
  query PersonQuery($id: ID!) {
    person(id: $id) {
      id
      databaseId
      status
    }
  }
`;
