import React from "react";
import { Trans } from "@lingui/macro";

import {
  DiagnosesGroupCodeConnection,
  DiagnosesGroupCode
} from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";
import Table from "../../../components/Table";

type DiagnosesGroupDiagnosesTableProps = {
  diagnosesGroupCodes: DiagnosesGroupCodeConnection["nodes"];
  tableName?: string;
};

const DiagnosesGroupDiagnosesTable = ({
  diagnosesGroupCodes,
  tableName = "diagnosesGroupCodes/search"
}: DiagnosesGroupDiagnosesTableProps) => {
  return (
    <Table
      data={diagnosesGroupCodes}
      header={{
        databaseId: <Trans>ID</Trans>,
        code: <Trans>Code</Trans>,
        system: <Trans>Dictionary</Trans>,
        name: <Trans>Name</Trans>
      }}
      renderRow={({ databaseId, code, system }: DiagnosesGroupCode) => ({
        databaseId,
        code,
        name: <DictionaryValue name={system} item={code} />,
        system: system
      })}
      tableName={tableName}
      hiddenFields="updatedAt"
    />
  );
};

export default DiagnosesGroupDiagnosesTable;
