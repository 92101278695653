import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box, Text } from "@rebass/emotion";

import system from "@edenlabllc/ehealth-system-components";
import { DefaultImageIcon } from "@edenlabllc/ehealth-icons";
import {
  ContractRequest,
  ContractDocument,
  Maybe
} from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../../components/DictionaryValue";
import EmptyData from "../../../../components/EmptyData";

type DocumentsCapitationProps = RouteComponentProps<{
  attachedDocuments: ContractRequest["attachedDocuments"];
}>;

const DocumentsCapitation = ({
  attachedDocuments
}: DocumentsCapitationProps) => {
  return (
    <>
      {attachedDocuments ? (
        attachedDocuments.map((document: Maybe<ContractDocument>) => (
          <Box m="2">
            <SaveLink href={document && document.url} target="_blank">
              <Box m={1} color="shiningKnight">
                <DefaultImageIcon />
              </Box>
              <Text color="rockmanBlue" lineHeight="1">
                <DictionaryValue
                  name="CONTRACT_DOCUMENT"
                  item={document && document.type}
                />
              </Text>
            </SaveLink>
          </Box>
        ))
      ) : (
        <EmptyData />
      )}
    </>
  );
};

const SaveLink = system(
  {
    is: "a"
  },
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    lineHeight: 0,
    textDecoration: "none",
    fontSize: 14
  }
);

DocumentsCapitation.fragments = {
  entry: gql`
    fragment DocumentsCapitation on CapitationContractRequest {
      attachedDocuments {
        type
        url
      }
    }
  `
};

export default DocumentsCapitation;
