import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { gql } from "graphql-tag";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Text } from "@rebass/emotion";

import {
  Form,
  Heading,
  Modal,
  Validation
} from "@edenlabllc/ehealth-components";
import system from "@edenlabllc/ehealth-system-components";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import { CreateConclusionInput, Dictionary } from "@ehealth/ehealth-ua.schema";

import Ability from "../../components/Ability";
import Button from "../../components/Button";
import DictionaryValue from "../../components/DictionaryValue";
import * as Field from "../../components/Field";
import UnpocessableEntityModalError from "../../components/UnpocessableEntityModalError";

import { getErrorCode, getErrorMessage } from "../../helpers/errorHelpers";

type CreateConclusionProps = {
  initialData: {
    patientId: string;
    resource: any;
  };
};

const CreateConclusion = ({ initialData }: CreateConclusionProps) => {
  const { i18n } = useLingui();
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Ability action="write" resource="conclusion">
      <Button variant="green" disabled={isOpen} onClick={toggle}>
        <Trans>Create conclusion</Trans>
      </Button>
      {isOpen && (
        <Modal width={500} backdrop>
          <Close onClick={toggle} />
          <Heading as="h1" fontWeight="normal" mb={6}>
            <Text>
              <Trans>Create conclusion</Trans>
            </Text>
          </Heading>
          <Mutation mutation={CreateConclusionMutation}>
            {(createConclusion: MutationFunction) => (
              <Form
                onSubmit={async (data: CreateConclusionInput) => {
                  try {
                    await createConclusion({
                      variables: {
                        input: data
                      }
                    });
                    toggle();
                  } catch (error) {
                    if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                      setError(getErrorMessage(error));
                    }
                  }
                }}
                initialValues={initialData}
              >
                <Box mb={3}>
                  <DictionaryValue name="eHealth/clinical_monitoring_conclusion">
                    {(dict: Dictionary["values"]) => (
                      <Field.Select
                        name="conclusionType"
                        label={<Trans id="Conclusion" />}
                        placeholder={i18n._(t`Select conclusion`)}
                        items={dict ? Object.keys(dict) : []}
                        itemToString={(item: string) =>
                          dict ? dict[item] : item
                        }
                        variant="select"
                        filterOptions={{
                          keys: [(item: string) => dict[item]]
                        }}
                      />
                    )}
                  </DictionaryValue>
                  <Validation.Required
                    field="conclusionType"
                    message="Required field"
                  />
                </Box>
                <Box mb={3}>
                  <Trans
                    id="Enter conclusion comment"
                    render={({ translation }) => (
                      <Field.Textarea
                        name="comment"
                        placeholder={translation}
                        rows={5}
                      />
                    )}
                  />
                  <Validation.Required
                    field="comment"
                    message="Required field"
                  />
                </Box>
                <Button type="submit" variant="green">
                  <Trans>Create</Trans>
                </Button>
              </Form>
            )}
          </Mutation>
        </Modal>
      )}
      {error && (
        <UnpocessableEntityModalError errorMessage={error} isModalOpen />
      )}
    </Ability>
  );
};

export default CreateConclusion;

const Close = system(
  {
    is: RemoveItemIcon
  },
  {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: 12,
    height: 12,
    opacity: 0.2
  }
);

export const CreateConclusionMutation = gql`
  mutation CreateConclusion($input: CreateConclusionInput!) {
    createConclusion(input: $input) {
      conclusion {
        id
      }
    }
  }
`;
