import React from "react";
import { Trans } from "@lingui/macro";

import { Maybe } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "./DictionaryValue";

type DosageProps = {
  dosage?: Maybe<number>;
  denumeratorUnit?: Maybe<string>;
};

const Dosage = ({ dosage, denumeratorUnit }: DosageProps) =>
  dosage || dosage === 0 ? (
    <>
      {dosage}
      &nbsp;
      {denumeratorUnit ? (
        <DictionaryValue name="MEDICATION_UNIT" item={denumeratorUnit} />
      ) : (
        <Trans>mg</Trans>
      )}
    </>
  ) : (
    <>-</>
  );

export default Dosage;
