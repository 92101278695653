import React from "react";
import { Trans } from "@lingui/macro";

import { Person } from "@ehealth/ehealth-ua.schema";

import Link from "../../../components/Link";

import { Heading, Table } from "./index";

type DeclarationsInfoProps = {
  data: Person[];
};

const DeclarationsInfo = ({ data }: DeclarationsInfoProps) => (
  <>
    <Heading>
      <Trans>Other</Trans>
    </Heading>
    <Table
      header={{
        id: <Trans>Declarations</Trans>
      }}
      data={data}
      renderRow={({ id }: Person) => ({
        id: (
          <Link to={`../../persons/${id}/declarations`} fontWeight="bold">
            <Trans>Show declarations</Trans>
          </Link>
        )
      })}
      skipComparison
    />
  </>
);

export default DeclarationsInfo;
