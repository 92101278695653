import React from "react";
import { t, Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex, Box } from "@rebass/emotion";

import { Form } from "@edenlabllc/ehealth-components";
import { Dictionary } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

const PrimarySearchFields = () => (
  <Form.Spy>
    {({
      values: { filter: { system } = { system: undefined } } = {}
    }: $TSFixMe) => (
      <Flex mx={-1} flexWrap="wrap">
        <Box width={2 / 3} px={1}>
          <Trans
            id="Business rules engine ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.databaseId"
                label={<Trans id="Rule engine rule ID" />}
                placeholder={translation}
                autoComplete="off"
              />
            )}
          />
        </Box>
        <Box width={1 / 3} px={1}>
          <Trans
            id="Enter rule engine rule title"
            render={({ translation }) => (
              <Field.Text
                name="filter.name"
                label={<Trans id="Rule engine rule title" />}
                placeholder={translation}
                autoComplete="off"
              />
            )}
          />
        </Box>
        <Box width={1 / 3} px={1}>
          <SearchField.Status
            name="filter.isActive"
            status="ACTIVE_STATUS_M"
            label={<Trans id="Status" />}
          />
        </Box>
        <Box width={1 / 3} px={1}>
          <DictionaryValue
            name="eHealth/rule_engine_dictionaries"
            render={(dict: Dictionary["values"]) => (
              <Trans
                id="All systems"
                render={({ translation }) => (
                  <Field.Select
                    name="filter.system"
                    label={<Trans id="Coding system" />}
                    placeholder={i18n._(t`Select option`)}
                    items={dict ? Object.keys(dict) : []}
                    itemToString={(item: string) =>
                      (dict && dict[item]) || translation
                    }
                    variant="select"
                    emptyOption
                    filterOptions={{ keys: [(item: string) => dict[item]] }}
                  />
                )}
              />
            )}
          />
        </Box>

        <Box width={1 / 3} px={1}>
          <DictionaryValue
            name={system}
            render={(dict: Dictionary["values"]) => (
              <Trans
                id="All codes"
                render={({ translation }) => (
                  <Field.Select
                    name="filter.code"
                    label={<Trans id="Value" />}
                    placeholder={translation}
                    items={dict ? Object.keys(dict) : []}
                    itemToString={(item: string) =>
                      (dict && dict[item]) || translation
                    }
                    variant="select"
                    disabled={!system}
                    emptyOption
                    filterOptions={{ keys: [(item: string) => dict[item]] }}
                  />
                )}
              />
            )}
          />
        </Box>
      </Flex>
    )}
  </Form.Spy>
);

export { PrimarySearchFields };
