import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  MedicationDispense,
  MedicationDispenseDetail
} from "@ehealth/ehealth-ua.schema";

import Link from "../../../../components/Link";
import Table from "../../../../components/Table";

export const MedicationDispenseDetails = ({
  details
}: {
  details: MedicationDispense["details"];
}) => {
  if (isEmpty(details)) return null;
  return (
    <Box mt={-4}>
      <Table
        hidePagination
        hideControls
        data={details}
        header={{
          medicationId: <Trans id="Trade name">Medication</Trans>,
          medicationQty: <Trans>Medication Quantity</Trans>,
          sellPrice: <Trans>Sell price</Trans>,
          sellAmount: <Trans>Sell amount</Trans>,
          reimbursementAmount: <Trans>Reimbursement amount</Trans>,
          discountAmount: <Trans>Discount amount</Trans>,
          programMedicationId: <Trans>Program medication ID</Trans>
        }}
        renderRow={({
          medication,
          medicationQty,
          sellPrice,
          sellAmount,
          reimbursementAmount,
          discountAmount,
          programMedication
        }: MedicationDispenseDetail) => ({
          medicationId: (
            <Link to={`/medications/${medication.id}`} fontSize={14}>
              {medication.name}
            </Link>
          ),
          medicationQty,
          sellPrice,
          sellAmount,
          reimbursementAmount,
          discountAmount,
          programMedicationId: (
            <Link
              to={`/program-medications/${
                programMedication && programMedication.id
              }`}
              fontSize={14}
            >
              {programMedication && programMedication.databaseId}
            </Link>
          )
        })}
      />
    </Box>
  );
};

MedicationDispenseDetails.fragments = {
  entry: gql`
    fragment MedicationDispenseDetail on MedicationDispenseDetail {
      discountAmount
      medication {
        id
        name
      }
      medicationQty
      programMedication {
        id
        databaseId
      }
      reimbursementAmount
      sellAmount
      sellPrice
    }
  `
};
