import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { PositiveIcon, NegativeIcon } from "@edenlabllc/ehealth-icons";
import { Dictionary } from "@ehealth/ehealth-ua.schema";

import Breadcrumbs from "../../../components/Breadcrumbs";
import DefinitionListView from "../../../components/DefinitionListView";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";

import DictionaryValues from "./DictionaryValues";
import DictionaryLabels from "./Mutations/UpdateDictionaryLabels";

type DetailsProps = RouteComponentProps<{
  name: Dictionary["name"];
}>;

const Details = ({ name }: DetailsProps) => (
  <Query
    query={DictionaryByNameQuery}
    variables={{ dictionaryName: name ? decodeURIComponent(name) : "" }}
  >
    {({ loading, data }: QueryResult<{ dictionaryByName: Dictionary }>) => {
      if (isEmpty(data) || isEmpty(data.dictionaryByName)) return null;
      const { id, isActive, labels = [], values = [] } = data.dictionaryByName;
      const isReadOnly = labels ? labels.includes("READ_ONLY") : false;

      return (
        <LoadingOverlay loading={loading}>
          <Box p={6}>
            <Box py={10}>
              <Breadcrumbs.List>
                <Breadcrumbs.Item to="/dictionaries">
                  <Trans>Dictionaries</Trans>
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                  <Trans>Editing the dictionary</Trans>
                </Breadcrumbs.Item>
              </Breadcrumbs.List>
            </Box>
            <Flex justifyContent="space-between">
              <Box>
                <DefinitionListView
                  labels={{
                    name: <Trans>Name</Trans>,
                    status: <Trans>Status</Trans>
                  }}
                  data={{
                    name: name ? decodeURIComponent(name) : "",
                    status: isActive ? <PositiveIcon /> : <NegativeIcon />
                  }}
                  labelWidth="100px"
                />
              </Box>
            </Flex>
          </Box>

          <Tabs.Nav>
            <Tabs.NavItem to="./">
              <Trans>Value</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./labels">
              <Trans>Tags</Trans>
            </Tabs.NavItem>
          </Tabs.Nav>
          <Tabs.Content>
            <Router>
              <DictionaryValues
                path="/"
                id={id}
                values={values}
                isActive={isActive}
                isReadOnly={isReadOnly}
              />
              <DictionaryLabels
                path="/labels"
                id={id}
                labels={labels}
                isActive={isActive}
                isReadOnly={isReadOnly}
              />
            </Router>
          </Tabs.Content>
        </LoadingOverlay>
      );
    }}
  </Query>
);

const DictionaryByNameQuery = gql`
  query DictionaryByNameQuery($dictionaryName: String!) {
    dictionaryByName(dictionaryName: $dictionaryName) {
      id
      name
      isActive
      labels
      values
    }
  }
`;

export default Details;
