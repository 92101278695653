import React, { useCallback } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Heading, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { DiagnosesGroupConnection } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import EmptyData from "../../../components/EmptyData";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import { PrimarySearchFields, SecondarySearchFields } from "./SearchFields";
import DiagnosesGroupsTable from "./DiagnosesGroupsTable";

const Search = ({ navigate }: RouteComponentProps) => {
  const onCreateDiagnosesGroup = useCallback(() => navigate!("../create"), [
    navigate
  ]);

  return (
    <Box p={6}>
      <Flex justifyContent="space-between">
        <Box>
          <Heading as="h1" fontWeight="normal" mb={6}>
            <Trans>Diagnoses groups</Trans>
          </Heading>
        </Box>
        <Ability action="write" resource="diagnoses_group">
          <Box>
            <Button
              variant="green"
              width={200}
              onClick={onCreateDiagnosesGroup}
            >
              <Trans>Create diagnoses group</Trans>
            </Button>
          </Box>
        </Ability>
      </Flex>
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => (
          <>
            <SearchForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
              renderPrimary={PrimarySearchFields}
              renderSecondary={SecondarySearchFields}
            />
            <Query
              fetchPolicy="cache-and-network"
              query={DiagnosesGroupsQuery}
              variables={filteredLocationParams(locationParams)}
            >
              {({
                loading,
                data
              }: QueryResult<{
                diagnosesGroups: DiagnosesGroupConnection;
              }>) => {
                if (isEmpty(data) || isEmpty(data.diagnosesGroups))
                  return <EmptyData />;
                const {
                  diagnosesGroups: { nodes: diagnosesGroups = [], pageInfo }
                } = data;

                return (
                  <LoadingOverlay loading={loading}>
                    <DiagnosesGroupsTable diagnosesGroups={diagnosesGroups} />
                    <Pagination {...pageInfo} />
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        )}
      </LocationParams>
    </Box>
  );
};

export default Search;

export const DiagnosesGroupsQuery = gql`
  query DiagnosesGroupsQuery(
    $filter: DiagnosesGroupFilter
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    diagnosesGroups(
      filter: $filter
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      nodes {
        ...DiagnosesGroups
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${DiagnosesGroupsTable.fragments.entry}
  ${Pagination.fragments.entry}
`;
