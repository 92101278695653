import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import {
  PersonsDeactivationJob,
  PersonsDeactivationTask
} from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import Pagination from "../../../components/Pagination";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import TasksTable from "../../../components/TasksTable";

type TasksProps = RouteComponentProps & {
  tasks: PersonsDeactivationJob["tasks"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const Tasks = ({ tasks, locationParams, setLocationParams }: TasksProps) => {
  const { nodes, pageInfo } = tasks || {};
  if (isEmpty(nodes)) return <EmptyData />;

  return (
    <>
      <TasksTable
        tasks={nodes!}
        locationParams={locationParams}
        setLocationParams={setLocationParams}
        taskName={() => <Trans>Person deactivation</Trans>}
        taskMeta={({ meta }: { meta: PersonsDeactivationTask["meta"] }) => {
          const { type, number, deathDate } = meta || {};
          return (
            <>
              <Box>
                <DictionaryValue
                  name="PERSONS_DEACTIVATION_IDENTITIES"
                  item={type}
                />{" "}
                {number}
              </Box>
              {deathDate && (
                <Box>
                  <Trans>Death date</Trans>: {deathDate}
                </Box>
              )}
            </>
          );
        }}
        tableName="persons-deactivation-job/tasks"
      />
      <Pagination {...pageInfo} />
    </>
  );
};

Tasks.fragments = {
  entry: gql`
    fragment PersonsDeactivationTasks on PersonsDeactivationTask {
      id
      databaseId
      name
      priority
      status
      insertedAt
      endedAt
      updatedAt
      meta {
        type
        number
        deathDate
      }
      error {
        message
      }
    }
  `
};

export default Tasks;
