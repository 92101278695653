import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { DocumentNode, MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";

import Popup from "../../../components/Popup";
import Button from "../../../components/Button";
import { SearchParams } from "../../../components/SearchForm";

type DeactivateServiceGroupPopupProps = {
  id: string;
  name: string;
  locationParams: SearchParams;
  serviceGroupDetailsQuery: DocumentNode;
};

const DeactivateServiceGroupPopup = ({
  id,
  name,
  locationParams,
  serviceGroupDetailsQuery
}: DeactivateServiceGroupPopupProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Mutation
      mutation={DeactivateServiceGroupMutation}
      refetchQueries={() => [
        {
          query: serviceGroupDetailsQuery,
          variables: { id, ...locationParams }
        }
      ]}
    >
      {(deactivateServiceGroup: MutationFunction) => (
        <>
          <Button onClick={toggle} variant="red">
            <Trans>Deactivate</Trans>
          </Button>
          <Popup
            visible={isPopupVisible}
            onCancel={toggle}
            title={
              <>
                <Trans>Deactivate group </Trans> "{name}
                "?
              </>
            }
            okText={<Trans>Deactivate</Trans>}
            onOk={async () => {
              await deactivateServiceGroup({
                variables: {
                  input: {
                    id
                  }
                }
              });
              toggle();
            }}
          />
        </>
      )}
    </Mutation>
  );
};

const DeactivateServiceGroupMutation = gql`
  mutation DeactivateServiceGroupMutation(
    $input: DeactivateServiceGroupInput!
  ) {
    deactivateServiceGroup(input: $input) {
      serviceGroup {
        id
      }
    }
  }
`;

export default DeactivateServiceGroupPopup;
