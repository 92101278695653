import * as React from "react";
import isEmpty from "lodash/isEmpty";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import system from "@edenlabllc/ehealth-system-components";
import { Form } from "@edenlabllc/ehealth-components";
import { FilterIcon, RemoveItemIcon } from "@edenlabllc/ehealth-icons";

import resetPaginationParams from "../helpers/resetPaginationParams";
import Button from "./Button";
import SelectedFilters from "./SelectedFilters";
import { SearchParams, SetSearchParams } from "./SearchForm";

type SearchModalFormProps = {
  initialValues: SearchParams;
  onSubmit: SetSearchParams;
  searchButton: React.ComponentType<{}>;
  children: React.ReactElement;
};

const SearchModalForm = ({
  initialValues,
  onSubmit,
  searchButton: SearchButton,
  children
}: SearchModalFormProps) => {
  const [visible, setToggleState] = React.useState(false);
  const toggle = () => setToggleState(!visible);
  return (
    <Flex>
      <Button
        variant="link-thin"
        px={0}
        py={0}
        onClick={toggle}
        icon={FilterIcon}
      >
        <Trans>Show all filters</Trans>
      </Button>
      <SelectedFilters initialValues={initialValues} onSubmit={onSubmit} />
      {visible && (
        <>
          <Popup>
            <Close onClick={toggle} />
            <Form
              onSubmit={(params: SearchParams) => {
                onSubmit({
                  ...params,
                  ...resetPaginationParams(initialValues)
                });
                toggle();
              }}
              initialValues={initialValues}
            >
              {children}
              <Flex mx={-1} mt={4} justifyContent="flex-start">
                <Box px={1}>
                  <Button variant="red" onClick={toggle}>
                    <Trans>Close</Trans>
                  </Button>
                </Box>
                <Box px={1}>
                  <SearchButton />
                </Box>
                {!isEmpty(initialValues.filter) && (
                  <Box px={1}>
                    <Button
                      variant="link"
                      icon={RemoveItemIcon}
                      px={1}
                      type="reset"
                      onClick={() => {
                        onSubmit({
                          ...initialValues,
                          filter: {},
                          searchRequest: null
                        });
                      }}
                    >
                      <Trans>Reset</Trans>
                    </Button>
                  </Box>
                )}
              </Flex>
            </Form>
          </Popup>
          <Backdrop onClick={toggle} />
        </>
      )}
    </Flex>
  );
};

const Popup = system(
  {},
  {
    position: "absolute",
    zIndex: 100,
    top: -40,
    left: -20,
    right: -20,
    padding: "40px 20px",
    background: "#fff",
    boxShadow: "0 0 7px 5px rgba(0, 0, 0, 0.1)"
  }
);

const Backdrop = system(
  {},
  {
    position: "fixed",
    zIndex: 99,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
);

const Close = system(
  {
    is: RemoveItemIcon
  },
  {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: 12,
    height: 12,
    opacity: 0.2
  }
);

export default SearchModalForm;
