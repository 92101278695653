import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  fieldNameDenormalizer,
  convertObjectKeys
} from "@edenlabllc/ehealth-utils";
import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import { Form, Validation } from "@edenlabllc/ehealth-components";
import { ForbiddenGroup } from "@ehealth/ehealth-ua.schema";

import Button from "../../../../components/Button";
import DefinitionListView from "../../../../components/DefinitionListView";
import * as Field from "../../../../components/Field";
import Line from "../../../../components/Line";
import Notification from "../../../../components/Notification";
import Steps from "../../../../components/Steps";

import { SearchForbiddenGroupsQuery } from "../index";

import env from "../../../../env";

type DeactivateProps = RouteComponentProps<{
  id: string;
}>;

const Deactivate = ({
  // @ts-expect-error location state
  location: { state, pathname },
  id
}: DeactivateProps) => {
  return (
    <>
      {pathname.includes("confirm") && (
        <Box m={-2}>
          <Notification variant="orange">
            <Trans>
              Warning! Forbidden group and all nested items will be deactivated!
            </Trans>
          </Notification>
        </Box>
      )}
      <Box pt={5} px={5}>
        <Steps.List>
          <Steps.Item to="./" state={state}>
            <Trans>Enter group deactivation reason</Trans>
          </Steps.Item>
          <Steps.Item to="./confirm" state={state} disabled>
            <Trans>Approve by EDS</Trans>
          </Steps.Item>
        </Steps.List>
      </Box>
      <Header id={id!} />
      <Box px={5}>
        <Line />
      </Box>
      <Router>
        <GeneralForm path="/" />
        <Confirmation path="/confirm" />
      </Router>
    </>
  );
};

export default Deactivate;

type HeaderType = {
  id: string;
};

const Header = ({ id }: HeaderType) => (
  <Query
    query={ForbiddenGroupQuery}
    variables={{
      id
    }}
  >
    {({ data }: QueryResult<{ forbiddenGroup: ForbiddenGroup }>) => {
      if (isEmpty(data) || isEmpty(data.forbiddenGroup)) return null;

      return (
        <Box px={5}>
          <DefinitionListView
            labels={{
              name: <Trans>Forbidden group name</Trans>,
              creationReason: <Trans>Basis of group creation</Trans>
            }}
            data={{
              name: data.forbiddenGroup.name,
              creationReason: data.forbiddenGroup.creationReason
            }}
            labelWidth="200px"
          />
        </Box>
      );
    }}
  </Query>
);

const GeneralForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <Box p={5}>
    <Form
      onSubmit={(data: { deactivationReason: string }) => {
        navigate!("./confirm", {
          state: {
            ...state,
            ...data
          }
        });
      }}
      initialValues={state || null}
    >
      <Box>
        <Trans
          id="Reason of group deactivation"
          render={({ translation }) => (
            <Field.Textarea
              name="deactivationReason"
              label={<Trans id="Specify the basis" />}
              placeholder={translation}
              rows={10}
              maxLength={1000}
              showLengthHint
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field="deactivationReason"
              message="Required field"
            />
          )}
        />
      </Box>
      <Flex pt={5} mb={100}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate!("../")}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Button variant="green" width={140}>
            <Trans>Next</Trans>
          </Button>
        </Box>
      </Flex>
    </Form>
  </Box>
);

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const {
    deactivationReason,
    forbiddenGroup: { databaseId }
  } = state;

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          deactivationReason: <Trans>Basis of group deactivation</Trans>
        }}
        data={{ deactivationReason }}
        labelWidth="200px"
      />
      <Flex pt={5} mb={100}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate!("../", { state })}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Signer.Parent
          url={env.REACT_APP_SIGNER_URL}
          features={{
            width: 640,
            height: 589
          }}
        >
          {/* @ts-expect-error signData */}
          {({ signData }) => (
            <Mutation
              mutation={DeactivateForbiddenGroupMutation}
              refetchQueries={() => [
                {
                  query: SearchForbiddenGroupsQuery,
                  variables: { first: 10 }
                }
              ]}
            >
              {(deactivateForbiddenGroup: MutationFunction) => {
                return (
                  <Box>
                    <Button
                      variant="green"
                      width={250}
                      onClick={async () => {
                        const { signedContent } = await signData(
                          convertObjectKeys(
                            {
                              id: databaseId,
                              deactivationReason
                            },
                            fieldNameDenormalizer
                          )
                        );

                        await deactivateForbiddenGroup({
                          variables: {
                            input: {
                              signedContent: {
                                content: signedContent,
                                encoding: "BASE64"
                              }
                            }
                          }
                        });
                        await navigate!("../../");
                      }}
                    >
                      <Trans>Approve by EDS</Trans>
                    </Button>
                  </Box>
                );
              }}
            </Mutation>
          )}
        </Signer.Parent>
      </Flex>
    </Box>
  );
};

export const ForbiddenGroupQuery = gql`
  query ForbiddenGroupQuery($id: ID!) {
    forbiddenGroup(id: $id) {
      id
      databaseId
      name
      creationReason
    }
  }
`;

const DeactivateForbiddenGroupMutation = gql`
  mutation DeactivateForbiddenGroupMutation(
    $input: DeactivateForbiddenGroupInput!
  ) {
    deactivateForbiddenGroup(input: $input) {
      forbiddenGroup {
        id
        databaseId
        name
        creationReason
        insertedAt
        isActive
      }
    }
  }
`;
