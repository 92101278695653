import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { DocumentNode, MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";

import { Form, Validation } from "@edenlabllc/ehealth-components";
import { AdminAddIcon } from "@edenlabllc/ehealth-icons";
import system from "@edenlabllc/ehealth-system-components";
import { ServiceGroup } from "@ehealth/ehealth-ua.schema";

import Button from "../../../components/Button";
import Popup from "../../../components/Popup";
import * as SearchField from "../../../components/SearchField";
import { SearchParams } from "../../../components/SearchForm";

type AddServiceToGroupPopupProps = {
  serviceGroupId: string;
  serviceGroupName: string;
  locationParams: SearchParams;
  serviceGroupDetailsQuery: DocumentNode;
};

const AddServiceToGroupPopup = ({
  serviceGroupId,
  serviceGroupName,
  locationParams,
  serviceGroupDetailsQuery
}: AddServiceToGroupPopupProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Box>
      <Button variant="link" mt={2} px={0} icon={AddIcon} onClick={toggle}>
        <Trans>Add service to the group</Trans>
      </Button>

      <Mutation
        mutation={AddServiceToGroupMutation}
        refetchQueries={() => [
          {
            query: serviceGroupDetailsQuery,
            variables: {
              id: serviceGroupId,
              ...locationParams
            }
          }
        ]}
      >
        {(addServiceToGroup: MutationFunction) => (
          <Popup
            visible={isPopupVisible}
            onCancel={toggle}
            title={
              <>
                <Trans>Add service to the group</Trans> "{serviceGroupName}"
              </>
            }
            okButtonProps={{ variant: "green" }}
            okText={<Trans>Add service</Trans>}
            justifyButtons="left"
            formId="addServiceToGroup"
          >
            <Form
              id="addServiceToGroup"
              initialValues={{
                serviceGroupId
              }}
              onSubmit={async ({
                service,
                ...input
              }: {
                serviceGroupId: string;
                service: ServiceGroup;
              }) => {
                const { id } = service;
                await addServiceToGroup({
                  variables: {
                    input: {
                      ...input,
                      serviceId: id
                    }
                  }
                });
                toggle();
              }}
            >
              <Box>
                <SearchField.ComposedService name="service" />
                <Validation.Required field="service" message="Required field" />
              </Box>
            </Form>
          </Popup>
        )}
      </Mutation>
    </Box>
  );
};

const AddServiceToGroupMutation = gql`
  mutation AddServiceToGroupMutation($input: AddServiceToGroupInput!) {
    addServiceToGroup(input: $input) {
      serviceGroup {
        id
      }
    }
  }
`;

const AddIcon = system(
  {
    is: AdminAddIcon,
    width: 16,
    height: 16
  },
  "width",
  "height"
);

export default AddServiceToGroupPopup;
