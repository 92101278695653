import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation, Query } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import Composer from "react-composer";
import Cookie from "js-cookie";
import { TransRenderProps, useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Flex, Heading } from "@rebass/emotion";
import { isEmpty, get } from "lodash";

import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import {
  Form,
  LocationParams,
  useModal,
  Validation
} from "@edenlabllc/ehealth-components";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  Dictionary,
  Justification,
  JustificationConnection,
  ReasonTypeInput
} from "@ehealth/ehealth-ua.schema";

import Badge from "../../components/Badge";
import Button from "../../components/Button";
import { DATE_TIME_FORMAT } from "../../constants/dateFormats";
import DictionaryValue from "../../components/DictionaryValue";
import * as Field from "../../components/Field";
import ExpandableText from "../../components/ExpandableText";
import LoadingOverlay from "../../components/LoadingOverlay";
import Pagination from "../../components/Pagination";
import Popup from "../../components/Popup";
import Table, { SortingParams } from "../../components/Table";
import { TLocationParams } from "../../components/SearchForm";

import dateFormatter from "../../helpers/dateFormatter";
import pagination from "../../helpers/pagination";
import { useAbility } from "../../helpers/useAbility";

import DetailsPageBreadcrumbs from "./Details/DetailsPageBreadcrumbs";

const FULL_SCREEN_HEIGHT_LIMIT = 1000;

export const dateFrom = new Date().toISOString();
export const dateTo = new Date("2099-01-01").toISOString();

type JustificationHistoryProps = RouteComponentProps<{
  id: string;
}>;

const JustificationHistory = ({ navigate, id }: JustificationHistoryProps) => {
  const [justificationHistoryAbility] = useAbility("read", "justification");
  const [isModalVisible, setModalVisibility] = useState(false);

  const [{ modal }] = useModal(Popup, {
    title: <Trans>Something went wrong. Please try again later</Trans>,
    hideOkButton: true
  });

  const { i18n } = useLingui();

  const { userId } = Cookie.getJSON("meta");

  return (
    justificationHistoryAbility && (
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => (
          <Box p={6}>
            <DetailsPageBreadcrumbs
              id={id}
              currentStepName={<Trans>Justification history</Trans>}
            />
            <Query
              query={JustificationsQuery}
              fetchPolicy="network-only"
              variables={{
                ...pagination(locationParams),
                orderBy: locationParams.orderBy
                  ? locationParams.orderBy
                  : "INSERTED_AT_DESC",
                filter: {
                  personId: id,
                  userId: userId
                }
              }}
            >
              {({
                loading,
                data,
                refetch
              }: QueryResult<{ justifications: JustificationConnection }>) => {
                if (isEmpty(data) || isEmpty(data.justifications)) return null;
                return (
                  <>
                    <Flex justifyContent="flex-end">
                      <Box>
                        <Button
                          variant="link"
                          px="0"
                          py="0"
                          icon={RemoveItemIcon}
                          type="reset"
                          onClick={() => navigate!("../")}
                        >
                          <Trans>Exit the justification history</Trans>
                        </Button>
                      </Box>
                    </Flex>
                    <Flex justifyContent={"flex-end"}>
                      <Box mt={5}>
                        <Button
                          width={220}
                          variant="blue"
                          onClick={() => setModalVisibility(true)}
                        >
                          <Trans>Create justification</Trans>
                        </Button>
                        <Popup
                          visible={isModalVisible}
                          okText={<Trans>Create justification</Trans>}
                          onCancel={() => setModalVisibility(false)}
                          formId="createJustification"
                          okButtonProps={{ variant: "orange", width: 220 }}
                          cancelButtonProps={{ variant: "blue", width: 220 }}
                        >
                          <Heading as="h1" fontWeight="normal" mb={6}>
                            <Trans>Create justification</Trans>
                          </Heading>
                          <Mutation mutation={CreateJustification}>
                            {(createJustification: MutationFunction) => (
                              <Form
                                id="createJustification"
                                onSubmit={async ({
                                  justificationReasonType,
                                  reasonPracticalMonitoring
                                }: {
                                  justificationReasonType: ReasonTypeInput;
                                  reasonPracticalMonitoring: string;
                                }) => {
                                  try {
                                    await createJustification({
                                      variables: {
                                        input: {
                                          personId: id,
                                          reason: reasonPracticalMonitoring,
                                          reasonType: {
                                            coding: [
                                              {
                                                code: justificationReasonType,
                                                system:
                                                  "eHealth/justification_reason_types"
                                              }
                                            ],
                                            text: null
                                          }
                                        }
                                      }
                                    });
                                  } catch (e) {
                                    modal.openModal();
                                  }
                                  refetch();
                                  setModalVisibility(false);
                                }}
                              >
                                <Box px={1} width={1 / 2}>
                                  <Composer
                                    components={[
                                      <DictionaryValue name="eHealth/justification_reason_types" />,
                                      ({
                                        render
                                      }: {
                                        render: (
                                          props: TransRenderProps
                                        ) => React.ReactElement;
                                      }) => (
                                        <Trans
                                          id="Select option"
                                          render={render}
                                        />
                                      )
                                    ]}
                                  >
                                    {([dict, { translation }]: [
                                      Dictionary["values"],
                                      { translation: React.ReactNode }
                                    ]) => (
                                      <Field.Select
                                        name="justificationReasonType"
                                        label={
                                          <Trans id="Justification reason type" />
                                        }
                                        placeholder={translation}
                                        items={Object.keys(dict)}
                                        itemToString={(item: string) =>
                                          dict[item] || translation
                                        }
                                        variant="select"
                                        filterOptions={{
                                          keys: [(item: string) => dict[item]]
                                        }}
                                      />
                                    )}
                                  </Composer>
                                  <Validation.Required
                                    field="justificationReasonType"
                                    message="Required field"
                                  />
                                </Box>
                                <Box mb={2}>
                                  <Trans
                                    id="Reason of practical monitoring"
                                    render={({ translation }) => (
                                      <Field.Textarea
                                        name="reasonPracticalMonitoring"
                                        label={
                                          <Trans id="Specify the reason of practical monitoring" />
                                        }
                                        placeholder={translation}
                                        rows={10}
                                        maxLength={5000}
                                        showLengthHint
                                      />
                                    )}
                                  />
                                  <Trans
                                    id="Required field"
                                    render={() => (
                                      <Validation.Required
                                        field="reasonPracticalMonitoring"
                                        message="Required field"
                                      />
                                    )}
                                  />
                                </Box>
                              </Form>
                            )}
                          </Mutation>
                        </Popup>
                      </Box>
                    </Flex>
                    <LoadingOverlay loading={loading}>
                      <Table
                        data={data.justifications.nodes}
                        header={{
                          databaseId: <Trans>ID</Trans>,
                          userId: <Trans>User ID</Trans>,
                          reasonType: <Trans>Reason type</Trans>,
                          reason: <Trans>Reason</Trans>,
                          status: <Trans>Status</Trans>,
                          expiresAt: <Trans>Expires at</Trans>,
                          insertedAt: <Trans>Inserted at</Trans>,
                          updatedAt: <Trans>Updated at</Trans>
                        }}
                        renderRow={({
                          databaseId,
                          reason,
                          reasonType,
                          consumer,
                          status,
                          expiresAt,
                          insertedAt,
                          updatedAt
                        }: Justification) => ({
                          databaseId,
                          userId: consumer.userId,
                          reasonType: (
                            <DictionaryValue
                              name={
                                get(
                                  reasonType,
                                  "coding[0].system",
                                  ""
                                ) as string
                              }
                              item={get(reasonType, "coding[0].code")}
                            />
                          ),
                          reason: (
                            <ExpandableText
                              text={reason}
                              fixedAlign
                              fullScreenHeight={
                                reason.length > FULL_SCREEN_HEIGHT_LIMIT
                              }
                            />
                          ),
                          status: (
                            <Badge
                              name={
                                new Date(expiresAt) < new Date() &&
                                status === "ACTIVE"
                                  ? "INACTIVE"
                                  : status
                              }
                              type="JUSTIFICATION_STATUS"
                              minWidth={115}
                            />
                          ),
                          expiresAt: dateFormatter(
                            i18n.locale,
                            DATE_TIME_FORMAT,
                            expiresAt
                          ),
                          insertedAt: dateFormatter(
                            i18n.locale,
                            DATE_TIME_FORMAT,
                            insertedAt
                          ),
                          updatedAt: dateFormatter(
                            i18n.locale,
                            DATE_TIME_FORMAT,
                            updatedAt
                          )
                        })}
                        sortableFields={["insertedAt"]}
                        sortingParams={parseSortingParams(
                          locationParams.orderBy
                        )}
                        onSortingChange={(sortingParams: SortingParams) =>
                          setLocationParams({
                            ...locationParams,
                            orderBy: stringifySortingParams(sortingParams)
                          })
                        }
                        tableName="justificationHistory"
                        hiddenFields="databaseId,updatedAt"
                      />
                      <Pagination {...data.justifications.pageInfo} />
                    </LoadingOverlay>
                  </>
                );
              }}
            </Query>
          </Box>
        )}
      </LocationParams>
    )
  );
};

export default JustificationHistory;

const JustificationsQuery = gql`
  query JustificationsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: JustificationFilter!
    $orderBy: JustificationOrderBy
  ) {
    justifications(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      nodes {
        id
        databaseId
        consumer {
          userId
        }
        reasonType {
          text
          coding {
            code
            system
          }
        }
        reason
        status
        expiresAt
        insertedAt
        updatedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

const CreateJustification = gql`
  mutation CreateJustification($input: CreateJustificationInput!) {
    createJustification(input: $input) {
      justification {
        id
        databaseId
        reason
        reasonType {
          text
          coding {
            code
            system
          }
        }
        status
        insertedAt
      }
    }
  }
`;
