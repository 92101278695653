import { gql } from "graphql-tag";
import Pagination from "../../../components/Pagination";
import DiagnosticReport from "../../PracticalMonitoring/Details/DiagnosticReport";
import ServiceRequestCode from "../../PracticalMonitoring/Details/ServiceRequestCode";

export const ImpersonalDiagnosticReportsQuery = gql`
  query ImpersonalDiagnosticReportsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImpersonalDiagnosticReportFilter!
    $orderBy: DiagnosticReportOrderBy
  ) {
    impersonalDiagnosticReports(
      after: $after
      before: $before
      filter: $filter
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      nodes {
        id
        databaseId
        status
        insertedAt
        code {
          ...ServiceRequestCode
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalEntries
    }
  }
  ${Pagination.fragments.entry}
  ${ServiceRequestCode.fragments.entry}
`;

export const ImpersonalDiagnosticReportByIDQuery = gql`
  query ImpersonalDiagnosticReportByIDQuery(
    $id: ID!
    $patientId: String!
    $skipAdditionalFields: Boolean!
  ) {
    impersonalDiagnosticReport(id: $id, patientId: $patientId) {
      ...DiagnosticReport
    }
  }
  ${DiagnosticReport.fragments!.entry}
`;
