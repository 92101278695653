import React, { useMemo } from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";
import { Box, Heading, Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import Ability from "../../components/Ability";
import EmptyData from "../../components/EmptyData";
import Link from "../../components/Link";
import LoadingOverlay from "../../components/LoadingOverlay";
import Tabs from "../../components/Tabs";

import { useTabsScopesAbilities } from "../PracticalMonitoring/Search/useTabsScopesAbilities";
import { TABS_LIST } from "../PracticalMonitoring/Search/constants";

// tabs
import EpisodesTab from "../PracticalMonitoring/Search/EpisodesTab";
import ObservationsTab from "../PracticalMonitoring/Search/ObservationsTab";
import AllergyIntolerancesTab from "../PracticalMonitoring/Search/AllergyIntolerancesTab";
import EncountersTab from "../PracticalMonitoring/Search/EncountersTab";
import ConditionsTab from "../PracticalMonitoring/Search/ConditionsTab";
import ImmunizationsTab from "../PracticalMonitoring/Search/ImmunizationsTab";
import ProceduresTab from "../PracticalMonitoring/Search/ProceduresTab";
import DiagnosticReportsTab from "../PracticalMonitoring/Search/DiagnosticReportsTab";
import CarePlansTab from "../PracticalMonitoring/Search/CarePlansTab";
import ServiceRequestsTab from "../PracticalMonitoring/Search/ServiceRequestsTab";
import MedicationDispensesTab from "../PracticalMonitoring/Search/MedicationDispensesTab";
import MedicationRequestsTab from "../PracticalMonitoring/Search/MedicationRequestsTab";

// graphql
import { ImpersonalEpisodesQuery } from "./graphql/Episodes";
import { ImpersonalObservationsQuery } from "./graphql/Observations";
import { ImpersonalAllergyIntolerancesQuery } from "./graphql/AllergyIntolerance";
import { ImpersonalEncountersQuery } from "./graphql/Encounters";
import { ImpersonalConditionsQuery } from "./graphql/Conditions";
import { ImpersonalImmunizationsQuery } from "./graphql/Immunizations";
import { ImpersonalProceduresQuery } from "./graphql/Procedures";
import { ImpersonalDiagnosticReportsQuery } from "./graphql/DiagnosticReports";
import { ImpersonalCarePlansQuery } from "./graphql/CarePlans";
import { ImpersonalServiceRequestsQuery } from "./graphql/ServiceRequests";
import { ImpersonalMedicationDispensesQuery } from "./graphql/MedicationDispenses";
import { ImpersonalMedicationRequestsQuery } from "./graphql/MedicationRequests";
import Button from "../../components/Button";

const Search = ({ uri }: RouteComponentProps) => {
  const tabsScopesAbilities: any = useTabsScopesAbilities("clinical_monitor");

  const TABS_LIST_MEMOIZED = useMemo(() => {
    return TABS_LIST.map((tab) => ({
      ...tab,
      ability: tabsScopesAbilities[`${tab.slug}Ability`]
    })).filter((tab) => tab.ability);
  }, [tabsScopesAbilities]);

  return (
    <LoadingOverlay loading={false}>
      <Box p={6}>
        <Flex justifyContent="space-between" mb={4}>
          <Heading as="h1" fontWeight="normal">
            <Trans>Clinical monitoring</Trans>
          </Heading>

          <Ability action="read" resource="conclusion">
            <Link to="conclusions">
              <Button variant="blue">
                <Trans>Search conclusions</Trans>
              </Button>
            </Link>
          </Ability>
        </Flex>

        <Box pt={6}>
          {TABS_LIST_MEMOIZED.length > 0 ? (
            <Box>
              <Tabs.Nav scrollAble>
                {TABS_LIST_MEMOIZED.map(({ tabLabel: TabLabel, path }) => (
                  <Tabs.NavItem key={path} to={`./${path}`}>
                    {TabLabel}
                  </Tabs.NavItem>
                ))}
              </Tabs.Nav>
              <Tabs.Content>
                <Router>
                  <Redirect from="/" to={`/${uri}/episodes`} />
                  <EpisodesTab
                    path="episodes"
                    query={ImpersonalEpisodesQuery}
                    isClinicalMonitoring
                  />
                  <ObservationsTab
                    path="observations"
                    query={ImpersonalObservationsQuery}
                    isClinicalMonitoring
                  />
                  <AllergyIntolerancesTab
                    path="allergy-intolerances"
                    query={ImpersonalAllergyIntolerancesQuery}
                    isClinicalMonitoring
                  />
                  <EncountersTab
                    path="encounters"
                    query={ImpersonalEncountersQuery}
                    isClinicalMonitoring
                  />
                  <ConditionsTab
                    path="conditions"
                    query={ImpersonalConditionsQuery}
                    isClinicalMonitoring
                  />
                  <ImmunizationsTab
                    path="immunizations"
                    query={ImpersonalImmunizationsQuery}
                    isClinicalMonitoring
                  />
                  <ProceduresTab
                    path="procedures"
                    query={ImpersonalProceduresQuery}
                    isClinicalMonitoring
                  />
                  <DiagnosticReportsTab
                    path="diagnostic-reports"
                    query={ImpersonalDiagnosticReportsQuery}
                    isClinicalMonitoring
                  />
                  <CarePlansTab
                    path="care-plans"
                    query={ImpersonalCarePlansQuery}
                    isClinicalMonitoring
                  />
                  <ServiceRequestsTab
                    path="service-requests"
                    query={ImpersonalServiceRequestsQuery}
                    isClinicalMonitoring
                  />
                  <MedicationDispensesTab
                    path="medication-dispenses"
                    query={ImpersonalMedicationDispensesQuery}
                    isClinicalMonitoring
                  />
                  <MedicationRequestsTab
                    path="medication-requests"
                    query={ImpersonalMedicationRequestsQuery}
                    isClinicalMonitoring
                  />
                </Router>
              </Tabs.Content>
            </Box>
          ) : (
            <EmptyData />
          )}
        </Box>
      </Box>
    </LoadingOverlay>
  );
};

export default Search;
