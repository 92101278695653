import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { LegalEntityDeactivationJob } from "@ehealth/ehealth-ua.schema";

import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DefinitionListView from "../../../components/DefinitionListView";
import EmptyData from "../../../components/EmptyData";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import Tabs from "../../../components/Tabs";
import TasksTable from "../../../components/TasksTable";
import {
  TLocationParams,
  SearchParams,
  SetSearchParams
} from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

const Details = ({ id }: DetailsProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => (
      <Query
        query={LegalEntityDeactivationJobQuery}
        variables={{ id, ...filteredLocationParams(locationParams) }}
      >
        {({
          loading,
          data
        }: QueryResult<{
          legalEntityDeactivationJob: LegalEntityDeactivationJob;
        }>) => {
          if (isEmpty(data) || isEmpty(data.legalEntityDeactivationJob))
            return null;
          const {
            databaseId,
            deactivatedLegalEntity: { name },
            status,
            tasks
          } = data.legalEntityDeactivationJob;

          return (
            <LoadingOverlay loading={loading}>
              <Box p={6}>
                <Box py={10}>
                  <Breadcrumbs.List>
                    <Breadcrumbs.Item to="/legal-entity-deactivate-jobs">
                      <Trans>Legal entity deactivate jobs</Trans>
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item>
                      <Trans>Job details</Trans>
                    </Breadcrumbs.Item>
                  </Breadcrumbs.List>
                </Box>
                <DefinitionListView
                  labels={{
                    databaseId: <Trans>Job ID</Trans>,
                    name: <Trans>Legal entity</Trans>,
                    status: <Trans>Status</Trans>
                  }}
                  data={{
                    databaseId,
                    name,
                    status: <Badge type="JOBS" name={status} minWidth={100} />
                  }}
                  color="#7F8FA4"
                  labelWidth="120px"
                />
              </Box>
              <Tabs.Nav>
                <Tabs.NavItem to="./">
                  <Trans>Job details</Trans>
                </Tabs.NavItem>
              </Tabs.Nav>
              <Tabs.Content>
                <Router>
                  <Tasks
                    path="/"
                    tasks={tasks}
                    locationParams={locationParams}
                    setLocationParams={setLocationParams}
                  />
                </Router>
              </Tabs.Content>
            </LoadingOverlay>
          );
        }}
      </Query>
    )}
  </LocationParams>
);

type TasksProps = RouteComponentProps & {
  tasks: LegalEntityDeactivationJob["tasks"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const Tasks = ({ tasks, locationParams, setLocationParams }: TasksProps) => {
  const { nodes, pageInfo } = tasks || {};
  if (isEmpty(nodes)) return <EmptyData />;

  return (
    <>
      <TasksTable
        tasks={nodes!}
        locationParams={locationParams}
        setLocationParams={setLocationParams}
        tableName="legal-entity-deactivate-jobs-table/tasks"
      />
      <Pagination {...pageInfo} />
    </>
  );
};

const LegalEntityDeactivationJobQuery = gql`
  query LegalEntityDeactivationJobQuery(
    $id: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: TaskFilter
    $orderBy: TaskOrderBy
  ) {
    legalEntityDeactivationJob(id: $id) {
      databaseId
      deactivatedLegalEntity {
        id
        name
      }
      status
      tasks(
        first: $first
        last: $last
        before: $before
        after: $after
        filter: $filter
        orderBy: $orderBy
      ) {
        nodes {
          ...JobTasks
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${TasksTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Details;
