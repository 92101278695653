const convertToQueryParams = (
  obj: $TSFixMe,
  keyReplacements: $TSFixMe = {}
) => {
  return new URLSearchParams(
    Object.entries(obj || {}).reduce((acc: $TSFixMe, [key, value]) => {
      if (value) {
        const validKey = keyReplacements[key] || key;
        acc[validKey] = value;
      }
      return acc;
    }, {})
  ).toString();
};

export default convertToQueryParams;
