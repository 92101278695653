import { gql } from "graphql-tag";
import Pagination from "../../../components/Pagination";
import Procedure from "../../PracticalMonitoring/Details/Procedure";
import ServiceRequestCode from "../../PracticalMonitoring/Details/ServiceRequestCode";

export const ImpersonalProceduresQuery = gql`
  query ImpersonalProceduresQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImpersonalProcedureFilter!
    $orderBy: ProcedureOrderBy
  ) {
    impersonalProcedures(
      after: $after
      before: $before
      filter: $filter
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      nodes {
        id
        databaseId
        status
        insertedAt
        code {
          ...ServiceRequestCode
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalEntries
    }
  }
  ${Pagination.fragments.entry}
  ${ServiceRequestCode.fragments.entry}
`;

export const ImpersonalProcedureByIDQuery = gql`
  query ImpersonalProcedureByIDQuery(
    $id: ID!
    $patientId: String!
    $skipAdditionalFields: Boolean!
  ) {
    impersonalProcedure(id: $id, patientId: $patientId) {
      ...Procedure
    }
  }
  ${Procedure.fragments.entry}
`;
