import React from "react";
import { Box, Flex } from "@rebass/emotion";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";

import FullName from "../components/FullName";
import DictionaryValue from "../components/DictionaryValue";
import resetPaginationParams from "../helpers/resetPaginationParams";
import STATUSES from "../helpers/statuses";
import { RemoveItem, SelectedItem } from "./Field/MultiSelectView";
import {
  SearchParamsFilter,
  SearchParams,
  SetSearchParams
} from "./SearchForm";

type SelectedFiltersProps = {
  initialValues: SearchParams;
  onSubmit: SetSearchParams;
};

const SelectedFilters = ({ initialValues, onSubmit }: SelectedFiltersProps) => {
  const {
    filter: {
      legalEntityRelation,
      isSuspended,
      medicalProgram = {},
      form,
      databaseId,
      employeeType,
      position,
      atcCode,
      date: {
        startFrom,
        startTo,
        endFrom,
        endTo,
        insertedAtFrom,
        insertedAtTo
      } = {
        startFrom: "",
        startTo: "",
        endFrom: "",
        endTo: "",
        insertedAtFrom: "",
        insertedAtTo: ""
      },
      startDate: { from, to } = { from: "", to: "" },
      contractorLegalEntity: { name } = { name: "" },
      employeeStatus,
      party: { noTaxId, ...party } = { noTaxId: undefined },
      identity = {},
      personal = {},
      requestAllowed,
      registryNumber,
      legalEntity: { edrData = {} } = {},
      division = {},
      status,
      taxId,
      ingredients = {},
      innmDosages = {},
      medication = {},
      medicalProgramId,
      medicationId,
      isActive,
      reimbursementType,
      medicationRequestAllowed
    } = {}
  } = initialValues;

  const { innm = {} } = ingredients;

  const resetValues = (values: Partial<SearchParamsFilter>) => ({
    ...initialValues,
    ...resetPaginationParams(initialValues),

    filter: {
      ...initialValues.filter,
      ...values
    }
  });

  const RemoveSelected = ({
    reset
  }: {
    reset: Partial<SearchParamsFilter>;
  }) => (
    <RemoveItem onClick={() => onSubmit(resetValues(reset))}>
      <RemoveItemIcon />
    </RemoveItem>
  );

  return (
    <Flex flexWrap="wrap">
      {!isEmpty(medicalProgram.name) && (
        <SelectedItem m={1}>
          <Trans>Medical program</Trans>:<Box ml={1}>{medicalProgram.name}</Box>
          <RemoveSelected
            reset={{ medicalProgram: { ...medicalProgram, name: undefined } }}
          />
        </SelectedItem>
      )}
      {!isEmpty(medicalProgram.mrBlankType) && (
        <SelectedItem m={1}>
          <Trans>Type of medication program request blank</Trans>:
          <Box ml={1}>{medicalProgram.mrBlankType}</Box>
          <RemoveSelected
            reset={{
              medicalProgram: { ...medicalProgram, mrBlankType: undefined }
            }}
          />
        </SelectedItem>
      )}
      {!isEmpty(medicalProgramId) && (
        <SelectedItem m={1}>
          <Trans>Medical program ID</Trans>:<Box ml={1}>{medicalProgramId}</Box>
          <RemoveSelected reset={{ medicalProgramId: undefined }} />
        </SelectedItem>
      )}
      {!isEmpty(medicationId) && (
        <SelectedItem m={1}>
          <Trans>Medication ID</Trans>:<Box ml={1}>{medicationId}</Box>
          <RemoveSelected reset={{ medicationId: undefined }} />
        </SelectedItem>
      )}
      {!isEmpty(isSuspended) && (
        <SelectedItem m={1}>
          {/* @ts-expect-error statuses boolean key */}
          {STATUSES.SUSPENDED[isSuspended]}
          <RemoveSelected reset={{ isSuspended: undefined }} />
        </SelectedItem>
      )}
      {legalEntityRelation && (
        <SelectedItem m={1}>
          <Trans>
            contract of {STATUSES.LEGAL_ENTITY_RELATION[legalEntityRelation]}{" "}
            legal entity
          </Trans>
          <RemoveSelected reset={{ legalEntityRelation: undefined }} />
        </SelectedItem>
      )}
      {(startFrom || startTo) && (
        <SelectedItem m={1}>
          <Trans>Contract start date</Trans>:
          {startFrom && <Box ml={1}>з {i18n.date(startFrom)}</Box>}
          {startTo && <Box ml={1}>по {i18n.date(startTo)}</Box>}
          <RemoveSelected
            reset={{
              date: {
                startFrom: undefined,
                startTo: undefined,
                endFrom,
                endTo,
                insertedAtFrom,
                insertedAtTo
              }
            }}
          />
        </SelectedItem>
      )}
      {(endFrom || endTo) && (
        <SelectedItem m={1}>
          <Trans>Contract end date</Trans>:
          {endFrom && <Box ml={1}>з {i18n.date(endFrom)}</Box>}
          {endTo && <Box ml={1}>по {i18n.date(endTo)}</Box>}
          <RemoveSelected
            reset={{
              date: {
                startFrom,
                startTo,
                endFrom: undefined,
                endTo: undefined,
                insertedAtFrom,
                insertedAtTo
              }
            }}
          />
        </SelectedItem>
      )}
      {(insertedAtFrom || insertedAtTo) && (
        <SelectedItem m={1}>
          <Trans>Contract inserted date</Trans>:
          {insertedAtFrom && <Box ml={1}>з {i18n.date(insertedAtFrom)}</Box>}
          {insertedAtTo && <Box ml={1}>по {i18n.date(insertedAtTo)}</Box>}
          <RemoveSelected
            reset={{
              date: {
                startFrom,
                startTo,
                endFrom,
                endTo,
                insertedAtFrom: undefined,
                insertedAtTo: undefined
              }
            }}
          />
        </SelectedItem>
      )}
      {(from || to) && (
        <SelectedItem m={1}>
          <Trans>Start date</Trans>:
          {from && <Box ml={1}>з {i18n.date(from)}</Box>}
          {to && <Box ml={1}>по {i18n.date(to)}</Box>}
          <RemoveSelected
            reset={{
              startDate: {
                from: undefined,
                to: undefined
              }
            }}
          />
        </SelectedItem>
      )}
      {name && (
        <SelectedItem m={1}>
          <Trans>Legal entity name</Trans>:<Box ml={1}>{name}</Box>
          <RemoveSelected
            reset={{ contractorLegalEntity: { name: undefined } }}
          />
        </SelectedItem>
      )}
      {form && (
        <SelectedItem m={1}>
          <Trans>Form</Trans>:
          <Box ml={1}>
            <DictionaryValue name="MEDICATION_FORM" item={form} />
          </Box>
          <RemoveSelected reset={{ form: undefined }} />
        </SelectedItem>
      )}
      {databaseId && (
        <SelectedItem m={1}>
          <Trans>ID</Trans>:<Box ml={1}>{databaseId}</Box>
          <RemoveSelected reset={{ databaseId: undefined }} />
        </SelectedItem>
      )}
      {employeeType && (
        <SelectedItem m={1}>
          <Trans>Type</Trans>:
          <Box ml={1}>
            <DictionaryValue name="EMPLOYEE_TYPE" item={employeeType} />
          </Box>
          <RemoveSelected reset={{ employeeType: undefined }} />
        </SelectedItem>
      )}
      {position && (
        <SelectedItem m={1}>
          <Trans>Position</Trans>:
          <Box ml={1}>
            <DictionaryValue name="POSITION" item={position} />
          </Box>
          <RemoveSelected reset={{ position: undefined }} />
        </SelectedItem>
      )}
      {employeeStatus && (
        <SelectedItem m={1}>
          <Trans>Employee status</Trans>:
          <Box ml={1}>
            <DictionaryValue name="EMPLOYEE_STATUS" item={employeeStatus} />
          </Box>
          <RemoveSelected reset={{ employeeStatus: undefined }} />
        </SelectedItem>
      )}
      {typeof noTaxId === "boolean" && (
        <SelectedItem m={1}>
          {/* @ts-expect-error statuses boolean key */}
          {STATUSES.NO_TAX_ID[noTaxId]}
          <RemoveSelected reset={{ party: { ...party, noTaxId: undefined } }} />
        </SelectedItem>
      )}
      {atcCode && (
        <SelectedItem m={1}>
          <Trans>ATC Code</Trans>:<Box ml={1}>{atcCode}</Box>
          <RemoveSelected reset={{ atcCode: undefined }} />
        </SelectedItem>
      )}
      {requestAllowed && (
        <SelectedItem m={1}>
          <Trans>Is request allowed</Trans>:
          <Box ml={1}>{STATUSES.YES_NO[requestAllowed]}</Box>
          <RemoveSelected reset={{ requestAllowed: undefined }} />
        </SelectedItem>
      )}
      {registryNumber && (
        <SelectedItem m={1}>
          <Trans>Registry number</Trans>:<Box ml={1}>{registryNumber}</Box>
          <RemoveSelected reset={{ registryNumber: undefined }} />
        </SelectedItem>
      )}
      {identity.firstName && identity.lastName && (
        <SelectedItem m={1}>
          <Trans>Full name</Trans>:
          <Box ml={1}>
            <FullName
              party={{
                firstName: identity.firstName,
                lastName: identity.lastName
              }}
            />
          </Box>
          <RemoveSelected
            reset={{
              identity: {
                ...identity,
                firstName: undefined,
                lastName: undefined
              }
            }}
          />
        </SelectedItem>
      )}
      {identity.number && (
        <SelectedItem m={1}>
          <Trans>Document number</Trans>:<Box ml={1}>{identity.number}</Box>
          <RemoveSelected
            reset={{
              identity: { ...identity, type: undefined, number: undefined }
            }}
          />
        </SelectedItem>
      )}
      {personal.birthDate && (
        <SelectedItem m={1}>
          <Trans>Date of birth</Trans>:
          <Box ml={1}>{i18n.date(personal.birthDate)}</Box>
          <RemoveSelected
            reset={{
              personal: { ...personal, birthDate: undefined }
            }}
          />
        </SelectedItem>
      )}
      {personal.authenticationMethod && (
        <SelectedItem m={1}>
          <Trans>Phone number</Trans>:
          <Box ml={1}>{personal.authenticationMethod.phoneNumber}</Box>
          <RemoveSelected
            reset={{
              personal: { ...personal, authenticationMethod: undefined }
            }}
          />
        </SelectedItem>
      )}
      {edrData.edrpou && (
        <SelectedItem m={1}>
          <Trans>Legal entity edrpou</Trans>:<Box ml={1}>{edrData.edrpou}</Box>
          <RemoveSelected
            reset={{
              legalEntity: { edrData: { ...edrData, edrpou: undefined } }
            }}
          />
        </SelectedItem>
      )}
      {division.databaseId && (
        <SelectedItem m={1}>
          <Trans>Division ID</Trans>:<Box ml={1}>{division.databaseId}</Box>
          <RemoveSelected
            reset={{
              division: { ...division, databaseId: undefined }
            }}
          />
        </SelectedItem>
      )}
      {innm.databaseId && (
        <SelectedItem m={1}>
          <Trans>Ingredient ID</Trans>:<Box ml={1}>{innm.databaseId}</Box>
          <RemoveSelected
            reset={{
              ingredients: {
                ...ingredients,
                innm: { ...innm, databaseId: undefined }
              }
            }}
          />
        </SelectedItem>
      )}
      {innmDosages.databaseId && (
        <SelectedItem m={1}>
          <Trans>INNM dosage ID</Trans>:
          <Box ml={1}>{innmDosages.databaseId}</Box>
          <RemoveSelected
            reset={{
              innmDosages: { ...innmDosages, databaseId: undefined }
            }}
          />
        </SelectedItem>
      )}
      {innmDosages.name && (
        <SelectedItem m={1}>
          <Trans>INNM dosage name</Trans>:<Box ml={1}>{innmDosages.name}</Box>
          <RemoveSelected
            reset={{
              innmDosages: { ...innmDosages, name: undefined }
            }}
          />
        </SelectedItem>
      )}
      {medication.name && (
        <SelectedItem m={1}>
          <Trans>Medication name</Trans>:<Box ml={1}>{medication.name}</Box>
          <RemoveSelected
            reset={{
              medication: { ...medication, name: undefined }
            }}
          />
        </SelectedItem>
      )}
      {medication.databaseId && (
        <SelectedItem m={1}>
          <Trans>Medication ID</Trans>:<Box ml={1}>{medication.databaseId}</Box>
          <RemoveSelected
            reset={{
              medication: { ...medication, databaseId: undefined }
            }}
          />
        </SelectedItem>
      )}
      {medication.manufacturer && medication.manufacturer.name && (
        <SelectedItem m={1}>
          <Trans>Manufacturer name</Trans>:
          <Box ml={1}>{medication.manufacturer.name}</Box>
          <RemoveSelected
            reset={{
              medication: {
                ...medication,
                manufacturer: { ...medication.manufacturer, name: undefined }
              }
            }}
          />
        </SelectedItem>
      )}
      {medication.innmDosages && medication.innmDosages.name && (
        <SelectedItem m={1}>
          <Trans>INNM dosage name</Trans>:
          <Box ml={1}>{medication.innmDosages.name}</Box>
          <RemoveSelected
            reset={{
              medication: {
                ...medication,
                innmDosages: {
                  ...medication.innmDosages,
                  name: undefined
                }
              }
            }}
          />
        </SelectedItem>
      )}
      {medication.innmDosages && medication.innmDosages.databaseId && (
        <SelectedItem m={1}>
          <Trans>INNM dosage ID</Trans>:
          <Box ml={1}>{medication.innmDosages.databaseId}</Box>
          <RemoveSelected
            reset={{
              medication: {
                ...medication,
                innmDosages: {
                  ...medication.innmDosages,
                  databaseId: undefined
                }
              }
            }}
          />
        </SelectedItem>
      )}
      {medication.form && (
        <SelectedItem m={1}>
          <Trans>Medication form</Trans>:
          <Box ml={1}>
            <DictionaryValue name="MEDICATION_FORM" item={medication.form} />
          </Box>
          <RemoveSelected
            reset={{
              medication: { ...medication, form: undefined }
            }}
          />
        </SelectedItem>
      )}
      {medication.atcCode && (
        <SelectedItem m={1}>
          <Trans>ATC Code</Trans>:<Box ml={1}>{medication.atcCode}</Box>
          <RemoveSelected
            reset={{
              medication: { ...medication, atcCode: undefined }
            }}
          />
        </SelectedItem>
      )}
      {medication.formPharm && (
        <SelectedItem m={1}>
          <Trans>Pharmaceutical form</Trans>:
          <Box ml={1}>
            <DictionaryValue
              name="FORM_PHARM_LIST"
              item={medication.formPharm}
            />
          </Box>
          <RemoveSelected
            reset={{
              medication: { ...medication, formPharm: undefined }
            }}
          />
        </SelectedItem>
      )}
      {medication.drlzSkuId && (
        <SelectedItem m={1}>
          <Trans>Product ID in DRLZ register</Trans>:
          <Box ml={1}>{medication.drlzSkuId}</Box>
          <RemoveSelected
            reset={{
              medication: { ...medication, drlzSkuId: undefined }
            }}
          />
        </SelectedItem>
      )}
      {status && (
        <SelectedItem m={1}>
          <Trans>Status</Trans>:
          <Box ml={1}>{STATUSES.SELECTED_FILTERS_STATUSES[status]}</Box>
          <RemoveSelected reset={{ status: undefined }} />
        </SelectedItem>
      )}
      {taxId && (
        <SelectedItem m={1}>
          <Trans>INN</Trans>:<Box ml={1}>{taxId}</Box>
          <RemoveSelected reset={{ taxId: undefined }} />
        </SelectedItem>
      )}
      {isActive && (
        <SelectedItem m={1}>
          <Trans>Status</Trans>:{/* @ts-expect-error statuses boolean key */}
          <Box ml={1}>{STATUSES.ACTIVE_STATUS_M[isActive]}</Box>
          <RemoveSelected reset={{ isActive: undefined }} />
        </SelectedItem>
      )}
      {reimbursementType && (
        <SelectedItem m={1}>
          <Trans>Reimbursement type</Trans>:
          <Box ml={1}>
            <DictionaryValue
              name="REIMBURSEMENT_TYPE"
              item={reimbursementType}
            />
          </Box>
          <RemoveSelected reset={{ reimbursementType: undefined }} />
        </SelectedItem>
      )}
      {medicationRequestAllowed && (
        <SelectedItem m={1}>
          <Trans>Prescription creation</Trans>:
          <Box ml={1}>
            {/* @ts-expect-error statuses boolean key */}
            {STATUSES.MEDICATION_REQUEST_ALLOWED[medicationRequestAllowed]}
          </Box>
          <RemoveSelected reset={{ medicationRequestAllowed: undefined }} />
        </SelectedItem>
      )}
    </Flex>
  );
};

export default SelectedFilters;
