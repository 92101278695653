import React from "react";
import { gql } from "graphql-tag";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import {
  ServiceRequestCode as TServiceRequestCode,
  ActivityProduct
} from "@ehealth/ehealth-ua.schema";

import DefinitionListView from "../../../components/DefinitionListView";

import Reference from "./Reference";
import { LABEL_WIDTH } from "./Episode";

const SERVICE_REQUEST_CODE_TYPES: {
  values: any;
  pathSlugs: { [key: string]: string };
} = {
  values: {
    Service: t`Service`,
    ServiceGroup: t`Service Group`,
    INNMDosage: t`Medication`
  },
  pathSlugs: {
    Service: "/services",
    ServiceGroup: "/service-groups",
    INNMDosage: "/innm-dosages"
  }
};

type ServiceRequestCodeProps = {
  serviceRequestCode: TServiceRequestCode | ActivityProduct;
};

const ServiceRequestCode = ({
  serviceRequestCode
}: ServiceRequestCodeProps) => {
  const { i18n } = useLingui();
  if (!serviceRequestCode) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labelWidth={LABEL_WIDTH}
      labels={{
        serviceRequestCode: i18n._(
          serviceRequestCode.__typename &&
            SERVICE_REQUEST_CODE_TYPES.values[serviceRequestCode.__typename]
        )
      }}
      data={{
        serviceRequestCode: (
          <Reference
            fontSize={14}
            customHeaderProps={{ fontWeight: "normal", width: "auto" }}
            linkPath={`${
              serviceRequestCode.__typename &&
              SERVICE_REQUEST_CODE_TYPES.pathSlugs[
                serviceRequestCode.__typename
              ]
            }/${serviceRequestCode.id}`}
            linkDisplayValue={serviceRequestCode.name}
            linkWrapperProps={{
              mt: 0
            }}
            code={(serviceRequestCode as TServiceRequestCode).code}
          />
        )
      }}
    />
  );
};

export default ServiceRequestCode;

ServiceRequestCode.fragments = {
  entry: gql`
    fragment ServiceRequestCode on ServiceRequestCode {
      __typename
      ...Service
      ...ServiceGroup
    }
    fragment Service on Service {
      id
      name
      code
    }
    fragment ServiceGroup on ServiceGroup {
      id
      name
      code
    }
  `,
  entryWithINNMDosage: gql`
    fragment ActivityProduct on ActivityProduct {
      __typename
      ...Service
      ...ServiceGroup
      ...INNMDosage
    }
    fragment Service on Service {
      id
      name
      code
    }
    fragment ServiceGroup on ServiceGroup {
      id
      name
      code
    }
    fragment INNMDosage on INNMDosage {
      id
      name
    }
  `
};
