import { useState } from "react";
import Cookie from "js-cookie";

const { scope = "" } = Cookie.getJSON("meta") || {};
const INITIAL_SCOPES = scope.split(" ");

export const useAbility = (actions: string | string[], resources: string) => {
  const [scopes] = useState(INITIAL_SCOPES);

  const actionsArray: string[] = Array.isArray(actions) ? actions : [actions];
  const resourcesArray: string[] = Array.isArray(resources)
    ? resources
    : [resources];

  const getRequiredScopes = () =>
    resourcesArray.reduce((prevScopes: string[], resource: string) => {
      const scopes = actionsArray.map((action) => `${resource}:${action}`);
      return [...prevScopes, ...scopes];
    }, []);

  const requiredScopes = getRequiredScopes();

  const isAble = Array.prototype.every.call(requiredScopes, (scope) =>
    scopes.includes(scope)
  );

  return [isAble, scopes];
};
