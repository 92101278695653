import _objectWithoutProperties from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
// Original implementation by Gott Phusit
// https://medium.com/netscape/connecting-react-component-to-redux-store-with-render-callback-53fd044bb42b
// https://gist.github.com/go1t/2c40beee8ad751342d6978f1f11b9b7a#file-connect-jsx

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
var Connect = function Connect(_ref) {
  var children = _ref.children,
    _ref$render = _ref.render,
    render = _ref$render === void 0 ? children : _ref$render,
    props = _objectWithoutProperties(_ref, ["children", "render"]);
  return render(props);
};
var noop = function noop() {
  return {};
};
export default connect(function (state, _ref2) {
  var _ref2$mapStateToProps = _ref2.mapStateToProps,
    mapStateToProps = _ref2$mapStateToProps === void 0 ? noop : _ref2$mapStateToProps;
  return mapStateToProps(state);
}, function (dispatch, _ref3) {
  var _ref3$mapDispatchToPr = _ref3.mapDispatchToProps,
    mapDispatchToProps = _ref3$mapDispatchToPr === void 0 ? noop : _ref3$mapDispatchToPr;
  return typeof mapDispatchToProps === "object" ? bindActionCreators(mapDispatchToProps, dispatch) : mapDispatchToProps(dispatch);
})(Connect);