import React from "react";
import { Trans } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";
import { Flex, Box } from "@rebass/emotion";
import Composer from "react-composer";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Validation } from "@edenlabllc/ehealth-components";
import { Dictionary } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

import STATUSES from "../../../helpers/statuses";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 3}>
      <SearchField.INNMDosage
        name="filter.innmdosage"
        filteredParams={["name"]}
      />
    </Box>
    <Box px={1} width={1 / 3}>
      <Trans
        id="Enter ingredient name"
        render={({ translation }) => (
          <SearchField.INNM
            name="filter.ingredients.innm.name"
            placeholder={translation}
            label={<Trans id="Ingredient name" />}
            getItemByKey="name"
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 3}>
      <SearchField.Status name="filter.isActive" status="ACTIVE_STATUS_F" />
    </Box>
  </Flex>
);

const SecondarySearchFields = () => (
  <>
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Enter ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.databaseId"
              label={<Trans id="INNM dosage ID" />}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
            />
          )}
        />
        <Validation.Matches
          field="filter.databaseId"
          options={UUID_PATTERN}
          message="Invalid number"
        />
      </Box>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Enter ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.ingredients.innm.databaseId"
              label={<Trans id="Ingredient ID" />}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
            />
          )}
        />
        <Validation.Matches
          field="filter.ingredients.innm.databaseId"
          options={UUID_PATTERN}
          message="Invalid number"
        />
      </Box>
      <Box px={1} width={1 / 3}>
        <Composer
          components={[
            <DictionaryValue name="MEDICATION_FORM" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="Select option" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            Dictionary["values"],
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.form"
              label={<Trans id="Form" />}
              placeholder={translation}
              items={Object.keys(dict)}
              itemToString={(item: string) => dict[item] || translation}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
    </Flex>
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <Composer
          components={[
            <DictionaryValue name="MR_BLANK_TYPES" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="Select option" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            Dictionary["values"],
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.mrBlankType"
              label={<Trans id="Type of Medication request blank" />}
              placeholder={translation}
              items={Object.keys(dict)}
              itemToString={(item: string) => dict[item] || translation}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Select option"
          render={({ translation }) => (
            <Field.Select
              name="filter.dosageFormIsDosed"
              label={<Trans id="Is Dosage Form dosed" />}
              items={Object.keys(STATUSES.YES_NO_NULL)}
              itemToString={(item: boolean | null) =>
                // @ts-expect-error statuses boolean key
                STATUSES.YES_NO_NULL[item] || translation
              }
              variant="select"
              emptyOption
              filterOptions={{
                // @ts-expect-error statuses boolean key
                keys: [(item: boolean | null) => STATUSES.YES_NO_NULL[item]]
              }}
            />
          )}
        />
      </Box>
    </Flex>
  </>
);

export { PrimarySearchFields, SecondarySearchFields };
