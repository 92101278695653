import { gql } from "graphql-tag";
import Pagination from "../../../components/Pagination";
import CodeableConcept from "../../../components/CodeableConcept";
import Immunization from "../../PracticalMonitoring/Details/Immunization";

export const ImpersonalImmunizationsQuery = gql`
  query ImpersonalImmunizationsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImpersonalImmunizationFilter!
    $orderBy: ImmunizationOrderBy
  ) {
    impersonalImmunizations(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status
        insertedAt
        vaccineCode {
          ...CodeableConcept
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
  ${CodeableConcept.fragments!.entry}
`;

export const ImpersonalImmunizationByIDQuery = gql`
  query ImpersonalImmunizationQuery(
    $id: ID!
    $patientId: String!
    $skipAdditionalFields: Boolean!
  ) {
    impersonalImmunization(id: $id, patientId: $patientId) {
      ...Immunization
    }
  }
  ${Immunization.fragments.entry}
`;
