import React, { useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Box, Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { Form, Validation } from "@edenlabllc/ehealth-components";
import {
  fieldNameDenormalizer,
  convertObjectKeys
} from "@edenlabllc/ehealth-utils";
import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import { RuleEngineRule } from "@ehealth/ehealth-ua.schema";

import Steps from "../../../../components/Steps";
import Line from "../../../../components/Line";
import DefinitionListView from "../../../../components/DefinitionListView";
import Button from "../../../../components/Button";
import * as Field from "../../../../components/Field";
import DictionaryValue from "../../../../components/DictionaryValue";
import UnpocessableEntityModalError from "../../../../components/UnpocessableEntityModalError";

import {
  getErrorCode,
  getErrorMessage
} from "../../../../helpers/errorHelpers";

import env from "../../../../env";

type DeactivateProps = RouteComponentProps<{
  id: string;
}>;

const Deactivate = ({
  // @ts-expect-error location state
  location: { state },
  id
}: DeactivateProps) => {
  return (
    <>
      <Box pt={5} px={5}>
        <Steps.List>
          <Steps.Item to="./" state={state}>
            <Trans>Deactivation reason</Trans>
          </Steps.Item>
          <Steps.Item to="./confirm" state={state} disabled>
            <Trans>approve by EDS</Trans>
          </Steps.Item>
        </Steps.List>
      </Box>
      <Header state={state} />
      <Box px={5}>
        <Line />
      </Box>
      <Router>
        <GeneralForm path="/" />
        <Confirmation path="/confirm" />
      </Router>
    </>
  );
};

export default Deactivate;

const Header = ({ state }: { state: { ruleEngineRule: RuleEngineRule } }) => {
  const {
    ruleEngineRule: { name, code, description }
  } = state;

  return (
    <Box px={5}>
      <DefinitionListView
        labels={{
          name: <Trans>Rule engine rule title</Trans>,
          system: <Trans>Coding system</Trans>,
          code: <Trans>Code</Trans>,
          ...(description && {
            description: <Trans>Description</Trans>
          })
        }}
        data={{
          name,
          system: (
            <DictionaryValue
              name="eHealth/rule_engine_dictionaries"
              item={code.system}
            />
          ),
          code: <DictionaryValue name={code.system} item={code.code} />,
          description
        }}
        labelWidth="200px"
      />
    </Box>
  );
};

const GeneralForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <Box p={5} width={1 / 2}>
    <Form
      onSubmit={(data: { deactivationReason: string }) => {
        navigate!("./confirm", {
          state: {
            ...state,
            ...data
          }
        });
      }}
      initialValues={state || null}
    >
      <Box>
        <Trans
          id="Deactivation reason hint"
          render={({ translation }) => (
            <Field.Textarea
              name="deactivationReason"
              label={<Trans id="Deactivation reason" />}
              placeholder={translation}
              rows={5}
              maxLength={255}
              showLengthHint
            />
          )}
        />
        <Trans
          id="Required field"
          render={({ translation }) => (
            <Validation.Required
              field="deactivationReason"
              message={translation}
            />
          )}
        />
      </Box>
      <Flex pt={5} mb={100}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate!("../")}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Button variant="green" width={140}>
            <Trans>Next</Trans>
          </Button>
        </Box>
      </Flex>
    </Form>
  </Box>
);

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const [error, setError] = useState(null);
  const {
    deactivationReason,
    ruleEngineRule: { databaseId }
  } = state;

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          deactivationReason: <Trans>Basis of group deactivation</Trans>
        }}
        data={{ deactivationReason }}
        labelWidth="200px"
      />
      <Flex pt={5} mb={100}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate!("../", { state })}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Signer.Parent
          url={env.REACT_APP_SIGNER_URL}
          features={{
            width: 640,
            height: 589
          }}
        >
          {/* @ts-expect-error signData */}
          {({ signData }) => (
            <Mutation mutation={DeactivateRuleEngineRuleMutation}>
              {(deactivateRuleEngineRule: MutationFunction) => (
                <Box>
                  <Button
                    variant="green"
                    width={250}
                    onClick={async () => {
                      const { signedContent } = await signData(
                        convertObjectKeys(
                          {
                            rule_id: databaseId,
                            deactivationReason
                          },
                          fieldNameDenormalizer
                        )
                      );

                      try {
                        await deactivateRuleEngineRule({
                          variables: {
                            input: {
                              signedContent: {
                                content: signedContent,
                                encoding: "BASE64"
                              }
                            }
                          }
                        });
                        await navigate!("../../");
                      } catch (error) {
                        if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                          setError(getErrorMessage(error));
                        }
                      }
                    }}
                  >
                    <Trans>Approve by EDS</Trans>
                  </Button>
                </Box>
              )}
            </Mutation>
          )}
        </Signer.Parent>
        {error && (
          <UnpocessableEntityModalError errorMessage={error} isModalOpen />
        )}
      </Flex>
    </Box>
  );
};

const DeactivateRuleEngineRuleMutation = gql`
  mutation DeactivateRuleEngineRuleMutation(
    $input: DeactivateRuleEngineRuleInput!
  ) {
    deactivateRuleEngineRule(input: $input) {
      ruleEngineRule {
        id
        databaseId
        name
        deactivationReason
        isActive
      }
    }
  }
`;
