import { EDRPOU_PATTERN, PASSPORT_PATTERN } from "./validationPatterns";

export const CONTRACT_PATTERN =
  "^[0-9A-Za-zА-ЯҐЇІЄа-яґїіє]{4}-[0-9A-Za-zА-ЯҐЇІЄа-яґїіє]{4}-[0-9A-Za-zА-ЯҐЇІЄа-яґїіє]{4}$";
export const SEARCH_CONTRACT_PATTERN = `(${EDRPOU_PATTERN})|(${PASSPORT_PATTERN})|(${CONTRACT_PATTERN})`;

export const GLOBAL_BUDGET_CONTRACT_PATTERN =
  "^[0-9A-Za-zА-ЯҐЇІЄа-яґїіє]{4}-[0-9A-Za-zА-ЯҐЇІЄа-яґїіє]{4}-[0-9A-Za-zА-ЯҐЇІЄа-яґїіє]{4}-[0-9A-Za-zА-ЯҐЇІЄа-яґїіє]{1}$";
export const SEARCH_GLOBAL_BUDGET_CONTRACT_PATTERN = `(${EDRPOU_PATTERN})|(${GLOBAL_BUDGET_CONTRACT_PATTERN})`;

export const CONTRACT_TYPES = {
  CAPITATION: "CAPITATION",
  REIMBURSEMENT: "REIMBURSEMENT",
  GLOBAL_BUDGET: "GLOBAL_BUDGET"
};
