import _slicedToArray from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import fromPairs from "lodash/fromPairs";
var convertObjectKeys = function convertObjectKeys(object, converter) {
  var keypath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  if (object == null || typeof object !== "object") {
    return object;
  }
  if (Array.isArray(object)) {
    return object.map(function (o, index) {
      return convertObjectKeys(o, converter, [].concat(_toConsumableArray(keypath), [String(index)]));
    });
  }
  return fromPairs(Object.entries(object).map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    var nestedKeyPath = [].concat(_toConsumableArray(keypath), [key]);
    var convertedKey = converter(key, nestedKeyPath);
    var convertedValue = convertObjectKeys(value, converter, nestedKeyPath);
    return [convertedKey, convertedValue];
  }));
};
export default convertObjectKeys;