import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { MedicationRequest as TMedicationRequest } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import FullName from "../../../components/FullName";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Line from "../../../components/Line";
import Link from "../../../components/Link";
import Tabs from "../../../components/Tabs";

import dateFormatter from "../../../helpers/dateFormatter";
import paramToBase64 from "../../../helpers/paramToBase64";
import { MonitoringDetailsPageProps } from "../../../helpers/types";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import { ImpersonalMedicationDispensesQuery } from "../../ClinicalMonitoring/graphql/MedicationDispenses";
import CreateConclusion from "../../ClinicalMonitoring/CreateConclusion";
import getConclusionData from "../../ClinicalMonitoring/getConclusionData";
import Conclusions from "../../ClinicalMonitoring/Conclusions";
import { MEDICATION_REQUESTS_STATUSES } from "../Search/MedicationRequestsTab";
import MedicationDispensesTab from "../Search/MedicationDispensesTab";
import DetailsPageBreadcrumbs from "./DetailsPageBreadcrumbs";
import Reference from "./Reference";
import { LABEL_WIDTH } from "./Episode";

const MedicationRequest = ({
  query,
  queryVariables = {},
  itemId,
  id,
  uri,
  patientId,
  isClinicalMonitoring = false
}: MonitoringDetailsPageProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="medication_request"
  >
    <Box p={6}>
      <DetailsPageBreadcrumbs
        id={id}
        currentStepName={<Trans>Medication request details</Trans>}
        isClinicalMonitoring={isClinicalMonitoring}
      />
      <Query
        query={query}
        fetchPolicy="network-only"
        variables={{
          ...queryVariables,
          id: itemId,
          ...(isClinicalMonitoring && { patientId })
        }}
      >
        {({
          loading,
          data = {},
          error
        }: QueryResult<{ [key: string]: TMedicationRequest }>) => {
          const medicationRequest = isClinicalMonitoring
            ? data.impersonalMedicationRequest
            : data.medicationRequest;

          return (
            <LoadingOverlay loading={loading}>
              <Box mt={4}>
                {!isEmpty(medicationRequest) && !error && (
                  <>
                    <HeaderDefinitions
                      medicationRequest={medicationRequest}
                      isClinicalMonitoring={isClinicalMonitoring}
                      patientId={patientId!}
                    />

                    <Tabs.Nav>
                      <Tabs.NavItem to={"./general-info"}>
                        <Trans>General info</Trans>
                      </Tabs.NavItem>
                      <Tabs.NavItem to={"./medication-dispense"}>
                        <Trans>Medication dispense</Trans>
                      </Tabs.NavItem>
                    </Tabs.Nav>

                    <Tabs.Content>
                      <Router>
                        <Redirect from="/" to={`${uri}/general-info`} />
                        <GeneralInfoTab
                          path="general-info"
                          medicationRequest={medicationRequest}
                          isClinicalMonitoring={isClinicalMonitoring}
                          patientId={patientId!}
                        />
                        <MedicationDispensesTab
                          path="medication-dispense"
                          predefinedMedicationRequestId={
                            medicationRequest.databaseId
                          }
                          query={
                            isClinicalMonitoring
                              ? ImpersonalMedicationDispensesQuery
                              : undefined
                          }
                          isClinicalMonitoring={isClinicalMonitoring}
                          patientId={patientId}
                        />
                      </Router>
                    </Tabs.Content>
                  </>
                )}
              </Box>
            </LoadingOverlay>
          );
        }}
      </Query>
      <Conclusions
        isClinicalMonitoring={isClinicalMonitoring}
        patientId={patientId!}
        itemId={itemId!}
      />
    </Box>
  </Ability>
);

export default MedicationRequest;

type GeneralInfoTabProps = RouteComponentProps & {
  medicationRequest: TMedicationRequest;
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const GeneralInfoTab = ({
  medicationRequest,
  isClinicalMonitoring,
  patientId
}: GeneralInfoTabProps) => (
  <Box mt={4}>
    <TopBodyDefinitions medicationRequest={medicationRequest} />
    {!isEmpty(medicationRequest.context) && (
      <Box mt={2}>
        <Reference
          fontSize={14}
          header={<Trans>Context</Trans>}
          linkPath={
            isClinicalMonitoring
              ? `../../../../encounter/${patientId}/${paramToBase64(
                  "Encounter",
                  medicationRequest.context.identifier.value
                )}`
              : `../../../encounter/${paramToBase64(
                  "Encounter",
                  medicationRequest.context.identifier.value
                )}`
          }
          linkDisplayValue={
            medicationRequest.context.displayValue
              ? medicationRequest.context.displayValue
              : medicationRequest.context.identifier.value
          }
        />
      </Box>
    )}
    <BottomBodyDefinitions medicationRequest={medicationRequest} />
  </Box>
);

export const getMedicationRequestQueryVariables = (id?: string) => ({
  personId: id
});

type HeaderDefinitionsProps = {
  medicationRequest: TMedicationRequest;
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const HeaderDefinitions = ({
  medicationRequest,
  isClinicalMonitoring,
  patientId
}: HeaderDefinitionsProps) => {
  const { i18n } = useLingui();
  if (isEmpty(medicationRequest)) return null;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <div>
          <DefinitionListView
            labels={{
              databaseId: <Trans>ID</Trans>,
              status: <Trans>Status</Trans>
            }}
            data={{
              ...medicationRequest,
              status: i18n._(
                MEDICATION_REQUESTS_STATUSES.values[
                  medicationRequest.status.toLowerCase()
                ]
              )
            }}
            color="#7F8FA4"
            labelWidth={LABEL_WIDTH}
          />
        </div>
        {isClinicalMonitoring && (
          <CreateConclusion
            initialData={getConclusionData(
              patientId,
              "medication_request",
              medicationRequest.databaseId
            )}
          />
        )}
      </Flex>
      <Line />
    </>
  );
};

const TopBodyDefinitions = ({
  medicationRequest
}: {
  medicationRequest: TMedicationRequest;
}) => {
  if (isEmpty(medicationRequest)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labelWidth={LABEL_WIDTH}
      labels={{
        requestNumber: <Trans>Request number</Trans>,
        intent: <Trans>Intent</Trans>,
        category: <Trans>Category</Trans>,
        legalEntity: (
          <Trans id="Medication request legal entity">Legal entity</Trans>
        ),
        division: <Trans>Division</Trans>,
        employee: <Trans>Employee</Trans>,
        medication: <Trans>Medication</Trans>,
        medicationQty: <Trans>Medication quantity</Trans>,
        medicationRequestRequestsId: (
          <Trans>Medication request request ID</Trans>
        ),
        ...(medicationRequest.medicalProgram && {
          medicalProgram: <Trans>Medical program</Trans>
        })
      }}
      data={{
        ...medicationRequest,
        intent: (
          <DictionaryValue
            name="MEDICATION_REQUEST_INTENT"
            item={medicationRequest.intent.toLowerCase()}
          />
        ),
        category: (
          <DictionaryValue
            name="MEDICATION_REQUEST_CATEGORY"
            item={medicationRequest.category.toLowerCase()}
          />
        ),
        legalEntity: (
          <Box>
            <Link
              to={`/legal-entities/${medicationRequest.legalEntity.id}`}
              fontSize={14}
            >
              {medicationRequest.legalEntity.name
                ? medicationRequest.legalEntity.name
                : medicationRequest.legalEntity.databaseId}
            </Link>
          </Box>
        ),
        division: (
          <Box>
            <Link
              to={`/legal-entities/${medicationRequest.legalEntity.id}/divisions/${medicationRequest.division.id}/`}
              fontSize={14}
            >
              {medicationRequest.division.name
                ? medicationRequest.division.name
                : medicationRequest.division.databaseId}
            </Link>
          </Box>
        ),
        employee: (
          <Box>
            <Link
              to={`/employees/${medicationRequest.employee.id}`}
              fontSize={14}
            >
              {!isEmpty(medicationRequest.employee.party) ? (
                <FullName party={medicationRequest.employee.party} />
              ) : (
                medicationRequest.employee.databaseId
              )}
            </Link>
          </Box>
        ),
        medication: (
          <Box>
            <Link
              to={`/innm-dosages/${medicationRequest.medication.id}/`}
              fontSize={14}
            >
              {medicationRequest.medication.name
                ? medicationRequest.medication.name
                : medicationRequest.medication.databaseId}
            </Link>
          </Box>
        ),
        ...(medicationRequest.medicalProgram && {
          medicalProgram: (
            <Box>
              <Link
                to={`/medical-programs/${medicationRequest.medicalProgram.id}/`}
                fontSize={14}
              >
                {medicationRequest.medicalProgram.name
                  ? medicationRequest.medicalProgram.name
                  : medicationRequest.medicalProgram.databaseId}
              </Link>
            </Box>
          )
        })
      }}
    />
  );
};

const BottomBodyDefinitions = ({
  medicationRequest
}: {
  medicationRequest: TMedicationRequest;
}) => {
  const { i18n } = useLingui();
  if (isEmpty(medicationRequest)) return null;

  return (
    <Box mt={4}>
      <DefinitionListView
        fontSize={14}
        labelWidth={LABEL_WIDTH}
        labels={{
          dispenseValidFrom: <Trans>Dispense valid from</Trans>,
          dispenseValidTo: <Trans>Dispense valid to</Trans>,
          createdAt: <Trans>Created at</Trans>,
          startedAt: (
            <Trans id="Medication request started at">Started at</Trans>
          ),
          endedAt: <Trans id="Medication request ended at">Ended at</Trans>,
          ...(medicationRequest.rejectedAt && {
            rejectedAt: <Trans>Rejected at</Trans>
          }),
          ...(medicationRequest.rejectedBy && {
            rejectedBy: <Trans>Rejected by</Trans>
          }),
          ...(medicationRequest.rejectReason && {
            rejectReason: (
              <Trans id="Medication request reject reason">Reject reason</Trans>
            )
          }),
          insertedAt: <Trans>Inserted at</Trans>,
          updatedAt: <Trans>Updated at</Trans>
        }}
        data={{
          ...medicationRequest,
          dispenseValidFrom: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            medicationRequest.dispenseValidFrom
          ),
          dispenseValidTo: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            medicationRequest.dispenseValidTo
          ),
          createdAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            medicationRequest.createdAt
          ),
          startedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            medicationRequest.startedAt
          ),
          endedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            medicationRequest.endedAt
          ),
          ...(medicationRequest.rejectedAt && {
            rejectedAt: dateFormatter(
              i18n.locale,
              DATE_TIME_FORMAT,
              medicationRequest.rejectedAt
            )
          }),
          insertedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            medicationRequest.insertedAt
          ),
          updatedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            medicationRequest.updatedAt
          )
        }}
      />
    </Box>
  );
};

MedicationRequest.fragments = {
  entry: gql`
    fragment MedicationRequest on MedicationRequest {
      id
      databaseId
      status
      requestNumber
      intent
      category
      context {
        ...Reference
      }
      legalEntity {
        databaseId
        name
        id
      }
      division {
        databaseId
        name
        id
      }
      employee {
        databaseId
        party {
          id
          ...FullName
        }
        id
      }
      medication {
        databaseId
        name
        id
      }
      medicationQty
      medicationRequestRequestsId
      medicalProgram {
        databaseId
        name
        id
      }
      dispenseValidFrom
      dispenseValidTo
      createdAt
      startedAt
      endedAt
      rejectReason
      rejectedAt
      rejectedBy
      insertedAt
      updatedAt
    }
    ${Reference.fragments.entry}
    ${FullName.fragments.entry}
  `
};

export const MedicationRequestByIDQuery = gql`
  query MedicationRequestByIDQuery($id: ID!, $personId: ID!) {
    medicationRequest(id: $id, personId: $personId) {
      ...MedicationRequest
    }
  }
  ${MedicationRequest.fragments.entry}
`;
