import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";

import Search from "./Search";

const PendingDeclarations = ({ uri }: RouteComponentProps) => (
  <Router>
    <Redirect from="/" to={`${uri}/search`} />
    <Search path="search/*" />
  </Router>
);

export default PendingDeclarations;
