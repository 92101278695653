import * as React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, isEmpty } from "lodash";

import { Innm, InnmConnection } from "@ehealth/ehealth-ua.schema";

import * as Field from "../Field";

type SearchINNMFieldProps = {
  name: string;
  placeholder: React.ReactNode;
  label?: React.ReactElement;
  getItemByKey: keyof Innm;
};

const SearchINNMField = ({
  name,
  placeholder,
  label,
  getItemByKey
}: SearchINNMFieldProps) => {
  return (
    <Query
      query={INNMsQuery}
      fetchPolicy="cache-first"
      variables={{
        skip: true
      }}
    >
      {({
        data,
        refetch: refetchINNMs
      }: QueryResult<{ innms: InnmConnection }>) => {
        const { innms: { nodes: innms = [] } = {} } = data || {};

        return (
          <Field.Select
            name={name}
            label={label || <Trans id="INNM" />}
            placeholder={placeholder}
            items={innms!.map((innm) => innm && innm[getItemByKey])}
            filter={(innms: Innm[]) => innms}
            onInputValueChange={debounce(
              (value, { selectedItem, inputValue }) =>
                !isEmpty(name) &&
                selectedItem !== inputValue &&
                refetchINNMs({
                  skip: false,
                  first: 20,
                  filter: { [getItemByKey]: value }
                }),
              1000
            )}
          />
        );
      }}
    </Query>
  );
};

const INNMsQuery = gql`
  query INNMsQuery($first: Int, $filter: INNMFilter, $skip: Boolean! = false) {
    innms(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        name
        nameOriginal
      }
    }
  }
`;

export default SearchINNMField;
