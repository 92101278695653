import React from "react";
import { Box, Flex } from "@rebass/emotion";

import * as SearchField from "../../../components/SearchField";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 3}>
      <SearchField.Status name="filter.status" status="JOBS" />
    </Box>
  </Flex>
);

export { PrimarySearchFields };
