import * as React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex } from "@rebass/emotion";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { InnmDosage, InnmDosageConnection } from "@ehealth/ehealth-ua.schema";

import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import Dosage from "../../../components/Dosage";
import Link from "../../../components/Link";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import { dosageFormIsDosedValues } from "../constants";

type INNMDosagesTableProps = {
  innmDosages: InnmDosageConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  tableName?: string;
};

const INNMDosagesTable = ({
  innmDosages,
  locationParams,
  setLocationParams,
  tableName = "innmDosages/search"
}: INNMDosagesTableProps) => (
  <Table
    data={innmDosages}
    header={{
      databaseId: <Trans>ID</Trans>,
      name: <Trans>INNM dosage name</Trans>,
      form: <Trans>Medication form</Trans>,
      dosageFormIsDosed: <Trans>Is Dosage Form dosed</Trans>,
      mrBlankType: <Trans>Type of Medication request blank</Trans>,
      dailyDosage: <Trans>Daily dosage</Trans>,
      isActive: <Trans>Status</Trans>,
      insertedAt: <Trans>Inserted at</Trans>,
      details: <Trans>Details</Trans>
    }}
    renderRow={({
      id,
      form,
      mrBlankType,
      dosageFormIsDosed,
      isActive,
      insertedAt,
      dailyDosage,
      ...innmDosage
    }: InnmDosage) => {
      return {
        ...innmDosage,
        form: <DictionaryValue name="MEDICATION_FORM" item={form} />,
        dosageFormIsDosed: (
          <Flex justifyContent="center">
            {/* @ts-expect-error statuses boolean key */}
            {dosageFormIsDosedValues[dosageFormIsDosed]}
          </Flex>
        ),
        mrBlankType: mrBlankType && (
          <DictionaryValue name="MR_BLANK_TYPES" item={mrBlankType} />
        ),
        dailyDosage: <Dosage dosage={dailyDosage} />,
        isActive: (
          <Badge
            type="ACTIVE_STATUS_F"
            name={isActive}
            variant={!isActive}
            display="block"
          />
        ),
        insertedAt: i18n.date(insertedAt),
        details: (
          <Link to={`../${id}`} fontWeight="bold">
            <Trans>Show details</Trans>
          </Link>
        )
      };
    }}
    sortableFields={["form", "name", "insertedAt"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    tableName={tableName}
    hiddenFields="dailyDosage,maxDailyDosage,dosageFormIsDosed"
  />
);

INNMDosagesTable.fragments = {
  entry: gql`
    fragment INNMDosages on INNMDosage {
      id
      databaseId
      name
      form
      dailyDosage
      isActive
      insertedAt
      dosageFormIsDosed
      mrBlankType
    }
  `
};

export default INNMDosagesTable;
