import React, { useCallback } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import styled from "@emotion/styled";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { DropDownButton, PencilIcon } from "@edenlabllc/ehealth-icons";
import { ForbiddenGroup, Scalars } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Button from "../../../components/Button";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import Tabs from "../../../components/Tabs";
import { TLocationParams } from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";

import Header from "./Header";
import GeneralInfo from "./GeneralInfo";
import Codes from "./Codes";
import Services from "./Services";

type DetailsProps = RouteComponentProps<{
  id: Scalars["ID"];
}>;

const Details = ({ id, navigate }: DetailsProps) => {
  const onDeactivateGroup = useCallback(
    (forbiddenGroup: ForbiddenGroup) => {
      navigate!("./deactivate", {
        state: { forbiddenGroup }
      });
    },
    [navigate]
  );

  const onAddGroupElement = useCallback(
    (forbiddenGroup: ForbiddenGroup) => {
      navigate!("./add-group-element", {
        state: { forbiddenGroup }
      });
    },
    [navigate]
  );

  const onDeactivateGroupElement = useCallback(
    (forbiddenGroup: ForbiddenGroup) => {
      navigate!("./deactivate-group-element", {
        state: { forbiddenGroup }
      });
    },
    [navigate]
  );

  return (
    <LocationParams>
      {({ locationParams }: TLocationParams) => {
        const { filter = {} } = locationParams || {};

        const {
          forbiddenGroupCodesFilter = {},
          forbiddenGroupServicesFilter = {}
        } = filter;

        if (forbiddenGroupCodesFilter.isActive) {
          forbiddenGroupCodesFilter.isActive = JSON.parse(
            forbiddenGroupCodesFilter.isActive
          );
        }
        if (forbiddenGroupServicesFilter.isActive) {
          forbiddenGroupServicesFilter.isActive = JSON.parse(
            forbiddenGroupServicesFilter.isActive
          );
        }

        return (
          <Query
            query={ForbiddenGroupQuery}
            variables={{
              id,
              forbiddenGroupCodesFilter,
              forbiddenGroupServicesFilter,
              ...pagination(locationParams)
            }}
            fetchPolicy="network-only"
          >
            {({
              loading,
              data
            }: QueryResult<{ forbiddenGroup: ForbiddenGroup }>) => {
              if (isEmpty(data) || isEmpty(data.forbiddenGroup)) return null;
              const {
                databaseId,
                isActive,
                forbiddenGroupCodes,
                forbiddenGroupServices
              } = data.forbiddenGroup;
              const activeForbiddenGroupServices =
                forbiddenGroupServices &&
                forbiddenGroupServices.nodes &&
                forbiddenGroupServices.nodes.filter(
                  (service) => service && service.isActive
                );
              const activeForbiddenGroupCodes =
                forbiddenGroupCodes &&
                forbiddenGroupCodes.nodes &&
                forbiddenGroupCodes.nodes.filter(
                  (code) => code && code.isActive
                );

              return (
                <LoadingOverlay loading={loading}>
                  <Flex>
                    <Box p={6} width={1 / 2}>
                      <Box py={10}>
                        <Breadcrumbs.List>
                          <Breadcrumbs.Item to="/forbiddenGroups">
                            <Trans>ForbiddenGroups</Trans>
                          </Breadcrumbs.Item>
                          <Breadcrumbs.Item>
                            <Trans>Forbidden Group details</Trans>
                          </Breadcrumbs.Item>
                        </Breadcrumbs.List>
                      </Box>
                      <Header databaseId={databaseId} isActive={isActive} />
                    </Box>
                    {isActive && (
                      <Box p={6} width={1 / 2}>
                        <Flex alignItems="flex-end" flexDirection="column">
                          <Ability action="write" resource="forbidden_group">
                            <Box>
                              <Button
                                variant="red"
                                width={200}
                                onClick={() =>
                                  onDeactivateGroup(data.forbiddenGroup)
                                }
                              >
                                <Trans>Group deactivation</Trans>
                              </Button>
                            </Box>
                            <Box mt={3}>
                              <Button
                                variant="none"
                                border="none"
                                px="0"
                                onClick={() =>
                                  onAddGroupElement(data.forbiddenGroup)
                                }
                              >
                                <Flex alignItems="center">
                                  <DropDownButton color="#2EA2F8" />
                                  <Description>
                                    <Trans>Add elements</Trans>
                                  </Description>
                                </Flex>
                              </Button>
                            </Box>
                            {(!isEmpty(activeForbiddenGroupServices) ||
                              !isEmpty(activeForbiddenGroupCodes)) && (
                              <Box mt={0.5}>
                                <Button
                                  variant="none"
                                  border="none"
                                  px="0"
                                  onClick={() =>
                                    onDeactivateGroupElement(
                                      data.forbiddenGroup
                                    )
                                  }
                                >
                                  <Flex alignItems="center">
                                    <PencilIcon
                                      color="#2EA2F8"
                                      width="16"
                                      height="14"
                                    />
                                    <Description>
                                      <Trans>Deactivate elements</Trans>
                                    </Description>
                                  </Flex>
                                </Button>
                              </Box>
                            )}
                          </Ability>
                        </Flex>
                      </Box>
                    )}
                  </Flex>
                  <Tabs.Nav>
                    <Tabs.NavItem to="./">
                      <Trans>General info</Trans>
                    </Tabs.NavItem>
                    <Tabs.NavItem to="./codes">
                      <Trans>Codes</Trans>
                    </Tabs.NavItem>
                    <Tabs.NavItem to="./services">
                      <Trans>Services</Trans>
                    </Tabs.NavItem>
                  </Tabs.Nav>
                  <Tabs.Content>
                    <Router>
                      <GeneralInfo
                        path="/"
                        forbiddenGroup={data.forbiddenGroup}
                      />
                      <Codes
                        path="codes"
                        forbiddenGroupCodes={forbiddenGroupCodes}
                      />
                      <Services
                        path="services"
                        forbiddenGroupServices={forbiddenGroupServices}
                      />
                    </Router>
                  </Tabs.Content>
                </LoadingOverlay>
              );
            }}
          </Query>
        );
      }}
    </LocationParams>
  );
};

export default Details;

export const ForbiddenGroupQuery = gql`
  query ForbiddenGroupQuery(
    $id: ID!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $forbiddenGroupCodesFilter: ForbiddenGroupCodeFilter
    $forbiddenGroupServicesFilter: ForbiddenGroupServiceFilter
  ) {
    forbiddenGroup(id: $id) {
      id
      forbiddenGroupCodes(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $forbiddenGroupCodesFilter
      ) {
        nodes {
          id
          databaseId
          isActive
          insertedAt
          updatedAt
          code
          system
        }
        pageInfo {
          ...PageInfo
        }
      }
      forbiddenGroupServices(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $forbiddenGroupServicesFilter
      ) {
        nodes {
          id
          databaseId
          service {
            id
            databaseId
            name
            code
          }
          serviceGroup {
            id
            databaseId
            name
            code
          }
          isActive
          insertedAt
          updatedAt
        }
        pageInfo {
          ...PageInfo
        }
      }
      ...ForbiddenGroupHeader
      ...ForbiddenGroupGeneralInfo
    }
  }
  ${GeneralInfo.fragments.entry}
  ${Header.fragments.entry}
  ${Pagination.fragments.entry}
`;

const Description = styled.span`
  margin-left: 10px;
  color: #2ea2f8;
  font-size: 12px;
  font-weight: 700;
`;
