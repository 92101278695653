import React, { useState, useContext } from "react";
import ModalContext from "./ModalContext";

/**
 * @example useModal
 * to use hook wrap application or any page
 *
 * import { ModalProvider } from "@edenlabllc/ehealth-components";
 *
 * <ModalProvider>
 *   <HomePage path="home"/>
 * </ModalProvider>
 *
 *
 * and after call hook inside component
 *
 * import { useModal } from "@edenlabllc/ehealth-components";
 * import Popup from "./components/Popup";  //it can be any modal with 'visible' and 'onCancel' props
 *
 * const [{ modal }] = useModal(Popup, {title: <Trans>Terminate contract</Trans>});
 *
 * <Button onClick={() => modal.openModal()}>open modal</Button>
 *
 */

var useModal = function useModal(modalComponent, modalData) {
  var context = useContext(ModalContext);
  var openModal = function openModal() {
    return context.openModal({
      modalComponent: modalComponent,
      modalData: modalData
    });
  };
  var closeModal = context.closeModal;
  return [{
    modal: {
      openModal: openModal,
      closeModal: closeModal
    }
  }];
};
export default useModal;