import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";
import { Router } from "@reach/router";

import { Tabs } from "@edenlabllc/ehealth-components";

import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

const PrimarySearchFields = () => (
  <Flex mx={-1} flexWrap="wrap">
    <Box px={1} width={1 / 2}>
      <Trans
        id="Enter Group name"
        render={({ translation }) => (
          <Field.Text
            name="filter.name"
            label={<Trans id="Find group" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 2}>
      <Trans
        id="Forbidden group ID"
        render={({ translation }) => (
          <Field.Text
            name="filter.databaseId"
            label={<Trans id="Group ID" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box width={1 / 2} px={1}>
      <SearchField.Status
        name="filter.isActive"
        status="ACTIVE_STATUS_M"
        label={<Trans id="Status group" />}
      />
    </Box>
  </Flex>
);

const SecondarySearchFields = () => (
  <>
    <Tabs.Nav justifyContent="center">
      <Tabs.Link to="./">
        <Trans>Code filters</Trans>
      </Tabs.Link>
      <Tabs.Link to="./service-filters">
        <Trans>Service filters</Trans>
      </Tabs.Link>
      <Tabs.Link to="./service-group-filters">
        <Trans>Service group filters</Trans>
      </Tabs.Link>
    </Tabs.Nav>
    <Router>
      <CodeFilters exact path="/" />
      <ServiceFilters path="/service-filters" />
      <ServiceGroupFilters path="/service-group-filters" />
    </Router>
  </>
);

const CodeFilters = (_props: RouteComponentProps & { exact: boolean }) => (
  <Flex mx={-1} flexWrap="wrap">
    <Box px={1} width={1 / 2}>
      <Trans
        id="Enter Code"
        render={({ translation }) => (
          <Field.Text
            name="filter.forbiddenGroupCode.code"
            label={<Trans id="Find by Dictionary code" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 2}>
      <SearchField.ForbiddenGroupSystem
        name="filter.forbiddenGroupCode.system"
        systemType="code"
      />
    </Box>
    <Box width={1 / 2} px={1}>
      <SearchField.Status
        name="filter.forbiddenGroupCode.isActive"
        status="ACTIVE_STATUS_M"
        label={<Trans id="Status groups element" />}
      />
    </Box>
  </Flex>
);

const ServiceFilters = (_props: RouteComponentProps) => (
  <Flex mx={-1} flexWrap="wrap">
    <Box px={1} width={1 / 2}>
      <Trans
        id="Enter Service Code"
        render={({ translation }) => (
          <Field.Text
            name="filter.forbiddenGroupService.service.code"
            label={<Trans id="Service Code" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 2}>
      <Trans
        id="Enter Service Name"
        render={({ translation }) => (
          <Field.Text
            name="filter.forbiddenGroupService.service.name"
            label={<Trans id="Service Name" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 2}>
      <Trans
        id="Service ID"
        render={({ translation }) => (
          <Field.Text
            name="filter.forbiddenGroupService.service.databaseId"
            label={<Trans id="Service ID" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box width={1 / 2} px={1}>
      <SearchField.Status
        name="filter.forbiddenGroupService.isActive"
        status="ACTIVE_STATUS_M"
        label={<Trans id="Status groups element" />}
      />
    </Box>
  </Flex>
);

const ServiceGroupFilters = (_props: RouteComponentProps) => (
  <Flex mx={-1} flexWrap="wrap">
    <Box px={1} width={1 / 2}>
      <Trans
        id="Enter Service Group Code"
        render={({ translation }) => (
          <Field.Text
            name="filter.forbiddenGroupService.serviceGroup.code"
            label={<Trans id="Service Group Code" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 2}>
      <Trans
        id="Enter Service Group Name"
        render={({ translation }) => (
          <Field.Text
            name="filter.forbiddenGroupService.serviceGroup.name"
            label={<Trans id="Service Group Name" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 2}>
      <Trans
        id="Service group ID"
        render={({ translation }) => (
          <Field.Text
            name="filter.forbiddenGroupService.serviceGroup.databaseId"
            label={<Trans id="Service group ID" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box width={1 / 2} px={1}>
      <SearchField.Status
        name="filter.forbiddenGroupService.isActive"
        status="ACTIVE_STATUS_M"
        label={<Trans id="Status groups element" />}
      />
    </Box>
  </Flex>
);

export { PrimarySearchFields, SecondarySearchFields };
