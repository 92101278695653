import React from "react";
import { gql } from "graphql-tag";
import { useLingui } from "@lingui/react";

import dateFormatter from "../../../helpers/dateFormatter";

type ValueDateTimeProps = {
  valueDateTime: any;
  dateTimeFormat: Intl.DateTimeFormatOptions;
};

const ValueDateTime = ({
  valueDateTime,
  dateTimeFormat
}: ValueDateTimeProps) => {
  const { i18n } = useLingui();
  if (!valueDateTime) return null;
  return <>{dateFormatter(i18n.locale, dateTimeFormat, valueDateTime)}</>;
};

export default ValueDateTime;

ValueDateTime.fragments = {
  entry: gql`
    fragment ValueDateTime on ValueDateTime {
      dateTime
    }
  `
};
