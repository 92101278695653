import React from "react";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  DiagnosticReport as TDiagnosticReport,
  Reference as TReference,
  ValueString
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../../components/Ability";
import CodeableConcept from "../../../../components/CodeableConcept";
import DefinitionListView from "../../../../components/DefinitionListView";
import DictionaryValue from "../../../../components/DictionaryValue";
import Line from "../../../../components/Line";
import LoadingOverlay from "../../../../components/LoadingOverlay";

import dateFormatter from "../../../../helpers/dateFormatter";
import paramToBase64 from "../../../../helpers/paramToBase64";
import STATUSES from "../../../../helpers/statuses";
import {
  FunctionComponentWithFragments,
  MonitoringDetailsPageProps
} from "../../../../helpers/types";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT
} from "../../../../constants/dateFormats";

import DetailsPageBreadcrumbs from "../DetailsPageBreadcrumbs";
import CodeableConceptList from "../CodeableConceptList";
import Reference from "../Reference";
import IntervalOrOpenDate from "../IntervalOrOpenDate";
import { PaperReferral } from "../PaperReferral";
import ServiceRequestCode from "../ServiceRequestCode";
import { LABEL_WIDTH } from "../Episode";
import CreateConclusion from "../../../ClinicalMonitoring/CreateConclusion";
import getConclusionData from "../../../ClinicalMonitoring/getConclusionData";
import Conclusions from "../../../ClinicalMonitoring/Conclusions";

export const DiagnosticReport: FunctionComponentWithFragments<MonitoringDetailsPageProps> = ({
  query,
  queryVariables,
  itemId,
  id,
  patientId,
  isClinicalMonitoring = false
}) => {
  const { i18n } = useLingui();

  return (
    <Ability
      action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
      resource="diagnostic_report"
    >
      <Box p={6}>
        <DetailsPageBreadcrumbs
          id={id}
          currentStepName={<Trans>Diagnostic report details</Trans>}
          isClinicalMonitoring={isClinicalMonitoring}
        />
        <Query
          query={query}
          fetchPolicy="network-only"
          variables={{
            ...queryVariables,
            id: itemId,
            ...(isClinicalMonitoring && { patientId })
          }}
        >
          {({
            loading,
            error,
            data = {}
          }: QueryResult<{ [key: string]: TDiagnosticReport }>) => {
            const diagnosticReport: TDiagnosticReport = isClinicalMonitoring
              ? data.impersonalDiagnosticReport
              : data.diagnosticReport;

            return (
              <LoadingOverlay loading={loading}>
                <Box mt={4}>
                  {!isEmpty(data) && !isEmpty(diagnosticReport) && !error && (
                    <>
                      <HeaderDefinitions
                        diagnosticReport={diagnosticReport}
                        isClinicalMonitoring={isClinicalMonitoring}
                        patientId={patientId!}
                      />

                      {!isEmpty(diagnosticReport.paperReferral) && (
                        <Box mt={4}>
                          <Text
                            fontSize={1}
                            fontWeight="bold"
                            color="darkCharcoal"
                          >
                            <Trans>Paper referral</Trans>
                          </Text>
                          <PaperReferral
                            paperReferral={diagnosticReport.paperReferral}
                            dateFormat={DATE_FORMAT}
                          />
                        </Box>
                      )}

                      <TopBodyDefinitions diagnosticReport={diagnosticReport} />

                      {!isEmpty(diagnosticReport.encounter) && (
                        <Box mt={2}>
                          <Reference
                            fontSize={14}
                            header={<Trans>Encounter</Trans>}
                            linkPath={
                              isClinicalMonitoring
                                ? `../../../encounter/${patientId}/${paramToBase64(
                                    "Encounter",
                                    diagnosticReport.encounter.identifier.value
                                  )}`
                                : `../../encounter/${paramToBase64(
                                    "Encounter",
                                    diagnosticReport.encounter.identifier.value
                                  )}`
                            }
                            linkDisplayValue={
                              diagnosticReport.encounter.displayValue
                                ? diagnosticReport.encounter.displayValue
                                : diagnosticReport.encounter.identifier.value
                            }
                          />
                        </Box>
                      )}

                      {!isEmpty(diagnosticReport.originEpisode) && (
                        <Box mt={2}>
                          <Reference
                            fontSize={14}
                            header={<Trans>Origin episode</Trans>}
                            linkPath={
                              isClinicalMonitoring
                                ? `../../../episode/${patientId}/${paramToBase64(
                                    "Episode",
                                    diagnosticReport.originEpisode.identifier
                                      .value
                                  )}`
                                : `../../episode/${paramToBase64(
                                    "Episode",
                                    diagnosticReport.originEpisode.identifier
                                      .value
                                  )}`
                            }
                            linkDisplayValue={
                              diagnosticReport.originEpisode.displayValue
                                ? diagnosticReport.originEpisode.displayValue
                                : diagnosticReport.originEpisode.identifier
                                    .value
                            }
                          />
                        </Box>
                      )}

                      {!isEmpty(diagnosticReport.managingOrganization) && (
                        <Box mt={2}>
                          <Reference
                            fontSize={14}
                            header={<Trans>Managing organization</Trans>}
                            linkPath={`/legal-entities/${paramToBase64(
                              "LegalEntity",
                              diagnosticReport.managingOrganization.identifier
                                .value
                            )}`}
                            linkDisplayValue={
                              diagnosticReport.managingOrganization.displayValue
                                ? diagnosticReport.managingOrganization
                                    .displayValue
                                : diagnosticReport.managingOrganization
                                    .identifier.value
                            }
                          />
                        </Box>
                      )}

                      {!isEmpty(diagnosticReport.performer) &&
                        ((diagnosticReport.performer as TReference)
                          .displayValue ? (
                          <Box mt={2}>
                            <Reference
                              fontSize={14}
                              header={<Trans>Performer</Trans>}
                              linkPath={`/employees/${paramToBase64(
                                "Employee",
                                (diagnosticReport.performer as TReference)
                                  .identifier.value
                              )}`}
                              linkDisplayValue={
                                (diagnosticReport.performer as TReference)
                                  .displayValue
                                  ? (diagnosticReport.performer as TReference)
                                      .displayValue
                                  : (diagnosticReport.performer as TReference)
                                      .identifier.value
                              }
                            />
                          </Box>
                        ) : (
                          <Box mt={4}>
                            <DefinitionListView
                              fontSize={14}
                              labels={{
                                string: <Trans>Performer</Trans>
                              }}
                              data={{
                                string: (diagnosticReport.performer as ValueString)
                                  .string
                              }}
                              labelWidth={LABEL_WIDTH}
                            />
                          </Box>
                        ))}

                      {!isEmpty(diagnosticReport.recordedBy) && (
                        <Box mt={2}>
                          <Reference
                            fontSize={14}
                            header={<Trans>Recorded by</Trans>}
                            linkPath={`/employees/${paramToBase64(
                              "Employee",
                              diagnosticReport.recordedBy.identifier.value
                            )}`}
                            linkDisplayValue={
                              diagnosticReport.recordedBy.displayValue
                                ? diagnosticReport.recordedBy.displayValue
                                : diagnosticReport.recordedBy.identifier.value
                            }
                          />
                        </Box>
                      )}

                      {!isEmpty(diagnosticReport.resultsInterpreter) &&
                        ((diagnosticReport.resultsInterpreter as TReference)
                          .displayValue ? (
                          <Box mt={2}>
                            <Reference
                              fontSize={14}
                              header={<Trans>Result interpreter</Trans>}
                              linkPath={`/employees/${paramToBase64(
                                "Employee",
                                (diagnosticReport.resultsInterpreter as TReference)
                                  .identifier.value
                              )}`}
                              linkDisplayValue={
                                (diagnosticReport.resultsInterpreter as TReference)
                                  .displayValue
                                  ? (diagnosticReport.resultsInterpreter as TReference)
                                      .displayValue
                                  : (diagnosticReport.resultsInterpreter as TReference)
                                      .identifier.value
                              }
                            />
                          </Box>
                        ) : (
                          <Box mt={4}>
                            <DefinitionListView
                              fontSize={14}
                              labels={{
                                string: <Trans>Result interpreter</Trans>
                              }}
                              data={{
                                string: (diagnosticReport.resultsInterpreter as ValueString)
                                  .string
                              }}
                              labelWidth={LABEL_WIDTH}
                            />
                          </Box>
                        ))}

                      {!isEmpty(diagnosticReport.basedOn) && (
                        <Box mt={2}>
                          <Reference
                            fontSize={14}
                            header={
                              <Trans id="Diagnostic report based on">
                                Based on
                              </Trans>
                            }
                            linkPath={
                              isClinicalMonitoring
                                ? `../../../service-request/${patientId}/${paramToBase64(
                                    "ServiceRequest",
                                    diagnosticReport.basedOn.identifier.value
                                  )}`
                                : `../../service-request/${paramToBase64(
                                    "ServiceRequest",
                                    diagnosticReport.basedOn.identifier.value
                                  )}`
                            }
                            linkDisplayValue={
                              diagnosticReport.basedOn.displayValue
                                ? diagnosticReport.basedOn.displayValue
                                : diagnosticReport.basedOn.identifier.value
                            }
                          />
                        </Box>
                      )}

                      {!isEmpty(diagnosticReport.effective) && (
                        <Box mt={2}>
                          <IntervalOrOpenDate
                            intervalOrOpenDate={diagnosticReport.effective}
                            header={<Trans>Effective at</Trans>}
                          />
                        </Box>
                      )}

                      <Box mt={4}>
                        <DefinitionListView
                          fontSize={14}
                          labels={{
                            issued: (
                              <Trans id="Diagnostic report issued">
                                Issued
                              </Trans>
                            ),
                            primarySource: <Trans>Primary source</Trans>,
                            ...(!isEmpty(diagnosticReport.reportOrigin) && {
                              reportOrigin: <Trans>Report origin</Trans>
                            })
                          }}
                          data={{
                            ...diagnosticReport,
                            issued: dateFormatter(
                              i18n.locale,
                              DATE_TIME_FORMAT,
                              diagnosticReport.issued
                            ),
                            primarySource: (
                              <Box>
                                {
                                  STATUSES.YES_NO[
                                    // @ts-expect-error statuses boolean key
                                    diagnosticReport.primarySource
                                  ]
                                }
                              </Box>
                            ),
                            ...(!isEmpty(diagnosticReport.reportOrigin) && {
                              reportOrigin: (
                                <CodeableConcept
                                  codeableConcept={
                                    diagnosticReport.reportOrigin
                                  }
                                />
                              )
                            })
                          }}
                          labelWidth={LABEL_WIDTH}
                        />
                      </Box>

                      <Box mt={4}>
                        <BottomBodyDefinitions
                          diagnosticReport={diagnosticReport}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </LoadingOverlay>
            );
          }}
        </Query>
        <Conclusions
          isClinicalMonitoring={isClinicalMonitoring}
          patientId={patientId!}
          itemId={itemId!}
        />
      </Box>
    </Ability>
  );
};

type HeaderDefinitionsProps = {
  diagnosticReport: TDiagnosticReport;
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const HeaderDefinitions = ({
  diagnosticReport,
  isClinicalMonitoring,
  patientId
}: HeaderDefinitionsProps) => {
  if (isEmpty(diagnosticReport)) return null;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <div>
          <DefinitionListView
            labels={{
              databaseId: <Trans>ID</Trans>,
              status: <Trans>Status</Trans>,
              explanatoryLetter: <Trans>Explanatory letter</Trans>,
              ...(diagnosticReport.cancellationReason && {
                cancellationReason: <Trans>Cancellation reason</Trans>
              })
            }}
            data={{
              ...diagnosticReport,
              status: (
                <DictionaryValue
                  name="eHealth/diagnostic_report_statuses"
                  item={diagnosticReport.status.toLowerCase()}
                />
              ),
              explanatoryLetter: diagnosticReport.explanatoryLetter,
              ...(diagnosticReport.cancellationReason && {
                cancellationReason: (
                  <CodeableConcept
                    codeableConcept={diagnosticReport.cancellationReason}
                  />
                )
              })
            }}
            color="#7F8FA4"
            labelWidth={LABEL_WIDTH}
          />
        </div>
        {isClinicalMonitoring && (
          <CreateConclusion
            initialData={getConclusionData(
              patientId,
              "diagnostic_report",
              diagnosticReport.databaseId
            )}
          />
        )}
      </Flex>
      <Line />
    </>
  );
};

const TopBodyDefinitions = ({
  diagnosticReport
}: {
  diagnosticReport: TDiagnosticReport;
}) => {
  if (isEmpty(diagnosticReport)) return null;
  return (
    <Box mt={4}>
      <ServiceRequestCode serviceRequestCode={diagnosticReport.code} />
      <DefinitionListView
        fontSize={14}
        labels={{
          ...(diagnosticReport.category && {
            category: <Trans id="Diagnostic report category">Category</Trans>
          })
        }}
        data={{
          ...diagnosticReport,
          ...(diagnosticReport.category && {
            category: (
              <CodeableConceptList
                codeableConceptList={diagnosticReport.category}
              />
            )
          })
        }}
        labelWidth={LABEL_WIDTH}
      />
    </Box>
  );
};

const BottomBodyDefinitions = ({
  diagnosticReport
}: {
  diagnosticReport: TDiagnosticReport;
}) => {
  const { i18n } = useLingui();
  if (isEmpty(diagnosticReport)) return null;

  return (
    <Box mt={4}>
      <DefinitionListView
        fontSize={14}
        labels={{
          conclusion: <Trans>Conclusion</Trans>,
          conclusionCode: <Trans>Conclusion code</Trans>,
          insertedAt: <Trans>Inserted at</Trans>,
          updatedAt: <Trans>Updated at</Trans>
        }}
        data={{
          ...diagnosticReport,
          insertedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            diagnosticReport.insertedAt
          ),
          updatedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            diagnosticReport.updatedAt
          ),
          ...(diagnosticReport.conclusionCode && {
            conclusionCode: (
              <CodeableConcept
                codeableConcept={diagnosticReport.conclusionCode}
                withCode
              />
            )
          })
        }}
        labelWidth={LABEL_WIDTH}
      />
    </Box>
  );
};
