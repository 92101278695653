import { SearchParams } from "../components/SearchForm";
import { convertStringToBoolean } from "@edenlabllc/ehealth-utils";
import { getFormatDateBeforeNow } from "./dateHelpers";
import pagination from "./pagination";

const prepareParamsToQueryWithUnverified = (locationParams: SearchParams) => {
  const filter = {
    ...locationParams.filter,
    ...(locationParams.filter &&
      locationParams.filter.hasDeclaration && {
        hasDeclaration: convertStringToBoolean(
          locationParams.filter.hasDeclaration
        )
      }),
    ...(locationParams.filter &&
      locationParams.filter.noTaxId && {
        noTaxId: convertStringToBoolean(locationParams.filter.noTaxId)
      })
  };

  if (
    locationParams.filter &&
    locationParams.filter.dracsDeathVerificationStatus &&
    locationParams.filter.dracsDeathVerificationStatus === "NOT_VERIFIED" &&
    locationParams.filter.dracsDeathUnverifiedAt
  ) {
    const unverifiedAt = locationParams.filter.dracsDeathUnverifiedAt;
    filter.dracsDeathUnverifiedAt = `${getFormatDateBeforeNow(
      Number(unverifiedAt.end)
    )}/${getFormatDateBeforeNow(Number(unverifiedAt.start))}`;
  }

  return {
    ...pagination(locationParams),
    filter,
    // reverse the sorting because it is necessary to sort not by date, but by the number of unverified days
    // the number of unverified days and the date are inversely proportional values
    ...(locationParams.orderBy &&
      locationParams.orderBy.includes("DRACS_DEATH_UNVERIFIED_AT") && {
        orderBy:
          locationParams.orderBy === "DRACS_DEATH_UNVERIFIED_AT_ASC"
            ? "DRACS_DEATH_UNVERIFIED_AT_DESC"
            : "DRACS_DEATH_UNVERIFIED_AT_ASC"
      })
  };
};

export default prepareParamsToQueryWithUnverified;
