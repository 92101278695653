import React, { useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation, Query } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Form, Validation } from "@edenlabllc/ehealth-components";
import {
  DiagnosesGroup,
  DeactivateDiagnosesGroupInput,
  Scalars
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Button from "../../../components/Button";
import * as Field from "../../../components/Field";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Popup from "../../../components/Popup";
import Tabs from "../../../components/Tabs";

import Header from "./Header";
import GeneralInfo from "./GeneralInfo";
import Diagnoses from "./Diagnoses";

type DetailsProps = RouteComponentProps<{
  id: Scalars["ID"];
}>;

const Details = ({ id, navigate }: DetailsProps) => {
  const [isVisible, setVisibilityState] = useState(false);
  const toggle = () => setVisibilityState(!isVisible);

  return (
    <Query
      query={DiagnosesGroupQuery}
      variables={{ id }}
      fetchPolicy="network-only"
    >
      {({ loading, data }: QueryResult<{ diagnosesGroup: DiagnosesGroup }>) => {
        if (isEmpty(data) || isEmpty(data.diagnosesGroup)) return null;

        return (
          <LoadingOverlay loading={loading}>
            <Flex>
              <Box p={6} width={1 / 2}>
                <Box py={10}>
                  <Breadcrumbs.List>
                    <Breadcrumbs.Item to="/diagnoses-groups">
                      <Trans>Diagnoses groups</Trans>
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item>
                      <Trans>Diagnoses groups details</Trans>
                    </Breadcrumbs.Item>
                  </Breadcrumbs.List>
                </Box>
                <Header
                  databaseId={data.diagnosesGroup.databaseId}
                  isActive={data.diagnosesGroup.isActive}
                />
              </Box>
              {data.diagnosesGroup.isActive && (
                <Box p={6} width={1 / 2}>
                  <Flex alignItems="flex-end" flexDirection="column">
                    <Ability action="write" resource="diagnoses_group">
                      <Mutation mutation={DeactivateDiagnosesGroupMutation}>
                        {(deactivateDiagnosesGroup: MutationFunction) => (
                          <Box>
                            <Button variant="red" width={200} onClick={toggle}>
                              <Trans>Group deactivation</Trans>
                            </Button>
                            <Popup
                              visible={isVisible}
                              onCancel={toggle}
                              title={<Trans>Deactivate diagnoses group</Trans>}
                              okText={<Trans>Deactivate</Trans>}
                              formId="deactivateDiagnosesGroupForm"
                            >
                              <Form
                                onSubmit={async ({
                                  // @ts-expect-error types mismatch
                                  deactivationReason
                                }: Partial<DeactivateDiagnosesGroupInput>) => {
                                  await deactivateDiagnosesGroup({
                                    variables: {
                                      input: { id, deactivationReason }
                                    }
                                  });
                                  toggle();
                                  await navigate!("../search");
                                }}
                                id="deactivateDiagnosesGroupForm"
                              >
                                <Trans
                                  id="Enter reason comment"
                                  render={({ translation }) => (
                                    <Field.Textarea
                                      name="deactivationReason"
                                      label={<Trans id="Deactivation reason" />}
                                      placeholder={translation}
                                      rows={4}
                                      maxLength={500}
                                      showLengthHint
                                    />
                                  )}
                                />
                                <Trans
                                  id="Required field"
                                  render={() => (
                                    <Validation.Required
                                      field="deactivationReason"
                                      message="Required field"
                                    />
                                  )}
                                />
                              </Form>
                            </Popup>
                          </Box>
                        )}
                      </Mutation>
                    </Ability>
                  </Flex>
                </Box>
              )}
            </Flex>
            <Tabs.Nav>
              <Tabs.NavItem to="./">
                <Trans>General info</Trans>
              </Tabs.NavItem>
              <Tabs.NavItem to="./diagnoses">
                <Trans>Diagnoses</Trans>
              </Tabs.NavItem>
            </Tabs.Nav>
            <Tabs.Content>
              <Router>
                <GeneralInfo path="/" diagnosesGroup={data.diagnosesGroup} />
                <Diagnoses path="/diagnoses" />
              </Router>
            </Tabs.Content>
          </LoadingOverlay>
        );
      }}
    </Query>
  );
};

export default Details;

export const DiagnosesGroupQuery = gql`
  query DiagnosesGroupQuery($id: ID!) {
    diagnosesGroup(id: $id) {
      id
      databaseId
      isActive
      code
      deactivationReason
      description
      name
      insertedAt
      updatedAt
    }
  }
`;

const DeactivateDiagnosesGroupMutation = gql`
  mutation DeactivateDiagnosesGroupMutation(
    $input: DeactivateDiagnosesGroupInput!
  ) {
    deactivateDiagnosesGroup(input: $input) {
      diagnosesGroup {
        id
      }
    }
  }
`;
