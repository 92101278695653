import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams, Validation } from "@edenlabllc/ehealth-components";
import { CarePlanConnection } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";
import paramToBase64 from "../../../helpers/paramToBase64";
import { MonitoringTabProps } from "../../../helpers/types";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

import { PatientSearchField } from "../../ClinicalMonitoring/PatientSearchField";
import { SearchResultTable } from "./EpisodesTab";

export const CARE_PLANS_DISPENSES_STATUSES: {
  values: { [key: string]: string };
} = {
  values: {
    active: t`Active`,
    cancelled: t`Cancelled`,
    completed: "Completed",
    new: t`New`,
    terminated: t`Terminated`
  }
};

const CarePlansTab = ({
  id,
  query = CarePlansQuery,
  isClinicalMonitoring = false
}: MonitoringTabProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="care_plan"
  >
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Query
          skip={!locationParams.first && !locationParams.last}
          query={query}
          fetchPolicy="network-only"
          variables={prepareParamsToQuery(
            id!,
            locationParams,
            isClinicalMonitoring
          )}
        >
          {({
            data = {},
            loading
          }: QueryResult<{ [key: string]: CarePlanConnection }>) => {
            const carePlans = isClinicalMonitoring
              ? data.impersonalCarePlans
              : data.carePlans;

            return (
              <LoadingOverlay loading={loading}>
                <Box p={1}>
                  <SearchForm
                    initialValues={locationParams}
                    onSubmit={setLocationParams}
                    renderPrimary={() =>
                      PrimarySearchFields(isClinicalMonitoring)
                    }
                    onResetSkipPagination
                  />
                </Box>
                {!data || isEmpty(carePlans) || isEmpty(carePlans.nodes) ? (
                  <EmptyData mx={2} />
                ) : (
                  <Box mb={6} p={2}>
                    <Box>
                      <Text>
                        <Trans>Total found</Trans>: {carePlans.totalEntries}
                      </Text>
                    </Box>
                    <SearchResultTable
                      data={carePlans.nodes}
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                      statusValues={CARE_PLANS_DISPENSES_STATUSES.values}
                      tableName="carePlans"
                      pathSlug={
                        isClinicalMonitoring
                          ? `care-plan/${
                              locationParams.filter &&
                              locationParams.filter.patientId
                            }`
                          : "care-plan"
                      }
                      isClinicalMonitoring={isClinicalMonitoring}
                    />
                    <Pagination {...carePlans.pageInfo} />
                  </Box>
                )}
              </LoadingOverlay>
            );
          }}
        </Query>
      )}
    </LocationParams>
  </Ability>
);

export default CarePlansTab;

const CarePlansQuery = gql`
  query CarePlansQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: CarePlanFilter!
    $orderBy: CarePlanOrderBy
  ) {
    carePlans(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

const prepareParamsToQuery = (
  id: string,
  locationParams: SearchParams,
  isClinicalMonitoring: boolean
) => {
  const { status, encounterId, carePlanId, ...restFilterParams } =
    locationParams.filter || {};

  return {
    orderBy: "INSERTED_AT_DESC",
    ...pagination(locationParams),
    filter: {
      ...(!isClinicalMonitoring && { personId: id }),
      ...restFilterParams,
      ...(status && {
        status: status.toUpperCase()
      }),
      ...(encounterId && {
        encounterId: paramToBase64("Encounter", encounterId)
      }),
      ...(carePlanId && {
        id: paramToBase64("CarePlan", carePlanId)
      })
    }
  };
};

const PrimarySearchFields = (isClinicalMonitoring: boolean) => {
  const { i18n } = useLingui();
  return (
    <Flex flexDirection="column">
      <PatientSearchField isClinicalMonitoring={isClinicalMonitoring} />
      <Flex>
        <Box width={1 / 3} mt={3} px={1}>
          <Field.Select
            name="filter.status"
            label={<Trans id="Care plan status" />}
            placeholder={i18n._(t`Select status`)}
            items={Object.keys(CARE_PLANS_DISPENSES_STATUSES.values)}
            itemToString={(item: string) =>
              item ? i18n._(CARE_PLANS_DISPENSES_STATUSES.values[item]) : item
            }
            variant="select"
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter encounter ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.encounterId"
                label={<Trans id="Encounter ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.encounterId"
            options={UUID_PATTERN}
            message="Invalid encounter ID"
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter care plan period date"
            render={() => (
              <Field.DatePicker
                name="filter.periodDate"
                minDate="1900-01-01"
                label={<Trans id="Care plan period date" />}
              />
            )}
          />
        </Box>
      </Flex>
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter care plan requisition"
            render={({ translation }) => (
              <Field.Text
                name="filter.requisition"
                label={<Trans id="Care plan requisition" />}
                placeholder={translation}
              />
            )}
          />
        </Box>
        {isClinicalMonitoring && (
          <Box px={1} width={1 / 3} mt={3}>
            <Trans
              id="Enter care plan ID"
              render={({ translation }) => (
                <Field.Text
                  name="filter.carePlanId"
                  label={<Trans id="Care plan ID" />}
                  placeholder={translation}
                />
              )}
            />
            <Validation.Matches
              field="filter.carePlanId"
              options={UUID_PATTERN}
              message="Invalid care plan ID"
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
