import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";

import {
  EmployeeRoleConnection,
  EmployeeRole
} from "@ehealth/ehealth-ua.schema";

import AddressView from "../../../components/AddressView";
import AvailableTime from "../../../components/AvailableTime";
import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";

type ActiveRolesProps = RouteComponentProps & {
  data: EmployeeRoleConnection;
  loading: boolean;
  legalEntityId: string;
};

const ActiveRoles = ({ data, loading, legalEntityId }: ActiveRolesProps) => {
  const { nodes: roles = [], pageInfo } = data;

  return (
    <LoadingOverlay loading={loading}>
      {roles && roles.length > 0 ? (
        <>
          <Table
            data={roles}
            header={{
              divisionName: <Trans>Division name</Trans>,
              addresses: <Trans>Address</Trans>,
              specialityType: <Trans>Speciality type</Trans>,
              providingCondition: <Trans>Providing Condition</Trans>,
              availableTime: <Trans>Available Time</Trans>,
              status: <Trans>Status of Healthcare Service</Trans>
            }}
            renderRow={({
              // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
              healthcareService: {
                specialityType,
                providingCondition,
                availableTime,
                status,
                // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
                division: { id: divisionId, name: divisionName, addresses } = {}
              } = {}
            }: EmployeeRole) => ({
              divisionName: (
                <Link
                  to={`/legal-entities/${legalEntityId}/divisions/${divisionId}`}
                >
                  {divisionName}
                </Link>
              ),
              addresses: addresses
                .filter((a) => a && a.type === "RESIDENCE")
                .map((item, key) => <AddressView data={item} key={key} />),
              specialityType: (
                <DictionaryValue name="SPECIALITY_TYPE" item={specialityType} />
              ),
              providingCondition: (
                <DictionaryValue
                  name="PROVIDING_CONDITION"
                  item={providingCondition}
                />
              ),
              availableTime: <AvailableTime data={availableTime} />,
              status: (
                <Badge type="ACTIVE_INACTIVE_M" name={status} display="block" />
              )
            })}
          />
          <Pagination {...pageInfo} />
        </>
      ) : (
        <EmptyData />
      )}
    </LoadingOverlay>
  );
};

ActiveRoles.fragments = {
  entry: gql`
    fragment Roles on Employee {
      roles(
        first: $first
        orderBy: $orderBy
        before: $before
        after: $after
        last: $last
        filter: $filter
      ) {
        nodes {
          id
          healthcareService {
            division {
              id
              name
              addresses {
                ...Addresses
              }
            }
            status
            specialityType
            providingCondition
            availableTime {
              daysOfWeek
              allDay
              availableStartTime
              availableEndTime
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
    ${AddressView.fragments.entry}
    ${Pagination.fragments.entry}
  `
};

export default ActiveRoles;
