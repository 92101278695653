import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Modal } from "@edenlabllc/ehealth-components";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import system from "@edenlabllc/ehealth-system-components";
import { PersonAuthenticationMethod, Person } from "@ehealth/ehealth-ua.schema";

import Badge from "../../../components/Badge";
import Button from "../../../components/Button";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Table from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import { ThirdPersonOwnMethod } from "../../Persons/Details/AuthMethods";

type AuthMethodsModalProps = {
  id: string;
  toggle: () => void;
  onChoose: (authorizeWith: { id: string; type: string }) => void;
};

const AuthMethodsModal = ({ id, toggle, onChoose }: AuthMethodsModalProps) => {
  const { i18n } = useLingui();

  return (
    <Modal width="90%" backdrop>
      <Close onClick={toggle} />

      <Query
        query={PersonAuthMethodsQuery}
        fetchPolicy="cache-first"
        variables={{
          id,
          first: 10,
          filter: {
            isActive: true,
            isEnded: false
          }
        }}
      >
        {({ data, loading }: QueryResult<{ person: Person }>) => {
          const {
            person: {
              authenticationMethods: { nodes: authenticationMethods = [] } = {}
            } = {}
          } = data || {};

          return (
            <LoadingOverlay loading={loading}>
              {isEmpty(authenticationMethods) ? (
                <Box mt={5}>
                  <EmptyData mx={5} />
                </Box>
              ) : (
                <Box p={5}>
                  <Table
                    tableName="authenticationMethods"
                    data={authenticationMethods}
                    header={{
                      type: <Trans>Type</Trans>,
                      phoneNumber: <Trans>Own phone number</Trans>,
                      thirdPerson: <Trans>Third person</Trans>,
                      ownAuthMethodThirdPerson: (
                        <Trans>Own auth method third person</Trans>
                      ),
                      alias: <Trans>Alias</Trans>,
                      startedAt: <Trans>Date started</Trans>,
                      endedAt: <Trans>Date ended</Trans>,
                      isActive: <Trans>Status</Trans>,
                      action: <Trans>Action</Trans>
                    }}
                    renderRow={({
                      id,
                      type,
                      phoneNumber,
                      thirdPerson,
                      startedAt,
                      endedAt,
                      isActive,
                      // @ts-expect-error types mismatch
                      alias
                    }: PersonAuthenticationMethod) => {
                      return {
                        type: type ? (
                          <DictionaryValue
                            name="AUTHENTICATION_METHOD"
                            item={type}
                          />
                        ) : (
                          "-"
                        ),
                        phoneNumber: phoneNumber ? phoneNumber : "-",
                        thirdPerson: thirdPerson ? (
                          <Link
                            to={`/persons/${thirdPerson.id}`}
                            fontWeight="bold"
                          >
                            <Trans>Show details</Trans>
                          </Link>
                        ) : (
                          "-"
                        ),
                        ownAuthMethodThirdPerson: thirdPerson ? (
                          <ThirdPersonOwnMethod thirdPerson={thirdPerson} />
                        ) : (
                          "-"
                        ),
                        alias: alias,
                        startedAt: startedAt
                          ? dateFormatter(
                              i18n.locale,
                              DATE_TIME_FORMAT,
                              startedAt
                            )
                          : "-",
                        endedAt: endedAt
                          ? dateFormatter(
                              i18n.locale,
                              DATE_TIME_FORMAT,
                              endedAt
                            )
                          : "-",
                        isActive: (
                          <Badge
                            type="ACTIVE_INACTIVE_M"
                            name={isActive ? "ACTIVE" : "INACTIVE"}
                            display="block"
                          />
                        ),
                        action: (
                          <Button
                            variant={"link"}
                            alignItems="start"
                            px="0"
                            py="0"
                            type="reset"
                            onClick={() => onChoose({ id, type })}
                          >
                            <Trans>Select</Trans>
                          </Button>
                        )
                      };
                    }}
                  />
                </Box>
              )}
            </LoadingOverlay>
          );
        }}
      </Query>
    </Modal>
  );
};

const Close = system(
  {
    is: RemoveItemIcon
  },
  {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: 12,
    height: 12,
    opacity: 0.2
  }
);

export const PersonAuthMethodsQuery = gql`
  query PersonAuthMethodsQuery(
    $id: ID!
    $filter: PersonAuthenticationMethodFilter
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    person(id: $id) {
      id
      authenticationMethods(
        filter: $filter
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        nodes {
          id
          alias
          type
          startedAt
          endedAt
          phoneNumber
          isActive
          thirdPerson {
            databaseId
            firstName
            lastName
            secondName
            id
            authenticationMethods(
              filter: { type: ["OTP", "OFFLINE", "NA"] }
              first: 50
            ) {
              nodes {
                id
                type
                phoneNumber
              }
            }
          }
        }
      }
    }
  }
`;

export default AuthMethodsModal;
