import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { loader } from "graphql.macro";
import { Flex, Box, Text } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import { getFullName } from "@edenlabllc/ehealth-utils";
import {
  Form,
  LocationParams,
  Validation
} from "@edenlabllc/ehealth-components";
import {
  SearchIcon,
  PositiveIcon,
  NegativeIcon
} from "@edenlabllc/ehealth-icons";
import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import { LegalEntity, LegalEntityConnection } from "@ehealth/ehealth-ua.schema";

import Badge from "../../components/Badge";
import Button from "../../components/Button";
import DefinitionListView from "../../components/DefinitionListView";
import DictionaryValue from "../../components/DictionaryValue";
import * as Field from "../../components/Field";
import Link from "../../components/Link";
import Line from "../../components/Line";
import LoadingOverlay from "../../components/LoadingOverlay";
import Pagination from "../../components/Pagination";
import Steps from "../../components/Steps";
import Table from "../../components/Table";
import Tooltip from "../../components/Tooltip";
import { TLocationParams } from "../../components/SearchForm";

import pagination from "../../helpers/pagination";
import { ITEMS_PER_PAGE } from "../../constants/pagination";
import { EDRPOU_OR_PASSPORT_OR_LEGAL_ENTITY_ID_PATTERN } from "../../constants/validationPatterns";

import env from "../../env";

import { LegalEntityQuery } from "./Details/";

const SearchLegalEntitiesQuery = loader(
  "../../graphql/SearchLegalEntitiesQuery.graphql"
);
const MergeLegalEntitiesMutation = loader(
  "../../graphql/MergeLegalEntitiesMutation.graphql"
);

const Add = ({
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <>
    <Box pt={5} px={5}>
      <Steps.List>
        <Steps.Item to="./" state={state}>
          <Trans>Find Legal entity</Trans>
        </Steps.Item>
        <Steps.Item
          to="./reason"
          state={state}
          disabled={state && !state.reason}
        >
          <Trans>Specify the basis</Trans>
        </Steps.Item>
        <Steps.Item to="./sign" state={state} disabled={state && !state.reason}>
          <Trans>Confirm with EDS</Trans>
        </Steps.Item>
      </Steps.List>
    </Box>
    <Router>
      <Search path="/" />
      <Reason path="/reason" />
      <Sign path="/sign" />
    </Router>
  </>
);

const Search = ({
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <LocationParams>
    {({
      locationParams: { filter, orderBy, ...locationParams },
      setLocationParams
    }: TLocationParams) => (
      <>
        <Form onSubmit={setLocationParams} initialValues={{ filter, orderBy }}>
          <Box px={5} width={460}>
            <Trans
              id="Enter legal entity EDRPOU"
              render={({ translation }) => (
                <Field.Text
                  name="filter.edrpou"
                  label={<Trans id="Find related legal entity" />}
                  placeholder={translation}
                  postfix={<SearchIcon color="silverCity" />}
                />
              )}
            />
            <Validation.Matches
              field="filter.edrpou"
              options={EDRPOU_OR_PASSPORT_OR_LEGAL_ENTITY_ID_PATTERN}
              message="Invalid number"
            />
          </Box>
        </Form>
        {filter && (
          <Query
            query={SearchLegalEntitiesQuery}
            variables={{
              ...pagination(locationParams),
              filter: {
                ...filter,
                status: ["ACTIVE", "SUSPENDED"]
              }
            }}
          >
            {({
              loading,
              error,
              data
            }: QueryResult<{ legalEntities: LegalEntityConnection }>) => {
              if (error) return <>Error! ${error.message}</>;
              if (isEmpty(data) || isEmpty(data.legalEntities)) return null;
              const {
                legalEntities: { nodes: legalEntities = [], pageInfo }
              } = data;

              return (
                <LoadingOverlay loading={loading}>
                  <>
                    <Table
                      data={legalEntities}
                      header={{
                        databaseId: <Trans>Legal entity ID</Trans>,
                        name: <Trans>Legal entity name</Trans>,
                        edrpou: <Trans>EDRPOU</Trans>,
                        owner: <Trans>CEO</Trans>,
                        type: <Trans>Type</Trans>,
                        nhsVerified: <Trans>Verified by NZZU</Trans>,
                        status: <Trans>Status</Trans>,
                        action: <Trans>Action</Trans>
                      }}
                      renderRow={({
                        status,
                        nhsVerified,
                        owner,
                        type,
                        ...legalEntity
                      }: LegalEntity) => ({
                        ...legalEntity,
                        type: (
                          <DictionaryValue
                            name="LEGAL_ENTITY_TYPE"
                            item={type}
                          />
                        ),
                        owner: owner && getFullName(owner.party),
                        nhsVerified: (
                          <Flex justifyContent="center">
                            {nhsVerified ? <PositiveIcon /> : <NegativeIcon />}
                          </Flex>
                        ),
                        status: (
                          <Badge
                            name={status}
                            type="LEGALENTITY"
                            display="block"
                          />
                        ),
                        action: (
                          <Link
                            to="./reason"
                            state={{
                              ...state,
                              legalEntity: { owner, ...legalEntity }
                            }}
                            fontWeight="bold"
                          >
                            <Trans>Next</Trans>
                          </Link>
                        )
                      })}
                      tableName="legalEntity/Add"
                    />
                    <Pagination {...pageInfo} />
                    <Flex px={5} mt={5}>
                      <Link to="../related-legal-entities">
                        <Button variant="blue">
                          <Trans>Return</Trans>
                        </Button>
                      </Link>
                    </Flex>
                  </>
                </LoadingOverlay>
              );
            }}
          </Query>
        )}
      </>
    )}
  </LocationParams>
);

const Reason = ({
  navigate,
  location: {
    // @ts-expect-error location state
    state: {
      reason,
      legalEntity: { owner, databaseId, name, edrpou }
    }
  }
}: RouteComponentProps) => (
  <Box pt={1} px={5}>
    <Text fontSize={1} fontWeight="bold" mb={3}>
      <Trans>Related legal entity</Trans>
    </Text>
    <DefinitionListView
      labels={{
        edrpou: <Trans>EDRPOU</Trans>,
        name: <Trans>Name</Trans>,
        owner: <Trans>Owner</Trans>
      }}
      data={{
        edrpou,
        name
        // owner: getFullName(owner.party)
      }}
    />
    <DefinitionListView
      labels={{
        databaseId: <Trans>Legal entity ID</Trans>
      }}
      data={{
        databaseId
      }}
      color="#7F8FA4"
    />
    <Box width={460} pt={2}>
      <Form
        onSubmit={async ({ reason }: { reason: string }) => {
          navigate!("../sign", {
            state: {
              reason,
              legalEntity: { owner, databaseId, name, edrpou }
            }
          });
        }}
        initialValues={{ reason }}
      >
        <Trans
          id="Example: Order of the Cabinet of Ministers of Ukraine # 73465 "
          render={({ translation }) => (
            <Field.Textarea
              name="reason"
              rows={6}
              label={<Trans id="Specify the merge reason" />}
              placeholder={translation}
            />
          )}
        />
        <Validation.Required field="reason" message="Required field" />
        <Flex>
          <Box mr={3}>
            <Link
              to={`../?filter.edrpou=${edrpou}`}
              state={{
                reason,
                legalEntity: { owner, databaseId, name, edrpou }
              }}
            >
              <Button variant="blue">
                <Trans>Return</Trans>
              </Button>
            </Link>
          </Box>
          <Button variant="green">
            <Trans>Next</Trans>
          </Button>
        </Flex>
      </Form>
    </Box>
  </Box>
);

type SignProps = RouteComponentProps<{
  id: string;
}>;

const Sign = ({
  id: legalEntityToId,
  location: {
    // @ts-expect-error location state
    state: {
      reason,
      legalEntity: {
        databaseId: legalEntityFromId,
        name: legalEntityFromName,
        edrpou: legalEntityFromEdrpou,
        owner
      }
    }
  },
  navigate
}: SignProps) => (
  <Query
    query={LegalEntityQuery}
    variables={{
      id: legalEntityToId,
      firstMergedFromLegalEntities: ITEMS_PER_PAGE[0],
      firstDivisions: ITEMS_PER_PAGE[0],
      firstHealthcareServices: ITEMS_PER_PAGE[0]
    }}
  >
    {({ loading, error, data }: QueryResult<{ legalEntity: LegalEntity }>) => {
      if (error) return <>Error! ${error.message}</>;
      if (isEmpty(data) || isEmpty(data.legalEntity)) return null;
      const {
        legalEntity: { databaseId, name, edrpou }
      } = data;
      const legalEntityTo = { id: databaseId, name, edrpou };
      const legalEntityFrom = {
        id: legalEntityFromId,
        name: legalEntityFromName,
        edrpou: legalEntityFromEdrpou
      };

      return (
        <LoadingOverlay loading={loading}>
          <Box px={5}>
            <Signer.Parent
              url={env.REACT_APP_SIGNER_URL}
              features={{ width: 640, height: 589 }}
            >
              {/* @ts-expect-error signData */}
              {({ signData }) => (
                <Mutation mutation={MergeLegalEntitiesMutation}>
                  {(mergeLegalEntities: MutationFunction) => (
                    <>
                      <Text fontSize={1} fontWeight="bold" mb={3}>
                        <Trans>Main legal entity</Trans>
                      </Text>
                      <DefinitionListView
                        labels={{
                          edrpou: <Trans>EDRPOU</Trans>,
                          name: <Trans>Name</Trans>,
                          id: <Trans>Legal entity ID</Trans>
                        }}
                        data={legalEntityTo}
                      />
                      <Line />
                      <Text fontSize={1} fontWeight="bold" mb={3}>
                        <Trans>Related legal entity</Trans>
                      </Text>
                      <DefinitionListView
                        labels={{
                          edrpou: <Trans>EDRPOU</Trans>,
                          name: <Trans>Name</Trans>,
                          id: <Trans>Legal entity ID</Trans>
                        }}
                        data={legalEntityFrom}
                      />
                      <Line />
                      <DefinitionListView
                        labels={{
                          reason: <Trans>Merge Legal entity reason</Trans>
                        }}
                        data={{ reason }}
                      />
                      <Flex>
                        <Box mr={3}>
                          <Link
                            to="../reason"
                            state={{
                              reason,
                              legalEntity: {
                                id: legalEntityFromId,
                                name: legalEntityFromName,
                                edrpou: legalEntityFromEdrpou,
                                owner
                              }
                            }}
                          >
                            <Button variant="blue">
                              <Trans>Return</Trans>
                            </Button>
                          </Link>
                        </Box>
                        <Tooltip
                          component={() => (
                            <Button
                              variant="green"
                              onClick={async () => {
                                const mergedLegalEntities = {
                                  merged_from_legal_entity: legalEntityFrom,
                                  merged_to_legal_entity: legalEntityTo,
                                  reason
                                };
                                const { signedContent } = await signData(
                                  mergedLegalEntities
                                );
                                await mergeLegalEntities({
                                  variables: {
                                    input: {
                                      signedContent: {
                                        content: signedContent,
                                        encoding: "BASE64"
                                      }
                                    }
                                  }
                                });
                                await navigate!("/legal-entity-merge-jobs");
                              }}
                            >
                              <Trans>Sign</Trans>
                            </Button>
                          )}
                          content={
                            <Trans>
                              Warning!
                              <br />
                              By approving the request, CONFIRMS the validity of
                              their own intentions, and also that the content of
                              the transaction is in accordance with your
                              authority, accepted and signed personally by you.
                            </Trans>
                          }
                        />
                      </Flex>
                    </>
                  )}
                </Mutation>
              )}
            </Signer.Parent>
          </Box>
        </LoadingOverlay>
      );
    }}
  </Query>
);

export default Add;
