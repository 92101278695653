import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import { InnmDosage, Scalars } from "@ehealth/ehealth-ua.schema";

import Breadcrumbs from "../../../components/Breadcrumbs";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";

import Header from "./Header";
import Ingredients from "./Ingredients";

type DetailsProps = RouteComponentProps<{
  id: Scalars["ID"];
}>;

const Details = ({ id }: DetailsProps) => (
  <Query query={INNMDosageDetailsQuery} variables={{ id }}>
    {({ loading, data }: QueryResult<{ innmDosage: InnmDosage }>) => {
      if (isEmpty(data) || isEmpty(data.innmDosage)) return null;
      const {
        databaseId,
        name,
        form,
        isActive,
        ingredients,
        dailyDosage,
        dosageFormIsDosed,
        mrBlankType,
        endDate
      } = data.innmDosage;

      return (
        <LoadingOverlay loading={loading}>
          <Box p={6}>
            <Box py={10}>
              <Breadcrumbs.List>
                <Breadcrumbs.Item to="/innm-dosages">
                  <Trans>INNM dosages</Trans>
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                  <Trans>INNM dosage details</Trans>
                </Breadcrumbs.Item>
              </Breadcrumbs.List>
            </Box>
            <Header
              id={id!}
              databaseId={databaseId}
              name={name}
              form={form}
              isActive={isActive}
            />
          </Box>
          <Tabs.Nav>
            <Tabs.NavItem to="./">
              <Trans>General info</Trans>
            </Tabs.NavItem>
          </Tabs.Nav>
          <Tabs.Content>
            <Router>
              <Ingredients
                path="/"
                id={id}
                data={ingredients}
                dailyDosage={dailyDosage}
                dosageFormIsDosed={dosageFormIsDosed}
                mrBlankType={mrBlankType}
                endDate={endDate}
              />
            </Router>
          </Tabs.Content>
        </LoadingOverlay>
      );
    }}
  </Query>
);

const INNMDosageDetailsQuery = gql`
  query INNMDosageDetailsQuery($id: ID!) {
    innmDosage(id: $id) {
      ...INNMDosageHeader
      ...INNMDosageIngredients
    }
  }
  ${Header.fragments.entry}
  ${Ingredients.fragments.entry}
`;

export default Details;
