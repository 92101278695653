import React from "react";

type DropzoneFileNameProps = {
  files: any[];
};

const DropzoneFileName = ({ files }: DropzoneFileNameProps) => {
  return (
    <>
      {files.map((file: any, i: number) => (
        <div key={i}>
          {file.path} - {file.size} bytes
        </div>
      ))}
    </>
  );
};

export default DropzoneFileName;
