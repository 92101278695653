import { gql } from "graphql-tag";
import Pagination from "../../../components/Pagination";
import MedicationRequest from "../../PracticalMonitoring/Details/MedicationRequest";

export const ImpersonalMedicationRequestsQuery = gql`
  query ImpersonalMedicationRequestsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImpersonalMedicationRequestFilter!
    $orderBy: MedicationRequestOrderBy
  ) {
    impersonalMedicationRequests(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

export const ImpersonalMedicationRequestByIDQuery = gql`
  query ImpersonalMedicationRequestQuery($id: ID!, $patientId: String!) {
    impersonalMedicationRequest(id: $id, patientId: $patientId) {
      ...MedicationRequest
    }
  }
  ${MedicationRequest.fragments.entry}
`;
