import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Flex, Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { ForbiddenGroup } from "@ehealth/ehealth-ua.schema";

import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import Pagination from "../../../components/Pagination";
import * as SearchField from "../../../components/SearchField";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import ForbiddenGroupCodesTable from "./ForbiddenGroupCodesTable";

type CodesProps = RouteComponentProps<{
  forbiddenGroupCodes: ForbiddenGroup["forbiddenGroupCodes"];
}>;

const Codes = ({ forbiddenGroupCodes }: CodesProps) => {
  if (isEmpty(forbiddenGroupCodes) || isEmpty(forbiddenGroupCodes.nodes)) {
    return <EmptyData />;
  }
  const { pageInfo } = forbiddenGroupCodes;
  return (
    <Box p={5}>
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => (
          <SearchForm
            initialValues={locationParams}
            onSubmit={setLocationParams}
            renderPrimary={PrimarySearchFields}
            renderSecondary={SecondarySearchFields}
          />
        )}
      </LocationParams>
      <ForbiddenGroupCodesTable
        forbiddenGroupCodes={forbiddenGroupCodes.nodes}
        tableName="forbiddenGroupCodes/search"
      />
      <Pagination {...pageInfo} />
    </Box>
  );
};

export default Codes;

const PrimarySearchFields = () => (
  <Flex mx={-1} flexWrap="wrap">
    <Box px={1} width={1 / 2}>
      <Trans
        id="Enter Code"
        render={({ translation }) => (
          <Field.Text
            name="filter.forbiddenGroupCodesFilter.code"
            label={<Trans id="Find by Dictionary code" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
  </Flex>
);

const SecondarySearchFields = () => (
  <Flex mx={-1} flexWrap="wrap">
    <Box width={1 / 2} px={1}>
      <SearchField.Status
        name="filter.forbiddenGroupCodesFilter.isActive"
        status="ACTIVE_STATUS_M"
        label={<Trans id="Status groups element" />}
      />
    </Box>
    <Box width={1 / 2} px={1}>
      <SearchField.ForbiddenGroupSystem
        name="filter.forbiddenGroupCodesFilter.system"
        systemType="code"
      />
    </Box>
  </Flex>
);
