import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box, Heading } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { getPhones } from "@edenlabllc/ehealth-utils";
import { Party } from "@ehealth/ehealth-ua.schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import DocumentView from "../../../components/DocumentView";
import Line from "../../../components/Line";

type GeneralInfoPartyProps = RouteComponentProps & {
  party: Party;
};

const GeneralInfoParty = ({
  party: {
    birthDate,
    gender,
    phones,
    documents,
    workingExperience,
    aboutMyself
  }
}: GeneralInfoPartyProps) => (
  <Box p={5}>
    <DefinitionListView
      labels={{
        birthDate: <Trans>Date of birth</Trans>,
        gender: <Trans>Gender</Trans>
      }}
      data={{
        birthDate: i18n.date(birthDate),
        gender: <DictionaryValue name="GENDER" item={gender} />
      }}
    />
    <Line />
    <DefinitionListView
      labels={{
        phones: <Trans>Phones</Trans>,
        documents: <Trans>Documents</Trans>
      }}
      data={{
        phones: getPhones(phones),
        documents:
          documents &&
          documents.map((document, index) => (
            <Box key={index} pb={4}>
              <Heading fontSize="0" fontWeight="bold" pb={4}>
                <DictionaryValue
                  name="DOCUMENT_TYPE"
                  item={document && document.type}
                />
              </Heading>
              <DocumentView data={document} />
            </Box>
          ))
      }}
    />
    {(workingExperience || aboutMyself) && (
      <>
        <Line />
        <DefinitionListView
          labels={{
            workingExperience: <Trans>Working experience</Trans>,
            aboutMyself: <Trans>About myself</Trans>
          }}
          data={{
            workingExperience,
            aboutMyself
          }}
        />
      </>
    )}
  </Box>
);

GeneralInfoParty.fragments = {
  entry: gql`
    fragment GeneralInfoParty on Party {
      databaseId
      birthDate
      gender
      phones {
        number
        type
      }
      documents {
        type
        number
        issuedBy
        issuedAt
      }
      aboutMyself
      workingExperience
    }
  `
};

export default GeneralInfoParty;
