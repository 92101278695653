import * as React from "react";

import { filterTableColumn as filterTableDefaultColumn } from "@edenlabllc/ehealth-utils";

type HeaderDataWithStatus = {
  name: string;
  status: boolean;
  title: string;
};

export type TableBodyType = {
  columns: string[];
  data: string[];
  renderRow: (tableData: any, index: number) => any;
  rowKeyExtractor: (item: any, index: number) => React.Key;
  columnKeyExtractor: (item: any, index: number) => React.Key;
  bodyComponent: React.ComponentType<{
    children: React.ReactElement | React.ReactElement[];
  }>;
  rowComponent: React.ComponentType<{
    children: React.ReactElement | React.ReactElement[];
  }>;
  cellComponent: React.ComponentType<{
    children: React.ReactElement;
    whitespacenowrap: number;
    title?: string;
  }>;
  filterTableColumn: (
    filterRow: Array<HeaderDataWithStatus | any>,
    bodyName: any
  ) => any;
  filterRow: Array<HeaderDataWithStatus | any>;
  whiteSpaceNoWrap: string[];
};

const TableBody = ({
  columns,
  data,
  renderRow,
  rowKeyExtractor,
  columnKeyExtractor,
  bodyComponent: BodyComponent,
  rowComponent: RowComponent,
  cellComponent: CellComponent,
  filterTableColumn = filterTableDefaultColumn,
  filterRow,
  whiteSpaceNoWrap = []
}: TableBodyType) => (
  <BodyComponent>
    {data.map((item: string, index: number) => {
      const row = renderRow(item, index);
      return (
        <RowComponent key={index}>
          {columns
            .filter((bodyName: string) =>
              filterTableColumn(filterRow, bodyName)
            )
            .map((name: string, index: number) => (
              <CellComponent
                key={columnKeyExtractor(name, index)}
                title={typeof row[name] === "string" ? row[name] : undefined}
                //whitespacenowrap is a non-boolean attribute, so we need to use 1 or 0
                whitespacenowrap={whiteSpaceNoWrap.includes(name) ? 1 : 0}
              >
                {row[name]}
              </CellComponent>
            ))}
        </RowComponent>
      );
    })}
  </BodyComponent>
);

export default TableBody;
