import { gql } from "graphql-tag";

import STATUSES from "../../../helpers/statuses";

type ValueBooleanProps = {
  valueBoolean: boolean;
};

const ValueBoolean = ({
  valueBoolean
}: // @ts-expect-error statuses boolean key
ValueBooleanProps) => STATUSES.YES_NO[valueBoolean];

export default ValueBoolean;

ValueBoolean.fragments = {
  entry: gql`
    fragment ValueBoolean on ValueBoolean {
      boolean
    }
  `
};
