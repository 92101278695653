import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";

import { InfoIcon } from "@edenlabllc/ehealth-icons";
import { Coding as CodingType, Maybe } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "./DictionaryValue";
import Tooltip from "./Tooltip";

type CodingProps = {
  coding: Maybe<CodingType>;
  withCode?: boolean;
};

const Coding = ({ coding, withCode }: CodingProps) => {
  const [isTooltipShown, showTooltip] = useState(false);
  if (isEmpty(coding)) return null;

  return (
    <>
      <DictionaryValue
        name={coding.system}
        item={coding.code}
        withCode={withCode}
      />
      {coding.system && (
        <Tooltip
          content={coding.system}
          component={() => (
            <Box ml={1}>
              <InfoIcon onClick={() => showTooltip(true)} cursor="pointer" />
            </Box>
          )}
          showTooltip={isTooltipShown}
        />
      )}
    </>
  );
};

Coding.fragments = {
  entry: gql`
    fragment Coding on Coding {
      code
      system
    }
  `
};

export default Coding;
