import { gql } from "graphql-tag";
import Pagination from "../../../components/Pagination";
import Diagnosis from "../../PracticalMonitoring/Details/Diagnosis";
import Episode from "../../PracticalMonitoring/Details/Episode";

export const ImpersonalEpisodesQuery = gql`
  query ImpersonalEpisodesQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImpersonalEpisodeFilter!
    $orderBy: EpisodeOrderBy
  ) {
    impersonalEpisodes(
      after: $after
      before: $before
      filter: $filter
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      nodes {
        id
        databaseId
        status
        insertedAt
        currentDiagnoses {
          ...Diagnosis
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalEntries
    }
  }
  ${Pagination.fragments.entry}
  ${Diagnosis.fragments.entry}
`;

export const ImpersonalEpisodeByIDQuery = gql`
  query ImpersonalEpisodeQuery(
    $id: ID!
    $patientId: String!
    $skipAdditionalFields: Boolean!
  ) {
    impersonalEpisode(id: $id, patientId: $patientId) {
      ...Episode
    }
  }
  ${Episode.fragments.entry}
`;
