import React, { useMemo } from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import uniq from "lodash/uniq";

import { ForbiddenGroupConnection } from "@ehealth/ehealth-ua.schema";

import * as Field from "../Field";

type ForbiddenGroupCodesCodeFieldProps = {
  name: string;
};

const ForbiddenGroupCodesCodeField = ({
  name
}: ForbiddenGroupCodesCodeFieldProps) => (
  <Trans
    id="Enter Code"
    render={({ translation }) => (
      <Query
        query={SearchForbiddenGroupsQuery}
        fetchPolicy="cache-first"
        variables={{ first: 20, forbiddenGroupCodesFirst: 100 }}
      >
        {({
          data
        }: QueryResult<{ forbiddenGroups: ForbiddenGroupConnection }>) => (
          <CodesSelect
            forbiddenGroupsNodes={
              data && data.forbiddenGroups ? data.forbiddenGroups.nodes : []
            }
            translation={translation}
            name={name}
          />
        )}
      </Query>
    )}
  />
);

type CodesSelectProps = {
  forbiddenGroupsNodes: ForbiddenGroupConnection["nodes"];
  translation: React.ReactNode;
  name: string;
};

const CodesSelect = ({
  forbiddenGroupsNodes,
  translation,
  name
}: CodesSelectProps) => {
  const codesList = useMemo(() => {
    const codes = forbiddenGroupsNodes!.reduce((accum: $TSFixMe, item) => {
      const forbiddenGroupCodesNodes = item && item.forbiddenGroupCodes.nodes;
      if (forbiddenGroupCodesNodes && forbiddenGroupCodesNodes.length) {
        const codes = forbiddenGroupCodesNodes
          .map((item) => {
            return item && item.code;
          })
          .filter((item) => Boolean(item));
        return [...accum, ...codes];
      }
      return accum;
    }, []);
    return uniq(codes);
  }, [forbiddenGroupsNodes, name]);

  const itemToString = (item: string) => item;

  return (
    <Field.Select
      name={name}
      label={<Trans id="Find by Dictionary code" />}
      placeholder={translation}
      items={codesList}
      itemToString={itemToString}
    />
  );
};

export default ForbiddenGroupCodesCodeField;

const SearchForbiddenGroupsQuery = gql`
  query SearchForbiddenGroupsQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $forbiddenGroupCodesFirst: Int
  ) {
    forbiddenGroups(
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      nodes {
        id
        forbiddenGroupCodes(first: $forbiddenGroupCodesFirst) {
          nodes {
            id
            databaseId
            isActive
            insertedAt
            code
            system
          }
        }
      }
    }
  }
`;
