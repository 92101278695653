import { gql } from "graphql-tag";
import CodeableConcept from "../../../components/CodeableConcept";
import Pagination from "../../../components/Pagination";
import { Activity } from "../../PracticalMonitoring/Details/Activity/Activity";
import Reference from "../../PracticalMonitoring/Details/Reference";
import ServiceRequestCode from "../../PracticalMonitoring/Details/ServiceRequestCode";

export const ImpersonalActivitiesQuery = gql`
  query ImpersonalActivitiesQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImpersonalActivityFilter!
    $orderBy: ActivityOrderBy
  ) {
    impersonalActivities(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      nodes {
        id
        databaseId
        insertedAt
        detail {
          status
          productReference {
            ...ActivityProduct
          }
        }
      }
      totalEntries
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
  ${ServiceRequestCode.fragments.entryWithINNMDosage}
`;

export const ImpersonalActivityByIDQuery = gql`
  query ImpersonalActivityByIDQuery(
    $id: ID!
    $patientId: String!
    $carePlanId: ID!
  ) {
    impersonalActivity(
      id: $id
      patientId: $patientId
      carePlanId: $carePlanId
    ) {
      ...Activity
      outcomeCodeableConcept {
        ...CodeableConcept
      }
      outcomeReference {
        ...Reference
      }
    }
  }
  ${Activity.fragments.entry}
  ${Reference.fragments.entry}
  ${CodeableConcept.fragments!.entry}
`;
