import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Text, Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { InfoIcon } from "@edenlabllc/ehealth-icons";

import Tooltip from "../../../components/Tooltip";

type QuantityProps = {
  quantity?: any; // Maybe<TQuantity> | Maybe<TSimpleQuantity>;
};

const Quantity = ({ quantity }: QuantityProps) => {
  const [isTooltipShown, showTooltip] = useState(false);
  if (isEmpty(quantity)) return null;
  const { comparator, system, unit, value } = quantity;

  return (
    <Flex alignItems="center">
      <Text>
        {comparator} {value}
        {unit}
      </Text>
      {system && (
        <Tooltip
          content={system}
          component={() => (
            <Box ml={1}>
              <InfoIcon onClick={() => showTooltip(true)} cursor="pointer" />
            </Box>
          )}
          showTooltip={isTooltipShown}
        />
      )}
    </Flex>
  );
};

export default Quantity;

Quantity.fragments = {
  entry: gql`
    fragment Quantity on Quantity {
      code
      comparator
      system
      unit
      value
    }
  `
};
