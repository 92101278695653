const fromBase64toUUID = (base64: string) => {
  const withKey = atob(base64);
  const convertArr = withKey.match(
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g
  );

  return (convertArr && convertArr[0]) || base64;
};

export default fromBase64toUUID;
