import React from "react";
import { Router, RouteComponentProps, NavigateFn } from "@reach/router";
import { gql } from "graphql-tag";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import Composer from "react-composer";
import { TransRenderProps, useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Heading, Flex, Box, Text } from "@rebass/emotion";
import { isEmpty, debounce } from "lodash";

import { cleanDeep, convertStringToBoolean } from "@edenlabllc/ehealth-utils";
import {
  Form,
  Validation,
  Validations,
  SUBMIT_ERROR
} from "@edenlabllc/ehealth-components";
import {
  CreateInnmDosageInput,
  Dictionary,
  InnmDosage,
  Innm,
  InnmDosageIngredient,
  InnmConnection
} from "@ehealth/ehealth-ua.schema";

import Button from "../../components/Button";
import DefinitionListView from "../../components/DefinitionListView";
import DictionaryValue from "../../components/DictionaryValue";
import Dosage from "../../components/Dosage";
import * as Field from "../../components/Field";
import Line from "../../components/Line";
import Steps from "../../components/Steps";

import STATUSES from "../../helpers/statuses";
import { dosageFormIsDosedValues } from "./constants";

const Create = ({
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <>
    <Box pt={5} px={5}>
      <Steps.List>
        <Steps.Item to="./" state={state}>
          <Trans>Fill in the form</Trans>
        </Steps.Item>
        <Steps.Item to="./confirm" state={state} disabled>
          <Trans>Confirm</Trans>
        </Steps.Item>
      </Steps.List>
    </Box>
    <Router>
      <CreationForm path="/" />
      <Confirmation path="/confirm" />
    </Router>
  </>
);

const CreationForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { createInnmDosage } = state || {};
  return (
    <Box p={5}>
      <Heading as="h1" fontWeight="normal" mb={5}>
        <Trans>Create INNM Dosage</Trans>
      </Heading>
      <AddForm navigate={navigate!} createInnmDosage={createInnmDosage} />
    </Box>
  );
};

type AddFormProps = {
  navigate: NavigateFn;
  createInnmDosage: Partial<InnmDosage>;
};

const AddForm = ({ navigate, createInnmDosage }: AddFormProps) => {
  const { i18n } = useLingui();

  return (
    <Form
      onSubmit={({ ingredients, ...data }: CreateInnmDosageInput) => {
        const existIsPrimaryIngredient = ingredients.some(
          (ingredient) => ingredient && ingredient.isPrimary
        );
        const invalidIsPrimary = [
          {
            entry: "ingredients.isPrimary",
            rules: [
              {
                rule: "notExistIsPrimaryIngredient"
              }
            ]
          }
        ];
        if (!existIsPrimaryIngredient) {
          return {
            [SUBMIT_ERROR]: invalidIsPrimary
          };
        }

        const completedIngredients = ingredients.map((ingredient) => {
          const { isPrimary, dosage, ...ingredientsData } = ingredient || {};
          return {
            ...ingredientsData,
            isPrimary: isPrimary,
            dosage: {
              ...dosage,
              // @ts-ignore
              numeratorValue: parseFloat(dosage.numeratorValue),
              denumeratorValue: 1
            }
          };
        });
        navigate("./confirm", {
          state: {
            createInnmDosage: {
              ...data,
              ingredients: completedIngredients
            }
          }
        });
      }}
      initialValues={
        createInnmDosage || {
          ingredients: [
            {
              isPrimary: true
            }
          ]
        }
      }
    >
      <Flex mx={-1}>
        <Box px={1} width={2.5 / 7}>
          <Trans
            id="Enter name"
            render={({ translation }) => (
              <Field.Text
                name="name"
                label={<Trans id="Name" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Required field="name" message="Required field" />
        </Box>
        <Box px={1} width={1.5 / 7}>
          <Composer
            components={[
              <DictionaryValue name="MEDICATION_FORM" />,
              ({
                render
              }: {
                render: (props: TransRenderProps) => React.ReactElement;
              }) => <Trans id="Select option" render={render} />
            ]}
          >
            {([dict, { translation }]: [
              Dictionary["values"],
              { translation: React.ReactNode }
            ]) => (
              <Field.Select
                name="form"
                label={<Trans id="Form" />}
                placeholder={translation}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || translation}
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            )}
          </Composer>
          <Validation.Required field="form" message="Required field" />
        </Box>
        <Box px={1} width={1.5 / 7}>
          <Trans
            id="Select option"
            render={({ translation }) => (
              <Field.Select
                name="dosageFormIsDosed"
                label={<Trans id="Is Dosage Form dosed" />}
                items={Object.keys(STATUSES.YES_NO)}
                itemToString={(item: boolean) =>
                  // @ts-expect-error statuses boolean key
                  STATUSES.YES_NO[item] || translation
                }
                variant="select"
                emptyOption
                filterOptions={{
                  // @ts-expect-error statuses boolean key
                  keys: [(item: boolean) => STATUSES.YES_NO[item]]
                }}
              />
            )}
          />
          <Validation.Required
            field="dosageFormIsDosed"
            message="Required field"
          />
        </Box>
        <Box px={1} width={1.5 / 7}>
          <Composer
            components={[
              <DictionaryValue name="MR_BLANK_TYPES" />,
              ({
                render
              }: {
                render: (props: TransRenderProps) => React.ReactElement;
              }) => <Trans id="Select option" render={render} />
            ]}
          >
            {([dict, { translation }]: [
              Dictionary["values"],
              { translation: React.ReactNode }
            ]) => (
              <Field.Select
                name="mrBlankType"
                label={<Trans id="Type of Medication request blank" />}
                placeholder={translation}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || translation}
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            )}
          </Composer>
          <Validation.Required field="mrBlankType" message="Required field" />
        </Box>
      </Flex>
      <Flex mx={-1}>
        <Box px={1} width={1.5 / 7}>
          <Field.Number
            name="dailyDosage"
            label={<Trans id="Daily dosage" />}
            placeholder="0 - 1 000"
          />
          <Trans
            id="Must be greater than zero"
            render={({ translation }) => (
              <Validation.PositiveFloat
                field="dailyDosage"
                message={translation}
              />
            )}
          />
        </Box>
      </Flex>
      <Line />
      <Field.Array
        name="ingredients"
        addText={<Trans>Add ingredient</Trans>}
        removeText={<Trans>Delete</Trans>}
        fields={Ingredients}
        headerComponent={IngredientsHeader}
        firstItemPinned
        vertical
      />
      <Box>
        <Form.Error
          entry={{
            "ingredients.isPrimary": {
              notExistIsPrimaryIngredient: i18n._(
                t`At least one innm should be is primary`
              )
            }
          }}
          default={i18n._(t`Something went wrong. Please try again later`)}
        />
      </Box>
      <Flex mb={100}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate("../search")}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Button variant="green" width={140}>
            <Trans>Add</Trans>
          </Button>
        </Box>
      </Flex>
    </Form>
  );
};

type IngredientsProps = {
  name: string;
};

const Ingredients = ({ name }: IngredientsProps) => {
  const { i18n } = useLingui();

  return (
    <Flex mx={-1}>
      <Box px={1} width={2.5 / 7}>
        <Query
          query={SearchINNMsQuery}
          fetchPolicy="cache-first"
          variables={{
            skip: true
          }}
        >
          {({
            data,
            refetch: refetchINNMs
          }: QueryResult<{ innms: InnmConnection }>) => {
            const { innms: { nodes: innms = [] } = {} } = data || {};

            return (
              <>
                <Field.Select
                  name={`${name}.innm`}
                  label={<Trans id="Ingredient name" />}
                  placeholder={i18n._(t`Enter ingredient name`)}
                  items={innms!.map((ingredient) => ({
                    innmId: ingredient && ingredient.id,
                    name: ingredient && ingredient.name
                  }))}
                  itemToString={(item: Innm) => item && item.name}
                  filter={(innms: Innm[]) => innms}
                  onInputValueChange={debounce(
                    (name, { selectedItem, inputValue }) =>
                      !isEmpty(name) &&
                      (selectedItem && selectedItem.name) !== inputValue &&
                      refetchINNMs({
                        skip: false,
                        first: 20,
                        filter: { name, isActive: true }
                      }),
                    1000
                  )}
                />
                <Validations field={`${name}.innm`}>
                  <Validation.Required message="Required field" />
                  <Validation.Custom
                    options={({
                      value,
                      allValues: { ingredients }
                    }: $TSFixMe) => {
                      const filteredIngredients = ingredients.filter(
                        ({ innm }: $TSFixMe = {}) => {
                          const { innmId: allValuesId } = innm || {};
                          const { innmId: valueId } = value || {};
                          return valueId === allValuesId;
                        }
                      );
                      return filteredIngredients.length === 1;
                    }}
                    message="This ingredient is used more than once"
                  />
                </Validations>
              </>
            );
          }}
        </Query>
      </Box>
      <Box px={1} ml={2} width={1.5 / 7}>
        <Field.Number
          name={`${name}.dosage.numeratorValue`}
          label={<Trans id="Amount" />}
          placeholder="0 - 1 000"
        />
        <Validations field={`${name}.dosage.numeratorValue`}>
          <Validation.Required message="Required field" />
          <Validation.PositiveFloat
            message={i18n._(t`Must be greater than zero`)}
          />
        </Validations>
      </Box>

      <Box px={1} width={1.5 / 7}>
        <Composer components={[<DictionaryValue name="MEDICATION_UNIT" />]}>
          {([dict]: [Dictionary["values"]]) => {
            const translation = i18n._(t`Select option`);
            return (
              <Field.Select
                name={`${name}.dosage.numeratorUnit`}
                label={<Trans id="Units" />}
                placeholder={translation}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || translation}
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            );
          }}
        </Composer>
        <Validation.Required
          field={`${name}.dosage.numeratorUnit`}
          message="Required field"
        />
      </Box>
      <Box px={1} ml={2} width={1.5 / 7}>
        <Field.Number
          name={`${name}.dosage.denumeratorValue`}
          label={<Trans id="By quantity" />}
          placeholder="0 - 1 000"
        />
        <Validations field={`${name}.dosage.denumeratorValue`}>
          <Validation.Required message="Required field" />
          <Validation.PositiveFloat
            message={i18n._(t`Must be greater than zero`)}
          />
        </Validations>
      </Box>
      <Box px={1} width={1.5 / 7}>
        <Composer components={[<DictionaryValue name="MEDICATION_UNIT" />]}>
          {([dict]: [Dictionary["values"]]) => {
            const translation = i18n._(t`Select option`);
            return (
              <Field.Select
                name={`${name}.dosage.denumeratorUnit`}
                label={<Trans id="Units" />}
                placeholder={translation}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || translation}
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            );
          }}
        </Composer>
        <Validation.Required
          field={`${name}.dosage.denumeratorUnit`}
          message="Required field"
        />
      </Box>
      <Box px={1} width={1.5 / 7}>
        <Trans
          id="Select option"
          render={({ translation }) => (
            <Field.Select
              name={`${name}.isPrimary`}
              label={<Trans id="Main substance" />}
              items={Object.keys(STATUSES.YES_NO)}
              itemToString={(item: boolean) =>
                // @ts-expect-error statuses boolean key
                STATUSES.YES_NO[item] || translation
              }
              variant="select"
              emptyOption
              filterOptions={{
                // @ts-expect-error statuses boolean key
                keys: [(item: boolean) => STATUSES.YES_NO[item]]
              }}
            />
          )}
        />
        <Validation.IsExists
          field={`${name}.isPrimary`}
          message="Required field"
        />
      </Box>
    </Flex>
  );
};

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  if (!state) return null;
  const {
    createInnmDosage,
    createInnmDosage: {
      name,
      form,
      dailyDosage,
      ingredients,
      dosageFormIsDosed,
      mrBlankType
    } // = {}
  } = state;
  const floatDailyDosage = dailyDosage && parseFloat(dailyDosage);

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          name: <Trans>INNM dosage name</Trans>,
          form: <Trans>Form</Trans>,
          dosageFormIsDosed: <Trans>Is Dosage Form dosed</Trans>,
          ...(!isEmpty(mrBlankType) && {
            mrBlankType: <Trans>Type of Medication request blank</Trans>
          }),
          dailyDosage: <Trans>Daily dosage</Trans>
        }}
        data={{
          name,
          form: <DictionaryValue name="MEDICATION_FORM" item={form} />,
          dosageFormIsDosed: dosageFormIsDosedValues[dosageFormIsDosed],
          mrBlankType: mrBlankType && (
            <DictionaryValue name="MR_BLANK_TYPES" item={mrBlankType} />
          ),
          dailyDosage: <Dosage dosage={floatDailyDosage} />
        }}
        labelWidth="200px"
      />
      <Line />
      {ingredients.map(
        (
          {
            innm: { name },
            dosage: {
              numeratorValue,
              numeratorUnit,
              denumeratorValue,
              denumeratorUnit
            },
            isPrimary
          }: InnmDosageIngredient,
          index: number
        ) => (
          <Box key={index}>
            <IngredientsHeader index={index} />
            <DefinitionListView
              labels={{
                innm: <Trans>Ingredient name</Trans>,
                dosages: <Trans>Dosage</Trans>,
                isPrimary: <Trans>Main substance</Trans>
              }}
              data={{
                innm: name,
                dosages: (
                  <>
                    {denumeratorValue}{" "}
                    <DictionaryValue
                      name="MEDICATION_UNIT"
                      item={denumeratorUnit}
                    />{" "}
                    <Trans>includes</Trans> {numeratorValue}{" "}
                    <DictionaryValue
                      name="MEDICATION_UNIT"
                      item={numeratorUnit}
                    />
                  </>
                ),
                isPrimary:
                  isPrimary === true ? <Trans>Yes</Trans> : <Trans>No</Trans>
              }}
              labelWidth="200px"
            />
            <Line />
          </Box>
        )
      )}
      <Flex mt={5}>
        <Box mr={3}>
          <Button
            variant="blue"
            width={140}
            onClick={() => {
              navigate!("../", {
                state: {
                  createInnmDosage
                }
              });
            }}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Mutation mutation={CreateINNMDosageMutation}>
            {(createInnmDosage: MutationFunction) => (
              <Button
                variant="green"
                width={140}
                onClick={async () => {
                  const completedIngredients = ingredients.map(
                    ({
                      innm: { innmId },
                      ...data
                    }: InnmDosageIngredient & {
                      innm: { innmId: string };
                    }) => ({
                      ...data,
                      innmId
                    })
                  );

                  const dosages = cleanDeep({
                    dailyDosage: floatDailyDosage
                  });

                  await createInnmDosage({
                    variables: {
                      input: {
                        ...dosages,
                        name,
                        form,
                        ingredients: completedIngredients,
                        dosageFormIsDosed: convertStringToBoolean(
                          dosageFormIsDosed
                        ),
                        mrBlankType
                      }
                    }
                  });
                  await navigate!("../../search");
                }}
              >
                <Trans>Add</Trans>
              </Button>
            )}
          </Mutation>
        </Box>
      </Flex>
    </Box>
  );
};

type IngredientsHeaderProps = {
  index: number;
};

const IngredientsHeader = ({ index }: IngredientsHeaderProps) => (
  <Text fontSize={2} mb={6}>
    {index ? (
      <Trans>Additional ingredient</Trans>
    ) : (
      <Trans>Main ingredient</Trans>
    )}
  </Text>
);

const CreateINNMDosageMutation = gql`
  mutation CreateINNMDosageMutation($input: CreateINNMDosageInput!) {
    createInnmDosage(input: $input) {
      innmDosage {
        id
      }
    }
  }
`;

const SearchINNMsQuery = gql`
  query SearchINNMsQuery(
    $first: Int
    $filter: INNMFilter
    $skip: Boolean! = false
  ) {
    innms(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        name
      }
    }
  }
`;

export default Create;
