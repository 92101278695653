import * as React from "react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Flex } from "@rebass/emotion";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Validation } from "@edenlabllc/ehealth-components";
import { Dictionary } from "@ehealth/ehealth-ua.schema";

import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";
import DictionaryValue from "../../../components/DictionaryValue";

import { EDRPOU_OR_PASSPORT_OR_LEGAL_ENTITY_ID_PATTERN } from "../../../constants/validationPatterns";

const PrimarySearchFields = () => {
  const { i18n } = useLingui();

  return (
    <Box mt={6}>
      <Box width={1 / 1.5}>
        <Trans
          id="Legal entity edrpou"
          render={({ translation }) => (
            <Field.Text
              name={"filter.reorganizedLegalEntity.edrpou"}
              label={<Trans id="Search reorganized legal entity" />}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
            />
          )}
        />
        <Validation.Matches
          field={"filter.reorganizedLegalEntity.edrpou"}
          options={EDRPOU_OR_PASSPORT_OR_LEGAL_ENTITY_ID_PATTERN}
          message={i18n._(t`Invalid number`)}
        />
      </Box>

      <Flex width={1 / 1.5}>
        <Box width={1 / 2} mr={4}>
          <DictionaryValue
            name="LEGAL_ENTITY_REORGANIZATION_TYPES"
            render={(dict: Dictionary["values"]) => (
              <Trans
                id="All reorganization type"
                render={({ translation }) => (
                  <Field.Select
                    variant="select"
                    name="filter.legalEntityReorganizationJobType"
                    label={<Trans id="Reorganization Type" />}
                    placeholder={translation}
                    itemToString={(item: string) => dict[item] || translation}
                    items={Object.keys(dict)}
                    size="small"
                    emptyOption
                    filterOptions={{ keys: [(item: string) => dict[item]] }}
                  />
                )}
              />
            )}
          />
        </Box>

        <Box width={1 / 2}>
          <SearchField.Status
            name="filter.status"
            status="JOBS"
            label={<Trans id="Job status" />}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export { PrimarySearchFields };
