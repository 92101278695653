import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import {
  MutationFunction,
  MutationResult
} from "@apollo/client/react/types/types";
import Papa from "papaparse";
import Dropzone from "react-dropzone";
import { Trans } from "@lingui/macro";
import { Box, Heading, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import Button from "../../components/Button";
import DropzoneView from "../../components/DropzoneView";
import DropzoneFileName from "../../components/DropzoneFileName";

import { UUID_PATTERN } from "../../constants/validationPatterns";

const ONE_MB = 1048576;

const ResetPersonsAuthMethod = ({ navigate }: RouteComponentProps) => {
  const [fileContent, setFileContent] = React.useState<{
    validEntries: unknown[];
    invalidEntries: unknown[];
  }>({
    validEntries: [],
    invalidEntries: []
  });
  const [isLoading, setLoading] = React.useState(false);

  return (
    <Box p={6}>
      <Heading as="h1" fontWeight="normal" mb={4}>
        <Trans>Reset persons authentication method</Trans>
      </Heading>
      <Mutation mutation={ResetPersonsAuthenticationMethodMutation}>
        {(resetPersonsAuth: MutationFunction, { loading }: MutationResult) => (
          <>
            <Dropzone
              multiple={false}
              accept=".csv"
              maxSize={ONE_MB}
              onDrop={(acceptedFiles) => {
                const reader = new FileReader();
                reader.onloadstart = () => {
                  setFileContent({
                    validEntries: [],
                    invalidEntries: []
                  });
                  setLoading(true);
                };
                reader.onloadend = () => setLoading(false);
                reader.onload = () => {
                  const parseResult = Papa.parse(reader.result as string, {
                    skipEmptyLines: true
                  });
                  const validEntries = parseResult.data
                    .flat()
                    .filter((e: $TSFixMe) => new RegExp(UUID_PATTERN).test(e));
                  const invalidEntries = parseResult.data
                    .flat()
                    .filter((e: $TSFixMe) => !new RegExp(UUID_PATTERN).test(e));

                  setFileContent({
                    validEntries,
                    invalidEntries
                  });
                };
                acceptedFiles.forEach((file) =>
                  reader.readAsBinaryString(file)
                );
              }}
            >
              {({
                acceptedFiles,
                getRootProps,
                getInputProps,
                rejectedFiles
              }) => {
                const [fileSizeError] = rejectedFiles.map(
                  (file) => file.size > ONE_MB
                );

                return (
                  <>
                    <DropzoneView {...getRootProps()}>
                      <input {...getInputProps()} />
                      {!isEmpty(acceptedFiles) ? (
                        <DropzoneFileName files={acceptedFiles} />
                      ) : !isEmpty(rejectedFiles) ? (
                        <DropzoneFileName files={rejectedFiles} />
                      ) : (
                        <Trans>
                          Drag 'n' drop file here, or click to select file
                        </Trans>
                      )}
                    </DropzoneView>
                    {fileSizeError && (
                      <Text color="red" fontSize={1} mb={2}>
                        <Trans>File size more than 1MB</Trans>
                      </Text>
                    )}
                    {!isEmpty(fileContent.invalidEntries) && (
                      <InvalidEntriesList
                        invalidEntries={fileContent.invalidEntries}
                      />
                    )}
                    <Button
                      variant="green"
                      disabled={
                        loading ||
                        isLoading ||
                        fileSizeError ||
                        !isEmpty(fileContent.invalidEntries) ||
                        isEmpty(fileContent.validEntries)
                      }
                      loading={loading}
                      onClick={async () => {
                        await resetPersonsAuth({
                          variables: {
                            input: {
                              databaseIds:
                                fileContent && fileContent.validEntries
                            }
                          }
                        });
                        await navigate!("/reset-persons-auth-method-jobs");
                      }}
                    >
                      <Trans>Reset authentication method</Trans>
                    </Button>
                  </>
                );
              }}
            </Dropzone>
          </>
        )}
      </Mutation>
    </Box>
  );
};

type InvalidEntriesListProps = {
  invalidEntries: unknown[];
};

const InvalidEntriesList = ({ invalidEntries }: InvalidEntriesListProps) => (
  <Box mb={3}>
    <Text fontWeight="bold" fontSize={2}>
      <Trans>Invalid fields</Trans>
    </Text>
    <Box
      as="ul"
      mt={1}
      style={{
        maxHeight: 200,
        overflow: "auto"
      }}
    >
      {invalidEntries.map((item) => (
        <Box as="li" key={item}>
          <Text color="#333" fontSize={0} mt={1}>
            {item}
          </Text>
        </Box>
      ))}
    </Box>
  </Box>
);

const ResetPersonsAuthenticationMethodMutation = gql`
  mutation ResetPersonsAuthMutation($input: ResetPersonsAuthInput!) {
    resetPersonsAuth(input: $input) {
      personsAuthResetJob {
        id
      }
    }
  }
`;

export default ResetPersonsAuthMethod;
