import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { PositiveIcon, NegativeIcon } from "@edenlabllc/ehealth-icons";
import {
  ServiceGroup,
  ServiceGroupConnection,
  ServiceConnection,
  Scalars
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DefinitionListView from "../../../components/DefinitionListView";
import EmptyData from "../../../components/EmptyData";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import {
  SearchParams,
  SetSearchParams,
  TLocationParams
} from "../../../components/SearchForm";
import Tabs from "../../../components/Tabs";

import filteredLocationParams from "../../../helpers/filteredLocationParams";
import { useAbility } from "../../../helpers/useAbility";

import ServiceGroupsTable from "../Search/ServiceGroupsTable";
import ServicesTable from "../../Services/Search/ServicesTable";
import AddServiceToGroupPopup from "./AddServiceToGroupPopup";
import DeleteServiceFromGroup from "./DeleteServiceFromGroup";
import UpdateServiceGroupPopup from "./UpdateServiceGroupPopup";
import DeactivateServiceGroupPopup from "./DeactivateServiceGroupPopup";

type DetailsProps = RouteComponentProps<{
  id: Scalars["ID"];
}>;

const Details = ({ id }: DetailsProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => {
      const filteredParams = filteredLocationParams(locationParams);

      return (
        <Query
          fetchPolicy="network-only"
          query={ServiceGroupDetailsQuery}
          variables={{ id, ...filteredParams }}
        >
          {({ loading, data }: QueryResult<{ serviceGroup: ServiceGroup }>) => {
            if (isEmpty(data) || isEmpty(data.serviceGroup)) return null;
            const {
              databaseId,
              name,
              isActive,
              requestAllowed,
              parentGroup,
              services,
              subGroups
            } = data.serviceGroup;

            const { id: parentGroupId, name: parentGroupName } =
              parentGroup || {};

            return (
              <LoadingOverlay loading={loading}>
                <Box p={6}>
                  <Box py={10}>
                    <Breadcrumbs.List>
                      <Breadcrumbs.Item to="/service-groups">
                        <Trans>Service groups</Trans>
                      </Breadcrumbs.Item>
                      <Breadcrumbs.Item>
                        <Trans>Service group details</Trans>
                      </Breadcrumbs.Item>
                    </Breadcrumbs.List>
                  </Box>
                  <Flex justifyContent="space-between" alignItems="flex-end">
                    <Box>
                      <DefinitionListView
                        labels={{
                          name: <Trans>Name</Trans>,
                          databaseId: <Trans>ID</Trans>,
                          isActive: <Trans>Status</Trans>,
                          requestAllowed: <Trans>Is request allowed</Trans>,
                          parentGroup: <Trans>Parent group</Trans>
                        }}
                        data={{
                          databaseId,
                          name,
                          isActive: (
                            <Badge
                              type="ACTIVE_STATUS_F"
                              name={isActive}
                              variant={!isActive}
                              minWidth={100}
                            />
                          ),
                          requestAllowed: requestAllowed ? (
                            <PositiveIcon />
                          ) : (
                            <NegativeIcon />
                          ),
                          parentGroup: parentGroupId && (
                            <Link to={`../${parentGroupId}`} fontWeight="bold">
                              {parentGroupName}
                            </Link>
                          )
                        }}
                        color="#7F8FA4"
                        labelWidth="135px"
                        marginBetween="auto"
                      />
                    </Box>
                    {isActive && (
                      <Ability action="write" resource="service_catalog">
                        <Flex justifyContent="flex-end" flexWrap="wrap">
                          <Box mt={2}>
                            <UpdateServiceGroupPopup
                              id={id!}
                              name={name}
                              requestAllowed={requestAllowed}
                              serviceGroupDetailsQuery={
                                ServiceGroupDetailsQuery
                              }
                              locationParams={filteredParams}
                            />
                          </Box>
                          <Box mt={2} ml={2}>
                            <DeactivateServiceGroupPopup
                              id={id!}
                              name={name}
                              serviceGroupDetailsQuery={
                                ServiceGroupDetailsQuery
                              }
                              locationParams={filteredParams}
                            />
                          </Box>
                        </Flex>
                      </Ability>
                    )}
                  </Flex>
                </Box>
                <Tabs.Nav>
                  <Tabs.NavItem to="./">
                    <Trans>Services</Trans>
                  </Tabs.NavItem>
                  <Tabs.NavItem to="./subgroups">
                    <Trans>Subgroups</Trans>
                  </Tabs.NavItem>
                </Tabs.Nav>
                <Tabs.Content>
                  <Router>
                    <Services
                      path="/"
                      id={id}
                      groupName={name}
                      isActive={isActive}
                      services={services}
                      locationParams={filteredParams}
                      setLocationParams={setLocationParams}
                    />
                    <SubGroups
                      path="/subgroups"
                      subGroups={subGroups}
                      locationParams={filteredParams}
                      setLocationParams={setLocationParams}
                    />
                  </Router>
                </Tabs.Content>
              </LoadingOverlay>
            );
          }}
        </Query>
      );
    }}
  </LocationParams>
);

type SubGroupsProps = RouteComponentProps & {
  subGroups: ServiceGroupConnection;
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const SubGroups = ({
  subGroups,
  locationParams,
  setLocationParams
}: SubGroupsProps) => {
  const { nodes, pageInfo } = subGroups || {};
  if (isEmpty(nodes)) return <EmptyData />;

  return (
    <>
      <ServiceGroupsTable
        serviceGroups={nodes!}
        locationParams={locationParams}
        setLocationParams={setLocationParams}
        tableName="service-group-details/subgroups-table"
      />
      <Pagination {...pageInfo} />
    </>
  );
};

type ServicesProps = RouteComponentProps<{
  id: string;
  services: ServiceConnection;
  isActive: boolean;
  groupName: string;
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
}>;

const Services = ({
  id,
  services,
  isActive,
  groupName,
  locationParams,
  setLocationParams
}: ServicesProps) => {
  const { nodes, pageInfo } = services || {};
  const [serviceCatalogAbility] = useAbility("write", "service_catalog");

  return (
    <>
      {isActive && (
        <Ability action="write" resource="service_catalog">
          <AddServiceToGroupPopup
            serviceGroupId={id!}
            serviceGroupName={groupName!}
            locationParams={locationParams!}
            serviceGroupDetailsQuery={ServiceGroupDetailsQuery}
          />
        </Ability>
      )}
      {!isEmpty(nodes) ? (
        <>
          <ServicesTable
            services={nodes || []}
            locationParams={locationParams!}
            setLocationParams={setLocationParams!}
            deleteServiceHeader={{
              ...(serviceCatalogAbility && { action: <Trans>Action</Trans> })
            }}
            deleteServiceFromGroup={({
              serviceId,
              serviceName
            }: {
              serviceId: string;
              serviceName: string;
            }) => (
              <DeleteServiceFromGroup
                serviceId={serviceId}
                serviceName={serviceName}
                serviceGroupId={id!}
                serviceGroupName={groupName!}
                locationParams={locationParams!}
                serviceGroupDetailsQuery={ServiceGroupDetailsQuery}
              />
            )}
            tableName="service-group-details/services-table"
          />
          <Pagination {...pageInfo!} />
        </>
      ) : (
        <EmptyData mx={0} my={5} />
      )}
    </>
  );
};

const ServiceGroupDetailsQuery = gql`
  query ServiceGroupDetailsQuery(
    $id: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: ServiceOrderBy
    $orderGroupBy: ServiceGroupOrderBy
  ) {
    serviceGroup(id: $id) {
      id
      databaseId
      name
      isActive
      requestAllowed
      parentGroup {
        id
        name
      }
      services(
        first: $first
        orderBy: $orderBy
        before: $before
        after: $after
        last: $last
      ) {
        nodes {
          ...Services
        }
        pageInfo {
          ...PageInfo
        }
      }
      subGroups(
        first: $first
        orderBy: $orderGroupBy
        before: $before
        after: $after
        last: $last
      ) {
        nodes {
          ...ServiceGroups
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${ServicesTable.fragments.entry}
  ${ServiceGroupsTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Details;
