import React from "react";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { loader } from "graphql.macro";

import { DictionaryConnection } from "@ehealth/ehealth-ua.schema";

const DictionaryQuery = loader("./graphql/SearchDictionariesQuery.graphql");

type PreloadProps = {
  children: React.ReactElement;
};

const Preload = ({ children }: PreloadProps) => (
  <Query
    fetchPolicy="cache-first"
    query={DictionaryQuery}
    variables={{ first: 400 }}
  >
    {({ loading, error }: QueryResult<{ data: DictionaryConnection }>) => {
      if (loading || error) return null;
      return children;
    }}
  </Query>
);

export default Preload;
