import React from "react";

import system from "@edenlabllc/ehealth-system-components";
import { InfoIconRound } from "@edenlabllc/ehealth-icons";

type Variant = "default" | "red" | "blue" | "orange";

const Icon = system(
  {
    is: InfoIconRound,
    width: 14,
    height: 14,
    mr: 2,
    variant: "default"
  },
  "space",
  "width",
  "height"
);

const Notification = system(
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: 0,
    fontSize: 1,
    height: "50px",
    outline: "none",
    variant: "default"
  },
  `
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  text-decoration: none;

  `,
  ({ variant }: { variant: Variant }) => variants[variant],
  "height",
  "display",
  "alignItems",
  "justifyContent",
  "border",
  "fontSize"
);

const variants = {
  default: {
    backgroundImage: "linear-gradient(0deg,#39B54A 0%,#34AA44 100%)",
    color: "#fff"
  },
  red: {
    backgroundImage: "linear-gradient(0deg,#F85359 0%,#DC151D 100%)",
    color: "#fff"
  },
  blue: {
    backgroundImage: "linear-gradient(0deg,#1991EB 0%,#2DA1F8 100%)",
    color: "#fff"
  },
  orange: {
    backgroundImage: "linear-gradient(0deg,#F7981C 0%,#F76B1C 100%)",
    color: "#fff"
  }
};

type NotificationProps = {
  children: React.ReactElement;
  variant: Variant;
  icon?: React.ReactElement;
};

export default ({ children, icon, ...rest }: NotificationProps) => (
  <Notification {...rest}>
    <Icon is={icon} />
    <span>{children}</span>
  </Notification>
);
