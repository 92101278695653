import React from "react";
import { BooleanValue } from "react-values";
import { Query, Mutation } from "@apollo/client/react/components";
import { loader } from "graphql.macro";
import { DocumentNode, MutationFunction, QueryResult } from "@apollo/client";
import { Manager, Reference, Popper } from "react-popper";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Flex } from "@rebass/emotion";
import { debounce, isEmpty } from "lodash";

import system from "@edenlabllc/ehealth-system-components";
import { getFullName } from "@edenlabllc/ehealth-utils";
import { boolean } from "@edenlabllc/ehealth-system-tools";
import { Form } from "@edenlabllc/ehealth-components";
import { DropDownButton } from "@edenlabllc/ehealth-icons";
import { EmployeeConnection, Employee } from "@ehealth/ehealth-ua.schema";

import * as Field from "./Field";

const AssignContractRequestMutation = loader(
  "../graphql/AssignContractRequestMutation.graphql"
);
const GetAssignEmployeeQuery = loader(
  "../graphql/GetAssignEmployeeQuery.graphql"
);

type ModalAssigneeSearchProps = {
  submitted: React.ReactNode;
  id: string | undefined;
  query: DocumentNode;
};

const ModalAssigneeSearch = ({
  submitted,
  id,
  query
}: ModalAssigneeSearchProps) => {
  const { i18n } = useLingui();
  return (
    <BooleanValue>
      {({ value: opened, toggle }: $TSFixMe) => (
        <Form onSubmit={() => null}>
          <Mutation
            mutation={AssignContractRequestMutation}
            refetchQueries={() => [
              {
                query,
                variables: { id }
              }
            ]}
          >
            {(assignContractRequest: MutationFunction) => (
              <Form.AutoSubmit
                onSubmit={({ assignee }: { assignee: Employee }) => {
                  if (assignee) {
                    assignContractRequest({
                      variables: {
                        input: {
                          id,
                          employeeId: assignee.id
                        }
                      }
                    });
                    toggle();
                  }
                }}
              />
            )}
          </Mutation>
          <Manager>
            <Reference>
              {({ ref }) => (
                <Flex ref={ref} alignItems="center">
                  {submitted}
                  <ButtonWrapper onClick={toggle}>
                    {!submitted && <DropDownButton color="#2EA2F8" />}
                    <ButtonText>
                      {!submitted ? "Додати виконавця" : "Змінити"}
                    </ButtonText>
                  </ButtonWrapper>
                </Flex>
              )}
            </Reference>
            {opened && (
              // @ts-expect-error TS(2769): No overload matches this call.
              <Popper placement="bottom-start" positionFixed>
                {({ ref, style }) => (
                  <ModalWrapper style={style} ref={ref}>
                    <Query
                      query={GetAssignEmployeeQuery}
                      fetchPolicy="no-cache"
                      variables={{
                        skip: true,
                        first: 50,
                        filter: {
                          employeeType: ["NHS ADMIN SIGNER"],
                          status: "APPROVED"
                        },
                        orderBy: "INSERTED_AT_DESC"
                      }}
                    >
                      {({
                        data,
                        refetch: refetchEmployees
                      }: QueryResult<{ employees: EmployeeConnection }>) => {
                        const { employees } = data || {};

                        return (
                          <Field.Select
                            name="assignee"
                            placeholder={i18n._(t`Choose assignee`)}
                            items={
                              employees && employees.nodes
                                ? employees.nodes.map((employee) => {
                                    const { id, party } = employee || {};
                                    return {
                                      id,
                                      name: getFullName(party)
                                    };
                                  })
                                : []
                            }
                            onInputValueChange={debounce(
                              (name) =>
                                !isEmpty(name) &&
                                opened &&
                                refetchEmployees({
                                  skip: false,
                                  first: 50,
                                  filter: {
                                    employeeType: ["NHS ADMIN SIGNER"],
                                    status: "APPROVED",
                                    party: { fullName: name }
                                  }
                                }),
                              1000
                            )}
                            itemToString={(item: {
                              id: string;
                              name: string;
                            }) => item && item.name}
                            filterOptions={{ keys: ["name"] }}
                            style={{
                              margin: "5px",
                              border: "1px solid #DFE3E9"
                            }}
                            hideErrors
                            autoFocus
                          />
                        );
                      }}
                    </Query>
                  </ModalWrapper>
                )}
              </Popper>
            )}
          </Manager>
        </Form>
      )}
    </BooleanValue>
  );
};

export default ModalAssigneeSearch;

const ModalWrapper = system(
  {
    width: 245,
    mt: 2,
    fontSize: 1,
    color: "darkAndStormy"
  },
  {
    zIndex: 100,
    boxShadow: "0 1px 4px rgba(72, 60, 60, 0.2)"
  },
  boolean({
    prop: "visible",
    key: "inputs.select.visible"
  }),
  "space",
  "color",
  "fontSize",
  "width"
);

const ButtonWrapper = system(
  {},
  {
    display: "flex",
    cursor: "pointer"
  }
);

const ButtonText = system(
  {
    is: "span",
    ml: 2,
    color: "rockmanBlue",
    fontSize: 0,
    fontWeight: 700
  },
  "space",
  "color",
  "fontSize",
  "fontWeight"
);
