import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { Box, Heading } from "@rebass/emotion";
import { Trans, t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import {
  ManualMergeCandidate,
  ManualMergeCandidateConnection
} from "@ehealth/ehealth-ua.schema";

import Button from "../../components/Button";
import Pagination from "../../components/Pagination";
import SearchForm, { TLocationParams } from "../../components/SearchForm";
import Table from "../../components/Table";

import pagination from "../../helpers/pagination";
import { ITEMS_PER_PAGE } from "../../constants/pagination";

import PrimarySearchFields from "./PrimarySearchFields";

export const CANDIDATE_MERGE_REQUEST_STATUSES: {
  values: { [key: string]: string };
} = {
  values: {
    NEW: i18n._(t`NEW`),
    PROCESSED: i18n._(t`PROCESSED`)
  }
};

const Search = ({ navigate }: RouteComponentProps) => (
  <Box p={6}>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Query
          query={CandidatesMergeRequestsQuery}
          fetchPolicy="network-only"
          variables={{
            ...pagination(locationParams),
            filter: {
              status:
                locationParams.filter && locationParams.filter.status
                  ? locationParams.filter.status
                  : Object.keys(CANDIDATE_MERGE_REQUEST_STATUSES.values)[0],
              ...(locationParams.filter &&
                locationParams.filter.masterPersonId && {
                  masterPersonId: locationParams.filter.masterPersonId
                }),
              ...(locationParams.filter &&
                locationParams.filter.personId && {
                  personId: locationParams.filter.personId
                })
            }
          }}
        >
          {({
            data,
            error
          }: QueryResult<{
            manualMergeCandidates: ManualMergeCandidateConnection;
          }>) => {
            if (isEmpty(data) || isEmpty(data.manualMergeCandidates) || error)
              return null;
            return (
              <>
                <Heading as="h1" fontWeight="normal" mb={4}>
                  <Trans>Search candidates merge requests</Trans>
                </Heading>
                <SearchForm
                  initialValues={{
                    filter: {
                      status: Object.keys(
                        CANDIDATE_MERGE_REQUEST_STATUSES.values
                      )[0]
                    },
                    ...locationParams
                  }}
                  onSubmit={setLocationParams}
                  renderPrimary={PrimarySearchFields}
                />
                <Mutation
                  mutation={AssignMergeCandidateMutation}
                  refetchQueries={() => [
                    {
                      query: CandidatesMergeRequestsQuery,
                      variables: {
                        first: ITEMS_PER_PAGE[0]
                      }
                    }
                  ]}
                >
                  {(assignMergeCandidate: MutationFunction) => (
                    <Table
                      data={data.manualMergeCandidates.nodes}
                      header={{
                        databaseId: <Trans>Pair ID</Trans>,
                        masterPersonId: <Trans>Master person id</Trans>,
                        personId: <Trans>Person id</Trans>,
                        status: <Trans>Status</Trans>,
                        assigneeId: <Trans>Assignee id</Trans>,
                        action: <Trans>Action</Trans>
                      }}
                      renderRow={({
                        databaseId,
                        assigneeId,
                        mergeCandidate,
                        status
                      }: ManualMergeCandidate) => {
                        const isDisabled = status === "PROCESSED";

                        return {
                          databaseId: mergeCandidate.databaseId,
                          assigneeId,
                          masterPersonId:
                            mergeCandidate.masterPerson.databaseId,
                          personId: mergeCandidate.person.databaseId,
                          status: <Trans id={status || ""} />,
                          action: (
                            <Button
                              onClick={async () => {
                                const { data } = await assignMergeCandidate({
                                  variables: {
                                    input: {
                                      manualMergeCandidateId: databaseId
                                    }
                                  }
                                });
                                if (
                                  data &&
                                  !isEmpty(data.assignMergeCandidate)
                                ) {
                                  await navigate!(
                                    "/applications-patient-merge-requests"
                                  );
                                }
                              }}
                              disabled={isDisabled}
                              variant={isDisabled ? "linkDisabled" : "link"}
                            >
                              <Trans>Perform</Trans>
                            </Button>
                          )
                        };
                      }}
                    />
                  )}
                </Mutation>
                <Pagination {...data.manualMergeCandidates.pageInfo} />
              </>
            );
          }}
        </Query>
      )}
    </LocationParams>
  </Box>
);

export default Search;

const CandidatesMergeRequestsQuery = gql`
  query CandidatesMergeRequestsQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: ManualMergeCandidateFilter
    $orderBy: ManualMergeCandidateOrderBy
  ) {
    manualMergeCandidates(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      nodes {
        id
        assigneeId
        databaseId
        status
        insertedAt
        updatedAt
        decision
        mergeCandidate {
          id
          databaseId
          masterPerson {
            id
            databaseId
          }
          person {
            id
            databaseId
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

export const AssignMergeCandidateMutation = gql`
  mutation AssignMergeCandidateMutation($input: AssignMergeCandidateInput!) {
    assignMergeCandidate(input: $input) {
      mergeRequest {
        id
        databaseId
        insertedAt
        updatedAt
        status
      }
    }
  }
`;
