import React, { useCallback } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box, Heading } from "@rebass/emotion";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { convertStringToBoolean, cleanDeep } from "@edenlabllc/ehealth-utils";
import { ProgramMedicationConnection } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import ProgramMedicationsTable from "./ProgramMedicationsTable";
import { PrimarySearchFields, SecondarySearchFields } from "./SearchFields";

const Search = ({ navigate }: RouteComponentProps) => {
  const onAddParticipant = useCallback(() => navigate!("../create"), [
    navigate
  ]);

  return (
    <Box p={6}>
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => {
          const {
            filter: {
              isActive,
              medicationRequestAllowed,
              medicalProgram,
              medication,
              innmDosages,
              medicalProgramId,
              medicationId,
              ...params
            } = {}
          } = locationParams;

          const medicationFilter: any = {
            medication: medication && {
              name: medication.name
            }
          };

          if (medicationId) {
            medicationFilter.medication = {
              ...medication,
              databaseId: medicationId
            };
          }

          if (innmDosages) {
            medicationFilter.medication = {
              ...(medicationFilter.medication || medication),
              innmDosages: {
                name: innmDosages.name,
                databaseId: innmDosages.databaseId
              }
            };
          }

          const medicalProgramFilter: any = {
            medicalProgram: medicalProgram && {
              name: medicalProgram.name,
              mrBlankType: medicalProgram.mrBlankType
            }
          };

          if (medicalProgramId) {
            medicalProgramFilter.medicalProgram = {
              ...medicalProgram,
              databaseId: medicalProgramId
            };
          }

          const programMedicationsFilter = cleanDeep({
            ...params,
            ...medicalProgramFilter,
            ...medicationFilter,
            isActive: convertStringToBoolean(isActive),
            medicationRequestAllowed: convertStringToBoolean(
              medicationRequestAllowed
            )
          });

          return (
            <>
              <Flex
                justifyContent="space-between"
                alignItems="flex-start"
                mb={6}
              >
                <Box>
                  <Heading as="h1" fontWeight="normal" mb={4}>
                    <Trans>Program medications</Trans>
                  </Heading>
                </Box>
                <Ability action="write" resource="program_medication">
                  <Box>
                    <Button onClick={onAddParticipant} variant="green">
                      <Trans>Add participant</Trans>
                    </Button>
                  </Box>
                </Ability>
              </Flex>
              <SearchForm
                initialValues={locationParams}
                onSubmit={setLocationParams}
                renderPrimary={PrimarySearchFields}
                renderSecondary={SecondarySearchFields}
              />
              <Query
                query={SearchProgramMedicationsQuery}
                fetchPolicy="network-only"
                variables={{
                  ...filteredLocationParams(locationParams),
                  filter: programMedicationsFilter
                }}
              >
                {({
                  loading,
                  data
                }: QueryResult<{
                  programMedications: ProgramMedicationConnection;
                }>) => {
                  return (
                    <LoadingOverlay loading={loading}>
                      <>
                        <ProgramMedicationsTable
                          locationParams={locationParams}
                          setLocationParams={setLocationParams}
                          programMedications={
                            data && data.programMedications
                              ? data.programMedications.nodes
                              : []
                          }
                        />
                        {data && data.programMedications && (
                          <Pagination {...data.programMedications.pageInfo} />
                        )}
                      </>
                    </LoadingOverlay>
                  );
                }}
              </Query>
            </>
          );
        }}
      </LocationParams>
    </Box>
  );
};

const SearchProgramMedicationsQuery = gql`
  query SearchProgramMedicationsQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: ProgramMedicationFilter
    $orderBy: ProgramMedicationOrderBy
  ) {
    programMedications(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        ...ProgramMedications
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ProgramMedicationsTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Search;
