import React from "react";
import format from "date-fns/format";
import { DateTimeWidgetProps } from "react-awesome-query-builder";

import * as Field from "../../../../../components/Field";

export default (props: DateTimeWidgetProps) => {
  const { value, setValue, readonly } = props;

  const onChange = (dtValue: any) => {
    if (dtValue === "") dtValue = undefined;
    return setValue(dtValue);
  };

  return (
    <Field.DatePicker
      value={value && format(value, "DD.MM.YYYY")}
      disabled={readonly}
      onChange={onChange}
      withoutFormField
      hideErrors
      minDate="1900-01-01"
      maxDate={Date.now()}
    />
  );
};
