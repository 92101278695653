import React from "react";
import { gql } from "graphql-tag";
import { Flex, Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Maybe, Range as TRange } from "@ehealth/ehealth-ua.schema";

import Quantity from "./Quantity";

type RangeProps = {
  range?: Maybe<TRange>;
};

const Range = ({ range }: RangeProps) => {
  if (isEmpty(range)) return null;

  const { high, low } = range;
  return (
    <Flex>
      <Box mr={1}>
        <Quantity quantity={low} />
      </Box>
      {"/"}
      <Box ml={1}>
        <Quantity quantity={high} />
      </Box>
    </Flex>
  );
};

export default Range;

Range.fragments = {
  entry: gql`
    fragment Range on Range {
      high {
        ...Quantity
      }
      low {
        ...Quantity
      }
    }
    ${Quantity.fragments.entry}
  `
};
