import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";

import Search from "./Search";
import Details from "./Details";
import ProcessDracs from "./Details/ProcessDracs";

const Parties = ({ uri }: RouteComponentProps) => (
  <Router>
    <Redirect from="/" to={`${uri}/search`} />
    <Search path="search/*" />
    <Details path=":id/*" />
    <ProcessDracs path=":id/verification/process-dracs/*" />
  </Router>
);

export default Parties;
