import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";

import Search from "./Search/";
import Details from "./Details/";

const LegalEntityDeactivateJobs = ({ uri }: RouteComponentProps) => (
  <Router>
    <Redirect from="/" to={`${uri}/search`} />
    <Search path="search/*" />
    <Details path=":id/*" />
  </Router>
);

export default LegalEntityDeactivateJobs;
