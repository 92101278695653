import React from "react";
import Composer from "react-composer";
import { Trans } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";
import { Flex, Box } from "@rebass/emotion";

import { Dictionary } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={3 / 5}>
      <Trans
        id="Dictionary name"
        render={({ translation }) => (
          <Field.Text
            name="filter.name"
            label={<Trans id="Find dictionary" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box width={2 / 5}>
      <Composer
        components={[
          <DictionaryValue name="DICTIONARY_LABELS" />,
          ({
            render
          }: {
            render: (props: TransRenderProps) => React.ReactElement;
          }) => <Trans id="Select option" render={render} />
        ]}
      >
        {([dict, { translation }]: [
          Dictionary["values"],
          { translation: React.ReactNode }
        ]) => (
          <Field.Select
            name="filter.label"
            label={<Trans id="Filter by Tag" />}
            placeholder={translation}
            items={Object.keys(dict)}
            itemToString={(item: string) => dict[item] || translation}
            variant="select"
            emptyOption
            filterOptions={{ keys: [(item: string) => dict[item]] }}
          />
        )}
      </Composer>
    </Box>
  </Flex>
);

export { PrimarySearchFields };
