import React, { useState } from "react";
import { Router, RouteComponentProps, NavigateFn } from "@reach/router";
import { gql } from "graphql-tag";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import styled from "@emotion/styled";
import { Box, Flex, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import {
  Form,
  LocationParams,
  SUBMIT_ERROR,
  Validation
} from "@edenlabllc/ehealth-components";
import { SearchIcon } from "@edenlabllc/ehealth-icons";
import {
  convertStringToBoolean,
  fieldNameDenormalizer,
  convertObjectKeys
} from "@edenlabllc/ehealth-utils";
import { ForbiddenGroup } from "@ehealth/ehealth-ua.schema";

import Button from "../../../../components/Button";
import DefinitionListView from "../../../../components/DefinitionListView";
import { Dictionaries } from "../../../../components/SearchField/ForbiddenGroupSystem";
import * as Field from "../../../../components/Field";
import Line from "../../../../components/Line";
import Notification from "../../../../components/Notification";
import * as SearchField from "../../../../components/SearchField";
import Steps from "../../../../components/Steps";
import Table from "../../../../components/Table";
import { TLocationParams } from "../../../../components/SearchForm";

import pagination from "../../../../helpers/pagination";

import { ForbiddenGroupQuery } from "../../Details";
import { SearchForbiddenGroupsQuery } from "../index";
import CodesModalForm from "./CodesModalForm";
import ServicesModalForm from "./ServicesModalForm";

import env from "../../../../env";

type AddGroupElementProps = RouteComponentProps<{
  id: string;
}>;

export type StateType = {
  codes: CodeType[];
  services: ServiceType[];
  forbiddenGroup: ForbiddenGroup;
};

export type CodeType = {
  codeValue: string;
  name: string;
  dictionaryName: string;
};

export type ServiceType = {
  id: string;
  codeValue: string;
  name: string;
  dictionaryName: string;
};

const AddGroupElement = ({
  // @ts-expect-error location state
  location: { state, pathname },
  id
}: AddGroupElementProps) => (
  <LocationParams>
    {({ locationParams }: TLocationParams) => {
      const { filter = {} } = locationParams || {};

      const {
        forbiddenGroupCodesFilter: {
          isActive: codesIsActive,
          ...restCodesFilter
        } = {},
        forbiddenGroupServicesFilter: {
          isActive: servicesIsActive,
          ...restServiceFilter
        } = {}
      } = filter;

      return (
        <Query
          query={ForbiddenGroupQuery}
          variables={{
            id,
            first: 400,
            forbiddenGroupCodesFilter: {
              isActive: convertStringToBoolean(codesIsActive),
              ...restCodesFilter
            },
            forbiddenGroupServicesFilter: {
              isActive: convertStringToBoolean(servicesIsActive),
              ...restServiceFilter
            },
            ...pagination(locationParams)
          }}
          fetchPolicy="network-only"
        >
          {({ data }: QueryResult<{ forbiddenGroup: ForbiddenGroup }>) => {
            if (isEmpty(data) || isEmpty(data.forbiddenGroup)) return null;

            return (
              <>
                {pathname.includes("confirm") && (
                  <Box m={-2}>
                    <Notification variant="orange">
                      <Trans>
                        Warning! Common access politics for some medical events
                        which contains elements listed below will not work!
                      </Trans>
                    </Notification>
                  </Box>
                )}
                <Box pt={5} px={5}>
                  <Steps.List>
                    <Steps.Item to="./" state={state}>
                      <Trans>Choose codes</Trans>
                    </Steps.Item>
                    <Steps.Item to="./services" state={state}>
                      <Trans>Choose services</Trans>
                    </Steps.Item>
                    <Steps.Item to="./confirm" state={state} disabled>
                      <Trans>Confirm data</Trans>
                    </Steps.Item>
                  </Steps.List>
                  <DefinitionListView
                    labels={{
                      name: <Trans>Forbidden group name</Trans>,
                      creationReason: <Trans>Basis of creation</Trans>
                    }}
                    data={{
                      name: data.forbiddenGroup.name,
                      creationReason: data.forbiddenGroup.creationReason
                    }}
                    labelWidth="200px"
                  />
                  <Line />
                  <Router>
                    <Codes path="/" />
                    <CodesModalForm path=":codeId" />
                    <Services path="/services" />
                    <ServicesModalForm path="/services/:serviceId" />
                    <Confirmation path="/confirm" />
                  </Router>
                </Box>
              </>
            );
          }}
        </Query>
      );
    }}
  </LocationParams>
);

export default AddGroupElement;

const Codes = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <Form
    initialValues={state}
    onSubmit={(data: StateType) =>
      navigate!("./services", {
        state: {
          ...data
        }
      })
    }
  >
    <Box px={1}>
      <Field.Array
        name="codes"
        addText={<Trans>Add code</Trans>}
        removeText={<Trans>Delete</Trans>}
        fields={(props) => (
          <ArrayFieldValues
            {...props}
            navigate={navigate!}
            forbiddenGroupSystemType="code"
          />
        )}
        vertical
      />
    </Box>
    <Flex pt={5}>
      <Box mr={3}>
        <Button
          type="reset"
          variant="blue"
          width={140}
          onClick={() => navigate!("../")}
        >
          <Trans>Back</Trans>
        </Button>
      </Box>
      <Box>
        <Button variant="green" width={140}>
          <Trans>Next</Trans>
        </Button>
      </Box>
    </Flex>
  </Form>
);

const Services = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { i18n } = useLingui();

  return (
    <Form
      initialValues={state}
      onSubmit={(data: StateType) => {
        if (isEmpty(data.services) && isEmpty(data.codes)) {
          const atleastOneItemIsChosen = [
            {
              entry: "atleast.item.chosen",
              rules: [
                {
                  rule: "notChosenAtleastOneCodeOrService"
                }
              ]
            }
          ];
          return { [SUBMIT_ERROR]: atleastOneItemIsChosen };
        }

        navigate!("../confirm", {
          state: {
            ...data
          }
        });
      }}
    >
      <Box px={1}>
        <Field.Array
          name="services"
          addText={<Trans>Add service/service group</Trans>}
          removeText={<Trans>Delete</Trans>}
          fields={(props) => (
            <ArrayFieldValues
              {...props}
              navigate={navigate!}
              forbiddenGroupSystemType="service"
            />
          )}
          vertical
        />
      </Box>
      <Flex pt={5}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate!("../", { state })}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Button variant="green" width={140}>
            <Trans>Next</Trans>
          </Button>
        </Box>
      </Flex>
      <Box>
        <Form.Error
          entry={{
            "atleast.item.chosen": {
              notChosenAtleastOneCodeOrService: (
                <Trans>At least one code or service should be chosen</Trans>
              )
            }
          }}
          default={i18n._(t`Something went wrong. Please try again later`)}
        />
      </Box>
    </Form>
  );
};

type ErrorProps = {
  message: string;
};

const Error = ({ message }: ErrorProps) => (
  <ErrorContentHolder>
    <Text color="red">{message}</Text>
  </ErrorContentHolder>
);

const ErrorContentHolder = styled.div`
  text-align: left;
  font-size: 14px;
  margin: 14px 0;
`;

const defaultError = [
  {
    entry: "default"
  }
];

const submitError = [
  {
    entry: "does.not.match.signer.drfo",
    rules: [
      {
        rule: "doesNotMatchSignerDrfo"
      }
    ]
  }
];

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const [error, setError] = useState(null);
  const { i18n } = useLingui();

  return (
    <>
      {state.codes && !isEmpty(state.codes) && (
        <Box mb={6}>
          <Trans>Chosen codes</Trans>
          <Table
            data={state.codes}
            hideControls
            hidePagination
            header={{
              codeValue: <Trans>Code</Trans>,
              name: <Trans>Name</Trans>,
              dictionaryName: <Trans>Dictionary name</Trans>
            }}
            renderRow={({ codeValue, name, dictionaryName }: CodeType) => ({
              codeValue,
              name,
              dictionaryName: Dictionaries.filter(
                (item) => item.value === dictionaryName
              ).map((item) => item.name)
            })}
          />
          {error &&
            handleSetMessage(error, "codes", state).map((message: string) => (
              <Error message={message} />
            ))}
          <Line />
        </Box>
      )}
      {state.services && !isEmpty(state.services) && (
        <Box>
          <Trans>Chosen services</Trans>
          <Table
            data={state.services}
            hideControls
            hidePagination
            header={{
              codeValue: <Trans>Code</Trans>,
              name: <Trans>Name</Trans>,
              dictionaryName: <Trans>Dictionary name</Trans>
            }}
            renderRow={({ codeValue, name, dictionaryName }: ServiceType) => ({
              codeValue,
              name,
              dictionaryName:
                dictionaryName === "Service" ? (
                  <Trans>Service</Trans>
                ) : (
                  <Trans>Service group</Trans>
                )
            })}
          />
          {error &&
            handleSetMessage(
              error,
              "serviceIds",
              state
            ).map((message: string) => <Error message={message} />)}
          {error &&
            handleSetMessage(
              error,
              "serviceGroupIds",
              state
            ).map((message: string) => <Error message={message} />)}
          <Line />
        </Box>
      )}
      <Box mt={6}>
        <Signer.Parent
          url={env.REACT_APP_SIGNER_URL}
          features={{
            width: 640,
            height: 589
          }}
        >
          {/* @ts-expect-error signData */}
          {({ signData }) => (
            <Mutation
              mutation={CreateForbiddenGroupItemsMutation}
              refetchQueries={() => [
                {
                  query: SearchForbiddenGroupsQuery,
                  variables: { first: 10 }
                }
              ]}
            >
              {(createForbiddenGroupItems: MutationFunction) => (
                <Form
                  onSubmit={async ({
                    creationReason
                  }: {
                    creationReason: string;
                  }) => {
                    const dataToSign = handlePrepareToSignContent(
                      state,
                      creationReason
                    );

                    const { signedContent } = await signData(dataToSign);

                    try {
                      await createForbiddenGroupItems({
                        variables: {
                          input: {
                            signedContent: {
                              content: signedContent,
                              encoding: "BASE64"
                            }
                          }
                        }
                      });

                      navigate!("../../");
                    } catch (err: $TSFixMe) {
                      const { graphQLErrors } = err;
                      const isDefaultError = checkIsDefaultError(
                        graphQLErrors[0]
                      );
                      if (isDefaultError) {
                        return { [SUBMIT_ERROR]: defaultError };
                      }
                      if (!graphQLErrors[0].extensions.exception) {
                        return { [SUBMIT_ERROR]: submitError };
                      }
                      setError(graphQLErrors[0]);
                    }
                  }}
                >
                  <Box>
                    <Trans
                      id="Reason of adding"
                      render={({ translation }) => (
                        <Field.Textarea
                          name="creationReason"
                          label={<Trans id="Specify the basis" />}
                          placeholder={translation}
                          rows={10}
                          maxLength={1000}
                          showLengthHint
                        />
                      )}
                    />
                    <Trans
                      id="Required field"
                      render={() => (
                        <Validation.Required
                          field="creationReason"
                          message="Required field"
                        />
                      )}
                    />
                  </Box>
                  <Flex pt={5} mb={20}>
                    <Box mr={3}>
                      <Button
                        type="reset"
                        variant="blue"
                        width={140}
                        onClick={() => navigate!("../services", { state })}
                      >
                        <Trans>Back</Trans>
                      </Button>
                    </Box>
                    <Box>
                      <Button variant="green" width={250}>
                        <Trans>Approve by EDS</Trans>
                      </Button>
                    </Box>
                  </Flex>
                  <Box>
                    <Form.Error
                      entry={{
                        "does.not.match.signer.drfo": {
                          doesNotMatchSignerDrfo: i18n._(
                            t`Does not match the signer drfo`
                          )
                        }
                      }}
                      default={i18n._(
                        t`Something went wrong. Please try again later`
                      )}
                    />
                  </Box>
                </Form>
              )}
            </Mutation>
          )}
        </Signer.Parent>
      </Box>
    </>
  );
};

const CreateForbiddenGroupItemsMutation = gql`
  mutation CreateForbiddenGroupItemsMutation(
    $input: CreateForbiddenGroupItemsInput!
  ) {
    createForbiddenGroupItems(input: $input) {
      forbiddenGroup {
        id
        databaseId
        name
        creationReason
        insertedAt
        isActive
        forbiddenGroupServices(first: 10) {
          nodes {
            databaseId
            creation_reason
            isActive
            service {
              id
              databaseId
              name
              code
            }
            serviceGroup {
              id
              databaseId
              name
              code
            }
          }
        }
        forbiddenGroupCodes(first: 10) {
          nodes {
            id
            databaseId
            isActive
            insertedAt
            code
            system
          }
        }
      }
    }
  }
`;

type ArrayFieldValuesProps = {
  name: any;
  navigate: NavigateFn;
  forbiddenGroupSystemType: string;
};

const ArrayFieldValues = ({
  name,
  navigate,
  forbiddenGroupSystemType
}: ArrayFieldValuesProps) => {
  const { i18n } = useLingui();
  return (
    <Box>
      <Form.Spy>
        {({ values }: $TSFixMe) => (
          <Box
            onClick={() => navigate(name.match(/\d+/)[0], { state: values })}
          >
            <Flex mx={-1}>
              <>
                <Box width={1 / 4}>
                  <Trans
                    id="Forbidden group element code"
                    render={({ translation }) => (
                      <Field.Select
                        name={`${name}.codeValue`}
                        label={<Trans id="Code" />}
                        placeholder={translation}
                        items={[]}
                        itemToString={(item) => item}
                        emptyOption
                        iconComponent={() => <SearchIcon color="silverCity" />}
                      />
                    )}
                  />
                </Box>
                <Box width={1 / 4} ml={4}>
                  <Field.Text
                    label={<Trans id="Name" />}
                    name={`${name}.name`}
                    placeholder={i18n._(t`Enter name`)}
                  />
                </Box>
                <Box width={1 / 3} ml={4}>
                  <SearchField.ForbiddenGroupSystem
                    name={`${name}.dictionaryName`}
                    systemType={forbiddenGroupSystemType}
                    disabled
                  />
                </Box>
              </>
            </Flex>
          </Box>
        )}
      </Form.Spy>
      <Validation.Required
        field={`${name}.codeValue`}
        message="Required field"
      />
      <Validation.Required field={`${name}.name`} message="Required field" />
      <Validation.Required
        field={`${name}.dictionaryName`}
        message="Required field"
      />
    </Box>
  );
};

const prepareCodesForSign = (codeslist: CodeType[]) =>
  codeslist.map(({ codeValue, dictionaryName }) => ({
    code: codeValue,
    system: dictionaryName
  }));

const prepareServiceForSign = (
  servicesList: ServiceType[],
  serviceType: string
) =>
  servicesList
    .filter((item) => item.dictionaryName === serviceType)
    .map(({ id }) => id);

const handlePrepareToSignContent = (
  state: StateType,
  creationReason: string
) => {
  const { services, codes } = state;

  const toSignContent = {
    id: state.forbiddenGroup.databaseId,
    creationReason: creationReason,
    ...(codes && !isEmpty(codes) && { codes: prepareCodesForSign(codes) }),
    ...(services &&
      !isEmpty(services) &&
      prepareServiceForSign(services, "Service").length && {
        serviceIds: prepareServiceForSign(services, "Service")
      }),
    ...(services &&
      prepareServiceForSign(services, "Service group").length && {
        serviceGroupIds: prepareServiceForSign(services, "Service group")
      })
  };

  return convertObjectKeys(toSignContent, fieldNameDenormalizer);
};

const handleSetMessage = (error: $TSFixMe, type: string, state: StateType) => {
  if (!error) return null;

  return error.extensions.exception.inputErrors
    .filter((error: $TSFixMe) => error.path.includes(type))
    .forEach((error: $TSFixMe) => {
      switch (type) {
        case "serviceIds":
          const serviceCode = state.services.find(
            (service: ServiceType) => service.id === error.options.properties.id
          );
          return `Сервіс з кодом ${
            serviceCode && serviceCode.codeValue
          } вже присутній в іншій забороненій групі з ідентифікатором ${
            error.options.properties.forbiddenGroupId
          };`;
        case "serviceGroupIds":
          const serviceGroupCode = state.services.find(
            (serviceGroup: ServiceType) =>
              serviceGroup.id === error.options.properties.id
          );
          return `Група сервісів з кодом ${
            serviceGroupCode && serviceGroupCode.codeValue
          } вже присутня в іншій забороненій групі з ідентифікатором ${
            error.options.properties.forbiddenGroupId
          };`;
        case "codes":
          return `Код ${error.options.properties.code} зі словника ${error.options.properties.system} вже присутній в іншій забороненій групі з ідентифікатором ${error.options.properties.forbiddenGroupId};`;
        default:
          return "";
      }
    });
};

const checkIsDefaultError = (error: $TSFixMe) =>
  error.extensions.exception
    ? error.extensions.exception.inputErrors.find((inputError: $TSFixMe) => {
        return (
          !inputError.path.includes("codes") &&
          !inputError.path.includes("serviceIds") &&
          !inputError.path.includes("serviceGroupIds")
        );
      })
    : null;
