import React from "react";
import { gql } from "graphql-tag";
import { Router, RouteComponentProps } from "@reach/router";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { Employee, Scalars } from "@ehealth/ehealth-ua.schema";

import Breadcrumbs from "../../../components/Breadcrumbs";
import LoadingOverlay from "../../../components/LoadingOverlay";
import OnRouteChange from "../../../components/OnRouteChange";
import Tabs from "../../../components/Tabs";
import { TLocationParams } from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";
import { LEGAL_ENTITY_VALID_TYPE } from "../../../constants/legalEntity";

import HeaderEmployee from "./HeaderEmployee";
import GeneralInfoEmployee from "./GeneralInfoEmployee";
import Education from "./Education";
import ActiveRoles from "./ActiveRoles";

type DetailsProps = RouteComponentProps<{
  id: Scalars["ID"];
}>;

const Details = ({ id, navigate }: DetailsProps) => (
  <LocationParams>
    {({ locationParams }: TLocationParams) => (
      <Query
        query={EmployeeQuery}
        variables={{
          id,
          filter: {
            status: "ACTIVE"
          },
          ...pagination(locationParams)
        }}
        fetchPolicy="network-only"
      >
        {({ loading, data }: QueryResult<{ employee: Employee }>) => {
          if (isEmpty(data) || isEmpty(data.employee)) return null;
          const {
            databaseId,
            party,
            position,
            employeeType,
            startDate,
            endDate,
            legalEntity,
            division,
            status,
            isActive,
            additionalInfo,
            roles
          } = data.employee;
          const { specialities, educations, qualifications, scienceDegree } =
            additionalInfo || {};
          const { type } = legalEntity || {};
          const isValidActiveRoles = LEGAL_ENTITY_VALID_TYPE.includes(type);

          return (
            <LoadingOverlay loading={loading}>
              <Box p={6}>
                <Box py={10}>
                  <Breadcrumbs.List>
                    <Breadcrumbs.Item to="/employees">
                      <Trans>Employees</Trans>
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item>
                      <Trans>Employee details</Trans>
                    </Breadcrumbs.Item>
                  </Breadcrumbs.List>
                </Box>
                <HeaderEmployee
                  id={id!}
                  databaseId={databaseId}
                  party={party}
                  legalEntityType={type}
                  status={status}
                  employeeType={employeeType}
                  navigate={navigate!}
                />
              </Box>
              <Tabs.Nav>
                <Tabs.NavItem to="./">
                  <Trans>General info</Trans>
                </Tabs.NavItem>
                <Tabs.NavItem to="./education">
                  <Trans>Education and Qualification</Trans>
                </Tabs.NavItem>
                {isValidActiveRoles && (
                  <Tabs.NavItem to="./roles">
                    <Trans>Active roles</Trans>
                  </Tabs.NavItem>
                )}
              </Tabs.Nav>
              <Tabs.Content>
                <Router>
                  <GeneralInfoEmployee
                    path="/"
                    party={party}
                    startDate={startDate}
                    endDate={endDate}
                    position={position}
                    employeeType={employeeType}
                    division={division}
                    legalEntity={legalEntity}
                    isActive={isActive}
                  />
                  <Education
                    path="education"
                    specialities={specialities}
                    educations={educations}
                    qualifications={qualifications}
                    scienceDegree={scienceDegree}
                  />
                  <ActiveRoles
                    path="roles"
                    data={roles}
                    loading={loading}
                    legalEntityId={legalEntity.id}
                  />
                </Router>
                <OnRouteChange />
              </Tabs.Content>
            </LoadingOverlay>
          );
        }}
      </Query>
    )}
  </LocationParams>
);

export const EmployeeQuery = gql`
  query EmployeeQuery(
    $id: ID!
    $orderBy: EmployeeRoleOrderBy
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: EmployeeRoleFilter
  ) {
    employee(id: $id) {
      id
      ...HeaderEmployee
      ...GeneralInfoEmployee
      ...Education
      ...Roles
    }
  }
  ${HeaderEmployee.fragments.entry}
  ${GeneralInfoEmployee.fragments.entry}
  ${Education.fragments!.entry}
  ${ActiveRoles.fragments.entry}
`;

export default Details;
