import React from "react";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Validation } from "@edenlabllc/ehealth-components";

import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

import parseDigits from "../../../helpers/parseDigits";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 4}>
      <Trans
        id="Choose INNM"
        render={({ translation }) => (
          <SearchField.INNM
            name="filter.name"
            placeholder={translation}
            getItemByKey="name"
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <Trans
        id="Choose INNM original name"
        render={({ translation }) => (
          <SearchField.INNM
            name="filter.nameOriginal"
            placeholder={translation}
            label={<Trans id="INNM original name" />}
            getItemByKey="nameOriginal"
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <Trans
        id="Enter INNM ID"
        render={({ translation }) => (
          <Field.Text
            name="filter.databaseId"
            label={<Trans id="Search by INNM ID" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
          />
        )}
      />
      <Validation.Matches
        field="filter.databaseId"
        options={UUID_PATTERN}
        message="Invalid ID"
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <Trans
        id="Enter SCTID"
        render={({ translation }) => (
          <Field.Text
            name="filter.sctid"
            label={<Trans id="Search by SCTID" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
            maxLength={8}
            format={parseDigits}
          />
        )}
      />
    </Box>
  </Flex>
);

const SecondarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 4}>
      <SearchField.Status
        name="filter.isActive"
        status="ACTIVE_STATUS_F"
        label={<Trans id="Status" />}
      />
    </Box>
  </Flex>
);

export { PrimarySearchFields, SecondarySearchFields };
