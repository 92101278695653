import React, { useState, useCallback } from "react";
import { RouteComponentProps } from "@reach/router";
import isEmpty from "lodash/isEmpty";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";

import { Form } from "@edenlabllc/ehealth-components";
import {
  DropDownButton as PlusIcon,
  SearchIcon
} from "@edenlabllc/ehealth-icons";
import { Scalars, Dictionary } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import * as Field from "../../../components/Field";
import Table from "../../../components/Table";

import { useAbility } from "../../../helpers/useAbility";

import UpdateDictionary from "./Mutations/UpdateDictionary";

type DictionaryValuesProps = RouteComponentProps<{
  id: Scalars["ID"];
  isReadOnly: boolean;
  isActive: Dictionary["isActive"];
  values: Dictionary["values"];
}>;

export type DictionaryValueItem = {
  key: string;
  description: string;
};

type StateType = {
  filterByKey: null;
  filterByDescription: null;
  fieldToEdit: null | DictionaryValueItem;
};

const DictionaryValues = ({
  id,
  isReadOnly,
  isActive,
  values
}: DictionaryValuesProps) => {
  const initialState = {
    filterByKey: null,
    filterByDescription: null,
    fieldToEdit: null
  };
  const [state, setState] = useState<StateType>(initialState);
  const toggle = () => setState(initialState);
  const [dictionaryAbility] = useAbility("write", "dictionary");

  const data = Object.entries(values).map(([key, description]) => ({
    key,
    description
  })) as DictionaryValueItem[];
  const filteredData =
    state.filterByKey || state.filterByDescription
      ? [
          data.find(
            (value) =>
              value.key === state.filterByKey ||
              value.description === state.filterByDescription
          )
        ]
      : data;

  const action =
    !isReadOnly && isActive ? { action: <Trans>Action</Trans> } : {};

  const onAddValue = useCallback(
    () =>
      setState({
        ...state,
        fieldToEdit: { key: "", description: "" }
      }),
    [state]
  );

  const onEdit = useCallback(
    (rowContent: DictionaryValueItem) =>
      setState({
        ...state,
        fieldToEdit: rowContent
      }),
    [state]
  );

  return (
    <Box p={5}>
      {!state.fieldToEdit ? (
        <>
          <Flex justifyContent="space-between" color="darkAndStormy">
            <Trans>Search for a value</Trans>
            {!isReadOnly && isActive && (
              <Ability action="write" resource="dictionary">
                <Button
                  variant="link"
                  px={0}
                  icon={PlusIcon}
                  onClick={onAddValue}
                >
                  <Trans>Add value</Trans>
                </Button>
              </Ability>
            )}
          </Flex>
          <Form onSubmit={() => null}>
            <Form.AutoSubmit
              onSubmit={(value: StateType) => {
                !isEmpty(value) &&
                  setState({
                    ...state,
                    ...value
                  });
              }}
            />
            <Flex mx={-1} pt={5}>
              <Box px={1} width={1 / 3}>
                <Trans
                  id="Select key"
                  render={({ translation }) => (
                    <Field.Select
                      name="filterByKey"
                      label={<Trans id="Key" />}
                      placeholder={translation}
                      items={Object.keys(values)}
                      hideErrors
                    />
                  )}
                />
              </Box>
              <Box px={1} width={2 / 3}>
                <Trans
                  id="Enter description"
                  render={({ translation }) => (
                    <Field.Select
                      name="filterByDescription"
                      label={<Trans id="Description" />}
                      placeholder={translation}
                      items={Object.values(values)}
                      iconComponent={SearchIcon}
                      hideErrors
                    />
                  )}
                />
              </Box>
            </Flex>
          </Form>
          <Table
            data={filteredData}
            hidePagination
            hideControls
            header={{
              key: <Trans>Key</Trans>,
              description: <Trans>Description</Trans>,
              ...(dictionaryAbility && {
                ...action
              })
            }}
            renderRow={({ ...rowContent }) => ({
              ...rowContent,
              action: !isReadOnly && isActive && (
                <Button
                  variant="link"
                  px={0}
                  onClick={() => onEdit(rowContent)}
                >
                  <Trans>Edit</Trans>
                </Button>
              )
            })}
            tableName="dictionaries/values"
          />
        </>
      ) : (
        <UpdateDictionary
          id={id!}
          toggle={toggle}
          dictionary={data}
          fieldToEdit={state.fieldToEdit}
        />
      )}
    </Box>
  );
};

export default DictionaryValues;
