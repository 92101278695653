import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { DocumentNode, MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";

import { Maybe } from "@ehealth/ehealth-ua.schema";

import Button from "../../../components/Button";
import Popup from "../../../components/Popup";
import { SearchParams } from "../../../components/SearchForm";

import { ActionType } from "../../../helpers/types";

type UpdateServicePopupProps = {
  id: string;
  name: string;
  requestAllowed?: Maybe<boolean>;
  locationParams: SearchParams;
  serviceDetailsQuery: DocumentNode;
};

const UpdateServicePopup = ({
  id,
  name,
  requestAllowed,
  locationParams,
  serviceDetailsQuery
}: UpdateServicePopupProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  const action: ActionType = requestAllowed
    ? {
        name: <Trans>Disallow Request</Trans>,
        description: <Trans>Disallow Request for service</Trans>,
        variant: "orange"
      }
    : {
        name: <Trans>Allow Request</Trans>,
        description: <Trans>Allow Request for service</Trans>,
        variant: "green"
      };

  return (
    <Box>
      <Mutation
        mutation={UpdateServiceMutation}
        refetchQueries={() => [
          {
            query: serviceDetailsQuery,
            variables: { id, ...locationParams }
          }
        ]}
      >
        {(updateService: MutationFunction) => (
          <>
            <Button onClick={toggle} variant={action.variant}>
              {action.name}
            </Button>
            <Popup
              okButtonProps={{ type: "submit", variant: action.variant }}
              visible={isPopupVisible}
              onCancel={toggle}
              title={
                <>
                  {action.description} "{name}
                  "?
                </>
              }
              okText={action.name}
              onOk={async () => {
                await updateService({
                  variables: {
                    input: {
                      id,
                      requestAllowed: !requestAllowed
                    }
                  }
                });
                toggle();
              }}
            />
          </>
        )}
      </Mutation>
    </Box>
  );
};

const UpdateServiceMutation = gql`
  mutation UpdateServiceMutation($input: UpdateServiceInput!) {
    updateService(input: $input) {
      service {
        id
      }
    }
  }
`;

export default UpdateServicePopup;
