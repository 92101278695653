import * as React from "react";
import * as ReactDOM from "react-dom";
import { Heading, Flex, Box } from "@rebass/emotion";
import { Config } from "react-awesome-query-builder";

import system from "@edenlabllc/ehealth-system-components";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";

import Button from "../../../../Button";

export default function confirm(config: Config) {
  const container = document.getElementById("query-builder-confirmation-modal");
  let currentConfig = { ...config, close, visible: true };

  function destroy() {
    if (container) {
      ReactDOM.unmountComponentAtNode(container);
    }
  }

  function render({
    okText,
    cancelText,
    visible,
    title,
    close,
    onOk
  }: $TSFixMe) {
    ReactDOM.render(
      visible && (
        <Backdrop onClick={close}>
          <Modal>
            <Close onClick={close} />
            <Heading fontSize="20px" fontWeight="normal" mb={4}>
              {title}
            </Heading>
            <Flex justifyContent="center">
              <Box mr="20px">
                <PrimaryButton onClick={close}>{cancelText}</PrimaryButton>
              </Box>
              <ErrorButton
                onClick={() => {
                  onOk();
                  close();
                }}
              >
                {okText}
              </ErrorButton>
            </Flex>
          </Modal>
        </Backdrop>
      ),
      container
    );
  }

  function close(this: $TSFixMe) {
    currentConfig = {
      ...currentConfig,
      visible: false,
      // @ts-ignore
      afterClose: () => {
        destroy.apply(this);
      }
    };
    render(currentConfig);
  }

  render(currentConfig);
}

const Backdrop = system(
  {
    is: "div"
  },
  `
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  `
);

const Modal = system(
  {
    is: "div"
  },
  `
    padding: 55px;
    background-color: #fff;
    box-shadow: 0 0 7px 5px rgb(0 0 0 / 10%);
    position: relative;
  `
);

const Close = system(
  {
    is: RemoveItemIcon
  },
  {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: 12,
    height: 12,
    opacity: 0.2
  }
);

const PrimaryButton = system(
  {
    is: Button
  },
  `
    background-image: linear-gradient(0deg,#1991EB 0%,#2DA1F8 100%);
    border-color: #1585D8;
    color: #fff;
  `
);

const ErrorButton = system(
  {
    is: Button
  },
  `
    background-image: linear-gradient(0deg,#F85359 0%,#DC151D 100%);
    border-color: #DB161E;
    color: #fff;
  `
);
