import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { MedicationRequest } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";
import DefinitionListView from "../../../components/DefinitionListView";
import FullName from "../../../components/FullName";
import Line from "../../../components/Line";
import Link from "../../../components/Link";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type GeneralInfoProps = RouteComponentProps<{
  medicationRequest: MedicationRequest;
}>;

const GeneralInfo = ({ medicationRequest }: GeneralInfoProps) => {
  const { i18n } = useLingui();
  if (isEmpty(medicationRequest)) return null;

  return (
    <Box p={5}>
      <DefinitionListView
        labelWidth="230px"
        labels={{
          requestNumber: <Trans>Request number</Trans>,
          intent: <Trans>Intent</Trans>,
          category: <Trans>Category</Trans>,
          startedAt: (
            <Trans id="Medication request started at">Started at</Trans>
          ),
          endedAt: <Trans id="Medication request ended at">Ended at</Trans>,
          dispenseValidFrom: <Trans>Dispense valid from</Trans>,
          dispenseValidTo: <Trans>Dispense valid to</Trans>,
          isBlocked: <Trans>Blocked</Trans>
        }}
        data={{
          requestNumber: medicationRequest.requestNumber,
          intent: (
            <DictionaryValue
              name="MEDICATION_REQUEST_INTENT"
              item={medicationRequest.intent.toLowerCase()}
            />
          ),
          category: (
            <DictionaryValue
              name="MEDICATION_REQUEST_CATEGORY"
              item={medicationRequest.category.toLowerCase()}
            />
          ),
          startedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            medicationRequest.startedAt
          ),
          endedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            medicationRequest.endedAt
          ),
          dispenseValidFrom: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            medicationRequest.dispenseValidFrom
          ),
          dispenseValidTo: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            medicationRequest.dispenseValidTo
          ),
          isBlocked: medicationRequest.isBlocked ? (
            <Trans>Yes</Trans>
          ) : (
            <Trans>No</Trans>
          )
        }}
      />
      <Line />

      <DefinitionListView
        labelWidth="230px"
        labels={{
          legalEntity: (
            <Trans id="Medication request legal entity">Legal entity</Trans>
          ),
          division: <Trans>Division</Trans>,
          employee: <Trans>Employee</Trans>,
          patient: <Trans>Patient</Trans>
        }}
        data={{
          legalEntity: (
            <Box>
              <Link to={`/legal-entities/${medicationRequest.legalEntity.id}`}>
                {medicationRequest.legalEntity.name
                  ? medicationRequest.legalEntity.name
                  : medicationRequest.legalEntity.databaseId}
              </Link>
            </Box>
          ),
          division: (
            <Box>
              <Link
                to={`/legal-entities/${medicationRequest.legalEntity.id}/divisions/${medicationRequest.division.id}/`}
              >
                {medicationRequest.division.name
                  ? medicationRequest.division.name
                  : medicationRequest.division.databaseId}
              </Link>
            </Box>
          ),
          employee: (
            <Box>
              <Link to={`/employees/${medicationRequest.employee.id}`}>
                {!isEmpty(medicationRequest.employee.party) ? (
                  <FullName party={medicationRequest.employee.party} />
                ) : (
                  medicationRequest.employee.databaseId
                )}
              </Link>
            </Box>
          ),
          // @ts-expect-error types mismatch
          patient: medicationRequest.patientId
        }}
      />
      <Line />

      <DefinitionListView
        labelWidth="230px"
        labels={{
          medication: <Trans>Medication</Trans>,
          medicationQty: <Trans>INNM quantity</Trans>,
          ...(medicationRequest.medicalProgram && {
            medicalProgram: <Trans>Medical program</Trans>
          }),
          medicationRequestRequestsId: (
            <Trans>Medication request request ID</Trans>
          )
        }}
        data={{
          medication: (
            <Box>
              <Link to={`/innm-dosages/${medicationRequest.medication.id}/`}>
                {medicationRequest.medication.name
                  ? medicationRequest.medication.name
                  : medicationRequest.medication.databaseId}
              </Link>
            </Box>
          ),
          medicationQty: medicationRequest.medicationQty,
          ...(medicationRequest.medicalProgram && {
            medicalProgram: (
              <Box>
                <Link
                  to={`/medical-programs/${medicationRequest.medicalProgram.id}/`}
                >
                  {medicationRequest.medicalProgram.name
                    ? medicationRequest.medicalProgram.name
                    : medicationRequest.medicalProgram.databaseId}
                </Link>
              </Box>
            )
          }),
          medicationRequestRequestsId:
            medicationRequest.medicationRequestRequestsId
        }}
      />
      <Line />

      {medicationRequest.rejectedAt && (
        <>
          <DefinitionListView
            labelWidth="230px"
            labels={{
              ...(medicationRequest.rejectedAt && {
                rejectedAt: <Trans>Rejected at</Trans>
              }),
              ...(medicationRequest.rejectedBy && {
                rejectedBy: <Trans>Rejected by</Trans>
              }),
              ...(medicationRequest.rejectReason && {
                rejectReason: (
                  <Trans id="Medication request reject reason">
                    Reject reason
                  </Trans>
                )
              })
            }}
            data={{
              ...(medicationRequest.rejectedAt && {
                rejectedAt: dateFormatter(
                  i18n.locale,
                  DATE_TIME_FORMAT,
                  medicationRequest.rejectedAt
                )
              }),
              rejectedBy: medicationRequest.rejectedBy,
              rejectReason: medicationRequest.rejectReason
            }}
          />
          <Line />
        </>
      )}

      <DefinitionListView
        labelWidth="230px"
        labels={{
          medicationDispenses: <Trans>Medication dispense</Trans>
        }}
        data={{
          medicationDispenses: (
            <Box>
              <Link
                to={`/medication-dispenses/search?filter.medicationRequestId=${medicationRequest.databaseId}`}
              >
                <Trans>View</Trans>
              </Link>
            </Box>
          )
        }}
      />
    </Box>
  );
};

export default GeneralInfo;
