import React, { useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { GlobalBudgetContract } from "@ehealth/ehealth-ua.schema";

import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DefinitionListView from "../../../components/DefinitionListView";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";

import { ITEMS_PER_PAGE } from "../../../constants/pagination";

import GeneralInfoGlobalBudget from "./GeneralInfoGlobalBudget";
import LegalEntityGlobalBudget from "./LegalEntityGlobalBudget";
import Divisions from "./Divisions";
import Employees from "./Employees";
import DocumentsGlobalBudget from "./DocumentsGlobalBudget";
import MedicalPrograms from "./MedicalPrograms";
import { ToggleSuspendContractModal } from "../Capitation/Details";

const GlobalBudgetContractsDetails = (_props: RouteComponentProps) => (
  <Router>
    <Details path=":id/*" />
  </Router>
);

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

type DetailsStateType = {
  suspendPopup: boolean;
  terminatePopup: boolean;
};

const Details = ({ id }: DetailsProps) => {
  const [isVisible, setVisibilityState] = useState<DetailsStateType>({
    suspendPopup: false,
    terminatePopup: false
  });
  const toggle = (popup: "suspendPopup" | "terminatePopup") =>
    setVisibilityState({
      ...isVisible,
      [popup]: !isVisible[popup]
    });

  return (
    <Query
      query={GlobalBudgetContractQuery}
      variables={{ id, first: ITEMS_PER_PAGE[0] }}
    >
      {({
        loading,
        data
      }: QueryResult<{ globalBudgetContract: GlobalBudgetContract }>) => {
        if (isEmpty(data) || isEmpty(data.globalBudgetContract)) return null;

        const {
          isSuspended,
          databaseId,
          contractNumber,
          status,
          startDate,
          endDate,
          contractorEmployeeDivisions,
          // @ts-expect-error types mismatch
          nhsSignerId,
          nhsSigner,
          nhsSignerBase,
          nhsContractPrice,
          nhsPaymentMethod,
          issueCity,
          contractorLegalEntity,
          contractorOwner,
          contractorBase,
          contractorPaymentDetails,
          attachedDocuments,
          statusReason,
          reason
        } = data.globalBudgetContract;

        return (
          <LoadingOverlay loading={loading}>
            <Box p={6}>
              <Box py={10}>
                <Breadcrumbs.List>
                  <Breadcrumbs.Item to="/contracts/global-budget">
                    <Trans>List of contracts</Trans>
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item>
                    <Trans>Details of the contract</Trans>
                  </Breadcrumbs.Item>
                </Breadcrumbs.List>
              </Box>
              <Flex justifyContent="space-between">
                <Box>
                  <DefinitionListView
                    labels={{
                      databaseId: <Trans>Contract ID</Trans>,
                      contractNumber: <Trans>Contract Number</Trans>,
                      status: <Trans>Status</Trans>,
                      isSuspended: <Trans>Contract state</Trans>
                    }}
                    data={{
                      databaseId,
                      contractNumber,
                      status: (
                        <Badge name={status} type="CONTRACT" minWidth={100} />
                      ),
                      isSuspended: (
                        <Flex alignItems="center">
                          <Badge
                            name={isSuspended}
                            type="SUSPENDED"
                            minWidth={100}
                          />
                          {status === "VERIFIED" && (
                            <ToggleSuspendContractModal
                              toggleSuspendContractMutation={
                                ToggleGlobalBudgetContractMutation
                              }
                              isSuspended={isSuspended}
                              isVisible={isVisible}
                              toggle={toggle}
                              formId="suspendGlobalBudget"
                              contractId={id}
                            />
                          )}
                        </Flex>
                      )
                    }}
                    color="#7F8FA4"
                    labelWidth="120px"
                  />
                </Box>
                <Flex
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="flex-end"
                />
              </Flex>
            </Box>

            <Tabs.Nav>
              <Tabs.NavItem to="./">
                <Trans>General information</Trans>
              </Tabs.NavItem>
              <Tabs.NavItem to="./legal-entity">
                <Trans>Legal entity</Trans>
              </Tabs.NavItem>
              <Tabs.NavItem to="./divisions">
                <Trans>Division</Trans>
              </Tabs.NavItem>
              {contractorEmployeeDivisions && (
                <Tabs.NavItem to="./employees">
                  <Trans>Doctors</Trans>
                </Tabs.NavItem>
              )}
              <Tabs.NavItem to="./medical-programs">
                <Trans>Medical programs</Trans>
              </Tabs.NavItem>
              <Tabs.NavItem to="./documents">
                <Trans>Documents</Trans>
              </Tabs.NavItem>
            </Tabs.Nav>
            <Tabs.Content>
              <Router>
                <GeneralInfoGlobalBudget
                  path="/"
                  startDate={startDate}
                  endDate={endDate}
                  nhsSignerId={nhsSignerId}
                  nhsSignerName={nhsSigner && nhsSigner.party}
                  nhsSignerBase={nhsSignerBase}
                  nhsContractPrice={nhsContractPrice}
                  nhsPaymentMethod={nhsPaymentMethod}
                  issueCity={issueCity}
                  statusReason={statusReason}
                  reason={reason}
                  isSuspended={isSuspended}
                />
                <LegalEntityGlobalBudget
                  path="/legal-entity"
                  contractorOwner={contractorOwner}
                  contractorBase={contractorBase}
                  contractorLegalEntity={contractorLegalEntity}
                  contractorPaymentDetails={contractorPaymentDetails}
                />
                <Divisions path="/divisions" />
                <Employees path="/employees" />
                <MedicalPrograms path="/medical-programs" />
                <DocumentsGlobalBudget
                  path="/documents"
                  attachedDocuments={attachedDocuments}
                />
              </Router>
            </Tabs.Content>
          </LoadingOverlay>
        );
      }}
    </Query>
  );
};

export const GlobalBudgetContractQuery = gql`
  query GlobalBudgetContractQuery(
    $id: ID!
    $before: String
    $after: String
    $first: Int
    $last: Int
    $orderBy: ContractorEmployeeDivisionOrderBy
    $divisionFilter: DivisionFilter
    $employeeFilter: ContractorEmployeeDivisionFilter
    $medicalProgramFilter: MedicalProgramFilter
  ) {
    globalBudgetContract(id: $id) {
      id
      databaseId
      contractNumber
      isSuspended
      contractorDivisions(
        filter: $divisionFilter
        first: $first
        before: $before
        after: $after
        last: $last
      ) {
        nodes {
          id
          databaseId
          name
          addresses {
            type
            area
            region
            settlement
            settlementType
            settlementId
            streetType
            street
            building
            apartment
          }
          phones {
            type
            number
          }
          email
          mountainGroup
          workingHours
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
      contractorEmployeeDivisions(
        filter: $employeeFilter
        first: $first
        before: $before
        after: $after
        last: $last
        orderBy: $orderBy
      ) {
        nodes {
          employee {
            id
            databaseId
            party {
              id
              firstName
              lastName
              secondName
            }
            additionalInfo {
              specialities {
                speciality
                specialityOfficio
              }
            }
          }
          staffUnits
          declarationLimit
          division {
            id
            name
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
      ...GeneralInfoGlobalBudget
      ...LegalEntityGlobalBudget
      ...DocumentsGlobalBudget
      ...MedicalProgram
    }
  }
  ${GeneralInfoGlobalBudget.fragments.entry}
  ${LegalEntityGlobalBudget.fragments.entry}
  ${DocumentsGlobalBudget.fragments.entry}
  ${MedicalPrograms.fragments.entry}
`;

export const ToggleGlobalBudgetContractMutation = gql`
  mutation ToggleGlobalBudgetContractMutation(
    $input: ToggleGlobalBudgetContractInput!
  ) {
    toggleGlobalBudgetContract(input: $input) {
      contract {
        id
        isSuspended
        reason
      }
    }
  }
`;

export default GlobalBudgetContractsDetails;
