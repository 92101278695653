import React from "react";
import { gql } from "graphql-tag";
import isEmpty from "lodash/isEmpty";
import { Text, Box } from "@rebass/emotion";

import {
  CodeableConcept as TCodeableConcept,
  Coding as TCoding,
  Maybe
} from "@ehealth/ehealth-ua.schema";

import Coding from "./Coding";
import { FunctionComponentWithFragments } from "../helpers/types";

type CodeableConceptProps = {
  codeableConcept: Maybe<TCodeableConcept> | undefined;
  withCode?: boolean;
};

const CodeableConcept: FunctionComponentWithFragments<CodeableConceptProps> = ({
  codeableConcept,
  withCode
}) => {
  if (isEmpty(codeableConcept)) return null;
  const { coding, text } = codeableConcept;
  if (isEmpty(coding) && text) {
    return <Text>{text}</Text>;
  } else if (!isEmpty(coding)) {
    return (
      <>
        {coding.map((codingItem: Maybe<TCoding>, index: number) => (
          <Box mt={index === 0 ? 0 : 2} key={index}>
            <Coding coding={codingItem} withCode={withCode} />
          </Box>
        ))}
      </>
    );
  }
  return null;
};

export default CodeableConcept;

CodeableConcept.fragments = {
  entry: gql`
    fragment CodeableConcept on CodeableConcept {
      text
      coding {
        ...Coding
      }
    }
    ${Coding.fragments.entry}
  `
};
