//TODO: might not be required
const dateFormatter = (
  locale: string,
  formatOptions: Intl.DateTimeFormatOptions,
  date: string
) => {
  try {
    return date
      ? new Intl.DateTimeFormat(locale, formatOptions).format(new Date(date))
      : null;
  } catch (e) {
    return date;
  }
};

export default dateFormatter;

/**
 * Convert date from DD.MM.YYYY to YYYY-MM-DD
 * @param  {String} date Dotted date string (27.12.2022)
 */
export const toDashedDateFormat = (date: string) => {
  const dottedRegex = /\d{2}(\.|-)\d{2}(\.|-)\d{4}/;
  const isDottedFormat = dottedRegex.test(date);

  if (typeof date === "string" && isDottedFormat) {
    return date.split(".").reverse().join("-");
  }

  return date;
};
