import React, { FC } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { uk } from "make-plural/plurals";

import { ThemeProvider, ModalProvider } from "@edenlabllc/ehealth-components";

import GlobalStyles from "./components/GlobalStyles";
import * as theme from "./theme";
import ErrorBoundary from "./ErrorBoundary";
import DataProvider from "./DataProvider";
import Preload from "./Preload";

import Layout from "./components/Layout";
import Home from "./pages/Home";
import ContractRequests from "./pages/ContractRequests";
import Contracts from "./pages/Contracts";
import Configuration from "./pages/Configuration";
import LegalEntityReorganizationJobs from "./pages/LegalEntityReorganizationJobs";
import LegalEntityDeactivateJobs from "./pages/LegalEntityDeactivateJobs";
import Persons from "./pages/Persons";
import PersonsVerification from "./pages/PersonsVerifications/Search";
import PatientMergeRequests from "./pages/PatientMergeRequests";
import CandidatesPatientMergeRequests from "./pages/CandidatesPatientMergeReuests";
import Declarations from "./pages/Declarations";
import PendingDeclarations from "./pages/PendingDeclarations";
import LegalEntities from "./pages/LegalEntities";
import LegalEntitiesReorganizations from "./pages/LegalEntitiesReorganizations";
import MedicalPrograms from "./pages/MedicalPrograms";
import MedicalComposition from "./pages/MedicalComposition";
import ProgramMedications from "./pages/ProgramMedications";
import Dictionaries from "./pages/Dictionaries";
import INNMs from "./pages/INNMs";
import INNMDosages from "./pages/INNMDosages";
import Medications from "./pages/Medications";
import Employees from "./pages/Employees";
import EmployeeRequests from "./pages/EmployeeRequests";
import Services from "./pages/Services";
import ServiceGroups from "./pages/ServiceGroups";
import ProgramServices from "./pages/ProgramServices";
import ResetPersonsAuthMethod from "./pages/ResetPersonsAuthMethod";
import ResetPersonsAuthMethodJobs from "./pages/ResetPersonsAuthMethodJobs";
import Registers from "./pages/Registers";
import PersonsDeactivationJobs from "./pages/PersonsDeactivationJobs";
import DeclarationsTerminationJobs from "./pages/DeclarationsTerminationJobs";
import ForbiddenGroups from "./pages/ForbiddenGroups";
import ClinicalMonitoring from "./pages/ClinicalMonitoring";
import RuleEngineRule from "./pages/RuleEngineRule";
import MedicationRegistryJobs from "./pages/MedicationRegistryJobs";
import DiagnosesGroups from "./pages/DiagnosesGroups";
import MedicationRequests from "./pages/MedicationRequests";
import MedicationDispenses from "./pages/MedicationDispenses";
import UnverifiedParties from "./pages/UnverifiedParties";

import localeUK from "./locales/uk/messages.js";
import flags, { FlagsProvider } from "./flags";

i18n.loadLocaleData("uk", { plurals: uk });
i18n.load("uk", localeUK.messages);
i18n.activate("uk");

const NotFound: FC<RouteComponentProps> = () => <p>Sorry, nothing here</p>;

const {
  medicationRegistryJobs,
  diagnosesGroups,
  medicationRequests,
  medicationDispenses
} = flags.features;

const App = () => (
  // @ts-expect-error TS(2322): Type '{ children: Element; flags: { features: any;... Remove this comment to see the full error message
  <FlagsProvider flags={flags}>
    <I18nProvider i18n={i18n}>
      <GlobalStyles />

      <ThemeProvider theme={theme}>
        <ModalProvider>
          <ErrorBoundary>
            <DataProvider>
              <Preload>
                <Layout>
                  <Router>
                    <Home path="/" />
                    <ContractRequests path="contract-requests/*" />
                    <Contracts path="contracts/*" />
                    <LegalEntityReorganizationJobs path="legal-entity-reorganization-jobs/*" />
                    <LegalEntityDeactivateJobs path="legal-entity-deactivate-jobs/*" />
                    <LegalEntities path="legal-entities/*" />
                    <LegalEntitiesReorganizations path="legal-entities-reorganizations/*" />
                    <MedicalPrograms path="medical-programs/*" />
                    <MedicalComposition path="medical-composition/*" />
                    <ProgramMedications path="program-medications/*" />
                    <Dictionaries path="dictionaries/*" />
                    <ClinicalMonitoring path="clinical-monitoring/*" />
                    <Persons path="persons/*" />
                    <PersonsVerification path="persons-verifications/*" />
                    <ResetPersonsAuthMethodJobs path="reset-persons-auth-method-jobs/*" />
                    <ResetPersonsAuthMethod path="reset-authentication-method/*" />
                    <Registers path="registers/*" />
                    <PersonsDeactivationJobs path="persons-deactivation-jobs/*" />
                    <DeclarationsTerminationJobs path="declarations-termination-jobs/*" />
                    <PatientMergeRequests path="applications-patient-merge-requests/*" />
                    <CandidatesPatientMergeRequests path="candidates-patient-merge-requests/*" />
                    <Declarations path="declarations/*" />
                    <PendingDeclarations path="pending-declarations/*" />
                    <INNMs path="innms/*" />
                    <INNMDosages path="innm-dosages/*" />
                    <Medications path="medications/*" />
                    <Employees path="employees/*" />
                    <EmployeeRequests path="employee-requests/*" />
                    <Services path="services/*" />
                    <ServiceGroups path="service-groups/*" />
                    <ProgramServices path="program-services/*" />
                    <Configuration path="configuration/*" />
                    <ForbiddenGroups path="forbiddenGroups/*" />
                    <RuleEngineRule path="rule-engine/*" />
                    {medicationRegistryJobs && (
                      <MedicationRegistryJobs path="medication-registry-jobs/*" />
                    )}
                    {diagnosesGroups && (
                      <DiagnosesGroups path="diagnoses-groups/*" />
                    )}
                    {medicationRequests && (
                      <MedicationRequests path="medication-requests/*" />
                    )}
                    {medicationDispenses && (
                      <MedicationDispenses path="medication-dispenses/*" />
                    )}
                    <UnverifiedParties path="unverified-parties/*" />
                    <UnverifiedParties path="parties/*" />
                    <NotFound default />
                  </Router>
                </Layout>
              </Preload>
            </DataProvider>
          </ErrorBoundary>
        </ModalProvider>
      </ThemeProvider>
    </I18nProvider>
  </FlagsProvider>
);

export default App;
