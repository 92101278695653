//https://github.com/timkuijsten/node-object-key-filter#readme

function filterObjectKeys(obj, keys, recurse) {
  if (typeof obj !== "object") {
    return;
  }
  if (!Array.isArray(keys)) {
    return;
  }
  if (recurse == null) {
    recurse = false;
  }
  if (typeof recurse !== "boolean") {
    return;
  }
  var result;
  if (Array.isArray(obj)) {
    result = [];
  } else {
    result = {};
  }
  Object.keys(obj).forEach(function (key) {
    if (~keys.indexOf(key)) {
      return;
    }
    if (recurse && obj[key] != null && typeof obj[key] === "object" && Object.keys(obj[key]).length) {
      result[key] = filterObjectKeys(obj[key], keys, recurse);
    } else {
      result[key] = obj[key];
    }
  });
  return result;
}
export default filterObjectKeys;