import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import {
  getFullName,
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  ReimbursementContractRequest,
  ReimbursementContractRequestConnection
} from "@ehealth/ehealth-ua.schema";

import Badge from "../../../../components/Badge";
import FullName from "../../../../components/FullName";
import Link from "../../../../components/Link";
import {
  SearchParams,
  SetSearchParams
} from "../../../../components/SearchForm";
import Table, { SortingParams } from "../../../../components/Table";

import dateFormatter from "../../../../helpers/dateFormatter";

type ContractTableProps = {
  reimbursementContractRequests: ReimbursementContractRequestConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const ContractTable = ({
  reimbursementContractRequests,
  locationParams,
  setLocationParams
}: ContractTableProps) => (
  <Table
    data={reimbursementContractRequests}
    header={{
      databaseId: <Trans>Contract request databaseID</Trans>,
      edrpou: <Trans>EDRPOU</Trans>,
      contractorLegalEntityName: <Trans>Name of medical institution</Trans>,
      contractNumber: <Trans>Contract Number</Trans>,
      startDate: <Trans>The contract is valid with</Trans>,
      endDate: <Trans>The contract is valid for</Trans>,
      assigneeName: <Trans>Performer</Trans>,
      insertedAt: <Trans>Added</Trans>,
      status: <Trans>Status</Trans>,
      details: <Trans>Details</Trans>
    }}
    renderRow={({
      id,
      status,
      startDate,
      endDate,
      contractorLegalEntity: { edrpou, edrData },
      assignee,
      insertedAt,
      ...reimbursementContractRequests
    }: ReimbursementContractRequest) => ({
      ...reimbursementContractRequests,
      edrpou,
      contractorLegalEntityName: edrData && edrData.name,
      startDate: i18n.date(startDate),
      endDate: i18n.date(endDate),
      insertedAt:
        insertedAt &&
        dateFormatter(
          i18n.locale,
          {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
          },
          insertedAt
        ),
      assigneeName: assignee ? getFullName(assignee.party) : undefined,
      status: <Badge type="CONTRACT_REQUEST" name={status} display="block" />,
      details: (
        <Link to={`./${id}`} fontWeight="bold">
          <Trans>Show details</Trans>
        </Link>
      )
    })}
    sortableFields={["status", "startDate", "endDate", "insertedAt"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    tableName="reimbursementContractRequests/search"
    whiteSpaceNoWrap={["databaseId"]}
    hiddenFields="contractorLegalEntityName,insertedAt"
  />
);

ContractTable.fragments = {
  entry: gql`
    fragment ReimbursementContractRequests on ReimbursementContractRequest {
      id
      databaseId
      contractNumber
      startDate
      endDate
      status
      databaseId
      assignee {
        id
        party {
          id
          ...FullName
        }
      }
      insertedAt
      contractorLegalEntity {
        id
        name
        databaseId
        edrpou
        edrData {
          name
        }
      }
      contractorOwner {
        id
      }
    }
    ${FullName.fragments.entry}
  `
};

export default ContractTable;
