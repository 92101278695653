import React, { useState } from "react";
import { Box, Text, Flex } from "@rebass/emotion";

import { CaretDownIcon, CaretUpIcon } from "@edenlabllc/ehealth-icons";

type CollapsableProps = {
  title: React.ReactElement;
  holderProps?: {
    mt?: number;
    mb?: number;
    style?: React.CSSProperties;
  };
  titleProps?: any;
  children: React.ReactNode;
  isCollapsed?: boolean;
};

const Collapsable = ({
  holderProps = { mt: 4 },
  title,
  titleProps = { fontSize: 1, fontWeight: "bold", mr: 2 },
  children,
  isCollapsed = true
}: CollapsableProps) => {
  const [collapsed, collapse] = useState(isCollapsed);

  return (
    <Box {...holderProps}>
      <>
        <Flex alignItems="center" onClick={() => collapse(!collapsed)}>
          <Text {...titleProps}>{title}</Text>
          {collapsed ? <CaretUpIcon /> : <CaretDownIcon />}
        </Flex>
        {!collapsed && children}
      </>
    </Box>
  );
};

export default Collapsable;
