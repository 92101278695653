import React from "react";
import { Trans } from "@lingui/macro";

import { Maybe } from "@ehealth/ehealth-ua.schema";

type PriceProps = {
  amount?: Maybe<number>;
};

const Price = ({ amount }: PriceProps) =>
  amount || amount === 0 ? (
    <>
      {amount} <Trans>uah</Trans>
    </>
  ) : (
    <>-</>
  );

export default Price;
