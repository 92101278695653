import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { Mutation, Query } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { loader } from "graphql.macro";
import Composer from "react-composer";
import { useLingui, TransRenderProps } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Heading, Flex, Box, Text } from "@rebass/emotion";
import { subYears, format } from "date-fns";
import isEmpty from "lodash/isEmpty";

import {
  Field as ControlledField,
  Form,
  Validation,
  Validations
} from "@edenlabllc/ehealth-components";
import { PositiveIcon, RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import {
  parsePhone,
  formatPhone,
  getFullName,
  cleanDeep
} from "@edenlabllc/ehealth-utils";
import { Dictionary, Employee, Party, Phone } from "@ehealth/ehealth-ua.schema";

import Button from "../../components/Button";
import DefinitionListView from "../../components/DefinitionListView";
import DictionaryValue from "../../components/DictionaryValue";
import DocumentView from "../../components/DocumentView";
import * as Field from "../../components/Field";
import Line from "../../components/Line";
import Steps from "../../components/Steps";

import {
  TAX_ID_PATTERN,
  NO_TAX_ID_DOCUMENT_PATTERN,
  UUID_PATTERN,
  CYRILLIC_NAME
} from "../../constants/validationPatterns";

import env from "../../env";

import { EmployeeQuery } from "./Details/";
import { ITEMS_PER_PAGE } from "../../constants/pagination";

const CreateEmployeeRequestMutation = loader(
  "../../graphql/CreateEmployeeRequestMutation.graphql"
);

type UpdateProps = RouteComponentProps<{
  id: string;
}>;

const Update = ({
  id,
  // @ts-expect-error location state
  location: { state }
}: UpdateProps) => (
  <Query query={EmployeeQuery} variables={{ id, first: ITEMS_PER_PAGE[0] }}>
    {({ data }: QueryResult<{ employee: Employee }>) => {
      if (isEmpty(data) || isEmpty(data.employee)) return null;

      return (
        <>
          <Box pt={5} px={5}>
            <Steps.List>
              <Steps.Item to="./" state={state}>
                <Trans>Fill in the form</Trans>
              </Steps.Item>
              <Steps.Item to="./confirm" state={state} disabled>
                <Trans>Confirm</Trans>
              </Steps.Item>
            </Steps.List>
          </Box>
          <Router>
            <CreationForm path="/" employee={data.employee} />
            <Confirmation path="/confirm" />
          </Router>
        </>
      );
    }}
  </Query>
);

type CreationFormProps = RouteComponentProps & {
  employee: Employee;
};

const CreationForm = ({
  navigate,
  employee,
  // @ts-expect-error location state
  location: { state }
}: CreationFormProps) => {
  const { toUpdateEmployeeData } = state || {};
  const {
    id,
    databaseId,
    party: {
      firstName,
      lastName,
      secondName,
      taxId,
      noTaxId,
      gender,
      // @ts-expect-error types mismatch
      email,
      phones = [],
      birthDate,
      documents = []
    },
    position,
    employeeType,
    startDate,
    division
  } = employee;

  const taxDocument = noTaxId
    ? {
        passport: taxId
      }
    : {
        taxNumber: taxId
      };

  const formattedPhones = phones.map((phone) => ({
    type: phone && phone.type,
    number: phone && phone.number
  }));
  const formattedDocuments = documents.map((document) => ({
    type: document && document.type,
    number: document && document.number,
    issued_by: document && document.issuedBy,
    issued_at: document && document.issuedAt
  }));

  return (
    <Box p={5}>
      <Heading as="h1" fontWeight="normal" mb={5}>
        <Trans>Updating employee</Trans>
      </Heading>
      <Form
        onSubmit={(data: Partial<Employee>) => {
          navigate!("./confirm", {
            state: {
              toUpdateEmployeeData: data
            }
          });
        }}
        initialValues={
          toUpdateEmployeeData || {
            position,
            employee_type: employeeType,
            start_date: startDate,
            division_id: division && division.databaseId,
            employee_id: databaseId,
            party: {
              first_name: firstName,
              last_name: lastName,
              second_name: secondName,
              birth_date: birthDate,
              gender,
              email,
              phones: formattedPhones,
              no_tax_id: noTaxId,
              tax_id: taxDocument,
              documents: formattedDocuments
            }
          }
        }
      >
        <Flex mx={-1}>
          <Box px={1} width={1 / 3}>
            <Trans
              id="Enter first name"
              render={({ translation }) => (
                <Field.Text
                  name="party.first_name"
                  label={<Trans id="First name" />}
                  placeholder={translation}
                />
              )}
            />
            <Validations field="party.first_name">
              <Validation.Required message="Required field" />
              <Validation.Matches
                options={CYRILLIC_NAME}
                message="Invalid first name"
              />
            </Validations>
          </Box>
          <Box px={1} width={1 / 3}>
            <Trans
              id="Enter second name"
              render={({ translation }) => (
                <Field.Text
                  name="party.second_name"
                  label={<Trans id="Second name" />}
                  placeholder={translation}
                />
              )}
            />
          </Box>
          <Box px={1} width={1 / 3}>
            <Trans
              id="Enter last name"
              render={({ translation }) => (
                <Field.Text
                  name="party.last_name"
                  label={<Trans id="Last name" />}
                  placeholder={translation}
                />
              )}
            />
            <Validations field="party.last_name">
              <Validation.Required message="Required field" />
              <Validation.Matches
                options={CYRILLIC_NAME}
                message="Invalid last name"
              />
            </Validations>
          </Box>
        </Flex>
        <Flex mx={-1}>
          <Box px={1} width={1 / 3}>
            <Composer
              components={[
                <DictionaryValue name="GENDER" />,
                ({
                  render
                }: {
                  render: (props: TransRenderProps) => React.ReactElement;
                }) => <Trans id="Select option" render={render} />
              ]}
            >
              {([dict, { translation }]: [
                Dictionary["values"],
                { translation: React.ReactNode }
              ]) => (
                <Field.Select
                  name="party.gender"
                  label={<Trans id="Gender" />}
                  placeholder={translation}
                  items={Object.keys(dict)}
                  itemToString={(item: string) => dict[item] || translation}
                  variant="select"
                  emptyOption
                  filterOptions={{ keys: [(item: string) => dict[item]] }}
                />
              )}
            </Composer>
            <Validation.Required
              field="party.gender"
              message="Required field"
            />
          </Box>
          <Box px={1} width={1 / 3}>
            <Field.DatePicker
              name="party.birth_date"
              label={<Trans id="Date of birth" />}
              minDate={format(subYears(new Date(), 150), "YYYY-MM-DD")}
            />
            <Validations field="party.birth_date">
              <Validation.Required message="Required field" />
              <Validation.BirthDate message="Invalid birth date" />
            </Validations>
          </Box>
        </Flex>
        <Line />
        <Text fontSize={2} mb={6}>
          <Trans>Job information</Trans>
        </Text>
        <Flex mx={-1}>
          <Box px={1} width={1 / 4}>
            <Composer
              components={[
                <DictionaryValue name="POSITION" />,
                ({
                  render
                }: {
                  render: (props: TransRenderProps) => React.ReactElement;
                }) => <Trans id="Select option" render={render} />
              ]}
            >
              {([dict, { translation }]: [
                Dictionary["values"],
                { translation: React.ReactNode }
              ]) => (
                <Field.Select
                  name="position"
                  label={<Trans id="Position" />}
                  placeholder={translation}
                  items={Object.keys(dict)}
                  itemToString={(item: string) => dict[item] || translation}
                  variant="select"
                  emptyOption
                  filterOptions={{ keys: [(item: string) => dict[item]] }}
                />
              )}
            </Composer>
            <Validation.Required field="position" message="Required field" />
          </Box>
          <Box px={1} width={1 / 4}>
            <Composer
              components={[
                <DictionaryValue name="EMPLOYEE_TYPE" />,
                ({
                  render
                }: {
                  render: (props: TransRenderProps) => React.ReactElement;
                }) => <Trans id="Select option" render={render} />
              ]}
            >
              {([dict, { translation }]: [
                Dictionary["values"],
                { translation: React.ReactNode }
              ]) => (
                <Field.Select
                  name="employee_type"
                  label={<Trans id="Employee type" />}
                  placeholder={translation}
                  items={Object.keys(dict).filter((item) => /^NHS/.test(item))}
                  itemToString={(item: string) => dict[item] || translation}
                  variant="select"
                  emptyOption
                  filterOptions={{ keys: [(item: string) => dict[item]] }}
                />
              )}
            </Composer>
            <Validation.Required
              field="employee_type"
              message="Required field"
            />
          </Box>
          <Box px={1} width={1 / 4}>
            <Field.DatePicker
              name="start_date"
              label={<Trans id="Start date" />}
              minDate="1900-01-01"
            />
            <Validation.Required field="start_date" message="Required field" />
          </Box>
          <Box px={1} width={1 / 4}>
            <Trans
              id="Enter division ID"
              render={({ translation }) => (
                <Field.Text
                  name="division_id"
                  label={<Trans id="Division ID" />}
                  placeholder={translation}
                />
              )}
            />
            <Validation.Matches
              field="division_id"
              options={UUID_PATTERN}
              message="Invalid ID"
            />
          </Box>
        </Flex>
        <Line />
        <Text fontSize={2} mb={6}>
          <Trans>Contact information</Trans>
        </Text>
        <Box pr={1} width={1 / 3}>
          <Trans
            id="Enter email"
            render={({ translation }) => (
              <Field.Text
                name="party.email"
                label={<Trans id="Email" />}
                placeholder={translation}
              />
            )}
          />
          <Validations field="party.email">
            <Validation.Required message="Required field" />
            <Validation.Email message="Invalid email" />
          </Validations>
        </Box>
        <Field.Array
          name="party.phones"
          addText={<Trans>Add phone</Trans>}
          fields={PhonesForm}
          removeButton={({ onClick }) => <RemoveButton onClick={onClick} />}
        />
        <Line />
        <Text fontSize={2} mb={6}>
          <Trans>Documents</Trans>
        </Text>
        <Flex>
          <Box pr={2} width={2 / 9}>
            <Trans
              id="Enter the number"
              render={({ translation }) => (
                <ControlledField
                  name="party.no_tax_id"
                  subscription={{ value: true }}
                >
                  {({ input: { value } }: $TSFixMe) =>
                    value ? (
                      <>
                        <Field.Text
                          name="party.tax_id.passport"
                          label={<Trans id="Passport" />}
                          placeholder={translation}
                          maxLength={9}
                        />
                        <Validation.Matches
                          field="party.tax_id.passport"
                          options={NO_TAX_ID_DOCUMENT_PATTERN}
                          message="Invalid passport number"
                        />
                      </>
                    ) : (
                      <>
                        <Field.Text
                          name="party.tax_id.taxNumber"
                          label={<Trans id="INN" />}
                          placeholder={translation}
                          maxLength={10}
                        />
                        <Validation.Matches
                          field="party.tax_id.taxNumber"
                          options={TAX_ID_PATTERN}
                          message="Invalid tax number"
                        />
                      </>
                    )
                  }
                </ControlledField>
              )}
            />
          </Box>
          <Box alignSelf="center">
            <Field.Checkbox
              label={<Trans id="Person waived of tax ID" />}
              name="party.no_tax_id"
            />
            <ControlledField.Listener
              field="party.no_tax_id"
              set="party.tax_id"
              to=""
            />
          </Box>
        </Flex>
        <Field.Array
          name="party.documents"
          addText={<Trans>Add document</Trans>}
          fields={DocumentsForm}
          removeButton={({ onClick }) => <RemoveButton onClick={onClick} />}
        />
        <Line />
        <Flex mb={200}>
          <Box mr={3}>
            <Button
              type="reset"
              variant="blue"
              width={140}
              onClick={() => navigate!(`/employees/${id}`)}
            >
              <Trans>Back</Trans>
            </Button>
          </Box>
          <Box>
            <Button variant="green" width={140}>
              <Trans>Update</Trans>
            </Button>
          </Box>
        </Flex>
      </Form>
    </Box>
  );
};

type PhonesFormProps = {
  name: string;
};

const PhonesForm = ({ name }: PhonesFormProps) => {
  const { i18n } = useLingui();

  return (
    <>
      <Box pr={2} width={2 / 9}>
        <Field.Text
          name={`${name}.number`}
          label={<Trans id="Phone number" />}
          format={formatPhone}
          parse={parsePhone}
        />
        <Validations field={`${name}.number`}>
          <Validation.Required message="Required field" />
          <Validation.Matches
            options={"^\\+380\\d{9}$"}
            message="Invalid phone number"
          />
          <Validation.Custom
            options={({
              value,
              allValues: {
                party: { phones }
              }
            }: {
              value: string;
              allValues: {
                party: Party;
              };
            }) => {
              const duplicates = phones.filter(
                (phone) => phone && phone.number === value
              );
              return duplicates.length === 1;
            }}
            message="This number is used more than once"
          />
        </Validations>
      </Box>
      <Box pr={2} width={2 / 9}>
        <Composer components={[<DictionaryValue name="PHONE_TYPE" />]}>
          {([dict]: [Dictionary["values"]]) => {
            const translation = i18n._(t`Select option`);
            return (
              <Field.Select
                name={`${name}.type`}
                label={<Trans id="Phone type" />}
                placeholder={translation}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || translation}
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            );
          }}
        </Composer>
        <Validation.Required field={`${name}.type`} message="Required field" />
      </Box>
    </>
  );
};

type DocumentsFormProps = {
  name: string;
};

const DocumentsForm = ({ name }: DocumentsFormProps) => {
  const { i18n } = useLingui();

  return (
    <>
      <Box pr={2} width={2 / 9}>
        <Composer components={[<DictionaryValue name="DOCUMENT_TYPE" />]}>
          {([dict]: [Dictionary["values"]]) => {
            const translation = i18n._(t`Select option`);
            return (
              <Field.Select
                name={`${name}.type`}
                label={<Trans id="Select document type" />}
                placeholder={translation}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || translation}
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            );
          }}
        </Composer>
        <Validations field={`${name}.type`}>
          <Validation.Required message="Required field" />
          <Validation.Custom
            options={({
              value,
              allValues: {
                party: { documents }
              }
            }: {
              value: string;
              allValues: {
                party: Party;
              };
            }) => {
              const duplicates = documents.filter(
                (doc) => doc && doc.type === value
              );
              return duplicates.length === 1;
            }}
            message="This document type is used more than once"
          />
        </Validations>
      </Box>
      <Box pr={2} width={2 / 9}>
        <Field.Text
          name={`${name}.number`}
          label={<Trans id="Document number" />}
          placeholder={i18n._(t`Enter the number`)}
        />
        <Validation.Required
          field={`${name}.number`}
          message="Required field"
        />
      </Box>
      <Box pr={2} width={2 / 9}>
        <Field.Text
          name={`${name}.issued_by`}
          label={<Trans id="Authority that issued" />}
          placeholder={i18n._(t`Enter authority name`)}
          autoComplete="off"
        />
        <Validation.Required
          field={`${name}.issued_by`}
          message="Required field"
        />
      </Box>
      <Box pr={2} width={2 / 9}>
        <Field.DatePicker
          name={`${name}.issued_at`}
          label={<Trans id="Date of issue" />}
          minDate="1900-01-01"
        />
        <Validation.Required
          field={`${name}.issued_at`}
          message="Required field"
        />
      </Box>
    </>
  );
};

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  if (!state) return null;
  const {
    toUpdateEmployeeData: { division_id, party, ...toUpdateEmployeeData }
  } = state;

  const cleanedEmployeeData = cleanDeep(
    {
      ...toUpdateEmployeeData,
      division_id: division_id ? division_id : undefined
    },
    { nullableValues: false }
  );

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          fullName: <Trans>Name of employee</Trans>,
          gender: <Trans>Gender</Trans>,
          birthDate: <Trans>Date of birth</Trans>
        }}
        data={{
          fullName: getFullName({
            firstName: party.first_name,
            secondName: party.second_name,
            lastName: party.last_name
          }),
          gender: <DictionaryValue name="GENDER" item={party.gender} />,
          birthDate: i18n.date(party.birth_date)
        }}
        labelWidth="200px"
      />
      <Line />
      <DefinitionListView
        labels={{
          position: <Trans>Position</Trans>,
          employeeType: <Trans>Employee type</Trans>,
          startDate: <Trans>Start date</Trans>
        }}
        data={{
          position: (
            <DictionaryValue
              name="POSITION"
              item={toUpdateEmployeeData.position}
            />
          ),
          employeeType: (
            <DictionaryValue
              name="EMPLOYEE_TYPE"
              item={toUpdateEmployeeData.employee_type}
            />
          ),
          startDate: i18n.date(toUpdateEmployeeData.start_date)
        }}
        labelWidth="200px"
      />
      <Line />
      <DefinitionListView
        labels={{
          email: <Trans>Email</Trans>,
          phones: <Trans>Phones</Trans>
        }}
        data={{
          email: party.email,
          phones: party.phones.map(({ number }: Phone) => number).join(", ")
        }}
        labelWidth="200px"
      />
      <Line />
      <DefinitionListView
        labels={{
          taxId: party.no_tax_id ? <Trans>Passport</Trans> : <Trans>INN</Trans>,
          noTaxId: <Trans>No tax ID</Trans>,
          documents: <Trans>Documents</Trans>
        }}
        data={{
          taxId: party.tax_id.taxNumber || party.tax_id.passport,
          noTaxId: party.no_tax_id ? <PositiveIcon /> : null,
          documents: party.documents.map(
            (
              {
                number,
                type,
                issued_at,
                issued_by
              }: {
                number: string;
                type: string;
                issued_at: string;
                issued_by: string;
              },
              index: number
            ) => (
              <Box key={index} pb={4}>
                <Heading fontSize="0" fontWeight="bold" pb={3}>
                  <DictionaryValue name="DOCUMENT_TYPE" item={type} />
                </Heading>
                <DocumentView
                  data={{
                    number,
                    issuedAt: issued_at,
                    issuedBy: issued_by
                  }}
                />
              </Box>
            )
          )
        }}
        labelWidth="200px"
      />
      <Flex mt={5}>
        <Box mr={3}>
          <Button
            variant="blue"
            width={140}
            onClick={() => {
              navigate!("../", {
                state: {
                  toUpdateEmployeeData: {
                    ...cleanedEmployeeData,
                    party
                  }
                }
              });
            }}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Signer.Parent
            url={env.REACT_APP_SIGNER_URL}
            features={{ width: 640, height: 589 }}
          >
            {/* @ts-expect-error signData */}
            {({ signData }) => (
              <Mutation mutation={CreateEmployeeRequestMutation}>
                {(createEmployeeRequest: MutationFunction) => (
                  <Button
                    variant="green"
                    width={140}
                    onClick={async () => {
                      const { signedContent } = await signData({
                        employee_request: {
                          ...cleanedEmployeeData,
                          party: {
                            ...party,
                            tax_id:
                              party.tax_id.taxNumber || party.tax_id.passport
                          },
                          status: "NEW"
                        }
                      });
                      await createEmployeeRequest({
                        variables: {
                          input: {
                            signedContent: {
                              content: signedContent,
                              encoding: "BASE64"
                            }
                          }
                        }
                      });
                      await navigate!("/employee-requests");
                    }}
                  >
                    <Trans>Sign</Trans>
                  </Button>
                )}
              </Mutation>
            )}
          </Signer.Parent>
        </Box>
      </Flex>
    </Box>
  );
};

type RemoveButtonProps = {
  onClick: () => void;
};

const RemoveButton = ({ onClick }: RemoveButtonProps) => (
  <Box width={1 / 9} alignSelf="center">
    <RemoveItemIcon onClick={onClick} />
  </Box>
);

export default Update;
