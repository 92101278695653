export const UUID_PATTERN =
  "^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$";

export const TAX_ID_PATTERN = "^[0-9]{10}$";

export const NO_TAX_ID_DOCUMENT_PATTERN = "^([0-9]{9}|[А-ЯЁЇIЄҐ]{2}[0-9]{6})$";

export const EDRPOU_PATTERN = "^[0-9]{8,10}$";

export const PASSPORT_PATTERN = "^((?![ЫЪЭЁ])([А-ЯҐЇІЄ])){2}[0-9]{6}$";

export const LEGAL_ENTITY_ID_PATTERN =
  "^[0-9A-Za-zА]{8}-[0-9A-Za-zА]{4}-[0-9A-Za-zА]{4}-[0-9A-Za-zА]{4}-[0-9A-Za-zА]{12}$";

export const EDRPOU_OR_PASSPORT_OR_LEGAL_ENTITY_ID_PATTERN = `(${EDRPOU_PATTERN})|(${PASSPORT_PATTERN})|(${LEGAL_ENTITY_ID_PATTERN})`;

export const INNM_PATTERN = "^[А-Яа-яЁёЇїІіЄєҐґ-\\s]*$";

export const INNM_ORIGINAL_NAME_PATTERN = "^[a-zA-Z-\\s]*$";

export const SCTID_PATTERN = "^[0-9]{8}$";

export const CYRILLIC_NAME = "^(?!.*[ЫЪЭЁыъэё@%&$^#])[А-ЯҐЇІЄа-яґїіє\\'\\- ]+$";

export const CYRILLIC_MEDICAL_PROGRAM_NAME = "^[А-Яа-яЁёЇїІіЄєҐґ'\\- ]*$";

export const PHONE_PATTERN = "^\\+380\\d{9}$";

export const UNZR_PATTERN = "^[0-9]{8}-[0-9]{5}$";

export const NATIONAL_ID = "^[0-9]{9}$";

export const BIRTH_CERTIFICATE =
  "^(?![ЫЪЭЁыъэё@%&$^#`~:,.*|}{?!])[A-ZА-ЯҐЇІЄ0-9№/()-]+$";

export const PERSON_TAX_ID_PATTERN = "^[0-9]{10}$";

export const ADDRESS_ITEM_PATTERN =
  '^(?!.*[ЫЪЭЁыъэё@%&$^#])[a-zA-ZА-ЯҐЇІЄа-яґїіє0-9№\\"!\\^\\*)\\]\\[(._-].*$';

export const ZIP_PATTERN = "^[0-9]{5}$";

export const BUILDING_PATTERN =
  "^[1-9]((?![ЫЪЭЁыъэё])()([А-ЯҐЇІЄа-яґїіє \\/\\'\\-0-9])){0,20}$";

export const PERSON_NAME_PATTERN =
  "^(?!.*[ЫЪЭЁыъэё@%&$^#])[А-ЯҐЇІЄа-яґїіє\\'\\-]+(\\s(?!.*[ЫЪЭЁыъэё@%&$^#])[А-ЯҐЇІЄа-яґїіє\\'\\-]+)*$";

export const SECRET_WORD_PATTERN =
  '^((?![ЫЪЭЁыъэё@%&$^#])([a-zA-ZА-ЯҐЇІЄа-яґїіє0-9№\\"!^*(._\\-)])){6,20}$';

export const TEMPORARY_CERTIFICATE_PATTERN =
  "^(((?![ЫЪЭЁ])([А-ЯҐЇІЄ])){2}[0-9]{4,6}|[0-9]{9}|((?![ЫЪЭЁ])([А-ЯҐЇІЄ])){2}[0-9]{5}\\/[0-9]{5})$";

export const BIRTH_CERTIFICATE_PATTERN =
  "^((?![ЫЪЭЁыъэё@%&$^#`~:,.*|}{?!])[A-ZА-ЯҐЇІЄ0-9№\\/()-]){2,25}$";

export const EMAIL_PATTERN =
  "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$";

export const PERCENT_PATTERN =
  "^(100|([0-9]{1,2})|([0-9]{1,2}\\.[0-9]{1,2})|([0-9]{1,2},[0-9]{1,2}))$";

export const NATIONAL_ID_OR_PASSPORT =
  "^(((?![ЫЪЭЁ])([А-ЯҐЇІЄ])){2}[0-9]{6}$|[0-9]{9}$)";

export const DRLZ_SKU_ID_PATTERN = "^[0-9]{1,38}$";
