import React, { useCallback, useState } from "react";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { mapListValues } from "react-awesome-query-builder/modules/utils/stuff";
import { BaseWidgetProps } from "react-awesome-query-builder";

import * as Field from "../../../../../components/Field";
import { ListValue, ListValues } from "../../../getFields";
import { ItemType, ItemTypes } from "../../../../Field/MultiSelectField";

export type MultiselectProps = BaseWidgetProps & {
  listValues: ListValues;
  viewKeysInsteadTitles?: boolean;
  asyncFetch?: (searchString: string) => ListValues;
};

export default ({
  listValues,
  value,
  setValue,
  readonly,
  asyncFetch,
  placeholder,
  viewKeysInsteadTitles
}: MultiselectProps) => {
  const [asyncFetchValues, setAsyncFetchValues] = useState<ListValues>([]);

  const onChange = useCallback((multiselectValue: ItemTypes) => {
    const values = multiselectValue.map((item: ItemType) => item.key);
    setValue(values);
    return multiselectValue;
  }, []);

  const onInputValueChange = (searchString: string) => {
    if (!(asyncFetch && typeof asyncFetch === "function")) return;
    const searchData = asyncFetch(searchString);
    setAsyncFetchValues(searchData);
    return searchData;
  };

  const getItemTitle = (itemVal: string) => {
    let findItem;
    if (listValues) {
      findItem = listValues.find((item: ListValue) => {
        return item.value === itemVal;
      });
      return findItem && findItem.title;
    } else if (asyncFetch) {
      const findItems = asyncFetch(itemVal);
      findItem = findItems[0];
    }
    return findItem && findItem.title;
  };

  const getSelectedItems = () => {
    return value
      ? value.map((v: string) => ({
          key: v,
          value: viewKeysInsteadTitles ? v : getItemTitle(v)
        }))
      : [];
  };

  return (
    <Field.MultiSelect
      onChange={onChange}
      onInputValueChange={onInputValueChange}
      selectedItems={getSelectedItems()}
      disabled={readonly}
      withoutFormField
      items={mapListValues(
        listValues || asyncFetchValues,
        (item: ListValue) => ({
          key: item.value,
          value: item.title
        })
      )}
      renderItem={(item: ItemType) =>
        viewKeysInsteadTitles ? item.key : item.value
      }
      hideErrors
      placeHolder={asyncFetch ? "Пошук" : placeholder}
      filter={(items: ItemTypes, inputValue = "") =>
        items.filter(
          (item: ItemType) =>
            item.value.toLowerCase().includes(inputValue.toLowerCase()) ||
            item.key.toLowerCase().includes(inputValue.toLowerCase())
        )
      }
    />
  );
};
