import _objectWithoutProperties from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import set from "lodash/set";
var normalizeInputErrors = function normalizeInputErrors() {
  var inputErrors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return inputErrors.reduce(function (acc, _ref) {
    var path = _ref.path,
      error = _objectWithoutProperties(_ref, ["path"]);
    return set(acc, path, error);
  }, {});
};
export default normalizeInputErrors;