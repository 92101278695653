import React from "react";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import { Episode, Diagnosis as TDiagnosis } from "@ehealth/ehealth-ua.schema";

import CodeableConcept from "../../../components/CodeableConcept";
import Table from "../../../components/Table";

import paramToBase64 from "../../../helpers/paramToBase64";

import Reference from "./Reference";

type DiagnosisProps = {
  diagnosis: Episode["currentDiagnoses"];
  isClinicalMonitoring: boolean;
  patientId?: string;
};

const Diagnosis = ({
  diagnosis,
  isClinicalMonitoring,
  patientId
}: DiagnosisProps) => {
  if (isEmpty(diagnosis)) return null;

  return (
    <Box mt={-4}>
      <Table
        hidePagination
        hideControls
        data={diagnosis}
        header={{
          role: <Trans>Role</Trans>,
          code: <Trans>Code</Trans>,
          rank: <Trans>Rank</Trans>,
          condition: <Trans>Condition</Trans>
        }}
        renderRow={({ role, code, condition, ...restData }: TDiagnosis) => ({
          ...restData,
          code: <CodeableConcept codeableConcept={code} withCode />,
          role: <CodeableConcept codeableConcept={role} />,
          condition: (
            <Reference
              fontSize={12}
              headless
              linkPath={
                isClinicalMonitoring
                  ? `../../../condition/${patientId}/${paramToBase64(
                      "Condition",
                      condition && condition.identifier.value
                    )}`
                  : `../../condition/${paramToBase64(
                      "Condition",
                      condition && condition.identifier.value
                    )}`
              }
              linkDisplayValue={
                condition && condition.displayValue
                  ? condition.displayValue
                  : condition && condition.identifier.value
              }
            />
          )
        })}
      />
    </Box>
  );
};

export default Diagnosis;

Diagnosis.fragments = {
  entry: gql`
    fragment Diagnosis on Diagnosis {
      rank
      code {
        ...CodeableConcept
      }
      role {
        ...CodeableConcept
      }
      condition {
        ...Reference
      }
    }
    ${CodeableConcept.fragments!.entry}
    ${Reference.fragments.entry}
  `
};
