import { addMonths, getYear, endOfYear } from "date-fns";

export const contractRequestMaxDateCount = (
  startDate: string,
  endDate: string
) => {
  const maxDate = addMonths(new Date(endDate), 3);

  return getYear(maxDate) - getYear(startDate) <= 1
    ? maxDate
    : endOfYear(endDate);
};
