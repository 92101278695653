import React from "react";
import { useLingui } from "@lingui/react";

import { Reference as TReference } from "@ehealth/ehealth-ua.schema";

import { useMemoizedReference } from "../../../helpers/useMemoizedReference";

import Reference from "./Reference";

type ReferenceSwitcherProps = {
  reference: TReference;
  referenceTypes: { values: { [key: string]: string } };
  handleSetReferenceLink: (
    memoizedReferenceType: string,
    memoizedReferenceSlug: string,
    reference: TReference
  ) => string;
};

const ReferenceSwitcher = ({
  reference,
  referenceTypes,
  handleSetReferenceLink
}: ReferenceSwitcherProps) => {
  const { i18n } = useLingui();
  const [memoizedReferenceType, memoizedReferenceSlug] = useMemoizedReference(
    reference
  );

  return (
    <Reference
      fontSize={14}
      customHeaderProps={{ fontWeight: "normal", width: "auto" }}
      header={<>{i18n._(referenceTypes.values[memoizedReferenceType])}: </>}
      linkPath={handleSetReferenceLink(
        memoizedReferenceType,
        memoizedReferenceSlug,
        reference
      )}
      linkDisplayValue={
        reference.displayValue
          ? reference.displayValue
          : reference.identifier.type.text || reference.identifier.value
      }
      linkWrapperProps={{
        mt: 0,
        ml: 1
      }}
    />
  );
};

export default ReferenceSwitcher;
