import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { Person } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";

import { Heading, Table } from "./index";

type GeneralInfoPatientProps = {
  data: Person | any[];
  isDracsDeathCandidatesDetailsPopup?: boolean;
  headerLabels?: any;
};

const GeneralInfoPatient = ({
  data,
  isDracsDeathCandidatesDetailsPopup,
  headerLabels
}: GeneralInfoPatientProps) => (
  <>
    <Heading>
      <Trans>General information</Trans>
    </Heading>
    {headerLabels && (
      <Table
        header={{
          id: null
        }}
        data={headerLabels}
        renderRow={({ id }) => ({
          id
        })}
        skipComparison
      />
    )}
    <Table
      header={{
        firstName: <Trans>First name</Trans>,
        lastName: <Trans>Last name</Trans>,
        secondName: <Trans>Second name</Trans>,
        birthDate: <Trans>Date of birth</Trans>,
        birthCountry: <Trans>Country of birth</Trans>,
        birthSettlement: <Trans>Settlement of birth</Trans>,
        gender: <Trans>Gender</Trans>,
        ...(!isDracsDeathCandidatesDetailsPopup
          ? {
              email: <Trans>Email</Trans>,
              preferredWayCommunication: (
                <Trans>Preferred way of communication</Trans>
              )
            }
          : {
              taxId: <Trans>Numident</Trans>
            })
      }}
      data={data}
      renderRow={({ birthDate, gender, taxId, ...content }) => ({
        ...content,
        gender: <DictionaryValue name="GENDER" item={gender} />,
        birthDate:
          birthDate && typeof birthDate === "string" && i18n.date(birthDate),
        taxId: taxId || "-"
      })}
    />
  </>
);

GeneralInfoPatient.fragments = {
  entry: gql`
    fragment GeneralInfoPatient on Person {
      firstName
      lastName
      secondName
      birthDate
      birthCountry
      birthSettlement
      gender
      email
      preferredWayCommunication
    }
  `
};

export default GeneralInfoPatient;
