import * as React from "react";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";
import get from "lodash/get";

import { Form, Validations, Validation } from "@edenlabllc/ehealth-components";

import { NumberField } from "./InputField";
import * as FieldView from "./FieldView";
import * as InputView from "./InputView";

type RangeNumberFieldProps = {
  rangeNames: string[];
  label?: React.ReactElement;
  hint?: string;
  disabled?: boolean;
};

const RangeNumberField = ({
  rangeNames: [start, end],
  label,
  hint,
  disabled = false
}: RangeNumberFieldProps) => {
  const { i18n } = useLingui();

  return (
    <Form.Spy>
      {({ values }: $TSFixMe) => (
        <Flex flexDirection="column">
          {label && (
            <FieldView.Header>
              <FieldView.Label>{label}</FieldView.Label>
              {hint && <FieldView.Message>{hint}</FieldView.Message>}
            </FieldView.Header>
          )}
          <Flex mx={-1}>
            <Box mx={1} width={1 / 2}>
              <NumberField
                name={start}
                placeholder={i18n._(t`from`)}
                disabled={disabled}
              />
              <Trans
                id="Number start must be less than number end"
                render={({ translation }) => (
                  <Validations field={start}>
                    {values && get(values, end) && (
                      <Validation.Required message="Required field" />
                    )}
                    <Validation.Custom
                      options={({ value, allValues }: $TSFixMe) => {
                        return (
                          !(value && get(allValues, end)) ||
                          (value && Number(get(allValues, end)) > Number(value))
                        );
                      }}
                      message={translation}
                    />
                  </Validations>
                )}
              />
            </Box>
            <Box mx={1}>
              <InputView.Content>-</InputView.Content>
            </Box>
            <Box mx={1} width={1 / 2}>
              <NumberField
                name={end}
                placeholder={i18n._(t`to`)}
                disabled={disabled}
              />
              <Trans
                id="Number end must be greater than number from"
                render={({ translation }) => (
                  <Validations field={end}>
                    {values && get(values, start) && (
                      <Validation.Required message="Required field" />
                    )}
                    <Validation.Custom
                      options={({ value, allValues }: $TSFixMe) => {
                        return (
                          !(value && get(allValues, start)) ||
                          (value &&
                            Number(get(allValues, start)) < Number(value))
                        );
                      }}
                      message={translation}
                    />
                  </Validations>
                )}
              />
            </Box>
          </Flex>
        </Flex>
      )}
    </Form.Spy>
  );
};

export default RangeNumberField;
