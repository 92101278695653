import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import Composer from "react-composer";
import { Trans } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Form, LocationParams, Switch } from "@edenlabllc/ehealth-components";
import {
  PositiveIcon,
  NegativeIcon,
  CancelIcon
} from "@edenlabllc/ehealth-icons";
import {
  Dictionary,
  Division,
  HealthcareService
} from "@ehealth/ehealth-ua.schema";

import AddressView from "../../../../components/AddressView";
import AvailableTime from "../../../../components/AvailableTime";
import Badge from "../../../../components/Badge";
import CodeableConcept from "../../../../components/CodeableConcept";
import DictionaryValue from "../../../../components/DictionaryValue";
import EmptyData from "../../../../components/EmptyData";
import * as Field from "../../../../components/Field";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import Pagination from "../../../../components/Pagination";
import {
  SearchParams,
  TLocationParams
} from "../../../../components/SearchForm";
import Table from "../../../../components/Table";

import STATUSES from "../../../../helpers/statuses";
import { ITEMS_PER_PAGE } from "../../../../constants/pagination";

import { DivisionQuery } from "./Details";

type DivisionHealthcareServicesProps = RouteComponentProps<{
  divisionId: string;
}>;

const DivisionHealthcareServices = ({
  divisionId
}: DivisionHealthcareServicesProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => {
      const { filter, first, last, after, before } = locationParams;
      const healthcareServicesFilter =
        filter && filter.healthcareServicesFilter;

      return (
        <>
          <Form onSubmit={setLocationParams} initialValues={locationParams}>
            <Form.AutoSubmit
              onSubmit={(values: SearchParams) => setLocationParams(values)}
            />
            <Flex pt={5}>
              <Box px={1} width={1 / 4}>
                <Composer
                  components={[
                    <DictionaryValue name="SPECIALITY_TYPE" />,
                    ({
                      render
                    }: {
                      render: (props: TransRenderProps) => React.ReactElement;
                    }) => <Trans id="Select option" render={render} />
                  ]}
                >
                  {([dict, { translation }]: [
                    Dictionary["values"],
                    { translation: React.ReactNode }
                  ]) => (
                    <Field.Select
                      name="filter.healthcareServicesFilter.specialityType"
                      label={<Trans id="Speciality type" />}
                      placeholder={translation}
                      items={Object.keys(dict)}
                      itemToString={(item: string) => dict[item] || translation}
                      emptyOption
                      filterOptions={{ keys: [(item: string) => dict[item]] }}
                    />
                  )}
                </Composer>
              </Box>
              <Box px={1} width={1 / 4}>
                <Trans
                  id="All statuses"
                  render={({ translation }) => (
                    <Field.Select
                      name="filter.healthcareServicesFilter.status"
                      label={<Trans id="Status" />}
                      placeholder={translation}
                      items={Object.keys(STATUSES.ACTIVE_INACTIVE_M)}
                      itemToString={(item: string) =>
                        STATUSES.ACTIVE_INACTIVE_M[item] || translation
                      }
                      variant="select"
                      emptyOption
                      filterOptions={{
                        keys: [
                          (item: string) => STATUSES.ACTIVE_INACTIVE_M[item]
                        ]
                      }}
                    />
                  )}
                />
              </Box>
              <Box px={1} width={1 / 4}>
                <DictionaryValue name="HEALTHCARE_SERVICE_CATEGORIES">
                  {(dict: Dictionary["values"]) => (
                    <Trans
                      id="Select category"
                      render={({ translation }) => (
                        <Field.Select
                          name="filter.healthcareServicesFilter.category"
                          label={<Trans id="Healthcare service category" />}
                          items={Object.keys(dict)}
                          itemToString={(item: string) =>
                            dict[item] || translation
                          }
                          variant="select"
                          emptyOption
                          filterOptions={{
                            keys: [(item: string) => dict[item]]
                          }}
                        />
                      )}
                    />
                  )}
                </DictionaryValue>
              </Box>
            </Flex>
          </Form>
          <Query
            query={DivisionQuery}
            variables={{
              id: divisionId,
              healthcareServicesFilter,
              firstHealthcareServices:
                !first && !last
                  ? ITEMS_PER_PAGE[0]
                  : first
                  ? parseInt(first)
                  : undefined,
              lastHealthcareServices: last ? parseInt(last) : undefined,
              beforeHealthcareServices: before,
              afterHealthcareServices: after,
              firstEquipment: ITEMS_PER_PAGE[0]
            }}
          >
            {({ loading, data }: QueryResult<{ division: Division }>) => {
              if (isEmpty(data)) return null;
              const { nodes: healthcareServices = [], pageInfo } =
                data.division.healthcareServices || {};

              return (
                <LoadingOverlay loading={loading}>
                  {healthcareServices!.length > 0 ? (
                    <>
                      <Table
                        data={healthcareServices}
                        header={{
                          addresses: <Trans>Address</Trans>,
                          category: <Trans>Healthcare service category</Trans>,
                          type: <Trans>Healthcare service type</Trans>,
                          specialityType: <Trans>Speciality type</Trans>,
                          providingCondition: (
                            <Trans>Providing Condition</Trans>
                          ),
                          availableTime: <Trans>Available Time</Trans>,
                          status: <Trans>Status</Trans>,
                          licensedHealthcareService: <Trans>License</Trans>
                        }}
                        renderRow={({
                          specialityType,
                          providingCondition,
                          availableTime,
                          status,
                          licensedHealthcareService,
                          category,
                          type,
                          division
                        }: HealthcareService) => ({
                          addresses: division.addresses
                            .filter((a) => a && a.type === "RESIDENCE")
                            .map((item, key) => (
                              <AddressView data={item} key={key} />
                            )),
                          category: !isEmpty(category) && (
                            <DictionaryValue
                              name={
                                category.coding[0]
                                  ? category.coding[0].system
                                  : ""
                              }
                              item={
                                category.coding[0] && category.coding[0].code
                              }
                            />
                          ),
                          type: !isEmpty(type) && (
                            <DictionaryValue
                              name={type.coding[0] ? type.coding[0].system : ""}
                              item={type.coding[0] && type.coding[0].code}
                            />
                          ),
                          specialityType: !isEmpty(specialityType) && (
                            <DictionaryValue
                              name="SPECIALITY_TYPE"
                              item={specialityType}
                            />
                          ),
                          providingCondition: !isEmpty(providingCondition) && (
                            <DictionaryValue
                              name="PROVIDING_CONDITION"
                              item={providingCondition}
                            />
                          ),
                          availableTime: <AvailableTime data={availableTime} />,
                          status: (
                            <Badge
                              type="ACTIVE_INACTIVE_M"
                              name={status}
                              display="block"
                            />
                          ),
                          licensedHealthcareService: (
                            <Flex justifyContent="center">
                              <LicensedHealthcareServiceStatus
                                service={licensedHealthcareService}
                              />
                            </Flex>
                          )
                        })}
                      />
                      <Pagination {...pageInfo} />
                    </>
                  ) : (
                    <EmptyData />
                  )}
                </LoadingOverlay>
              );
            }}
          </Query>
        </>
      );
    }}
  </LocationParams>
);

const LicensedHealthcareServiceStatus = ({
  service
}: {
  service: HealthcareService["licensedHealthcareService"];
}) => {
  if (!service || typeof service !== "object")
    return <NegativeIcon title={<Trans>No info</Trans>} />;

  return (
    <Switch
      value={service.status}
      ACTIVE={<PositiveIcon title={<Trans>Active licence</Trans>} />}
      INACTIVE={
        <Box mx={2} color="redPigment">
          <CancelIcon title={<Trans>Inactive licence</Trans>} />
        </Box>
      }
      default={<NegativeIcon title={<Trans>No info</Trans>} />}
    />
  );
};

DivisionHealthcareServices.fragments = {
  entry: gql`
    fragment DivisionHealthcareServices on Division {
      healthcareServices(
        filter: $healthcareServicesFilter
        first: $firstHealthcareServices
        before: $beforeHealthcareServices
        after: $afterHealthcareServices
        last: $lastHealthcareServices
        orderBy: $healthcareServicesOrderBy
      ) {
        nodes {
          id
          division {
            id
            name
            addresses {
              ...Addresses
            }
          }
          status
          specialityType
          providingCondition
          licensedHealthcareService {
            status
          }
          availableTime {
            daysOfWeek
            allDay
            availableStartTime
            availableEndTime
          }
          category {
            ...CodeableConcept
          }
          type {
            ...CodeableConcept
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
    ${AddressView.fragments.entry}
    ${Pagination.fragments.entry}
    ${CodeableConcept.fragments!.entry}
  `
};

export default DivisionHealthcareServices;
