import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { PartyConnection } from "@ehealth/ehealth-ua.schema";

import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";
import LoadingOverlay from "../../../components/LoadingOverlay";

import prepareParamsToQueryWithUnverified from "../../../helpers/filteredLocationParamsWithUnverified";

import PartiesTable from "./PartiesTable";
import { PrimarySearchFields } from "./SearchFields";

const Search = (_props: RouteComponentProps) => {
  return (
    <Box p={6}>
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => (
          <>
            <Heading as="h1" fontWeight="normal" mb={6}>
              <Trans>Party verification</Trans>
            </Heading>

            <SearchForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
              renderPrimary={PrimarySearchFields}
            />
            <Query
              query={SearchUnverifiedPartiesQuery}
              fetchPolicy="network-only"
              variables={prepareParamsToQueryWithUnverified(locationParams)}
            >
              {({
                loading,
                data
              }: QueryResult<{ unverifiedParties: PartyConnection }>) => {
                const {
                  unverifiedParties: {
                    nodes: unverifiedParties = [],
                    pageInfo = undefined
                  } = {}
                } = data || {};

                return (
                  <LoadingOverlay loading={loading}>
                    <>
                      <PartiesTable
                        unverifiedParties={unverifiedParties}
                        locationParams={locationParams}
                        setLocationParams={setLocationParams}
                      />
                      {pageInfo && <Pagination {...pageInfo} />}
                    </>
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        )}
      </LocationParams>
    </Box>
  );
};

const SearchUnverifiedPartiesQuery = gql`
  query SearchUnverifiedPartiesQuery(
    $filter: UnverifiedPartyFilter
    $orderBy: PartyOrderBy
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    unverifiedParties(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        ...Parties
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PartiesTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Search;
