import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";

import { Scalars } from "@ehealth/ehealth-ua.schema";

import Button from "../../../../components/Button";
import Popup from "../../../../components/Popup";

type DeactivateProgramServicePopupProps = {
  id: Scalars["ID"];
};

const DeactivateProgramServicePopup = ({
  id
}: DeactivateProgramServicePopupProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Mutation mutation={DeactivateProgramServiceMutation}>
      {(deactivateProgramService: MutationFunction) => (
        <>
          <Button onClick={toggle} variant="red">
            <Trans>Deactivate</Trans>
          </Button>
          <Popup
            visible={isPopupVisible}
            onCancel={toggle}
            title={
              <>
                <Trans>Deactivate program service</Trans>?
              </>
            }
            okText={<Trans>Deactivate</Trans>}
            onOk={async () => {
              await deactivateProgramService({
                variables: {
                  input: {
                    id
                  }
                }
              });
              toggle();
            }}
          />
        </>
      )}
    </Mutation>
  );
};

const DeactivateProgramServiceMutation = gql`
  mutation DeactivateProgramServiceMutation(
    $input: DeactivateProgramServiceInput!
  ) {
    deactivateProgramService(input: $input) {
      programService {
        id
        isActive
      }
    }
  }
`;

export default DeactivateProgramServicePopup;
