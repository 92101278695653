import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams, Validation } from "@edenlabllc/ehealth-components";
import {
  Dictionary,
  ServiceRequestConnection
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../components/SearchForm";

import { MonitoringTabProps } from "../../../helpers/types";
import pagination from "../../../helpers/pagination";
import paramToBase64 from "../../../helpers/paramToBase64";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

import { PatientSearchField } from "../../ClinicalMonitoring/PatientSearchField";
import { SearchResultTable } from "./EpisodesTab";

const ServiceRequestsTab = ({
  id,
  query = ServiceRequestsQuery,
  isClinicalMonitoring = false
}: MonitoringTabProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="service_request"
  >
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Query
          skip={!locationParams.first && !locationParams.last}
          query={query}
          fetchPolicy="network-only"
          variables={prepareParamsToQuery(
            id!,
            locationParams,
            isClinicalMonitoring
          )}
        >
          {({
            data = {},
            loading
          }: QueryResult<{ [key: string]: ServiceRequestConnection }>) => {
            const serviceRequests = isClinicalMonitoring
              ? data.impersonalServiceRequests
              : data.serviceRequests;

            return (
              <LoadingOverlay loading={loading}>
                <Box p={1}>
                  <SearchForm
                    initialValues={locationParams}
                    onSubmit={setLocationParams}
                    renderPrimary={() =>
                      PrimarySearchFields(isClinicalMonitoring)
                    }
                    onResetSkipPagination
                  />
                </Box>
                {isEmpty(serviceRequests) || isEmpty(serviceRequests.nodes) ? (
                  <EmptyData mx={2} />
                ) : (
                  <Box mb={6} p={2}>
                    <Box>
                      <Text>
                        <Trans>Total found</Trans>:{" "}
                        {serviceRequests.totalEntries}
                      </Text>
                    </Box>
                    <SearchResultTable
                      data={serviceRequests.nodes}
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                      statusSystem="service_request_statuses"
                      tableName="serviceRequests"
                      pathSlug={
                        isClinicalMonitoring
                          ? `service-request/${
                              locationParams.filter &&
                              locationParams.filter.patientId
                            }`
                          : "service-request"
                      }
                      isClinicalMonitoring={isClinicalMonitoring}
                      codeColumnTextType="service"
                    />
                    <Pagination {...serviceRequests.pageInfo} />
                  </Box>
                )}
              </LoadingOverlay>
            );
          }}
        </Query>
      )}
    </LocationParams>
  </Ability>
);

export default ServiceRequestsTab;

const ServiceRequestsQuery = gql`
  query ServiceRequestsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ServiceRequestFilter!
    $orderBy: ServiceRequestOrderBy
  ) {
    serviceRequests(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

const prepareParamsToQuery = (
  id: string,
  locationParams: SearchParams,
  isClinicalMonitoring: boolean
) => {
  const {
    code,
    status,
    requesterLegalEntityId,
    usedByLegalEntityId,
    serviceRequestId,
    ...restFilterParams
  } = locationParams.filter || {};

  return {
    orderBy: "INSERTED_AT_DESC",
    ...pagination(locationParams),
    filter: {
      ...(!isClinicalMonitoring && { personId: id }),
      ...restFilterParams,
      ...(code && { code }),
      ...(status && {
        status: status.toUpperCase()
      }),
      ...(requesterLegalEntityId && {
        requesterLegalEntityId: paramToBase64(
          "LegalEntity",
          requesterLegalEntityId
        )
      }),
      ...(usedByLegalEntityId && {
        usedByLegalEntityId: paramToBase64("LegalEntity", usedByLegalEntityId)
      }),
      ...(serviceRequestId && {
        id: paramToBase64("ServiceRequest", serviceRequestId)
      })
    }
  };
};

const PrimarySearchFields = (isClinicalMonitoring: boolean) => {
  const { i18n } = useLingui();

  return (
    <Flex flexDirection="column">
      <PatientSearchField isClinicalMonitoring={isClinicalMonitoring} />
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter service ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.code"
                label={<Trans id="Service or service group ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.code"
            options={UUID_PATTERN}
            message="Invalid service ID"
          />
        </Box>
        <Box width={1 / 3} mt={3} px={1}>
          <DictionaryValue name="service_request_statuses">
            {(dict: Dictionary["values"]) => (
              <Field.Select
                name="filter.status"
                label={<Trans id="Service request status" />}
                placeholder={i18n._(t`Select status`)}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || item}
                variant="select"
              />
            )}
          </DictionaryValue>
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter service request requisition"
            render={({ translation }) => (
              <Field.Text
                name="filter.requisition"
                label={<Trans id="Service Request requisition" />}
                placeholder={translation}
              />
            )}
          />
        </Box>
      </Flex>
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter requester legal entity ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.requesterLegalEntityId"
                label={<Trans id="Requester legal entity ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.requesterLegalEntityId"
            options={UUID_PATTERN}
            message="Invalid requester legal entity ID"
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter used by legal entity ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.usedByLegalEntityId"
                label={<Trans id="Used by legal entity ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.usedByLegalEntityId"
            options={UUID_PATTERN}
            message="Invalid used by legal entity ID"
          />
        </Box>
        {isClinicalMonitoring && (
          <Box px={1} width={1 / 3} mt={3}>
            <Trans
              id="Enter service request ID"
              render={({ translation }) => (
                <Field.Text
                  name="filter.serviceRequestId"
                  label={<Trans id="Service request ID" />}
                  placeholder={translation}
                />
              )}
            />
            <Validation.Matches
              field="filter.serviceRequestId"
              options={UUID_PATTERN}
              message="Invalid service request ID"
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
