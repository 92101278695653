export const drfoVerificationStatus = (verificationStatus: string) => {
  switch (true) {
    case verificationStatus === "VERIFICATION_NEEDED":
      return "VERIFICATION_AUTO_NEEDED";

    default:
      return verificationStatus;
  }
};

export const dracsVerificationStatus = (verificationStatus: string) => {
  switch (true) {
    case verificationStatus === "VERIFICATION_NEEDED":
      return "VERIFICATION_AUTO_NEEDED";

    default:
      return verificationStatus;
  }
};
