import _taggedTemplateLiteral from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import { keyframes } from "@emotion/core";
var toTopBounce = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  from {\n    transform: translate(-50%, -100%);\n    opacity: 0;\n  }\n  to {\n    transform: translate(-50%, 0);\n    opacity: 1;\n  }\n"])));
var toCenterBounce = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  from {\n    transform: translate(-50%, -100%);\n    opacity: 0;\n  }\n  to {\n    transform: translate(-50%, -50%);\n    opacity: 1;\n  }\n"])));
export var placements = {
  top: {
    top: 0,
    left: "50%",
    transform: "translate(-50%, 0)",
    animation: "".concat(toTopBounce, " .5s ease forwards")
  },
  center: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    animation: "".concat(toCenterBounce, " .5s ease forwards")
  }
};
var colors = {
  red: "#ff0001"
};
export default {
  placements: placements,
  colors: colors
};