import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";

import Details from "./Details/";
import Search from "./Search/";

const ServiceGroups = ({ uri }: RouteComponentProps) => (
  <Router>
    <Redirect from="/" to={`${uri}/search`} />
    <Search path="search/*" />
    <Details path=":id/*" />
  </Router>
);

export default ServiceGroups;
