import React, { useCallback } from "react";
import {
  Query as QueryBuilder,
  Builder,
  Utils as QbUtils,
  ImmutableTree,
  BuilderProps,
  Config
} from "react-awesome-query-builder";
import { Box, Text } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import debounce from "lodash/debounce";

import { getQueryStringFromTree } from "./helpers";
import "./styles.css";

const { isValidTree } = QbUtils;

type EhealthQueryBuilderProps = {
  tree: ImmutableTree | undefined;
  config: Config;
  handleTreeChange?: (immutableTree: ImmutableTree) => void;
};

const EhealthQueryBuilder = ({
  tree,
  handleTreeChange,
  config
}: EhealthQueryBuilderProps) => {
  const onChange = useCallback(
    (immutableTree: ImmutableTree) => {
      if (handleTreeChange && typeof handleTreeChange === "function") {
        handleTreeChange(immutableTree);
      }
    },
    [config, handleTreeChange]
  );

  const renderBuilder = (props: BuilderProps) => (
    <div className="query-builder-container">
      <div className="qb-lite">
        <Builder {...props} />
      </div>
      <div id="query-builder-confirmation-modal" />
    </div>
  );

  const renderResult = () => {
    const titleProps = {
      color: "darkCharcoal",
      fontWeight: "bold"
    };

    const humanString =
      tree &&
      JSON.stringify(getQueryStringFromTree(tree, config), undefined, 2);

    if (!humanString) return null;

    return (
      <div className="query-builder-result">
        <Text {...titleProps}>
          <Trans>Query builder result</Trans>:
        </Text>
        <Box pt={2}>{humanString}</Box>
      </div>
    );
  };

  return (
    <>
      {tree && (
        <QueryBuilder
          {...config}
          value={tree}
          onChange={debounce(onChange, 100)}
          renderBuilder={renderBuilder}
        />
      )}
      {tree && isValidTree(tree) && <Box pt={4}>{renderResult()}</Box>}
    </>
  );
};

export default EhealthQueryBuilder;
