import * as React from "react";
import { Box, Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";

import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import system from "@edenlabllc/ehealth-system-components";
import { Form } from "@edenlabllc/ehealth-components";
import { Person } from "@ehealth/ehealth-ua.schema";

import Button from "./Button";
import SearchModalForm from "./SearchModalForm";
import resetPaginationParams from "../helpers/resetPaginationParams";
import { PaginationType } from "../helpers/pagination";

export type SearchParamsFilter = {
  activityId?: string;
  addresses?: {
    id?: string;
    settlementType?: string;
  };
  allergyIntoleranceId?: string;
  allergyIntoleranceOnsetDate?: {
    from?: string;
    to?: string;
  };
  assignee?: {
    databaseId?: string;
  };
  atcCode?: string;
  basedOn?: string;
  carePlanId?: string;
  code?: string;
  conditionId?: string;
  conditionOnsetDate?: {
    from?: string;
    to?: string;
  };
  contextEpisodeId?: string;
  contractorLegalEntity?: {
    name?: string;
  };
  databaseId?: string;
  date?: {
    startFrom?: string;
    startTo?: string;
    endFrom?: string;
    endTo?: string;
    insertedAtFrom?: string;
    insertedAtTo?: string;
  };
  declarationNumber?: string;
  diagnosticReportId?: string;
  declarationSearch?: string;
  diagnosticReportIssuedDate?: {
    from?: string;
    to?: string;
  };
  dispenseValidFrom?: {
    from?: string;
    to?: string;
  };
  dispenseValidTo?: {
    from?: string;
    to?: string;
  };
  division?: {
    databaseId?: string;
    name?: string;
  };
  divisionFilter?: {};
  divisionId?: string;
  dlsVerified?: string;
  dosageFormIsDosed?: string;
  dracsDeathUnverifiedAt?: any;
  dracsDeathVerificationStatus?: string;
  encounterId?: string;
  encounterDate?: {
    from?: string;
    to?: string;
  };
  endedAt?: {
    from?: string;
    to?: string;
  };
  employeeId?: string;
  employeeStatus?: string;
  employeeType?: string;
  episodeId?: string;
  episodePeriod?: {
    from?: string;
    to?: string;
  };
  equipmentFilter?: {
    status?: string;
  };
  forbiddenGroupCode?: {
    isActive?: string;
  };
  forbiddenGroupCodesFilter?: {
    isActive?: string;
  };
  forbiddenGroupService?: {
    isActive?: string;
  };
  forbiddenGroupServicesFilter?: {
    isActive?: string;
  };
  form?: string;
  hasDeclaration?: string;
  healthcareServicesFilter?: {};
  id?: string;
  identity?: {
    firstName?: string;
    lastName?: string;
    type?: string;
    number?: string;
  };
  incomingReferralId?: string;
  ingredients?: {
    databaseId?: string;
    name?: string;
    innm?: {
      databaseId?: string;
    };
  };
  innmDosages?: {
    databaseId?: string;
    name?: string;
  };
  innmdosage?: {
    name?: string;
  };
  immunizationDate?: {
    from?: string;
    to?: string;
  };
  immunizationId?: string;
  isActive?: boolean;
  isEnded?: string;
  isSuspended?: boolean;
  legalEntity?: {
    edrData?: {
      name?: string;
      edrpou?: string;
    };
  };
  legalEntityId?: any;
  legalEntityRelation?: string;
  licenseFilter?: {};
  managingOrganizationId?: string;
  manualRulesVerificationStatus?: string;
  masterPersonId?: string;
  medicationDispenseId?: string;
  medicationDispensed?: {
    from?: string;
    to?: string;
  };
  medicalProgram?: {
    name?: string;
    databaseId?: string;
    mrBlankType?: string;
  };
  medicalProgramId?: string;
  medication?: {
    name?: string;
    databaseId?: string;
    innmDosages?: {
      databaseId?: string;
      name?: string;
    };
    manufacturer?: {
      name?: string;
    };
    atcCode?: string;
    form?: string;
    formPharm?: string;
    drlzSkuId?: string;
  };
  medicationId?: string;
  medicationRequestAllowed?: boolean;
  medicationRequestCreated?: {
    from?: string;
    to?: string;
  };
  medicationRequestId?: string;
  nhsReviewed?: string;
  nhsVerified?: string;
  noTaxId?: boolean;
  observationId?: string;
  observationIssued?: {
    from?: string;
    to?: string;
  };
  originEpisodeId?: string;
  party?: {
    noTaxId?: boolean;
    fullName?: string;
  };
  patientId?: string;
  personal?: {
    authenticationMethod?: {
      phoneNumber?: string;
    };
    birthDate?: string;
  };
  personId?: string;
  position?: string;
  procedureId?: string;
  reason?: string;
  registryNumber?: string;
  reimbursementType?: string;
  requestAllowed?: string;
  requesterLegalEntityId?: string;
  resourceType?: string;
  searchRequest?: string;
  service?: {
    databaseId?: string;
  };
  serviceGroup?: {
    databaseId?: string;
  };
  serviceRequestId?: string;
  startDate?: {
    from?: string;
    to?: string;
  };
  startedAt?: {
    from?: string;
    to?: string;
  };
  status?: string;
  taxId?: string;
  type?: string;
  verificationStatus?: string;
  usedByLegalEntityId?: string;
};

export type SearchParams = PaginationType & {
  addresses?: {};
  alias?: string;
  code?: string;
  division?: string;
  fieldType?: string;
  filter?: SearchParamsFilter;
  firstDictionary?: string;
  id?: string;
  name?: string;
  searchRequest?: string | null;
  system?: string;
  phoneNumber?: string;
  servicesTableFirst?: string;
  thirdPerson?: Partial<Person> & {
    phoneNumber?: string;
  };
  thirdPersonFullName?: string;
  thirdPersonId?: string;
  type?: string;
};

export type SetSearchParams = (params: SearchParams) => void;

export type TLocationParams = {
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

type SearchFormProps = {
  initialValues: SearchParams;
  onSubmit: SetSearchParams;
  renderPrimary: React.ComponentType<{ initialValues: {} }>;
  renderSecondary?: React.ComponentType<{ initialValues: {} }>;
  onClose?: () => void;
  showCloseButton?: boolean;
  onResetSkipPagination?: boolean;
  decorators?: any[]; // Decorator[]
  searchButton?: React.ComponentType<{}>;
};

const SearchForm = ({
  initialValues,
  onSubmit,
  renderPrimary: PrimarySearchFields,
  renderSecondary: SecondarySearchFields,
  decorators,
  searchButton: SearchButton = DefaultSearchButton,
  onClose,
  showCloseButton,
  onResetSkipPagination
}: SearchFormProps) => (
  <Form
    initialValues={initialValues}
    onSubmit={(params: SearchParams) =>
      onSubmit({
        ...params,
        ...resetPaginationParams(initialValues)
      })
    }
    decorators={decorators}
  >
    <Wrapper>
      <PrimarySearchFields initialValues={initialValues} />
      {SecondarySearchFields && (
        <Flex mb={4} alignItems="center">
          <SearchModalForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            searchButton={SearchButton}
          >
            <>
              <PrimarySearchFields initialValues={initialValues} />
              <SecondarySearchFields initialValues={initialValues} />
            </>
          </SearchModalForm>
        </Flex>
      )}
    </Wrapper>
    <Flex justifyContent="flex-start">
      {showCloseButton && (
        <Button variant="red" onClick={onClose} mr={2}>
          <Trans>Close</Trans>
        </Button>
      )}
      <Box px={1}>
        <SearchButton />
      </Box>
      {!isEmpty(initialValues.filter) && (
        <Box px={1}>
          <Button
            icon={RemoveItemIcon}
            type="reset"
            variant="link"
            px={1}
            onClick={() => {
              onSubmit({
                ...(onResetSkipPagination
                  ? omit(initialValues, ["first", "last", "after", "before"])
                  : initialValues),
                filter: {},
                searchRequest: null
              });
            }}
          >
            <Trans>Reset</Trans>
          </Button>
        </Box>
      )}
    </Flex>
  </Form>
);

const DefaultSearchButton = () => (
  <Button variant="blue">
    <Trans>Search</Trans>
  </Button>
);

const Wrapper = system(
  {},
  {
    position: "relative"
  }
);

export default SearchForm;
