import React from "react";
import { gql } from "graphql-tag";
import { Box, Text } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { getFullName } from "@edenlabllc/ehealth-utils";
import { Party as TParty } from "@ehealth/ehealth-ua.schema";

import Table from "../../../../components/Table";

export const Party = ({ party }: { party: TParty }) => {
  const headerProps = {
    fontSize: 14,
    fontWeight: "bold",
    color: "darkCharcoal"
  };

  return (
    <>
      <Text {...headerProps}>
        <Trans>Party</Trans>
      </Text>
      <Box mt={-4} mb={20}>
        <Table
          hidePagination
          hideControls
          data={[party]}
          header={{
            id: <Trans>Party ID</Trans>,
            fullName: <Trans>Party full name</Trans>
          }}
          renderRow={(item: TParty) => ({
            id: item.databaseId,
            fullName: getFullName(item)
          })}
        />
      </Box>
    </>
  );
};

Party.fragments = {
  entry: gql`
    fragment Party on Party {
      id
      databaseId
      firstName
      lastName
      secondName
    }
  `
};
