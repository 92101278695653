import React from "react";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Flex, Text } from "@rebass/emotion";
import get from "lodash/get";

import { Form, Validations, Validation } from "@edenlabllc/ehealth-components";
import { Address, Dictionary } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../../components/DictionaryValue";
import DistrictField from "../../../../components/SearchField/DistrictField";
import * as Field from "../../../../components/Field";
import RegionField from "../../../../components/SearchField/RegionField";
import SettlementField from "../../../../components/SearchField/SettlementField";
import StreetField from "../../../../components/SearchField/StreetField";

import {
  ZIP_PATTERN,
  BUILDING_PATTERN
} from "../../../../constants/validationPatterns";

type AddressesFormProps = {
  name: string;
  fieldsProps: {
    addresses: Address[];
  };
};

const AddressesForm = ({ name, fieldsProps }: AddressesFormProps) => {
  const { i18n } = useLingui();

  return (
    <Flex flexWrap="wrap" justifyContent="flex-start">
      <Form.Spy>
        {({ values }: $TSFixMe) => {
          const currentValue = get(values, name);

          const currentNumber =
            currentValue && currentValue.identifier
              ? currentValue.identifier.index
              : fieldsProps.addresses.length + 1;

          return (
            <Box width={1} mb={2} fontSize={1}>
              <Text>
                <Trans>Address</Trans> №{currentNumber}
              </Text>
            </Box>
          );
        }}
      </Form.Spy>
      <Box width={0.3} mr={2}>
        <DictionaryValue
          name="ADDRESS_TYPE"
          render={(dict: Dictionary["values"]) => (
            <Field.Select
              variant="select"
              name={`${name}.type`}
              label={<Trans id="Type" />}
              placeholder={i18n._(t`Select option`)}
              items={Object.keys(dict)}
              filterOptions={{ keys: [(item: string) => dict[item]] }}
              itemToString={(item: string) => dict[item] || item}
            />
          )}
        />
        <Validations field={`${name}.type`}>
          <Validation.Required message="Required field" />
          <Validation.Custom
            options={({ value, allValues: { addresses } }: $TSFixMe) => {
              const duplicates = addresses.filter((address: Address) => {
                return (
                  address && address.type === value && value === "RESIDENCE"
                );
              });

              return duplicates.length >= 2 ? false : true;
            }}
            message={i18n._(t`Address type residence is used more than once`)}
          />
        </Validations>
      </Box>
      <Box width={1 / 4} mr={2}>
        <DictionaryValue
          name="COUNTRY"
          render={(dict: Dictionary["values"]) => (
            <Field.Select
              name={`${name}.country`}
              label={<Trans id="Country" />}
              placeholder={i18n._(t`Select option`)}
              items={Object.keys(dict)}
              filterOptions={{ keys: [(item: string) => dict[item]] }}
              itemToString={(item: string) => dict[item] || item}
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field={`${name}.country`}
              message="Required field"
            />
          )}
        />
      </Box>
      <Box mr={2}>
        <Trans
          id="Enter zip code"
          render={({ translation }) => (
            <Field.Text
              name={`${name}.zip`}
              label={<Trans id="ZIP code" />}
              placeholder={translation}
              maxLength={5}
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field={`${name}.zip`}
              message="Required field"
            />
          )}
        />
        <Trans
          id="Invalid format"
          render={({ translation }) => (
            <Validation.Matches
              field={`${name}.zip`}
              options={ZIP_PATTERN}
              message={translation}
            />
          )}
        />
      </Box>
      <Box width={1 / 3} mr={2}>
        <RegionField name={`${name}.area`} />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field={`${name}.area`}
              message="Required field"
            />
          )}
        />
      </Box>
      <Box width={1 / 3} mr={2}>
        <DistrictField name={`${name}.region`} regionName={`${name}.area`} />
      </Box>
      <Box width={1}>
        <Flex>
          <Box width={1 / 4} mr={2}>
            <DictionaryValue
              name="SETTLEMENT_TYPE"
              render={(dict: Dictionary["values"]) => (
                <Field.Select
                  variant="select"
                  name={`${name}.settlementType`}
                  label={<Trans id="Settlement type" />}
                  placeholder={i18n._(t`Select option`)}
                  items={Object.keys(dict)}
                  filterOptions={{ keys: [(item: string) => dict[item]] }}
                  itemToString={(item: string) => dict[item] || item}
                />
              )}
            />
            <Trans
              id="Required field"
              render={() => (
                <Validation.Required
                  field={`${name}.settlementType`}
                  message="Required field"
                />
              )}
            />
          </Box>
          <Box width={1 / 3} mr={2}>
            <SettlementField
              name={`${name}.settlement`}
              regionName={`${name}.area`}
              districtName={`${name}.region`}
            />
            <Trans
              id="Required field"
              render={() => (
                <Validation.Required
                  field={`${name}.settlement`}
                  message="Required field"
                />
              )}
            />
          </Box>
        </Flex>
      </Box>
      <Box width={1}>
        <Flex>
          <Box width={1 / 4} mr={2}>
            <DictionaryValue
              name="STREET_TYPE"
              render={(dict: Dictionary["values"]) => (
                <Field.Select
                  variant="select"
                  name={`${name}.streetType`}
                  label={<Trans id="Street type" />}
                  placeholder={i18n._(t`Select option`)}
                  items={Object.keys(dict)}
                  filterOptions={{ keys: [(item: string) => dict[item]] }}
                  itemToString={(item: string) => dict[item] || item}
                />
              )}
            />
            <Trans
              id="Required field"
              render={() => (
                <Validation.Required
                  field={`${name}.streetType`}
                  message="Required field"
                />
              )}
            />
          </Box>
          <Box width={1 / 3} mr={2}>
            <StreetField
              name={`${name}.street`}
              streetTypeName={`${name}.streetType`}
              rootName={name}
            />
            <Trans
              id="Required field"
              render={() => (
                <Validation.Required
                  field={`${name}.street`}
                  message="Required field"
                />
              )}
            />
          </Box>
          <Box width={100} mr={2}>
            <Trans
              id="Enter building"
              render={({ translation }) => (
                <Field.Text
                  name={`${name}.building`}
                  label={<Trans id="Building" />}
                  placeholder={translation}
                  maxLength={100}
                />
              )}
            />
            <Trans
              id="Required field"
              render={() => (
                <Validation.Required
                  field={`${name}.building`}
                  message="Required field"
                />
              )}
            />
            <Trans
              id="Invalid format"
              render={({ translation }) => (
                <Validation.Matches
                  field={`${name}.building`}
                  options={BUILDING_PATTERN}
                  message={translation}
                />
              )}
            />
          </Box>
          <Box width={100} mr={2}>
            <Trans
              id="Enter apartment"
              render={({ translation }) => (
                <Field.Text
                  name={`${name}.apartment`}
                  label={<Trans id="apartment" />}
                  placeholder={translation}
                  maxLength={100}
                />
              )}
            />
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default AddressesForm;
