import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, isEmpty } from "lodash";

import {
  MedicalProgram,
  MedicalProgramConnection
} from "@ehealth/ehealth-ua.schema";

import * as Field from "../Field";
import onlyValidKeys from "../../helpers/onlyValidKeys";

type SearchMedicalProgramFieldProps = {
  name: string;
  filteredParams: string[];
  additionalFilters?: object;
};

const SearchMedicalProgramField = ({
  name,
  filteredParams,
  additionalFilters
}: SearchMedicalProgramFieldProps) => (
  <Trans
    id="Choose medical program"
    render={({ translation }) => (
      <Query
        query={MedicalProgramsQuery}
        fetchPolicy="cache-first"
        variables={{
          skip: true
        }}
      >
        {({
          data,
          refetch: refetchMedicalPrograms
        }: QueryResult<{ medicalPrograms: MedicalProgramConnection }>) => {
          const { medicalPrograms: { nodes: medicalPrograms = [] } = {} } =
            data || {};
          const itemToString = (item: MedicalProgram) => item && item.name;
          const filteredItems = medicalPrograms!.map((item) =>
            onlyValidKeys(item, filteredParams)
          );

          return (
            <Field.Select
              name={name}
              label={<Trans id="Medical program" />}
              placeholder={translation}
              items={filteredItems}
              itemToString={itemToString}
              filter={(medicalPrograms: MedicalProgram[]) => medicalPrograms}
              onInputValueChange={debounce(
                (name, { selectedItem, inputValue }) =>
                  !isEmpty(name) &&
                  itemToString(selectedItem) !== inputValue &&
                  refetchMedicalPrograms({
                    skip: false,
                    first: 20,
                    filter: {
                      ...additionalFilters,
                      name
                    }
                  }),
                300
              )}
            />
          );
        }}
      </Query>
    )}
  />
);

const MedicalProgramsQuery = gql`
  query MedicalProgramsQuery(
    $first: Int
    $filter: MedicalProgramFilter
    $skip: Boolean! = false
  ) {
    medicalPrograms(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        databaseId
        name
      }
    }
  }
`;

export default SearchMedicalProgramField;
