import React from "react";
import Composer from "react-composer";
import { Trans } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Flex, Box } from "@rebass/emotion";
import { Dictionary } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 4}>
      <Trans
        id="Enter name"
        render={({ translation }) => (
          <Field.Text
            name="filter.name"
            label={<Trans id="Search by name" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <Trans
        id="Enter code"
        render={({ translation }) => (
          <Field.Text
            name="filter.code"
            label={<Trans id="Search by code" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 4}>
      <SearchField.Status name="filter.isActive" status="ACTIVE_STATUS_M" />
    </Box>
    <Box px={1} width={1 / 4}>
      <Composer
        components={[
          <DictionaryValue name="SERVICE_CATEGORY" />,
          ({
            render
          }: {
            render: (props: TransRenderProps) => React.ReactElement;
          }) => <Trans id="Select option" render={render} />
        ]}
      >
        {([dict, { translation }]: [
          Dictionary["values"],
          { translation: React.ReactNode }
        ]) => (
          <Field.Select
            name="filter.category"
            label={<Trans id="Category" />}
            placeholder={translation}
            items={Object.keys(dict)}
            itemToString={(item: string) => dict[item] || translation}
            variant="select"
            emptyOption
            filterOptions={{ keys: [(item: string) => dict[item]] }}
          />
        )}
      </Composer>
    </Box>
  </Flex>
);

export { PrimarySearchFields };
