import React from "react";
import { ConjsProps } from "react-awesome-query-builder";
import { Box, Flex } from "@rebass/emotion";

import * as Field from "../../../../../components/Field";

export default ({
  id,
  not,
  setNot,
  conjunctionOptions,
  setConjunction,
  disabled,
  readonly,
  config,
  showNot,
  notLabel
}: ConjsProps) => {
  const conjsCount = conjunctionOptions
    ? Object.keys(conjunctionOptions).length
    : 0;
  const forceShowConj =
    config && config.settings && config.settings.forceShowConj;
  const showConj = forceShowConj || (conjsCount > 1 && !disabled);

  const renderOptions = () =>
    conjunctionOptions &&
    Object.keys(conjunctionOptions).map((key) => {
      const { id, key: name, label, checked } = conjunctionOptions[key];
      if ((readonly || disabled) && !checked) return null;

      return (
        <Box mr={3} key={key}>
          <Field.RadioField
            name={name}
            id={id}
            selected={checked}
            disabled={readonly || disabled}
            value={key}
            onChange={onChange}
          >
            {label}
          </Field.RadioField>
        </Box>
      );
    });

  const renderNot = () => {
    const postfix = "not";
    return (
      <Box mr={3}>
        <Field.CheckboxFieldView
          id={id + postfix}
          checked={not}
          disabled={readonly}
          onChange={onNotChange}
          label={notLabel || "NOT"}
        />
      </Box>
    );
  };

  const onChange = (val: string) => setConjunction(val);

  const onNotChange = (e: $TSFixMe) => setNot(e.nativeEvent.target.checked);

  return (
    <Flex>
      {showNot && renderNot()}
      {showConj && <Flex>{renderOptions()}</Flex>}
    </Flex>
  );
};
