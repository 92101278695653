import * as React from "react";
import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/emotion";

import { DropDownButton } from "@edenlabllc/ehealth-icons";
import { checkLastInList } from "@edenlabllc/ehealth-utils";

import Dropdown from "../Dropdown";

type HeaderDataWithStatus = {
  name: string;
  status: boolean;
  title: string;
};

type TableDropDownControllType = {
  onChange: (name: string) => void;
  data: Array<HeaderDataWithStatus | any>;
  columnKeyExtractor: (name: string, index: number) => React.Key;
  buttonComponent?: React.ElementType;
  buttonContent?: React.ElementType;
  description?: string;
};

type TableDropdownType = {
  data: Array<HeaderDataWithStatus | any>;
  onChange: (name: string) => void;
  columnKeyExtractor: (name: string, index: number) => React.Key;
};

const TableDropDownControll = ({
  onChange,
  data,
  columnKeyExtractor = (name: string) => name,
  buttonComponent: ButtonDropDownWrapper = Box,
  buttonContent: ButtonContent = Icon,
  description = "Додати параметр"
}: TableDropDownControllType) => (
  <ButtonDropDownWrapper>
    <details>
      <ButtonContent>
        <Flex>
          <DropDownButton color="#2EA2F8" />
          <Description>{description}</Description>
        </Flex>
      </ButtonContent>

      <TableDropdown
        data={data}
        onChange={onChange}
        columnKeyExtractor={columnKeyExtractor}
      />
    </details>
  </ButtonDropDownWrapper>
);

const TableDropdown = ({
  data,
  onChange,
  columnKeyExtractor
}: TableDropdownType) => (
  <List>
    {data.map(
      ({ title, name, status }: HeaderDataWithStatus, index: number) => (
        <Dropdown.Item
          // @ts-ignore
          on={status}
          onClick={
            checkLastInList(data, name) ? () => onChange(name) : () => {}
          }
          key={columnKeyExtractor(name, index)}
        >
          {title}
          {status && <Dropdown.Icon />}
        </Dropdown.Item>
      )
    )}
  </List>
);

const Icon = styled.summary`
  list-style-type: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const Description = styled.span`
  margin-left: 10px;
  color: #2ea2f8;
  font-size: 12px;
  font-weight: 700;
`;

const List = styled(Dropdown.List)`
  position: absolute;
  left: auto;
  right: 0;
  margin-top: 10px;
  z-index: 50;
`;

export default TableDropDownControll;
