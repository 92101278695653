import React from "react";
import Composer from "react-composer";
import { TransRenderProps, useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Validation, Validations } from "@edenlabllc/ehealth-components";
import {
  ConfidantPerson,
  Dictionary,
  Person
} from "@ehealth/ehealth-ua.schema";

import * as Field from "../../../../components/Field";
import DictionaryValue from "../../../../components/DictionaryValue";

import {
  UNZR_PATTERN,
  SECRET_WORD_PATTERN,
  EMAIL_PATTERN
} from "../../../../constants/validationPatterns";

import PhonesForm from "./PhonesForm";
import DocumentsForm from "./DocumentsForm";

type ConfidantPersonsFormProps = {
  name: string;
  person: Person;
};

const ConfidantPersonsForm = ({ name }: ConfidantPersonsFormProps) => {
  const { i18n } = useLingui();

  return (
    <Flex flexWrap="wrap" justifyContent="flex-start">
      <Box width={1}>
        <Box width={0.45} mr={2}>
          <DictionaryValue
            name="CONFIDANT_PERSON_TYPE"
            render={(dict: Dictionary["values"]) => (
              <Field.Select
                variant="select"
                name={`${name}.relationType`}
                label={<Trans id="Relation type" />}
                placeholder={i18n._(t`Select option`)}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || item}
              />
            )}
          />
          <Validations field={`${name}.relationType`}>
            <Validation.Required message="Required field" />
            <Validation.Custom
              options={({
                value,
                allValues: { confidantPersons }
              }: $TSFixMe) => {
                if (
                  isEmpty(confidantPersons) ||
                  !name.includes("confidantPersons")
                )
                  return true;
                const duplicates = confidantPersons.filter(
                  (confidantPerson: ConfidantPerson) =>
                    confidantPerson && confidantPerson.relationType === value
                );
                return duplicates.length >= 2 ? false : true;
              }}
              message={i18n._(
                t`This confidant person type is used more than once`
              )}
            />
          </Validations>
        </Box>
      </Box>
      <Box width={0.45} mr={2}>
        <Trans
          id="Enter first name"
          render={({ translation }) => (
            <Field.Text
              name={`${name}.firstName`}
              label={<Trans id="First name" />}
              placeholder={translation}
              maxLength={100}
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field={`${name}.firstName`}
              message="Required field"
            />
          )}
        />
      </Box>
      <Box width={0.45} mr={2}>
        <Trans
          id="Enter last name"
          render={({ translation }) => (
            <Field.Text
              name={`${name}.lastName`}
              label={<Trans id="Last name" />}
              placeholder={translation}
              maxLength={100}
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field={`${name}.lastName`}
              message="Required field"
            />
          )}
        />
      </Box>
      <Box width={0.45} mr={2}>
        <Trans
          id="Enter second name"
          render={({ translation }) => (
            <Field.Text
              name={`${name}.secondName`}
              label={<Trans id="Second name" />}
              placeholder={translation}
              maxLength={100}
            />
          )}
        />
      </Box>
      <Box width={1 / 4} mr={2}>
        <DictionaryValue
          name="GENDER"
          render={(dict: Dictionary["values"]) => (
            <Field.Select
              name={`${name}.gender`}
              label={<Trans id="Gender" />}
              placeholder={i18n._(t`Select option`)}
              items={Object.keys(dict)}
              itemToString={(item: string) => dict[item] || item}
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field={`${name}.gender`}
              message="Required field"
            />
          )}
        />
      </Box>
      <Box width={1 / 4} mr={2}>
        <Field.DatePicker
          name={`${name}.birthDate`}
          label={<Trans id="Date of birthdate" />}
          minDate="1900-01-01"
          maxDate={Date.now()}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field={`${name}.birthDate`}
              message="Required field"
            />
          )}
        />
      </Box>
      <Box width={1 / 4} mr={2}>
        <Trans
          id="Enter country of birth"
          render={({ translation }) => (
            <Field.Text
              name={`${name}.birthCountry`}
              label={<Trans id="Country of birth" />}
              placeholder={translation}
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field={`${name}.birthCountry`}
              message="Required field"
            />
          )}
        />
      </Box>
      <Box width={1 / 4} mr={2}>
        <Trans
          id="Enter birth settlement"
          render={({ translation }) => (
            <Field.Text
              name={`${name}.birthSettlement`}
              label={<Trans id="Birth settlement" />}
              placeholder={translation}
              maxLength={100}
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field={`${name}.birthSettlement`}
              message="Required field"
            />
          )}
        />
      </Box>
      <Box width={1 / 4} mr={2}>
        <Trans
          id="Enter secret word"
          render={({ translation }) => (
            <Field.Text
              name={`${name}.secret`}
              label={<Trans id="Secret word" />}
              placeholder={translation}
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field={`${name}.secret`}
              message="Required field"
            />
          )}
        />
        <Trans
          id="Invalid format"
          render={({ translation }) => (
            <Validation.Matches
              field={`${name}.secret`}
              options={SECRET_WORD_PATTERN}
              message={translation}
            />
          )}
        />
      </Box>
      <Box width={0.45} mr={2}>
        <Trans
          id="Enter email"
          render={({ translation }) => (
            <Field.Text
              name={`${name}.email`}
              label={<Trans id="email" />}
              placeholder={translation}
            />
          )}
        />
        <Trans
          id="Invalid format"
          render={({ translation }) => (
            <Validation.Matches
              field={`${name}.email`}
              options={EMAIL_PATTERN}
              message={translation}
            />
          )}
        />
      </Box>
      <Box width={1 / 4} mr={2}>
        <Composer
          components={[
            <DictionaryValue name="PREFERRED_WAY_COMMUNICATION" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="Select option" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            Dictionary["values"],
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name={`${name}.preferredWayCommunication`}
              label={<Trans id="Preferred way communication" />}
              placeholder={translation}
              items={Object.keys(dict)}
              itemToString={(item: string) => dict[item] || translation}
              variant="select"
            />
          )}
        </Composer>
      </Box>
      <Box width={0.45} mr={2}>
        <Trans
          id="Enter tax ID"
          render={({ translation }) => (
            <Field.Text
              name={`${name}.taxId`}
              label={<Trans id="Tax ID" />}
              placeholder={translation}
            />
          )}
        />
      </Box>
      <Box width={0.45} mr={2}>
        <Trans
          id="UNZR"
          render={({ translation }) => (
            <Field.Text
              name={`${name}.unzr`}
              label={<Trans id="Enter unzr" />}
              placeholder={translation}
            />
          )}
        />
        <Trans
          id="Invalid format"
          render={({ translation }) => (
            <Validation.Matches
              field={`${name}.unzr`}
              options={UNZR_PATTERN}
              message={translation}
            />
          )}
        />
      </Box>
      <Box width={1} mb={6}>
        <Field.Array
          name={`${name}.phones`}
          addText={<Trans>Add phone</Trans>}
          removeText={<Trans>Delete phone</Trans>}
          fields={PhonesForm}
          vertical
        />
      </Box>
      <Box width={1} mb={6}>
        <Field.Array
          name={`${name}.documents`}
          addText={<Trans>Add document</Trans>}
          removeText={<Trans>Delete document</Trans>}
          fields={DocumentsForm}
          vertical
          fieldsProps={{ documentsListName: <Trans>Documents</Trans> }}
        />
      </Box>
      <Box width={1} mb={6}>
        <Field.Array
          name={`${name}.relationshipDocuments`}
          addText={<Trans>Add relationship document</Trans>}
          removeText={<Trans>Delete relationship document</Trans>}
          fields={DocumentsForm}
          vertical
          fieldsProps={{
            documentsListName: <Trans>Relationship Documents</Trans>
          }}
        />
      </Box>
    </Flex>
  );
};

export default ConfidantPersonsForm;
