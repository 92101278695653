import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import {
  PersonsAuthResetJob,
  PersonsAuthResetTask,
  Scalars
} from "@ehealth/ehealth-ua.schema";

import Badge from "../../components/Badge";
import Breadcrumbs from "../../components/Breadcrumbs";
import DefinitionListView from "../../components/DefinitionListView";
import EmptyData from "../../components/EmptyData";
import LoadingOverlay from "../../components/LoadingOverlay";
import Pagination from "../../components/Pagination";
import {
  SearchParams,
  SetSearchParams,
  TLocationParams
} from "../../components/SearchForm";
import Tabs from "../../components/Tabs";
import TasksTable from "../../components/TasksTable";

import filteredLocationParams from "../../helpers/filteredLocationParams";

type DetailsProps = RouteComponentProps<{
  id?: Scalars["ID"];
}>;

const Details = ({ id }: DetailsProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => (
      <Query
        query={ResetPersonsAuthMethodJobQuery}
        variables={{ id, ...filteredLocationParams(locationParams) }}
      >
        {({
          loading,
          data
        }: QueryResult<{ personsAuthResetJob: PersonsAuthResetJob }>) => {
          if (isEmpty(data) || isEmpty(data.personsAuthResetJob)) return null;
          const { databaseId, status, tasks } = data.personsAuthResetJob;

          return (
            <LoadingOverlay loading={loading}>
              <Box p={6}>
                <Box py={10}>
                  <Breadcrumbs.List>
                    <Breadcrumbs.Item to="/reset-persons-auth-method-jobs">
                      <Trans>Reset persons authentication method</Trans>
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item>
                      <Trans>Job details</Trans>
                    </Breadcrumbs.Item>
                  </Breadcrumbs.List>
                </Box>
                <DefinitionListView
                  labels={{
                    databaseId: <Trans>Job ID</Trans>,
                    status: <Trans>Status</Trans>
                  }}
                  data={{
                    databaseId,
                    status: <Badge type="JOBS" name={status} minWidth={100} />
                  }}
                  color="#7F8FA4"
                  labelWidth="140px"
                />
              </Box>
              <Tabs.Nav>
                <Tabs.NavItem to="./">
                  <Trans>Job details</Trans>
                </Tabs.NavItem>
              </Tabs.Nav>
              <Tabs.Content>
                <Router>
                  <Tasks
                    path="/"
                    tasks={tasks}
                    locationParams={locationParams}
                    setLocationParams={setLocationParams}
                  />
                </Router>
              </Tabs.Content>
            </LoadingOverlay>
          );
        }}
      </Query>
    )}
  </LocationParams>
);

type TasksProps = RouteComponentProps & {
  tasks: PersonsAuthResetJob["tasks"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const Tasks = ({ tasks, locationParams, setLocationParams }: TasksProps) => {
  const { nodes, pageInfo } = tasks || {};
  if (isEmpty(nodes)) return <EmptyData />;

  return (
    <>
      <TasksTable
        tasks={nodes!}
        locationParams={locationParams}
        setLocationParams={setLocationParams}
        taskName={({ meta }: { meta?: PersonsAuthResetTask["meta"] }) => {
          const { id } = meta || {};
          return (
            <>
              <Trans>Reset authentication method for person with id</Trans> {id}
            </>
          );
        }}
        tableName="reset-persons-auth-method-jobs-table/tasks"
      />
      <Pagination {...pageInfo} />
    </>
  );
};

const ResetPersonsAuthMethodJobQuery = gql`
  query ResetPersonsAuthMethodJobQuery(
    $id: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: TaskFilter
    $orderBy: TaskOrderBy
  ) {
    personsAuthResetJob(id: $id) {
      id
      databaseId
      status
      tasks(
        first: $first
        last: $last
        before: $before
        after: $after
        filter: $filter
        orderBy: $orderBy
      ) {
        nodes {
          id
          databaseId
          name
          priority
          status
          insertedAt
          endedAt
          updatedAt
          meta {
            id
          }
          error {
            message
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${Pagination.fragments.entry}
`;

export default Details;
