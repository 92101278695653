import React, { useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Box, Flex, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { getFullName } from "@edenlabllc/ehealth-utils";
import { Form, LocationParams } from "@edenlabllc/ehealth-components";
import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  Party,
  PartyDracsDeathCandidateConnection
} from "@ehealth/ehealth-ua.schema";

import Button from "../../../../components/Button";
import DefinitionListView from "../../../../components/DefinitionListView";
import EmptyData from "../../../../components/EmptyData";
import * as Field from "../../../../components/Field";
import Line from "../../../../components/Line";
import Link from "../../../../components/Link";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import Pagination from "../../../../components/Pagination";
import Steps from "../../../../components/Steps";
import { TLocationParams } from "../../../../components/SearchForm";
import Table from "../../../../components/Table";

import dateFormatter from "../../../../helpers/dateFormatter";
import pagination from "../../../../helpers/pagination";
import { ITEMS_PER_PAGE } from "../../../../constants/pagination";

import DracsDeathActPopup from "../../../Persons/Details/DracsDeathActPopup";
import { PartyQuery } from "../index";
import ComparisonPopup from "./ComparisonPopup";
import Verify from "./Verify";

type ProcessDracsProps = RouteComponentProps<{
  id: string;
}>;

const ProcessDracs = ({
  id,
  // @ts-expect-error location state
  location: { state, pathname }
}: ProcessDracsProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <LocationParams>
      {({ locationParams }: TLocationParams) => (
        <Query
          query={PartyQuery}
          variables={{
            id,
            ...pagination(locationParams)
          }}
        >
          {({ loading, data }: QueryResult<{ party: Party }>) => {
            if (isEmpty(data) || isEmpty(data.party)) return null;
            const { databaseId, firstName, secondName, lastName } = data.party;

            const fullName = getFullName({
              firstName,
              secondName,
              lastName
            });

            return (
              <LoadingOverlay loading={loading}>
                <Box pt={5} px={5}>
                  <Steps.List>
                    <Steps.Item to="./" state={state}>
                      <Trans>Choose death act</Trans>
                    </Steps.Item>
                    <Steps.Item to="./confirm" state={state} disabled>
                      <Trans>Confirm data</Trans>
                    </Steps.Item>
                  </Steps.List>

                  <DefinitionListView
                    labels={{
                      fullName: <Trans>Full name</Trans>,
                      partyId: <Trans>ID</Trans>,
                      deathAct: <Trans>Death act</Trans>
                    }}
                    data={{
                      fullName,
                      partyId: databaseId,
                      ...(state &&
                        !!state.deathAct && {
                          deathAct: (
                            <Button
                              variant="link"
                              onClick={toggle}
                              px={0}
                              py={0}
                            >
                              {state.deathAct}
                            </Button>
                          )
                        })
                    }}
                    labelWidth="200px"
                  />

                  {state && !!state.deathAct && (
                    <DracsDeathActPopup
                      deathActId={state.deathAct}
                      isPopupVisible={isPopupVisible}
                      toggle={toggle}
                    />
                  )}

                  {!pathname.includes("confirm") && (
                    <Text mt={5} fontSize={14}>
                      <Trans>
                        Select a match from the list to compare with the party's
                        personal information
                      </Trans>
                    </Text>
                  )}
                  <Line />

                  <Router>
                    <CandidatesList path="/" party={data && data.party} />
                    <Confirmation path="/confirm" />
                  </Router>
                </Box>
              </LoadingOverlay>
            );
          }}
        </Query>
      )}
    </LocationParams>
  );
};

type CandidatesListProps = RouteComponentProps & {
  party: Party;
};

const CandidatesList = ({
  navigate,
  // @ts-expect-error location state
  location: { state },
  party
}: CandidatesListProps) => {
  const [isComparisonPopupVisible, setComparisonPopupVisible] = useState(false);
  const [activePopupDeatchActId, setActivePopupDeatchActId] = useState(null);

  return (
    <>
      <Query
        query={PartyDracsDeathCandidatesQuery}
        variables={{
          first: ITEMS_PER_PAGE[0],
          filter: {
            partyId: party.databaseId,
            status: "NEW"
          }
        }}
        fetchPolicy="network-only"
      >
        {({
          loading,
          data
        }: QueryResult<{
          partyDracsDeathCandidates: PartyDracsDeathCandidateConnection;
        }>) => {
          if (isEmpty(data) || isEmpty(data.partyDracsDeathCandidates))
            return null;
          const {
            partyDracsDeathCandidates: {
              nodes: partyDracsDeathCandidates = [],
              pageInfo
            }
          } = data;

          return (
            <LoadingOverlay loading={loading}>
              <Form
                initialValues={state}
                onSubmit={async (data: {
                  deathAct: { [id: string]: boolean };
                }) => {
                  const { deathAct } = data;

                  const deathActId = Object.keys(deathAct || {}).filter(
                    (key) => deathAct[key]
                  )[0];

                  navigate!("./confirm", {
                    state: {
                      ...state,
                      ...(!!deathActId && {
                        deathAct: deathActId
                      })
                    }
                  });
                }}
              >
                <Form.Spy>
                  {({ values: { deathAct } }: $TSFixMe) => {
                    const choosenCandidates =
                      deathAct &&
                      Object.keys(deathAct).filter((key) => deathAct[key]);

                    return (
                      <>
                        {partyDracsDeathCandidates &&
                        partyDracsDeathCandidates.length < 1 ? (
                          <EmptyData height="auto" />
                        ) : (
                          <>
                            <Table
                              hidePagination
                              hideControls
                              data={partyDracsDeathCandidates}
                              tableName="partyDracsDeathCandidates"
                              header={{
                                action: <Trans>Choose</Trans>,
                                candidateId: <Trans>Candidate ID</Trans>,
                                score: <Trans>Сoincidence</Trans>,
                                insertedAt: <Trans>Inserted at</Trans>,
                                updatedAt: <Trans>Updated at</Trans>,
                                details: <Trans>Details</Trans>
                              }}
                              renderRow={({
                                databaseId,
                                score,
                                insertedAt,
                                updatedAt,
                                deathAct
                              }) => {
                                return {
                                  action: (
                                    <Field.Checkbox
                                      name={`deathAct.${
                                        deathAct && deathAct.databaseId
                                      }`}
                                      disabled={
                                        choosenCandidates &&
                                        choosenCandidates[0] &&
                                        deathAct.databaseId !==
                                          choosenCandidates[0]
                                      }
                                    />
                                  ),
                                  candidateId: databaseId,
                                  score,
                                  insertedAt: dateFormatter(
                                    i18n.locale,
                                    {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric"
                                    },
                                    insertedAt
                                  ),
                                  updatedAt: dateFormatter(
                                    i18n.locale,
                                    {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric"
                                    },
                                    updatedAt
                                  ),
                                  details: (
                                    <Link
                                      to="./"
                                      onClick={() => {
                                        setActivePopupDeatchActId(deathAct.id);
                                        setComparisonPopupVisible(true);
                                      }}
                                      fontWeight="bold"
                                    >
                                      <Trans>Show details</Trans>
                                    </Link>
                                  )
                                };
                              }}
                            />
                            <Pagination {...pageInfo} />
                          </>
                        )}

                        <Flex pt={5} mb={100}>
                          <Box mr={3}>
                            <Button
                              type="reset"
                              variant="blue"
                              width={140}
                              onClick={() => navigate!("../", { state })}
                            >
                              <Trans>Back</Trans>
                            </Button>
                          </Box>
                          <Box>
                            <Button variant="green" width={140}>
                              <Trans>Next</Trans>
                            </Button>
                          </Box>
                        </Flex>

                        {isComparisonPopupVisible && (
                          <ComparisonPopup
                            isVisible={isComparisonPopupVisible}
                            onCancel={() => setComparisonPopupVisible(false)}
                            party={party}
                            deathActId={activePopupDeatchActId}
                          />
                        )}
                      </>
                    );
                  }}
                </Form.Spy>
              </Form>
            </LoadingOverlay>
          );
        }}
      </Query>
    </>
  );
};

type ConfirmationProps = RouteComponentProps<{
  id: string;
}>;

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state },
  id
}: ConfirmationProps) => {
  const [isStartVerifyModalVisible, setVerifyModalVisible] = useState(false);

  return (
    <Form>
      <Form.Spy>
        {({
          values: { verificationComment } = { verificationComment: undefined }
        }: $TSFixMe) => (
          <>
            <Flex>
              {!!state.deathAct ? <PositiveIcon /> : <NegativeIcon />}
              <Text ml={2} mb={5} fontSize={14}>
                <Trans>The death of the patient is confirmed</Trans>
              </Text>
            </Flex>
            <Box mb={2}>
              <Trans
                id="Enter reason comment"
                render={({ translation }) => (
                  <Field.Textarea
                    name="verificationComment"
                    label={<Trans id="Comment" />}
                    placeholder={translation}
                    rows={10}
                    maxLength={300}
                    showLengthHint
                  />
                )}
              />
            </Box>

            <Flex mt={5} justifyContent="space-between">
              <Box mr={3}>
                <Button
                  type="reset"
                  variant="blue"
                  width={140}
                  onClick={() =>
                    navigate!("../", {
                      state: {
                        deathAct: undefined
                      }
                    })
                  }
                >
                  <Trans>Back</Trans>
                </Button>
              </Box>
              <Box mb={3} ml={2}>
                <Button
                  width={220}
                  variant="green"
                  onClick={() => {
                    setVerifyModalVisible(true);
                  }}
                >
                  <Trans>Verify</Trans>
                </Button>
              </Box>
            </Flex>

            {isStartVerifyModalVisible && (
              <Verify
                isVisible={isStartVerifyModalVisible}
                onCancel={() => setVerifyModalVisible(false)}
                partyId={id!}
                navigate={navigate!}
                verificationComment={verificationComment}
                deathAct={state.deathAct}
              />
            )}
          </>
        )}
      </Form.Spy>
    </Form>
  );
};

const PartyDracsDeathCandidatesQuery = gql`
  query PartyDracsDeathCandidatesQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: PartyDracsDeathCandidateFilter
    $orderBy: PartyDracsDeathCandidateOrderBy
  ) {
    partyDracsDeathCandidates(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      nodes {
        id
        databaseId
        status
        insertedAt
        updatedAt
        score
        deathAct {
          id
          databaseId
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

export default ProcessDracs;
