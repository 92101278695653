import React from "react";
import { Box, Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { Validation } from "@edenlabllc/ehealth-components";

import * as Field from "../../components/Field";

type PatientSearchFieldProps = {
  isClinicalMonitoring: boolean;
};

export const PatientSearchField = ({
  isClinicalMonitoring
}: PatientSearchFieldProps) => {
  if (!isClinicalMonitoring) return null;

  return (
    <Flex>
      <Box px={1} width={1} mt={3}>
        <Trans
          id="Enter patient hash"
          render={({ translation }) => (
            <Field.Text
              name="filter.patientId"
              label={<Trans id="Patient hash" />}
              placeholder={translation}
            />
          )}
        />
        <Trans
          id="Required field"
          render={({ translation }) => (
            <Validation.Required
              field="filter.patientId"
              message={translation}
            />
          )}
        />
      </Box>
    </Flex>
  );
};
