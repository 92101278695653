import styled from "@emotion/styled";
import { ifProp, ifNotProp } from "styled-tools";

import { CheckRightIcon } from "@edenlabllc/ehealth-icons";

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  font-weight: ${ifProp("isSelected", "bold", "normal")};
  border-top: 1px solid #dfe3e9;
  border-left: ${ifProp("isSelected", "1px solid #2EA2F8")};
  color: ${ifProp("isSelected", "#354052")};
  color: ${ifProp("disabled", "#9299a3")};
  background: ${ifProp("isSelected", "#F1F4F8", "#fff")};
  user-select: none;
  cursor: pointer;
  text-align: left;

  &:first-of-type {
    border-top: none;
  }

  &:hover {
    color: ${ifNotProp("disabled", "#2ea2f8")};
    background: ${ifNotProp("disabled", "#f1f4f8")};
    cursor: ${ifProp("disabled", "auto")};
  }
`;

const List = styled.div`
  min-width: 200px;
  box-shadow: 0 2px 4px rgba(72, 60, 60, 0.2);
`;

const Icon = styled(CheckRightIcon)`
  flex-basis: 10px;
  width: 10px;
  height: 10px;
`;

const Dropdown = {
  List,
  Item,
  Icon
};

export default Dropdown;
