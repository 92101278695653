import React, { useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation, Query } from "@apollo/client/react/components";
import { DocumentNode, MutationFunction, QueryResult } from "@apollo/client";
import printIframe from "print-iframe";
import { BooleanValue } from "react-values";
import { loader } from "graphql.macro";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex, Box, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import system from "@edenlabllc/ehealth-system-components";
import {
  Form,
  LocationParams,
  Validation
} from "@edenlabllc/ehealth-components";
import {
  PrinterIcon,
  PositiveIcon,
  DefaultImageIcon,
  SearchIcon,
  NegativeIcon
} from "@edenlabllc/ehealth-icons";
import {
  getFullName,
  filterTableColumn as filterTableDefaultColumn,
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  Dictionary,
  Division,
  CapitationContract,
  ContractDocument,
  ContractorEmployeeDivision,
  ExternalContractor,
  ExternalContractorDivision,
  Maybe,
  Party,
  TerminateContractInput
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import AddressView from "../../../components/AddressView";
import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Button from "../../../components/Button";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import Line from "../../../components/Line";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";
import Table, {
  TableBodyComponent,
  TableRow,
  TableCell,
  SortingParams
} from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import Popup from "../../../components/Popup";
import Price from "../../../components/Price";
import { TableBodyType } from "../../../components/Table/TableBody";
import { TLocationParams } from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";
import { ITEMS_PER_PAGE } from "../../../constants/pagination";

const CapitationContractQuery = loader(
  "../../../graphql/CapitationContractQuery.graphql"
);
const TerminateContractMutation = loader(
  "../../../graphql/TerminateContractMutation.graphql"
);

const CapitationContractsDetails = (_props: RouteComponentProps) => (
  <Router>
    <Details path=":id/*" />
  </Router>
);

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

type DetailsStateType = {
  suspendPopup: boolean;
  terminatePopup: boolean;
};

const Details = ({ id, navigate }: DetailsProps) => {
  const [isVisible, setVisibilityState] = useState<DetailsStateType>({
    suspendPopup: false,
    terminatePopup: false
  });
  const toggle = (popup: "suspendPopup" | "terminatePopup") =>
    setVisibilityState({
      ...isVisible,
      [popup]: !isVisible[popup]
    });

  return (
    <Query
      query={CapitationContractQuery}
      variables={{ id, first: ITEMS_PER_PAGE[0] }}
    >
      {({
        loading,
        data
      }: QueryResult<{ capitationContract: CapitationContract }>) => {
        if (isEmpty(data) || isEmpty(data.capitationContract)) return null;

        const {
          isSuspended,
          databaseId,
          contractNumber,
          contractRequest,
          status,
          startDate,
          endDate,
          // @ts-expect-error types mismatch
          nhsSignerId,
          nhsSigner,
          nhsSignerBase,
          nhsContractPrice,
          nhsPaymentMethod,
          issueCity,
          printoutContent,
          contractorRmspAmount,
          contractorLegalEntity,
          contractorOwner,
          contractorBase,
          contractorPaymentDetails,
          contractorEmployeeDivisions,
          externalContractors = [],
          attachedDocuments,
          statusReason,
          reason
        } = data.capitationContract;

        return (
          <LoadingOverlay loading={loading}>
            <Box p={6}>
              <Box py={10}>
                <Breadcrumbs.List>
                  <Breadcrumbs.Item to="/contracts">
                    <Trans>List of contracts</Trans>
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item>
                    <Trans>Details of the contract</Trans>
                  </Breadcrumbs.Item>
                </Breadcrumbs.List>
              </Box>
              <Flex justifyContent="space-between">
                <Box>
                  <DefinitionListView
                    labels={{
                      databaseId: <Trans>Contract ID</Trans>,
                      contractRequestId: <Trans>Contract request ID</Trans>,
                      contractNumber: <Trans>Contract Number</Trans>,
                      status: <Trans>Status</Trans>,
                      isSuspended: <Trans>Contract state</Trans>
                    }}
                    data={{
                      databaseId,
                      contractRequestId: (
                        <Link
                          to={`/contract-requests/capitation/${
                            contractRequest && contractRequest.id
                          }`}
                        >
                          {contractRequest && contractRequest.databaseId}
                        </Link>
                      ),
                      contractNumber,
                      status: (
                        <Badge name={status} type="CONTRACT" minWidth={100} />
                      ),
                      isSuspended: (
                        <Flex alignItems="center">
                          <Badge
                            name={isSuspended}
                            type="SUSPENDED"
                            minWidth={100}
                          />
                          {status === "VERIFIED" && (
                            <ToggleSuspendContractModal
                              toggleSuspendContractMutation={
                                ToggleContractSuspendMutation
                              }
                              isSuspended={isSuspended}
                              isVisible={isVisible}
                              toggle={toggle}
                              formId="suspendContract"
                              contractId={id}
                            />
                          )}
                        </Flex>
                      )
                    }}
                    color="#7F8FA4"
                    labelWidth="120px"
                  />
                </Box>
                <Flex
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <PrintButton content={printoutContent} />
                  <Flex justifyContent="flex-end" flexWrap="wrap">
                    <Ability action="create" resource="contract_request">
                      {status === "VERIFIED" && !isSuspended && (
                        <Button
                          mt={2}
                          variant="blue"
                          onClick={() =>
                            navigate!(
                              `/contract-requests/capitation/create/${id}`,
                              {
                                state: {
                                  updateContract: { id }
                                }
                              }
                            )
                          }
                        >
                          <Trans>Update contract</Trans>
                        </Button>
                      )}
                    </Ability>
                    {status === "VERIFIED" && (
                      <Mutation mutation={TerminateContractMutation}>
                        {(terminateContract: MutationFunction) => (
                          <>
                            <Button
                              ml={2}
                              mt={2}
                              variant="red"
                              disabled={isVisible["terminatePopup"]}
                              onClick={() => toggle("terminatePopup")}
                            >
                              <Trans>Terminate contract</Trans>
                            </Button>
                            <Popup
                              visible={isVisible["terminatePopup"]}
                              onCancel={() => toggle("terminatePopup")}
                              title={<Trans>Terminate contract</Trans>}
                              formId="terminateContract"
                            >
                              <Form
                                onSubmit={async ({
                                  reason,
                                  statusReason
                                }: Partial<TerminateContractInput>) => {
                                  await terminateContract({
                                    variables: {
                                      input: { id, reason, statusReason }
                                    }
                                  });
                                  toggle("terminatePopup");
                                }}
                                id="terminateContract"
                              >
                                <Text mb={5}>
                                  <Trans>
                                    Attention! After the termination of the
                                    agreement, this action can not be canceled
                                  </Trans>
                                </Text>
                                <Box width={1 / 2}>
                                  <DictionaryValue
                                    name="CONTRACT_STATUS_REASON"
                                    render={(dict: Dictionary["values"]) => (
                                      <Trans
                                        id="Choose status reason"
                                        render={({ translation }) => (
                                          <Field.Select
                                            name="statusReason"
                                            label={<Trans id="Status reason" />}
                                            placeholder={translation}
                                            items={["DEFAULT"]}
                                            itemToString={(item: string) =>
                                              dict[item] || translation
                                            }
                                            variant="select"
                                            emptyOption
                                            filterOptions={{
                                              keys: [
                                                (item: string) => dict[item]
                                              ]
                                            }}
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                  <Validation.Required
                                    field="statusReason"
                                    message="Required field"
                                  />
                                </Box>
                                <Trans
                                  id="Enter reason comment"
                                  render={({ translation }) => (
                                    <Field.Textarea
                                      label={
                                        <Trans id="Status reason comment" />
                                      }
                                      name="reason"
                                      placeholder={translation}
                                      rows={5}
                                      maxLength={3000}
                                      showLengthHint
                                    />
                                  )}
                                />
                                <Validation.Required
                                  field="reason"
                                  message="Required field"
                                />
                              </Form>
                            </Popup>
                          </>
                        )}
                      </Mutation>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Box>

            <Tabs.Nav>
              <Tabs.NavItem to="./">
                <Trans>General information</Trans>
              </Tabs.NavItem>
              <Tabs.NavItem to="./legal-entity">
                <Trans>Legal entity</Trans>
              </Tabs.NavItem>
              <Tabs.NavItem to="./divisions">
                <Trans>Division</Trans>
              </Tabs.NavItem>
              {contractorEmployeeDivisions && (
                <Tabs.NavItem to="./employees">
                  <Trans>Doctors</Trans>
                </Tabs.NavItem>
              )}
              <Tabs.NavItem to="./external-contractors">
                <Trans>Contractors</Trans>
              </Tabs.NavItem>
              <Tabs.NavItem to="./documents">
                <Trans>Documents</Trans>
              </Tabs.NavItem>
            </Tabs.Nav>
            <Tabs.Content>
              <Router>
                <GeneralInfo
                  path="/"
                  startDate={startDate}
                  endDate={endDate}
                  contractorRmspAmount={contractorRmspAmount}
                  nhsSignerId={nhsSignerId}
                  nhsSignerName={nhsSigner && nhsSigner.party}
                  nhsSignerBase={nhsSignerBase}
                  nhsContractPrice={nhsContractPrice}
                  nhsPaymentMethod={nhsPaymentMethod}
                  issueCity={issueCity}
                  statusReason={statusReason}
                  reason={reason}
                  isSuspended={isSuspended}
                />
                <LegalEntity
                  path="/legal-entity"
                  contractorOwner={contractorOwner}
                  contractorBase={contractorBase}
                  contractorLegalEntity={contractorLegalEntity}
                  contractorPaymentDetails={contractorPaymentDetails}
                />
                <Divisions path="/divisions" />
                <Employees path="/employees" />
                <ExternalContractors
                  path="/external-contractors"
                  externalContractors={externalContractors}
                />
                <Documents
                  path="/documents"
                  attachedDocuments={attachedDocuments}
                />
              </Router>
            </Tabs.Content>
          </LoadingOverlay>
        );
      }}
    </Query>
  );
};

export const ToggleContractSuspendMutation = gql`
  mutation ToggleContractSuspendMutation($input: ToggleContractSuspendInput!) {
    toggleContractSuspend(input: $input) {
      contract {
        id
        isSuspended
        reason
      }
    }
  }
`;

type ToggleSuspendContractModalProps = {
  toggleSuspendContractMutation: DocumentNode;
  isSuspended: boolean;
  isVisible: DetailsStateType;
  toggle: (popup: "suspendPopup" | "terminatePopup") => void;
  formId: string;
  contractId?: string;
};

export const ToggleSuspendContractModal = ({
  toggleSuspendContractMutation,
  isSuspended,
  isVisible,
  toggle,
  formId,
  contractId
}: ToggleSuspendContractModalProps) => (
  <Box ml={3}>
    <Mutation mutation={toggleSuspendContractMutation}>
      {(suspendContract: MutationFunction) => (
        <>
          <Link
            is="a"
            disabled={isVisible["suspendPopup"]}
            onClick={() => toggle("suspendPopup")}
            fontWeight="bold"
          >
            {isSuspended ? (
              <Trans>Unsuspend contract</Trans>
            ) : (
              <Trans>Suspend contract</Trans>
            )}
          </Link>
          <Popup
            visible={isVisible["suspendPopup"]}
            onCancel={() => toggle("suspendPopup")}
            title={
              isSuspended ? (
                <Trans>Unsuspend contract</Trans>
              ) : (
                <Trans>Suspend contract</Trans>
              )
            }
            formId={formId}
          >
            <Form
              id={formId}
              onSubmit={async ({
                reason,
                statusReason
              }: Partial<TerminateContractInput>) => {
                await suspendContract({
                  variables: {
                    input: {
                      id: contractId,
                      reason,
                      isSuspended: isSuspended ? false : true,
                      statusReason
                    }
                  }
                });
                toggle("suspendPopup");
              }}
            >
              <Box width={1 / 2}>
                <DictionaryValue
                  name="CONTRACT_STATUS_REASON"
                  render={(dict: Dictionary["values"]) => (
                    <Trans
                      id="Choose status reason"
                      render={({ translation }) => (
                        <Field.Select
                          name="statusReason"
                          label={<Trans id="Status reason" />}
                          placeholder={translation}
                          items={["DEFAULT"]}
                          itemToString={(item: string) =>
                            dict[item] || translation
                          }
                          variant="select"
                          emptyOption
                          filterOptions={{
                            keys: [(item: string) => dict[item]]
                          }}
                        />
                      )}
                    />
                  )}
                />
                <Validation.Required
                  field="statusReason"
                  message="Required field"
                />
              </Box>
              <Trans
                id="Enter reason comment"
                render={({ translation }) => (
                  <Field.Textarea
                    label={<Trans id="Status reason comment" />}
                    name="reason"
                    placeholder={translation}
                    rows={5}
                    maxLength={3000}
                    showLengthHint
                  />
                )}
              />
              <Validation.Required field="reason" message="Required field" />
            </Form>
          </Popup>
        </>
      )}
    </Mutation>
  </Box>
);

type GeneralInfoProps = RouteComponentProps & {
  contractorRmspAmount: CapitationContract["contractorRmspAmount"];
  // @ts-expect-error types mismatch
  nhsSignerId: CapitationContract["nhsSignerId"];
  nhsSignerName?: Party | null;
  nhsSignerBase: CapitationContract["nhsSignerBase"];
  nhsContractPrice: CapitationContract["nhsContractPrice"];
  nhsPaymentMethod: CapitationContract["nhsPaymentMethod"];
  issueCity: CapitationContract["issueCity"];
  startDate: CapitationContract["startDate"];
  endDate: CapitationContract["endDate"];
  statusReason: CapitationContract["statusReason"];
  reason: CapitationContract["reason"];
  isSuspended: CapitationContract["isSuspended"];
};

const GeneralInfo = ({
  contractorRmspAmount,
  nhsSignerId,
  nhsSignerName,
  nhsSignerBase,
  nhsContractPrice,
  nhsPaymentMethod,
  issueCity,
  startDate,
  endDate,
  statusReason,
  reason,
  isSuspended
}: GeneralInfoProps) => (
  <Box p={5}>
    <DefinitionListView
      labels={{
        nhsSignerId: <Trans>Signer ID</Trans>,
        nhsSignerName: <Trans>Signer name</Trans>,
        nhsSignerBase: <Trans>Signer base</Trans>,
        nhsContractPrice: <Trans>Contract Price</Trans>,
        nhsPaymentMethod: <Trans>Payment method</Trans>,
        issueCity: <Trans>The city of the conclusion of the contract</Trans>
      }}
      data={{
        nhsSignerId,
        nhsSignerName: nhsSignerName ? getFullName(nhsSignerName) : "-",
        nhsSignerBase,
        nhsContractPrice: <Price amount={nhsContractPrice} />,
        nhsPaymentMethod: (
          <DictionaryValue
            name="CONTRACT_PAYMENT_METHOD"
            item={nhsPaymentMethod}
          />
        ),
        issueCity
      }}
    />
    <Line />
    <DefinitionListView
      labels={{
        startDate: <Trans>Initial date of the contract</Trans>,
        endDate: <Trans>End date of the contract</Trans>
      }}
      data={{
        startDate: i18n.date(startDate),
        endDate: i18n.date(endDate)
      }}
    />
    <Line />
    <DefinitionListView
      labels={{
        contractorRmspAmount: (
          <Trans>Number of persons served by legal entity</Trans>
        )
      }}
      data={{
        contractorRmspAmount: (
          <>
            {contractorRmspAmount}
            <Grey>(станом на 01.01.2018)</Grey>
          </>
        )
      }}
    />
    {(statusReason || reason) && (
      <>
        <Line />
        <DefinitionListView
          labels={{
            statusReason: <Trans>Status Comment</Trans>,
            reason: isSuspended ? (
              <Trans>Reason of deactivation contract</Trans>
            ) : (
              <Trans>Reason of activation contract</Trans>
            )
          }}
          data={{
            statusReason: (
              <DictionaryValue
                name="CONTRACT_STATUS_REASON"
                item={statusReason}
              />
            ),
            reason
          }}
        />
      </>
    )}
  </Box>
);

type LegalEntityProps = RouteComponentProps & {
  contractorBase: CapitationContract["contractorBase"];
  contractorOwner: CapitationContract["contractorOwner"];
  contractorPaymentDetails: CapitationContract["contractorPaymentDetails"];
  contractorLegalEntity: CapitationContract["contractorLegalEntity"];
};

const LegalEntity = ({
  contractorBase,
  contractorOwner,
  contractorPaymentDetails: { bankName, mfo, payerAccount },
  contractorLegalEntity: {
    databaseId: legalEntityDatabaseId,
    id: legalEntityId,
    edrpou,
    edrData
  }
}: LegalEntityProps) => {
  const { name, registrationAddress } = edrData || {};
  const { zip, country, address } = registrationAddress || {};

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          edrpou: <Trans>EDRPOU</Trans>,
          name: <Trans>Name</Trans>,
          addresses: <Trans>Address</Trans>
        }}
        data={{
          name,
          edrpou,
          addresses: (
            <>
              {zip}, {country}, {address}
            </>
          )
        }}
      />
      <DefinitionListView
        color="blueberrySoda"
        labels={{
          legalEntityId: <Trans>Legal entity ID</Trans>
        }}
        data={{
          legalEntityId: (
            <Link to={`/legal-entities/${legalEntityId}`}>
              {legalEntityDatabaseId}
            </Link>
          )
        }}
      />
      <Line />
      <DefinitionListView
        labels={{
          fullName: <Trans>Subscriber Name</Trans>,
          contractorBase: <Trans>Based on</Trans>
        }}
        data={{
          fullName: contractorOwner && getFullName(contractorOwner.party),
          contractorBase: contractorBase
        }}
      />
      <DefinitionListView
        color="blueberrySoda"
        labels={{
          ownerId: <Trans>Signer ID</Trans>
        }}
        data={{
          ownerId: contractorOwner && contractorOwner.databaseId
        }}
      />
      <Line />
      <DefinitionListView
        labels={{
          bankName: <Trans>Bank</Trans>,
          mfo: <Trans>Bank Code (MFO)</Trans>,
          payerAccount: <Trans>Account number</Trans>
        }}
        data={{
          bankName,
          mfo,
          payerAccount
        }}
      />
    </Box>
  );
};

type DivisionsProps = RouteComponentProps<{
  id: string;
}>;

const Divisions = ({ id }: DivisionsProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => {
      const { first, last, after, before, name } = locationParams;
      return (
        <>
          <Form onSubmit={setLocationParams} initialValues={locationParams}>
            <Box px={5} pt={5} width={460}>
              <Trans
                id="Enter division name"
                render={({ translation }) => (
                  <Field.Text
                    name="name"
                    label={<Trans id="Find division" />}
                    placeholder={translation}
                    postfix={<SearchIcon color="silverCity" />}
                  />
                )}
              />
            </Box>
          </Form>
          <Query
            query={CapitationContractQuery}
            variables={{
              id,
              ...pagination({ first, last, after, before }),
              divisionFilter: { name }
            }}
          >
            {({
              loading,
              data
            }: QueryResult<{ capitationContract: CapitationContract }>) => {
              if (isEmpty(data) || isEmpty(data.capitationContract))
                return null;
              const {
                capitationContract: {
                  contractorDivisions: {
                    nodes: contractorDivisions = [],
                    pageInfo
                  }
                }
              } = data;

              return (
                <LoadingOverlay loading={loading}>
                  {contractorDivisions!.length > 0 ? (
                    <>
                      <Table
                        data={contractorDivisions}
                        header={{
                          name: <Trans>Division name</Trans>,
                          addresses: <Trans>Address</Trans>,
                          mountainGroup: <Trans>Mountain region</Trans>,
                          phones: (
                            <>
                              <Trans>Phone</Trans>
                              <br />
                              <Trans>Email</Trans>
                            </>
                          ),
                          action: <Trans>Action</Trans>
                        }}
                        renderRow={({
                          databaseId,
                          name,
                          addresses,
                          mountainGroup,
                          phones,
                          email
                        }: Division) => ({
                          name,
                          mountainGroup: (
                            <Flex justifyContent="center">
                              {mountainGroup ? (
                                <PositiveIcon />
                              ) : (
                                <NegativeIcon />
                              )}
                            </Flex>
                          ),
                          phones: (
                            <>
                              <Box>
                                {phones
                                  .filter((a) => a && a.type === "MOBILE")
                                  .map((item) => item && item.number)[0] ||
                                  (phones && phones[0] && phones[0].number)}
                              </Box>
                              <Box>{email}</Box>
                            </>
                          ),
                          addresses: addresses
                            .filter((a) => a && a.type === "RESIDENCE")
                            .map((item, key) => (
                              <AddressView data={item} key={key} />
                            )),
                          action: (
                            <Link
                              to={`../employees?division.databaseId=${databaseId}&division.name=${name}`}
                              fontWeight="bold"
                            >
                              <Trans>Go to employees</Trans>
                            </Link>
                          )
                        })}
                        tableName="/contract/divisions"
                      />
                      <Pagination {...pageInfo} />
                    </>
                  ) : (
                    <EmptyData />
                  )}
                </LoadingOverlay>
              );
            }}
          </Query>
        </>
      );
    }}
  </LocationParams>
);

type EmployeesProps = RouteComponentProps<{
  id: string;
}>;

const Employees = ({ id }: EmployeesProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => {
      const { first, last, after, before, orderBy, division } = locationParams;
      return (
        <>
          <Form onSubmit={setLocationParams} initialValues={locationParams}>
            <Box px={5} pt={5} width={460}>
              <Trans
                id="Enter division name"
                render={({ translation }) => (
                  <Field.Text
                    name="division.name"
                    label={<Trans id="Find by divison" />}
                    placeholder={translation}
                    postfix={<SearchIcon color="silverCity" />}
                  />
                )}
              />
            </Box>
          </Form>
          <Query
            query={CapitationContractQuery}
            variables={{
              id,
              ...pagination({ first, last, after, before, orderBy }),
              employeeFilter: { division }
            }}
          >
            {({
              loading,
              data
            }: QueryResult<{ capitationContract: CapitationContract }>) => {
              if (isEmpty(data) || isEmpty(data.capitationContract))
                return null;
              const {
                capitationContract: {
                  contractorEmployeeDivisions: {
                    nodes: contractorEmployeeDivisions = [],
                    pageInfo
                  }
                }
              } = data;

              return (
                <LoadingOverlay loading={loading}>
                  {contractorEmployeeDivisions!.length > 0 ? (
                    <>
                      <Table
                        data={contractorEmployeeDivisions}
                        header={{
                          databaseId: <Trans>ID</Trans>,
                          divisionName: <Trans>Division name</Trans>,
                          employeeName: <Trans>Name of employee</Trans>,
                          speciality: <Trans>Specialty</Trans>,
                          staffUnits: <Trans>Permanent unit</Trans>,
                          declarationLimit: <Trans>Declarations limit</Trans>
                        }}
                        renderRow={({
                          employee: { databaseId, party, additionalInfo },
                          division: { name: divisionName },
                          ...contractorEmployeeDivisions
                        }: ContractorEmployeeDivision) => ({
                          databaseId,
                          divisionName,
                          employeeName: getFullName(party),
                          speciality: (
                            <DictionaryValue
                              name="SPECIALITY_TYPE"
                              item={
                                additionalInfo &&
                                additionalInfo.specialities &&
                                additionalInfo.specialities.find(
                                  (item) => item && item.specialityOfficio
                                )!.speciality
                              }
                            />
                          ),
                          ...contractorEmployeeDivisions
                        })}
                        sortableFields={["staffUnits", "declarationLimit"]}
                        sortingParams={parseSortingParams(
                          locationParams.orderBy
                        )}
                        onSortingChange={(sortingParams: SortingParams) =>
                          setLocationParams({
                            ...locationParams,
                            orderBy: stringifySortingParams(sortingParams)
                          })
                        }
                        tableName="/contract/employees"
                        whiteSpaceNoWrap={["databaseId"]}
                      />
                      <Pagination {...pageInfo} />
                    </>
                  ) : (
                    <EmptyData />
                  )}
                </LoadingOverlay>
              );
            }}
          </Query>
        </>
      );
    }}
  </LocationParams>
);

type ExternalContractorsProps = RouteComponentProps & {
  externalContractors: CapitationContract["externalContractors"];
};

const ExternalContractors = ({
  externalContractors
}: ExternalContractorsProps) =>
  externalContractors && externalContractors.length > 0 ? (
    <>
      <Text px={6} pt={2} fontSize={1}>
        <Trans>To see the services, click on "Show division"</Trans>
      </Text>
      <ExternalContractorsTable data={externalContractors} />
    </>
  ) : (
    <EmptyData />
  );

type DocumentsProps = RouteComponentProps & {
  attachedDocuments: CapitationContract["attachedDocuments"];
};

export const Documents = ({ attachedDocuments }: DocumentsProps) => {
  if (isEmpty(attachedDocuments)) return <EmptyData />;

  return (
    <>
      {attachedDocuments.map((document: Maybe<ContractDocument>) => (
        <Box m="5">
          <SaveLink href={document && document.url} target="_blank">
            <Box mr={2} color="shiningKnight">
              <DefaultImageIcon />
            </Box>
            <Text color="rockmanBlue" lineHeight="1">
              <DictionaryValue
                name="CONTRACT_DOCUMENT"
                item={document && document.type}
              />
            </Text>
          </SaveLink>
        </Box>
      ))}
    </>
  );
};

type ExternalContractorsTableProps = {
  data: CapitationContract["externalContractors"];
};

const ExternalContractorsTable = ({ data }: ExternalContractorsTableProps) => (
  <Table
    data={data}
    header={{
      name: <Trans>Legal entity</Trans>,
      divisions: <Trans>Division and Services</Trans>,
      number: <Trans>Contract Number</Trans>,
      issuedAt: <Trans>Contract start date</Trans>,
      expiresAt: <Trans>Contract end date</Trans>
    }}
    tableName="/contract/external-contractors"
    tableBody={({
      columns,
      data,
      rowKeyExtractor,
      columnKeyExtractor,
      filterTableColumn = filterTableDefaultColumn,
      filterRow
    }: TableBodyType) => {
      const renderRow = (
        {
          legalEntity: { name },
          contract: { number, issuedAt, expiresAt },
          divisions
        }: ExternalContractor,
        onClick: () => void
      ) => ({
        name,
        number,
        issuedAt: i18n.date(issuedAt),
        expiresAt: i18n.date(expiresAt),
        divisions: (
          <Wrapper onClick={onClick}>
            <Text color="rockmanBlue" fontWeight="bold" mr={1} fontSize={0}>
              <Trans>Show division</Trans>({divisions.length})
            </Text>
          </Wrapper>
        )
      });

      return (
        <TableBodyComponent>
          {data.map((item: $TSFixMe, index: number) => (
            <BooleanValue>
              {({ value: opened, toggle }: $TSFixMe) => {
                const row: $TSFixMe = renderRow(item, toggle);
                return (
                  <>
                    <TableRow key={rowKeyExtractor(item, index)}>
                      {columns
                        .filter((bodyName: string) =>
                          filterTableColumn(filterRow, bodyName)
                        )
                        .map((name: string, index: number) => (
                          <TableCell key={columnKeyExtractor(name, index)}>
                            {row[name]}
                          </TableCell>
                        ))}
                    </TableRow>
                    {opened && (
                      <TableRow
                        key={`row_${rowKeyExtractor(item, index)}`}
                        // @ts-expect-error TS(2322): Type '{ children: Element; key: string; fullSize: ... Remove this comment to see the full error message
                        fullSize
                      >
                        <TableCell
                          key={`cell_${rowKeyExtractor(item, index)}`}
                          colSpan={
                            columns.filter((bodyName: string) =>
                              filterTableColumn(filterRow, bodyName)
                            ).length
                          }
                          fullSize
                        >
                          <Table
                            data={item.divisions}
                            header={{
                              name: "",
                              medicalService: ""
                            }}
                            renderRow={({
                              division: { name },
                              medicalService
                            }: ExternalContractorDivision) => ({
                              name,
                              medicalService: (
                                <DictionaryValue
                                  name="MEDICAL_SERVICE"
                                  item={medicalService}
                                />
                              )
                            })}
                            tableName="/contract/ExternalContractorsTable"
                            headless
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                );
              }}
            </BooleanValue>
          ))}
        </TableBodyComponent>
      );
    }}
  />
);

type PrintButtonProps = {
  content: CapitationContract["printoutContent"];
};

const PrintButton = ({ content }: PrintButtonProps) => (
  <Wrapper color="shiningKnight" onClick={() => printIframe(content)}>
    <Text color="rockmanBlue" fontWeight="bold" mr={1} fontSize="0">
      <Trans>Show printout form</Trans>
    </Text>
    <PrinterIcon />
  </Wrapper>
);

const Wrapper = system(
  {
    extend: Flex
  },
  { cursor: "pointer" }
);

const Grey = system({
  color: "blueberrySoda"
});

const SaveLink = system(
  {
    is: "a"
  },
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    lineHeight: 0,
    textDecoration: "none"
  }
);

export default CapitationContractsDetails;
