import React from "react";
import { Flex, Box } from "@rebass/emotion";

import { Maybe, Reference as TReference } from "@ehealth/ehealth-ua.schema";

import Reference from "./Reference";

type ServiceRequestCodeListProps = {
  pathSlug: string;
  referencesList?: Maybe<Array<Maybe<TReference>>>;
};

const ServiceRequestCodeList = ({
  referencesList,
  pathSlug
}: ServiceRequestCodeListProps) => (
  <Flex flexDirection="column">
    {referencesList &&
      referencesList.map((reference, index) => (
        <Box key={index}>
          <Reference
            fontSize={14}
            headless
            linkWrapperProps={{
              mt: index === 0 ? 0 : 2
            }}
            // @ts-expect-error types mismatch
            linkPath={`${pathSlug}/${reference && reference.id}`}
            // @ts-expect-error types mismatch
            linkDisplayValue={reference && reference.name}
            // @ts-expect-error types mismatch
            code={reference && reference.code}
          />
        </Box>
      ))}
  </Flex>
);

export default ServiceRequestCodeList;
