import * as React from "react";
import { gql } from "graphql-tag";
import { Box, Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import Badge from "../../../components/Badge";
import DefinitionListView from "../../../components/DefinitionListView";

type HeaderProps = {
  databaseId: string;
  isActive: boolean;
};

const Header = ({ databaseId, isActive }: HeaderProps) => (
  <Flex justifyContent="space-between" alignItems="flex-end">
    <Box>
      <DefinitionListView
        labels={{
          databaseId: <Trans>Group ID</Trans>,
          status: <Trans>Employee status</Trans>
        }}
        data={{
          databaseId,
          status: (
            <Badge
              name={isActive}
              type="ACTIVE_STATUS_M"
              minWidth={100}
              variant={!isActive}
            />
          )
        }}
        color="#7F8FA4"
        labelWidth="120px"
      />
    </Box>
  </Flex>
);

export default Header;

Header.fragments = {
  entry: gql`
    fragment ForbiddenGroupHeader on ForbiddenGroup {
      id
      databaseId
      isActive
    }
  `
};
