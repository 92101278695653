import React from "react";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";

import DefinitionListView from "./DefinitionListView";
import DictionaryValue from "./DictionaryValue";

import dateFormatter from "../helpers/dateFormatter";
import STATUSES from "../helpers/statuses";
import { DATE_TIME_FORMAT } from "../constants/dateFormats";

const changesetContentItems: any = {
  drfo_data_id: <Trans>DRFO table identifier</Trans>,
  drfo_data_result: <Trans>DRFO verification result</Trans>,
  drfo_verification_status: <Trans>DRFO verification status</Trans>,
  drfo_verification_reason: <Trans>DRFO verification status reason</Trans>,
  drfo_synced_at: <Trans>DRFO synced at</Trans>,
  drfo_unverified_at: <Trans>DRFO unverified</Trans>,
  dracs_death_act_id: <Trans>Death act</Trans>,
  dracs_death_online_status: <Trans>DRACS death status</Trans>,
  dracs_death_verification_status: <Trans>DRACS verification status</Trans>,
  dracs_death_verification_reason: (
    <Trans>DRACS verification status reason</Trans>
  ),
  dracs_death_verification_comment: <Trans>DRACS verification comment</Trans>,
  dracs_death_unverified_at: <Trans>DRACS unverified</Trans>,
  nhs_verification_status: <Trans>Manual verification status</Trans>,
  nhs_verification_reason: <Trans>Manual verification status reason</Trans>,
  nhs_verification_comment: <Trans>Manual verification comment</Trans>,
  nhs_unverified_at: <Trans>Manual unverified</Trans>,
  verification_status: <Trans>General verification status</Trans>
};

type ChangesetViewProps = {
  content: string;
  operationType: string;
};

const ChangesetView = ({ content, operationType }: ChangesetViewProps) => {
  const { i18n } = useLingui();
  if (!content) return <>-</>;
  const changes = JSON.parse(content);
  const filteredChanges = Object.keys(changes).filter((changesetKey) => {
    if (operationType === "CREATE_OPERATION") {
      return (
        !!changesetContentItems[changesetKey] && changes[changesetKey] !== null
      );
    } else {
      return !!changesetContentItems[changesetKey];
    }
  });
  if (!filteredChanges.length) return <>-</>;

  return (
    <Box>
      {filteredChanges.map((changesetKey) => {
        let value;

        if (changesetKey.includes("verification_status")) {
          value = (
            <DictionaryValue
              name="PERSON_VERIFICATION_STATUSES"
              item={changes[changesetKey]}
            />
          );
        } else if (changesetKey.includes("verification_reason")) {
          value = (
            <DictionaryValue
              name="PERSON_VERIFICATION_STATUS_REASONS"
              item={changes[changesetKey]}
            />
          );
        } else if (changesetKey === "drfo_data_result") {
          value = (
            <DictionaryValue name="DRFO_RESULT" item={changes[changesetKey]} />
          );
        } else if (changesetKey === "dracs_death_online_status") {
          value = STATUSES.DRACS_DEATH_STATUSES[changes[changesetKey]];
        } else if (changesetKey.includes("_at")) {
          value = dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            changes[changesetKey]
          );
        } else {
          if (changes[changesetKey] === null) {
            value = <Trans>Missing value</Trans>;
          } else {
            value = changes[changesetKey];
          }
        }

        return (
          <DefinitionListView
            labelWidth={300}
            labels={{
              item: changesetContentItems[changesetKey]
            }}
            data={{
              item: value
            }}
          />
        );
      })}
    </Box>
  );
};

export default ChangesetView;
