import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { MedicalProgramConnection } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import { PrimarySearchFields } from "./SearchFields";
import MedicalProgramsTable from "./MedicalProgramsTable";
import CreateMedicalProgramPopup from "./CreateMedicalProgramPopup";

const Search = (_props: RouteComponentProps) => (
  <Box p={6}>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const filteredParams = filteredLocationParams(locationParams);
        return (
          <>
            <Flex justifyContent="space-between" alignItems="flex-start" mb={6}>
              <Box>
                <Heading as="h1" fontWeight="normal" mb={4}>
                  <Trans>Medical programs</Trans>
                </Heading>
              </Box>
              <Box>
                <Ability action="write" resource="medical_program">
                  <CreateMedicalProgramPopup
                    locationParams={filteredParams}
                    medicalProgramsQuery={MedicalProgramsQuery}
                  />
                </Ability>
              </Box>
            </Flex>
            <SearchForm
              initialValues={filteredParams}
              onSubmit={setLocationParams}
              renderPrimary={PrimarySearchFields}
            />
            <Query
              query={MedicalProgramsQuery}
              fetchPolicy="network-only"
              variables={filteredParams}
            >
              {({
                loading,
                data
              }: QueryResult<{
                medicalPrograms: MedicalProgramConnection;
              }>) => {
                if (isEmpty(data) || isEmpty(data.medicalPrograms)) return null;
                const {
                  medicalPrograms: { nodes: medicalPrograms = [], pageInfo }
                } = data;

                return (
                  <LoadingOverlay loading={loading}>
                    <MedicalProgramsTable
                      locationParams={filteredParams}
                      setLocationParams={setLocationParams}
                      medicalPrograms={medicalPrograms}
                    />
                    <Pagination {...pageInfo} />
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        );
      }}
    </LocationParams>
  </Box>
);

export default Search;

const MedicalProgramsQuery = gql`
  query MedicalProgramsQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: MedicalProgramFilter
    $orderBy: MedicalProgramOrderBy
    $skip: Boolean! = false
  ) {
    medicalPrograms(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) @skip(if: $skip) {
      nodes {
        ...MedicalPrograms
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${MedicalProgramsTable.fragments.entry}
  ${Pagination.fragments.entry}
`;
