import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import {
  DeclarationsTerminationJob,
  DeclarationsTerminationTask
} from "@ehealth/ehealth-ua.schema";

import EmptyData from "../../../components/EmptyData";
import Pagination from "../../../components/Pagination";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import TasksTable from "../../../components/TasksTable";

type TasksProps = RouteComponentProps & {
  tasks: DeclarationsTerminationJob["tasks"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const Tasks = ({ tasks, locationParams, setLocationParams }: TasksProps) => {
  const { nodes = [], pageInfo } = tasks || {};
  if (isEmpty(nodes)) return <EmptyData />;

  return (
    <>
      <TasksTable
        tasks={nodes}
        locationParams={locationParams}
        setLocationParams={setLocationParams}
        taskName={({
          meta
        }: {
          meta?: DeclarationsTerminationTask["meta"];
        }) => {
          const { databaseId } = meta || {};
          return (
            <>
              <Trans>Declaration termination</Trans> {databaseId}
            </>
          );
        }}
        tableName="declaration-termination-job/tasks"
      />
      <Pagination {...pageInfo} />
    </>
  );
};

Tasks.fragments = {
  entry: gql`
    fragment DeclarationsTerminationTasks on DeclarationsTerminationTask {
      id
      databaseId
      name
      priority
      status
      insertedAt
      endedAt
      updatedAt
      meta {
        databaseId
      }
      error {
        message
      }
    }
  `
};

export default Tasks;
