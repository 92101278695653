import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Condition as TCondition } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import CodeableConcept from "../../../components/CodeableConcept";
import Collapsable from "../../../components/Collapsable";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Line from "../../../components/Line";

import paramToBase64 from "../../../helpers/paramToBase64";
import dateFormatter from "../../../helpers/dateFormatter";
import STATUSES from "../../../helpers/statuses";
import { MonitoringDetailsPageProps } from "../../../helpers/types";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import CreateConclusion from "../../ClinicalMonitoring/CreateConclusion";
import getConclusionData from "../../ClinicalMonitoring/getConclusionData";
import Conclusions from "../../ClinicalMonitoring/Conclusions";
import CodeableConceptList from "./CodeableConceptList";
import DetailsPageBreadcrumbs from "./DetailsPageBreadcrumbs";
import Reference from "./Reference";
import { LABEL_WIDTH } from "./Episode";
import Evidences from "./Evidences";

const Condition = ({
  id,
  query,
  itemId,
  queryVariables,
  patientId,
  isClinicalMonitoring = false
}: MonitoringDetailsPageProps) => {
  const { i18n } = useLingui();

  return (
    <Ability
      action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
      resource="condition"
    >
      <Box p={6}>
        <DetailsPageBreadcrumbs
          id={id}
          currentStepName={<Trans>Condition details</Trans>}
          isClinicalMonitoring={isClinicalMonitoring}
        />
        <Query
          query={query}
          fetchPolicy="network-only"
          variables={{
            ...queryVariables,
            id: itemId,
            ...(isClinicalMonitoring && { patientId })
          }}
        >
          {({
            loading,
            error,
            data = {}
          }: QueryResult<{ [key: string]: TCondition }>) => {
            const condition = isClinicalMonitoring
              ? data.impersonalCondition
              : data.condition;

            return (
              <LoadingOverlay loading={loading}>
                <Box mt={4}>
                  {!isEmpty(condition) && !error && (
                    <>
                      <Flex
                        justifyContent="space-between"
                        alignItems="flex-end"
                      >
                        <div>
                          <DefinitionListView
                            labels={{
                              databaseId: <Trans>ID</Trans>,
                              ...(condition.clinicalStatus && {
                                clinicalStatus: <Trans>Clinical status</Trans>
                              }),
                              ...(condition.verificationStatus && {
                                verificationStatus: (
                                  <Trans>Diagnosis confirmation status</Trans>
                                )
                              })
                            }}
                            data={{
                              ...condition,
                              ...(condition.clinicalStatus && {
                                clinicalStatus: (
                                  <DictionaryValue
                                    name="eHealth/condition_clinical_statuses"
                                    item={condition.clinicalStatus.toLowerCase()}
                                  />
                                )
                              }),
                              ...(condition.verificationStatus && {
                                verificationStatus: (
                                  <DictionaryValue
                                    name="eHealth/condition_verification_statuses"
                                    item={condition.verificationStatus.toLowerCase()}
                                  />
                                )
                              })
                            }}
                            color="#7F8FA4"
                            labelWidth={LABEL_WIDTH}
                          />
                        </div>
                        {isClinicalMonitoring && (
                          <CreateConclusion
                            initialData={getConclusionData(
                              patientId!,
                              "condition",
                              condition.databaseId
                            )}
                          />
                        )}
                      </Flex>
                      <Line />
                      <Box mt={4}>
                        <DefinitionListView
                          fontSize={14}
                          labels={{
                            ...(condition.severity && {
                              severity: <Trans>Severity</Trans>
                            }),
                            ...(!isEmpty(condition.code) && {
                              code: <Trans>Code</Trans>
                            }),
                            ...(!isEmpty(condition.bodySites) && {
                              bodySites: <Trans>Body sites</Trans>
                            })
                          }}
                          data={{
                            ...condition,
                            severity: (
                              <CodeableConcept
                                codeableConcept={condition.severity}
                              />
                            ),
                            code: (
                              <CodeableConcept
                                // @ts-expect-error maybe bug - condition.code is array
                                codeableConcept={condition.code}
                                withCode
                              />
                            ),
                            bodySites: (
                              <CodeableConceptList
                                codeableConceptList={condition.bodySites}
                              />
                            )
                          }}
                          labelWidth={LABEL_WIDTH}
                        />
                      </Box>
                      <Box mt={2}>
                        <Reference
                          fontSize={14}
                          header={<Trans>Context</Trans>}
                          linkPath={
                            isClinicalMonitoring
                              ? `../../../encounter/${patientId}/${paramToBase64(
                                  "Encounter",
                                  condition.context.identifier.value
                                )}`
                              : `../../encounter/${paramToBase64(
                                  "Encounter",
                                  condition.context.identifier.value
                                )}`
                          }
                          linkDisplayValue={
                            condition.context.displayValue
                              ? condition.context.displayValue
                              : condition.context.identifier.value
                          }
                        />
                      </Box>
                      {!isEmpty(condition.asserter) && (
                        <Box mt={2}>
                          <Reference
                            fontSize={14}
                            header={<Trans>Asserter</Trans>}
                            linkPath={`/employees/${paramToBase64(
                              "Employee",
                              condition.asserter.identifier.value
                            )}`}
                            linkDisplayValue={
                              condition.asserter.displayValue
                                ? condition.asserter.displayValue
                                : condition.asserter.identifier.value
                            }
                          />
                        </Box>
                      )}
                      <Box mt={4}>
                        <DefinitionListView
                          fontSize={14}
                          labels={{
                            onsetDate: <Trans>Onset date</Trans>,
                            assertedDate: <Trans>Asserted date</Trans>,
                            insertedAt: <Trans>Inserted at</Trans>,
                            updatedAt: <Trans>Updated at</Trans>,
                            primarySource: <Trans>Primary source</Trans>,
                            ...(condition.reportOrigin && {
                              reportOrigin: <Trans>Report origin</Trans>
                            }),
                            ...(!isEmpty(condition.stage) && {
                              stage: <Trans>Stage</Trans>
                            })
                          }}
                          data={{
                            onsetDate: dateFormatter(
                              i18n.locale,
                              DATE_TIME_FORMAT,
                              condition.onsetDate
                            ),
                            assertedDate: dateFormatter(
                              i18n.locale,
                              DATE_TIME_FORMAT,
                              condition.assertedDate
                            ),
                            insertedAt: dateFormatter(
                              i18n.locale,
                              DATE_TIME_FORMAT,
                              condition.insertedAt
                            ),
                            updatedAt: dateFormatter(
                              i18n.locale,
                              DATE_TIME_FORMAT,
                              condition.updatedAt
                            ),
                            primarySource: (
                              <Box>
                                {/* @ts-expect-error statuses boolean key */}
                                {STATUSES.YES_NO[condition.primarySource]}
                              </Box>
                            ),
                            reportOrigin: (
                              <CodeableConcept
                                codeableConcept={condition.reportOrigin}
                              />
                            ),
                            ...(!isEmpty(condition.stage) && {
                              stage: (
                                <CodeableConcept
                                  codeableConcept={condition.stage.summary}
                                />
                              )
                            })
                          }}
                          labelWidth={LABEL_WIDTH}
                        />
                      </Box>
                      {!isEmpty(condition.evidences) && (
                        <Box mt={4}>
                          <Collapsable title={<Trans>Evidences</Trans>}>
                            <Evidences
                              evidences={condition.evidences}
                              patientId={patientId!}
                            />
                          </Collapsable>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </LoadingOverlay>
            );
          }}
        </Query>
        <Conclusions
          isClinicalMonitoring={isClinicalMonitoring}
          patientId={patientId!}
          itemId={itemId!}
        />
      </Box>
    </Ability>
  );
};

export default Condition;

Condition.fragments = {
  entry: gql`
    fragment Condition on Condition {
      id
      databaseId
      clinicalStatus @skip(if: $skipAdditionalFields)
      verificationStatus @skip(if: $skipAdditionalFields)
      severity @skip(if: $skipAdditionalFields) {
        ...CodeableConcept
      }
      code {
        ...CodeableConcept
      }
      bodySites {
        ...CodeableConcept
      }
      context {
        ...Reference
      }
      onsetDate
      assertedDate
      primarySource
      asserter {
        ...Reference
      }
      reportOrigin {
        ...CodeableConcept
      }
      stage @skip(if: $skipAdditionalFields) {
        summary {
          ...CodeableConcept
        }
      }
      evidences @skip(if: $skipAdditionalFields) {
        codes {
          ...CodeableConcept
        }
        details {
          ...Reference
        }
      }
      insertedAt
      updatedAt
    }
    ${CodeableConcept.fragments!.entry}
    ${Reference.fragments.entry}
  `
};

export const ConditionByIDQuery = gql`
  query ConditionByIDQuery(
    $id: ID!
    $personId: ID!
    $skipAdditionalFields: Boolean!
  ) {
    condition(id: $id, personId: $personId) {
      ...Condition
    }
  }
  ${Condition.fragments.entry}
`;
