import React, { useCallback } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import {
  Scalars,
  RuleEngineRuleConnection,
  RuleEngineRule
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Button from "../../../components/Button";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";
import { TLocationParams } from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";
import fromBase64toUUID from "../../../helpers/fromBase64toUUID";

import { RuleEngineRulesQuery } from "../Search";
import Header from "./Header";
import GeneralInfo from "./GeneralInfo";
import Rule from "./Rule";

type DetailsProps = RouteComponentProps<{
  id: Scalars["ID"];
}>;

const Details = ({ id, navigate }: DetailsProps) => {
  const onDeactivateRuleEngine = useCallback(
    (ruleEngineRule: RuleEngineRule) => {
      navigate!("./deactivate", {
        state: { ruleEngineRule }
      });
    },
    [navigate]
  );

  return (
    <LocationParams>
      {({ locationParams }: TLocationParams) => {
        return (
          <Query
            query={RuleEngineRulesQuery}
            variables={{
              filter: {
                databaseId: fromBase64toUUID(id!)
              },
              ...pagination(locationParams)
            }}
            fetchPolicy="network-only"
          >
            {({
              loading,
              data
            }: QueryResult<{
              ruleEngineRules: RuleEngineRuleConnection;
            }>) => {
              const firstRule =
                data &&
                data.ruleEngineRules &&
                data.ruleEngineRules.nodes &&
                data.ruleEngineRules.nodes[0];
              if (isEmpty(firstRule)) return null;
              const { databaseId, isActive } = firstRule;

              return (
                <LoadingOverlay loading={loading}>
                  <Flex justifyContent="space-between" p={6}>
                    <Box width={1 / 2}>
                      <Box py={10}>
                        <Breadcrumbs.List>
                          <Breadcrumbs.Item to="/rule-engine">
                            <Trans>Rule engine rule</Trans>
                          </Breadcrumbs.Item>
                          <Breadcrumbs.Item>
                            <Trans>Rule engine rule details</Trans>
                          </Breadcrumbs.Item>
                        </Breadcrumbs.List>
                      </Box>
                      <Header databaseId={databaseId} isActive={isActive} />
                    </Box>
                    {isActive && (
                      <Ability action="write" resource="rule_engine_rule">
                        <Box>
                          <Button
                            variant="red"
                            width={240}
                            onClick={() => onDeactivateRuleEngine(firstRule)}
                          >
                            <Trans>Deactivate rules engine rule</Trans>
                          </Button>
                        </Box>
                      </Ability>
                    )}
                  </Flex>
                  <Tabs.Nav>
                    <Tabs.NavItem to="./">
                      <Trans>General info</Trans>
                    </Tabs.NavItem>
                    <Tabs.NavItem to="./rule">
                      <Trans>Rule tab</Trans>
                    </Tabs.NavItem>
                  </Tabs.Nav>
                  <Tabs.Content>
                    <Router>
                      <GeneralInfo path="/" ruleEngine={firstRule} />
                      <Rule path="rule" ruleEngine={firstRule} />
                    </Router>
                  </Tabs.Content>
                </LoadingOverlay>
              );
            }}
          </Query>
        );
      }}
    </LocationParams>
  );
};

export default Details;
