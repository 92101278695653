import React, { useCallback } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Heading, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { ForbiddenGroupConnection } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import { PrimarySearchFields, SecondarySearchFields } from "./SearchFields";
import ForbiddenGroupsTable from "./ForbiddenGroupsTable";

const Search = ({ navigate }: RouteComponentProps) => {
  const onCreateForbiddenGroup = useCallback(() => navigate!("../create"), [
    navigate
  ]);

  return (
    <Box p={6}>
      <Flex justifyContent="space-between">
        <Box>
          <Heading as="h1" fontWeight="normal" mb={6}>
            <Trans>Forbidden Groups</Trans>
          </Heading>
        </Box>
        <Ability action="write" resource="forbidden_group">
          <Box>
            <Button
              variant="green"
              width={200}
              onClick={onCreateForbiddenGroup}
            >
              <Trans>Create forbidden group</Trans>
            </Button>
          </Box>
        </Ability>
      </Flex>
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => (
          <>
            <SearchForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
              renderPrimary={PrimarySearchFields}
              renderSecondary={SecondarySearchFields}
            />
            <Query
              fetchPolicy="cache-and-network"
              query={SearchForbiddenGroupsQuery}
              variables={filteredLocationParams(locationParams)}
            >
              {({
                loading,
                data
              }: QueryResult<{
                forbiddenGroups: ForbiddenGroupConnection;
              }>) => {
                if (isEmpty(data) || isEmpty(data.forbiddenGroups)) return null;
                const {
                  forbiddenGroups: { nodes: forbiddenGroups = [], pageInfo }
                } = data;

                return (
                  <LoadingOverlay loading={loading}>
                    <ForbiddenGroupsTable forbiddenGroups={forbiddenGroups} />
                    <Pagination {...pageInfo} />
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        )}
      </LocationParams>
    </Box>
  );
};

export default Search;

export const SearchForbiddenGroupsQuery = gql`
  query SearchForbiddenGroupsQuery(
    $filter: ForbiddenGroupFilter
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    forbiddenGroups(
      filter: $filter
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      nodes {
        ...ForbiddenGroups
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ForbiddenGroupsTable.fragments.entry}
  ${Pagination.fragments.entry}
`;
