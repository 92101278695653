import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams, Form } from "@edenlabllc/ehealth-components";
import { MedicationDispenseConnection } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import EmptyData from "../../../components/EmptyData";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";
import paramToBase64 from "../../../helpers/paramToBase64";

import MedicationDispensesTable from "./MedicationDispensesTable";
import { PrimarySearchFields } from "./SearchFields";

const Search = (_props: RouteComponentProps) => (
  <Ability action="read" resource="medication_dispense_admin">
    <Box p={6}>
      <Heading as="h1" fontWeight="normal" mb={6}>
        <Trans>Medication dispenses</Trans>
      </Heading>
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => {
          const params = prepareParamsToQuery(locationParams);

          return (
            <Query
              query={AdminMedicationDispensesQuery}
              fetchPolicy="network-only"
              variables={params}
              skip={!Object.keys(params.filter).length}
            >
              {({
                data,
                loading
              }: QueryResult<{
                adminMedicationDispenses: MedicationDispenseConnection;
              }>) => {
                const medicationDispenses =
                  data && data.adminMedicationDispenses;

                return (
                  <LoadingOverlay loading={loading}>
                    <SearchForm
                      initialValues={locationParams}
                      onSubmit={setLocationParams}
                      renderPrimary={() => <PrimarySearchFields />}
                      onResetSkipPagination
                      searchButton={() => (
                        <Form.Spy>
                          {({ values: { filter = {} } }: $TSFixMe) => (
                            <Button
                              variant="blue"
                              disabled={!Object.keys(filter).length}
                            >
                              <Trans>Search</Trans>
                            </Button>
                          )}
                        </Form.Spy>
                      )}
                    />
                    {isEmpty(medicationDispenses) ||
                    isEmpty(medicationDispenses.nodes) ? (
                      <Box pt={4}>
                        <EmptyData mx={2} />
                      </Box>
                    ) : (
                      <Box mb={6}>
                        <MedicationDispensesTable
                          data={medicationDispenses.nodes}
                          locationParams={locationParams}
                          setLocationParams={setLocationParams}
                          tableName="adminMedicationDispenses"
                        />
                        <Pagination {...medicationDispenses.pageInfo} />
                      </Box>
                    )}
                  </LoadingOverlay>
                );
              }}
            </Query>
          );
        }}
      </LocationParams>
    </Box>
  </Ability>
);

export default Search;

const AdminMedicationDispensesQuery = gql`
  query AdminMedicationDispensesQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: AdminMedicationDispenseFilter!
    $orderBy: MedicationDispenseOrderBy
  ) {
    adminMedicationDispenses(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        ...MedicationDispenses
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
  ${MedicationDispensesTable.fragments.entry}
`;

const prepareParamsToQuery = (locationParams: SearchParams) => {
  const {
    id,
    medicationDispensed,
    medicationRequestId,
    legalEntityId,
    divisionId,
    ...restFilterParams
  } = locationParams.filter || {};

  return {
    orderBy: "INSERTED_AT_DESC",
    ...pagination(locationParams),
    filter: {
      ...restFilterParams,
      ...(medicationRequestId && {
        medicationRequestId: paramToBase64(
          "MedicationRequest",
          medicationRequestId
        )
      }),
      ...(legalEntityId && {
        legalEntityId: paramToBase64("LegalEntity", legalEntityId)
      }),
      ...(divisionId && {
        divisionId: paramToBase64("Division", divisionId)
      }),
      ...(medicationDispensed && {
        dispensed: `${medicationDispensed.from}/${medicationDispensed.to}`
      }),
      ...(id && {
        id: paramToBase64("MedicationDispense", id)
      })
    }
  };
};
