import { useAbility } from "../../../helpers/useAbility";

export const useTabsScopesAbilities = (scope = "practical_monitor") => {
  const [episodeAbility] = useAbility(scope, "episode");
  const [encounterAbility] = useAbility(scope, "encounter");
  const [conditionAbility] = useAbility(scope, "condition");
  const [observationAbility] = useAbility(scope, "observation");
  const [procedureAbility] = useAbility(scope, "procedure");
  const [serviceRequestAbility] = useAbility(scope, "service_request");
  const [medicationRequestAbility] = useAbility(scope, "medication_request");
  const [medicationDispenseAbility] = useAbility(scope, "medication_dispense");
  const [immunizationAbility] = useAbility(scope, "immunization");
  const [carePlanAbility] = useAbility(scope, "care_plan");
  const [allergyIntoleranceAbility] = useAbility(scope, "allergy_intolerance");
  const [diagnosticReportAbility] = useAbility(scope, "diagnostic_report");
  return {
    episodeAbility,
    encounterAbility,
    conditionAbility,
    observationAbility,
    procedureAbility,
    serviceRequestAbility,
    medicationRequestAbility,
    medicationDispenseAbility,
    immunizationAbility,
    carePlanAbility,
    allergyIntoleranceAbility,
    diagnosticReportAbility
  };
};
