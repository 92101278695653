import React from "react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import * as Field from "../Field";

export const Dictionaries = [
  {
    type: "code",
    value: "eHealth/ICD10_AM/condition_codes",
    name: "Довідник станів ICD10AM"
  },
  {
    type: "code",
    value: "eHealth/ICPC2/condition_codes",
    name: "Довідник станів ICPC2"
  },
  {
    type: "code",
    value: "eHealth/ICPC2/actions",
    name: "Довідник процесів медичної допомоги/втручань ICPC2"
  },
  {
    type: "code",
    value: "eHealth/ICPC2/reasons",
    name: "Довідник причин звернення ICPC2"
  },
  {
    type: "service",
    value: "Service",
    name: "Сервіси"
  },
  {
    type: "service",
    value: "Service group",
    name: "Група сервісів"
  }
];

type ForbiddenGroupSystemProps = {
  name: string;
  systemType: string;
  disabled?: boolean;
};

const ForbiddenGroupSystem = ({
  name,
  systemType,
  disabled
}: ForbiddenGroupSystemProps) => {
  const { i18n } = useLingui();

  return (
    <Field.Select
      name={name}
      label={<Trans id="Dictionary type" />}
      placeholder={i18n._(t`All dictionaries`)}
      items={Dictionaries.filter((item) => item.type === systemType).map(
        (item) => item.value
      )}
      itemToString={(item: string) => {
        const found = Dictionaries.find(
          (dictionary) => dictionary.value === item
        );
        return found ? found.name : i18n._(t`All dictionaries`);
      }}
      variant="select"
      disabled={disabled}
    />
  );
};

export default ForbiddenGroupSystem;
