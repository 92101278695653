import _slicedToArray from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
export default function (array, headerName) {
  var filterArray = array.filter(function (_ref) {
    var name = _ref.name;
    return headerName === name;
  });
  var _filterArray = _slicedToArray(filterArray, 1),
    _filterArray$ = _filterArray[0],
    _filterArray$2 = _filterArray$ === void 0 ? {} : _filterArray$,
    status = _filterArray$2.status;
  return status;
}