import * as React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { PositiveIcon } from "@edenlabllc/ehealth-icons";
import { Party } from "@ehealth/ehealth-ua.schema";

import Badge from "../../../components/Badge";
import DefinitionListView from "../../../components/DefinitionListView";
import FullName from "../../../components/FullName";

type HeaderPartyProps = {
  party: Party;
};

const HeaderParty = ({ party }: HeaderPartyProps) => {
  return (
    <Flex justifyContent="space-between" alignItems="flex-end">
      <Box>
        <DefinitionListView
          labels={{
            databaseId: <Trans>ID</Trans>,
            name: <Trans>Full name</Trans>,
            taxId: <Trans>INN</Trans>,
            noTaxId: <Trans>No tax ID</Trans>,
            verificationStatus: <Trans>Verification status</Trans>
          }}
          data={{
            databaseId: party.databaseId,
            taxId: party.taxId,
            name: <FullName party={party} />,
            noTaxId: party.noTaxId ? <PositiveIcon /> : null,
            verificationStatus: (
              <Badge
                name={party.verificationStatus}
                type="STATUS_VERIFICATION"
                minWidth={160}
              />
            )
          }}
          color="#7F8FA4"
          labelWidth="160px"
        />
      </Box>
    </Flex>
  );
};

HeaderParty.fragments = {
  entry: gql`
    fragment HeaderParty on Party {
      id
      ...FullName
      taxId
      noTaxId
      verificationStatus
    }
    ${FullName.fragments.entry}
  `
};

export default HeaderParty;
