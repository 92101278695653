import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";

import { Scalars, ProgramMedication } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Badge from "../../../components/Badge";
import DefinitionListView from "../../../components/DefinitionListView";

import UpdateProgramMedicationPopup from "./Mutations/Update";

type HeaderProps = {
  id: Scalars["ID"];
  databaseId: Scalars["UUID"];
  isActive: ProgramMedication["isActive"];
  medicationRequestAllowed: ProgramMedication["medicationRequestAllowed"];
  // @ts-expect-error types mismatch
  carePlanActivityAllowed: ProgramMedication["carePlanActivityAllowed"];
};

const Header = ({
  id,
  databaseId,
  isActive,
  medicationRequestAllowed,
  carePlanActivityAllowed
}: HeaderProps) => (
  <Flex justifyContent="space-between" alignItems="flex-start">
    <Box>
      <DefinitionListView
        labels={{
          databaseId: <Trans>Participant ID</Trans>,
          isActive: <Trans>Status</Trans>
        }}
        data={{
          databaseId,
          isActive: (
            <Badge
              type="ACTIVE_STATUS_M"
              name={isActive}
              variant={!isActive}
              minWidth={100}
            />
          )
        }}
        color="#7F8FA4"
        labelWidth="160px"
      />
      <UpdateProgramMedicationPopup
        id={id}
        isActive={isActive}
        medicationRequestAllowed={medicationRequestAllowed}
        carePlanActivityAllowed={carePlanActivityAllowed}
        isDefinitionListView
      />
    </Box>
    <Ability action="write" resource="program_medication">
      <UpdateProgramMedicationPopup
        id={id}
        isActive={isActive}
        medicationRequestAllowed={medicationRequestAllowed}
        carePlanActivityAllowed={carePlanActivityAllowed}
      />
    </Ability>
  </Flex>
);

Header.fragments = {
  entry: gql`
    fragment ProgramMedicationHeader on ProgramMedication {
      id
      databaseId
      isActive
      medicationRequestAllowed
      carePlanActivityAllowed
    }
  `
};

export default Header;
