import React from "react";
import { Box, Flex, Text } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { Condition } from "@ehealth/ehealth-ua.schema";

import CodeableConcept from "../../../components/CodeableConcept";
import DefinitionListView from "../../../components/DefinitionListView";

import ReferenceList from "./ReferenceList";
import { LABEL_WIDTH } from "./Episode";

type EvidencesProps = {
  evidences: Condition["evidences"];
  patientId: string;
};

const Evidences = ({ evidences, patientId }: EvidencesProps) => {
  const titleProps = {
    color: "darkCharcoal",
    width: 100
  };

  return (
    <>
      {evidences &&
        evidences.map((evidence, index) => (
          <Box mt={4} key={index}>
            <DefinitionListView
              fontSize={14}
              labels={{
                codes: <Trans>Ciphers</Trans>,
                details: <Trans>Details</Trans>
              }}
              data={{
                codes:
                  evidence &&
                  evidence.codes &&
                  evidence.codes.map((code, j) => (
                    <Box key={j}>
                      <CodeableConcept codeableConcept={code} />
                    </Box>
                  )),
                details: (
                  <Flex alignItems="center">
                    <Text {...titleProps}>
                      <Trans>Observations</Trans>:
                    </Text>
                    <ReferenceList
                      key={index}
                      referencesList={evidence && evidence.details}
                      pathSlug={`../../../observation/${patientId}`}
                      entityName="Observation"
                    />
                  </Flex>
                )
              }}
              labelWidth={LABEL_WIDTH}
            />
          </Box>
        ))}
    </>
  );
};

export default Evidences;
