import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import Composer from "react-composer";
import { TransRenderProps } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";

import { Form } from "@edenlabllc/ehealth-components";
import {
  DropDownButton as PlusIcon,
  RemoveItemIcon
} from "@edenlabllc/ehealth-icons";
import { Scalars, Dictionary } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import DictionaryValue from "../../../../components/DictionaryValue";
import DefinitionListView from "../../../../components/DefinitionListView";
import * as Field from "../../../../components/Field";
import {
  SelectedItem,
  RemoveItem
} from "../../../../components/Field/MultiSelectView";

type DictionaryLabelsProps = RouteComponentProps<{
  id: Scalars["ID"];
  labels: Dictionary["labels"];
  isActive: Dictionary["isActive"];
  isReadOnly: boolean;
}>;

const DictionaryLabels = ({
  id,
  labels,
  isActive,
  isReadOnly
}: DictionaryLabelsProps) => {
  const [isFormVisible, setFormVisibility] = useState(false);
  const toggle = () => setFormVisibility(!isFormVisible);

  return (
    <Mutation mutation={UpdateDictionaryLabelMutation}>
      {(updateDictionary: MutationFunction) => (
        <Box px={5} py={4}>
          <DefinitionListView
            labels={{
              labels: <Trans>Tags</Trans>
            }}
            data={{
              labels: (
                <Flex>
                  {labels &&
                    labels.map((label) => (
                      <SelectedItem key={label} mx={1}>
                        {label}
                        {!isReadOnly &&
                          isActive &&
                          labels &&
                          labels.length > 1 && (
                            <Ability action="write" resource="dictionary">
                              <RemoveItem
                                onClick={() => {
                                  const updatedLabelsList = labels.filter(
                                    (item) => item !== label
                                  );

                                  updateDictionary({
                                    variables: {
                                      input: {
                                        id,
                                        labels: updatedLabelsList
                                      }
                                    }
                                  });
                                }}
                              >
                                <RemoveItemIcon />
                              </RemoveItem>
                            </Ability>
                          )}
                      </SelectedItem>
                    ))}
                </Flex>
              )
            }}
            labelWidth="100px"
            alignItems="center"
          />
          {!isReadOnly && isActive && (
            <>
              <Ability action="write" resource="dictionary">
                <Box>
                  <Button
                    variant="link"
                    px={0}
                    icon={PlusIcon}
                    onClick={toggle}
                  >
                    <Trans>Add tag</Trans>
                  </Button>
                </Box>
              </Ability>
              {isFormVisible && (
                <Box mt={2} width={1 / 4}>
                  <Form
                    onSubmit={({ addNewLabel }: { addNewLabel: string }) => {
                      addNewLabel &&
                        updateDictionary({
                          variables: {
                            input: {
                              id,
                              labels: labels
                                ? [...labels, addNewLabel]
                                : [addNewLabel]
                            }
                          }
                        });

                      toggle();
                    }}
                  >
                    <Composer
                      components={[
                        <DictionaryValue name="DICTIONARY_LABELS" />,
                        ({
                          render
                        }: {
                          render: (
                            props: TransRenderProps
                          ) => React.ReactElement;
                        }) => (
                          <Trans id="Select tag from list" render={render} />
                        )
                      ]}
                    >
                      {([dict, { translation }]: [
                        Dictionary["values"],
                        { translation: React.ReactNode }
                      ]) => {
                        const items = Object.keys(dict);
                        const availableItems = labels
                          ? items.filter((item) => !labels.includes(item))
                          : [];

                        return (
                          <Field.Select
                            name="addNewLabel"
                            label={<Trans id="Select a tag" />}
                            placeholder={translation}
                            items={availableItems}
                            hideErrors
                          />
                        );
                      }}
                    </Composer>
                    <Button variant="green" mt={4}>
                      <Trans>Save</Trans>
                    </Button>
                  </Form>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </Mutation>
  );
};

const UpdateDictionaryLabelMutation = gql`
  mutation UpdateDictionaryLabelMutation($input: UpdateDictionaryInput!) {
    updateDictionary(input: $input) {
      dictionary {
        id
        labels
      }
    }
  }
`;

export default DictionaryLabels;
