import * as Reach from "@reach/router";

import system from "@edenlabllc/ehealth-system-components";

const Link = system(
  {
    is: Reach.Link,
    fontSize: 0,
    color: "rockmanBlue"
  },
  {
    display: "inline-block",
    verticalAlign: "middle",
    outline: "none",
    textDecoration: "none",
    cursor: "pointer"
  },

  "display",
  "verticalAlign",
  "color",
  "fontSize",
  "fontWeight"
);

export default Link;
