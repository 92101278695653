import React from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";

import { Form, Validation, Validations } from "@edenlabllc/ehealth-components";
import system from "@edenlabllc/ehealth-system-components";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import { Dictionary, Scalars } from "@ehealth/ehealth-ua.schema";

import Button from "../../../../components/Button";
import * as Field from "../../../../components/Field";

import { DictionaryValueItem } from "../DictionaryValues";

type UpdateDictionaryProps = {
  id: Scalars["ID"];
  fieldToEdit: DictionaryValueItem;
  toggle: () => any;
  dictionary: Array<DictionaryValueItem>;
};

const UpdateDictionary = ({
  id,
  fieldToEdit,
  toggle,
  dictionary
}: UpdateDictionaryProps) => (
  <Mutation mutation={UpdateDictionaryMutation}>
    {(updateDictionary: MutationFunction) => {
      const submitMutationForm = (data = []) => {
        const dataForMutation = getDataForMutation(
          data,
          fieldToEdit,
          dictionary
        ).reduce(
          (result, item) => ({
            ...result,
            ...{
              [item.key]: item.description
            }
          }),
          {}
        );

        const values = JSON.stringify(dataForMutation);
        updateDictionary({
          variables: {
            input: {
              id,
              values
            }
          }
        });

        toggle();
      };

      return (
        <Form
          onSubmit={({ values }: Partial<Dictionary>) => {
            submitMutationForm(values);
          }}
          initialValues={{
            values: fieldToEdit.key
              ? [fieldToEdit]
              : [{ key: "", description: "" }]
          }}
        >
          <Field.Array
            name="values"
            addText={!fieldToEdit.key && <Trans id="Add another" />}
            removeButton={({ onClick }) =>
              !fieldToEdit.key ? (
                <RemoveIcon onClick={onClick} />
              ) : (
                <RemovalContainer>
                  <Button
                    variant="red"
                    disabled={dictionary.length === 1}
                    onClick={async () => {
                      await submitMutationForm();
                    }}
                  >
                    <Trans id="Delete" />
                  </Button>
                </RemovalContainer>
              )
            }
            fields={({ name }: { name: string }) => (
              <Trans
                id="Enter value"
                render={({ translation }) => (
                  <Flex mx={-1} key={name} width="100%">
                    <Box px={1} width={1 / 3}>
                      <Field.Text
                        name={`${name}.key`}
                        label={
                          fieldToEdit.key ? (
                            <Trans id="Change key" />
                          ) : (
                            <Trans id="Key" />
                          )
                        }
                        placeholder={translation}
                        parse={(value: string) => value.trim()}
                      />
                      <Validations field={`${name}.key`}>
                        <Validation.Required message="Required field" />
                        <Validation.IsExists
                          options={dictionary
                            .map(({ key }) => key)
                            .filter((item) => item !== fieldToEdit.key)}
                          message="This key is already exists"
                        />
                      </Validations>
                    </Box>
                    <Box px={1} width={2 / 3}>
                      <Field.Text
                        name={`${name}.description`}
                        label={
                          fieldToEdit.key ? (
                            <Trans id="Change description" />
                          ) : (
                            <Trans id="Description" />
                          )
                        }
                        placeholder={translation}
                      />
                      <Validation.Required
                        field={`${name}.description`}
                        message="Required field"
                      />
                    </Box>
                  </Flex>
                )}
              />
            )}
          />
          <Flex mx={-2} pt={5}>
            <Box px={2}>
              <Button variant="blue" onClick={toggle}>
                <Trans id="Return" />
              </Button>
            </Box>
            <Box px={2}>
              <Button variant="green">
                <Trans id="Save and Return" />
              </Button>
            </Box>
          </Flex>
        </Form>
      );
    }}
  </Mutation>
);

const UpdateDictionaryMutation = gql`
  mutation UpdateDictionaryMutation($input: UpdateDictionaryInput!) {
    updateDictionary(input: $input) {
      dictionary {
        id
        values
      }
    }
  }
`;

const getDataForMutation = (
  data: DictionaryValueItem[],
  fieldToEdit: DictionaryValueItem,
  dictionary: DictionaryValueItem[]
) => {
  if (!data.length) {
    return dictionary.filter((item) => item.key !== fieldToEdit.key);
  }

  if (fieldToEdit.key) {
    const indexOfField = dictionary.findIndex(
      (item) => item.key === fieldToEdit.key
    );
    dictionary.splice(indexOfField, 1, ...data);
    return dictionary;
  } else {
    return [...dictionary, ...data];
  }
};

const RemoveIcon = system(
  {
    extend: RemoveItemIcon,
    color: "redPigment",
    ml: 2
  },
  {
    alignSelf: "center"
  },
  "color",
  "space"
);

const RemovalContainer = system(
  {
    extend: Box,
    px: 4,
    mt: "-4px"
  },
  {
    alignSelf: "center"
  },
  "space"
);

export default UpdateDictionary;
