import React, { Fragment } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import system from "@edenlabllc/ehealth-system-components";
import { LocationParams } from "@edenlabllc/ehealth-components";
import { DefaultImageIcon } from "@edenlabllc/ehealth-icons";
import {
  Maybe,
  RequestAttachedDocument,
  RequestAttachedDocuments
} from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";
import LoadingOverlay from "../../../components/LoadingOverlay";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type PersonDocumentsProps = RouteComponentProps<{
  id: string;
}>;

const PersonDocuments = ({ id }: PersonDocumentsProps) => {
  if (!id) return null;

  return (
    <LocationParams>
      {() => {
        const variables = {
          orderBy: "INSERTED_AT_DESC",
          filter: {
            includeEmptyBuckets: false,
            personId: id
          }
        };
        return (
          <Query
            query={PersonAttachedDocumentsQuery}
            fetchPolicy="network-only"
            variables={variables}
          >
            {({
              loading,
              error,
              data
            }: QueryResult<{
              personBucketsDocuments: Maybe<
                Array<Maybe<RequestAttachedDocuments>>
              >;
            }>) => {
              return (
                <LoadingOverlay loading={loading}>
                  {error ||
                    isEmpty(data) ||
                    (isEmpty(data.personBucketsDocuments) && (
                      <Box p={5}>
                        <Text>
                          <Trans>There are no documents</Trans>
                        </Text>
                      </Box>
                    ))}
                  <Box p={5}>
                    {data &&
                      data.personBucketsDocuments &&
                      data.personBucketsDocuments.map(
                        (
                          personBucketDocuments: Maybe<RequestAttachedDocuments>,
                          i: number
                        ) => {
                          const {
                            insertedAt,
                            documents,
                            confidantPersons,
                            thirdPerson
                          } = personBucketDocuments || {};
                          if (
                            isEmpty(documents) &&
                            isEmpty(confidantPersons) &&
                            isEmpty(thirdPerson)
                          ) {
                            return null;
                          }

                          return (
                            <Fragment key={i}>
                              <Document
                                title={<Trans>Person's documents</Trans>}
                                documents={documents}
                                dataAdded={insertedAt}
                              />
                              {!isEmpty(confidantPersons) &&
                                confidantPersons!.map(
                                  (confidantPerson, index) => {
                                    return (
                                      <Fragment key={index}>
                                        <Document
                                          title={
                                            confidantPerson!.relationType ===
                                            "PRIMARY" ? (
                                              <Trans>
                                                Primary confidant person's
                                                documents
                                              </Trans>
                                            ) : (
                                              <Trans>
                                                Secondary confidant person's
                                                documents
                                              </Trans>
                                            )
                                          }
                                          documents={confidantPerson!.documents}
                                          dataAdded={insertedAt}
                                        />
                                        <Document
                                          title={
                                            confidantPerson!.relationType ===
                                            "PRIMARY" ? (
                                              <Trans>
                                                Primary confidant person's
                                                relationship documents, which
                                                confirms the authority
                                              </Trans>
                                            ) : (
                                              <Trans>
                                                Secondary confidant person's
                                                relationship documents, which
                                                confirms the authority
                                              </Trans>
                                            )
                                          }
                                          documents={
                                            confidantPerson!
                                              .relationshipDocuments
                                          }
                                          dataAdded={insertedAt}
                                        />
                                      </Fragment>
                                    );
                                  }
                                )}
                              <Document
                                title={<Trans>Third person's documents</Trans>}
                                documents={thirdPerson}
                                dataAdded={insertedAt}
                              />
                              {data.personBucketsDocuments &&
                                i + 1 < data.personBucketsDocuments.length && (
                                  <Line />
                                )}
                            </Fragment>
                          );
                        }
                      )}
                  </Box>
                </LoadingOverlay>
              );
            }}
          </Query>
        );
      }}
    </LocationParams>
  );
};

type DocumentProps = {
  title: React.ReactNode;
  documents: Maybe<Maybe<RequestAttachedDocument>[]> | undefined;
  dataAdded: string;
};

const Document = ({ title, documents, dataAdded }: DocumentProps) => {
  if (isEmpty(documents)) return null;

  return (
    <Box mb={6}>
      <Text mb={2} color="#7F8FA4" fontWeight="500">
        {title}
      </Text>
      {documents!.map((document, index) => (
        <Flex alignItems="flex-start" mb={4} key={index}>
          <Box>
            <SaveLink href={document!.url} target="_blank">
              <Box color="shiningKnight">
                <DefaultImageIcon width={16} height={16} />
              </Box>
            </SaveLink>
          </Box>
          <Flex flexDirection="column" ml={2}>
            <SaveLink href={document!.url} target="_blank">
              <Text color="rockmanBlue" lineHeight="1" fontSize={1} mb={2}>
                <DictionaryValue
                  name="DOCUMENT_TYPE_EXTENDED"
                  item={document!.type}
                />
              </Text>
            </SaveLink>
            <Text color="#7F8FA4" lineHeight="1" fontSize={12}>
              <Trans>Added</Trans>
              {": "}
              {dateFormatter(i18n.locale, DATE_TIME_FORMAT, dataAdded)}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Box>
  );
};

export default PersonDocuments;

const SaveLink = system(
  {
    is: "a"
  },
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    lineHeight: 0,
    textDecoration: "none"
  }
);

export const PersonAttachedDocumentsQuery = gql`
  query PersonAttachedDocumentsQuery(
    $filter: PersonBucketsDocumentsFilter!
    $orderBy: PersonBucketsDocumentsOrderBy
  ) {
    personBucketsDocuments(filter: $filter, orderBy: $orderBy) {
      id
      databaseId
      type
      channel
      emptyBucket
      insertedAt
      documents {
        type
        url
      }
      confidantPersons {
        relationType
        documents {
          type
          url
        }
        relationshipDocuments {
          type
          url
        }
      }
      thirdPerson {
        type
        url
      }
    }
  }
`;
