import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { getFullName } from "@edenlabllc/ehealth-utils";
import { GlobalBudgetContract, Party } from "@ehealth/ehealth-ua.schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";
import Price from "../../../components/Price";

type GeneralInfoGlobalBudgetProps = RouteComponentProps & {
  // @ts-expect-error types mismatch
  nhsSignerId: GlobalBudgetContract["nhsSignerId"];
  nhsSignerName?: Party | null;
  nhsSignerBase: GlobalBudgetContract["nhsSignerBase"];
  nhsContractPrice: GlobalBudgetContract["nhsContractPrice"];
  nhsPaymentMethod: GlobalBudgetContract["nhsPaymentMethod"];
  issueCity: GlobalBudgetContract["issueCity"];
  startDate: GlobalBudgetContract["startDate"];
  endDate: GlobalBudgetContract["endDate"];
  statusReason: GlobalBudgetContract["statusReason"];
  reason: GlobalBudgetContract["reason"];
  isSuspended: GlobalBudgetContract["isSuspended"];
};

const GeneralInfoGlobalBudget = ({
  nhsSignerId,
  nhsSignerName,
  nhsSignerBase,
  nhsContractPrice,
  nhsPaymentMethod,
  issueCity,
  startDate,
  endDate,
  statusReason,
  reason,
  isSuspended
}: GeneralInfoGlobalBudgetProps) => (
  <Box p={5}>
    <DefinitionListView
      labels={{
        nhsSignerId: <Trans>Signer ID</Trans>,
        nhsSignerName: <Trans>Signer name</Trans>,
        nhsSignerBase: <Trans>Signer base</Trans>,
        nhsContractPrice: <Trans>Contract Price</Trans>,
        nhsPaymentMethod: <Trans>Payment method</Trans>,
        issueCity: <Trans>The city of the conclusion of the contract</Trans>
      }}
      data={{
        nhsSignerId,
        nhsSignerName: getFullName(nhsSignerName),
        nhsSignerBase,
        nhsContractPrice: <Price amount={nhsContractPrice} />,
        nhsPaymentMethod: (
          <DictionaryValue
            name="CONTRACT_PAYMENT_METHOD"
            item={nhsPaymentMethod}
          />
        ),
        issueCity
      }}
    />
    <Line />
    <DefinitionListView
      labels={{
        startDate: <Trans>Initial date of the contract</Trans>,
        endDate: <Trans>End date of the contract</Trans>
      }}
      data={{
        startDate: i18n.date(startDate),
        endDate: i18n.date(endDate)
      }}
    />
    {(statusReason || reason) && (
      <>
        <Line />
        <DefinitionListView
          labels={{
            statusReason: <Trans>Status Comment</Trans>,
            reason: isSuspended ? (
              <Trans>Reason of deactivation contract</Trans>
            ) : (
              <Trans>Reason of activation contract</Trans>
            )
          }}
          data={{
            statusReason: (
              <DictionaryValue
                name="CONTRACT_STATUS_REASON"
                item={statusReason}
              />
            ),
            reason
          }}
        />
      </>
    )}
  </Box>
);

export default GeneralInfoGlobalBudget;

GeneralInfoGlobalBudget.fragments = {
  entry: gql`
    fragment GeneralInfoGlobalBudget on GlobalBudgetContract {
      id
      nhsSignerBase
      nhsContractPrice
      nhsPaymentMethod
      issueCity
      startDate
      endDate
      statusReason
      reason
      status
      contractorLegalEntity {
        id
        name
        edrpou
        databaseId
        status
        edrData {
          name
          edrpou
          registrationAddress {
            zip
            country
            address
          }
        }
        mergedFromLegalEntities(first: 10) {
          nodes {
            mergedFromLegalEntity {
              id
              name
              edrpou
              databaseId
            }
          }
        }
        mergedToLegalEntity {
          mergedFromLegalEntity {
            id
            name
            edrpou
            databaseId
          }
        }
      }
      nhsSigner {
        id
        party {
          id
          firstName
          lastName
          secondName
        }
      }
    }
  `
};
