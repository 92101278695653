import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";

import Search from "./Search/";
import Details from "./Details/";
import Create from "./Search/Mutations/Create";
import Deactivate from "./Search/Mutations/Deactivate";
import AddGroupElement from "./Search/Mutations/AddGroupElement";
import DeactivateGroupElement from "./Search/Mutations/DeactivateGroupElement";

const ForbiddenGroups = ({ uri }: RouteComponentProps) => (
  <Router>
    <Redirect from="/" to={`${uri}/search`} />
    <AddGroupElement path=":id/add-group-element/*" />
    <DeactivateGroupElement path=":id/deactivate-group-element/*" />
    <Create path="create/*" />
    <Deactivate path=":id/deactivate/*" />
    <Search path="search/*" />
    <Details path=":id/*" />
  </Router>
);

export default ForbiddenGroups;
