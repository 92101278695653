import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box, Text } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import {
  Education as EducationType,
  Maybe,
  ScienceDegree,
  Speciality,
  Qualification
} from "@ehealth/ehealth-ua.schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import Line from "../../../components/Line";

import { FunctionComponentWithFragments } from "../../../helpers/types";

type EducationProps = RouteComponentProps & {
  specialities?: Maybe<Maybe<Speciality>[]>;
  educations?: Maybe<Maybe<EducationType>[]>;
  qualifications?: Maybe<Maybe<Qualification>[]>;
  scienceDegree?: Maybe<Maybe<ScienceDegree>[]>;
};

const Education: FunctionComponentWithFragments<EducationProps> = ({
  specialities,
  educations,
  qualifications,
  scienceDegree
}) =>
  specialities || educations || qualifications || scienceDegree ? (
    <Box px={5} pt={5}>
      {specialities && (
        <EducationDefinitionList
          title={<Trans>Specialities</Trans>}
          data={specialities}
        />
      )}

      {educations && (
        <EducationDefinitionList
          title={<Trans>Education</Trans>}
          data={educations}
        />
      )}

      {qualifications && (
        <EducationDefinitionList
          title={<Trans>Qualifications</Trans>}
          data={qualifications}
        />
      )}

      {scienceDegree && (
        <>
          <Text fontSize={2} mb={6}>
            <Trans>Science degree</Trans>
          </Text>
          {scienceDegree.map((science) => {
            if (!science) return null;
            const {
              degree,
              speciality,
              diplomaNumber,
              institutionName,
              issuedDate,
              city,
              country
            } = science;

            return (
              <>
                <DefinitionListView
                  labels={{
                    degree: <Trans>Science degree</Trans>,
                    speciality: <Trans>Speciality</Trans>,
                    diplomaNumber: <Trans>Diploma number</Trans>,
                    institutionName: <Trans>Institution name</Trans>,
                    issuedDate: <Trans>Issued date</Trans>,
                    place: <Trans>Place of receipt</Trans>
                  }}
                  data={{
                    speciality: speciality && (
                      <DictionaryValue
                        name="SPECIALITY_TYPE"
                        item={speciality}
                      />
                    ),
                    degree: degree && (
                      <DictionaryValue name="SCIENCE_DEGREE" item={degree} />
                    ),
                    diplomaNumber,
                    institutionName,
                    issuedDate: issuedDate && i18n.date(issuedDate),
                    place: country && city && (
                      <>
                        <DictionaryValue name="COUNTRY" item={country} />,{" "}
                        {city}
                      </>
                    )
                  }}
                  labelWidth="180px"
                />
                <Line />
              </>
            );
          })}
        </>
      )}
    </Box>
  ) : (
    <EmptyData />
  );

type EducationDefinitionListProps = {
  title: React.ReactNode;
  data: Maybe<Speciality>[] | Maybe<EducationType>[] | Maybe<Qualification>[];
};

const EducationDefinitionList = ({
  title,
  data
}: EducationDefinitionListProps) => (
  <>
    <Text fontSize={2} mb={6}>
      {title}
    </Text>
    {data.map(
      ({
        attestationDate,
        attestationName,
        certificateNumber,
        level,
        qualificationType,
        speciality,
        validToDate,
        city,
        country,
        degree,
        diplomaNumber,
        institutionName,
        issuedDate,
        type
      }: any) => (
        <>
          <DefinitionListView
            labels={{
              degree: <Trans>Degree</Trans>,
              speciality: <Trans>Speciality</Trans>,
              diplomaNumber: <Trans>Diploma number</Trans>,
              institutionName: <Trans>Institution name</Trans>,
              issuedDate: <Trans>Issued date</Trans>,
              place: <Trans>Place of receipt</Trans>,
              type: <Trans>Speciality type</Trans>,
              level: <Trans>Speciality level</Trans>,
              qualificationType: <Trans>Qualification type</Trans>,
              certificateNumber: <Trans>Certificate number</Trans>,
              validToDate: <Trans>Valid to date</Trans>,
              attestationName: <Trans>Attestation name</Trans>,
              attestationDate: <Trans>Attestation date</Trans>
            }}
            data={{
              speciality: speciality && (
                <DictionaryValue name="SPECIALITY_TYPE" item={speciality} />
              ),
              level: level && (
                <DictionaryValue name="SPECIALITY_LEVEL" item={level} />
              ),
              qualificationType: qualificationType && (
                <DictionaryValue
                  name="SPEC_QUALIFICATION_TYPE"
                  item={qualificationType}
                />
              ),
              certificateNumber,
              validToDate: validToDate && i18n.date(validToDate),
              attestationName,
              attestationDate: attestationDate && i18n.date(attestationDate),
              degree: degree && (
                <DictionaryValue name="EDUCATION_DEGREE" item={degree} />
              ),
              diplomaNumber,
              institutionName,
              issuedDate: issuedDate && i18n.date(issuedDate),
              place: country && city && (
                <>
                  <DictionaryValue name="COUNTRY" item={country} />, {city}
                </>
              ),
              type: type && (
                <DictionaryValue name="QUALIFICATION_TYPE" item={type} />
              )
            }}
            labelWidth="180px"
          />
          <Line />
        </>
      )
    )}
  </>
);

Education.fragments = {
  entry: gql`
    fragment Education on Employee {
      additionalInfo {
        specialities {
          attestationDate
          attestationName
          certificateNumber
          level
          qualificationType
          speciality
          validToDate
        }
        educations {
          city
          country
          degree
          diplomaNumber
          institutionName
          issuedDate
          speciality
        }
        qualifications {
          certificateNumber
          institutionName
          issuedDate
          speciality
          type
        }
        scienceDegree {
          city
          country
          degree
          diplomaNumber
          institutionName
          issuedDate
          speciality
        }
      }
    }
  `
};

export default Education;
