import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Text } from "@rebass/emotion";

import { Form } from "@edenlabllc/ehealth-components";
import { Scalars, Maybe } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import * as Field from "../../../../components/Field";
import Popup from "../../../../components/Popup";

import { ActionType } from "../../../../helpers/types";

type UpdateProps = {
  id: Scalars["ID"];
  data: {
    medicationRequestAllowed?: boolean;
    medicationDispenseAllowed?: boolean;
    medicationRequestAllowedText?: Maybe<string>;
    medicationDispenseAllowedText?: Maybe<string>;
  };
};

const Update = ({ id, data }: UpdateProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  const { medicationRequestAllowed, medicationDispenseAllowed } = data || {};
  const action: ActionType =
    medicationRequestAllowed || medicationDispenseAllowed
      ? { name: <Trans>Disallow Request</Trans>, variant: "orange" }
      : { name: <Trans>Allow Request</Trans>, variant: "green" };

  return (
    <Mutation mutation={UpdateMedicalProgramMutation}>
      {(updateMedicalProgram: MutationFunction) => (
        <Box>
          <Ability action="write" resource="medical_program">
            <Button variant="none" border="none" px="0" py="0" onClick={toggle}>
              <Text color="rockmanBlue" fontWeight="bold" ml={1}>
                <Trans>Change</Trans>
              </Text>
            </Button>
          </Ability>
          <Popup
            visible={isPopupVisible}
            onCancel={toggle}
            title={action.name}
            okText={action.name}
            okButtonProps={{ variant: action.variant }}
            formId="updateMedicalProgram"
          >
            <Form
              id="updateMedicalProgram"
              onSubmit={async ({ reason }: { reason: string }) => {
                if (medicationRequestAllowed !== undefined) {
                  await updateMedicalProgram({
                    variables: {
                      input: {
                        id,
                        medicationRequestAllowed: !medicationRequestAllowed,
                        medicationRequestAllowedText: reason
                      }
                    }
                  });
                  toggle();
                }
                if (medicationDispenseAllowed !== undefined) {
                  await updateMedicalProgram({
                    variables: {
                      input: {
                        id,
                        medicationDispenseAllowed: !medicationDispenseAllowed,
                        medicationDispenseAllowedText: reason
                      }
                    }
                  });
                  toggle();
                }
              }}
            >
              <Trans
                id="Enter reason comment"
                render={({ translation }) => (
                  <Field.Textarea
                    name="reason"
                    placeholder={translation}
                    rows={5}
                    maxLength={500}
                    showLengthHint
                  />
                )}
              />
            </Form>
          </Popup>
        </Box>
      )}
    </Mutation>
  );
};

const UpdateMedicalProgramMutation = gql`
  mutation UpdateMedicalProgramMutation($input: UpdateMedicalProgramInput!) {
    updateMedicalProgram(input: $input) {
      medicalProgram {
        id
        medicationRequestAllowed
        medicationDispenseAllowed
        medicationRequestAllowedText
        medicationDispenseAllowedText
      }
    }
  }
`;

export default Update;
