import React from "react";
import { variant } from "styled-system";
import { ifNotProp } from "styled-tools";

import system from "@edenlabllc/ehealth-system-components";
import { SpinnerIcon } from "@edenlabllc/ehealth-icons";

export type ButtonVariantType =
  | "blue"
  | "green"
  | "orange"
  | "red"
  | "link"
  | "link-thin"
  | "linkDisabled"
  | "light"
  | "none";

const Button = system(
  {
    is: "button",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    border: 1,
    fontSize: 1,
    lineHeight: 1,
    px: 4,
    py: 2,
    variant: "light"
  },
  ifNotProp("disabled", "cursor: pointer;"),
  `
    display: inline-block;
    vertical-align: middle;
    outline: none;
    text-decoration: none;
  `,
  "display",
  "alignItems",
  "space",
  "fontSize",
  "border",
  "lineHeight",
  "width",
  "justifyContent",
  variant({
    key: "buttons"
  })
);

const Spinner = system(
  {
    is: SpinnerIcon,
    width: 14,
    height: 14,
    mr: 2
  },
  "space",
  "width",
  "height"
);

const Icon = system(
  {
    mr: 2
  },
  "space"
);

Button.displayName = "Button";

type ButtonProps = {
  loading?: boolean;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  type?: string;
  variant?: ButtonVariantType;
  width?: number | string;
  onClick?: () => void;
  onMouseOver?: () => void;
  border?: string;
  disabled?: boolean;
  form?: string;
  state?: any;
  is?: string;
  href?: string;
  mt?: number | string;
  ml?: number | string;
  mr?: number | string;
  mb?: number | string;
  mx?: number | string;
  px?: number | string;
  py?: number | string;
  alignItems?: string;
  outline?: string;
  style?: React.CSSProperties;
};

export default ({ loading, children, icon, ...rest }: ButtonProps) => (
  <Button {...rest}>
    {loading && <Spinner />}
    {icon && !loading && <Icon is={icon} />}
    <span>{children}</span>
  </Button>
);
