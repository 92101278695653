import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import {
  VaccinationProtocol as TVaccinationProtocol,
  Maybe
} from "@ehealth/ehealth-ua.schema";

import CodeableConcept from "../../../components/CodeableConcept";
import DefinitionListView from "../../../components/DefinitionListView";

import CodeableConceptList from "./CodeableConceptList";

type VaccinationProtocolProps = {
  vaccinationProtocol: Maybe<TVaccinationProtocol>;
  labelWidth: string;
};

const VaccinationProtocol = ({
  vaccinationProtocol,
  labelWidth
}: VaccinationProtocolProps) => {
  if (isEmpty(vaccinationProtocol)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labels={{
        ...(!isEmpty(vaccinationProtocol.authority) && {
          authority: <Trans>Authority</Trans>
        }),
        ...(vaccinationProtocol.description && {
          description: <Trans>Description</Trans>
        }),
        ...(vaccinationProtocol.doseSequence && {
          doseSequence: <Trans>Dose sequence</Trans>
        }),
        ...(vaccinationProtocol.series && {
          series: <Trans>Series</Trans>
        }),
        ...(vaccinationProtocol.seriesDoses && {
          seriesDoses: <Trans>Series doses</Trans>
        }),
        ...(!isEmpty(vaccinationProtocol.targetDiseases) && {
          targetDiseases: <Trans>Target diseases</Trans>
        })
      }}
      data={{
        ...vaccinationProtocol,
        ...(!isEmpty(vaccinationProtocol.authority) && {
          authority: (
            <CodeableConcept codeableConcept={vaccinationProtocol.authority} />
          )
        }),
        ...(!isEmpty(vaccinationProtocol.targetDiseases) && {
          targetDiseases: (
            <CodeableConceptList
              codeableConceptList={vaccinationProtocol.targetDiseases}
            />
          )
        })
      }}
      labelWidth={labelWidth}
    />
  );
};

export default VaccinationProtocol;

VaccinationProtocol.fragments = {
  entry: gql`
    fragment VaccinationProtocol on VaccinationProtocol {
      authority {
        ...CodeableConcept
      }
      description
      doseSequence
      series
      seriesDoses
      targetDiseases {
        ...CodeableConcept
      }
    }
    ${CodeableConcept.fragments!.entry}
  `
};
