import React from "react";
import { gql } from "graphql-tag";

const ValueTime = ({ valueTime }: { valueTime: string }) => {
  if (!valueTime) return null;
  return <>valueTime</>;
};

export default ValueTime;

ValueTime.fragments = {
  entry: gql`
    fragment ValueTime on ValueTime {
      time
    }
  `
};
