import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex } from "@rebass/emotion";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { PositiveIcon, NegativeIcon } from "@edenlabllc/ehealth-icons";
import {
  MedicalProgram,
  MedicalProgramConnection
} from "@ehealth/ehealth-ua.schema";

import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import Link from "../../../components/Link";
import Table, { SortingParams } from "../../../components/Table";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";

import dateFormatter from "../../../helpers/dateFormatter";

type MedicalProgramsTableProps = {
  medicalPrograms: MedicalProgramConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const MedicalProgramsTable = ({
  medicalPrograms,
  locationParams,
  setLocationParams
}: MedicalProgramsTableProps) => (
  <Table
    data={medicalPrograms}
    header={{
      databaseId: <Trans>ID</Trans>,
      name: <Trans>Medical program name</Trans>,
      isActive: <Trans>Status</Trans>,
      type: <Trans>Type</Trans>,
      mrBlankType: <Trans>Type of Medication request blank</Trans>,
      insertedAt: <Trans>Inserted at</Trans>,
      medicationRequestAllowed: <Trans>Medication request allowed</Trans>,
      medicationDispenseAllowed: <Trans>Medication dispense allowed</Trans>,
      action: <Trans>Action</Trans>
    }}
    renderRow={({
      id,
      insertedAt,
      isActive,
      name,
      type,
      mrBlankType,
      medicationRequestAllowed,
      medicationDispenseAllowed,
      ...medicalProgram
    }: MedicalProgram) => ({
      ...medicalProgram,
      name,
      insertedAt: dateFormatter(
        i18n.locale,
        {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        },
        insertedAt
      ),
      isActive: (
        <Badge
          type="ACTIVE_STATUS_F"
          name={isActive}
          variant={!isActive}
          display="block"
        />
      ),
      type: <DictionaryValue name="MEDICAL_PROGRAM_TYPE" item={type} />,
      mrBlankType: mrBlankType && (
        <DictionaryValue name="MR_BLANK_TYPES" item={mrBlankType} />
      ),
      medicationRequestAllowed: (
        <Flex justifyContent="center">
          {medicationRequestAllowed ? <PositiveIcon /> : <NegativeIcon />}
        </Flex>
      ),
      medicationDispenseAllowed: (
        <Flex justifyContent="center">
          {medicationDispenseAllowed ? <PositiveIcon /> : <NegativeIcon />}
        </Flex>
      ),
      action: (
        <Link to={`../${id}`} fontWeight="bold">
          <Trans>Details</Trans>
        </Link>
      )
    })}
    sortableFields={["name", "insertedAt"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    tableName="medicalPrograms/search"
    hiddenFields="medicationDispenseAllowed,medicationRequestAllowed"
  />
);

MedicalProgramsTable.fragments = {
  entry: gql`
    fragment MedicalPrograms on MedicalProgram {
      id
      databaseId
      name
      isActive
      insertedAt
      type
      medicationRequestAllowed
      medicationDispenseAllowed
      mrBlankType
    }
  `
};

export default MedicalProgramsTable;
