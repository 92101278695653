import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex } from "@rebass/emotion";

import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  RuleEngineRule,
  RuleEngineRuleConnection
} from "@ehealth/ehealth-ua.schema";

import Button from "../../../components/Button";
import DictionaryValue from "../../../components/DictionaryValue";
import Link from "../../../components/Link";
import Popup from "../../../components/Popup";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type RuleEngineRulesTableProps = {
  ruleEngineRules: RuleEngineRuleConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  onChoose?: (items: any) => void;
  onClosePopup?: () => void;
  tableName?: string;
};

const RuleEngineRulesTable = ({
  ruleEngineRules,
  tableName = "ruleEngineSets/search",
  setLocationParams,
  locationParams,
  onChoose,
  onClosePopup
}: RuleEngineRulesTableProps) => {
  const [isConfirmPopupVisible, setConfirmPopupVisibility] = useState(false);
  const confirmToggle = () => setConfirmPopupVisibility(!isConfirmPopupVisible);
  const [ruleItems, setRuleItems] = useState<RuleEngineRule["items"]>(
    undefined
  );

  return (
    <>
      <Table
        data={ruleEngineRules}
        header={{
          databaseId: <Trans>ID</Trans>,
          name: <Trans>Rule engine rule title</Trans>,
          system: <Trans>Coding system</Trans>,
          code: <Trans>Code</Trans>,
          isActive: <Trans>Status</Trans>,
          insertedAt: <Trans>Inserted at</Trans>,
          action: <Trans>Action</Trans>
        }}
        renderRow={({
          databaseId,
          name,
          isActive,
          insertedAt,
          code,
          id,
          items = []
        }: RuleEngineRule) => ({
          databaseId,
          name,
          system: (
            <DictionaryValue
              name="eHealth/rule_engine_dictionaries"
              item={code.system}
            />
          ),
          code: <DictionaryValue name={code.system} item={code.code} />,
          isActive: (
            <Flex justifyContent="center">
              {isActive ? <PositiveIcon /> : <NegativeIcon />}
            </Flex>
          ),
          insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
          action: onChoose ? (
            <Button
              variant="link"
              onClick={() => {
                confirmToggle();
                setRuleItems(items);
              }}
            >
              <Trans>Add</Trans>
            </Button>
          ) : (
            <Link to={`../${id}`} fontWeight="bold">
              <Trans>View</Trans>
            </Link>
          )
        })}
        sortableFields={["insertedAt", "name"]}
        sortingParams={parseSortingParams(locationParams.orderBy)}
        onSortingChange={(sortingParams: SortingParams) =>
          setLocationParams({
            ...locationParams,
            orderBy: stringifySortingParams(sortingParams)
          })
        }
        tableName={tableName}
        isShort={!!onChoose}
      />
      <Popup
        visible={isConfirmPopupVisible}
        onCancel={confirmToggle}
        onOk={() => {
          confirmToggle();
          onClosePopup!();
          return onChoose!(ruleItems);
        }}
        okText={<Trans>Next</Trans>}
        title={
          <Trans>
            Filling from existing rule engine rule will clear currently
            configured rules, are you sure want to proceed?
          </Trans>
        }
      />
    </>
  );
};

RuleEngineRulesTable.fragments = {
  entry: gql`
    fragment RuleEngineRules on Rule {
      id
      code {
        code
        system
      }
      databaseId
      deactivationReason
      description
      name
      isActive
      itemsComparator
      insertedAt
      updatedAt
      items {
        number
        description
        value {
          json
          string
        }
      }
    }
  `
};

export default RuleEngineRulesTable;
