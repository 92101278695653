export const pollStatus = async (
  fetcher: $TSFixMe,
  timeout: number = 120 * 1000
) => {
  let timeToStop = timeout;
  await new Promise((resolve, reject) => {
    let intervalId: NodeJS.Timer;
    intervalId = setInterval(async () => {
      if (timeout) {
        timeToStop -= 1000;
        if (timeToStop < 0) {
          clearInterval(intervalId);
        }
      }
      try {
        const statusResponse = await fetcher();
        if (statusResponse.data && statusResponse.data.status !== "PENDING") {
          clearInterval(intervalId);
          resolve(statusResponse);
        }
      } catch (e) {
        reject(e);
      }
    }, 1000);
  });
};
