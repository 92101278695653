import _slicedToArray from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import camelCase from "lodash/camelCase";
var parseSortingParams = function parseSortingParams(orderBy) {
  if (!orderBy) return;
  var _orderBy$split = orderBy.split(/_(?=[^_]+$)/),
    _orderBy$split2 = _slicedToArray(_orderBy$split, 2),
    name = _orderBy$split2[0],
    order = _orderBy$split2[1];
  return {
    name: camelCase(name),
    order: order
  };
};
export default parseSortingParams;