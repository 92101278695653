import React from "react";
import { Box } from "@rebass/emotion";
import { NumberWidgetProps } from "react-awesome-query-builder";

import * as Field from "../../../../../components/Field";

export default (props: NumberWidgetProps) => {
  const {
    value,
    setValue,
    readonly,
    min,
    max,
    step,
    placeholder,
    customProps
  } = props;

  const onChange = (e: $TSFixMe) => {
    let val = e.target.value;
    if (val === "" || val === null) val = undefined;
    else val = Number(val);
    setValue(val);
  };
  const numberValue = value === undefined ? "" : value;

  return (
    <Box width={"120px"}>
      <Field.InputView
        placeholder={placeholder}
        input={{
          onChange,
          min,
          max,
          step,
          value: numberValue,
          type: "number",
          disabled: readonly
        }}
        hideErrors
        {...customProps}
      />
    </Box>
  );
};
