import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";

import { getPhones } from "@edenlabllc/ehealth-utils";
import { Person } from "@ehealth/ehealth-ua.schema";

import { Heading, Table } from "./index";

type PhonesInfoProps = {
  data: Person[];
};

const PhonesInfo = ({ data }: PhonesInfoProps) => (
  <>
    <Heading>
      <Trans>Phones</Trans>
    </Heading>
    <Table
      header={{
        phones: <Trans>Phones</Trans>
      }}
      data={data}
      renderRow={({ phones = [] }) => ({
        phones: phones.length > 0 && getPhones(phones)
      })}
    />
  </>
);

PhonesInfo.fragments = {
  entry: gql`
    fragment PhonesInfo on Person {
      phones {
        type
        number
      }
    }
  `
};

export default PhonesInfo;
