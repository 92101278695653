import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { ApolloQueryResult, QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, get, isEmpty } from "lodash";

import { Validation, Form } from "@edenlabllc/ehealth-components";
import {
  SettlementConnection,
  QuerySettlementsArgs
} from "@ehealth/ehealth-ua.schema";

import * as Field from "../Field";
import { ADDRESS_ITEM_PATTERN } from "../../constants/validationPatterns";

type SettlementFieldProps = {
  name: string;
  regionName: string;
  districtName: string;
};

const SettlementField = ({
  name,
  regionName,
  districtName
}: SettlementFieldProps) => (
  <Trans
    id="Enter settlement name"
    render={({ translation }) => (
      <Form.Spy>
        {({ values }: $TSFixMe) => {
          const currRegionVal = get(values, regionName);
          const currDistrictVal = get(values, districtName);
          return (
            <Query
              query={SettlementQuery}
              fetchPolicy="cache-first"
              variables={{
                first: 20,
                filter: {
                  district: currDistrictVal,
                  region: currRegionVal
                }
              }}
            >
              {({
                data,
                error,
                refetch
              }: QueryResult<{ settlements: SettlementConnection }>) => {
                const { settlements } = data || {};
                if (error || isEmpty(settlements)) {
                  return (
                    <>
                      <Field.Text
                        name={name}
                        label={<Trans id="Settlement" />}
                        placeholder={translation}
                      />
                      <Trans
                        id="Invalid format"
                        render={({ translation }) => (
                          <Validation.Matches
                            field={name}
                            options={ADDRESS_ITEM_PATTERN}
                            message={translation}
                          />
                        )}
                      />
                    </>
                  );
                }
                return (
                  <>
                    <SettlementSelect
                      refetch={refetch}
                      settlements={(settlements && settlements.nodes) || []}
                      name={name}
                      translation={translation}
                      district={currDistrictVal}
                      region={currRegionVal}
                    />
                  </>
                );
              }}
            </Query>
          );
        }}
      </Form.Spy>
    )}
  />
);

export default SettlementField;

type SettlementSelectProps = {
  settlements: SettlementConnection["nodes"];
  refetch: (
    variables: QuerySettlementsArgs
  ) => Promise<
    ApolloQueryResult<{
      settlements: SettlementConnection;
    }>
  >;
  name: string;
  translation: React.ReactNode;
  district: string;
  region: string;
};

const SettlementSelect = ({
  settlements,
  refetch,
  name,
  translation,
  district,
  region
}: SettlementSelectProps) => {
  return (
    <Field.Select
      name={name}
      label={<Trans id="Settlement" />}
      filterOptions={{ keys: ["name"] }}
      placeholder={translation}
      items={settlements!}
      itemToString={(item) => {
        return typeof item === "string"
          ? item
          : item && item.name
          ? item.name
          : "";
      }}
      onInputValueChange={debounce(
        (settlement, { selectedItem, inputValue }) => {
          if (
            selectedItem &&
            selectedItem.name &&
            selectedItem.name === inputValue
          ) {
            return;
          }

          return (
            !isEmpty(settlement) &&
            selectedItem !== inputValue &&
            refetch({
              first: 20,
              filter: {
                name: inputValue,
                region: region,
                district: district
              }
            })
          );
        },
        300
      )}
    />
  );
};

export const SettlementQuery = gql`
  query SettlementQuery($first: Int, $filter: SettlementFilter) {
    settlements(filter: $filter, first: $first) {
      nodes {
        id
        databaseId
        name
      }
    }
  }
`;
