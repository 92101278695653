import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import Popup from "../../../../components/Popup";

type DeactivateProps = {
  id: string;
  name: string;
  isActive: boolean;
};

const Deactivate = ({ id, name, isActive }: DeactivateProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Ability action="deactivate" resource="innm">
      <Mutation mutation={DeactivateInnmInputMutation}>
        {(deactivateInnmInput: MutationFunction) => (
          <>
            <Button
              width="100%"
              onClick={toggle}
              variant="red"
              disabled={!isActive}
            >
              <Trans>Deactivate</Trans>
            </Button>
            <Popup
              visible={isPopupVisible}
              onCancel={toggle}
              title={
                <Box mb={4}>
                  <Trans>Deactivate</Trans> "{name}
                  "?
                </Box>
              }
              okText={<Trans>Deactivate</Trans>}
              onOk={async () => {
                await deactivateInnmInput({
                  variables: {
                    input: {
                      id
                    }
                  }
                });
                toggle();
              }}
            />
          </>
        )}
      </Mutation>
    </Ability>
  );
};

const DeactivateInnmInputMutation = gql`
  mutation DeactivateInnmInputMutation($input: DeactivateInnmInput!) {
    deactivateInnm(input: $input) {
      innm {
        id
        isActive
      }
    }
  }
`;

export default Deactivate;
