import React from "react";
import {
  ValueSourcesProps,
  ValueSourcesItems
} from "react-awesome-query-builder";

import * as Field from "../../../../../components/Field";

type TValueSourceItem = {
  key: string | undefined;
  value: string;
};

export default ({
  valueSources,
  valueSrc,
  setValueSrc,
  readonly,
  title
}: ValueSourcesProps) => {
  const renderOptions = (fields: ValueSourcesItems) => {
    // @ts-expect-error TS(7031): Binding element 'srcKey' implicitly has an 'any' t... Remove this comment to see the full error message
    return fields.map(([srcKey, info]) => {
      return { key: srcKey, value: info.label };
    });
  };

  const onChange = (selectedItem: TValueSourceItem) =>
    setValueSrc((selectedItem && selectedItem.key) || "");

  // @ts-ignore
  const labelMap = new Map(valueSources);
  const item = labelMap.get(valueSrc);

  return (
    <Field.SelectFieldView
      placeholder={title || ""}
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      value={item && item.label}
      onChange={onChange}
      disabled={readonly}
      items={renderOptions(valueSources)}
      itemToString={(item) => (item ? item.value : "")}
      variant={"select"}
      hideErrors
    />
  );
};
