import React from "react";
import { gql } from "graphql-tag";
import { Flex, Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Ratio as TRatio } from "@ehealth/ehealth-ua.schema";

import Quantity from "./Quantity";

type RatioProps = {
  ratio: TRatio;
};

const Ratio = ({ ratio }: RatioProps) => {
  if (isEmpty(ratio)) return null;

  const { numerator, denominator } = ratio;
  return (
    <Flex>
      <Box mr={1}>
        <Quantity quantity={numerator} />
      </Box>
      {"/"}
      <Box ml={1}>
        <Quantity quantity={denominator} />
      </Box>
    </Flex>
  );
};

export default Ratio;

Ratio.fragments = {
  entry: gql`
    fragment Ratio on Ratio {
      denominator {
        ...Quantity
      }
      numerator {
        ...Quantity
      }
    }
    ${Quantity.fragments.entry}
  `
};
