import { gql } from "graphql-tag";
import Pagination from "../../../components/Pagination";
import MedicationDispense from "../../PracticalMonitoring/Details/MedicationDispense";

export const ImpersonalMedicationDispensesQuery = gql`
  query ImpersonalMedicationDispensesQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImpersonalMedicationDispenseFilter!
    $orderBy: MedicationDispenseOrderBy
  ) {
    impersonalMedicationDispenses(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

export const ImpersonalMedicationDispenseByIDQuery = gql`
  query ImpersonalMedicationDispenseQuery($id: ID!, $patientId: String!) {
    impersonalMedicationDispense(id: $id, patientId: $patientId) {
      ...MedicationDispense
    }
  }
  ${MedicationDispense.fragments.entry}
`;
