const DECLARATION = {
  TERMINATED: "розірвана",
  ACTIVE: "активна",
  REJECTED: "відхилена",
  PENDING_VERIFICATION: "очікує підтвердження",
  CLOSED: "деактивована"
};

const PERSON = {
  ACTIVE: "активна",
  INACTIVE: "неактивна"
};

const LEGALENTITY = {
  ACTIVE: "активний",
  CLOSED: "неактивний",
  SUSPENDED: "призупинений",
  REORGANIZED: "реорганізований"
};

const DIVISIONS = {
  ACTIVE: "активний",
  INACTIVE: "неактивний"
};

const LEGAL_ENTITY_RELATION = {
  MERGED_FROM: "підпорядкованого",
  MERGED_TO: "основного"
};

const CONTRACT_REQUEST = {
  NEW: "Нова",
  IN_PROCESS: "Обробляється",
  DECLINED: "Відмінена",
  APPROVED: "Підтверджена",
  PENDING_NHS_SIGN: "Очікує на підписання НСЗУ",
  NHS_SIGNED: "Підписана НСЗУ",
  TERMINATED: "Завершена",
  SIGNED: "Підписана"
};

const CONTRACT = {
  VERIFIED: "Верифікований",
  TERMINATED: "Завершений"
};

const SUSPENDED = {
  // @ts-expect-error statuses boolean key
  [false]: "Діючий",
  // @ts-expect-error statuses boolean key
  [true]: "Призупинений"
};

const EDR_VERIFY_STATUS = {
  // @ts-expect-error statuses boolean key
  [true]: "Веріфіковано",
  // @ts-expect-error statuses boolean key
  [false]: "Неверіфіковано"
};

const ACTIVE_STATUS_F = {
  // @ts-expect-error statuses boolean key
  [true]: "Активна",
  // @ts-expect-error statuses boolean key
  [false]: "Неактивна"
};

const ACTIVE_STATUS_M = {
  // @ts-expect-error statuses boolean key
  [true]: "Активний",
  // @ts-expect-error statuses boolean key
  [false]: "Неактивний"
};

const ACTIVE_INACTIVE_M = {
  ACTIVE: "Активний",
  INACTIVE: "Неактивний"
};

const STATUS_VERIFICATION = {
  IN_REVIEW: "На опрацюванні",
  NOT_VERIFIED: "Не верифіковано",
  VERIFICATION_NEEDED: "Потребує верифікації",
  VERIFICATION_MANUAL_NEEDED: "Потребує ручної верифікації",
  VERIFICATION_AUTO_NEEDED: "Потребує автоматичної верифікації",
  VERIFIED: "Верифіковано"
};

const DRACS_STATUS_VERIFICATION = {
  VERIFICATION_NEEDED: "Потребує верифікації",
  VERIFIED: "Верифіковано",
  NOT_VERIFIED: "Не верифіковано",
  IN_REVIEW: "На опрацюванні"
};

const DRACS_AR_OP_NAME = {
  1: "Реєстрація",
  2: "Видалення",
  3: "Анулювання",
  4: "Зміни"
};

const SELECTED_FILTERS_STATUSES = {
  ...ACTIVE_INACTIVE_M,
  ...CONTRACT_REQUEST,
  ...CONTRACT
};

const MEDICATION_REQUEST_ALLOWED = {
  // @ts-expect-error statuses boolean key
  [true]: "Дозволено",
  // @ts-expect-error statuses boolean key
  [false]: "Не дозволено"
};

const NO_TAX_ID = {
  // @ts-expect-error statuses boolean key
  [true]: "ІПН відсутній",
  // @ts-expect-error statuses boolean key
  [false]: "З ІПН"
};

const JOBS = {
  FAILED: "Помилка",
  PENDING: "В процесі виконання",
  PROCESSED: "Виконана"
};

const TASKS = {
  ABORTED: "Перервана",
  CONSUMED: "Прийнята на обробку",
  FAILED: "Помилка",
  NEW: "Створена",
  PENDING: "В процесі виконання",
  PROCESSED: "Виконана",
  RESCUED: "Системна помилка"
};

const REASON = {
  NO_TAX_ID: "Без ІПН"
};

const PATIENT_MERGE_REQUEST = {
  NEW: "Нова",
  POSTPONE: "Відкладена"
};

const EMPLOYEE_STATUS = {
  APPROVED: "Активний",
  DISMISSED: "Звільнений",
  REORGANIZED: "Реорганізований"
};

const EMPLOYEE_REQUEST_STATUS = {
  APPROVED: "Схвалено",
  EXPIRED: "Прострочено",
  NEW: "Очікує верифікації",
  REJECTED: "Відхилено"
};

const ENTITY_TYPE = {
  patient: "Пацієнти",
  declaration: "Декларації",
  medication: "Медикаменти"
};

const YES_NO = {
  // @ts-expect-error statuses boolean key
  [true]: "Так",
  // @ts-expect-error statuses boolean key
  [false]: "Ні"
};

const YES_NO_NULL = {
  // @ts-expect-error statuses boolean key
  [null]: "Відсутнє значення",
  // @ts-expect-error statuses boolean key
  [true]: "Так",
  // @ts-expect-error statuses boolean key
  [false]: "Ні"
};

const JUSTIFICATION_STATUS = {
  ACTIVE: "активний",
  TERMINATED: "розірваний",
  INACTIVE: "неактивний"
};

const APPROVAL_STATUS = {
  ACTIVE: "Активний",
  CANCELLED: "Відхилений",
  NEW: "Новий",
  TERMINATED: "Припинений"
};

const MEDICATION_REGISTRY_JOB_STATUSES = {
  PROCESSED: "Виконана",
  FAILED: "Помилка",
  NEW: "Створена",
  PENDING: "В процесі виконання",
  ABORTED: "Перервана",
  CONSUMED: "Прийнята на обробку",
  RESCUED: "Системна помилка"
};

const PERSON_DRACS_DEATH_CANDIDATES_STATUS = {
  NEW: "Новий"
};

const DRACS_DEATH_STATUSES = {
  READY: "Готовий до синхронізації",
  IN_PROCESS: "В процесі",
  COMPLETED: "Виконана"
};

const STATUSES: any = {
  ACTIVE_INACTIVE_M,
  CONTRACT,
  PERSON,
  PATIENT_MERGE_REQUEST,
  DECLARATION,
  REASON,
  LEGALENTITY,
  DIVISIONS,
  LEGAL_ENTITY_RELATION,
  CONTRACT_REQUEST,
  JOBS,
  TASKS,
  MEDICATION_REQUEST_ALLOWED,
  SELECTED_FILTERS_STATUSES,
  SUSPENDED,
  ACTIVE_STATUS_F,
  ACTIVE_STATUS_M,
  NO_TAX_ID,
  EDR_VERIFY_STATUS,
  EMPLOYEE_STATUS,
  EMPLOYEE_REQUEST_STATUS,
  ENTITY_TYPE,
  YES_NO,
  YES_NO_NULL,
  STATUS_VERIFICATION,
  JUSTIFICATION_STATUS,
  APPROVAL_STATUS,
  MEDICATION_REGISTRY_JOB_STATUSES,
  DRACS_STATUS_VERIFICATION,
  DRACS_AR_OP_NAME,
  PERSON_DRACS_DEATH_CANDIDATES_STATUS,
  DRACS_DEATH_STATUSES
};

export default STATUSES;
