import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";

import { getPhones } from "@edenlabllc/ehealth-utils";
import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import { Division, Address, Maybe } from "@ehealth/ehealth-ua.schema";

import AddressView from "../../../../components/AddressView";
import DictionaryValue from "../../../../components/DictionaryValue";
import DefinitionListView from "../../../../components/DefinitionListView";

import STATUSES from "../../../../helpers/statuses";

type GeneralInfoProps = RouteComponentProps & {
  type: Division["type"];
  addresses: Division["addresses"];
  mountainGroup: Division["mountainGroup"];
  phones: Division["phones"];
  email: Division["email"];
  dlsVerified: Division["dlsVerified"];
};

const GeneralInfo = ({
  type,
  addresses,
  mountainGroup,
  phones,
  email,
  dlsVerified
}: GeneralInfoProps) => {
  const residenceAddress = addresses.find(
    (a: Maybe<Address>) => a && a.type === "RESIDENCE"
  );
  const receptionAddress = addresses.find(
    (a: Maybe<Address>) => a && a.type === "RECEPTION"
  );
  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          type: <Trans>Type</Trans>,
          residenceAddress: <Trans>Residence Address</Trans>,
          receptionAddress: <Trans>Reception Address</Trans>,
          mountainGroup: <Trans>Mountain region</Trans>,
          phones: <Trans>Phone</Trans>,
          email: <Trans>Email</Trans>,
          dlsVerified: <Trans>DLS Verification</Trans>
        }}
        data={{
          email,
          type: type && <DictionaryValue name="DIVISION_TYPE" item={type} />,
          mountainGroup: mountainGroup ? <PositiveIcon /> : <NegativeIcon />,
          residenceAddress: residenceAddress && (
            <AddressView data={residenceAddress} />
          ),
          receptionAddress: receptionAddress && (
            <AddressView data={receptionAddress} />
          ),
          phones: getPhones(phones),
          // @ts-expect-error statuses boolean key
          dlsVerified: STATUSES.YES_NO[dlsVerified]
        }}
      />
    </Box>
  );
};

GeneralInfo.fragments = {
  entry: gql`
    fragment DivisionGeneralInfo on Division {
      type
      legalEntity {
        id
        type
      }
      addresses {
        ...Addresses
      }
      mountainGroup
      phones {
        type
        number
      }
      email
      dlsVerified
    }
    ${AddressView.fragments.entry}
  `
};

export default GeneralInfo;
