import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { CapitationContractRequestConnection } from "@ehealth/ehealth-ua.schema";

import LoadingOverlay from "../../../../components/LoadingOverlay";
import Pagination from "../../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../../components/SearchForm";
import contractFormFilteredParams from "../../../../helpers/contractFormFilteredParams";
import pagination from "../../../../helpers/pagination";
import { CONTRACT_TYPES } from "../../../../constants/contracts";
import ContractRequestsNav from "../../ContractRequestsNav";
import { PrimarySearchFields, SecondarySearchFields } from "./SearchFields";
import ContractTable from "./ContractsTable";

const CapitationContractRequestsSearch = (_props: RouteComponentProps) => (
  <Box p={6}>
    <ContractRequestsNav />
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const { filter, first, last, after, before, orderBy } = locationParams;
        return (
          <>
            <SearchForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
              renderPrimary={PrimarySearchFields}
              renderSecondary={SecondarySearchFields}
            />
            <Query
              query={SearchCapitationContractRequestsQuery}
              fetchPolicy="network-only"
              variables={{
                ...pagination({ first, last, after, before, orderBy }),
                filter: contractFormFilteredParams(
                  filter,
                  CONTRACT_TYPES.CAPITATION
                )
              }}
            >
              {({
                loading,
                data
              }: QueryResult<{
                capitationContractRequests: CapitationContractRequestConnection;
              }>) => {
                if (isEmpty(data) || isEmpty(data.capitationContractRequests))
                  return null;
                const {
                  capitationContractRequests: {
                    nodes: capitationContractRequests = [],
                    pageInfo
                  }
                } = data;

                return (
                  <LoadingOverlay loading={loading}>
                    <ContractTable
                      capitationContractRequests={capitationContractRequests}
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                    />
                    <Pagination {...pageInfo} />
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        );
      }}
    </LocationParams>
  </Box>
);

const SearchCapitationContractRequestsQuery = gql`
  query SearchCapitationContractRequestsQuery(
    $filter: CapitationContractRequestFilter
    $orderBy: CapitationContractRequestOrderBy
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    capitationContractRequests(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        ...CapitationContractRequests
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ContractTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default CapitationContractRequestsSearch;
