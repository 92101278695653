import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  Form,
  LocationParams,
  Validation
} from "@edenlabllc/ehealth-components";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { Dictionary, EpisodeConnection } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import CodeableConcept from "../../../components/CodeableConcept";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  SetSearchParams,
  TLocationParams
} from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { MonitoringTabProps } from "../../../helpers/types";
import pagination from "../../../helpers/pagination";
import paramToBase64 from "../../../helpers/paramToBase64";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

import { PatientSearchField } from "../../ClinicalMonitoring/PatientSearchField";

const EpisodesTab = ({
  id,
  query = EpisodesQuery,
  isClinicalMonitoring = false
}: MonitoringTabProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="episode"
  >
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Query
          skip={!locationParams.first && !locationParams.last}
          query={query}
          fetchPolicy="network-only"
          variables={prepareParamsToQuery(
            id!,
            locationParams,
            isClinicalMonitoring
          )}
        >
          {({
            data = {},
            loading
          }: QueryResult<{ [key: string]: EpisodeConnection }>) => {
            const episodes = isClinicalMonitoring
              ? data.impersonalEpisodes
              : data.episodes;

            return (
              <LoadingOverlay loading={loading}>
                <Box p={1}>
                  <SearchForm
                    initialValues={locationParams}
                    onSubmit={setLocationParams}
                    renderPrimary={() =>
                      primarySearchFields(isClinicalMonitoring)
                    }
                    onResetSkipPagination
                  />
                </Box>
                {isEmpty(episodes) || isEmpty(episodes.nodes) ? (
                  <EmptyData mx={2} />
                ) : (
                  <Box mb={6} p={2}>
                    <Box>
                      <Text>
                        <Trans>Total found</Trans>: {episodes.totalEntries}
                      </Text>
                    </Box>
                    <SearchResultTable
                      data={episodes.nodes}
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                      statusSystem="eHealth/episode_statuses"
                      tableName="episodes"
                      pathSlug={
                        isClinicalMonitoring
                          ? `episode/${
                              locationParams.filter &&
                              locationParams.filter.patientId
                            }`
                          : "episode"
                      }
                      isClinicalMonitoring={isClinicalMonitoring}
                    />
                    <Pagination {...episodes.pageInfo} />
                  </Box>
                )}
              </LoadingOverlay>
            );
          }}
        </Query>
      )}
    </LocationParams>
  </Ability>
);

export default EpisodesTab;

const EpisodesQuery = gql`
  query EpisodesQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: EpisodeFilter!
    $orderBy: EpisodeOrderBy
  ) {
    episodes(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

const prepareParamsToQuery = (
  id: string,
  locationParams: SearchParams,
  isClinicalMonitoring: boolean
) => {
  const {
    episodePeriod,
    status,
    managingOrganizationId,
    episodeId,
    ...restFilterParams
  } = locationParams.filter || {};

  return {
    orderBy: "INSERTED_AT_DESC",
    ...pagination(locationParams),
    filter: {
      ...(!isClinicalMonitoring && { personId: id }),
      ...restFilterParams,
      ...(status && {
        status: status.toUpperCase()
      }),
      ...(managingOrganizationId && {
        managingOrganizationId: paramToBase64(
          "LegalEntity",
          managingOrganizationId
        )
      }),
      ...(episodePeriod && {
        period: `${episodePeriod.from}/${episodePeriod.to}`
      }),
      ...(episodeId && {
        id: paramToBase64("Episode", episodeId)
      })
    }
  };
};

const primarySearchFields = (isClinicalMonitoring: boolean) => {
  const { i18n } = useLingui();

  return (
    <Flex flexDirection="column">
      <PatientSearchField isClinicalMonitoring={isClinicalMonitoring} />
      <Flex>
        <Box width={1 / 3} mt={3} px={1}>
          <DictionaryValue name="eHealth/episode_statuses">
            {(dict: Dictionary["values"]) => (
              <Field.Select
                name="filter.status"
                label={<Trans id="Episode status" />}
                placeholder={i18n._(t`Select status`)}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || item}
                variant="select"
              />
            )}
          </DictionaryValue>
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter managing organization ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.managingOrganizationId"
                label={<Trans id="Managing organization ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.managingOrganizationId"
            options={UUID_PATTERN}
            message="Invalid managing organization ID"
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter current diagnosis code"
            render={({ translation }) => (
              <Field.Text
                name="filter.code"
                label={<Trans id="Current diagnosis code" />}
                placeholder={translation}
              />
            )}
          />
        </Box>
      </Flex>
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter episode period"
            render={() => (
              <Field.RangePicker
                rangeNames={[
                  "filter.episodePeriod.from",
                  "filter.episodePeriod.to"
                ]}
                label={<Trans id="Episode period" />}
              />
            )}
          />
        </Box>
        <Form.Spy>
          {({ values = {} }: $TSFixMe) => {
            if (
              (values.filter &&
                values.filter.episodePeriod &&
                values.filter.episodePeriod.from) ||
              (values.filter &&
                values.filter.episodePeriod &&
                values.filter.episodePeriod.to)
            ) {
              return (
                <>
                  <Trans
                    id="Required field"
                    render={({ translation }) => (
                      <Validation.Required
                        field="filter.episodePeriod.from"
                        message={translation}
                      />
                    )}
                  />
                  <Trans
                    id="Required field"
                    render={({ translation }) => (
                      <Validation.Required
                        field="filter.episodePeriod.to"
                        message={translation}
                      />
                    )}
                  />
                </>
              );
            }
            return null;
          }}
        </Form.Spy>
        {isClinicalMonitoring && (
          <Box px={1} width={1 / 3} mt={3}>
            <Trans
              id="Enter episode ID"
              render={({ translation }) => (
                <Field.Text
                  name="filter.episodeId"
                  label={<Trans id="Episode ID" />}
                  placeholder={translation}
                />
              )}
            />
            <Validation.Matches
              field="filter.episodeId"
              options={UUID_PATTERN}
              message="Invalid episode ID"
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

type SearchResultTableProps = {
  data: any; // common table for all monitoring instances
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  tableName: string;
  pathSlug: string;
  statusSystem?: string;
  isClinicalMonitoring?: boolean;
  statusValues?: any;
  customHeaders?: any;
  codeColumnTextType?: string;
};

export const SearchResultTable = ({
  data,
  locationParams,
  setLocationParams,
  statusSystem,
  tableName,
  statusValues,
  pathSlug,
  customHeaders,
  isClinicalMonitoring,
  codeColumnTextType
}: SearchResultTableProps) => {
  const { i18n } = useLingui();
  if (!(data && data.length)) return null;

  const codeHeader: { [key: string]: string } = {
    default: i18n._(t`Diagnoses code`),
    observation: i18n._(t`Observation code`),
    service: i18n._(t`Service code`),
    vaccine: i18n._(t`Vaccine code`)
  };

  return (
    <Table
      data={data}
      header={{
        databaseId: <Trans>ID</Trans>,
        ...(isClinicalMonitoring && {
          diagnosesCode:
            (codeColumnTextType && codeHeader[codeColumnTextType]) ||
            codeHeader.default
        }),
        insertedAt: <Trans>Added</Trans>,
        status: <Trans>Status</Trans>,
        action: <Trans>Action</Trans>,
        ...(!isEmpty(customHeaders) && { ...customHeaders })
      }}
      renderRow={({
        id: itemId,
        databaseId,
        status,
        insertedAt,
        currentDiagnoses,
        diagnoses,
        code,
        vaccineCode,
        addresses
      }: any) => ({
        databaseId,
        diagnosesCode:
          !isEmpty(code) && !isEmpty(code.code) ? (
            `${code.name} (${code.code})`
          ) : (
            <CodeableConcept
              codeableConcept={
                (!isEmpty(currentDiagnoses) && currentDiagnoses[0].code) ||
                (!isEmpty(diagnoses) && diagnoses[0].code) ||
                (!isEmpty(code) && code) ||
                (!isEmpty(vaccineCode) && vaccineCode) ||
                (!isEmpty(addresses) && addresses[0])
              }
              withCode
            />
          ),
        insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
        status: statusSystem ? (
          <DictionaryValue name={statusSystem} item={status.toLowerCase()} />
        ) : (
          i18n._(statusValues[status.toLowerCase()])
        ),
        action: (
          <Link to={`../${pathSlug}/${itemId}`} fontWeight="bold">
            <Trans>Details</Trans>
          </Link>
        )
      })}
      sortableFields={["insertedAt", "status"]}
      sortingParams={parseSortingParams(locationParams.orderBy)}
      onSortingChange={(sortingParams: SortingParams) =>
        setLocationParams({
          ...locationParams,
          orderBy: stringifySortingParams(sortingParams)
        })
      }
      tableName={tableName}
    />
  );
};
