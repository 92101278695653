import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Box, Heading, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { getFullName } from "@edenlabllc/ehealth-utils";
import { Maybe, Person, PersonDocument } from "@ehealth/ehealth-ua.schema";

import AddressView from "../../../components/AddressView";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import DocumentView from "../../../components/DocumentView";

import { getDataByType } from "./index";

type UserInfoProps = RouteComponentProps & {
  data?: Person;
  wrapperPadding?: number;
};

const UserInfo = ({ data, wrapperPadding = 5 }: UserInfoProps) => {
  if (!data) return null;

  const {
    firstName,
    secondName = "",
    lastName,
    gender,
    birthDate,
    phones = [],
    documents = [],
    // @ts-expect-error types mismatch
    relationshipDocuments = [],
    addresses = [],
    // @ts-expect-error types mismatch
    relationType,
    deathDate,
    secret,
    noTaxId,
    taxId,
    preferredWayCommunication,
    ...person
  } = data;
  const [mobilePhone] = getDataByType("MOBILE", phones);
  const [landLinePhone] = getDataByType("LAND_LINE", phones);

  return (
    <Box p={wrapperPadding}>
      <DefinitionListView
        labelWidth={200}
        labels={{
          fullName: relationType ? (
            <Trans>PIB</Trans>
          ) : (
            <Trans>Patient Name</Trans>
          ),
          gender: <Trans>Gender</Trans>,
          birthDate: <Trans>Date of birth</Trans>,
          deathDate: <Trans>Death date</Trans>,
          birthCountry: <Trans>Country of birth</Trans>,
          birthSettlement: <Trans>Place of birth</Trans>,
          secret: <Trans>Secret word</Trans>,
          registrationAddress: <Trans>Registration address</Trans>,
          residenceAddress: <Trans>Residence address</Trans>,
          receptionAddress: <Trans>Reception address</Trans>,
          taxId: <Trans>INN</Trans>,
          noTaxId: <Trans>No tax ID</Trans>,
          unzr: <Trans>Record ID in EDDR</Trans>,
          preferredWayCommunication: (
            <Trans>Preferred way of communication</Trans>
          ),
          email: <Trans>Email</Trans>,
          mobilePhone: <Trans>Mobile number</Trans>,
          landLinePhone: <Trans>Stationary number</Trans>
        }}
        data={{
          ...person,
          ...(typeof taxId !== "undefined" && {
            taxId: taxId === null ? <Trans>Missing value</Trans> : taxId
          }),
          ...(typeof noTaxId !== "undefined" && {
            noTaxId:
              noTaxId === null ? (
                <Trans>Missing value</Trans>
              ) : noTaxId ? (
                <Trans>Yes</Trans>
              ) : (
                <Trans>No</Trans>
              )
          }),
          preferredWayCommunication: preferredWayCommunication && (
            <DictionaryValue
              name="PREFERRED_WAY_COMMUNICATION"
              item={preferredWayCommunication.toUpperCase()}
            />
          ),
          gender: gender && <DictionaryValue name="GENDER" item={gender} />,
          birthDate: birthDate && i18n.date(birthDate),
          deathDate: deathDate && i18n.date(deathDate),
          fullName: getFullName({ firstName, secondName, lastName }),
          secret: secret,
          registrationAddress: getAddressByType(addresses, "REGISTRATION"),
          residenceAddress: getAddressByType(addresses, "RESIDENCE"),
          receptionAddress: getAddressByType(addresses, "RECEPTION"),
          ...(!isEmpty(mobilePhone) && { mobilePhone: mobilePhone.number }),
          ...(!isEmpty(landLinePhone) && {
            landLinePhone: landLinePhone.number
          })
        }}
      />
      {!isEmpty(documents) && (
        <Box py={4}>
          <Heading fontSize="1" fontWeight="normal">
            <Trans>Documents</Trans>
          </Heading>
          <Documents documents={documents} dictionary="DOCUMENT_TYPE" />
        </Box>
      )}
      {!isEmpty(relationshipDocuments) && (
        <Box pt={4}>
          <Heading fontSize="1" fontWeight="normal">
            <Trans>Documents confirming the credentials of the confidant</Trans>
          </Heading>
          <Documents
            documents={relationshipDocuments}
            dictionary="DOCUMENT_RELATIONSHIP_TYPE"
          />
        </Box>
      )}
    </Box>
  );
};

export default UserInfo;

type DocumentsProps = {
  documents: Maybe<Maybe<PersonDocument>[]>;
  dictionary: string;
};

const Documents = ({ documents, dictionary }: DocumentsProps) => {
  if (isEmpty(documents)) return null;

  return (
    <>
      {documents!.map((document, index) => (
        <Box key={index}>
          <Heading fontSize="0" fontWeight="bold" py={4}>
            <DictionaryValue
              name={dictionary}
              item={document && document.type}
            />
          </Heading>
          <DocumentView data={document} />
        </Box>
      ))}
    </>
  );
};

const getAddressByType = (addresses: Person["addresses"], type: string) => {
  const addressesWithCurrentType = addresses.filter(
    (address) => address && address.type === type
  );
  if (!addressesWithCurrentType.length) return null;

  return addressesWithCurrentType.map((typeMatchedAddress) => (
    <Box mb={2} key={typeMatchedAddress && typeMatchedAddress.settlementId}>
      <Flex>
        <AddressView data={typeMatchedAddress} />
      </Flex>
    </Box>
  ));
};

UserInfo.fragments = {
  entry: gql`
    fragment UserInfo on Person {
      id
      databaseId
      status
      verificationStatus
      firstName
      secondName
      lastName
      gender
      birthDate
      birthCountry
      birthSettlement
      addresses {
        ...Addresses
      }
      taxId
      noTaxId
      insertedAt
      updatedAt
      unzr
      email
      phones {
        type
        number
      }
      preferredWayCommunication
      documents {
        type
        number
        issuedAt
        issuedBy
        expirationDate
      }
      nhsRequestNumber
      nhsRequestComment
      deathDate
      secret
    }
    ${AddressView.fragments.entry}
  `
};
