import React from "react";
import { Router, RouteComponentProps } from "@reach/router";

import Search from "./Search";
import JustificationHistory from "./JustificationHistory";
import Episode, {
  EpisodeByIDQuery,
  getQueryVariables
} from "./Details/Episode";
import Encounter, { EncounterByIDQuery } from "./Details/Encounter";
import Condition, { ConditionByIDQuery } from "./Details/Condition";
import Observation, { ObservationByIDQuery } from "./Details/Observation";
import ServiceRequest, {
  ServiceRequestByIDQuery
} from "./Details/ServiceRequest";
import Procedure, { ProcedureByIDQuery } from "./Details/Procedure";
import Immunization, { ImmunizationByIDQuery } from "./Details/Immunization";
import AllergyIntolerance, {
  AllergyIntoleranceByIDQuery
} from "./Details/AllergyIntolerance";
import MedicationRequest, {
  MedicationRequestByIDQuery,
  getMedicationRequestQueryVariables
} from "./Details/MedicationRequest";
import DiagnosticReport, {
  DiagnosticReportByIDQuery
} from "./Details/DiagnosticReport";
import CarePlan, { CarePlanByIDQuery } from "./Details/CarePlan";
import MedicationDispense, {
  MedicationDispenseByIDQuery,
  getMedicationDispenseQueryVariables
} from "./Details/MedicationDispense";
import { Activity } from "./Details/Activity/Activity";
import PatientApprovals from "./PatientApprovals";

import { ContentCopyProtection } from "../../components/ContentCopyProtection";

type PracticalMonitoringProps = RouteComponentProps<{
  id: string;
}>;

const PracticalMonitoring = ({ id }: PracticalMonitoringProps) => (
  <ContentCopyProtection>
    <Router>
      <JustificationHistory path="justification-history" />
      <PatientApprovals path="patient-approvals" id={id} />
      <Episode
        path="/episode/:itemId/*"
        query={EpisodeByIDQuery}
        queryVariables={getQueryVariables(id)}
      />
      <Encounter
        path="/encounter/:itemId/*"
        query={EncounterByIDQuery}
        queryVariables={getQueryVariables(id)}
      />
      <Condition
        path="/condition/:itemId/*"
        query={ConditionByIDQuery}
        queryVariables={getQueryVariables(id)}
      />
      <Observation
        path="/observation/:itemId/*"
        query={ObservationByIDQuery}
        queryVariables={getQueryVariables(id)}
      />
      <ServiceRequest
        path="/service-request/:itemId/*"
        query={ServiceRequestByIDQuery}
        queryVariables={getQueryVariables(id)}
      />
      <Procedure
        path="/procedure/:itemId/*"
        query={ProcedureByIDQuery}
        queryVariables={getQueryVariables(id)}
      />
      <Immunization
        path="/immunization/:itemId/*"
        query={ImmunizationByIDQuery}
        queryVariables={getQueryVariables(id)}
      />
      <AllergyIntolerance
        path="/allergy-intolerance/:itemId/*"
        query={AllergyIntoleranceByIDQuery}
        queryVariables={getQueryVariables(id)}
      />
      <MedicationRequest
        path="/medication-request/:itemId/*"
        query={MedicationRequestByIDQuery}
        queryVariables={getMedicationRequestQueryVariables(id)}
      />
      <MedicationDispense
        path="/medication-dispense/:itemId/*"
        query={MedicationDispenseByIDQuery}
        queryVariables={getMedicationDispenseQueryVariables(id)}
      />
      <DiagnosticReport
        path="/diagnostic-report/:itemId/*"
        query={DiagnosticReportByIDQuery}
        queryVariables={getQueryVariables(id)}
      />
      <Activity path="/care-plan/:itemId/activity/:activityId/*" />
      <CarePlan
        path="/care-plan/:itemId/*"
        query={CarePlanByIDQuery}
        queryVariables={getQueryVariables(id)}
      />
      <Search path="/*" />
    </Router>
  </ContentCopyProtection>
);

export default PracticalMonitoring;
