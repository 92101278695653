import { gql } from "graphql-tag";
import Pagination from "../../../components/Pagination";
import AllergyIntolerance from "../../PracticalMonitoring/Details/AllergyIntolerance";

export const ImpersonalAllergyIntolerancesQuery = gql`
  query ImpersonalAllergyIntolerancesQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImpersonalAllergyIntoleranceFilter!
    $orderBy: AllergyIntoleranceOrderBy
  ) {
    impersonalAllergyIntolerances(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status: verificationStatus
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

export const ImpersonalAllergyIntoleranceByIDQuery = gql`
  query ImpersonalAllergyIntoleranceQuery(
    $id: ID!
    $patientId: String!
    $skipAdditionalFields: Boolean!
  ) {
    impersonalAllergyIntolerance(id: $id, patientId: $patientId) {
      ...AllergyIntolerance
    }
  }
  ${AllergyIntolerance.fragments.entry}
`;
