import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import {
  MutationFunction,
  MutationResult
} from "@apollo/client/react/types/types";
import Papa from "papaparse";
import Composer from "react-composer";
import Dropzone from "react-dropzone";
import { Trans } from "@lingui/macro";
import { Box, Heading, Text } from "@rebass/emotion";
import { differenceInCalendarDays, isLeapYear } from "date-fns";
import createDecorator from "final-form-calculate";
import isEmpty from "lodash/isEmpty";

import { cleanDeep } from "@edenlabllc/ehealth-utils";
import { Form, Validation } from "@edenlabllc/ehealth-components";
import { Dictionary } from "@ehealth/ehealth-ua.schema";

import Button from "../../components/Button";
import * as Field from "../../components/Field";
import DictionaryValue from "../../components/DictionaryValue";
import DropzoneView from "../../components/DropzoneView";
import DropzoneFileName from "../../components/DropzoneFileName";

import STATUSES from "../../helpers/statuses";
import { UUID_PATTERN } from "../../constants/validationPatterns";

type RegistersFileRow = {
  type?: string;
  number?: string;
  death_date?: string;
};

const ONE_MB = 1048576;

const getRegisterTypes = (type: string) => {
  switch (type) {
    case "patient":
      return ["DEATH_REGISTRATION", "FRAUD"];
    case "declaration":
      return ["FRAUD"];
    default:
      return [
        "FULL_MEDICATIONS_REGISTRY",
        "UPDATE_PROGRAM_MEDICATION_REGISTRY",
        "DEACTIVATE_INNM_DOSAGE_REGISTRY",
        "DEACTIVATE_BRAND_REGISTRY",
        "DEACTIVATE_PROGRAM_MEDICATION_REGISTRY"
      ];
  }
};

const UpdateRegisters = ({ navigate }: RouteComponentProps) => {
  const [fileContent, setFileContent] = React.useState<{
    validEntries: RegistersFileRow[] | string[];
    invalidEntries: { path: string; message: string }[] | string[];
    content: string | ArrayBuffer | null;
    type: null | string;
  }>({
    validEntries: [],
    invalidEntries: [],
    content: "",
    type: null
  });
  const [isLoading, setLoading] = React.useState(false);

  return (
    <Box p={6}>
      <Heading as="h1" fontWeight="normal" mb={4}>
        <Trans>Reset registers</Trans>
      </Heading>
      <Composer
        components={[
          ({ render }: $TSFixMe) => (
            <Mutation mutation={TerminateDeclarationsMutation}>
              {(
                terminateDeclarations: MutationFunction,
                { loading }: MutationResult
              ) => render([terminateDeclarations, loading])}
            </Mutation>
          ),
          ({ render }: $TSFixMe) => (
            <Mutation mutation={DeactivatePersonsMutation}>
              {(
                deactivatePersons: MutationFunction,
                { loading }: MutationResult
              ) => render([deactivatePersons, loading])}
            </Mutation>
          ),
          ({ render }: $TSFixMe) => (
            <Mutation mutation={CreateMedicationRegistryMutation}>
              {(
                createMedicationRegistry: MutationFunction,
                { loading }: MutationResult
              ) => render([createMedicationRegistry, loading])}
            </Mutation>
          ),
          ({ render }: $TSFixMe) => (
            <Mutation mutation={UpdateMedicationRegistryMutation}>
              {(
                updateMedicationRegistry: MutationFunction,
                { loading }: MutationResult
              ) => render([updateMedicationRegistry, loading])}
            </Mutation>
          ),
          ({ render }: $TSFixMe) => (
            <Mutation mutation={DeactivateMedicationRegistryMutation}>
              {(
                deactivateMedicationRegistry: MutationFunction,
                { loading }: MutationResult
              ) => render([deactivateMedicationRegistry, loading])}
            </Mutation>
          )
        ]}
      >
        {([
          [terminateDeclarations, terminateLoading],
          [deactivatePersons, deactivateLoading],
          [createMedicationRegistry, createMedicationRegistryLoading],
          [updateMedicationRegistry, updateMedicationRegistryLoading],
          [deactivateMedicationRegistry, deactivateMedicationRegistryLoading]
        ]: [MutationFunction, boolean][]) => (
          <Form
            onSubmit={async ({
              registerType,
              reasonDescription
            }: {
              registerType: string;
              reasonDescription: string;
            }) => {
              switch (fileContent.type) {
                case "declaration": {
                  await terminateDeclarations({
                    variables: {
                      input: {
                        databaseIds: (fileContent.validEntries as RegistersFileRow[]).map(
                          ({ number }) => number
                        ),
                        registerType,
                        reasonDescription
                      }
                    }
                  });
                  await navigate!("/declarations-termination-jobs");
                  break;
                }
                case "patient": {
                  const deactivationParams = (fileContent.validEntries as RegistersFileRow[]).map(
                    ({ type, number, death_date }) => {
                      const deathRegistrationData = cleanDeep({
                        type,
                        number,
                        death_date
                      });
                      const fraudData = { type, number };

                      return registerType === "FRAUD"
                        ? fraudData
                        : deathRegistrationData;
                    }
                  );

                  deactivatePersons({
                    variables: {
                      input: {
                        deactivationParams,
                        registerType,
                        reasonDescription
                      }
                    }
                  });
                  await navigate!("/persons-deactivation-jobs");
                  break;
                }
                case "medication": {
                  const medicationData = {
                    variables: {
                      input: {
                        csvData: fileContent.content,
                        registerType,
                        reasonDescription
                      }
                    }
                  };
                  try {
                    if (registerType.includes("UPDATE")) {
                      await updateMedicationRegistry(medicationData);
                    } else if (registerType.includes("DEACTIVATE")) {
                      await deactivateMedicationRegistry(medicationData);
                    } else {
                      await createMedicationRegistry(medicationData);
                    }
                    await navigate!("/medication-registry-jobs");
                  } catch (err: $TSFixMe) {
                    const { graphQLErrors } = err;
                    const errorList: { path: string; message: string }[] = [];
                    const respError = graphQLErrors[0];
                    if (
                      respError.extensions.exception &&
                      respError.extensions.exception.inputErrors
                    ) {
                      respError.extensions.exception.inputErrors.forEach(
                        (item: $TSFixMe) => {
                          let message = item.message;
                          Object.keys(item.options).forEach((key) => {
                            message = message.replace(
                              `{${key}`,
                              item.options[key]
                            );
                          });
                          errorList.push({
                            path: item.path.join("."),
                            message
                          });
                        }
                      );
                    }
                    setFileContent({
                      ...fileContent,
                      invalidEntries: errorList
                    });
                  }
                  break;
                }
                default:
                  return null;
              }
            }}
            decorators={[resetRegisterType]}
          >
            <Box width={1 / 4}>
              <Trans
                id="Select option"
                render={({ translation }) => (
                  <Field.Select
                    name="type"
                    variant="select"
                    label={<Trans id="Entity type" />}
                    placeholder={translation}
                    items={Object.keys(STATUSES.ENTITY_TYPE)}
                    itemToString={(item: string) => STATUSES.ENTITY_TYPE[item]}
                  />
                )}
              />
              <Validation.Required field="type" message="Required field" />
            </Box>
            <Form.Spy>
              {({ values: { type } = { type: undefined } }: $TSFixMe) => {
                return (
                  <Box width={1 / 4}>
                    <DictionaryValue
                      name="REGISTER_TYPE"
                      render={(dict: Dictionary["values"]) => (
                        <Trans
                          id="Choose file type"
                          render={({ translation }) => (
                            <Field.Select
                              name="registerType"
                              variant="select"
                              label={<Trans id="File type" />}
                              placeholder={translation}
                              itemToString={(item: string) =>
                                dict[item] || translation
                              }
                              items={getRegisterTypes(type)}
                              disabled={!type}
                            />
                          )}
                        />
                      )}
                    />
                    {type !== "declaration" && (
                      <Validation.Required
                        field="registerType"
                        message="Required field"
                      />
                    )}
                  </Box>
                );
              }}
            </Form.Spy>
            <Box width={2 / 4}>
              <Trans
                id="Enter reason comment"
                render={({ translation }) => (
                  <Field.Textarea
                    label={<Trans id="Reason" />}
                    name="reasonDescription"
                    placeholder={translation}
                    rows={3}
                    maxLength={100}
                    showLengthHint
                  />
                )}
              />
              <Validation.Required
                field="reasonDescription"
                message="Required field"
              />
            </Box>

            <DictionaryValue name="PERSONS_DEACTIVATION_IDENTITIES">
              {(dictionary: Dictionary["values"]) => {
                const documentTypes = Object.keys(dictionary);
                return (
                  <Form.Spy>
                    {({ values: { type } = { type: undefined } }: $TSFixMe) => {
                      if (type && type !== fileContent.type) {
                        const fileContentEntries = [
                          ...fileContent.validEntries,
                          ...fileContent.invalidEntries
                        ];

                        const content = validateEntries(
                          fileContentEntries,
                          type,
                          documentTypes
                        );
                        setFileContent({
                          ...content,
                          type
                        });
                      }

                      return (
                        <Dropzone
                          disabled={!type}
                          multiple={false}
                          accept=".csv"
                          maxSize={ONE_MB}
                          onDrop={(acceptedFiles) => {
                            const reader = new FileReader();

                            acceptedFiles.forEach((file) =>
                              reader.readAsText(file, "UTF-8")
                            );

                            reader.onloadstart = () => {
                              setFileContent({
                                validEntries: [],
                                invalidEntries: [],
                                type,
                                content: ""
                              });
                              setLoading(true);
                            };
                            reader.onloadend = () => setLoading(false);
                            reader.onload = () => {
                              if (type === "medication") {
                                return setFileContent({
                                  validEntries: [],
                                  invalidEntries: [],
                                  type,
                                  content: reader.result
                                });
                              } else {
                                const { data: headers } = Papa.parse(
                                  reader.result as string,
                                  {
                                    preview: 1
                                  }
                                );
                                const isFileHeaderValid = checkFileHeader(
                                  headers
                                );

                                if (!isFileHeaderValid) {
                                  return setFileContent({
                                    ...fileContent,
                                    invalidEntries: ["Invalid header"]
                                  });
                                }

                                const { data } = Papa.parse(
                                  reader.result as string,
                                  {
                                    skipEmptyLines: true,
                                    header: true
                                  }
                                );
                                const content = validateEntries(
                                  data,
                                  type,
                                  documentTypes
                                );

                                setFileContent({
                                  ...content,
                                  type
                                });
                              }
                            };
                          }}
                        >
                          {({
                            acceptedFiles,
                            getRootProps,
                            getInputProps,
                            rejectedFiles
                          }) => {
                            const [fileSizeError] = rejectedFiles.map(
                              (file) => file.size > ONE_MB
                            );

                            return (
                              <>
                                <Box width={2 / 4}>
                                  <DropzoneView {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <DropZoneState
                                      type={type}
                                      files={[
                                        ...acceptedFiles,
                                        ...rejectedFiles
                                      ]}
                                    />
                                  </DropzoneView>
                                </Box>

                                {fileSizeError && (
                                  <Text color="red" fontSize={1} mb={2}>
                                    <Trans>File size more than 1MB</Trans>
                                  </Text>
                                )}
                                {!isEmpty(fileContent.invalidEntries) && (
                                  <InvalidEntriesList
                                    invalidEntries={fileContent.invalidEntries}
                                  />
                                )}

                                <Button
                                  variant="green"
                                  disabled={
                                    terminateLoading ||
                                    deactivateLoading ||
                                    createMedicationRegistryLoading ||
                                    updateMedicationRegistryLoading ||
                                    deactivateMedicationRegistryLoading ||
                                    isLoading ||
                                    fileSizeError ||
                                    (type !== "medication" &&
                                      (!isEmpty(fileContent.invalidEntries) ||
                                        isEmpty(fileContent.validEntries))) ||
                                    (type === "medication" &&
                                      !fileContent.content)
                                  }
                                  loading={
                                    terminateLoading ||
                                    deactivateLoading ||
                                    createMedicationRegistryLoading ||
                                    updateMedicationRegistryLoading ||
                                    deactivateMedicationRegistryLoading
                                  }
                                >
                                  <Trans>Upload</Trans>
                                </Button>
                              </>
                            );
                          }}
                        </Dropzone>
                      );
                    }}
                  </Form.Spy>
                );
              }}
            </DictionaryValue>
          </Form>
        )}
      </Composer>
    </Box>
  );
};

const validateEntries = (
  data: Array<unknown>,
  selectedEntityType: string,
  documentTypes: string[]
) => {
  const validEntries = data.map(({ type, number, death_date }: $TSFixMe) => {
    const data = { type, number, death_date };

    if (selectedEntityType === "declaration") {
      const isDeclarationIdValid = checkDeclarationId(number);
      return type === "DECLARATION_ID" && isDeclarationIdValid
        ? data
        : undefined;
    } else {
      const isDeathDateValid = checkDeathDate(death_date);
      return documentTypes.includes(type) && number && isDeathDateValid
        ? data
        : undefined;
    }
  });

  const invalidEntries = data.map(({ type, number, death_date }: $TSFixMe) => {
    const data = { type, number, death_date };

    if (selectedEntityType === "declaration") {
      const isDeclarationIdValid = checkDeclarationId(number);
      return type !== "DECLARATION_ID" || !isDeclarationIdValid
        ? data
        : undefined;
    } else {
      const isDeathDateValid = checkDeathDate(death_date);
      return !documentTypes.includes(type) || !number || !isDeathDateValid
        ? data
        : undefined;
    }
  });

  return cleanDeep(
    {
      validEntries,
      invalidEntries
    },
    { emptyArrays: false }
  );
};

const checkDeclarationId = (number?: string) =>
  number && new RegExp(UUID_PATTERN).test(number);

const checkDeathDate = (date?: string) => {
  if (typeof date === "undefined") return true;

  const DAYS_IN_150_YEARS = 54750;
  const DATE_PATTERN = /^\d{4}-\d{1,2}-\d{1,2}$/;

  if (!DATE_PATTERN.test(date)) {
    return false;
  }

  const dateInstance = new Date(date);
  const differenceInDays = differenceInCalendarDays(new Date(), dateInstance);

  const parts = date.split("-");
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  if (
    differenceInDays < 0 ||
    differenceInDays > DAYS_IN_150_YEARS ||
    month === 0 ||
    month > 12
  ) {
    return false;
  }

  const daysInFeb = isLeapYear(dateInstance) ? 29 : 28;
  const daysInMonth = [31, daysInFeb, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  return day > 0 && day <= daysInMonth[month - 1];
};

const checkFileHeader = (data?: $TSFixMe) =>
  data && new RegExp("^type,number").test(data);

const DropZoneState = ({ type, files }: $TSFixMe) => {
  switch (true) {
    case !type: {
      return <Trans>Select entity type.</Trans>;
    }
    case !isEmpty(files): {
      return <DropzoneFileName files={files} />;
    }
    default: {
      return <Trans>Drag 'n' drop file here, or click to select file</Trans>;
    }
  }
};

type InvalidEntriesListProps = {
  invalidEntries: Array<{ path: string; message: string } | string>;
};

const InvalidEntriesList = ({ invalidEntries }: InvalidEntriesListProps) => (
  <Box mb={3} width={2 / 4}>
    <Text fontWeight="bold" fontSize={2}>
      <Trans>Invalid fields</Trans>
    </Text>
    <Box
      as="ul"
      mt={1}
      style={{
        maxHeight: 200,
        overflow: "auto"
      }}
    >
      {invalidEntries.map((item, i) => {
        const entry =
          typeof item === "string" ? (
            <Trans>
              The header row must contain three fields: type, number, death_date
            </Trans>
          ) : (
            JSON.stringify(item)
          );

        return (
          <Box as="li" key={i}>
            <Text color="#333" fontSize={0} mt="8px">
              {entry}
            </Text>
          </Box>
        );
      })}
    </Box>
  </Box>
);

const DeactivatePersonsMutation = gql`
  mutation DeactivatePersonsMutation($input: DeactivatePersonsInput!) {
    deactivatePersons(input: $input) {
      personsDeactivationJob {
        id
      }
    }
  }
`;

const TerminateDeclarationsMutation = gql`
  mutation TerminateDeclarationsMutation($input: TerminateDeclarationsInput!) {
    terminateDeclarations(input: $input) {
      declarationsTerminationJob {
        id
      }
    }
  }
`;

const CreateMedicationRegistryMutation = gql`
  mutation CreateMedicationRegistryMutation(
    $input: CreateMedicationRegistryInput!
  ) {
    createMedicationRegistry(input: $input) {
      medicationRegistryJob {
        id
      }
    }
  }
`;

const UpdateMedicationRegistryMutation = gql`
  mutation UpdateMedicationRegistryMutation(
    $input: UpdateMedicationRegistryInput!
  ) {
    updateMedicationRegistry(input: $input) {
      medicationRegistryJob {
        id
      }
    }
  }
`;

const DeactivateMedicationRegistryMutation = gql`
  mutation DeactivateMedicationRegistryMutation(
    $input: DeactivateMedicationRegistryInput!
  ) {
    deactivateMedicationRegistry(input: $input) {
      medicationRegistryJob {
        id
      }
    }
  }
`;

const resetRegisterType = createDecorator({
  field: "type",
  updates: {
    registerType: () => {
      return undefined;
    }
  }
});

export default UpdateRegisters;
