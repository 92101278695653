import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { DocumentNode, MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Text } from "@rebass/emotion";

import system from "@edenlabllc/ehealth-system-components";

import Popup from "../../../components/Popup";
import { SearchParams } from "../../../components/SearchForm";

type DeleteServiceFromGroupPopupProps = {
  serviceId: string;
  serviceName: string;
  serviceGroupId: string;
  serviceGroupName: string;
  locationParams: SearchParams;
  serviceGroupDetailsQuery: DocumentNode;
};

const DeleteServiceFromGroupPopup = ({
  serviceId,
  serviceName,
  serviceGroupId,
  serviceGroupName,
  locationParams,
  serviceGroupDetailsQuery
}: DeleteServiceFromGroupPopupProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Mutation
      mutation={DeleteServiceFromGroupPopupMutation}
      refetchQueries={() => [
        {
          query: serviceGroupDetailsQuery,
          variables: {
            id: serviceGroupId,
            ...locationParams
          }
        }
      ]}
    >
      {(deleteServiceFromGroup: MutationFunction) => (
        <>
          <DeleteButton onClick={toggle}>
            <Trans>Delete</Trans>
          </DeleteButton>
          <Popup
            visible={isPopupVisible}
            onCancel={toggle}
            title={
              <>
                <Trans>Delete service</Trans> "{serviceName}"{" "}
                <Trans>from service group</Trans> "{serviceGroupName}
                "?
              </>
            }
            okText={<Trans>Delete</Trans>}
            onOk={async () => {
              await deleteServiceFromGroup({
                variables: {
                  input: {
                    serviceId,
                    serviceGroupId
                  }
                }
              });
              toggle();
            }}
          />
        </>
      )}
    </Mutation>
  );
};

const DeleteServiceFromGroupPopupMutation = gql`
  mutation DeleteServiceFromGroupPopupMutation(
    $input: DeleteServiceFromGroupInput!
  ) {
    deleteServiceFromGroup(input: $input) {
      serviceGroup {
        id
      }
    }
  }
`;

const DeleteButton = system(
  {
    is: Text,
    color: "rockmanBlue",
    fontWeight: "bold",
    textAlign: "center"
  },
  {
    cursor: "pointer"
  },

  "color",
  "fontWeight",
  "textAlign"
);

export default DeleteServiceFromGroupPopup;
