import React, { useMemo } from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { ApolloQueryResult, QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, isEmpty } from "lodash";

import { Validation } from "@edenlabllc/ehealth-components";
import { RegionConnection, QueryRegionsArgs } from "@ehealth/ehealth-ua.schema";

import * as Field from "../Field";
import { ADDRESS_ITEM_PATTERN } from "../../constants/validationPatterns";

type RegionFieldProps = {
  name: string;
};

const RegionField = ({ name }: RegionFieldProps) => (
  <Trans
    id="Enter settlement name"
    render={({ translation }) => (
      <Query
        query={RegionQuery}
        fetchPolicy="cache-first"
        variables={{ first: 20 }}
      >
        {({
          data,
          error,
          refetch
        }: QueryResult<{ regions: RegionConnection }>) => {
          const { regions } = data || {};
          if (error || isEmpty(regions)) {
            return (
              <>
                <Field.Text
                  name={name}
                  label={<Trans id="Region" />}
                  placeholder={translation}
                />
                <Trans
                  id="Invalid format"
                  render={({ translation }) => (
                    <Validation.Matches
                      field={name}
                      options={ADDRESS_ITEM_PATTERN}
                      message={translation}
                    />
                  )}
                />
              </>
            );
          }
          return (
            <>
              <RegionSelect
                refetch={refetch}
                regions={(regions && regions.nodes) || []}
                name={name}
                translation={translation}
              />
              <Trans
                id="Invalid format"
                render={({ translation }) => (
                  <Validation.Matches
                    field={name}
                    options={ADDRESS_ITEM_PATTERN}
                    message={translation}
                  />
                )}
              />
            </>
          );
        }}
      </Query>
    )}
  />
);

type RegionSelectProps = {
  regions: RegionConnection["nodes"];
  refetch: (
    variables: QueryRegionsArgs
  ) => Promise<
    ApolloQueryResult<{
      regions: RegionConnection;
    }>
  >;
  name: string;
  translation: React.ReactNode;
};

const RegionSelect = ({
  regions,
  refetch,
  name,
  translation
}: RegionSelectProps) => {
  const regionsList = useMemo(
    () => regions!.map((region) => region && region.name),
    [regions]
  );
  return (
    <Field.Select
      name={name}
      label={<Trans id="Region" />}
      placeholder={translation}
      items={regionsList}
      itemToString={(item: string) => (item ? item : "")}
      onInputValueChange={debounce(
        (regionName, { selectedItem, inputValue }) => {
          return (
            !isEmpty(regionName) &&
            selectedItem !== inputValue &&
            refetch({
              first: 20,
              filter: {
                name: inputValue
              }
            })
          );
        },
        300
      )}
    />
  );
};

const RegionQuery = gql`
  query RegionQuery($first: Int, $filter: RegionFilter) {
    regions(filter: $filter, first: $first) {
      nodes {
        id
        name
      }
    }
  }
`;

export default RegionField;
