import isEmpty from "lodash/isEmpty";
import { Person } from "@ehealth/ehealth-ua.schema";

import { SUBMIT_ERROR } from "@edenlabllc/ehealth-components";

export const defaultError = [
  {
    entry: "default"
  }
];

export const personsPhonesEmptyError = [
  {
    entry: "person.phones.empty",
    rules: [
      {
        rule: "personPhonesEmpty"
      }
    ]
  }
];

export const personsAddressesRecidenceError = [
  {
    entry: "person.addresses.recidence.empty",
    rules: [
      {
        rule: "personAddressesRecidenceEmpty"
      }
    ]
  }
];

export const personsAddressesError = [
  {
    entry: "person.addresses.empty",
    rules: [
      {
        rule: "personAddressesEmpty"
      }
    ]
  }
];

export const personsDocumentsError = [
  {
    entry: "person.documents.empty",
    rules: [
      {
        rule: "personDocumentsEmpty"
      }
    ]
  }
];

export const personsEmergencyContactPhonesEmptyError = [
  {
    entry: "person.emergency.contact.phones.empty",
    rules: [
      {
        rule: "personEmergencyContactPhonesEmpty"
      }
    ]
  }
];

export const personsConfidantPersonsPhonesEmptyError = [
  {
    entry: "person.confidant.persons.phones.empty",
    rules: [
      {
        rule: "personConfidantPersonsPhonesEmpty"
      }
    ]
  }
];

export const handleProcessEditFormErrors = (
  data: Person & {
    nhsRequestNumber: string;
    nhsRequestComment: string;
    resetSignRefusalAndID: boolean;
  }
) => {
  if (isEmpty(data.phones)) {
    return { [SUBMIT_ERROR]: personsPhonesEmptyError };
  }

  if (isEmpty(data.addresses)) {
    return { [SUBMIT_ERROR]: personsAddressesError };
  }

  if (
    isEmpty(
      data.addresses &&
        data.addresses.find(
          (address) => address && address.type === "RESIDENCE"
        )
    )
  ) {
    return { [SUBMIT_ERROR]: personsAddressesRecidenceError };
  }

  if (isEmpty(data.documents)) {
    return { [SUBMIT_ERROR]: personsDocumentsError };
  }

  if (isEmpty(data.emergencyContact) || isEmpty(data.emergencyContact.phones)) {
    return { [SUBMIT_ERROR]: personsEmergencyContactPhonesEmptyError };
  }
};
