import { concat } from "@apollo/client";
import { ApolloClient, HttpLink } from "@apollo/client/core";
import { InMemoryCache } from "@apollo/client/cache";
import { onError } from "@apollo/client/link/error";
import { RestLink } from "apollo-link-rest";

import env from "./env";

class GraphQLError extends Error {
  operationName: $TSFixMe;
  variables: $TSFixMe;
  name = "GraphQLError";

  constructor(
    { message, ...error }: $TSFixMe,
    operationName: $TSFixMe,
    variables: $TSFixMe
  ) {
    super(message);

    Object.assign(this, error);

    this.operationName = operationName;
    this.variables = variables;
  }
}

class NetworkError extends Error {
  name = "NetworkError";

  constructor({ name, message, ...error }: $TSFixMe) {
    super(message);

    // NOTE: This assignment is potentially harmful, rewrite with props whitelisting if any issues will be encountered
    Object.assign(this, error);
  }
}

// @ts-expect-error TS(7031): Binding element 'handleError' implicitly has an 'a... Remove this comment to see the full error message
export const createClient = ({ onError: handleError }) => {
  const cache = new InMemoryCache();

  const errorLink = onError(
    ({
      graphQLErrors,
      networkError,
      operation,
      operation: { operationName, variables }
    }) => {
      const error = graphQLErrors
        ? new GraphQLError(graphQLErrors[0], operationName, variables)
        : new NetworkError(networkError);

      handleError({ error });
    }
  );

  const httpLink = new HttpLink({
    uri: env.REACT_APP_API_URL,
    credentials: "include",
    headers: {
      "X-Custom-PSK": env.REACT_APP_CUSTOM_PSK_HEADER || ""
    }
  });

  const restLink = new RestLink({
    uri: env.REACT_APP_REST_API_URL,
    headers: {
      "Content-Type": "application/json",
      "X-Custom-PSK": env.REACT_APP_CUSTOM_PSK_HEADER || ""
    },
    bodySerializers: {
      empty: (data: any, headers: Headers) => {
        return {
          body: "",
          headers: { ...headers }
        };
      }
    }
  });

  return new ApolloClient({
    cache,
    // @ts-expect-error TS(2345): Argument of type 'RestLink' is not assignable to parameter of type 'ApolloLink | RequestHandler'
    link: concat(restLink, concat(errorLink, httpLink)),
    // @ts-expect-error TS(2322): Type '{ watchQuery: { fetchPolicy: string; errorPo... Remove this comment to see the full error message
    defaultOptions
  });
};

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-first",
    errorPolicy: "all"
  },
  query: {
    errorPolicy: "all"
  },
  mutate: {
    errorPolicy: "none"
  }
};
