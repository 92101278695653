import React from "react";
import { Trans } from "@lingui/macro";

type ErrorTranslationProps = {
  error:
    | string
    | {
        message: string;
        options: any;
      };
};

const ErrorTranslation = ({ error }: ErrorTranslationProps) => {
  const [id, values] =
    typeof error === "string" ? [error] : [error.message, error.options];
  return <Trans id={id} values={values} />;
};

export default ErrorTranslation;
