import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import { Form, Validation } from "@edenlabllc/ehealth-components";

import Button from "../../../../components/Button";
import * as Field from "../../../../components/Field";
import Steps from "../../../../components/Steps";
import DefinitionListView from "../../../../components/DefinitionListView";

import { SearchForbiddenGroupsQuery } from "../index";
import { StateType } from "./AddGroupElement";

import env from "../../../../env";

const Create = ({
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <>
    <Box pt={5} px={5}>
      <Steps.List>
        <Steps.Item to="./" state={state}>
          <Trans>Fill info</Trans>
        </Steps.Item>
        <Steps.Item to="./confirm" state={state} disabled>
          <Trans>Approve by EDS</Trans>
        </Steps.Item>
      </Steps.List>
    </Box>
    <Router>
      <GeneralForm path="/" />
      <Confirmation path="/confirm" />
    </Router>
  </>
);

const GeneralForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <Box p={5}>
    <Form
      onSubmit={(data: StateType) => {
        navigate!("./confirm", {
          state: {
            ...state,
            createGroup: data
          }
        });
      }}
      initialValues={state.createGroup || null}
    >
      <Box width={1 / 2}>
        <Trans
          id="Enter group name"
          render={({ translation }) => (
            <Field.Text
              name="name"
              label={<Trans id="Group name" />}
              placeholder={translation}
              maxLength={255}
              showLengthHint
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required field="name" message="Required field" />
          )}
        />
      </Box>
      <Box width={1 / 2}>
        <Trans
          id="Enter short name"
          render={({ translation }) => (
            <Field.Text
              name="shortName"
              label={<Trans id="Short name" />}
              placeholder={translation}
              maxLength={12}
              showLengthHint
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required field="shortName" message="Required field" />
          )}
        />
      </Box>
      <Box width={1 / 2}>
        <Trans
          id="Enter sms url"
          render={({ translation }) => (
            <Field.Text
              name="smsUrl"
              label={<Trans id="Forbidden group sms url" />}
              placeholder={translation}
              maxLength={25}
              showLengthHint
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required field="smsUrl" message="Required field" />
          )}
        />
      </Box>
      <Box width={1 / 2}>
        <Trans
          id="Reason of creation"
          render={({ translation }) => (
            <Field.Textarea
              name="creationReason"
              label={<Trans id="Specify the basis" />}
              placeholder={translation}
              rows={10}
              maxLength={1000}
              showLengthHint
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field="creationReason"
              message="Required field"
            />
          )}
        />
      </Box>
      <Flex pt={5} mb={100}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate!("../search")}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Button variant="green" width={140}>
            <Trans>Next</Trans>
          </Button>
        </Box>
      </Flex>
    </Form>
  </Box>
);

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { name, creationReason, shortName, smsUrl } = state.createGroup;
  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          name: <Trans>Forbidden group name</Trans>,
          creationReason: <Trans>Basis of creation</Trans>,
          shortName: <Trans>Short name</Trans>,
          smsUrl: <Trans>Forbidden group sms url</Trans>
        }}
        data={{ name, creationReason, shortName, smsUrl }}
        labelWidth="230px"
      />
      <Flex pt={5} mb={100}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate!("../", { state })}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Signer.Parent
          url={env.REACT_APP_SIGNER_URL}
          features={{
            width: 640,
            height: 589
          }}
        >
          {/* @ts-expect-error signData */}
          {({ signData }) => (
            <Mutation
              mutation={CreateForbiddenGroupMutation}
              refetchQueries={() => [
                {
                  query: SearchForbiddenGroupsQuery,
                  variables: { first: 10 }
                }
              ]}
            >
              {(createForbiddenGroup: MutationFunction) => {
                return (
                  <Box>
                    <Button
                      variant="green"
                      width={250}
                      onClick={async () => {
                        const { signedContent } = await signData({
                          creation_reason: creationReason,
                          name: name,
                          short_name: shortName,
                          sms_url: smsUrl
                        });

                        await createForbiddenGroup({
                          variables: {
                            input: {
                              signedContent: {
                                content: signedContent,
                                encoding: "BASE64"
                              }
                            }
                          }
                        });
                        await navigate!("../../");
                      }}
                    >
                      <Trans>Approve by EDS</Trans>
                    </Button>
                  </Box>
                );
              }}
            </Mutation>
          )}
        </Signer.Parent>
      </Flex>
    </Box>
  );
};

export default Create;

const CreateForbiddenGroupMutation = gql`
  mutation CreateForbiddenGroupMutation($input: CreateForbiddenGroupInput!) {
    createForbiddenGroup(input: $input) {
      forbiddenGroup {
        id
        databaseId
        name
        creationReason
        insertedAt
        isActive
      }
    }
  }
`;
