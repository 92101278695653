import React from "react";
import Composer from "react-composer";
import { t, Trans } from "@lingui/macro";
import { TransRenderProps, useLingui } from "@lingui/react";
import { Flex, Box } from "@rebass/emotion";

import { Validation } from "@edenlabllc/ehealth-components";
import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Dictionary } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

import STATUSES from "../../../helpers/statuses";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 3}>
      <Trans
        id="Enter ID"
        render={({ translation }) => (
          <Field.Text
            name="filter.databaseId"
            label={<Trans id="Program medication ID" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 3}>
      <SearchField.MedicalProgram
        name="filter.medicalProgram"
        filteredParams={["name"]}
        additionalFilters={{ isActive: true, type: "MEDICATION" }}
      />
    </Box>
    <Box px={1} width={1 / 3}>
      <SearchField.Medication
        name="filter.medication"
        filteredParams={["name"]}
      />
    </Box>
  </Flex>
);

const SecondarySearchFields = () => {
  const { i18n } = useLingui();

  return (
    <>
      <Flex mx={-1}>
        <Box px={1} width={1 / 3}>
          <SearchField.Status
            name="filter.isActive"
            status="ACTIVE_STATUS_M"
            label={<Trans id="Participant status" />}
          />
        </Box>
        <Box px={1} width={1 / 3}>
          <Trans
            id="Enter ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.medicalProgramId"
                label={<Trans id="Medical program ID" />}
                placeholder={translation}
                postfix={<SearchIcon color="silverCity" />}
              />
            )}
          />
          <Validation.Matches
            field="filter.medicalProgramId"
            options={UUID_PATTERN}
            message="Invalid number"
          />
        </Box>
        <Box px={1} width={1 / 3}>
          <Trans
            id="Enter ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.medicationId"
                label={<Trans id="Medication ID" />}
                placeholder={translation}
                postfix={<SearchIcon color="silverCity" />}
              />
            )}
          />
          <Validation.Matches
            field="filter.medicationId"
            options={UUID_PATTERN}
            message="Invalid number"
          />
        </Box>
      </Flex>

      <Flex mx={-1}>
        <Box px={1} width={1 / 3}>
          <Trans
            id="Select option"
            render={({ translation }) => (
              <Field.Select
                name="filter.medicationRequestAllowed"
                label={<Trans id="Prescription creation" />}
                items={Object.keys(STATUSES.MEDICATION_REQUEST_ALLOWED)}
                itemToString={(item: boolean) =>
                  // @ts-expect-error statuses boolean key
                  STATUSES.MEDICATION_REQUEST_ALLOWED[item] || translation
                }
                variant="select"
                emptyOption
                filterOptions={{
                  keys: [
                    (item: boolean) =>
                      // @ts-expect-error statuses boolean key
                      STATUSES.MEDICATION_REQUEST_ALLOWED[item]
                  ]
                }}
              />
            )}
          />
        </Box>
        <Box px={1} width={1 / 3}>
          <Composer
            components={[
              <DictionaryValue name="MR_BLANK_TYPES" />,
              ({
                render
              }: {
                render: (props: TransRenderProps) => React.ReactElement;
              }) => <Trans id="Select option" render={render} />
            ]}
          >
            {([dict, { translation }]: [
              Dictionary["values"],
              { translation: React.ReactNode }
            ]) => (
              <Field.Select
                name="filter.medicalProgram.mrBlankType"
                label={<Trans id="Type of medication program request blank" />}
                placeholder={translation}
                items={dict ? Object.keys(dict) : []}
                itemToString={(item: string) => dict[item] || translation}
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            )}
          </Composer>
        </Box>
        <Box px={1} width={1 / 3}>
          <Trans
            id="Enter INNM dosage name"
            render={({ translation }) => (
              <Field.Text
                name="filter.innmDosages.name"
                label={<Trans id="INNM dosage name" />}
                placeholder={translation}
                postfix={<SearchIcon color="silverCity" />}
                autoComplete="off"
              />
            )}
          />
        </Box>
      </Flex>

      <Flex mx={-1}>
        <Box px={1} width={1 / 3}>
          <DictionaryValue name="REIMBURSEMENT_TYPE">
            {(reimbursementTypes: Dictionary["values"]) => (
              <Field.Select
                name="filter.reimbursementType"
                label={<Trans id="Reimbursement type" />}
                items={Object.keys(reimbursementTypes)}
                itemToString={(item: string) => reimbursementTypes[item] || ""}
                variant="select"
                placeholder={i18n._(t`Select option`)}
              />
            )}
          </DictionaryValue>
        </Box>
        <Box px={1} width={1 / 3}>
          <Trans
            id="Enter registry number"
            render={({ translation }) => (
              <Field.Text
                name="filter.registryNumber"
                label={<Trans id="Registry number" />}
                placeholder={translation}
                postfix={<SearchIcon color="silverCity" />}
                autoComplete="off"
              />
            )}
          />
        </Box>
        <Box px={1} width={1 / 3}>
          <Trans
            id="Enter ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.innmDosages.databaseId"
                label={<Trans id="INNM dosage ID" />}
                placeholder={translation}
                postfix={<SearchIcon color="silverCity" />}
              />
            )}
          />
          <Validation.Matches
            field="filter.innmDosages.databaseId"
            options={UUID_PATTERN}
            message="Invalid number"
          />
        </Box>
      </Flex>
    </>
  );
};

export { PrimarySearchFields, SecondarySearchFields };
