import React from "react";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex } from "@rebass/emotion";

import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  ForbiddenGroupCodeConnection,
  ForbiddenGroupCode
} from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../components/DictionaryValue";
import Table from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type ForbiddenGroupCodesTableProps = {
  forbiddenGroupCodes: ForbiddenGroupCodeConnection["nodes"];
  tableName?: string;
};

const ForbiddenGroupCodesTable = ({
  forbiddenGroupCodes,
  tableName = "forbiddenGroupCodes/search"
}: ForbiddenGroupCodesTableProps) => {
  return (
    <Table
      data={forbiddenGroupCodes}
      header={{
        databaseId: <Trans>ID</Trans>,
        code: <Trans>Code</Trans>,
        system: <Trans>Dictionary</Trans>,
        name: <Trans>Name</Trans>,
        isActive: <Trans>Status</Trans>,
        insertedAt: <Trans>Inserted at</Trans>,
        updatedAt: <Trans>Updated at</Trans>
      }}
      renderRow={({
        databaseId,
        isActive,
        insertedAt,
        updatedAt,
        code,
        system
      }: ForbiddenGroupCode) => ({
        databaseId,
        code,
        name: <DictionaryValue name={system} item={code} />,
        isActive: (
          <Flex justifyContent="center">
            {isActive ? <PositiveIcon /> : <NegativeIcon />}
          </Flex>
        ),
        system: system,
        insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
        updatedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, updatedAt)
      })}
      tableName={tableName}
      hiddenFields="updatedAt"
    />
  );
};

export default ForbiddenGroupCodesTable;
