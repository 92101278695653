import React from "react";
import { Text } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { isEmpty, isEqual } from "lodash";

import {
  TableRoot,
  TableBodyComponent,
  TableCell,
  TableRow,
  HeaderData
} from "../../../../components/Table";

type TableDiffProps = {
  renderRow: (tableData: any) => { [key: string]: any };
  header: HeaderData;
  data: any;
  hideEmptyFields?: boolean;
};

const TableDiff = ({
  renderRow,
  header,
  data,
  hideEmptyFields
}: TableDiffProps) => {
  const transformedData = (
    header: HeaderData,
    [firstValue, secondValue]: { [key: string]: any }[],
    hideEmptyFields?: boolean
  ) => {
    const person = !isEmpty(firstValue) ? firstValue : {};
    const masterPerson = !isEmpty(secondValue) ? secondValue : {};

    const result = Object.entries(header).reduce(
      (summary: $TSFixMe, [key, translation]) => {
        const data = {
          [key]: [
            translation,
            person[key]
              ? typeof person[key] === "string"
                ? person[key]
                : Object.entries(person[key]).reduce((accum, [k, v]) => {
                    return {
                      ...accum,
                      ...(!isEqual(
                        person[key][k],
                        masterPerson[key] && masterPerson[key][k]
                      ) && {
                        [k]: v
                      })
                    };
                  }, {})
              : person[key],
            masterPerson[key]
              ? typeof masterPerson[key] === "string"
                ? masterPerson[key]
                : Object.entries(masterPerson[key]).reduce((accum, [k, v]) => {
                    return {
                      ...accum,
                      ...(!isEqual(
                        masterPerson[key][k],
                        person[key] && person[key][k]
                      ) && {
                        [k]: v
                      })
                    };
                  }, {})
              : masterPerson[key]
          ]
        };

        if (hideEmptyFields) {
          if (
            !isEqual(person[key], masterPerson[key]) &&
            (person[key] || masterPerson[key])
          ) {
            return [...summary, data];
          } else return summary;
        } else return [...summary, data];
      },
      []
    );
    return result;
  };

  return (
    <TableRoot fontSize={1}>
      {[
        null,
        <Trans>Previous version</Trans>,
        <Trans>Change version</Trans>
      ].map((tableHeader, i) => (
        <TableCell key={i}>
          <Text fontWeight="bold">{tableHeader}</Text>
        </TableCell>
      ))}
      <TableBodyComponent>
        {transformedData(header, data, hideEmptyFields).map(
          (item: $TSFixMe, index: number) => (
            <TableRow key={`row-${index}`}>
              {Object.entries(item).map(
                ([key, values]: [key: string, values: any]) =>
                  values.map((value: any, index: number) => {
                    const row = renderRow({ [key]: value });
                    return (
                      <TableCell key={`${key}-${index}`} variant="horizontal">
                        {index ? row[key] : value}
                      </TableCell>
                    );
                  })
              )}
            </TableRow>
          )
        )}
      </TableBodyComponent>
    </TableRoot>
  );
};

export default TableDiff;
