import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";

import { Scalars } from "@ehealth/ehealth-ua.schema";

import Button from "../../../../components/Button";
import Popup from "../../../../components/Popup";

type DeactivateProps = {
  id: Scalars["ID"];
  isActive: boolean;
};

const Deactivate = ({ id, isActive }: DeactivateProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Mutation mutation={DeactivateMedicalProgramMutation}>
      {(deactivateMedicalProgram: MutationFunction) => (
        <Box>
          <Button variant="red" onClick={toggle} disabled={!isActive}>
            <Trans>Deactivate</Trans>
          </Button>
          <Popup
            visible={isPopupVisible}
            onCancel={toggle}
            title={<Trans>Deactivate medical program</Trans>}
            okText={<Trans>Deactivate</Trans>}
            onOk={async () => {
              await deactivateMedicalProgram({
                variables: {
                  input: {
                    id
                  }
                }
              });
              toggle();
            }}
          />
        </Box>
      )}
    </Mutation>
  );
};

const DeactivateMedicalProgramMutation = gql`
  mutation DeactivateMedicalProgramMutation(
    $input: DeactivateMedicalProgramInput!
  ) {
    deactivateMedicalProgram(input: $input) {
      medicalProgram {
        id
      }
    }
  }
`;

export default Deactivate;
