import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  Form,
  LocationParams,
  Validation
} from "@edenlabllc/ehealth-components";
import { MedicationRequestConnection } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../components/SearchForm";

import { MonitoringTabProps } from "../../../helpers/types";
import pagination from "../../../helpers/pagination";
import paramToBase64 from "../../../helpers/paramToBase64";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

import { PatientSearchField } from "../../ClinicalMonitoring/PatientSearchField";
import { SearchResultTable } from "./EpisodesTab";

export const MEDICATION_REQUESTS_STATUSES: {
  values: { [key: string]: string };
} = {
  values: {
    active: t`Active`,
    completed: t`Completed`,
    expired: t`Expired`,
    rejected: t`Rejected`
  }
};

const MedicationRequestsTab = ({
  id,
  query = MedicationRequestsQuery,
  isClinicalMonitoring = false
}: MonitoringTabProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="medication_request"
  >
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Query
          skip={!locationParams.first && !locationParams.last}
          query={query}
          fetchPolicy="network-only"
          variables={prepareParamsToQuery(
            id!,
            locationParams,
            isClinicalMonitoring
          )}
        >
          {({
            data = {},
            loading
          }: QueryResult<{ [key: string]: MedicationRequestConnection }>) => {
            const medicationRequests = isClinicalMonitoring
              ? data.impersonalMedicationRequests
              : data.medicationRequests;

            return (
              <LoadingOverlay loading={loading}>
                <Box p={1}>
                  <SearchForm
                    initialValues={locationParams}
                    onSubmit={setLocationParams}
                    renderPrimary={() =>
                      PrimarySearchFields(isClinicalMonitoring)
                    }
                    onResetSkipPagination
                  />
                </Box>
                {isEmpty(medicationRequests) ||
                isEmpty(medicationRequests.nodes) ? (
                  <EmptyData mx={2} />
                ) : (
                  <Box mb={6} p={2}>
                    <Box>
                      <Text>
                        <Trans>Total found</Trans>:{" "}
                        {medicationRequests.totalEntries}
                      </Text>
                    </Box>
                    <SearchResultTable
                      data={medicationRequests.nodes}
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                      statusValues={MEDICATION_REQUESTS_STATUSES.values}
                      tableName="medicationRequests"
                      pathSlug={
                        isClinicalMonitoring
                          ? `medication-request/${
                              locationParams.filter &&
                              locationParams.filter.patientId
                            }`
                          : "medication-request"
                      }
                    />
                    <Pagination {...medicationRequests.pageInfo} />
                  </Box>
                )}
              </LoadingOverlay>
            );
          }}
        </Query>
      )}
    </LocationParams>
  </Ability>
);

export default MedicationRequestsTab;

const MedicationRequestsQuery = gql`
  query MedicationRequestsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: MedicationRequestFilter!
    $orderBy: MedicationRequestOrderBy
  ) {
    medicationRequests(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

const prepareParamsToQuery = (
  id: string,
  locationParams: SearchParams,
  isClinicalMonitoring: boolean
) => {
  const {
    medicationRequestCreated,
    status,
    employeeId,
    medicationId,
    legalEntityId,
    encounterId,
    medicationRequestId,
    medicalProgramId,
    startedAt,
    endedAt,
    dispenseValidFrom,
    dispenseValidTo,
    ...restFilterParams
  } = locationParams.filter || {};

  return {
    orderBy: "INSERTED_AT_DESC",
    ...pagination(locationParams),
    filter: {
      ...(!isClinicalMonitoring && { personId: id }),
      ...restFilterParams,
      ...(status && {
        status: status.toUpperCase()
      }),
      ...(medicationRequestCreated && {
        created: `${medicationRequestCreated.from}/${medicationRequestCreated.to}`
      }),
      ...(employeeId && {
        employeeId: paramToBase64("Employee", employeeId)
      }),
      ...(medicationId && {
        medicationId: paramToBase64("INNMDosage", medicationId)
      }),
      ...(legalEntityId && {
        legalEntityId: paramToBase64("LegalEntity", legalEntityId)
      }),
      ...(encounterId && {
        encounterId: paramToBase64("Encounter", encounterId)
      }),
      ...(medicationRequestId && {
        id: paramToBase64("MedicationRequest", medicationRequestId)
      }),
      ...(medicalProgramId && {
        medicalProgramId: paramToBase64("MedicalProgram", medicalProgramId)
      }),
      ...(startedAt && {
        startedAt: `${startedAt.from}/${startedAt.to}`
      }),
      ...(endedAt && {
        endedAt: `${endedAt.from}/${endedAt.to}`
      }),
      ...(dispenseValidFrom && {
        dispenseValidFrom: `${dispenseValidFrom.from}/${dispenseValidFrom.to}`
      }),
      ...(dispenseValidTo && {
        dispenseValidTo: `${dispenseValidTo.from}/${dispenseValidTo.to}`
      })
    }
  };
};

const PrimarySearchFields = (isClinicalMonitoring: boolean) => {
  const { i18n } = useLingui();

  return (
    <Form.Spy>
      {({ values = {} }: $TSFixMe) => (
        <Flex flexDirection="column">
          <PatientSearchField isClinicalMonitoring={isClinicalMonitoring} />
          <Flex>
            <Box px={1} width={1 / 3} mt={3}>
              <Trans
                id="Enter medication request number"
                render={({ translation }) => (
                  <Field.Text
                    name="filter.requestNumber"
                    label={<Trans id="Medication request number" />}
                    placeholder={translation}
                  />
                )}
              />
            </Box>
            <Box width={1 / 3} mt={3} px={1}>
              <Field.Select
                name="filter.status"
                label={<Trans id="Medication request status" />}
                placeholder={i18n._(t`Select status`)}
                items={Object.keys(MEDICATION_REQUESTS_STATUSES.values)}
                itemToString={(item: string) =>
                  item
                    ? i18n._(MEDICATION_REQUESTS_STATUSES.values[item])
                    : item
                }
                variant="select"
              />
            </Box>
            <Box px={1} width={1 / 3} mt={3}>
              <Trans
                id="Enter medication request created"
                render={({ translation }) => (
                  <Field.RangePicker
                    rangeNames={[
                      "filter.medicationRequestCreated.from",
                      "filter.medicationRequestCreated.to"
                    ]}
                    label={<Trans id="Medication request created" />}
                  />
                )}
              />
              {values.filter &&
                values.filter.medicationRequestCreated &&
                (values.filter.medicationRequestCreated.from ||
                  values.filter.medicationRequestCreated.to) && (
                  <>
                    <Trans
                      id="Required field"
                      render={({ translation }) => (
                        <Validation.Required
                          field="filter.medicationRequestCreated.from"
                          message={translation}
                        />
                      )}
                    />
                    <Trans
                      id="Required field"
                      render={({ translation }) => (
                        <Validation.Required
                          field="filter.medicationRequestCreated.to"
                          message={translation}
                        />
                      )}
                    />
                  </>
                )}
            </Box>
          </Flex>
          <Flex>
            <Box px={1} width={1 / 3} mt={3}>
              <Trans
                id="Enter employee ID"
                render={({ translation }) => (
                  <Field.Text
                    name="filter.employeeId"
                    label={<Trans id="Employee ID" />}
                    placeholder={translation}
                  />
                )}
              />
              <Validation.Matches
                field="filter.employeeId"
                options={UUID_PATTERN}
                message="Invalid employee ID"
              />
            </Box>
            <Box px={1} width={1 / 3} mt={3}>
              <Trans
                id="Enter INNM Dosage ID"
                render={({ translation }) => (
                  <Field.Text
                    name="filter.medicationId"
                    label={<Trans id="INNM Dosage ID" />}
                    placeholder={translation}
                  />
                )}
              />
              <Validation.Matches
                field="filter.medicationId"
                options={UUID_PATTERN}
                message="Invalid INNM Dosage ID"
              />
            </Box>
            <Box px={1} width={1 / 3} mt={3}>
              <Trans
                id="Enter legal entity ID"
                render={({ translation }) => (
                  <Field.Text
                    name="filter.legalEntityId"
                    label={<Trans id="Legal entity ID" />}
                    placeholder={translation}
                  />
                )}
              />
              <Validation.Matches
                field="filter.legalEntityId"
                options={UUID_PATTERN}
                message="Invalid legal entity ID"
              />
            </Box>
          </Flex>
          <Flex>
            <Box px={1} width={1 / 3} mt={3}>
              <Trans
                id="Enter encounter ID"
                render={({ translation }) => (
                  <Field.Text
                    name="filter.encounterId"
                    label={<Trans id="Encounter ID" />}
                    placeholder={translation}
                  />
                )}
              />
              <Validation.Matches
                field="filter.encounterId"
                options={UUID_PATTERN}
                message="Invalid encounter ID"
              />
            </Box>
            {isClinicalMonitoring && (
              <Box px={1} width={1 / 3} mt={3}>
                <Trans
                  id="Enter medication request ID"
                  render={({ translation }) => (
                    <Field.Text
                      name="filter.medicationRequestId"
                      label={<Trans id="Medication request ID" />}
                      placeholder={translation}
                    />
                  )}
                />
                <Validation.Matches
                  field="filter.medicationRequestId"
                  options={UUID_PATTERN}
                  message="Invalid medication request ID"
                />
              </Box>
            )}
            <Box px={1} width={1 / 3} mt={3}>
              <Trans
                id="Enter ID"
                render={({ translation }) => (
                  <Field.Text
                    name="filter.medicalProgramId"
                    label={<Trans id="Medical program ID" />}
                    placeholder={translation}
                  />
                )}
              />
              <Validation.Matches
                field="filter.medicalProgramId"
                options={UUID_PATTERN}
                message="Invalid ID"
              />
            </Box>
          </Flex>
          <Flex>
            <Box px={1} width={1 / 3} mt={3}>
              <Field.RangePicker
                rangeNames={["filter.startedAt.from", "filter.startedAt.to"]}
                label={<Trans id="Medication request started at" />}
              />
              {values.filter &&
                values.filter.startedAt &&
                (values.filter.startedAt.from ||
                  values.filter.startedAt.to) && (
                  <>
                    <Validation.Required
                      field="filter.startedAt.from"
                      message="Required field"
                    />
                    <Validation.Required
                      field="filter.startedAt.to"
                      message="Required field"
                    />
                  </>
                )}
            </Box>
            <Box px={1} width={1 / 3} mt={3}>
              <Field.RangePicker
                rangeNames={["filter.endedAt.from", "filter.endedAt.to"]}
                label={<Trans id="Medication request ended at" />}
              />
              {values.filter &&
                values.filter.endedAt &&
                (values.filter.endedAt.from || values.filter.endedAt.to) && (
                  <>
                    <Validation.Required
                      field="filter.endedAt.from"
                      message="Required field"
                    />
                    <Validation.Required
                      field="filter.endedAt.to"
                      message="Required field"
                    />
                  </>
                )}
            </Box>
          </Flex>
          <Flex>
            <Box px={1} width={1 / 3} mt={3}>
              <Field.RangePicker
                rangeNames={[
                  "filter.dispenseValidFrom.from",
                  "filter.dispenseValidFrom.to"
                ]}
                label={<Trans id="Dispense valid from" />}
              />
              {values.filter &&
                values.filter.dispenseValidFrom &&
                (values.filter.dispenseValidFrom.from ||
                  values.filter.dispenseValidFrom.to) && (
                  <>
                    <Validation.Required
                      field="filter.dispenseValidFrom.from"
                      message="Required field"
                    />
                    <Validation.Required
                      field="filter.dispenseValidFrom.to"
                      message="Required field"
                    />
                  </>
                )}
            </Box>
            <Box px={1} width={1 / 3} mt={3}>
              <Field.RangePicker
                rangeNames={[
                  "filter.dispenseValidTo.from",
                  "filter.dispenseValidTo.to"
                ]}
                label={<Trans id="Dispense valid to" />}
              />
              {values.filter &&
                values.filter.dispenseValidTo &&
                (values.filter.dispenseValidTo.from ||
                  values.filter.dispenseValidTo.to) && (
                  <>
                    <Validation.Required
                      field="filter.dispenseValidTo.from"
                      message="Required field"
                    />
                    <Validation.Required
                      field="filter.dispenseValidTo.to"
                      message="Required field"
                    />
                  </>
                )}
            </Box>
          </Flex>
        </Flex>
      )}
    </Form.Spy>
  );
};
