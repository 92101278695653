import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation, Query } from "@apollo/client/react/components";
import {
  ApolloQueryResult,
  MutationFunction,
  QueryResult
} from "@apollo/client";
import Cookie from "js-cookie";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Flex } from "@rebass/emotion";
import debounce from "lodash/debounce";

import { Form, SUBMIT_ERROR, Validation } from "@edenlabllc/ehealth-components";
import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { getFullName } from "@edenlabllc/ehealth-utils";
import {
  PersonAuthenticationMethod,
  ApprovalConnection,
  Dictionary,
  Employee,
  EmployeeConnection,
  ForbiddenGroup,
  ForbiddenGroupConnection
} from "@ehealth/ehealth-ua.schema";

import Button from "../../../components/Button";
import Popup from "../../../components/Popup";
import * as Field from "../../../components/Field";
import DictionaryValue from "../../../components/DictionaryValue";

import AuthMethodsModal from "./AuthMethodsModal";

type CreatePatientApprovalProps = {
  id: string;
  refetch: () => Promise<ApolloQueryResult<{ approvals: ApprovalConnection }>>;
};

const CreatePatientApproval = ({ id, refetch }: CreatePatientApprovalProps) => {
  const { i18n } = useLingui();
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);
  const [isAuthMethodsPopupVisible, setAuthMethodsPopupVisibility] = useState(
    false
  );
  const toggleAuthPopup = () =>
    setAuthMethodsPopupVisibility(!isAuthMethodsPopupVisible);
  const { userId } = Cookie.getJSON("meta");

  return (
    <Mutation mutation={CreateApprovalMutation}>
      {(createApproval: MutationFunction) => (
        <>
          <Button width={200} variant="blue" onClick={toggle} py={2}>
            <Trans>Request Approval</Trans>
          </Button>
          <Popup
            okButtonProps={{ variant: "green" }}
            visible={isPopupVisible}
            onCancel={toggle}
            title={<Trans>Create Request Approval</Trans>}
            okText={<Trans>Create</Trans>}
            justifyButtons="left"
            formId="createApproval"
          >
            <Form
              id="createApproval"
              onSubmit={async ({
                forbiddenGroup,
                grantedTo,
                authorizeWith
              }: {
                forbiddenGroup: ForbiddenGroup;
                grantedTo: Employee;
                authorizeWith: PersonAuthenticationMethod;
              }) => {
                try {
                  await createApproval({
                    variables: {
                      input: {
                        personId: id,
                        accessLevel: "READ",
                        forbiddenGroup: forbiddenGroup.id,
                        grantedTo: grantedTo.id,
                        ...(authorizeWith && {
                          authorizeWith: authorizeWith.id
                        })
                      }
                    }
                  });
                } catch ({ graphQLErrors }) {
                  return {
                    [SUBMIT_ERROR]: [
                      {
                        entry: "default"
                      }
                    ]
                  };
                }
                toggle();
                refetch();
              }}
            >
              <Flex justifyContent="space-between">
                <Box mr={5} width={0.5}>
                  <Query
                    query={SearchForbiddenGroupsQuery}
                    fetchPolicy="cache-first"
                    variables={{
                      skip: true
                    }}
                  >
                    {({
                      data,
                      refetch: refetchForbiddenGroup
                    }: QueryResult<{
                      forbiddenGroups: ForbiddenGroupConnection;
                    }>) => {
                      const forbiddenGroups =
                        data && data.forbiddenGroups
                          ? data.forbiddenGroups.nodes
                          : [];

                      return (
                        <Trans
                          id="Enter group"
                          render={({ translation }) => (
                            <Field.Select
                              name="forbiddenGroup"
                              label={<Trans id="Forbidden group" />}
                              placeholder={translation}
                              items={forbiddenGroups!}
                              itemToString={(item: ForbiddenGroup) =>
                                item && item.name
                              }
                              filterOptions={{
                                keys: ["name"]
                              }}
                              filter={(names: ForbiddenGroup[]) => names}
                              onInputValueChange={debounce(
                                (name: string, { selectedItem, inputValue }) =>
                                  (selectedItem && selectedItem.name) !==
                                    inputValue &&
                                  refetchForbiddenGroup({
                                    skip: false,
                                    first: 20,
                                    filter: {
                                      isActive: true,
                                      name
                                    }
                                  }),
                                1000
                              )}
                              iconComponent={SearchIcon}
                            />
                          )}
                        />
                      );
                    }}
                  </Query>
                  <Validation.Required
                    field="forbiddenGroup"
                    message="Required field"
                  />
                </Box>
                <Box width={0.5}>
                  <Query
                    query={SearchEmployeesQuery}
                    fetchPolicy="cache-first"
                    variables={{
                      skip: false,
                      first: 50,
                      filter: {
                        isActive: true,
                        status: "APPROVED",
                        employeeType: ["NHS PRACTICAL MONITORING EXTENDED"],
                        party: {
                          userId
                        }
                      }
                    }}
                  >
                    {({
                      data
                    }: QueryResult<{ employees: EmployeeConnection }>) => {
                      const employees =
                        data && data.employees ? data.employees.nodes : [];

                      return (
                        <Trans
                          id="Choose signer"
                          render={({ translation }) => (
                            <Field.Select
                              name="grantedTo"
                              label={<Trans id="Performer" />}
                              placeholder={translation}
                              items={employees!.map((e) => {
                                const { party, id, databaseId } = e || {};
                                const { firstName, secondName, lastName } =
                                  party || {};

                                return {
                                  party: { firstName, secondName, lastName },
                                  id,
                                  databaseId
                                };
                              })}
                              itemToString={(item: Employee) =>
                                item && getFullName(item.party)
                              }
                              filterOptions={{
                                keys: ["party.lastName", "party.firstName"]
                              }}
                              filter={(fullNames: Employee[]) => fullNames}
                              variant="select"
                            />
                          )}
                        />
                      );
                    }}
                  </Query>
                  <Validation.Required
                    field="grantedTo"
                    message="Required field"
                  />
                </Box>
              </Flex>
              <Form.Spy
                render={({ form }: $TSFixMe) => {
                  return (
                    <>
                      <Box width={1} onClick={toggleAuthPopup}>
                        <DictionaryValue
                          name="AUTHENTICATION_METHOD"
                          render={(dict: Dictionary["values"]) => (
                            <Trans
                              id="Choose authentication method"
                              render={({ translation }) => (
                                <Field.Select
                                  variant="select"
                                  name="authorizeWith"
                                  label={<Trans id="Authentication methods" />}
                                  placeholder={translation}
                                  itemToString={(item) => dict[item.type]}
                                  iconComponent={() => (
                                    <SearchIcon color="silverCity" />
                                  )}
                                  disabled
                                />
                              )}
                            />
                          )}
                        />
                      </Box>
                      {isAuthMethodsPopupVisible && (
                        <AuthMethodsModal
                          id={id}
                          toggle={toggleAuthPopup}
                          onChoose={(authorizeWith: {
                            id: string;
                            type: string;
                          }) => {
                            form.change("authorizeWith", authorizeWith);
                            toggleAuthPopup();
                          }}
                        />
                      )}
                    </>
                  );
                }}
              />
              <Form.Error
                default={i18n._(
                  t`Something went wrong. Please try again later`
                )}
              />
            </Form>
          </Popup>
        </>
      )}
    </Mutation>
  );
};

export default CreatePatientApproval;

const CreateApprovalMutation = gql`
  mutation CreateApprovalMutation($input: CreateApprovalInput!) {
    createApproval(input: $input) {
      approval {
        id
      }
    }
  }
`;

export const SearchForbiddenGroupsQuery = gql`
  query SearchForbiddenGroupsQuery(
    $filter: ForbiddenGroupFilter
    $first: Int
    $last: Int
    $before: String
    $after: String
    $skip: Boolean! = false
  ) {
    forbiddenGroups(
      filter: $filter
      first: $first
      last: $last
      before: $before
      after: $after
    ) @skip(if: $skip) {
      nodes {
        id
        databaseId
        name
        isActive
        insertedAt
        updatedAt
      }
    }
  }
`;

export const SearchEmployeesQuery = gql`
  query SearchEmployeesQuery(
    $filter: EmployeeFilter
    $orderBy: EmployeeOrderBy
    $first: Int
    $last: Int
    $before: String
    $after: String
    $skip: Boolean! = false
  ) {
    employees(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) @skip(if: $skip) {
      nodes {
        id
        databaseId
        party {
          id
          firstName
          secondName
          lastName
        }
      }
    }
  }
`;
