import React from "react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import * as Field from "../Field";

const Dictionaries = [
  {
    value: "eHealth/ICD10_AM/condition_codes",
    name: "Довідник діагнозів ICD10AM"
  },
  {
    value: "eHealth/ICPC2/condition_codes",
    name: "Довідник діагнозів ICPC2"
  }
];

type DiagnosesGroupSystemProps = {
  name: string;
  disabled?: boolean;
};

const DiagnosesGroupSystem = ({
  name,
  disabled
}: DiagnosesGroupSystemProps) => {
  const { i18n } = useLingui();

  return (
    <Field.Select
      name={name}
      label={<Trans id="Dictionary type" />}
      placeholder={i18n._(t`All dictionaries`)}
      items={Dictionaries.map((item) => item.value)}
      itemToString={(item) => {
        const found = Dictionaries.find(
          (dictionary) => dictionary.value === item
        );
        return found ? found.name : i18n._(t`All dictionaries`);
      }}
      variant="select"
      disabled={disabled}
    />
  );
};

export default DiagnosesGroupSystem;
