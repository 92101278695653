import React from "react";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import system from "@edenlabllc/ehealth-system-components";
import { cleanDeep } from "@edenlabllc/ehealth-utils";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import { LocationParams, Modal } from "@edenlabllc/ehealth-components";
import { Person, PersonConnection } from "@ehealth/ehealth-ua.schema";

import LoadingOverlay from "../../../../components/LoadingOverlay";
import Pagination from "../../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../../components/SearchForm";

import filteredLocationParams from "../../../../helpers/filteredLocationParams";

import { SearchPersonsQuery } from "../../Search";
import { AllSearchField, SearchButton } from "../../Search/SearchFields";
import PersonsTable from "../../Search/PersonsTable";

type ThirdPersonModalFormProps = {
  state: any;
  onClose: () => void;
};

const ThirdPersonModalForm = ({
  state,
  onClose
}: ThirdPersonModalFormProps) => {
  return (
    <LocationParams>
      {() => (
        <Modal backdrop width="70%">
          <Close onClick={onClose} />
          <SearchThirdPerson state={state} onClose={onClose} />
        </Modal>
      )}
    </LocationParams>
  );
};

export default ThirdPersonModalForm;

const Close = system(
  {
    is: RemoveItemIcon
  },
  {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: 12,
    height: 12,
    opacity: 0.2
  }
);

type SearchThirdPersonProps = {
  state: any;
  onClose: () => void;
};

const SearchThirdPerson = ({ state, onClose }: SearchThirdPersonProps) => (
  <Box>
    <Heading as="h1" fontWeight="normal" mb={6}>
      <Trans>Patient Search</Trans>
    </Heading>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const {
          filter: { taxId, databaseId, identity, personal, status } = {}
        } = locationParams;
        const { number, type, ...documents } = identity || {};

        const filterPersonsParams = cleanDeep({
          taxId,
          databaseId,
          identity: {
            ...documents,
            document: number && {
              type: type,
              number
            }
          },
          personal,
          status
        });

        return (
          <>
            <SearchForm
              initialValues={{ ...locationParams, ...state }}
              onSubmit={setLocationParams}
              renderPrimary={AllSearchField}
              searchButton={SearchButton}
              onClose={onClose}
              showCloseButton
            />
            <Query
              skip={
                isEmpty(taxId) &&
                isEmpty(databaseId) &&
                (isEmpty(identity) || isEmpty(personal))
              }
              fetchPolicy="network-only"
              query={SearchPersonsQuery}
              variables={{
                ...filteredLocationParams(locationParams),
                filter: filterPersonsParams
              }}
            >
              {({
                loading,
                data
              }: QueryResult<{ persons: PersonConnection }>) => {
                const pageInfo = data && data.persons.pageInfo;
                const persons = isEmpty(data) ? [] : data.persons.nodes;
                return (
                  <LoadingOverlay loading={loading}>
                    <PersonsTable
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                      persons={persons}
                      ableChosePerson
                      handleChosePerson={(thirdPerson: Partial<Person>) => {
                        setLocationParams({
                          ...locationParams,
                          thirdPerson
                        });
                        onClose();
                      }}
                    />
                    {pageInfo && <Pagination {...pageInfo} />}
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        );
      }}
    </LocationParams>
  </Box>
);
