import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";

import { InnmDosage, Scalars } from "@ehealth/ehealth-ua.schema";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import Popup from "../../../../components/Popup";

type DeactivateProps = {
  id: Scalars["ID"];
  name: InnmDosage["name"];
};

const Deactivate = ({ id, name }: DeactivateProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Ability action="deactivate" resource="innm_dosage">
      <Mutation mutation={DeactivateINNMDosageMutation}>
        {(deactivateINNMDosage: MutationFunction) => (
          <>
            <Button onClick={toggle} variant="red">
              <Trans>Deactivate</Trans>
            </Button>
            <Popup
              visible={isPopupVisible}
              onCancel={toggle}
              title={
                <>
                  <Trans>Deactivate</Trans> "{name}
                  "?
                </>
              }
              okText={<Trans>Deactivate</Trans>}
              onOk={async () => {
                await deactivateINNMDosage({
                  variables: {
                    input: {
                      id
                    }
                  }
                });
                toggle();
              }}
            />
          </>
        )}
      </Mutation>
    </Ability>
  );
};

const DeactivateINNMDosageMutation = gql`
  mutation DeactivateINNMDosageMutation($input: DeactivateINNMDosageInput!) {
    deactivateINNMDosage(input: $input) {
      innmDosage {
        id
        isActive
      }
    }
  }
`;

export default Deactivate;
