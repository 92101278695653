import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  LegalEntity,
  RelatedLegalEntity,
  Scalars
} from "@ehealth/ehealth-ua.schema";

import Ability from "../../../components/Ability";
import Badge from "../../../components/Badge";
import EmptyData from "../../../components/EmptyData";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import Table, { SortingParams } from "../../../components/Table";
import Tooltip from "../../../components/Tooltip";
import { TLocationParams } from "../../../components/SearchForm";

import dateFormatter from "../../../helpers/dateFormatter";
import { ITEMS_PER_PAGE } from "../../../constants/pagination";

import { LegalEntityQuery } from "./";

type RelatedLegalEntitiesProps = RouteComponentProps<{
  id: Scalars["ID"];
  mergedToLegalEntity: LegalEntity["mergedToLegalEntity"];
}>;

const RelatedLegalEntities = ({
  id,
  mergedToLegalEntity
}: RelatedLegalEntitiesProps) => (
  <Ability action="read" resource="related_legal_entities">
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const { first, last, after, before, orderBy } = locationParams;

        return (
          <Box p={5}>
            <Box css={{ textAlign: "right" }}>
              {mergedToLegalEntity ? (
                <Tooltip
                  placement="top"
                  content={
                    <Trans>Attention, legal entity was reorganized</Trans>
                  }
                  component={() => (
                    <Link
                      to={`../../${mergedToLegalEntity.mergedToLegalEntity.id}`}
                      fontWeight="bold"
                    >
                      <Trans>Go to the main legal entity</Trans>
                    </Link>
                  )}
                />
              ) : null}
            </Box>
            <Query
              query={LegalEntityQuery}
              fetchPolicy="network-only"
              variables={{
                id,
                firstMergedFromLegalEntities:
                  !first && !last
                    ? ITEMS_PER_PAGE[0]
                    : first
                    ? parseInt(first)
                    : undefined,
                lastMergedFromLegalEntities: last ? parseInt(last) : undefined,
                beforeMergedFromLegalEntities: before,
                afterMergedFromLegalEntities: after,
                firstDivisions: ITEMS_PER_PAGE[0],
                firstHealthcareServices: ITEMS_PER_PAGE[0],
                firstLicenses: ITEMS_PER_PAGE[0]
              }}
            >
              {({
                loading,
                data
              }: QueryResult<{ legalEntity: LegalEntity }>) => {
                if (
                  isEmpty(data) ||
                  isEmpty(data.legalEntity) ||
                  isEmpty(data.legalEntity.mergedFromLegalEntities)
                )
                  return null;
                const {
                  legalEntity: {
                    mergedFromLegalEntities: {
                      nodes: mergedFromLegalEntities,
                      pageInfo
                    }
                  }
                } = data;

                return (
                  <LoadingOverlay loading={loading}>
                    {mergedFromLegalEntities &&
                    mergedFromLegalEntities.length > 0 ? (
                      <>
                        <Table
                          data={mergedFromLegalEntities}
                          header={{
                            name: <Trans>Legal entity name</Trans>,
                            edrpou: <Trans>EDRPOU</Trans>,
                            reason: <Trans>Basis</Trans>,
                            insertedAt: <Trans>Added</Trans>,
                            status: <Trans>Status</Trans>,
                            details: <Trans>Action</Trans>
                          }}
                          renderRow={({
                            reason,
                            insertedAt,
                            mergedFromLegalEntity: {
                              edrpou,
                              name,
                              status,
                              id: mergedFromLegalEntityId
                            }
                          }: RelatedLegalEntity) => ({
                            reason,
                            insertedAt: dateFormatter(
                              i18n.locale,
                              {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric"
                              },
                              insertedAt
                            ),
                            name,
                            edrpou,
                            status: (
                              <Badge
                                name={status}
                                type="LEGALENTITY"
                                display="block"
                              />
                            ),
                            details: (
                              <Link
                                to={`../../${mergedFromLegalEntityId}`}
                                fontWeight="bold"
                              >
                                <Trans>Details</Trans>
                              </Link>
                            )
                          })}
                          sortableFields={["insertedAt"]}
                          sortingParams={parseSortingParams(orderBy)}
                          onSortingChange={(sortingParams: SortingParams) =>
                            setLocationParams({
                              orderBy: stringifySortingParams(sortingParams)
                            })
                          }
                          tableName="mergedFromLegalEntities"
                        />
                        <Pagination {...pageInfo} />
                      </>
                    ) : (
                      <EmptyData />
                    )}
                  </LoadingOverlay>
                );
              }}
            </Query>
          </Box>
        );
      }}
    </LocationParams>
  </Ability>
);

RelatedLegalEntities.fragments = {
  entry: gql`
    fragment RelatedLegalEntities on LegalEntity {
      mergedToLegalEntity {
        mergedToLegalEntity {
          id
        }
      }
      mergedFromLegalEntities(
        first: $firstMergedFromLegalEntities
        before: $beforeMergedFromLegalEntities
        after: $afterMergedFromLegalEntities
        last: $lastMergedFromLegalEntities
        filter: $mergeLegalEntityFilter
        orderBy: $orderByMergedFromLegalEntities
      ) {
        nodes {
          id
          reason
          insertedAt
          mergedFromLegalEntity {
            id
            name
            edrpou
            status
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
    ${Pagination.fragments.entry}
  `
};

export default RelatedLegalEntities;
