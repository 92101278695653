import React, { useMemo } from "react";
import { NavigateFn, RouteComponentProps } from "@reach/router";
import { loader } from "graphql.macro";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Flex, Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  LocationParams,
  Validation,
  Validations
} from "@edenlabllc/ehealth-components";
import {
  SearchIcon,
  PositiveIcon,
  NegativeIcon
} from "@edenlabllc/ehealth-icons";
import { getFullName } from "@edenlabllc/ehealth-utils";
import { LegalEntity, LegalEntityConnection } from "@ehealth/ehealth-ua.schema";

import Badge from "../../../components/Badge";
import Button from "../../../components/Button";
import DictionaryValue from "../../../components/DictionaryValue";
import DefinitionListView from "../../../components/DefinitionListView";
import * as Field from "../../../components/Field";
import Line from "../../../components/Line";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";
import Table from "../../../components/Table";

import pagination from "../../../helpers/pagination";
import { EDRPOU_OR_PASSPORT_OR_LEGAL_ENTITY_ID_PATTERN } from "../../../constants/validationPatterns";

import { ID_PATTERN } from "../../LegalEntities/Search";

const edrpouOrPassportOrLegalentityIdPatternWithExcludedCode = (
  edrpou: string,
  legalentityId: string
) =>
  `(^(?!${edrpou}$)([0-9]{8,10}|((?![ЫЪЭЁ])([А-ЯҐЇІЄ])){2}[0-9]{6})$)|(^(?!${legalentityId}$)[0-9A-Za-zА]{8}-[0-9A-Za-zА]{4}-[0-9A-Za-zА]{4}-[0-9A-Za-zА]{4}-[0-9A-Za-zА]{12}$)`;

const SearchLegalEntitiesQuery = loader(
  "../../../graphql/SearchLegalEntitiesQuery.graphql"
);

const SearchLEStep = ({
  navigate,
  location,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const isEnableChoseLE = useMemo(
    () => isEmpty(state) || isEmpty(state.reorganizedLegalEntities),
    [state]
  );

  return (
    <>
      <LocationParams state={state}>
        {({ locationParams, setLocationParams }: TLocationParams) => {
          const code = locationParams.filter && locationParams.filter.code;
          const regId = new RegExp(ID_PATTERN);
          const testID = code && regId.test(code);
          const sendCodeParams = testID
            ? { databaseId: code }
            : { edrpou: code };

          const filteredParams = {
            ...sendCodeParams,
            status: ["ACTIVE", "SUSPENDED"],
            type: ["MSP", "PRIMARY_CARE", "EMERGENCY", "OUTPATIENT"]
          };

          const isLEChosen = (legalEntity: Partial<LegalEntity>) => {
            const findLE = () => {
              if (isEmpty(state.reorganizedLegalEntities)) return null;
              return state.reorganizedLegalEntities.find(
                (currLegalEntity: LegalEntity) =>
                  currLegalEntity.databaseId === legalEntity.databaseId
              );
            };

            const foundLE = findLE();

            return isEmpty(foundLE);
          };

          const excludedOrganization = {
            id: [],
            edrpou: []
          };

          Object.keys(state.successorLegalEntites || []).map((index) => {
            excludedOrganization.edrpou.push(
              // @ts-expect-error location state
              state.successorLegalEntites[index].edrpou
            );
            excludedOrganization.id.push(
              // @ts-expect-error location state
              state.successorLegalEntites[index].databaseId
            );
          });

          return (
            <>
              <SearchForm
                initialValues={locationParams}
                onSubmit={setLocationParams}
                renderPrimary={() => (
                  <PrimarySearchFields
                    codeFilterLabel={
                      <Trans id="Search reorganized legal entity steps">
                        Search reorganized legal entity
                      </Trans>
                    }
                    excludedOrganization={{
                      id: excludedOrganization.id.join("|"),
                      edrpou: excludedOrganization.edrpou.join("|")
                    }}
                  />
                )}
                searchButton={() => (
                  <Button
                    variant="blue"
                    width={140}
                    ml={-1}
                    disabled={!isEnableChoseLE}
                  >
                    <Trans>Search</Trans>
                  </Button>
                )}
              />
              {isEnableChoseLE && !isEmpty(locationParams.filter) && (
                <Query
                  query={SearchLegalEntitiesQuery}
                  variables={{
                    ...pagination(locationParams),
                    filter: !isEmpty(locationParams.filter)
                      ? filteredParams
                      : undefined
                  }}
                  fetchPolicy="network-only"
                >
                  {({
                    loading,
                    error,
                    data
                  }: QueryResult<{ legalEntities: LegalEntityConnection }>) => {
                    if (error || isEmpty(data) || isEmpty(data.legalEntities))
                      return null;
                    const {
                      legalEntities: { nodes: legalEntities = [], pageInfo }
                    } = data;

                    return (
                      <LoadingOverlay loading={loading}>
                        <Table
                          data={legalEntities}
                          header={{
                            databaseId: <Trans>Legal entity ID</Trans>,
                            name: <Trans>Legal entity name</Trans>,
                            edrpou: <Trans>EDRPOU</Trans>,
                            owner: <Trans>CEO</Trans>,
                            type: <Trans>Type</Trans>,
                            nhsVerified: <Trans>Verified by NZZU</Trans>,
                            status: <Trans>Status</Trans>,
                            action: <Trans>Action</Trans>
                          }}
                          renderRow={({
                            status,
                            nhsVerified,
                            owner,
                            type,
                            ...legalEntity
                          }: LegalEntity) => ({
                            ...legalEntity,
                            type: (
                              <DictionaryValue
                                name="LEGAL_ENTITY_TYPE"
                                item={type}
                              />
                            ),
                            owner: owner && getFullName(owner.party),
                            nhsVerified: (
                              <Flex justifyContent="center">
                                {nhsVerified ? (
                                  <PositiveIcon />
                                ) : (
                                  <NegativeIcon />
                                )}
                              </Flex>
                            ),
                            status: (
                              <Badge
                                name={status}
                                type="LEGALENTITY"
                                display="block"
                              />
                            ),
                            action: isLEChosen(legalEntity) ? (
                              <Button
                                mr={2}
                                onClick={() => {
                                  navigate!(
                                    `${
                                      location && location.pathname
                                    }?filter.code=${code}`,
                                    {
                                      state: {
                                        ...state,
                                        reorganizedLegalEntities: [
                                          {
                                            status,
                                            nhsVerified,
                                            owner,
                                            type,
                                            ...legalEntity
                                          }
                                        ]
                                      }
                                    }
                                  );
                                }}
                                variant="link"
                                type="reset"
                                width={140}
                              >
                                <Trans>Chose</Trans>
                              </Button>
                            ) : (
                              <Button variant="linkDisabled">
                                <Trans>Chosen</Trans>
                              </Button>
                            )
                          })}
                          tableName="legalEntity/Add"
                        />
                        <Pagination {...pageInfo} />
                      </LoadingOverlay>
                    );
                  }}
                </Query>
              )}
              {state && !isEmpty(state.reorganizedLegalEntities) && (
                <Box mt={6}>
                  <Heading as="h1" fontWeight="normal" mb={6}>
                    <Trans>Added reorganized legal entity</Trans>
                  </Heading>
                  {(state.reorganizedLegalEntities as LegalEntity[]).map(
                    (legalEntity) => (
                      <LegalEntityItem
                        legalEntity={legalEntity}
                        key={legalEntity.id}
                        navigate={navigate}
                        state={state}
                        location={location}
                        statePartName="reorganizedLegalEntities"
                      />
                    )
                  )}
                </Box>
              )}
              <Line />
              <Flex mt={5}>
                <Link to="../">
                  <Button variant="blue" width={140}>
                    <Trans>Return</Trans>
                  </Button>
                </Link>
                <Button
                  variant="green"
                  width={140}
                  ml={2}
                  disabled={
                    isEmpty(state) || isEmpty(state.reorganizedLegalEntities)
                  }
                  onClick={() =>
                    navigate!("reorganization-data", {
                      state
                    })
                  }
                >
                  <Trans>Next</Trans>
                </Button>
              </Flex>
            </>
          );
        }}
      </LocationParams>
    </>
  );
};

export default SearchLEStep;

type PrimarySearchFieldsProps = {
  codeFilterLabel: React.ReactNode;
  excludedOrganization: {
    edrpou: string;
    id: string;
  };
};

export const PrimarySearchFields = ({
  codeFilterLabel,
  excludedOrganization
}: PrimarySearchFieldsProps) => {
  const { i18n } = useLingui();

  return (
    <Flex mx={-1}>
      <Box px={1} width={1 / 2}>
        <Trans
          id="Legal entity EDRPOU or ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.code"
              label={codeFilterLabel}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
              autoComplete="off"
            />
          )}
        />
        <Validations field="filter.code">
          <Validation.Matches
            options={EDRPOU_OR_PASSPORT_OR_LEGAL_ENTITY_ID_PATTERN}
            message={i18n._(t`Invalid format`)}
          />
          {excludedOrganization && (
            <Validation.Matches
              options={edrpouOrPassportOrLegalentityIdPatternWithExcludedCode(
                excludedOrganization.edrpou,
                excludedOrganization.id
              )}
              message={i18n._(
                t`Legal entity is already selected on other step`
              )}
            />
          )}
        </Validations>
      </Box>
    </Flex>
  );
};

type LegalEntityItemProps = {
  legalEntity: LegalEntity;
  state: any;
  location: RouteComponentProps["location"];
  statePartName: string;
  navigate?: NavigateFn;
};

export const LegalEntityItem = ({
  legalEntity,
  navigate,
  state,
  location,
  statePartName
}: LegalEntityItemProps) => {
  const { databaseId, name, edrpou, owner, type, nhsVerified } = legalEntity;

  return (
    <>
      <Flex>
        <Box width={1 / 2}>
          <DefinitionListView
            labels={{
              databaseId: <Trans>Legal entity ID</Trans>,
              name: <Trans>Name</Trans>,
              edrpou: <Trans>EDRPOU</Trans>,
              owner: <Trans>Owner</Trans>,
              type: <Trans>Type</Trans>,
              nhsVerified: <Trans>NHS Verified</Trans>
            }}
            data={{
              databaseId: databaseId,
              name: name,
              edrpou: edrpou,
              owner: !isEmpty(owner) && getFullName(owner.party),
              type: <DictionaryValue name="LEGAL_ENTITY_TYPE" item={type} />,
              nhsVerified: nhsVerified ? <PositiveIcon /> : <NegativeIcon />
            }}
            labelWidth="120px"
          />
        </Box>
        <Box alignSelf="flex-end">
          <Button
            variant="red"
            onClick={() =>
              navigate!(`${location!.pathname}`, {
                state: {
                  ...state,
                  [statePartName]: state[statePartName].filter(
                    (currentLegalEntity: LegalEntity) =>
                      currentLegalEntity !== legalEntity
                  )
                }
              })
            }
          >
            <Trans>Delete</Trans>
          </Button>
        </Box>
      </Flex>
    </>
  );
};
