import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { PositiveIcon, NegativeIcon } from "@edenlabllc/ehealth-icons";
import { MedicalProgram, Maybe } from "@ehealth/ehealth-ua.schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import Update from "./Mutations/Update";

type GeneralInfoProps = RouteComponentProps & {
  id: MedicalProgram["id"];
  name: MedicalProgram["name"];
  insertedAt: MedicalProgram["insertedAt"];
  type: MedicalProgram["type"];
  medicationRequestAllowed: MedicalProgram["medicationRequestAllowed"];
  medicationDispenseAllowed: MedicalProgram["medicationDispenseAllowed"];
  medicationRequestAllowedText: MedicalProgram["medicationRequestAllowedText"];
  medicationDispenseAllowedText: MedicalProgram["medicationDispenseAllowedText"];
  fundingSource: MedicalProgram["fundingSource"];
  mrBlankType: MedicalProgram["mrBlankType"];
};

const GeneralInfo = ({
  id,
  name,
  insertedAt,
  type,
  medicationRequestAllowed,
  medicationDispenseAllowed,
  medicationRequestAllowedText,
  medicationDispenseAllowedText,
  fundingSource,
  mrBlankType
}: GeneralInfoProps) => (
  <Box p={5}>
    <DefinitionListView
      labels={{
        name: <Trans>Medical program name</Trans>,
        type: <Trans>Type</Trans>,
        fundingSource: <Trans>Funding source</Trans>,
        ...(!isEmpty(mrBlankType) && {
          mrBlankType: <Trans>Type of Medication request blank</Trans>
        }),
        insertedAt: <Trans>Inserted at</Trans>,
        ...(type === "MEDICATION" && {
          medicationRequestAllowed: <Trans>Medication request allowed</Trans>
        }),
        ...(type === "MEDICATION" && {
          medicationDispenseAllowed: <Trans>Medication dispense allowed</Trans>
        })
      }}
      data={{
        name,
        insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
        type: <DictionaryValue name="MEDICAL_PROGRAM_TYPE" item={type} />,
        fundingSource: (
          <DictionaryValue name="FUNDING_SOURCE" item={fundingSource} />
        ),
        mrBlankType: (
          <DictionaryValue name="MR_BLANK_TYPES" item={mrBlankType} />
        ),
        medicationRequestAllowed: (
          <MedicationAllowed
            allowed={medicationRequestAllowed}
            text={medicationRequestAllowedText}
          >
            <Update
              id={id}
              data={{
                medicationRequestAllowed,
                medicationRequestAllowedText
              }}
            />
          </MedicationAllowed>
        ),
        medicationDispenseAllowed: (
          <MedicationAllowed
            allowed={medicationDispenseAllowed}
            text={medicationDispenseAllowedText}
          >
            <Update
              id={id}
              data={{
                medicationDispenseAllowed,
                medicationDispenseAllowedText
              }}
            />
          </MedicationAllowed>
        )
      }}
    />
  </Box>
);

type MedicationAllowedProps = {
  allowed: boolean;
  children: React.ReactNode;
  text?: Maybe<string>;
};

const MedicationAllowed = ({
  allowed,
  text,
  children
}: MedicationAllowedProps) => (
  <Box>
    <Flex align="center">
      <Box>{allowed ? <PositiveIcon /> : <NegativeIcon />}</Box>
      {children}
    </Flex>
    {text && <Box mt={2}>{text}</Box>}
  </Box>
);

GeneralInfo.fragments = {
  entry: gql`
    fragment MedicalProgramGeneralInfo on MedicalProgram {
      name
      insertedAt
      type
      medicationRequestAllowed
      medicationDispenseAllowed
      medicationRequestAllowedText
      medicationDispenseAllowedText
      fundingSource
      mrBlankType
    }
  `
};

export default GeneralInfo;
