import React from "react";
import isEmpty from "lodash/isEmpty";
import { useLingui } from "@lingui/react";
import { Box, Flex } from "@rebass/emotion";
import { Trans, t } from "@lingui/macro";

import { Validation, Validations } from "@edenlabllc/ehealth-components";
import { parsePhone, formatPhone } from "@edenlabllc/ehealth-utils";
import { Dictionary, Phone } from "@ehealth/ehealth-ua.schema";

import DictionaryValue from "../../../../components/DictionaryValue";
import * as Field from "../../../../components/Field";

type PhonesFormProps = {
  name: string;
};

const PhonesForm = ({ name }: PhonesFormProps) => {
  const { i18n } = useLingui();

  return (
    <Flex flexWrap="wrap" justifyContent="start">
      <Box width={0.45} mr={2}>
        <Field.Text
          name={`${name}.number`}
          label={<Trans id="Phone number" />}
          format={formatPhone}
          parse={parsePhone}
        />
        <Validations field={`${name}.number`}>
          <Validation.Required message="Required field" />
          <Validation.Matches
            options={"^\\+380\\d{9}$"}
            message="Invalid phone number"
          />
          <Validation.Custom
            options={({ value, allValues: { phones } }: $TSFixMe) => {
              const duplicates = phones.filter(
                (phone: Phone) => phone && phone.number === value
              );
              return duplicates.length >= 2 &&
                !name.includes("emergencyContact") &&
                !name.includes("confidantPersons")
                ? false
                : true;
            }}
            message={i18n._(t`This number is used more than once`)}
          />
          <Validation.Custom
            options={({
              value,
              allValues: {
                emergencyContact: { phones }
              }
            }: $TSFixMe) => {
              const duplicates = phones.filter(
                (phone: Phone) => phone && phone.number === value
              );
              return duplicates.length >= 2 && name.includes("emergencyContact")
                ? false
                : true;
            }}
            message={i18n._(t`This number is used more than once`)}
          />
          <Validation.Custom
            options={({ value, allValues: { confidantPersons } }: $TSFixMe) => {
              if (
                isEmpty(confidantPersons) ||
                isEmpty(confidantPersons[0]) ||
                isEmpty(confidantPersons[0].phones) ||
                !name.includes("confidantPersons")
              )
                return true;

              const duplicates = confidantPersons[0].phones.filter(
                (phone: Phone) => phone && phone.number === value
              );
              return duplicates.length >= 2 &&
                name.includes("confidantPersons[0]")
                ? false
                : true;
            }}
            message={i18n._(t`This number is used more than once`)}
          />
          <Validation.Custom
            options={({ value, allValues: { confidantPersons } }: $TSFixMe) => {
              if (
                isEmpty(confidantPersons) ||
                isEmpty(confidantPersons[1]) ||
                isEmpty(confidantPersons[1].phones) ||
                !name.includes("confidantPersons")
              )
                return true;

              const duplicates = confidantPersons[1].phones.filter(
                (phone: Phone) =>
                  phone && phone.number && phone.number === value
              );
              return duplicates.length >= 2 &&
                name.includes("confidantPersons[1]")
                ? false
                : true;
            }}
            message={i18n._(t`This number is used more than once`)}
          />
        </Validations>
      </Box>
      <Box width={1 / 4}>
        <DictionaryValue
          name="PHONE_TYPE"
          render={(dict: Dictionary["values"]) => (
            <Field.Select
              name={`${name}.type`}
              label={<Trans id="Phone type" />}
              placeholder={i18n._(t`Select option`)}
              items={Object.keys(dict)}
              itemToString={(item: string) => dict[item] || item}
              variant="select"
            />
          )}
        />
        <Validations field={`${name}.type`}>
          <Validation.Required message="Required field" />
          <Validation.Custom
            options={({ value, allValues: { phones } }: $TSFixMe) => {
              if (
                name.includes("emergencyContact") ||
                name.includes("confidantPersons")
              )
                return true;
              const duplicates = phones.filter(
                (phone: Phone) => phone && phone.type === value
              );
              return duplicates.length >= 2 &&
                !name.includes("emergencyContact")
                ? false
                : true;
            }}
            message={i18n._(t`This type is used more than once`)}
          />
          <Validation.Custom
            options={({
              value,
              allValues: {
                emergencyContact: { phones }
              }
            }: $TSFixMe) => {
              if (isEmpty(phones) || !name.includes("emergencyContact"))
                return true;
              const duplicates = phones.filter(
                (phone: Phone) => phone && phone.type === value
              );
              return duplicates.length >= 2 ? false : true;
            }}
            message={i18n._(t`This type is used more than once`)}
          />
          <Validation.Custom
            options={({ value, allValues: { confidantPersons } }: $TSFixMe) => {
              if (
                isEmpty(confidantPersons) ||
                !name.includes("confidantPersons[0]")
              )
                return true;

              const duplicates = confidantPersons[0].phones.filter(
                (phone: Phone) => phone && phone.type === value
              );
              return duplicates.length >= 2 ? false : true;
            }}
            message={i18n._(t`This type is used more than once`)}
          />
          <Validation.Custom
            options={({ value, allValues: { confidantPersons } }: $TSFixMe) => {
              if (
                isEmpty(confidantPersons) ||
                !name.includes("confidantPersons[1]")
              )
                return true;

              const duplicates = confidantPersons[1].phones.filter(
                (phone: Phone) => phone && phone.type === value
              );
              return duplicates.length >= 2 ? false : true;
            }}
            message={i18n._(t`This type is used more than once`)}
          />
        </Validations>
      </Box>
    </Flex>
  );
};

export default PhonesForm;
