import isEmpty from "lodash/isEmpty";
import {
  formatDateTimeInterval,
  formatDateInterval,
  convertStringToBoolean
} from "@edenlabllc/ehealth-utils";

import { SearchParamsFilter } from "../components/SearchForm";

import {
  CONTRACT_PATTERN,
  GLOBAL_BUDGET_CONTRACT_PATTERN,
  CONTRACT_TYPES
} from "../constants/contracts";

const contractFormFilteredParams = (
  filter: SearchParamsFilter | undefined,
  type: string
) => {
  if (!filter) return {};
  const {
    status,
    medicalProgram,
    legalEntityRelation,
    searchRequest,
    assignee,
    isSuspended,
    contractorLegalEntity,
    date: {
      startFrom,
      startTo,
      endFrom,
      endTo,
      insertedAtFrom,
      insertedAtTo
    } = {}
  } = filter;
  const contractNumberReg =
    type === CONTRACT_TYPES.GLOBAL_BUDGET
      ? new RegExp(GLOBAL_BUDGET_CONTRACT_PATTERN)
      : new RegExp(CONTRACT_PATTERN);
  const contractNumberRegTest =
    searchRequest && contractNumberReg.test(searchRequest);
  const contract =
    !isEmpty(searchRequest) &&
    (!contractNumberRegTest
      ? { contractorLegalEntity: { edrpou: searchRequest } }
      : { contractNumber: searchRequest });

  return {
    startDate: formatDateInterval(startFrom, startTo),
    endDate: formatDateInterval(endFrom, endTo),
    insertedAt: formatDateTimeInterval(insertedAtFrom, insertedAtTo),
    status,
    contractorLegalEntity,
    legalEntityRelation,
    assignee: !isEmpty(assignee)
      ? { databaseId: assignee.databaseId }
      : undefined,
    isSuspended: convertStringToBoolean(isSuspended),
    medicalProgram: !isEmpty(medicalProgram)
      ? { name: medicalProgram.name }
      : undefined,
    ...contract
  };
};

export default contractFormFilteredParams;
