import * as React from "react";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { loader } from "graphql.macro";
import { Trans } from "@lingui/macro";

import { DictionaryConnection, Maybe } from "@ehealth/ehealth-ua.schema";

const DictionaryQuery = loader("../graphql/SearchDictionariesQuery.graphql");

type DictionaryValueProps = {
  name: string;
  item?: Maybe<string> | Maybe<number>;
  children?: (data: { value: string }) => React.ReactNode;
  render?: any;
  withCode?: boolean;
};

const DictionaryValue = ({
  name = "",
  item = "",
  children,
  render = children,
  withCode = false
}: DictionaryValueProps) => (
  <Query
    fetchPolicy="cache-first"
    query={DictionaryQuery}
    variables={{ first: 400 }}
  >
    {({
      loading,
      error,
      data
    }: QueryResult<{ dictionaries: DictionaryConnection }>) => {
      if (loading || error) return null;
      const { dictionaries: { nodes: dictionaries = [] } = {} } = data || {};
      const dictionary =
        dictionaries && dictionaries.find((dict) => dict && dict.name === name);

      const values = dictionary && dictionary.values;

      let value =
        typeof render !== "function" && values
          ? item && values[item]
            ? values[item]
            : item
          : values;

      if (withCode) {
        value = `${value} (${item})`;
      }

      return typeof render === "function"
        ? render(value)
        : value || <Trans>Not found</Trans>;
    }}
  </Query>
);

export default DictionaryValue;
