import React, { useMemo, useCallback } from "react";
import { FieldProps, FieldItem } from "react-awesome-query-builder";

import * as Field from "../../../../../components/Field";

type TSelectItem = {
  key: string | undefined;
  value: string;
};

export default ({
  items,
  setField,
  selectedKey,
  readonly,
  placeholder
}: FieldProps) => {
  const renderSubOptions = (items: FieldItem[], parentLabel: string) => {
    let data: TSelectItem[] = [];
    items.forEach((subfield) => {
      const { items, path, label } = subfield;
      if (items) {
        data = data.concat(
          renderSubOptions(items, `${parentLabel} / ${label}`)
        );
      } else {
        data.push({ key: path, value: `${parentLabel} / ${label}` });
      }
    });

    return data;
  };

  const renderOptions = useCallback((fields: FieldItem[]): TSelectItem[] => {
    let data: TSelectItem[] = [];
    fields.forEach((field) => {
      const { items, path, label, fullLabel } = field;
      if (items) {
        data = data.concat(renderSubOptions(items, label));
      } else {
        data.push({ key: path, value: fullLabel || label });
      }
    });

    return data;
  }, []);

  const onChange = (selectedItem: TSelectItem) =>
    setField((selectedItem && selectedItem.key) || "");

  const selectedItem = useMemo(
    () =>
      items.find((item) => {
        return item.path === selectedKey;
      }) ||
      renderOptions(items).find((item) => {
        return item.key === selectedKey;
      }) ||
      {},
    [items, selectedKey]
  );

  return (
    <Field.SelectFieldView
      placeholder={!readonly ? placeholder : ""}
      value={
        (selectedItem as FieldItem).label ||
        (selectedItem as TSelectItem).value ||
        ""
      }
      onChange={onChange}
      disabled={readonly}
      items={renderOptions(items)}
      itemToString={(item) => (item ? item.value : "")}
      variant="select"
      filter={(items, inputValue = "") =>
        items.filter((item) =>
          item.value.toLowerCase().includes(inputValue.toLowerCase())
        )
      }
      hideErrors
    />
  );
};
