import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { ReimbursementContractConnection } from "@ehealth/ehealth-ua.schema";

import LoadingOverlay from "../../../../components/LoadingOverlay";
import Pagination from "../../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../../components/SearchForm";

import contractFormFilteredParams from "../../../../helpers/contractFormFilteredParams";
import pagination from "../../../../helpers/pagination";
import { CONTRACT_TYPES } from "../../../../constants/contracts";

import ContractsNav from "../../ContractsNav";
import { PrimarySearchFields, SecondarySearchFields } from "./SearchFields";
import ContractTable from "./ContractsTable";

const ReimbursementContractsSearch = (_props: RouteComponentProps) => (
  <Box p={6}>
    <ContractsNav />
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const { filter, first, last, after, before, orderBy } = locationParams;

        return (
          <>
            <SearchForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
              renderPrimary={PrimarySearchFields}
              renderSecondary={SecondarySearchFields}
            />
            <Query
              query={SearchReimbursementContractsQuery}
              fetchPolicy="network-only"
              variables={{
                ...pagination({ first, last, after, before, orderBy }),
                filter: contractFormFilteredParams(
                  filter,
                  CONTRACT_TYPES.REIMBURSEMENT
                )
              }}
            >
              {({
                loading,
                data
              }: QueryResult<{
                reimbursementContracts: ReimbursementContractConnection;
              }>) => {
                if (isEmpty(data) || isEmpty(data.reimbursementContracts))
                  return null;
                const {
                  reimbursementContracts: {
                    nodes: reimbursementContracts = [],
                    pageInfo
                  }
                } = data;

                return (
                  <LoadingOverlay loading={loading}>
                    <>
                      <ContractTable
                        reimbursementContracts={reimbursementContracts}
                        locationParams={locationParams}
                        setLocationParams={setLocationParams}
                      />
                      <Pagination {...pageInfo} />
                    </>
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        );
      }}
    </LocationParams>
  </Box>
);

const SearchReimbursementContractsQuery = gql`
  query SearchReimbursementContractsQuery(
    $filter: ReimbursementContractFilter
    $orderBy: ReimbursementContractOrderBy
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    reimbursementContracts(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        ...ReimbursementContracts
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ContractTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default ReimbursementContractsSearch;
