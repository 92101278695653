import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { DocumentNode, MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex } from "@rebass/emotion";

// @ts-expect-error types mismatch
import { MedicalComposition } from "@ehealth/ehealth-ua.schema";

import Button from "../../../components/Button";
import Popup from "../../../components/Popup";
import { SearchParams } from "../../../components/SearchForm";
import UnpocessableEntityModalError from "../../../components/UnpocessableEntityModalError";

import { getErrorMessage } from "../../../helpers/errorHelpers";

type SendToErlnPopupProps = {
  composition: MedicalComposition;
  integrationData: $TSFixMe;
  locationParams: SearchParams;
  medicalCompositionQuery: DocumentNode;
};

const SendToErlnPopup = ({
  composition,
  integrationData,
  locationParams,
  medicalCompositionQuery
}: SendToErlnPopupProps) => {
  const [sendToErlnPopupOpened, setSendToErlnPopupOpened] = useState(false);
  const [shouldResultPopupOpen, setShouldResultPopupOpen] = useState(false);
  const [error, setError] = useState<null | React.ReactElement>(null);
  const toggleSendToErlnPopup = () => {
    setSendToErlnPopupOpened(!sendToErlnPopupOpened);
    setError(null);
  };

  const id = composition.identifier.value;

  return (
    <Mutation
      mutation={SendToErln}
      onQueryUpdated={() => false}
      refetchQueries={() => [
        {
          query: medicalCompositionQuery,
          variables: locationParams
        }
      ]}
    >
      {(sendToErln: MutationFunction) => {
        return (
          <>
            <Button
              onClick={toggleSendToErlnPopup}
              disabled={
                composition.status !== "FINAL" ||
                (integrationData &&
                  integrationData.integrationStatus !== "ERROR") ||
                composition.section.focus.type.coding[0].code === "preperson"
              }
              variant="blue"
              style={{ flex: 1 }}
            >
              <Trans>Resend medical composition</Trans>
            </Button>

            <Popup
              visible={shouldResultPopupOpen}
              hideOkButton={true}
              onCancel={() => {
                setShouldResultPopupOpen(false);
              }}
              title={<Trans>Integration status</Trans>}
              cancelButtonProps={{ variant: "green" }}
              cancelText={<Trans>Ok</Trans>}
              justifyButtons="center"
            >
              <Flex justifyContent="center" mb={4}>
                <Trans>Status loading</Trans>
              </Flex>
            </Popup>
            <Popup
              visible={sendToErlnPopupOpened}
              onCancel={toggleSendToErlnPopup}
              title={<Trans>Resend medical composition</Trans>}
              okButtonProps={{ variant: "green" }}
              okText={<Trans>Send medical composition</Trans>}
              justifyButtons="center"
              onOk={async () => {
                setError(null);
                try {
                  await sendToErln({
                    variables: {
                      id,
                      input: ""
                    }
                  });

                  setShouldResultPopupOpen(true);
                } catch (e) {
                  setError(
                    <Trans>Something went wrong. Please try again later</Trans>
                  );
                }
                setSendToErlnPopupOpened(false);
              }}
              formId="changeMedicalConclusionStatus"
            >
              <Flex justifyContent="center" mb={4}>
                <Trans>Do you want to resend medical composition to erln</Trans>
              </Flex>
            </Popup>
            {error && (
              <UnpocessableEntityModalError
                errorMessage={getErrorMessage(error)}
                isModalOpen
              />
            )}
          </>
        );
      }}
    </Mutation>
  );
};

const SendToErln = gql`
  mutation SendToErln($id: String!) {
    sendToErln(id: $id, input: {})
      @rest(
        type: "RetryResult"
        method: "PATCH"
        path: "/admin/composition/:id/erln/create/retry"
      ) {
      data
    }
  }
`;

export default SendToErlnPopup;
