import React from "react";
import { Box, Flex, Heading } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { Modal } from "@edenlabllc/ehealth-components";
import system from "@edenlabllc/ehealth-system-components";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";

import Button, { ButtonVariantType } from "./Button";

type PopupProps = {
  visible: boolean;
  onCancel: () => void;
  title?: React.ReactElement;
  children?: React.ReactElement | React.ReactElement[];
  cancelText?: React.ReactElement;
  okText?: React.ReactElement;
  okButtonProps?: {
    variant: ButtonVariantType;
    type?: string;
    disabled?: boolean;
    width?: number;
  };
  cancelButtonProps?: { variant: ButtonVariantType; width?: number };
  width?: number | string;
  onOk?: () => void;
  renderFooter?: (props: PopupFooterProps) => React.ReactElement;
  justifyButtons?: "center" | "left";
  formId?: string;
  hideOkButton?: boolean;
  overflow?: "visible" | "auto";
  textAlign?: "center" | "left";
  headingAlign?: "center";
};

const Popup = ({
  visible,
  title,
  children,
  cancelText = <Trans>Return</Trans>,
  okText = title,
  okButtonProps = { type: "submit", variant: "red" },
  cancelButtonProps = { variant: "blue" },
  width = 760,
  onCancel,
  onOk,
  renderFooter: Footer = PopupFooter,
  justifyButtons = "center",
  formId,
  hideOkButton = false,
  overflow = "visible",
  textAlign = "center",
  headingAlign = "center"
}: PopupProps) =>
  visible ? (
    <Modal width={width} backdrop overflow={overflow} textAlign={textAlign}>
      <Close onClick={onCancel} />
      <Heading as="h1" fontWeight="normal" mb={6} textAlign={headingAlign}>
        {title}
      </Heading>
      {children}
      <Footer
        onCancel={onCancel}
        cancelButtonProps={cancelButtonProps}
        cancelText={cancelText}
        okButtonProps={okButtonProps}
        onOk={onOk}
        okText={okText}
        justifyButtons={justifyButtons}
        formId={formId}
        hideOkButton={hideOkButton}
      />
    </Modal>
  ) : null;

type PopupFooterProps = {
  onCancel: () => void;
  cancelButtonProps?: { variant: ButtonVariantType };
  cancelText?: React.ReactElement;
  okButtonProps?: {
    variant: ButtonVariantType;
    type?: string;
    disabled?: boolean;
  };
  onOk?: () => void;
  okText?: React.ReactElement;
  justifyButtons?: "center" | "left";
  formId?: string;
  hideOkButton?: boolean;
};

const PopupFooter = ({
  onCancel,
  cancelButtonProps,
  cancelText,
  okButtonProps,
  onOk,
  okText,
  justifyButtons,
  formId,
  hideOkButton
}: PopupFooterProps) => (
  <Flex justifyContent={justifyButtons} as="footer">
    <Box mr={!hideOkButton && 20}>
      <Button onClick={onCancel} {...cancelButtonProps}>
        {cancelText}
      </Button>
    </Box>
    {!hideOkButton && (
      <Button {...okButtonProps} onClick={onOk} form={formId}>
        {okText}
      </Button>
    )}
  </Flex>
);

const Close = system(
  {
    is: RemoveItemIcon
  },
  {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: 22,
    height: 22,
    opacity: 0.2,
    padding: "5px",
    cursor: "pointer"
  }
);

export default Popup;
