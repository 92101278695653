import { ITEMS_PER_PAGE } from "../constants/pagination";
import { SearchParams } from "../components/SearchForm";

const resetPaginationParams = ({ first }: SearchParams) => ({
  after: undefined,
  before: undefined,
  last: undefined,
  first: first || String(ITEMS_PER_PAGE[0])
});

export default resetPaginationParams;
