import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { DocumentNode, MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";

import { Form, Validation } from "@edenlabllc/ehealth-components";
import { convertStringToBoolean, cleanDeep } from "@edenlabllc/ehealth-utils";
import { ServiceGroup } from "@ehealth/ehealth-ua.schema";

import Button from "../../../components/Button";
import * as Field from "../../../components/Field";
import Popup from "../../../components/Popup";
import * as SearchField from "../../../components/SearchField";
import { SearchParams } from "../../../components/SearchForm";

import STATUSES from "../../../helpers/statuses";

type CreateServiceGroupPopupProps = {
  locationParams: SearchParams;
  refetchQuery: DocumentNode;
};

const CreateServiceGroupPopup = ({
  locationParams,
  refetchQuery
}: CreateServiceGroupPopupProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Box>
      <Mutation
        mutation={CreateServiceGroupMutation}
        refetchQueries={() => [
          {
            query: refetchQuery,
            variables: locationParams
          }
        ]}
      >
        {(createServiceGroup: MutationFunction) => (
          <>
            <Button onClick={toggle} variant="green">
              <Trans>Create service group</Trans>
            </Button>
            <Popup
              visible={isPopupVisible}
              onCancel={toggle}
              title={<Trans>Create service group</Trans>}
              okButtonProps={{ variant: "green" }}
              justifyButtons="left"
              formId="createServiceGroup"
            >
              <Form
                id="createServiceGroup"
                onSubmit={async ({
                  isRequestAllowed,
                  ...input
                }: {
                  isRequestAllowed: string;
                  parentGroupId: ServiceGroup;
                }) => {
                  const requestAllowed = convertStringToBoolean(
                    isRequestAllowed
                  );
                  const createServiceGroupInput = cleanDeep({
                    ...input,
                    requestAllowed,
                    parentGroupId: input.parentGroupId && input.parentGroupId.id
                  });

                  await createServiceGroup({
                    variables: {
                      input: createServiceGroupInput
                    }
                  });
                  toggle();
                }}
              >
                <Flex mx={-1}>
                  <Box px={1} width={1 / 2}>
                    <Trans
                      id="Enter name"
                      render={({ translation }) => (
                        <Field.Text
                          name="name"
                          label={<Trans id="Name" />}
                          placeholder={translation}
                          maxLength={100}
                          showLengthHint
                        />
                      )}
                    />
                    <Validation.Required
                      field="name"
                      message="Required field"
                    />
                  </Box>
                  <Box px={1} width={1 / 2}>
                    <SearchField.ComposedServiceGroup name="parentGroupId" />
                  </Box>
                </Flex>
                <Flex mx={-1} mb={4}>
                  <Box px={1} width={1 / 2}>
                    <Trans
                      id="Enter code"
                      render={({ translation }) => (
                        <Field.Text
                          name="code"
                          label={<Trans id="Code" />}
                          placeholder={translation}
                          maxLength={10}
                          showLengthHint
                        />
                      )}
                    />
                    <Validation.Required
                      field="code"
                      message="Required field"
                    />
                  </Box>
                  <Box px={1} width={1 / 2}>
                    <Trans
                      id="Select option"
                      render={({ translation }) => (
                        <Field.Select
                          name="isRequestAllowed"
                          label={<Trans id="Is request allowed" />}
                          items={Object.keys(STATUSES.YES_NO)}
                          itemToString={(item: boolean) =>
                            // @ts-expect-error statuses boolean key
                            STATUSES.YES_NO[item] || translation
                          }
                          variant="select"
                          emptyOption
                          filterOptions={{
                            // @ts-expect-error statuses boolean key
                            keys: [(item: boolean) => STATUSES.YES_NO[item]]
                          }}
                        />
                      )}
                    />
                    <Validation.Required
                      field="isRequestAllowed"
                      message="Required field"
                    />
                  </Box>
                </Flex>
              </Form>
            </Popup>
          </>
        )}
      </Mutation>
    </Box>
  );
};

const CreateServiceGroupMutation = gql`
  mutation CreateServiceGroupMutation($input: CreateServiceGroupInput!) {
    createServiceGroup(input: $input) {
      serviceGroup {
        id
      }
    }
  }
`;

export default CreateServiceGroupPopup;
